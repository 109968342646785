import gql from 'graphql-tag';

const CREATE_ORGANIZATION = gql`
  mutation CreateOrganizations($input: [OrganizationCreateInput!]!) {
    createOrganizations(input: $input) {
      info {
        nodesCreated
        relationshipsCreated
      }
      organizations {
        _id
      }
    }
  }
`;

export default CREATE_ORGANIZATION;
