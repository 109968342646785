import Joi from 'joi';

const license = {
  _id: () => Joi.string().optional().allow(null).allow(''),
  owner: () => Joi.string().required(),
  state: () => Joi.string().regex(/^[A-Z]{2}$/).required(),
  number: () => Joi.string().required(),
};

export default license;
