import gql from 'graphql-tag';

const UPDATE_SAVED_SEARCH = gql`
  mutation UpdateSavedSearch($params: SavedSearchParams) {
    updateSavedSearch(params: $params) {
      _id
    }
  }
`;

export default UPDATE_SAVED_SEARCH;
