import React from 'react';
import styled from '@emotion/styled';
import {
  QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation as rqMutation,
} from 'react-query';
import { useQuery } from '@apollo/client';
import { TeamRolesEnum } from '@biproxi/models/enums/Neo4jRolesEnums';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import IUsersToInvite from '@biproxi/models/interfaces/teams/IUsersToInvite';
import ICreateTeamsParams from '@biproxi/models/interfaces/teams/ICreateTeamParams';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import IListingQuery from '@biproxi/models/interfaces/IListingQuery';
import StatusCodeEnum from '@biproxi/models/enums/StatusCodeEnum';
import { createOrganizationMutation } from '@biproxi/next-api-requests';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { media } from '../../utils/MediaQuery';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Icon, { Icons } from '../../elements/Icon';
import Input from '../../elements/Input';
import Dropdown from '../../elements/Dropdown';
import useUser from '../../hooks/useUser.hook';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import LIST_LISTINGS from '../../graphql/queries/listings.query';

const Container = styled.div`
  width: 632px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;

  ${media.mobile} {
    width: 100%;
  }
`;

export enum CreateTeamErrorsEnum {
  MissingTeamName = 'Team name is required. Please create a name for your team.',
  ForgotUser = "It looks like you forgot to add a member after typing an email. Please click the 'Add member' button or delete the email from the input."
}

export interface ICreateTeamError {
  message: string,
  errors: boolean,
}

export type CreateTeamModalProps = {
  refetchTeams: <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<{data: any; status: StatusCodeEnum;}, unknown>>
  establishedTeamData?: ICreateTeamsParams
}

const CreateTeamModal: React.FC<CreateTeamModalProps> = ({
  refetchTeams,
  establishedTeamData,
}) => {
  /** State */
  const [userListings, setUserListings] = React.useState<IListingGraphQL[]>([]);
  const [selectedListings, setSelectedListings] = React.useState<IListingGraphQL[]>([]);
  const [invitedUserRole, setInvitedUserRole] = React.useState<TeamRolesEnum>(TeamRolesEnum.Member);
  const [invitedUserEmail, setInvitedUserEmail] = React.useState<string>('');
  const [usersToInvite, setUsersToInvite] = React.useState<IUsersToInvite[]>(establishedTeamData?.users ?? []);
  const [teamName, setTeamName] = React.useState<string>(establishedTeamData?.name ?? '');
  const [teamDescription, setTeamDescription] = React.useState<string>(establishedTeamData?.description ?? '');

  /** Hooks */
  const user = useUser();

  /** GraphQL */
  type Data = {
    listings: {
      data: IListingGraphQL[],
    }
  }

  type Vars = {
    params: {
      query: IListingQuery,
    }
  }

  useQuery<Data, Vars>(LIST_LISTINGS, {
    skip: Boolean(userListings?.length) || !user?.userId,
    variables: {
      params: {
        query: {
          queryType: ListingQueryTypesEnum.Personal,
          brokerUserId: user.userId,
        },
      },
    },
    onCompleted: (data) => {
      const { listings: { data: listingData } } = data;
      setUserListings(listingData);
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const { mutate: handleCreateTeamMutation, isLoading } = rqMutation((orgData: INeo4jServiceAPI.ICreateOrganizationParams) => createOrganizationMutation(orgData), {
    onSuccess: () => handleCreateTeamSuccess(),
    onError: (err) => handleCreateTeamError(err),
  });

  /** Functions */
  const handleAddMemberCallback = React.useCallback((userEmail: string, userRole: TeamRolesEnum) => {
    // This would be cleaner with proper joi validation standards but they were never created for some reason.
    const cleanedEmail = StringUtil.lowerCaseTrim(userEmail);
    const isDuplicate = usersToInvite?.some((userToInvite) => Object.keys(userToInvite)[0] === cleanedEmail);
    const isSelf = user.user?.email === cleanedEmail;
    const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // should use joi
    const isInvalidEmail = !emailReg.test(cleanedEmail);
    const validationError = isInvalidEmail ? 'Invalid email' : isSelf ? 'Can\'t invite self' : isDuplicate ? 'Email already in list' : '';
    if (validationError) {
      pushToast({
        type: ToastTypesEnum.Error,
        message: validationError,
      });
      return;
    }

    setUsersToInvite([...usersToInvite, { [cleanedEmail]: userRole.toUpperCase() as TeamRolesEnum }]);
    setInvitedUserEmail('');
    const defaultMemberRole: TeamRolesEnum = (TeamRolesEnum.Member.charAt(0)?.toUpperCase() + TeamRolesEnum.Member.slice(1).toLowerCase()) as TeamRolesEnum;
    setInvitedUserRole(defaultMemberRole);
  }, [usersToInvite]);

  const errorHandler = (): ICreateTeamError => {
    const errorConfig: ICreateTeamError = {
      message: null,
      errors: false,
    };
    if (!teamName?.length) {
      errorConfig.message = CreateTeamErrorsEnum.MissingTeamName;
      errorConfig.errors = true;
    }
    if (invitedUserEmail?.length > 0) {
      errorConfig.message = CreateTeamErrorsEnum.ForgotUser;
      errorConfig.errors = true;
    }
    return errorConfig;
  };

  const handleCreateTeam = (teamObject: ICreateTeamsParams) => {
    const errors = errorHandler();
    if (errors.errors) {
      pushToast({
        type: ToastTypesEnum.Error,
        message: errors.message,
      });
      return;
    }
    handleCreateTeamMutation(teamObject);
  };

  const handleCreateTeamSuccess = () => {
    pushToast({
      type: ToastTypesEnum.Notification,
      message: 'Team successfully created',
    });
    refetchTeams();
    popModal();
  };

  const handleCreateTeamError = (error) => {
    pushToast({
      type: ToastTypesEnum.Error,
      message: error,
    });
  };
  return (
    <ModalContainer
      data-cy="create-team-modal-container"
    >
      <ModalHeader
        title={establishedTeamData?.name?.length ? 'Edit team' : 'Create team'}
        close={popModal}
        dataCy="create-team-modal-header"
      />
      <ModalContent>
        <Container>
          <Input
            label="Team name *"
            value={teamName}
            placeholder="Enter team name"
            onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => setTeamName(event.target.value)}
            data-cy="team-name-input"
          />
          <Input
            label="Description"
            value={teamDescription}
            placeholder="Enter team description"
            onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => setTeamDescription(event.target.value)}
            data-cy="team-description-input"
          />
          <Text
            type={TextTypesEnum.Bold14}
            color={Colors.Grey900}
          >
            Members
          </Text>
          {
            usersToInvite?.length > 0 && (
              <Flex
                direction="column"
                width="100%"
                dataCy="users-to-invite-container"
              >
                {usersToInvite.map((user: IUsersToInvite, index) => (
                  <Flex
                    width="100%"
                    align="center"
                    justify="space-between"
                    key={index}
                  >
                    <Text>{Object.keys(user)[0]}</Text>
                    <>
                      <Icon
                        icon={Icons.TimesRegular}
                        onClick={() => {
                          // This would be simpler if it was just a regular json object
                          const newUsers = [...usersToInvite.filter((curUser) => !(Object.keys(curUser)[0] === Object.keys(user)[0]))];
                          setUsersToInvite(newUsers);
                        }}
                      />
                    </>
                  </Flex>
                ))}
              </Flex>
            )
          }
          <Input
            label="Member email"
            placeholder="Enter member email"
            value={invitedUserEmail}
            onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => setInvitedUserEmail(event.target.value)}
            data-cy="team-member-input"
          />
          <Dropdown
            label="Member role"
            placeholder="Select member role"
            items={Object.keys(TeamRolesEnum).filter((role: TeamRolesEnum) => TeamRolesEnum[role] !== TeamRolesEnum.Owner && TeamRolesEnum[role] !== TeamRolesEnum.Pending).map((role: TeamRolesEnum) => ({
              name: TeamRolesEnum[role].charAt(0)?.toUpperCase() + TeamRolesEnum[role].slice(1).toLowerCase(),
              value: TeamRolesEnum[role],
              active: invitedUserRole === TeamRolesEnum[role],
            }))}
            value={invitedUserRole.charAt(0)?.toUpperCase() + invitedUserRole.slice(1).toLowerCase()}
            onChange={(value: any) => setInvitedUserRole(value)}
            data-cy="team-member-role-dropdown"
          />
          <Flex
            width="100%"
            justify="flex-end"
          >
            <Button
              text="Add member"
              size={ButtonSizesEnum.Small}
              type={ButtonTypesEnum.Pale}
              onClick={() => handleAddMemberCallback(invitedUserEmail, invitedUserRole)}
              data-cy="add-member-button"
            />
          </Flex>
          <Text
            type={TextTypesEnum.Bold14}
            color={Colors.Grey900}
          >
            Listings
          </Text>
          <Dropdown
            label="Listings"
            placeholder="Add listings to team"
            items={userListings?.map((listing: IListingGraphQL) => ({
              name: ListingUtil.name(listing, { allButAddress1: true }),
              value: listing,
            }))}
            value="Add listings to team"
            onChange={(value: IListingGraphQL) => {
              if (!selectedListings.includes(value)) {
                setSelectedListings([...selectedListings, value]);
              }
            }}
            data-cy="add-listings-dropdown"
          />
          {
            Boolean(selectedListings?.length) && (
              <Flex
                direction="column"
                width="100%"
                dataCy="selected-listings-container"
              >
                {selectedListings?.map((listing: IListingGraphQL, index) => (
                  <Flex
                    width="100%"
                    justify="space-between"
                    key={index}
                  >
                    <Text
                      type={TextTypesEnum.Regular14}
                      color={Colors.Brand700 || Colors.Blurple700}
                    >
                      {ListingUtil.name(listing, { allButAddress1: true })}
                    </Text>
                    <Icon
                      icon={Icons.TimesRegular}
                      color={Colors.Brand700 || Colors.Blurple700}
                      onClick={() => setSelectedListings(selectedListings.filter((i) => i !== listing))}
                    />
                  </Flex>
                ))}
              </Flex>
            )
          }
          <Flex
            width="100%"
            gap="12px"
            justify="flex-end"
          >
            <Button
              text="Cancel"
              size={ButtonSizesEnum.Small}
              type={ButtonTypesEnum.Outline}
              onClick={popModal}
              data-cy="modal-cancel-button"
            />
            <Button
              text="Create team"
              size={ButtonSizesEnum.Small}
              type={ButtonTypesEnum.Primary}
              isLoading={isLoading}
              onClick={() => {
                const listingIds = selectedListings.map((listing) => listing._id);
                handleCreateTeam({
                  name: teamName,
                  createdByUserId: user.userId,
                  description: teamDescription,
                  users: usersToInvite,
                  listingIds,
                });
              }}
              data-cy="modal-create-team-button"
            />
          </Flex>
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default CreateTeamModal;
