import { gql } from '@apollo/client';

const UPDATE_PRIVATE_EVENT = gql`
  mutation UpdatePrivateEvent($params: UpdatePrivateEventParams!) {
    updatePrivateEvent(params: $params) {
      _id
    }
  }
`;

export default UPDATE_PRIVATE_EVENT;
