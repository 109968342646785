import React from 'react';
import styled from '@emotion/styled';
import Icon, { IconsType } from '../elements/Icon';
import Text, { TextTypesEnum } from '../elements/Text';
import Colors from '../styles/Colors';
import Button from '../elements/Button';
import { media, useMobileMedia, useTabletMedia } from '../utils/MediaQuery';
import Auth from '../utils/Auth';

export const SurfaceContainer = styled.div<{ isNotDesktop: boolean }>`
  position: fixed;
  width: ${({ isNotDesktop }) => (isNotDesktop ? '100%' : '440px')};
  min-height: 312px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${media.desktop} {
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }
`;

const SurfaceContent = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  background: rgba(229, 62, 62, 0.05);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 24px 0;
`;

export type PageErrorProps = {
  icon: IconsType;
  warning?: boolean;
  header: string;
  body: React.ReactNode;
  buttonText?: string;
  onClick?: () => void;
};

const PageError: React.FC<PageErrorProps> = ({
  icon,
  header,
  body,
  buttonText = 'Go home',
  warning = true,
  onClick,
}) => {
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  const handleClick = () => {
    if (onClick) onClick();
    else {
      /**
       * If the user sees this page
       * they likely need to login.
       * Clear token, redirect to /login
       */
      Auth.logout();
    }
  };

  return (
    <SurfaceContainer isNotDesktop={isMobile || isTablet}>
      <SurfaceContent>
        <IconContainer>
          <Icon icon={icon} size={24} color={warning ? Colors.Red500 : Colors.Grey700} margin="24px 0" />
        </IconContainer>
        <Text align="center" type={TextTypesEnum.Bold24} margin="0 0 8px 0">{header}</Text>
        <Text align="center" type={TextTypesEnum.Regular16} margin="8px 0px">{body}</Text>
        <Button text={buttonText} onClick={handleClick} isFullWidth margin="16px 0 0 0" />
      </SurfaceContent>
    </SurfaceContainer>
  );
};

export default PageError;
