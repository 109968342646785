import Joi from 'joi';
import ListingAssetClassEnum from '../enums/ListingAssetClassEnum';
import ListingPropertyTypeEnum from '../enums/ListingPropertyTypeEnum';
import JoiUtil from '../utils/JoiUtil';
import addressSchema from './address.joi';
import { authorizedDataSchema } from './authentication.joi';
import { miscellaneousListingQueryParamsSchema } from './listing.joi';

export const savedSearch = {
  _id: () => Joi.string,
  organizationId: () => Joi.string,
  userId: () => Joi.string,
  name: () => Joi.string,
  notifications: () => Joi.boolean,
  query: () => savedSearchQuery,
  address: () => addressSchema,
};

const minMax = Joi.object().keys({
  includeUndefined: Joi.boolean().optional().allow(null),
  min: Joi.number().optional().allow(null),
  max: Joi.number().optional().allow(null),
});

// const mapLatLng = Joi.object().keys({
//   lat: Joi.number().required(),
//   lng: Joi.number().required(),
// });

// const mapBounds = Joi.object().keys({
//   ne: mapLatLng.optional().allow(null),
//   nw: mapLatLng.optional().allow(null),
//   se: mapLatLng.optional().allow(null),
//   sw: mapLatLng.optional().allow(null),
// });

const savedSearchQuery = Joi.object().keys({
  queryType: Joi.string().required().allow(null),
  assetClasses: Joi.array().items(Joi.string().valid(...Object.values(ListingAssetClassEnum))).optional().allow(null),
  propertyTypes: Joi.array().items(Joi.string().valid(...Object.values(ListingPropertyTypeEnum))).optional().allow(null),
  price: minMax.optional().allow(null),
  buildingSize: minMax.optional().allow(null),
  capRate: minMax.optional().allow(null),
  occupancyRate: minMax.optional().allow(null),
  sortBy: Joi.string().optional().allow(null).allow(''),
  listingIds: Joi.array().items(Joi.string()).optional().allow(null),
  bounds: Joi.any().allow(null),
  keywords: Joi.array().items(Joi.string()).optional(),
  state: Joi.string().optional().allow(null).allow(''),
  miscellaneous: miscellaneousListingQueryParamsSchema.optional().allow(null),
  address: addressSchema.required().allow(null).allow(''),
});

/** ******************************************************************************
*  Create Saved Search
******************************************************************************* */
export const createSavedSearchParamsSchema = Joi.object().keys({
  _id: Joi.string().optional().allow(null).allow(''),
  name: Joi.string().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  notifications: Joi.boolean().required(),
  query: savedSearchQuery,
  address: addressSchema.optional().allow(null).allow(''),
});

export const createSavedSearchRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: createSavedSearchParamsSchema,
});

/** ******************************************************************************
*  List Saved Searches
******************************************************************************* */

export const listSavedSearchesParamsSchema = Joi.object().keys({});

export const listSavedSearchesRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: listSavedSearchesParamsSchema,
});

/** ******************************************************************************
*  Update Saved Searches
******************************************************************************* */
export const updateSavedSearchParamsSchema = Joi.object().keys({
  _id: Joi.string().required(),
  name: Joi.string().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  notifications: Joi.boolean().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  query: savedSearchQuery,
  address: addressSchema.optional().allow(null).allow(''),
});

export const updateSavedSearchRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: updateSavedSearchParamsSchema,
});
