import gql from 'graphql-tag';

const mutation = gql`
  mutation Register($params: RegisterUserParams!) {
    register(params: $params) {
      user {
        _id
        firstName
        lastName
        email
        meta {
          createdAt
        }
      }
      token
      hubspotToken
    }
  }
`;

export default mutation;
