enum ListingCardTypesEnum {
  DefaultSearch = 'DefaultSearch',
  DefaultMap = 'DefaultMap',
  DefaultFavorited = 'DefaultFavorited',
  DefaultFavoritedDashboard = 'DefaultFavoritedDashboard',
  Personal = 'Personal',
  Dashboard = 'Dashboard',
  Uploaded = 'Uploaded',
  Private = 'Private',
}

export default ListingCardTypesEnum;
