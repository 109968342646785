import axios from 'axios';
import { config } from '@biproxi/env';

const createChargebeeCustomerMutation = async (userData: object) => {
  const res = await axios.post('/api/billing/create-customer', userData, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const customer = await res.data;
  return customer;
};

export default createChargebeeCustomerMutation;
