import React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Icon, { Icons } from '../elements/Icon';
import GenericCard from '../elements/GenericCard';
import Anchor from './Anchor';
import BoxShadows from '../styles/BoxShadows';
import PopperPlacementTypes from '../models/enums/PopperPlacementTypes';
import Loader, { LoaderSizes } from '../elements/Loader';
import NoContent from './NoContent';
import Flex from '../elements/Flex';
import NotificationsTable, { NotificationTableTypesEnum } from './NotificationTable';
import { useMobileMedia } from '../utils/MediaQuery';

const Container = styled.div`
  border-radius: 8px;
  overflow: hidden;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.Grey300};
  height: 56px;
  padding: 0px 16px;
  position: sticky;
  top: 0;
  background: ${Colors.White};
  z-index: 1;
`;

const LoaderContainer = styled.div`
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type NotificationsListDropdownProps = {
  anchorRef: any;
  data: any,
  loading: any,
  fetchMore: () => void,
};

const NotificationsListDropdown: React.FC<NotificationsListDropdownProps> = ({
  anchorRef,
  data,
  loading,
  fetchMore,
}) => {
  /** Hooks */
  const navigate = useNavigate();
  const isMobile = useMobileMedia();

  /** Render */
  const notifications = data?.notifications?.data ?? [];
  return (
    <Anchor
      anchorRef={anchorRef}
      placement={isMobile ? PopperPlacementTypes.Bottom : PopperPlacementTypes.BottomEnd}
    >
      <GenericCard
        width="320px"
        boxShadow={BoxShadows.Hard}
        borderRadius="8px"
        padding="0px"
      >
        <Container>
          <Header>
            <Text
              type={TextTypesEnum.Bold18}
            >
              Notifications
            </Text>
            <Flex align="center">
              <Text
                type={TextTypesEnum.Medium12}
                color={Colors.Brand700 || Colors.Blurple700}
                onClick={() => navigate('/app/dashboard/notifications')}
                margin="0px 16px 0px 0px"
              >
                View all
              </Text>
              <Icon
                onClick={() => null}
                icon={Icons.TimesRegular}
                color={Colors.Grey400}
                hoverColor={Colors.Red500}
                size={18}
              />
            </Flex>
          </Header>
          {loading ? (
            <LoaderContainer>
              <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
            </LoaderContainer>
          ) : (
            <>
              {notifications?.length > 0 ? (
                <NotificationsTable
                  notifications={notifications}
                  type={NotificationTableTypesEnum.Dropdown}
                  fetchMore={fetchMore}
                  totalCount={data?.notifications?.info?.totalCount}
                />
              ) : (
                <NoContent
                  height="176px"
                  icon={Icons.BellLight}
                  text="No notifications yet. Notifications you have received will appear here."
                  padding="0 20px"
                />
              )}
            </>
          )}
        </Container>
      </GenericCard>
    </Anchor>
  );
};

export default NotificationsListDropdown;
