import { logger } from '@biproxi/logger';
import React from 'react';
import { useMutation } from '@apollo/client';
import * as UserJoi from '@biproxi/models/joi/user.joi';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import styled from '@emotion/styled';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Flex from '../../elements/Flex';
import AUTHENTICATED_PASSWORD_CHANGE from '../../graphql/mutations/authenticatedPasswordChange.mutation';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import Input, { InputTypesEnum } from '../../elements/Input';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';

const Form = styled.form``;

const Spacer = styled.div`
  height: 8px;
`;

export type ChangePasswordModalProps = {};

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = () => {
/** Hooks */
  const formParams: UseFormParams = {
    fields: {
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    fieldOrder: ['/password', '/newPassword', 'newPasswordConfirm'],
    schema: UserJoi.authenticatedPasswordChangeParamsSchema,
  };

  const {
    controllers: {
      password,
      newPassword,
      newPasswordConfirm,
    },
    setFieldErrors,
    isValid,
  } = useForm(formParams);

  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const handleSubmit = async (event?: React.FormEvent) => {
    if (event) event.preventDefault();
    if (!isValid()) return;
    if (newPassword.value() !== newPasswordConfirm.value()) {
      newPasswordConfirm.setError('Passwords do not match');
      return;
    }

    await authenticatedPasswordChangeMutation({
      variables: {
        params: {
          password: password.value().toString(),
          newPassword: newPassword.value().toString(),
        },
      },
    });

    popModal();
  };

  /** GraphQL */
  const [authenticatedPasswordChangeMutation, { loading }] = useMutation(AUTHENTICATED_PASSWORD_CHANGE, {
    onCompleted: async () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Password successfully updated',
      });
    },
    onError: async (error) => {
      logger.error('AUTHENTICATED_PASSWORD_CHANGE error', error);
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        password.setError(message);
      }
    },
  });

  /** Render */
  return (
    <ModalContainer data-cy="password-modal-container" width="400px">
      <ModalHeader title="Change password" close={popModal} />
      <ModalContent>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Input
            data-cy="current-password-input"
            label="Current password"
            placeholder="Enter your current password"
            value={password.value()}
            onChange={password.setValue}
            ref={password.ref}
            error={password.error()}
            inputType={InputTypesEnum.Password}
          />
          <Spacer />
          <Input
            data-cy="new-password-input"
            label="New password"
            placeholder="Enter a new password"
            value={newPassword.value()}
            onChange={newPassword.setValue}
            ref={newPassword.ref}
            error={newPassword.error()}
            inputType={InputTypesEnum.Password}
          />
          <Spacer />
          <Input
            data-cy="confirm-new-password-input"
            label="Confirm new password"
            placeholder="Confirm new password"
            value={newPasswordConfirm.value()}
            onChange={newPasswordConfirm.setValue}
            ref={newPasswordConfirm.ref}
            error={newPasswordConfirm.error()}
            inputType={InputTypesEnum.Password}
          />
          <Spacer />
          <Flex justify="flex-end" width="100%" margin="24px 0px">
            <Button
              data-cy="cancel-password-button"
              text="Cancel"
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Medium}
              onClick={() => popModal()}
              margin="0px 8px 0px 0px"
              htmlType="button"
            />
            <Button
              data-cy="last-change-password-button"
              text="Change password"
              isLoading={loading}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Medium}
            />
          </Flex>
        </Form>
      </ModalContent>
    </ModalContainer>
  );
};

export default ChangePasswordModal;
