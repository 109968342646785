import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import AnalyticsEventTypesEnum from '@biproxi/models/enums/AnalyticsEventTypesEnum';
import Colors from '../../styles/Colors';
import Divider from '../../elements/Divider';
import { media } from '../../utils/MediaQuery';
import Flex from '../../elements/Flex';
import useListing from '../../hooks/useListing.hook';
import Loader, { LoaderSizes } from '../../elements/Loader';
import Text, { TextTypesEnum } from '../../elements/Text';
import PDPVaultFolder from '../pdp/PDPVaultFolder';
import Icon, { Icons } from '../../elements/Icon';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from './Modal';
import useUser from '../../hooks/useUser.hook';
import NoContent from '../NoContent';
import LeadDilligenceAccess from '../lead/LeadDilligenceAccess';

const Container = styled.div`
  width: 100%;
  height: 65vh;
  overflow: auto;
  padding-right: 24px;
  position: relative;

  ${media.mobile} {
    height: calc(100vh - 135px);
  }
`;

const Docs = styled.div`
  position: relative;
`;

const File = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 4px;
  background-color: ${Colors.White};
  transition: all 0.1s;
  border-radius: 4px;

  &:hover{
    cursor: pointer;
    background-color: ${Colors.Grey200}
  }
`;

const LockedContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 1;
`;

const Locked = styled.div`
  background-color: ${Colors.Black75};
  height: 32px;
  border-radius: 8px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
`;

const SectionTitle = styled.div`
  white-space: nowrap;
  margin-right: 16px;
`;

const Section = styled.div``;

export type LeadModalFilesProps = {
  lead: ILeadGraphQL;
  buyer?: boolean;
};

const LeadModalFiles: React.FC<LeadModalFilesProps> = ({
  lead,
  buyer, // check for if buyer lead modal version
}) => {
  /** Hooks */
  const { listing } = useListing(lead?.listingId);
  const { user } = useUser();

  /* Actions */
  const dispatch = useAppDispatch();
  const pushUnlockDocumentsModal = () => dispatch(AppActions.pushModal({
    type: ModalTypesEnum.UnlockDocuments,
    props: {
      listing,
    },
  }));

  const viewLOI = (fileId, files) => dispatch(
    AppActions.previewFiles({
      fileId,
      files,
      track: false,
    }),
  );

  const viewedDocsUniqueIds = Array.from(new Set(lead.analyticsEvents.filter((event) => event.eventType === AnalyticsEventTypesEnum.ViewedDueDiligenceDocument).map((event) => event.fileId)));
  const downloadedDocsUniqueIds = Array.from(new Set(lead.analyticsEvents.filter((event) => event.eventType === AnalyticsEventTypesEnum.DownloadedDueDiligenceDocument).map((event) => event.fileId)));
  const hasDownloadedVault = Boolean(lead.analyticsEvents.find((event) => event.eventType === AnalyticsEventTypesEnum.DownloadedDueDiligenceVault));

  /** Render */
  return (
    <Container data-cy="leads-files-container">
      {!listing || !user || !lead ? (
        <Flex height="100%" align="center" justify="center">
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </Flex>
      ) : (
        <>
          {(() => {
            const hasUserSignedCA = ListingUtil.hasUserSignedCA(listing, user?._id);
            const hasDD = ListingUtil.vaultFiles(listing).length > 0;
            const hasLOI = Boolean(lead?.offers?.find((offer) => offer?.loiFileIds?.length > 0));
            const hasFiles = hasDD || hasLOI;
            const hasLevel2 = ListingUtil.containsLevel2(listing);

            if (!hasFiles) {
              return (
                <NoContent
                  height="176px"
                  icon={Icons.FileSolid}
                  text="No files to view"
                />
              );
            }

            return (
              <>
                {hasLOI && (
                  <Section>
                    <Flex align="center" margin="0px 0px 16px">
                      <SectionTitle>
                        <Text type={TextTypesEnum.Regular16} color={Colors.Grey500}>
                          LOI files
                        </Text>
                      </SectionTitle>
                      <Divider color={Colors.Grey200} />
                    </Flex>
                    {lead?.offers?.map((offer) => (
                      <React.Fragment key={offer._id}>
                        {offer?.loiFiles?.map((file) => (
                          <File key={file._id} onClick={() => viewLOI(file._id, offer.loiFiles)}>
                            <Icon
                              icon={Icons.FileSolid}
                              size={16}
                              color={Colors.Black}
                              margin="0 8px 0 0"
                            />
                            <Text type={TextTypesEnum.Regular16} color={Colors.Black}>
                              {file.name}
                            </Text>
                          </File>
                        ))}
                      </React.Fragment>
                    ))}
                  </Section>
                )}
                {hasDD && (
                  <>
                    {(hasLevel2 && !buyer) && (
                      <>
                        <Flex align="center" margin="0 0 16px 0">
                          <SectionTitle>
                            <Text type={TextTypesEnum.Regular16} color={Colors.Grey500}>
                              Diligence Access
                            </Text>
                          </SectionTitle>
                          <Divider color={Colors.Grey200} />
                        </Flex>
                        <Flex margin="0 0 16px 0">
                          <LeadDilligenceAccess
                            lead={lead}
                          />
                        </Flex>
                      </>
                    )}
                    <Section>
                      <Flex align="center" margin={hasLOI ? '16px 0px' : '0px 0px 16px'}>
                        <SectionTitle>
                          <Text type={TextTypesEnum.Regular16} color={Colors.Grey300}>
                            Due diligence vault
                          </Text>
                        </SectionTitle>
                      </Flex>
                      <Divider color={Colors.Grey100} />
                      <Docs onClick={() => (hasUserSignedCA ? null : pushUnlockDocumentsModal())}>
                        {!hasUserSignedCA && (
                        <LockedContainer>
                          <Locked>
                            <Icon icon={Icons.LockAltSolid} color={Colors.White} margin="0 8px 0 0" size={14} />
                            <Text type={TextTypesEnum.Bold14} color={Colors.White}>Click to unlock</Text>
                          </Locked>
                        </LockedContainer>
                        )}
                        {listing?.vault?.folders.map((folder, index) => (
                          <PDPVaultFolder
                            key={index}
                            listing={listing}
                            folder={folder}
                            isActive={hasUserSignedCA}
                            hasLevel2Access
                            filesViewedIds={buyer ? [] : viewedDocsUniqueIds}
                            filesDownloadedIds={buyer ? [] : downloadedDocsUniqueIds}
                          />
                        ))}
                        {hasDownloadedVault && !buyer && (
                          <Text type={TextTypesEnum.Regular16} color={Colors.Grey400} align="center" margin="16px 0 0">
                            This lead has downloaded the entire due diligence vault.
                          </Text>
                        )}
                      </Docs>
                    </Section>
                  </>
                )}
              </>
            );
          })()}
        </>
      )}
    </Container>
  );
};

export default LeadModalFiles;
