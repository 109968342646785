import { logger } from '@biproxi/logger';
import {
  all, takeLatest, call, put, select,
} from 'redux-saga/effects';
import {
  PayloadAction,
} from '@reduxjs/toolkit';
import { config } from '@biproxi/env';
import * as IUserService from '@biproxi/models/services/IUserService';
import UserUtil from '@biproxi/models/utils/UserUtil';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import AuthenticationStrategyEnum from '@biproxi/models/enums/AuthenticationStrategyEnum';
import axios from 'axios';
// import { ICreateUserResponse, INeo4jUser } from '@biproxi/models/services/INeo4jService';
// import TimeUtil from '@biproxi/models/utils/TimeUtil';
import StatusCodeEnum from '@biproxi/models/enums/StatusCodeEnum';
import { MutateAuraUserHandler } from '@biproxi/neo4j-aura';
import {
  UserActionTypesEnum, UserActionPayloadTypes, UserActions,
} from '../user.redux';
import Auth from '../../utils/Auth';
import { initializeApollo } from '../../graphql/client';
import GET_USER from '../../graphql/queries/user.query';
import { AppActions, AppSelectors } from '../app.redux';
import { ModalTypesEnum } from '../../components/modal/Modal';
import * as UrlUtil from '../../utils/UrlUtil';
import UPDATE_OAUTH_PROVIDERS from '../../graphql/mutations/updateUserOAuthProviders.mutation';
import { AppState } from '../store';

export default function* userSaga() {
  try {
    yield all([
      completeAuthenticationWatch(),
      startOAuthFlowWatch(),
      cancelOAuthFlowWatch(),
    ]);
  } catch (e) {
    logger.error('userSaga() error', e);
  }
}

/** ******************************************************************************
 *  Complete Authentication
 ****************************************************************************** */

function* completeAuthenticationWatch() {
  yield takeLatest(
    UserActionTypesEnum.CompleteAuthentication,
    completeAuthenticationSaga,
  );
}

function* completeAuthenticationSaga(
  action: PayloadAction<UserActionPayloadTypes['CompleteAuthenticationPayloadType']>,
) {
  /* Set the token */
  if (action?.payload?.token) {
    Auth.setToken(action.payload.token, action.payload.hubspotToken);
  }

  const client = initializeApollo();

  type Data = {
    user: IUserGraphQL;
  }

  const { data: { user } } = yield call(async () => {
    /**
     * Clear client cache and force reconnect the websocket
     * now that we have the token.
     * See the comment in client.ts
     */
    client.stop();
    await client.resetStore();

    /* Refetch user via GraphQL */
    return client.query<Data>({ query: GET_USER });
  });

  yield put(UserActions.cacheUser({ user }));

  const updateUserInAura = async (userId: string, userEmail: string) => {
    const auraUserMutator = new MutateAuraUserHandler(user);
    const auraUserData = await auraUserMutator.userMutationFlow(userId, userEmail);
    if (auraUserData?.status === StatusCodeEnum.OK) {
      logger.info(`User with id: ${user?._id} was succesfully updated in Aura`);
    } else {
      logger.warn(`There was an issue updating user with id: ${user?._id} in Aura`);
    }
  };

  yield updateUserInAura(user?._id, user?.email);

  /** self-heal: if user not present in Chargebee records, add them. */
  let userInChargebee;
  yield call(async () => {
    try {
      const res = await axios(`/api/billing/customer?id=${user._id}`, {
        headers: {
          Authorization: config.CHARGEBEE_KEY,
        },
      });
      userInChargebee = await res?.data?.customer;
    } catch (e) {
      logger.error('api/billing/customer error', e);
    }
  });

  const selfHealEnterUserIntoChargebee = async () => {
    const res = await axios.post('/api/billing/create-customer', {
      userId: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phoneNumber,
    }, {
      headers: {
        Authorization: config.CHARGEBEE_KEY,
      },
    });
    const customer = await res?.data?.customer;
    return customer;
  };

  if (!userInChargebee) {
    yield call(async () => {
      await selfHealEnterUserIntoChargebee();
    });
  }

  let currentSubscription;
  const queryUserCurrentSubscription = async (userId: string) => {
    const res = await axios(`/api/billing/subscription?id=${userId}`, {
      headers: {
        Authorization: config.CHARGEBEE_KEY,
      },
    });
    const currentSubscription = res?.data ?? {};
    return currentSubscription;
  };

  yield call(async () => {
    currentSubscription = await queryUserCurrentSubscription(user?._id);
  });

  yield put(UserActions.setUserSubscription({ currentSubscription }));
  /**
   * Finish requireAuthentication flow if needed
   */
  const state = yield select((state: AppState) => state);
  const requireAuthentication = AppSelectors.requireAuthentication(state);

  if (requireAuthentication?.isCallbackValid?.(state) ?? true) {
    requireAuthentication?.callback?.();
    yield put(AppActions.setRequireAuthentication());
  }

  /** Identify the user to Segment */
  window.analytics.identify(user._id, {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phoneNumber,
    company: {
      id: user?.organization?._id,
    },
  });
}

/** ******************************************************************************
 *  Start OAuth Flow
 ****************************************************************************** */

function* startOAuthFlowWatch() {
  yield takeLatest(
    UserActionTypesEnum.StartOAuthFlow,
    startOAuthFlowSaga,
  );
}

function* startOAuthFlowSaga(
  action: PayloadAction<UserActionPayloadTypes['StartOAuthFlowPayloadType']>,
) {
  const { payload: { strategy, isStandalone } } = action;
  const response: IUserService.TOAuthAuthenticateUserResponse = yield call(async () => Auth.startOAuthFlow(strategy));

  const {
    user,
    token,
    hubspotToken,
    error,
  } = response;

  if (error) {
    yield put(UserActions.finishOAuthFlow({ error }));
    return;
  }

  /* Set the token */
  Auth.setToken(token, hubspotToken);

  /* Move to the next step in the flow */
  if (UserUtil.phoneIsVerified(user)) {
    yield put(UserActions.completeAuthentication());
    if (isStandalone) {
      yield put(UserActions.setAuthRedirectLoading({ loading: true }));
      const { query } = UrlUtil.parse(window.location.toString());
      const { to } = query;
      if (to) {
        window?.open(to as string);
      }
    } else {
      yield put(AppActions.popModal());
    }
  } else {
    yield put(AppActions.replaceModal({
      type: ModalTypesEnum.CompleteRegister,
      props: {
        isStandalone,
      },
    }));
  }

  yield put(UserActions.finishOAuthFlow(null));

  /** if user successfully oAuths from either registration or login page and they did
   * not have the provider recognized in their oAuthProviders information,
   * update this information on the user and cache the user
   */
  const isGoogle = Boolean(strategy === AuthenticationStrategyEnum.GoogleLogin || strategy === AuthenticationStrategyEnum.GoogleRegister);
  const isLinkedin = Boolean(strategy === AuthenticationStrategyEnum.LinkedInLogin || strategy === AuthenticationStrategyEnum.LinkedInRegister);
  const needGoogleUpdate = Boolean(isGoogle && !user.oAuthProviders?.google);
  const needLinkedinUpdate = Boolean(isLinkedin && !user.oAuthProviders?.linkedin);

  if (needGoogleUpdate || needLinkedinUpdate) {
    const updatedUserOAuthProviders = {
      google: needGoogleUpdate ? true : user.oAuthProviders?.google,
      linkedin: needLinkedinUpdate ? true : user.oAuthProviders?.linkedin,
    };
    const updatedUser = {
      ...user,
      oAuthProviders: updatedUserOAuthProviders,
    };

    // update user with new oAuthProviders information in backend
    const client = initializeApollo();

    try {
      yield call(async () => {
        await client.mutate<any, any>({
          mutation: UPDATE_OAUTH_PROVIDERS,
          variables: {
            params: {
              oAuthProviders: updatedUserOAuthProviders,
            },
          },
        });
      });
    } catch (e) {
      logger.error('startOAuthFlowSaga error', e);
    }
    // cache user with new oAuthProviders
    yield put(UserActions.cacheUser({ user: updatedUser }));
  } else {
    /* Otherwise, cache the user as we normally would have (no new oAuth info) */
    yield put(UserActions.cacheUser({ user }));
  }
}

/** ******************************************************************************
 *  Cancel OAuth Flow
 ****************************************************************************** */

function* cancelOAuthFlowWatch() {
  yield takeLatest(
    UserActionTypesEnum.CancelOAuthFlow,
    cancelOAuthFlowSaga,
  );
}

function* cancelOAuthFlowSaga(
  _action: PayloadAction<UserActionPayloadTypes['CancelOAuthFlowPayloadType']>,
) {
  Auth.cancelOAuthFlow();

  yield put(UserActions.finishOAuthFlow(null));
}
