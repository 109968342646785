import gql from 'graphql-tag';
import ListingUploadFragments from '../fragments/listingUpload.fragments';

const UPDATE_LISTING_UPLOAD = gql`
    mutation UpdateListingUpload($params: ListingUploadParams) {
        updateListingUpload(params: $params) {
            ...ListingUploadFields
            ...ListingUploadMedia
            ...ListingUploadMeta
            ...ListingUploadUser
            ...ListingUploadAdminInfo
        }
    }
    ${ListingUploadFragments.fields}
    ${ListingUploadFragments.media}
    ${ListingUploadFragments.meta}
    ${ListingUploadFragments.user}
    ${ListingUploadFragments.adminInfo}
`;

export default UPDATE_LISTING_UPLOAD;
