import { logger } from '@biproxi/logger';
import React from 'react';
import PageError from '../components/PageError';
import Icons from '../elements/Icons';
import LinkText from '../elements/LinkText';
import HubSpotSDK from '../utils/HubSpotSDK';

type State = {
  hasError: boolean;
}

type Props = {
  children: React.ReactNode;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(params) {
    super(params);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(e) {
    logger.error('ErrorBoundary componentDidCatch() error', e);
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <PageError
          icon={Icons.ExclamationTriangleSolid}
          header="Something went wrong"
          body={(
            <>
              Biproxi has encountered an unexpected error. Please refresh the page and try again, or&nbsp;
              <LinkText onClick={() => {
                if (!HubSpotSDK.isInitialized()) {
                  HubSpotSDK.initialize();
                }

                HubSpotSDK.openChat();
              }}
              >
                contact support
              </LinkText>
              .
            </>
)}
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
