import React from 'react';
import {
  Editable, withReact, Slate,
} from 'slate-react';
import {
  createEditor, Text,
} from 'slate';
import escapeHtml from 'escape-html';
import { jsx } from 'slate-hyperscript';
import styled from '@emotion/styled';
import * as Polished from 'polished';
import Label from '../elements/Label';
import Colors from '../styles/Colors';
import Flex from '../elements/Flex';
import TextComponent, { TextTypesEnum } from '../elements/Text';

type EditorProps = {
  margin?: string;
  error?: string;
  focused?: boolean;
  hovered?: boolean;
}

const EditorContainer = styled(Editable)<EditorProps>`
  border-radius: 4px;
  background: ${Colors.White};
  margin: ${(props) => props.margin};
  min-height: 160px !important;
  box-sizing: border-box;
  max-height: 400px;
  width: 100%;
  line-height: 160%;
  padding: 12px 16px;
  overflow: auto;
  font-size: 1.6rem;
  color: ${Colors.Grey900};
  font-weight: 400;
  transition: all 0.2s;

  border: 1px solid ${(props) => {
    if (props.error) return Colors.Red500;
    if (props.focused) return Colors.Brand700 || Colors.Blurple700;
    if (props.hovered) return Colors.Grey400;
    return Colors.Grey300;
  }};

  box-shadow: ${(props) => {
    if (props.error && props.focused) return `0px 0px 0px 4px ${Polished.rgba(Colors.Red500, 0.10)}`;
    if (props.focused) return `0px 0px 0px 4px ${Polished.rgba(Colors.Brand700 || Colors.Blurple700, 0.10)}`;
    return null;
  }};
`;

function Element({ attributes, children }: any) {
  return <p {...attributes}>{children}</p>;
}

function Leaf({ attributes, children }: any) {
  return <span {...attributes}>{children}</span>;
}

const serialize = (node: any) => {
  if (Text.isText(node)) {
    return escapeHtml(node.text) || ' ';
  }

  const children = node.children.map((n: any) => serialize(n)).join('');

  switch (node.type) {
    case 'paragraph':
      return `<p>${children}</p>`;
    default:
      return children;
  }
};
const deserialize = (el: any) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } if (el.nodeType !== 1) {
    return null;
  }

  const children: any = Array.from(el.childNodes).map(deserialize);
  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    default:
      return el.textContent;
  }
};

type RichTextEditorProps = {
  value: string; // must be valid html string or empty or it will break
  onChange: (html: string) => void;
  label?: string;
  margin?: string;
  subLabel?: string;
  placeholder?: string;
  error?: string;
};

/**
 * This rich text input only supports the <p> tag
 * to conserve line breaks when the input is display.
 */
const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  onChange,
  label,
  margin,
  placeholder,
  error,
}) => {
  const [hovered, setHovered] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const [jsonValue, setJsonValue] = React.useState(!value
    ? [{ type: 'paragraph', children: [{ text: '' }] }]
    : deserialize((new DOMParser().parseFromString(value, 'text/html')).body));
  const renderElement = React.useCallback((props) => <Element {...props} />, []);
  const renderLeaf = React.useCallback((props) => <Leaf {...props} />, []);
  const [editor] = React.useState(withReact(createEditor() as any));

  return (
    <>
      <Flex justify="space-between" align="center">
        {label && <Label text={label} />}
        {error && <TextComponent type={TextTypesEnum.Medium12} color={Colors.Red500} margin="0 0 4px">{error}</TextComponent>}
      </Flex>
      <Slate
        editor={editor}
        value={jsonValue}
        onChange={(jsonValue) => {
          setJsonValue(jsonValue as any);
          const html = jsonValue.reduce((html, node) => html + serialize(node), '');
          onChange(html);
        }}
      >
        <EditorContainer
          onFocus={() => setTimeout(() => {
            setFocused(true);
          }, 0)}
          onBlur={() => setFocused(false)}
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          hovered={hovered}
          focused={focused}
          margin={margin}
          error={error}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder || ''}
          spellCheck
        />
      </Slate>
    </>
  );
};

export default RichTextEditor;
