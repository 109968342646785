import { logger } from '@biproxi/logger';
import ListingAssetClassEnum from '@biproxi/models/enums/ListingAssetClassEnum';
import ListingPropertyTypeEnum from '@biproxi/models/enums/ListingPropertyTypeEnum';
import StatusCodeEnum from '@biproxi/models/enums/StatusCodeEnum';
import IListingParams from '@biproxi/models/interfaces/IListingParams';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import axios from 'axios';

type ListingParams = Required<IListingParams>;

export default class AuraListingMutator {
  private mongoListingParams: Partial<IListingParams>;
  private auraListingParams: INeo4jServiceAPI.ICreateListingParams;

  public setAuraListingParams = (listing: ListingParams): void => {
    this.auraListingParams = {
      _id: listing?._id ?? '',
      name: listing?.name ?? '',
      createdByUserId: listing?.meta?.createdBy || '',
      assetClass: listing?.assetClass ?? ListingAssetClassEnum.Retail,
      propertyType: listing?.propertyType ?? ListingPropertyTypeEnum.SingleTenantRetail,
      description: listing?.description,
      state: listing?.state,
      address1: listing?.address.address1,
      address2: listing?.address?.address2 ?? '',
      cherreId: listing?.address?.cherreId ?? '',
      city: listing?.address?.city ?? '',
      country: listing?.address?.country ?? 'USA',
      googlePlaceId: listing?.address?.googlePlaceId ?? '',
      addressState: listing?.address?.state,
      timeZoneId: listing?.address?.timeZoneId ?? '',
      zip: listing?.address?.zip,
      mediaFileIds: listing?.media?.fileIds ?? [],
      mediaVideoFileId: listing?.media?.video?.fileId ?? '',
      metaCreatedAt: TimeUtil.now(),
      metaCreatedBy: listing?.meta?.createdBy ?? '',
      metaLastPublishedAt: TimeUtil.now(),
      metaLastUpdatedAt: TimeUtil.now(),
      metaLastUpdatedBy: listing?.meta?.lastUpdatedBy ?? listing?.meta?.createdBy,
      isPortfolioListing: listing?.isPortfolioListing ?? false,
      isPrivateListing: listing?.isPrivateListing ?? false,
    };
  };

  public getListing = async (listingId: string): Promise<INeo4jServiceAPI.IGetListingResponse> => {
    const response: INeo4jServiceAPI.IGetListingResponse = {
      data: null as any,
      status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    };

    try {
      const res = await axios.get('/api/neo4j/getListing', { params: { _id: listingId } });
      response.status = res?.data?.data?.listings?.length ? res?.status : StatusCodeEnum.NO_CONTENT;
      response.data = res?.data?.data;
    } catch (error) {
      logger.warn(`There was an issue in the AuraListingMutator constructor: ${error}`);
    }
    return response;
  };

  public deleteListing = async (listingId: string): Promise<INeo4jServiceAPI.IDeleteListingResponse> => {
    const response: INeo4jServiceAPI.IDeleteListingResponse = {
      data: null as any,
      status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    };

    try {
      const res = await axios.get('/api/neo4j/deleteListing', { params: { listingId } });
      response.status = res?.data?.listings?.length ? res?.data?.status : StatusCodeEnum.NO_CONTENT;
      response.data = res?.data?.data;
    } catch (error) {
      logger.warn(`There was an issue in the AuraListingMutator constructor: ${error}`);
    }
    return response;
  };

  public async createListing(createListingParams: INeo4jServiceAPI.ICreateListingParams): Promise<INeo4jServiceAPI.ICreateListingResponse> {
    const response: INeo4jServiceAPI.ICreateListingResponse = {
      data: null,
      status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    };
    try {
      const res = await axios.post('/api/neo4j/createListing', { params: createListingParams });
      response.data = res?.data?.data;
      response.status = res?.data?.status;
    } catch (e) {
      logger.warn(`Failed to create listing in Aura: ${e}`);
    }
    return response;
  }

  public async updateListing(updateListingParams: INeo4jServiceAPI.IUpdateListingParams = this.auraListingParams): Promise<INeo4jServiceAPI.IUpdateListingResponse> {
    const response: INeo4jServiceAPI.IUpdateListingResponse = {
      data: null as any,
      status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    };
    try {
      const res = await axios.post('/api/neo4j/updateListing', { params: updateListingParams });
      response.data = res?.data?.data;
      response.status = res?.data?.status;
    } catch (e) {
      logger.warn(`Failed to update listing in Aura: ${e}`);
    }
    return response;
  }

  public async listingMutationFlow(listingId: string): Promise<INeo4jServiceAPI.IUpdateListingResponse> {
    // First, we want to check to see if the uhhh mf uhhh listing exists in Aura
    const auraListingData = await this.getListing(listingId);
    const response: INeo4jServiceAPI.IUpdateListingResponse = {
      data: null as any,
      status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    };
    if (auraListingData?.status === StatusCodeEnum.NO_CONTENT) {
      const createListingResponse = await this.createListing(this.auraListingParams);
      response.data = createListingResponse?.data?.createListings?.listings?.[0];
      response.status = createListingResponse?.status;
    } if (auraListingData?.status === StatusCodeEnum.OK) {
      const updateListingResponse: any = await this.updateListing(this.auraListingParams);
      response.data = updateListingResponse?.data?.updateListings?.listings?.[0];
      response.status = updateListingResponse?.status;
      return updateListingResponse?.data?.updateListings?.listings?.[0];
    }
    return response;
  }
}
