import { gql } from '@apollo/client';

export const GET_BRAND_NAMES = gql`
query($preview: Boolean!) {
  brandCollection (preview: $preview) {
    items {
      name
    }
  }
}
`;

export const GET_BRANDS = gql`
query($preview: Boolean!) {
  brandCollection (preview: $preview) {
    items {
      name
      favicon {
        url
      }
    }
  }
}
`;

export const GET_BRAND = gql`
query($name: String!, $preview: Boolean!) {
  brandCollection(preview: $preview, limit:1, where: {name: $name}) {
    items {
        name
        email {
          url
        }
        favicon {
          url
        }
        cornerLoginLogo {
          url
        }
        lockedListingImage {
          url
        }
        marketingLoginPhoto {
          url
        }
        navigationDesktopLogo {
          url
        }
        navigationMobileLogo {
          url
        }
        colors
    }
  }
}
`;

export const GET_BRAND_FOR_HOSTNAME = gql`
query($hostname: String!, $preview: Boolean!) {
  hostnameBrandCollection(preview: $preview, limit:1, where: {hostname: $hostname}) {
    items {
      hostname
      brand {
        name
        favicon {
          url
        }
        cornerLoginLogo {
          url
        }
        lockedListingImage {
          url
        }
        marketingLoginPhoto {
          url
        }
        navigationDesktopLogo {
          url
        }
        navigationMobileLogo {
          url
        }
        email {
          url
        }
        colors
        cornerLogoUrl
      }
    }
  }
}
`;

export const GET_HOSTNAMES = gql`
query($preview: Boolean!) {
  hostnameBrandCollection(preview: $preview) {
    items {
      hostname
    }
  }
}
`;
