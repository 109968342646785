import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import MessageAllPrivateEventParticipantsTypeEnum from '@biproxi/models/enums/MessageAllPrivateEventParticipantsTypeEnum';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { media } from '../../utils/MediaQuery';
import Input, { InputTypesEnum } from '../../elements/Input';
import { ModalTypesEnum } from './Modal';
import { ConfirmChangeModalTypesEnum } from './ConfirmChangeModal';
import KeyCodesEnum from '../../models/enums/KeyCodesEnum';
import MESSAGE_ALL_PRIVATE_EVENT_PARTICIPANTS from '../../graphql/mutations/messageAllPrivateEventParticipants.mutation';
import Radio from '../../elements/Radio';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import LIST_CHANNELS from '../../graphql/queries/channels.query';

const Container = styled.div`
  width: 432px;

  ${media.mobile} {
    width: 100%;
  }
`;

const Form = styled.form``;

export type MessageAllBiddersModalProps = {
  privateEvent?: IPrivateEventGraphQL
};

const MessageAllBiddersModal: React.FC<MessageAllBiddersModalProps> = ({
  privateEvent,
}) => {
  /** State */
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState('');
  const [type, setType] = React.useState(MessageAllPrivateEventParticipantsTypeEnum.All);

  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const replaceWithConfirmModal = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Success,
        title: 'Your messages have been sent',
        text: 'You can view all of your in-app messages in the message tab of the dashboard.',
        confirm: (event: React.FormEvent) => {
          event?.preventDefault();
          popModal();
        },
      },
    }),
  );

  /** GraphQL */
  const [messageAllPrivateEventParticipants, { loading }] = useMutation(MESSAGE_ALL_PRIVATE_EVENT_PARTICIPANTS, {
    onCompleted: () => {
      replaceWithConfirmModal();
    },
    onError: (error) => {
      logger.error('MESSAGE_ALL_PRIVATE_EVENT_PARTICIPANTS error', error);
      const { message } = ApolloUtil.parseApolloClientError(error);
      setError(message);
    },
    refetchQueries: [{
      query: LIST_CHANNELS,
      variables: {
        params: {
          pagination: {
            limit: 6,
            cursor: '',
          },
        },
      },
    }],
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!message || loading) return;

    messageAllPrivateEventParticipants({
      variables: {
        params: {
          privateEventId: privateEvent?._id,
          message,
          type,
        },
      },
    });
  };

  /* Render */
  return (
    <ModalContainer>
      <Form onSubmit={(event) => handleSubmit(event)}>
        <ModalHeader title="Message bidders" close={popModal} />
        <ModalContent>
          <Container onSubmit={handleSubmit}>
            <Text
              type={TextTypesEnum.Regular16}
              color={Colors.Grey700}
            >
              For participants that have accepted the event invitation, your message will be sent as both an in-app message and an email. For participants that have not yet accepted the invitation, your message will be sent as an email only.
            </Text>
            <Flex margin="24px 0 24px">
              <Radio
                label="All"
                active={type === MessageAllPrivateEventParticipantsTypeEnum.All}
                margin="0 24px 0 0"
                onClick={() => {
                  setType(MessageAllPrivateEventParticipantsTypeEnum.All);
                }}
              />
              <Radio
                label="Accepted"
                active={type === MessageAllPrivateEventParticipantsTypeEnum.Accepted}
                margin="0 24px 0 0"
                onClick={() => {
                  setType(MessageAllPrivateEventParticipantsTypeEnum.Accepted);
                }}
              />
              <Radio
                label="Pending"
                active={type === MessageAllPrivateEventParticipantsTypeEnum.NotAccepted}
                margin="0 24px 0 0"
                onClick={() => {
                  setType(MessageAllPrivateEventParticipantsTypeEnum.NotAccepted);
                }}
              />
            </Flex>
            <Input
              autoFocus
              placeholder="Enter your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              error={error}
              inputType={InputTypesEnum.TextArea}
              height="120px"
              onKeyPress={(e) => {
                if (e.which === KeyCodesEnum.ENTER && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
            />
          </Container>
        </ModalContent>
        <ModalFooter>
          <Flex justify="flex-end" width="100%">
            <Button
              text="Cancel"
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Medium}
              onClick={() => popModal()}
              margin="0px 8px 0px 0px"
              htmlType="button"
            />
            <Button
              text="Send"
              isLoading={loading}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Medium}
              htmlType="submit"
            />
          </Flex>
        </ModalFooter>
      </Form>
    </ModalContainer>
  );
};

export default MessageAllBiddersModal;
