import { logger } from '@biproxi/logger';
import axios from 'axios';
import { IBobberCsvData, IBobberDashboard, IBobberDashboardHeaderUpdate } from '../models/IBobberInterfaces';

/** Create */
export const createDashboard = async (createDashboardParams: object) => {
  let result;
  try {
    const res = await axios.post('/api/bobber/create-dashboard', createDashboardParams);
    result = res?.data;
  } catch (e) {
    logger.error(e as any);
  }
  return result;
};

/** Update */
export const addDashboardLeadsData = async (_id: string, csvDashboardsArray: IBobberDashboard[]): Promise<IBobberCsvData> => {
  let result;
  try {
    const res = await axios.put('/api/bobber/update-dashboard', csvDashboardsArray, { params: { _id } });
    result = res?.data;
  } catch (e) {
    logger.error(e as any);
  }
  return result;
};
export const updateDashboardHeaderInfo = async (_id: string, dashboardHeaderInfo: IBobberDashboardHeaderUpdate) => {
  let result;
  try {
    const res = await axios.put('/api/bobber/update-header-info', dashboardHeaderInfo, { params: { _id } });
    result = res;
  } catch (e) {
    logger.error(e as any);
  }
  return result;
};
