import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { useQuery } from '@apollo/client';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import IListingsQuery from '@biproxi/models/interfaces/IListingsQuery';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { media } from '../../utils/MediaQuery';
import LIST_LISTINGS from '../../graphql/queries/listings.query';
import ListingCard from '../ListingCard.default';
import ListingCardTypesEnum from '../../models/enums/ListingCardTypesEnum';
import Loader, { LoaderSizes } from '../../elements/Loader';
import Colors from '../../styles/Colors';
import { CustomSpacer } from '../../elements/Flex';
import Link from '../../elements/Link';

const Container = styled.div`
  width: 432px;
  min-height: 300px;
  max-height: 80vh;
  overflow-y: scroll;
  margin-right: -16px;

  ${media.mobile} {
    width: 100%;
    max-height: calc(100vh - 128px);
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 432px;
  min-height: 300px;

  ${media.mobile} {
    width: 100%;
  }
`;

export type BrokerListingsModalProps = {
  brokerUserId: string;
};

const BrokerListingsModal: React.FC<BrokerListingsModalProps> = ({
  brokerUserId,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  /** GraphQL */
  type Data = {
    listings: {
      data: IListingGraphQL[];
      info: {
        totalCount: number;
      }
    }
  }

  type Vars = {
    params: IListingsQuery,
  }

  const { data, loading } = useQuery<Data, Vars>(LIST_LISTINGS, {
    variables: {
      params: {
        query: {
          queryType: ListingQueryTypesEnum.Active,
          brokerUserId,
        },
      },
    },
  });

  const listings = data?.listings?.data;

  /* Render */
  return (
    <ModalContainer>
      <ModalHeader title="Broker's Listings" close={popModal} />
      <ModalContent>
        <Container>
          {listings?.length > 0 && !loading ? (
            <>
              {listings.map((listing, index) => (
                <>
                  <Link
                    key={listing?._id ?? index}
                    href={listing ? ListingUtil.slug(listing) : '/'}
                    target="_blank"
                  >
                    <ListingCard
                      type={ListingCardTypesEnum.DefaultSearch}
                      listing={listing}
                    />
                  </Link>
                  {index !== listings.length - 1 && (
                    <CustomSpacer height="32px" />
                  )}
                </>
              ))}
            </>
          ) : (
            <LoaderContainer>
              <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
            </LoaderContainer>
          )}
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default BrokerListingsModal;
