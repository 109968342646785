import React from 'react';
import styled from '@emotion/styled';
import {
  ModalContainer,
  ModalHeaderLead,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import Colors from '../../styles/Colors';
import LeadModalDetails from './LeadModalDetails';
import LeadModalStats from './LeadModalStats';
import LeadModalChat from './LeadModalChat';
import LeadModalNotes from './LeadModalNotes';
import useLead from '../../hooks/useLead.hook';
import Loader, { LoaderSizes } from '../../elements/Loader';
import Flex from '../../elements/Flex';
import IButtonOptions from '../../models/interfaces/IButtonOptions';
import LeadModalOffers from './LeadModalOffers';
import LeadModalFiles from './LeadModalFiles';
import { ListingActions } from '../../redux/listing.redux';
import { LeadActions, LeadSelectors } from '../../redux/lead.redux';
import LeadModalBids from './LeadModalBids';
import LeadModalStatus from './LeadModalStatus';
import LeadModalSellerNotes from './LeadModalSellerNotes';
import LeadModalGuidance from './LeadModalGuidance';

const Container = styled.div`
  width: 620px;
  margin-top: -8px;

  ${media.mobile} {
    width: 100%;
  }
`;

const Content = styled.div`
  height: 65vh;
  /* overflow: scroll; */
`;

export enum LeadModalPageStateEnum {
  Details = 'Details',
  Offers = 'Offers',
  Stats = 'Stats',
  Files = 'Files',
  Status = 'Status',
  Guidance = 'Guidance',
  Chat = 'Chat',
  Notes = 'Notes',
  SellerNotes = 'Seller notes',
  Bids = 'Bids',
}

export type LeadModalProps = {
  initialState: LeadModalPageStateEnum;
  showBidsTab?: boolean;
};

const LeadModal: React.FC<LeadModalProps> = ({
  initialState,
  showBidsTab = false,
}) => {
  /** State */
  const { lead } = useLead();
  const [pageState, setPageState] = React.useState(initialState || LeadModalPageStateEnum.Details);

  /** Actions */
  const dispatch = useAppDispatch();
  const manualPageState = useAppSelector(LeadSelectors.modalPageState);
  const selectListing = (listingId: string | null) => dispatch(ListingActions.selectListing({ listingId }));
  const popModal = () => dispatch(
    AppActions.popModal(),
  );
  const clearManualPageState = () => dispatch(LeadActions.setModalPageState({ modalPageState: null }));

  /** Hooks */
  const isMobile = useMobileMedia();

  /** Effects */
  React.useEffect(() => {
    const isDetailsPage = window.location.pathname.includes('/app/dashboard/listings/details');
    if (!isDetailsPage) selectListing(lead?.listingId);
    return () => {
      if (!isDetailsPage) selectListing(null);
      clearManualPageState();
    };
  }, [lead]);

  React.useEffect(() => {
    if (manualPageState) setPageState(manualPageState);
  }, [manualPageState]);

  const setPageStateHandler = (pageState: LeadModalPageStateEnum) => {
    clearManualPageState();
    setPageState(pageState);
  };

  /** Render */
  const leadModalNavItems: IButtonOptions[] = [
    {
      text: 'Details',
      onClick: () => setPageStateHandler(LeadModalPageStateEnum.Details),
      active: pageState === LeadModalPageStateEnum.Details,
    },
    {
      text: 'Offers',
      onClick: () => setPageStateHandler(LeadModalPageStateEnum.Offers),
      active: pageState === LeadModalPageStateEnum.Offers,
    },
    {
      text: 'Stats',
      onClick: () => setPageStateHandler(LeadModalPageStateEnum.Stats),
      active: pageState === LeadModalPageStateEnum.Stats,
    },
    {
      text: 'Files',
      onClick: () => setPageStateHandler(LeadModalPageStateEnum.Files),
      active: pageState === LeadModalPageStateEnum.Files,
    },
    {
      text: 'Status',
      onClick: () => setPageStateHandler(LeadModalPageStateEnum.Status),
      active: pageState === LeadModalPageStateEnum.Status,
    },
    {
      text: 'Guidance',
      onClick: () => setPageStateHandler(LeadModalPageStateEnum.Guidance),
      active: pageState === LeadModalPageStateEnum.Guidance,
    },
    {
      text: 'Notes',
      onClick: () => setPageStateHandler(LeadModalPageStateEnum.Notes),
      active: pageState === LeadModalPageStateEnum.Notes,
    },
    {
      text: 'Seller notes',
      onClick: () => setPageStateHandler(LeadModalPageStateEnum.SellerNotes),
      active: pageState === LeadModalPageStateEnum.SellerNotes,
    },
    {
      text: 'Chat',
      onClick: () => setPageStateHandler(LeadModalPageStateEnum.Chat),
      active: pageState === LeadModalPageStateEnum.Chat,
    },
  ];

  if (showBidsTab) {
    leadModalNavItems.push({
      text: 'Bids',
      onClick: () => setPageStateHandler(LeadModalPageStateEnum.Bids),
      active: pageState === LeadModalPageStateEnum.Bids,
    });
  }

  return (
    <ModalContainer height={isMobile ? 'fill-available' : ''}>
      <ModalHeaderLead
        user={lead?.user}
        listing={lead?.listing}
        close={popModal}
        navItems={leadModalNavItems}
      />
      <ModalContent>
        <Container>
          <Content>
            {(() => {
              if (!lead) {
                return (
                  <Flex height="100%" align="center" justify="center">
                    <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
                  </Flex>
                );
              }
              switch (pageState) {
                case LeadModalPageStateEnum.Details:
                  return <LeadModalDetails lead={lead} />;
                case LeadModalPageStateEnum.Offers:
                  return <LeadModalOffers lead={lead} />;
                case LeadModalPageStateEnum.Stats:
                  return <LeadModalStats lead={lead} />;
                case LeadModalPageStateEnum.Files:
                  return <LeadModalFiles lead={lead} />;
                case LeadModalPageStateEnum.Status:
                  return <LeadModalStatus lead={lead} />;
                case LeadModalPageStateEnum.Guidance:
                  return <LeadModalGuidance lead={lead} />;
                case LeadModalPageStateEnum.Notes:
                  return <LeadModalNotes lead={lead} />;
                case LeadModalPageStateEnum.Chat:
                  return <LeadModalChat lead={lead} />;
                case LeadModalPageStateEnum.SellerNotes:
                  return <LeadModalSellerNotes lead={lead} />;
                case LeadModalPageStateEnum.Bids:
                  return <LeadModalBids lead={lead} />;
                default:
                  return <LeadModalDetails lead={lead} />;
              }
            })()}
          </Content>
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default LeadModal;
