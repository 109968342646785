import React from 'react';
import { useQuery } from '@apollo/client';
import { IError } from '@biproxi/models/interfaces/common';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import { UserActions, UserSelectors } from '../redux/user.redux';
import GET_USER from '../graphql/queries/user.query';
import { useAppDispatch, useAppSelector } from '../redux/store';
import HubSpotSDK from '../utils/HubSpotSDK';
import Auth from '../utils/Auth';
import { useMobileMedia } from '../utils/MediaQuery';

type UseUser = {
  user?: IUserGraphQL | undefined;
  userId: string;
  userEmail?: string;
  loading: boolean;
  error: IError | undefined;
};

type UseUserHook = () => UseUser;

const useUser: UseUserHook = () => {
  /* State */
  const isMobile = useMobileMedia();
  const user = useAppSelector(UserSelectors.user);

  /* Actions */
  const dispatch = useAppDispatch();

  const cacheUser = (user: IUserGraphQL) => dispatch(UserActions.cacheUser({ user }));

  /* Hooks */
  const { loading, error } = useQuery<{ user: IUserGraphQL }>(GET_USER, {
    skip: Boolean(user),
    onCompleted: (data) => {
      if (data.user && !user) {
        cacheUser(data.user);
      }
    },
  });

  /**
    * Identity user in HubSpot Chat
    *
    * Once HubSpot has been initialized with a user,
    * do not initialize again or the chat will re-render
    * repeatedly
    */

  React.useEffect(() => {
    if (user?.email && !isMobile && !window.hbInitializedWithUser) {
      window.hbInitializedWithUser = true;
      HubSpotSDK.initialize(user.email, Auth.getHubSpotToken());
    }
  }, [user]);

  return {
    user,
    userId: user?._id,
    userEmail: user?.email,
    loading: user ? false : loading,
    error: error ? ApolloUtil.parseApolloClientError(error) : undefined,
  };
};

export default useUser;
