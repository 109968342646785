import React from 'react';
import styled from '@emotion/styled';
import Colors from '../../styles/Colors';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Flex from '../../elements/Flex';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import Text, { TextTypesEnum } from '../../elements/Text';
import { media } from '../../utils/MediaQuery';

const Container = styled.div`
  width: 432px;
  max-height: 80vw;
  overflow-y: auto;

  ${media.mobile} {
    width: 100%;
    max-height: unset;
  }
`;

export type SubscriptionConfirmedModalProps = {};

const SubscriptionConfirmedModal: React.FC<SubscriptionConfirmedModalProps> = () => {
  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  /** Render */
  return (
    <ModalContainer data-cy="confirm-subscription-modal">
      <ModalHeader title="Subscription Confirmed" close={popModal} />
      <ModalContent>
        <Container>
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Grey900}
          >
            Your payment was processed successfully and your Biproxi subscription has been confirmed. If you have any questions please contact Biproxi support.
          </Text>
        </Container>
      </ModalContent>
      <ModalFooter>
        <Flex justify="flex-end" width="100%">
          <Button
            data-cy="confirm-subscription-button"
            text="Continue"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            onClick={popModal}
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default SubscriptionConfirmedModal;
