import IFile from '../interfaces/IFile';
import IFileParams from '../interfaces/IFileParams';

function isFile(file: IFile | IFileParams): file is IFile {
  return (file as IFile)?.url !== undefined;
}

function find(files: IFile[], fileId: string): IFile | null {
  return files.find((file: IFile) => file._id === fileId) ?? null;
}

function isImageMimeType(mimetype: string): boolean {
  const mimetypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
  ];

  return mimetypes.includes(mimetype);
}

function isVideoMimeType(mimetype: string): boolean {
  const mimetypes = [
    'video/mp4',
    'video/quicktime',
  ];

  return mimetypes.includes(mimetype);
}

function isPDFMimeType(mimetype: string): boolean {
  const mimetypes = [
    'application/pdf',
    'application/x-pdf',
  ];

  return mimetypes.includes(mimetype);
}

function isBinaryMimeType(mimetype: string): boolean {
  const mimetypes = [
    'application/octet-stream',
  ];

  return mimetypes.includes(mimetype);
}

const FileUtil = {
  isFile,
  find,
  isImageMimeType,
  isVideoMimeType,
  isPDFMimeType,
  isBinaryMimeType,
};

export default FileUtil;
