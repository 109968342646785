import React from 'react';
import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone';
import IFileParams, { GraphQLFileUpload } from '@biproxi/models/interfaces/IFileParams';
import { useMutation } from '@apollo/client';
import IFile from '@biproxi/models/interfaces/IFile';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import Text, { TextAsEnum, TextTypesEnum } from '../../elements/Text';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import Flex from '../../elements/Flex';
import Colors from '../../styles/Colors';
import CREATE_FILES from '../../graphql/mutations/createFiles.mutation';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import LOIFile from '../LOIFile';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import LinkText from '../../elements/LinkText';
import { useMobileMedia } from '../../utils/MediaQuery';

const Container = styled.div`
    max-width: 488px;
    min-width: 343px;
`;

const FileContainer = styled.div`
  margin: 0 0 8px 0;
`;

const FileUploadWrapper = styled.div`
    margin: 0 0 24px 0;
`;

const Dashed = styled.div<{ error?: boolean }>`
  border: ${(props) => {
    if (props.error) return `1px solid ${Colors.Red500}`;
    return `1px dashed ${Colors.Grey300}`;
  }};
  padding: 12px 16px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

export type UploadDocumentsModalProps = {
  uploadedListing?: IListingGraphQL;
  onSubmit: any;
};

const UploadDocumentsAdminModal: React.FC<UploadDocumentsModalProps> = ({
  uploadedListing,
  onSubmit,
}) => {
  /** State */
  const [files, setFiles] = React.useState<IFile[]>([]);
  const [fileError, setFileError] = React.useState<boolean>(false);

  /** Hooks */
  const isMobile = useMobileMedia();

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => {
    dispatch(AppActions.popModal());
  };
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /** GraphQL */
  const [uploadFiles, { loading: fileLoading }] = useMutation(CREATE_FILES, {
    onCompleted: async (data) => {
      setFiles([...files, ...data?.createFiles]);
    },
    onError: () => {
      setFileError(true);
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'Error uploading files',
      });
    },
  });

  const onDrop: any = (browserFiles: GraphQLFileUpload[]) => {
    if (fileLoading) return;
    const fileParams: IFileParams[] = browserFiles.map((browserFile: GraphQLFileUpload) => ({
      name: browserFile.name,
      description: browserFile.name,
      mimetype: browserFile.type || 'application/octet-stream',
      encoding: 'utf8',
      upload: browserFile,
    }));

    uploadFiles({
      variables: {
        params: fileParams[0],
      },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  /** Functions */
  const handleSubmit = () => {
    onSubmit(uploadedListing, files?.map((file: IFile) => file._id));
  };

  return (
    <ModalContainer margin={isMobile ? '0 16px' : '0px'}>
      <ModalHeader title="Upload document" close={popModal} />
      <ModalContent>
        <Container>
          <Text margin="24px 0">
            Select documents to be uploaded.
          </Text>
          <FileUploadWrapper>
            <Dashed {...getRootProps()} error={fileError}>
              <input {...getInputProps()} />
              {(!fileLoading && files?.length <= 0) ? (
                <Flex align="center" justify="center">
                  <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>
                    Drag file(s) here or
                    &nbsp;
                    <Text
                      type={TextTypesEnum.Medium14}
                      as={TextAsEnum.Span}
                      color={Colors.Brand700 || Colors.Blurple700}
                    >
                      click to upload
                    </Text>
                  </Text>
                </Flex>
              ) : (
                <>
                  {
                  files.map((file, index) => (
                    <FileContainer key={index}>
                      <LOIFile
                        key={file?._id}
                        file={file}
                        deleteFile={() => {
                          setFiles(files.filter((f) => f._id !== file._id));
                        }}
                      />
                    </FileContainer>
                  ))
                }
                </>
              )}

            </Dashed>
            {files.length > 0 && (
              <div {...getRootProps()}>
                <LinkText type={TextTypesEnum.Medium12} margin="8px 0 0 0">
                  <input {...getInputProps()} />
                  Add more files
                </LinkText>
              </div>
            )}
          </FileUploadWrapper>
        </Container>
      </ModalContent>
      <ModalFooter>
        <Flex justify={isMobile ? 'space-between' : 'flex-end'} align="center" width="100%">
          <Button
            text="Cancel"
            type={ButtonTypesEnum.Ghost}
            size={ButtonSizesEnum.Medium}
            onClick={() => popModal()}
            margin="0px 8px 0px 0px"
            htmlType="button"
          />
          <Button
            text="Upload documents"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            onClick={() => handleSubmit()}
            htmlType="button"
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default UploadDocumentsAdminModal;
