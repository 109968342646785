import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors, { ColorsType } from '../styles/Colors';
import IconsEnum from './Icons';
import TValueOf from '../models/types/TValueOf';
import BiproxiIconEnum from '../models/enums/BiproxiIconEnum';

// TODO: rename 'icon' prop as its a bit too broad now. say 'faIcon' or some such since thats where all our icons come from

const Container = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify};
  background: ${(props) => props.background};
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  height: ${(props) => props.height};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  margin: ${(props) => props.margin};
  color: ${(props) => props.color};
  data-cy: ${(props) => props['data-cy']};
  position: ${(props) => props.position};
  cursor: ${(props) => props.cursor};
  transition: background-color 0.2s ease-in-out;
  border-radius: ${(props) => props.borderRadius};

  &:hover {
    color: ${(props) => {
    if (props.color === 'inherit') return null;
    return props.hoverColor;
  }};
    background-color: ${({ hoverContainerColor }) => hoverContainerColor};
  }

  &:active {
    background-color: ${({ activeContainerColor }) => activeContainerColor};
  }
`;

/** Component to return custom Biproxi icons */
type BiproxiIconProps = {
  icon: BiproxiIconEnum;
  size: string | number | undefined;
}

const biproxiIconStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
};

const BiproxiIcon: React.FC<BiproxiIconProps> = ({ icon, size }) => (
  <i
    id="BiproxiIcon"
    className={`bp${icon}`}
    style={
    { ...biproxiIconStyle, ...(size ? { fontSize: size } : {}) }
   }
  />
);

export const Icons = IconsEnum;

export type IconsType = TValueOf<typeof Icons>;

export type IconProps = {
  icon?: IconsType;
  customIcon?: BiproxiIconEnum;
  color?: ColorsType | string | null;
  borderRadius?: string;
  background?: ColorsType;
  hoverColor?: ColorsType | string | null;
  onClick?: any | null;
  size?: string | number | undefined;
  width?: string;
  height?: string;
  position?: string;
  top?: string | number | undefined;
  left?: string | number | undefined;
  right?: string | number | undefined;
  zIndex?: number | void;
  margin?: string | number | undefined;
  tip?: string;
  tipPlace?: string;
  transitionDuration?: string;
  rotation?: number;
  justify?: 'flex-start' | 'center';
  hoverContainerColor?: string | undefined,
  activeContainerColor?: string | undefined,
  'data-cy'?: string,
};

export default function Icon({
  icon = Icons.BellSolid,
  customIcon,
  color = Colors.Brand700 || Colors.Blurple700,
  borderRadius = '4px',
  background,
  hoverColor = null,
  hoverContainerColor = null,
  activeContainerColor = null,
  onClick,
  size = 20,
  width,
  height,
  top,
  left,
  right,
  position = 'relative' as any,
  zIndex,
  margin,
  tip,
  tipPlace,
  transitionDuration,
  rotation,
  justify = 'center',
  'data-cy': dataCy,
}: IconProps) {
  /* State */
  const cursor: string = onClick ? 'pointer' : '';
  /* Render */
  return (
    <Container
      id="IconContainer"
      color={color}
      borderRadius={borderRadius}
      background={background}
      hoverColor={hoverColor}
      hoverContainerColor={hoverContainerColor}
      activeContainerColor={activeContainerColor}
      position={position}
      width={width}
      height={height}
      top={top}
      left={left}
      right={right}
      justify={justify}
      margin={margin}
      data-tip={tip}
      data-place={tipPlace}
      onClick={onClick}
      cursor={cursor}
      data-cy={dataCy}
    >
      {customIcon ? (
        <BiproxiIcon icon={customIcon} size={size} />
      ) : (
        <FontAwesomeIcon
          icon={icon as any}
          style={{
            position: 'relative',
            zIndex: zIndex as any,
            fontSize: size,
            transition: `all ${transitionDuration || '0.2s'}`,
            transform: rotation ? `rotate(${rotation}deg)` : undefined,
            cursor,
          }}
        />
      )}
    </Container>
  );
}
