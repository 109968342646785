enum ListingQuerySortByEnum {
  Trending = 'Trending',
  NewestFirst = 'Newest first',
  OldestFirst = 'Oldest first',
  HighestPricedFirst = 'Highest priced first',
  LowestPricedFirst = 'Lowest priced first',
  Unsorted = 'Unsorted',
}

export default ListingQuerySortByEnum;
