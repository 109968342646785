import IFile from '@biproxi/models/interfaces/IFile';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import UserUtil from '@biproxi/models/utils/UserUtil';
import { CONFIDENTIALITY_URL } from '@biproxi/models/externalLinks';
import React from 'react';
import Input, { InputTypesEnum } from '../elements/Input';
import Link from '../elements/Link';
import LinkText from '../elements/LinkText';
import Text, { TextTypesEnum } from '../elements/Text';
import { AppActions } from '../redux/app.redux';
import { useAppDispatch } from '../redux/store';
import Colors from '../styles/Colors';
import SignaturePad from './SignaturePad';

export type SignatureInputProps = {
    customCAFile?: IFile;
    user: IUserGraphQL;
    setParentSignatureState?: any;
};

const SignatureInput: React.FC<SignatureInputProps> = ({
  customCAFile,
  user,
  setParentSignatureState,
}) => {
  /** State */
  const [userFullName, setUserFullName] = React.useState(null);
  /** Actions */
  const dispatch = useAppDispatch();
  const previewCustomCA = () => dispatch(
    AppActions.previewFiles({
      fileId: customCAFile._id,
      files: [customCAFile],
      track: false,
    }),
  );

  React.useEffect(() => {
    setParentSignatureState(userFullName);
  }, [userFullName]);
  return (
    <>
      <Input
        autoFocus
        label="Enter your full name to sign"
        placeholder={UserUtil.fullName(user)}
        value={userFullName}
        onChange={(e) => setUserFullName(e.target.value)}
        inputType={InputTypesEnum.Text}
        margin="0 0 16px"
      />
      <SignaturePad text={userFullName} />
      {customCAFile ? (
        <Text type={TextTypesEnum.Regular12} margin="0 0 24px">
          By signing your name you agree to comply with this
          {' '}
          <Text
            type={TextTypesEnum.Regular12}
            color={Colors.Brand500 || Colors.Blurple500}
            onClick={() => previewCustomCA()}
          >
            confidentiality agreement.
          </Text>
        </Text>
      ) : (
        <Text type={TextTypesEnum.Regular12} margin="0 0 24px">
          By signing your name you agree to comply with this
          {' '}
          <Link
            href={CONFIDENTIALITY_URL}
            target="_blank"
            isExternal
          >
            <LinkText type={TextTypesEnum.Regular12} display="block">confidentiality agreement.</LinkText>
          </Link>
        </Text>
      )}
    </>
  );
};

export default SignatureInput;
