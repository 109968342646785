import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import LeadActivityTimeline from '../lead/LeadActivityTimeline';
import LeadStats from '../lead/LeadStats';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Divider from '../../elements/Divider';
import UserInfo, { UserInfoTypesEnum } from '../UserInfo';
import { media } from '../../utils/MediaQuery';

const Container = styled.div`
  width: 100%;
  height: 65vh;
  overflow: auto;
  padding-right: 24px;

  ${media.mobile} {
    height: calc(100vh - 135px);
  }
`;

export type LeadModalStatsProps = {
  lead: ILeadGraphQL;
};

const LeadModalStats: React.FC<LeadModalStatsProps> = ({
  lead,
}) => (
  <Container>
    <UserInfo user={lead.user} type={UserInfoTypesEnum.Large} includePhone={Boolean(!lead?.isInvestorMatch)} links />
    <Divider color={Colors.Grey200} margin="16px 0" />
    <Text type={TextTypesEnum.Bold16} color={Colors.Black} margin="0 0 8px">Stats</Text>
    <LeadStats lead={lead} />
    <Divider color={Colors.Grey200} margin="16px 0" />
    <Text type={TextTypesEnum.Bold16} color={Colors.Black} margin="0 0 8px">Recent Activity</Text>
    <LeadActivityTimeline events={lead.analyticsEvents} />
  </Container>
);

export default LeadModalStats;
