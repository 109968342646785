import { logger } from '@biproxi/logger';
import React from 'react';
import { useQuery } from '@apollo/client';
import { IError } from '@biproxi/models/interfaces/common';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { INotificationGraphQL } from '@biproxi/models/interfaces/INotification';
import INotificationQuery from '@biproxi/models/interfaces/INotificationQuery';
import { useAppDispatch, useAppSelector } from '../redux/store';
import LIST_NOTIFICATIONS from '../graphql/queries/notifications.query';
import PaginationLimitsEnum from '../models/enums/PaginationLimitsEnum';
import { NotificationActions, NotificationSelectors } from '../redux/notification.redux';
import NOTIFICATION from '../graphql/subscriptions/notification.subscription';
import useUserHook from './useUser.hook';
import Auth from '../utils/Auth';

interface Data {
  notifications: {
    data: INotificationGraphQL[];
    info: {
      totalCount: number;
      totalUnreadCount: number;
    }
  }
}

type UseNotification = {
  data?: Data;
  fetchMore: any;
  loading: boolean;
  error: IError | undefined;
};

type UseNotificationHook = () => UseNotification;

/**
 * Hook to load in the notifications in the dashboard page
 * and subscribe to new ones as they come in.
 */
const useNotification: UseNotificationHook = () => {
  /** State */
  const unreadNotificationsRedux = useAppSelector(NotificationSelectors.unreadNotificationsCount);
  const { user } = useUserHook();

  /* Actions */
  const dispatch = useAppDispatch();
  const search = useAppSelector(NotificationSelectors.notificationSearch);
  const offset = useAppSelector(NotificationSelectors.notificationPaginationOffset);

  const setNotificationsUnreadCount = (unreadNotificationsCount: number | null) => dispatch(
    NotificationActions.setUnreadNotificationsCount({
      unreadNotificationsCount,
    }),
  );
  const incrementUnreadNotificationsCount = () => {
    dispatch(NotificationActions.incrementUnreadNotificationsCount({}));
  };

  /** GraphQL */
  interface Vars {
    params: INotificationQuery
  }

  const {
    data, loading, error, fetchMore, subscribeToMore,
  } = useQuery<Data, Vars>(LIST_NOTIFICATIONS, {
    skip: !user,
    variables: {
      params: {
        search,
        pagination: {
          limit: PaginationLimitsEnum.Notifications,
          offset,
        },
      },
    },
    context: {
      debounceKey: 'QUERY_NOTIFICATIONS',
    },
    onCompleted: (data) => {
      setNotificationsUnreadCount(unreadNotificationsRedux ?? data?.notifications?.info?.totalUnreadCount);
    },
    onError: (error) => {
      logger.error('LIST_NOTIFICATIONS error', error);
    },
  });

  /** Effects */
  type SubscriptionData = {
    notification: INotificationGraphQL;
  }

  React.useEffect(() => {
    if (subscribeToMore && Auth.isAuthenticated()) {
      subscribeToMore<SubscriptionData>({
        document: NOTIFICATION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) { return prev; }
          incrementUnreadNotificationsCount();
          // Merge into list is handled by Apollo type policy.
          const notifications = {
            data: [subscriptionData?.data?.notification],
            info: { ...prev?.notifications?.info },
          };

          return {
            notifications,
          };
        },
      });
    }
  }, [subscribeToMore, Auth.isAuthenticated]);

  return {
    data,
    fetchMore,
    loading,
    error: error ? ApolloUtil.parseApolloClientError(error) : undefined,
  };
};

export default useNotification;
