import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import { BoxShadowsType } from '../styles/BoxShadows';

const Container = styled.div<GenericCardProps>`
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  background:  ${Colors.White};
  margin: ${(props) => props.margin};
  box-shadow: ${(props) => props.boxShadow ?? null};
  border-radius: ${(props) => props.borderRadius};
  border: 1px solid ${Colors.Grey300};
  box-sizing: border-box;
  overflow: ${(props) => props.overflow};
  transition: all 0.1s;

  &:hover {
    cursor: ${(props) => (props.hover ? 'pointer' : null)};
    background-color: ${(props) => (props.hover ? Colors.Grey50 : null)};
  }
`;

type BodyProps = {
  padding?: string;
}
const Body = styled.div<BodyProps>`
  border-radius: 0px 0px 10px 10px;
  padding: ${(props) => props.padding};
  height: 100%;
  box-sizing: border-box;
`;

type GenericCardProps = {
  children: React.ReactNode;
  width?: string;
  margin?: string;
  padding?: string;
  borderRadius?: string;
  boxShadow?: BoxShadowsType;
  height?: string;
  overflow?: string;
  hover?: boolean;
  onClick?: () => void;
};

const GenericCard: React.FC<GenericCardProps> = ({
  children,
  width,
  margin = '0',
  padding = '24px',
  borderRadius = '16px',
  boxShadow,
  height,
  overflow,
  hover,
  onClick,
}) => (
  <Container
    width={width}
    margin={margin}
    boxShadow={boxShadow}
    borderRadius={borderRadius}
    height={height}
    overflow={overflow}
    hover={hover}
    onClick={onClick || null}
  >
    <Body padding={padding}>
      {children}
    </Body>
  </Container>
);

export default GenericCard;
