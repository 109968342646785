import React from 'react';
import styled from '@emotion/styled';
import GoogleMapReact from 'google-map-react';
import { config } from '@biproxi/env';
import { useMobileMedia, useTabletMedia } from '../utils/MediaQuery';
import {
  GoogleMapProps,
} from '../models/interfaces/IGoogleMaps';

type GoogleMapContainerProps = {
    width: string;
    height: string | number;
    children?: React.ReactNode;
    'data-cy'?: string;
}

const GoogleMapContainer = styled.div<GoogleMapContainerProps>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    data-cy: ${(props) => props['data-cy']};
`;

const GoogleMap: React.FC<GoogleMapProps> = ({
  children,
  heatmap = null,
  zoom = null,
  center = { lat: 37.89618351189365, lng: -96.39266225 },
  options = {
    streetViewControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    zoomControlOptions: {
      // eslint-disable-next-line no-undef
      position: google.maps.ControlPosition.TOP_RIGHT,
    },
  },
  style,
  width = '100%',
  height = '100%',
  onClick,
  onChange,
  onDrag,
  onDragEnd,
  onLoad,
  draggable,
  shouldUnregisterMapOnUnmount = false,
  'data-cy': dataCy,
}) => {
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  return (
    <GoogleMapContainer
      width={width}
      height={height}
      data-cy={dataCy}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: config.NEXT_PUBLIC_GOOGLE_BROWSER_KEY,
          libraries: ['places', 'visualization'],
        }}
        options={{ ...options, mapId: '8714d1fd4391b858' } as any}
        style={style}
        heatmap={heatmap || undefined}
        center={center}
        zoom={zoom ?? (isMobile || isTablet ? 2 : 4)}
        onDragEnd={onDragEnd}
        onGoogleApiLoaded={onLoad}
        onClick={onClick}
        onChange={onChange}
        onDrag={onDrag}
        draggable={draggable}
        yesIWantToUseGoogleMapApiInternals
        shouldUnregisterMapOnUnmount={shouldUnregisterMapOnUnmount}
      >
        {children as any}
      </GoogleMapReact>
    </GoogleMapContainer>
  );
};

export default GoogleMap;
