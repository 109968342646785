import FileAccessLevelsEnum from '../enums/FileAccessLevelsEnum';
import LeadQuerySortByEnum from '../enums/LeadQuerySortByEnum';
import LeadQuerySortDirectionEnum from '../enums/LeadQuerySortDirectionEnum';
import ILeadQuery from '../interfaces/ILeadQuery';
import { ILeadGraphQL } from '../interfaces/ILead';
import UserUtil from './UserUtil';
import LeadAlertTypesEnum from '../enums/LeadAlertTypesEnum';

function sort(leads: ILeadGraphQL[], query: ILeadQuery): ILeadGraphQL[] {
  const {
    sortBy,
    sortDirection,
  } = query;

  const sortFn = (lead1: ILeadGraphQL, lead2: ILeadGraphQL): number => {
    const getValue = (lead: ILeadGraphQL): string | number | boolean => {
      switch (sortBy) {
        case LeadQuerySortByEnum.Name:
          return UserUtil.fullName(lead.user)?.toString().toLowerCase() ?? '';
        case LeadQuerySortByEnum.Views:
          return lead.metrics.pageViews;
        case LeadQuerySortByEnum.SignedCA:
          return lead.metrics.signedCA;
        case LeadQuerySortByEnum.Created:
          return lead.meta.createdAt ?? '';
        default:
          return UserUtil.fullName(lead.user)?.toString().toLowerCase() ?? '';
      }
    };

    const lead1Value = getValue(lead1);
    const lead2Value = getValue(lead2);
    const isAscending = sortDirection === LeadQuerySortDirectionEnum.Ascending;

    if (lead1Value > lead2Value) return isAscending ? 1 : -1;
    if (lead1Value <= lead2Value) return isAscending ? -1 : 1;
    return 0;
  };

  return leads.sort(sortFn);
}

function filter(leads: ILeadGraphQL[], query: ILeadQuery, isInvestorMatches: boolean): ILeadGraphQL[] {
  const {
    term,
    leadType,
  } = query;

  const filterFn = (lead: ILeadGraphQL) => {
    if (!isInvestorMatches && leadType && leadType !== lead.state) return false;

    if (!term) return true;

    const match = term.toLowerCase().split(' ').find((word: string) => {
      const firstName = lead.user?.firstName;
      const lastName = lead.user?.lastName;
      const email = lead.user?.email;
      return Boolean(firstName.toLowerCase().includes(word) || lastName.toLowerCase().includes(word) || email.toLowerCase().includes(word));
    });
    return Boolean(match);
  };

  return leads.filter(filterFn);
}

function level2Requested(lead: ILeadGraphQL): boolean {
  return lead?.fileAccessLevel?.accessLevel === FileAccessLevelsEnum.Level1
  && lead?.fileAccessLevel?.requestedAccessLevel === FileAccessLevelsEnum.Level2
  && lead?.fileAccessLevel?.hasBeenDenied === null;
}

function privateListingRequested(lead: ILeadGraphQL): boolean {
  if (lead?.privateListingAccess === undefined) {
    return false;
  }
  return lead?.privateListingAccess?.accessRequested
    && !lead?.privateListingAccess?.accessApproved
    && !lead?.privateListingAccess?.accessDenied;
}

function getLeadAlertTypes(lead: ILeadGraphQL): LeadAlertTypesEnum[] {
  let alerts: LeadAlertTypesEnum[] = [];
  if (!lead || lead === undefined) {
    return alerts;
  }
  if (level2Requested(lead)) {
    alerts = [...alerts, LeadAlertTypesEnum.Level2FileAccess];
  } else if (privateListingRequested(lead)) {
    alerts = [...alerts, LeadAlertTypesEnum.PrivateListing];
  }
  return alerts;
}

const LeadUtil = {
  sort,
  filter,
  level2Requested,
  privateListingRequested,
  getLeadAlertTypes,
};

export default LeadUtil;
