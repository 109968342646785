/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import { useAppDispatch } from '../redux/store';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from './modal/Modal';
import { IToastConfig, ToastTypesEnum } from './Toast';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';
import { media } from '../utils/MediaQuery';

const BannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  background-color: ${Colors.Brand100 || Colors.Blurple100};
  height: fit-content;
  min-height: 80px;

  ${media.mobile} {
    flex-direction: column;
    padding: 16px 0px;
  }
`;

const BannerTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  ${media.mobile} {
    flex-direction: column;
  }
`;

const TextBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;

  width: fit-content;
  height: 40px;
  background: ${Colors.White};
  border-radius: 4px;

  ${media.mobile} {
    text-align: center;
  }

  &:hover {
    cursor: pointer;
  }
`;

const CloseIconWrapper = styled.div`
  display: flex;
`;

type SubscriptionPromoDashboardBannerProps = {
  setBannerVisible: any;
};

const SubscriptionPromoDashboardBanner: React.FC<SubscriptionPromoDashboardBannerProps> = ({
  setBannerVisible,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();
  const openReminderModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.LoginUpgradeAccountReminder,
      props: {},
    }),
  );
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /** Functions */
  const handleCopyPromoCodeToClipboard = () => {
    navigator.clipboard.writeText('EARLYACCESS99');
    pushToast({
      type: ToastTypesEnum.Notification,
      message: 'Promo code copied to clipboard',
    });
  };
  return (
    <BannerWrapper>
      <BannerTextWrapper>
        <Text
          type={TextTypesEnum.Regular18}
          color={Colors.Brand700 || Colors.Blurple700}
        >
          Use promo code
        </Text>
        <TextBlock
          onClick={handleCopyPromoCodeToClipboard}
        >
          <Text
            type={TextTypesEnum.Medium16}
            color={Colors.Brand700 || Colors.Blurple700}
          >
            EARLYACCESS99
          </Text>
        </TextBlock>
        <Text
          type={TextTypesEnum.Regular18}
          color={Colors.Brand700 || Colors.Blurple700}
          align="center"
        >
          and upgrade to Pro to take advantage of all features.
        </Text>
        <Text
          type={TextTypesEnum.Bold18}
          color={Colors.Brand700 || Colors.Blurple700}
          onClick={openReminderModal}
        >
          Learn more
        </Text>
        <Link href="/app/dashboard/settings/billing" target="_blank">
          <TextBlock
            onClick={handleCopyPromoCodeToClipboard}
          >
            <Text
              type={TextTypesEnum.Medium16}
              color={Colors.Brand700 || Colors.Blurple700}
              align="center"
            >
              Upgrade now
            </Text>
          </TextBlock>
        </Link>
      </BannerTextWrapper>
      <CloseIconWrapper>
        <Icon
          icon={Icons.TimesRegular}
          size={16}
          color={Colors.Brand700 || Colors.Blurple700}
          onClick={setBannerVisible}
        />
      </CloseIconWrapper>
    </BannerWrapper>
  );
};

export default SubscriptionPromoDashboardBanner;
