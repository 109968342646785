import { logger } from '@biproxi/logger';
import StatusCodeEnum from '@biproxi/models/enums/StatusCodeEnum';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import axios from 'axios';

type UserParams = Required<IUserGraphQL>;

export default class AuraUserMutator {
  private mongoUserParams: Partial<IUserGraphQL>;
  private auraUserParams: INeo4jServiceAPI.ICreateUserParams;

  constructor(user: UserParams) {
    this.mongoUserParams = user;

    this.auraUserParams = {
      _id: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      email: user.email,
      metaCreatedAt: TimeUtil.now(),
      metaLastUpdatedAt: TimeUtil.now(),
      role: user.role,
      biproxiRoles: [],
      biproxiPermissions: [],
    };
  }

  public getUser = async (userId: string): Promise<INeo4jServiceAPI.IGetUserResponse> => {
    const response: INeo4jServiceAPI.IGetUserResponse = {
      data: null as any,
      status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    };

    try {
      const res = await axios.get('/api/neo4j/getUser', { params: { userId } });
      response.status = res?.data?.data?.users?.length ? res?.status : StatusCodeEnum.NO_CONTENT;
      response.data = res?.data?.data;
    } catch (error) {
      logger.warn(`Failed to fetch user in Aura by user id: ${error}`);
    }
    return response;
  };

  public getUserByEmail = async (userEmail: string): Promise<INeo4jServiceAPI.IGetUserByEmailResponse> => {
    const response: INeo4jServiceAPI.IGetUserByEmailResponse = {
      data: null as any,
      status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    };

    try {
      const res = await axios.get('/api/neo4j/getUserByEmail', { params: { userEmail } });
      response.status = res?.data?.data?.users?.length ? res?.status : StatusCodeEnum.NO_CONTENT;
      response.data = res?.data?.data;
    } catch (error) {
      logger.warn(`Failed to fetch user in Aura by user email: ${error}`);
    }
    return response;
  };

  public async createUser(createUserParams: INeo4jServiceAPI.ICreateUserParams): Promise<INeo4jServiceAPI.ICreateUserResponse> {
    const response: INeo4jServiceAPI.ICreateUserResponse = {
      data: null,
      status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    };
    try {
      const res = await axios.post('/api/neo4j/createUser', { params: createUserParams });
      response.data = res?.data?.data;
      response.status = res?.data?.status;
    } catch (e) {
      logger.warn(`Failed to create user in Aura: ${e}`);
    }
    return response;
  }

  public async updateUser(updateUserParams: INeo4jServiceAPI.IUpdateUserParams): Promise<INeo4jServiceAPI.IUpdateUserResponse> {
    const response: INeo4jServiceAPI.IUpdateUserResponse = {
      data: null as any,
      status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    };
    try {
      const res = await axios.post('/api/neo4j/updateUser', { params: updateUserParams });
      response.data = res?.data?.data;
      response.status = res?.data?.status;
    } catch (e) {
      logger.warn(`Failed to update user in Aura: ${e}`);
    }
    return response;
  }

  public async userMutationFlow(userId: string, userEmail?: string): Promise<INeo4jServiceAPI.IUpdateUserResponse> {
    // First, we want to check to see if the uhhh mf uhhh user exists in Aura
    let auraUserData = {
      status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    };
    if (userEmail?.length) {
      auraUserData = await this.getUserByEmail(userEmail);
    } else {
      auraUserData = await this.getUser(userId);
    }
    const response: INeo4jServiceAPI.IUpdateUserResponse = {
      data: null as any,
      status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    };
    // logger.debug(`${auraUserData}`);
    if (auraUserData?.status === StatusCodeEnum.NO_CONTENT) {
      const createUserResponse = await this.createUser(this.auraUserParams);
      response.data = createUserResponse?.data?.createUsers?.users?.[0];
      response.status = createUserResponse?.status;
    } if (auraUserData?.status === StatusCodeEnum.OK) {
      const updateUserResponse: any = await this.updateUser(this.auraUserParams);
      response.data = updateUserResponse?.data?.updateUsers?.users?.[0];
      response.status = updateUserResponse?.status;
    }
    return response;
  }
}
