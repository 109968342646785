import React from 'react';
import Joi from 'joi';
import JoiUtil from '@biproxi/models/utils/JoiUtil';
import pointer from 'json-pointer';
import lodash from 'lodash';
import makeEventHandler from '../utils/makeEventHandler';

export type TFieldController = {
  pointerKey(): string;
  value(): any;
  error(): string | null;
  focus(): void;
  ref(): HTMLInputElement;
  setValue: (event: any, isPhoneInput?: boolean) => void;
  setRawValue: (value: string | number) => void;
  setError: (error: string) => void;
  setRef: (element: HTMLInputElement) => void;
}

export type UseForm = {
  controllers: any;
  isValid: () => boolean;
  setValues: (fields: any) => void;
  setFieldErrors: (errorFields: Record<string, string>) => void;
};

export type UseFormParams = {
  fields: any;
  errors?: any;
  schema: Joi.ObjectSchema;
  fieldOrder: string[];
  bubbleFields?: string[];
}

type HTMLInputElementMap = Record<string, HTMLInputElement | null>;

type UseFormHook = (params: UseFormParams) => UseForm;

const useFormHook: UseFormHook = (params: UseFormParams) => {
  const [values, setValuesInternal] = React.useState(params.fields);

  const [errors, setErrorsInternal] = React.useState(params.errors ?? params.fields);
  const fieldPointers = pointer.dict(params.fields);

  const initialRefs = Object.keys(fieldPointers).reduce((cur, pointerKey) => {
    pointer.set(cur, pointerKey, typeof HTMLInputElement !== 'undefined' ? HTMLInputElement : undefined);
    return cur;
  }, {});

  const refs = React.useRef<HTMLInputElementMap>(initialRefs);

  const eventHandler = makeEventHandler(() => { });

  const focus = (pointerKey: string) => {
    // To bubble nested field errors and focus to their parent
    const newKey = pointer.compile([pointer.parse(pointerKey)[0]]);
    if (params?.bubbleFields?.includes(newKey)) {
      pointerKey = newKey;
    }
    const inputRef = pointer.get(refs.current, pointerKey);

    // For use with mask input
    const hasInnerInputElement = Boolean(inputRef?.inputElement ?? null);

    if (hasInnerInputElement) {
      inputRef.inputElement.focus?.();
      // Conditonal for phone input as library maintains unique internal ref
    } else if (inputRef?.numberInputRef) {
      inputRef?.numberInputRef.focus();
    } else if (inputRef?.focus) {
      inputRef?.focus?.();
    }
  };

  const controllers = Object.keys(fieldPointers).reduce((cur, pointerKey) => {
    const setError = (error: string) => {
      const copy = lodash.cloneDeep(errors);
      pointer.set(copy, pointerKey, error);
      setErrorsInternal(copy);
    };

    const controller: TFieldController = {
      pointerKey: () => pointerKey,
      value: () => pointer.get(values, pointerKey),
      error: () => pointer.get(errors, pointerKey),
      focus: () => {
        focus(pointerKey);
      },
      ref: () => pointer.get(refs.current, pointerKey),
      setValue: eventHandler((value: string | number) => {
        const copy = lodash.cloneDeep(values);
        pointer.set(copy, pointerKey, value);
        setValuesInternal(copy);
        setError(null);
      }),
      setRawValue: (value: string | number) => {
        const copy = lodash.cloneDeep(values);
        pointer.set(copy, pointerKey, value);
        setValuesInternal(copy);
        setError(null);
      },
      setError,
      setRef: (element: HTMLInputElement) => {
        pointer.set(refs.current, pointerKey, element);
      },
    };

    pointer.set(cur, pointerKey, controller);
    return cur;
  }, {});

  const setFieldErrors = (errorsMap: Record<string, string>) => {
    const copy = lodash.cloneDeep(errors);
    Object.entries(errorsMap).forEach(([key, value]) => {
      const newKey = pointer.compile([pointer.parse(key)[0]]);
      if (params?.bubbleFields?.includes(newKey)) {
        key = newKey;
      }
      pointer.set(copy, key, value);
    });

    const [firstErrorPointerKey] = Object.keys(errorsMap).sort((a: string, b: string): number => {
      if (params.fieldOrder.indexOf(a) > params.fieldOrder.indexOf(b)) return 1;
      return -1;
    });

    // Focus the first error field
    focus(firstErrorPointerKey);

    setErrorsInternal(copy);
  };

  const setValues = (fields: any) => {
    setValuesInternal(fields);
  };

  const isValid = () => {
    const { errors } = JoiUtil.validate(
      params.schema,
      values,
    );

    if (JoiUtil.hasErrors(errors)) {
      setFieldErrors(JoiUtil.parseErrors(errors));
      return false;
    }

    setErrorsInternal(params.errors ?? params.fields);
    return true;
  };
  return {
    controllers,
    setFieldErrors,
    setValues,
    isValid,
  };
};

export default useFormHook;
