import { logger } from '@biproxi/logger';
import { tacklebox, BrandedColorsType } from '@biproxi/tacklebox';
import TValueOf from '../models/types/TValueOf';
// import ColorsJson from './Colors.json';

const fetchingColors = async () => {
  /**
   * * With the new changes in removing the other colors out of contentful models
   * * It would be difficult to resort to calling some of the colors that are missing within web
   * * For example: Colors.Black, that is removed from Contentful when the tacklebox function is successful
   * * Quick change here, we just reference an existing object here, either return it just like the Colorsjson file
   * * or returning the Colorsjson with the added Brand10-900 colors.
   */
  const baseColors: BrandedColorsType = {
    Black: '#000000',
    SpecialBlack: '#0F102B',
    Black03: 'rgba(0,0,0,0.03)',
    Black05: 'rgba(0,0,0,0.05)',
    Black10: 'rgba(0,0,0,0.1)',
    Black20: 'rgba(0,0,0,0.2)',
    Black33: 'rgba(0,0,0,0.33)',
    Black50: 'rgba(0,0,0,0.5)',
    Black66: 'rgba(0,0,0,0.66)',
    Black75: 'rgba(0,0,0,0.75)',
    Black90: 'rgba(0,0,0,0.9)',
    Black95: 'rgba(0,0,0,0.95)',
    White: '#FFFFFF',
    White05: 'rgba(255,255,255,0.05)',
    White10: 'rgba(255,255,255,0.1)',
    White20: 'rgba(255,255,255,0.2)',
    White50: 'rgba(255,255,255,0.5)',
    Blurple5: 'rgba(79,70,229,0.05)',
    Blurple10: 'rgba(79,70,229,0.1)',
    Blurple50: '#F0EFFF',
    Blurple100: '#E7E6FD',
    Blurple200: '#C7D2FE',
    Blurple300: '#A5B4FC',
    Blurple400: '#8F81EC',
    Blurple500: '#6366FF',
    Blurple600: '#4F46E5',
    Blurple700: '#534CA5',
    Blurple800: '#362F8D',
    Blurple900: '#2A2475',
    Red50: '#FEF2F2',
    Red100: '#FEE2E2',
    Red200: '#FECACA',
    Red300: '#FCA5A5',
    Red400: '#F87171',
    Red500: '#EF4444',
    Red600: '#DC2626',
    Red700: '#B91C1C',
    Red800: '#991B1B',
    Red900: '#7F1D1D',
    Green50: '#EAF8ED',
    Green100: '#CFF5E2',
    Green200: '#A4ECC1',
    Green300: '#81E5A9',
    Green400: '#67D493',
    Green500: '#10B981',
    Green600: '#059669',
    Green700: '#047857',
    Green800: '#065F46',
    Green900: '#064E3B',
    Blue50: '#E3EEFF',
    Blue100: '#C8DEFF',
    Blue200: '#B3D1FF',
    Blue300: '#A1C6FF',
    Blue400: '#79AEFF',
    Blue500: '#5093FB',
    Blue600: '#226DE2',
    Blue700: '#0055DA',
    Blue800: '#0247B2',
    Blue900: '#03398D',
    Burgundy50: '#FFF1F9',
    Burgundy100: '#FFDDF0',
    Burgundy200: '#FBB8E0',
    Burgundy300: '#EF9BCD',
    Burgundy400: '#DF82B9',
    Burgundy500: '#DB55A4',
    Burgundy600: '#C7358B',
    Burgundy700: '#A72B74',
    Burgundy800: '#902564',
    Burgundy900: '#791F54',
    Yellow50: '#FFFBEB',
    Yellow100: '#FEF3C7',
    Yellow200: '#FDE68A',
    Yellow300: '#FCD34D',
    Yellow400: '#FBBF24',
    Yellow500: '#F59E0B',
    Yellow600: '#D97706',
    Yellow700: '#B45309',
    Yellow800: '#92400E',
    Yellow900: '#78350F',
    Grey50: '#F9FAFB',
    Grey100: '#F3F4F6',
    Grey200: '#E5E7EB',
    Grey300: '#D1D5DB',
    Grey400: '#A1A3B5',
    Grey500: '#444968',
    Grey600: '#3C4160',
    Grey700: '#363B57',
    Grey800: '#31354E',
    Grey900: '#2D3148',
  };

  try {
    const { colors } = await tacklebox.getBrandForHostname();
    return {
      ...baseColors,
      ...colors,
    };
  } catch (e) {
    logger.info(e);
    return baseColors;
  }
};

const Colors = await fetchingColors();

export type ColorsType = TValueOf<typeof Colors>;

export default Colors;
