import React from 'react';
import styled from '@emotion/styled';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import IAddress from '@biproxi/models/interfaces/IAddress';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import GoogleMap from '../GoogleMap';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { media } from '../../utils/MediaQuery';
import AutoCompleteAddress from '../AutoCompleteAddress';
import Colors from '../../styles/Colors';
import { IToastConfig, ToastTypesEnum } from '../Toast';

const Container = styled.div`
  position: relative;
`;

const InputContainer = styled.div`
  position: relative;
  z-index: 100;
  background-color: ${Colors.White};
  padding: 0 0 24px;
  width: 100%;
`;

const MapContainer = styled.div`
  width: 780px;
  height: 480px;
  box-sizing: border-box;

  ${media.mobile} {
    width: 100%;
  }
`;

export type ManualAddressCoordinatesModalProps = {
  value?: IAddress;
  onChange?: (address: IAddress) => void;
};

const ManualAddressCoordinatesModal: React.FC<ManualAddressCoordinatesModalProps> = ({
  value,
  onChange,
}) => {
  /** State */
  const [coords, setCoords] = React.useState<{lat: number, lng: number} | null>(null);
  const [center, setCenter] = React.useState<{lat: number, lng: number} | null>(null);
  const [zoom, setZoom] = React.useState<number>(4);
  // const [marker, setMarker] = React.useState(null);

  /** Actions */
  const dispatch = useAppDispatch();

  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const setMapLocation = (address: IAddress) => {
    const defaultLat = 37.99618351189365;
    const defaultLng = -99.539266225;

    const currentLat = AddressUtil.getLatitudeAsFloat(address);
    const currentLng = AddressUtil.getLongitudeAsFloat(address);
    const hasCurrentLatLng = Boolean(currentLat) && Boolean(currentLng);

    if (hasCurrentLatLng) {
      setCoords({
        lat: parseFloat(currentLat.toString()),
        lng: parseFloat(currentLng.toString()),
      });
      setCenter({
        lat: parseFloat(currentLat.toString()),
        lng: parseFloat(currentLng.toString()),
      });
      setZoom(15);
    } else {
      setCoords(null);
      setCenter({ lat: defaultLat, lng: defaultLng });
      setZoom(4);
    }
  };

  React.useEffect(() => {
    setMapLocation(value);
  }, []);

  /** A super hacky hack that renders a unique pin on the new map */
  let marker;
  const renderMarker = (location, map) => {
    if (marker == null) {
      // eslint-disable-next-line no-undef
      marker = new google.maps.Marker({
        position: location,
        map,
      });
    } else {
      marker.setPosition(location);
    }
  };

  /* Render */
  return (
    <ModalContainer>
      <ModalHeader title="Select property location" close={popModal} />
      <ModalContent>
        <Container>
          <InputContainer>
            <AutoCompleteAddress
              label="Search for an address"
              placeholder="Enter the address"
              onChange={(address: IAddress) => {
                setMapLocation(address);
              }}
              showUnit={false}
            />
          </InputContainer>
          <MapContainer>
            {(() => (
              <GoogleMap
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
                center={center}
                onClick={(e) => {
                  setCoords({
                    lat: e.lat,
                    lng: e.lng,
                  });
                }}
                onLoad={({ map, maps }) => {
                  /** Hack to add a pin onClick to the map */
                  maps.event.addListener(map, 'click', (event) => { renderMarker(event.latLng, map); });
                }}
                zoom={zoom}
                options={{
                  disableDefaultUI: true,
                  zoomControl: true,
                  fullscreenControl: true,
                  draggableCursor: 'pointer',
                }}
                shouldUnregisterMapOnUnmount
              />
            ))()}
          </MapContainer>
        </Container>
      </ModalContent>
      <ModalFooter>
        <Flex justify="flex-end" width="100%">
          <Button
            text="Cancel"
            type={ButtonTypesEnum.Ghost}
            size={ButtonSizesEnum.Medium}
            onClick={() => popModal()}
            margin="0px 8px 0px 0px"
          />
          <Button
            text="Confirm location"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            onClick={() => {
              if (!coords?.lat || !coords?.lng) {
                pushToast({
                  type: ToastTypesEnum.Error,
                  message: 'Please select a location on the map before continuing.',
                });
              } else {
                onChange({
                  ...value,
                  location: {
                    type: 'Point',
                    coordinates: [
                      coords.lng.toString(),
                      coords.lat.toString(),
                    ],
                  },
                });
                popModal();
              }
            }}
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default ManualAddressCoordinatesModal;
