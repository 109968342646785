import React from 'react';
import styled from '@emotion/styled';
import * as Polished from 'polished';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from './Text';
import Flex from './Flex';
import KeyCodesEnum from '../models/enums/KeyCodesEnum';

type ContainerProps = {
  margin?: string;
}
const Container = styled.div<ContainerProps>`
  width: fit-content;
  margin: ${(props) => props.margin};
`;

type InputProps = {
  error?: boolean;
};

const Input = styled.input<InputProps>`
  border: ${(props) => (props.error
    ? `1px solid ${Colors.Red500}`
    : `1px solid ${Colors.Grey300}`)};
  box-sizing: border-box;
  height: 56px;
  width: 48px;
  border-radius: 4px;
  background-color: white;
  transition: all 0.1s;
  caret-color: transparent;
  margin-right: 8px;
  outline: 0px;
  font-size: 24px;
  text-align: center;
  padding: 0px;
  /* box-shadow: ${(props) => (props.error
    ? `0px 0px 0px 4px ${Polished.rgba(Colors.Red500, 0.10)}`
    : null)}; */
  -moz-appearance: textfield;


  &:last-of-type {
    margin-right: 0px;
  }

  &:focus {
    border: ${(props) => (props.error
    ? `1px solid ${Colors.Red500}`
    : `1px solid ${Colors.Brand700 || Colors.Blurple700}`)};
    box-shadow: ${(props) => (props.error
    ? `0px 0px 0px 4px ${Polished.rgba(Colors.Red500, 0.10)}`
    : `0px 0px 0px 4px ${Polished.rgba(Colors.Brand700 || Colors.Blurple700, 0.10)}`)};
  }

  &:hover {
    &:focus {
      border: ${(props) => (props.error
    ? `1px solid ${Colors.Red500}`
    : `1px solid ${Colors.Brand700 || Colors.Blurple700}`)};
    }
    border: ${(props) => (props.error
    ? `1px solid ${Colors.Red500}`
    : `1px solid ${Colors.Grey400}`)};
  }

  ::placeholder {
    color: ${Colors.Grey300};
  }

  &::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

type CodeInputProps = {
  length?: number;
  onChange?: Function;
  onComplete?: Function;
  margin?: string;
  error?: string | null;
  'data-cy'?: string,
}

const CodeInput = React.forwardRef<HTMLInputElement, CodeInputProps>(({
  onChange,
  onComplete,
  length = 6,
  margin,
  error,
  'data-cy': dataCy,
}, _ref) => {
  const [value, setValue] = React.useState<Array<string>>(new Array(length).fill(''));
  const inputs: any[] = [];

  const change = (valueAt: any, index: any) => {
    const currentValue = [...value];
    if (valueAt.length === 1) {
      currentValue[index] = valueAt;
      setValue(currentValue);
      if (inputs[index + 1]) {
        inputs[index + 1].focus();
      }
    } else if (valueAt.length === length) {
      setValue(valueAt.split(''));
      onComplete(valueAt);
      if (inputs[index + 1]) {
        inputs[index + 1].focus();
      }
    } else if (valueAt.length > 0 && valueAt.length < length) {
      change(valueAt.charAt(valueAt.length - 1), index);
    }

    if (index + 1 === length) {
      onComplete(currentValue.join(''));
    }
  };

  const renderInput = (index: any) => {
    const curValue = value[index];
    return (
      <Input
        data-cy={dataCy}
        error={Boolean(error)}
        key={index}
        placeholder="●"
        autoFocus={index === 0}
        value={curValue}
        type="number"
        pattern="\d*"
        // eslint-disable-next-line no-return-assign
        ref={(ref) => inputs[index] = ref}
        onChange={(event) => {
          onChange();
          change(event.target.value, index);
        }}
        onKeyDown={(event) => {
          if (event.keyCode === KeyCodesEnum.BACKSPACE || event.keyCode === KeyCodesEnum.DELETE) { // handle backspace or delete
            event.preventDefault();
            const currentValue = [...value];
            currentValue[index] = '';
            setValue(currentValue);
            if (index !== 0) {
              inputs[index - 1].focus();
            }
          } else if (event.keyCode === KeyCodesEnum.LEFT_ARROW) { // navigate left with left arrow key
            event.preventDefault();
            if (index !== 0) {
              inputs[index - 1].focus();
            }
          } else if (event.keyCode === KeyCodesEnum.RIGHT_ARROW) { // navigate right with right arrow key
            event.preventDefault();
            if (index !== value.length - 1) {
              inputs[index + 1].focus();
            }
          } else if ( // prohibit weird behavior when up, down, +, -, or e are pressed
            event.keyCode === KeyCodesEnum.UP_ARROW
            || event.keyCode === KeyCodesEnum.DOWN_ARROW
            || event.keyCode === KeyCodesEnum.KEY_E
            || event.keyCode === KeyCodesEnum.ADD
            || event.keyCode === KeyCodesEnum.SUBTRACT) {
            event.preventDefault();
          }
        }}
      />
    );
  };

  return (
    <Container margin={margin}>
      <Flex justify="space-between">
        <Text type={TextTypesEnum.Medium12} color={Colors.Red500} margin="0 0 4px">&nbsp;</Text>
        {error && <Text type={TextTypesEnum.Medium12} color={Colors.Red500} margin="0 0 4px">{error}</Text>}
      </Flex>
      {value.map((_, index) => renderInput(index))}
    </Container>
  );
});

export default CodeInput;
