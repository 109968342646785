import { gql } from '@apollo/client';
import ListingFragments from '../fragments/listing.fragments';

const DELETE_LISTING_FROM_WEBFLOW = gql`
  mutation DeleteListingFromWebflow($params: DeleteListingFromWebflowParams!) {
    deleteListingFromWebflow(params: $params) {
      ...ListingFields
      ...ListingAddress
      ...ListingPortfolioAddresses
      ...ListingSchedule
      ...ListingMedia
      ...ListingGuidance
      ...ListingVault
      ...ListingLicense
      ...ListingMetrics
      ...ListingMeta
      ...ListingUser
      ...ListingContacts
    }
  }

  ${ListingFragments.fields}
  ${ListingFragments.address}
  ${ListingFragments.portfolioAddresses}
  ${ListingFragments.schedule}
  ${ListingFragments.media}
  ${ListingFragments.guidance}
  ${ListingFragments.vault}
  ${ListingFragments.license}
  ${ListingFragments.metrics}
  ${ListingFragments.meta}
  ${ListingFragments.user}
  ${ListingFragments.contacts}
`;

export default DELETE_LISTING_FROM_WEBFLOW;
