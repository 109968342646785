import React from 'react';
import { useQuery } from '@apollo/client';
import { IError } from '@biproxi/models/interfaces/common';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import * as ILeadService from '@biproxi/models/services/ILeadService';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import useLead from './useLead.hook';
import GET_LEAD_BY_LISTING_AND_USER_ID from '../graphql/queries/leadByListingAndUserId.query';

type UseIsPrivateListingConfigParams = {
    listing: IListingGraphQL;
    user: IUserGraphQL;
}

type UseIsPrivateListingConfig = {
    onComplete?: (result: boolean) => void;
    onError?: (error: IError) => void;
    params: UseIsPrivateListingConfigParams;
}

type UseIsPrivateListingHook = {
    isPrivateListing: boolean;
    isPrivateListingAccess: boolean;
    loading: boolean;
    error: IError | null;
};

function useIsPrivateListing(
  config: UseIsPrivateListingConfig = { params: {} as UseIsPrivateListingConfigParams },
): UseIsPrivateListingHook {
  /** State */
  const { params } = config;
  const [isPrivateListing, setIsPrivateListing] = React.useState<boolean>(false);
  const [isPrivateListingAccess, setIsPrivateListingAccess] = React.useState<boolean>(false);
  const lead = useLead()?.lead;
  const user = params?.user;
  const listing = params?.listing;
  const isListingOwner = ListingUtil.isOwner(listing, user?._id);

  /** GraphQL */
  type LeadData = { getLeadByListingAndUserId?: ILeadGraphQL };

  type LeadParams = ILeadService.TGetLeadPayload;

  const { data: leadData } = useQuery<LeadData, LeadParams>(GET_LEAD_BY_LISTING_AND_USER_ID, {
    skip: !listing?._id || !user,
    variables: {
      params: {
        userId: user?._id,
        listingId: listing?._id,
      },
    },
    onCompleted: (data) => {
      if (data?.getLeadByListingAndUserId?.privateListingAccess?.accessApproved) {
        setIsPrivateListingAccess(true);
      }
    },
  });

  React.useEffect(() => {
    if (listing && listing?.isPrivateListing) {
      setIsPrivateListing(true);
    }
    if (listing && lead) {
      if (listing?.isPrivateListing && leadData?.getLeadByListingAndUserId?.privateListingAccess?.accessApproved) {
        setIsPrivateListingAccess(true);
      }
    }
  }, []);

  return {
    isPrivateListing,
    isPrivateListingAccess: isListingOwner ? true : isPrivateListingAccess,
    loading: false,
    error: null,
  };
}

export default useIsPrivateListing;
