import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { IShareListingWithNylasParams, IShareListingWithNylasResponse } from '@biproxi/models/services/INylasService';
import parse, { Element } from 'html-react-parser';
import IUserNylasSettings from '@biproxi/models/interfaces/IUserNylasSettings';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { useQuery as rqQuery } from 'react-query';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import { ModalTypesEnum } from './Modal';
import Flex from '../../elements/Flex';
import SHARE_LISTING_WITH_NYLAS from '../../graphql/mutations/shareListingWithNylas.mutation';
import Loader, { LoaderSizes } from '../../elements/Loader';
import Input, { InputTypesEnum } from '../../elements/Input';
import Divider from '../../elements/Divider';
import { ConfirmChangeModalTypesEnum } from './ConfirmChangeModal';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { UserActions } from '../../redux/user.redux';
import { useMobileMedia } from '../../utils/MediaQuery';
import generateEmailPreview from '../../next-api/queries/email/generateEmailPreview.query';

const EmailPreviewContainer = styled.div`
  border: 1px solid ${Colors.Grey300};
  border-radius: 4px;
  padding: 12px 24px;
`;

const EmailCustomMessageContainer = styled.div`
  word-wrap: break-word;
  max-width: 100%;
`;

export type EmailPreviewModalProps = {
  listing: IListingGraphQL;
  contacts: string[];
};

const EmailPreviewModal: React.FC<EmailPreviewModalProps> = ({ listing, contacts }) => {
  /** State */
  const [customSubject, setCustomSubject] = React.useState<string>('');
  const [customMessage, setCustomMessage] = React.useState<string>('');
  const [customSubjectError, setCustomSubjectError] = React.useState<string>(null);
  const [customMessageError, setCustomMessageError] = React.useState<string>(null);

  /** Hooks */
  const isMobile = useMobileMedia();

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushShareListingSuccessModal = (emailsSent: number, dailyEmailLimit: number) => dispatch(AppActions.pushModal({
    type: ModalTypesEnum.ConfirmChange,
    props: {
      type: ConfirmChangeModalTypesEnum.Success,
      title: 'Success',
      text: `Listing shared with ${emailsSent} contacts. You can send emails to up to ${dailyEmailLimit} more contacts today.`,
      confirm: () => popModal(),
    },
  }));

  const sharelisting = () => {
    if (!customSubject) {
      setCustomSubjectError('Please add a subject');
      return;
    }
    if (!customMessage) {
      setCustomMessageError('Please add a message');
      return;
    }
    shareListingWithNylas();
  };

  const setUserNylasSettings = (nylasSettings: Partial<IUserNylasSettings>) => dispatch(
    UserActions.setUserNylasSettings(nylasSettings),
  );

  /** Data */
  const { data: emailPreview, error: emailPreviewError } = rqQuery(['email-generate-html'], () => generateEmailPreview({
    emailConfig: {
      emailType: 'Marketing Share Listing',
      emailProps: {
        listing,
        listingImageUrl: listing.media.files[0].url,
        customMessage,
      },
    },
  }));

  type ShareListingParams = {
    params: IShareListingWithNylasParams,
  }
  type ShareListingData = {
    shareListingWithNylas: IShareListingWithNylasResponse,
  }

  const [shareListingWithNylas, { loading: shareListingLoading }] = useMutation<ShareListingData, ShareListingParams>(SHARE_LISTING_WITH_NYLAS, {
    variables: {
      params: {
        listingId: listing._id,
        contactList: contacts,
        customSubject,
        customMessage,
        userId: listing.user._id,
      },
    },
    onCompleted: (data) => {
      const { shareListingWithNylas: { dailyEmailLimit, emailsSent, emailLastSentAt } } = data;
      setUserNylasSettings({
        dailyEmailLimit,
        emailLastSentAt,
      });
      popModal();
      pushShareListingSuccessModal(emailsSent, dailyEmailLimit);
    },
    onError: () => {
      popModal();
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error sharing this listing. Please try again later.',
      });
    },
  });

  /** Render */
  return (
    <ModalContainer height="calc(100% - 32px)" width={isMobile ? 'calc(100vw - 32px)' : '50vw'} overflow="auto">
      <ModalHeader title="Email" close={popModal} />
      <ModalContent>
        <Text
          type={TextTypesEnum.Regular16}
          margin="0 0 20px 0"
        >
          Add a custom subject and message to your email before sending.
        </Text>
        <EmailPreviewContainer>
          <Input
            inputType={InputTypesEnum.EmailText}
            placeholder="Subject:"
            value={customSubject}
            onChange={(e) => {
              setCustomSubject(e.target.value);
              setCustomSubjectError(null);
            }}
            error={customSubjectError}
          />
          <Divider color={Colors.Grey200} margin="1px 0px" />
          <Input
            inputType={InputTypesEnum.EmailText}
            placeholder="Message:"
            value={customMessage}
            onChange={(e) => {
              setCustomMessage(e.target.value);
              setCustomMessageError(null);
            }}
            error={customMessageError}
            margin="0px 0px 8px"
          />
          {/* Here, we parse the html of the email preview in order to render it.
        We replace a marked div with a Text element that displays the value of
        the custom message input */}
          {emailPreviewError ? (
            <Flex flex="1" align="center" justify="center" direction="column">
              <Text type={TextTypesEnum.Medium18} margin="8px 0 0">Email preview unavailable</Text>
            </Flex>
          ) : (
            <>
              {emailPreview ? (
                <>
                  {parse(emailPreview, {
                    replace: (domNode: Element) => {
                      if (domNode?.attribs?.id === 'replace') {
                        return (
                          <EmailCustomMessageContainer>
                            <Text
                              type={TextTypesEnum.Bold16}
                              margin="0 0 20px 0"
                            >
                              {customMessage}
                            </Text>
                          </EmailCustomMessageContainer>
                        );
                      }
                      return domNode;
                    },
                  })}
                </>
              ) : (
                <Flex flex="1" align="center" justify="center" direction="column">
                  <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
                </Flex>
              )}
            </>
          )}
        </EmailPreviewContainer>
      </ModalContent>
      <ModalFooter>
        <Flex justify="flex-end" width="100%">
          <Button
            text="Cancel"
            type={ButtonTypesEnum.Outline}
            size={ButtonSizesEnum.Medium}
            onClick={() => popModal()}
            margin="0px 8px 0px 0px"
          />
          <Button
            text="Send email"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            onClick={() => sharelisting()}
            isLoading={shareListingLoading}
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default EmailPreviewModal;
