import { RegionalFocusEnum } from '../enums/InvestorProfileAnswersEnums';
import { AmericanStatesExpandedEnum } from '../enums/AmericanStatesEnums';

const dictionary = {
  [RegionalFocusEnum.Northeast]: [
    AmericanStatesExpandedEnum.Connecticut,
    AmericanStatesExpandedEnum.Maine,
    AmericanStatesExpandedEnum.Massachusetts,
    AmericanStatesExpandedEnum.NewHampshire,
    AmericanStatesExpandedEnum.RhodeIsland,
    AmericanStatesExpandedEnum.Vermont,
    AmericanStatesExpandedEnum.NewYork,
    AmericanStatesExpandedEnum.Pennsylvania,
    AmericanStatesExpandedEnum.NewJersey,
  ],
  [RegionalFocusEnum.Southeast]: [
    AmericanStatesExpandedEnum.NorthCarolina,
    AmericanStatesExpandedEnum.SouthCarolina,
    AmericanStatesExpandedEnum.Georgia,
    AmericanStatesExpandedEnum.Florida,
    AmericanStatesExpandedEnum.Alabama,
    AmericanStatesExpandedEnum.Mississippi,
    AmericanStatesExpandedEnum.Tennessee,
    AmericanStatesExpandedEnum.Arkansas,
    AmericanStatesExpandedEnum.Louisiana,
  ],
  [RegionalFocusEnum['Mid-Atlantic']]: [
    AmericanStatesExpandedEnum.Maryland,
    AmericanStatesExpandedEnum.WashingtonDC,
    AmericanStatesExpandedEnum.Delaware,
    AmericanStatesExpandedEnum.Virginia,
    AmericanStatesExpandedEnum.WestVirginia,
    AmericanStatesExpandedEnum.Kentucky,
  ],
  [RegionalFocusEnum.Northwest]: [
    AmericanStatesExpandedEnum.Washington,
    AmericanStatesExpandedEnum.Oregon,
    AmericanStatesExpandedEnum.Idaho,
    AmericanStatesExpandedEnum.Wyoming,
    AmericanStatesExpandedEnum.Montana,
  ],
  [RegionalFocusEnum.Southwest]: [
    AmericanStatesExpandedEnum.Arizona,
    AmericanStatesExpandedEnum.NewMexico,
    AmericanStatesExpandedEnum.Texas,
    AmericanStatesExpandedEnum.Oklahoma,
  ],
  [RegionalFocusEnum.West]: [
    AmericanStatesExpandedEnum.California,
    AmericanStatesExpandedEnum.Nevada,
    AmericanStatesExpandedEnum.Utah,
    AmericanStatesExpandedEnum.Colorado,
  ],
  [RegionalFocusEnum.Midwest]: [
    AmericanStatesExpandedEnum.NorthDakota,
    AmericanStatesExpandedEnum.SouthDakota,
    AmericanStatesExpandedEnum.Nebraska,
    AmericanStatesExpandedEnum.Kansas,
    AmericanStatesExpandedEnum.Missouri,
    AmericanStatesExpandedEnum.Iowa,
    AmericanStatesExpandedEnum.Minnesota,
    AmericanStatesExpandedEnum.Wisconsin,
    AmericanStatesExpandedEnum.Illinois,
    AmericanStatesExpandedEnum.Indiana,
    AmericanStatesExpandedEnum.Michigan,
    AmericanStatesExpandedEnum.Ohio,
  ],
  [RegionalFocusEnum.Hawaii]: [AmericanStatesExpandedEnum.Hawaii],
  [RegionalFocusEnum.Alaska]: [AmericanStatesExpandedEnum.Alaska],
};

const AmericanStatesDictionaryUtil = {
  dictionary,
};

export default AmericanStatesDictionaryUtil;
