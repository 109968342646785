import React from 'react';

export const Ellipses = '...';

const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
};

type UsePaginationParams = {
    totalCount: number;
    maxVisibleData: number;
    siblingCount?: number;
    currentPage: number;
}

type UsePaginationHook = (params?: UsePaginationParams) => number[];

/**
 * Returns an array of page numbers (as well as Ellipses ...) that should be visible in the pagination
 */
const usePaginationHook: UsePaginationHook = ({
  totalCount,
  maxVisibleData,
  siblingCount = 3,
  currentPage,
}) => {
  // eslint-disable-next-line consistent-return
  const paginationRange = React.useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / maxVisibleData);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*Ellipses
    const totalPageNumbers = siblingCount + 5;

    /*
        Case 1:
        If the number of pages is less than the page numbers we want to show in our
        paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /*
        Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount,
    );

    /*
        We do not show ellipses just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftEllipses = leftSiblingIndex > 2;
    const shouldShowRightEllipses = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
        Case 2: No left Ellipses to show, but rights Ellipses to be shown
    */
    if (!shouldShowLeftEllipses && shouldShowRightEllipses) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, Ellipses, totalPageCount];
    }

    /*
        Case 3: No right Ellipses to show, but left Ellipses to be shown
    */
    if (shouldShowLeftEllipses && !shouldShowRightEllipses) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount,
      );
      return [firstPageIndex, Ellipses, ...rightRange];
    }

    /*
        Case 4: Both left and right Ellipses to be shown
    */
    if (shouldShowLeftEllipses && shouldShowRightEllipses) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, Ellipses, ...middleRange, Ellipses, lastPageIndex];
    }
  }, [totalCount, maxVisibleData, siblingCount, currentPage]);

  return paginationRange;
};

export default usePaginationHook;
