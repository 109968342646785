import gql from 'graphql-tag';

const GET_ORGANIZATION = gql`
  query GetOrganization($where: OrganizationWhere) {
    organizations(where: $where) {
      _id
      name
      description
      listingsConnection {
        edges {
          node {
            _id
            address1
            address2
            addressState
            assetClass
            propertyType
            city
            description
            name
            metaCreatedAt
            metaLastUpdatedAt
            zip
            state
            mediaFileIds
            createdByUser {
              _id
              lastName
              firstName
            }
          }
        }
      }
      usersConnection {
        edges {
          permissions
          roles
          node {
            _id
            firstName
            email
            lastName
          }
        }
        totalCount
      }
    }
  }
`;

export default GET_ORGANIZATION;
