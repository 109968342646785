import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import * as ILeadService from '@biproxi/models/services/ILeadService';
import {
  ModalContainer,
  ModalHeaderLead,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import Colors from '../../styles/Colors';
import LeadModalChat from './LeadModalChat';
import Loader, { LoaderSizes } from '../../elements/Loader';
import Flex from '../../elements/Flex';
import IButtonOptions from '../../models/interfaces/IButtonOptions';
import LeadModalOffers from './LeadModalOffers';
import GET_LEAD from '../../graphql/queries/lead.query';
import { ListingActions } from '../../redux/listing.redux';
import LeadModalFiles from './LeadModalFiles';

const Container = styled.div`
  width: 520px;
  margin-top: -8px;

  ${media.mobile} {
    width: 100%;
  }
`;

const Content = styled.div`
  height: 65vh;
  /* overflow: scroll; */
`;

export enum BuyerLeadModalPageStateEnum {
  Offers = 'Offers',
  Files = 'Files',
  Chat = 'Chat',
}

export type BuyerLeadModalProps = {
  initialState: BuyerLeadModalPageStateEnum
  listingId: string;
  doNotSelectListing?: boolean;
};

const BuyerLeadModal: React.FC<BuyerLeadModalProps> = ({
  initialState,
  listingId,
  doNotSelectListing,
}) => {
  /** State */
  const [pageState, setPageState] = React.useState(initialState || BuyerLeadModalPageStateEnum.Offers);

  /** GraphQL */
  type Data = {
    lead: ILeadGraphQL;
  }

  type Vars = ILeadService.TGetLeadPayload;

  const { data } = useQuery<Data, Vars>(GET_LEAD, {
    variables: {
      params: {
        listingId,
      },
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );
  const selectListing = (listingId: string | null) => dispatch(ListingActions.selectListing({ listingId }));

  /** Hooks */
  const isMobile = useMobileMedia();

  /** Effects */
  // This seems weird, why not just use the listing passed in?
  React.useEffect(() => {
    if (!doNotSelectListing) selectListing(lead?.listingId);
    return () => {
      if (!doNotSelectListing) selectListing(null);
    };
  }, [data]);

  /* Render */
  const leadModalNavItems: IButtonOptions[] = [
    {
      text: 'Offers',
      onClick: () => setPageState(BuyerLeadModalPageStateEnum.Offers),
      active: pageState === BuyerLeadModalPageStateEnum.Offers,
    },
    {
      text: 'Files',
      onClick: () => setPageState(BuyerLeadModalPageStateEnum.Files),
      active: pageState === BuyerLeadModalPageStateEnum.Files,
    },
    {
      text: 'Chat',
      onClick: () => setPageState(BuyerLeadModalPageStateEnum.Chat),
      active: pageState === BuyerLeadModalPageStateEnum.Chat,
    },
  ];

  const lead = data?.lead;

  return (
    <ModalContainer height={isMobile ? 'fill-available' : ''}>
      <ModalHeaderLead
        listingOnly
        listing={lead?.listing}
        close={popModal}
        navItems={leadModalNavItems}
      />
      <ModalContent>
        <Container>
          <Content>
            {(() => {
              if (!lead) {
                return (
                  <Flex height="100%" align="center" justify="center">
                    <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
                  </Flex>
                );
              }

              switch (pageState) {
                case BuyerLeadModalPageStateEnum.Offers:
                  return <LeadModalOffers lead={lead} buyer />;
                case BuyerLeadModalPageStateEnum.Files:
                  return <LeadModalFiles lead={lead} buyer />;
                case BuyerLeadModalPageStateEnum.Chat:
                  return <LeadModalChat lead={lead} />;
                default:
                  return <LeadModalOffers lead={lead} buyer />;
              }
            })()}
          </Content>
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default BuyerLeadModal;
