import React from 'react';
import styled from '@emotion/styled';
import FileUtil from '@biproxi/models/utils/FileUtil';
import IFileParams from '@biproxi/models/interfaces/IFileParams';
import IFile from '@biproxi/models/interfaces/IFile';
import wait from '@biproxi/utils/wait';
import { useAppDispatch } from '../redux/store';
import Colors from '../styles/Colors';
import { Icons } from '../elements/Icon';
import AnchoredMenu from '../elements/AnchoredMenu';
import SquareIconButton from '../elements/SquareIconButton';
import { AppActions } from '../redux/app.redux';
import FileUploadBarStatus from './FileUploadBarStatus';

const Container = styled.div`
  position: relative;
  /* height: 100%; */
  width: auto;
`;

const LoaderBar = styled.div<{width: string}>`
  position: absolute;
  height: 100%;
  box-sizing: border-box;
  background-color: ${Colors.Blurple5};
  width: ${(props) => `calc(${props.width}%)`};
  z-index: 1000;
`;

const FileContainer = styled.div`
  width: 100%;
  background: ${Colors.White};
  border-radius: 4px;
  border: 1px solid ${Colors.Grey300};
  display: flex;
  position: relative;
  padding: 8px;
  align-items: center;
  box-sizing: border-box;
  transition: all 0.2s;
  height: auto;
`;

const MenuRef = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MenuContainer = styled.div`
  z-index: 3;
  position: fixed;
`;

type LOIFileProps = {
  file: IFile | IFileParams;
  deleteFile: () => void;
};

const LOIFile: React.FC<LOIFileProps> = ({
  file,
  deleteFile,
}) => {
  /* State */
  const isFile = FileUtil.isFile(file);
  const [percent, setPercent] = React.useState(0);

  /* Hooks */
  React.useEffect(() => {
    async function inc() {
      if (percent < 99) {
        await wait(20);
        setPercent(percent + 3);
      }
    }
    inc();
  }, [percent]);

  /* Actions */
  const dispatch = useAppDispatch();

  const viewLOI = () => dispatch(
    AppActions.previewFiles({
      fileId: file._id,
      files: [file as IFile],
      track: false,
    }),
  );

  const menuItems = [
    {
      text: 'Preview',
      onClick: (e) => {
        e.stopPropagation();
        viewLOI();
      },
    },
    {
      text: 'Delete',
      onClick: (e) => {
        e.stopPropagation();
        deleteFile();
      },
    },
  ];

  const ref = React.useRef(null);

  /* Render */
  // TODO: Improve the loading
  return (
    <Container>
      {!isFile && <LoaderBar width={percent.toString()} />}
      <FileContainer>
        <FileUploadBarStatus percent={percent} file={file} />
        <ButtonContainer>
          <MenuRef ref={ref}>
            <SquareIconButton
              icon={Icons.EllipsisHRegular}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </MenuRef>
        </ButtonContainer>
      </FileContainer>
      <MenuContainer>
        <AnchoredMenu
          anchorRef={ref}
          menuItems={menuItems}
        />
      </MenuContainer>
    </Container>
  );
};

export default LOIFile;
