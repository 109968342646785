import TimeUtil from './TimeUtil';
import IAnalyticsEvent from '../interfaces/IAnalyticsEvent';

function groupByDay(events: IAnalyticsEvent[]): Record<string, IAnalyticsEvent[]> {
  return events.reduce((cur, next) => {
    const date = TimeUtil.date(
      Number(next.createdAt),
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    ).startOf('day').toMillis();

    if (Object.prototype.hasOwnProperty.call(cur, date)) {
      cur[date].unshift(next);
    } else {
      cur[date] = [next];
    }

    return cur;
  }, {});
}

const AnalyticsEventUtil = {
  groupByDay,
};

export default AnalyticsEventUtil;
