import React from 'react';
import styled from '@emotion/styled';
import Text, { TextTypesEnum } from '../elements/Text';
import Colors from '../styles/Colors';
import BoxShadows from '../styles/BoxShadows';
import {
  AppState, useAppSelector,
} from '../redux/store';
import Icon, { Icons } from '../elements/Icon';
import * as Motion from '../elements/Motion';
import Flex from '../elements/Flex';

export enum ToastTypesEnum {
  Notification = 'Notification',
  Warning = 'Warning',
  Error = 'Error',
}

export interface IToastConfig {
  _id?: string;
  type: ToastTypesEnum;
  message: string;
  duration?: number;
  'data-cy'?: string;
}

const Container = styled.div`
  position: fixed;
  width: fit-content;
  width: 300px;
  left: calc((100% - 300px) / 2);
  top: 8px;
  z-index: 10000000000;
`;

type ToastItemProps = {
  type: ToastTypesEnum;
  'data-cy'?: string;
}
const ToastItem = styled(Motion.CardPop)<ToastItemProps>`
  border-radius: 8px;
  margin-top: 16px;
  padding: 12px;
  display: flex;
  align-items: center;
  box-shadow: ${BoxShadows.Standard};
  background-color: ${Colors.White};
  border: 1px solid ${Colors.Grey200};
`;

export type ToastProps = {};

const Toast: React.FC<ToastProps> = () => {
  /** State */
  const { toasts } = useAppSelector((state: AppState) => state.app);

  /** Render */
  return (
    <Container>
      {toasts.map((toast: IToastConfig, index: number) => {
        const { icon, color } = (() => {
          switch (toast.type) {
            case ToastTypesEnum.Warning:
              return {
                icon: Icons.ExclamationTriangleSolid,
                color: Colors.Yellow500,

              };
            case ToastTypesEnum.Error:
              return {
                icon: Icons.TimesCircleSolid,
                color: Colors.Red500,

              };
            case ToastTypesEnum.Notification:
            default:
              return {
                icon: Icons.CheckCircleSolid,
                color: Colors.Green500,
              };
          }
        })();
        return (
          <ToastItem
            key={index}
            type={toast.type}
            data-cy={`${toast.type.toLocaleLowerCase()}-modal-container`}
          >
            <Flex width="100%" align="flex-start">
              <Icon icon={icon} color={color} size={16} margin="4px 12px 0 0" />
              <Flex>
                <Text data-cy={`${toast.type.toLocaleLowerCase()}-modal-text`} type={TextTypesEnum.Medium14} color={Colors.Grey900}>{toast.message}</Text>
              </Flex>
              {/* <Icon icon={Icons.TimesRegular} color={Colors.Grey500} hoverColor={Colors.Red500} size={18} onClick={() => popToast(toast._id)} margin="0 0 0 12px" /> */}
            </Flex>
          </ToastItem>
        );
      })}
    </Container>
  );
};

export default Toast;
