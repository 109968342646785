import gql from 'graphql-tag';

const REMOVE_LISTING_FROM_ORGANIZATION = gql`
  mutation removeListingFromOrganization($where: OrganizationWhere, $disconnect: OrganizationDisconnectInput) {
    updateOrganizations(where: $where, disconnect: $disconnect) {
      info {
        relationshipsDeleted
      }
    }
  }
`;

export default REMOVE_LISTING_FROM_ORGANIZATION;
