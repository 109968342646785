import {
  createAction, createReducer, PayloadAction,
} from '@reduxjs/toolkit';
import IPageConfiguration from '../models/interfaces/IPageConfiguration';
import { AppState } from './store';

export type NotificationReducerState = {
  unreadNotificationsCount: number | null;
  notificationSearch: string;
};

export function notificationReducerState(_config?: IPageConfiguration): NotificationReducerState {
  return {
    unreadNotificationsCount: null,
    notificationSearch: '',
  };
}

export enum NotificationActionTypesEnum {
  SetUnreadNotificationsCount = 'SetUnreadNotificationsCount',
  IncrementUnreadNotificationsCount = 'IncrementUnreadNotificationsCount',
  DecrementUnreadNotificationsCount = 'DecrementUnreadNotificationsCount',
  SetNotificationSearch = 'SetNotificationSearch',
}

/** ******************************************************************************
 *  Set Unread Notifications
 ****************************************************************************** */

// Payload
type SetUnreadNotificationsCountPayloadType = {
  unreadNotificationsCount: number | null;
};

// Action
const setUnreadNotificationsCount = createAction<SetUnreadNotificationsCountPayloadType, NotificationActionTypesEnum.SetUnreadNotificationsCount>(NotificationActionTypesEnum.SetUnreadNotificationsCount);

// Reducer
function setUnreadNotificationsCountReducer(state: NotificationReducerState, action: PayloadAction<SetUnreadNotificationsCountPayloadType>) {
  const { unreadNotificationsCount } = action.payload;
  state.unreadNotificationsCount = unreadNotificationsCount;
  return state;
}

/** ******************************************************************************
 *  Increment Unread Notifications
 ****************************************************************************** */

// Payload
type IncrementUnreadNotificationsCountPayloadType = {};

// Action
const incrementUnreadNotificationsCount = createAction<IncrementUnreadNotificationsCountPayloadType, NotificationActionTypesEnum.IncrementUnreadNotificationsCount>(NotificationActionTypesEnum.IncrementUnreadNotificationsCount);

// Reducer
function incrementUnreadNotificationsCountReducer(state: NotificationReducerState, _action: PayloadAction<IncrementUnreadNotificationsCountPayloadType>) {
  state.unreadNotificationsCount += 1;
  return state;
}

/** ******************************************************************************
 *  Decrement Unread Notifications
 ****************************************************************************** */

// Payload
type DecrementUnreadNotificationsCountPayloadType = {};

// Action
const decrementUnreadNotificationsCount = createAction<DecrementUnreadNotificationsCountPayloadType, NotificationActionTypesEnum.DecrementUnreadNotificationsCount>(NotificationActionTypesEnum.DecrementUnreadNotificationsCount);

// Reducer
function decrementUnreadNotificationsCountReducer(state: NotificationReducerState, _action: PayloadAction<DecrementUnreadNotificationsCountPayloadType>) {
  if (state.unreadNotificationsCount > 0) state.unreadNotificationsCount -= 1;
  return state;
}

/** ******************************************************************************
 *  Set Notification Search
 ****************************************************************************** */

// Payload
type SetNotificationSearchPayloadType = {
  notificationSearch: string;
};

// Action
const setNotificationSearch = createAction<SetNotificationSearchPayloadType, NotificationActionTypesEnum.SetNotificationSearch>(NotificationActionTypesEnum.SetNotificationSearch);

// Reducer
function setNotificationSearchReducer(state: NotificationReducerState, action: PayloadAction<SetNotificationSearchPayloadType>) {
  const { notificationSearch } = action.payload;
  state.notificationSearch = notificationSearch;
  return state;
}

/** ******************************************************************************
 *  Exports
 ****************************************************************************** */

export const NotificationSelectors = {
  unreadNotificationsCount: (app: AppState) => app.notification.unreadNotificationsCount,
  subscribeToNotifications: (app: AppState) => app.notification.subscribeToNotifications,
  notificationSearch: (app: AppState) => app.notification.notificationSearch,
  notificationPaginationOffset: (app: AppState) => app.notification.notificationPaginationOffset,
};

export type NotificationActionPayloadTypes = {
  SetUnreadNotificationsCountPayloadType: SetUnreadNotificationsCountPayloadType,
  SetNotificationSearchPayloadType: SetNotificationSearchPayloadType,
};

export const NotificationActions = {
  setUnreadNotificationsCount,
  incrementUnreadNotificationsCount,
  decrementUnreadNotificationsCount,
  setNotificationSearch,
};

const reducer = createReducer(notificationReducerState(), (builder) => builder
  .addCase(setUnreadNotificationsCount, setUnreadNotificationsCountReducer)
  .addCase(incrementUnreadNotificationsCount, incrementUnreadNotificationsCountReducer)
  .addCase(decrementUnreadNotificationsCount, decrementUnreadNotificationsCountReducer)
  .addCase(setNotificationSearch, setNotificationSearchReducer));

export default reducer;
