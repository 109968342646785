import gql from 'graphql-tag';

const DELETE_USER = gql`
  mutation DeleteUsers($where: UserWhere) {
    deleteUsers(where: $where) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export default DELETE_USER;
