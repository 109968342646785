import React from 'react';
import styled from '@emotion/styled';
import * as ListingUploadJoi from '@biproxi/models/joi/listingUpload.joi';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { useDropzone } from 'react-dropzone';
import IFileParams, { GraphQLFileUpload } from '@biproxi/models/interfaces/IFileParams';
import { useMutation, useQuery } from '@apollo/client';
import IFile from '@biproxi/models/interfaces/IFile';
import ListingUploadStateEnum from '@biproxi/models/enums/ListingUploadStateEnum';
import IListingUploadParams from '@biproxi/models/interfaces/IListingUploadParams';
import IListingUpload from '@biproxi/models/interfaces/IListingUpload';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import IListingParams from '@biproxi/models/interfaces/IListingParams';
import ListingUploadQueryTypesEnum from '@biproxi/models/enums/ListingUploadQueryTypesEnum';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import ILicense from '@biproxi/models/interfaces/ILicense';
import StringUtil from '@biproxi/models/utils/StringUtil';
import Text, { TextAsEnum, TextTypesEnum } from '../../elements/Text';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import Flex from '../../elements/Flex';
import Colors from '../../styles/Colors';
import CREATE_FILES from '../../graphql/mutations/createFiles.mutation';
import CREATE_LISTING_UPLOAD from '../../graphql/mutations/createListingUpload.mutation';
import Input, { InputTypesEnum } from '../../elements/Input';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import LOIFile from '../LOIFile';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import LinkText from '../../elements/LinkText';
import { ModalTypesEnum } from './Modal';
import { ConfirmChangeModalTypesEnum } from './ConfirmChangeModal';
import CREATE_LISTING from '../../graphql/mutations/createListing.mutation';
import LIST_LISTING_UPLOADS from '../../graphql/queries/listingUploads.query';
import { useMobileMedia } from '../../utils/MediaQuery';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import Loader from '../../elements/Loader';
import Dropdown, { DropdownTypesEnum } from '../../elements/Dropdown';
import GET_USER from '../../graphql/queries/user.query';
import NoContent from '../NoContent';
import Icons from '../../elements/Icons';

const Container = styled.div`
    max-width: 488px;
`;

const FileContainer = styled.div`
  margin: 0 0 8px 0;
`;

const FileUploadWrapper = styled.div`
    margin: 0 0 24px 0;
`;

const ListingNameWrapper = styled.div`
    margin: 0 0 24px 0;
`;

type NoLicenseWrapperProps = {
  error: boolean;
}

const NoLicenseWrapper = styled.div<NoLicenseWrapperProps>`
  display: flex;
  flex-direction: column;
  border: ${(props) => {
    if (props.error) return `1px solid ${Colors.Red500}`;
    return null;
  }};
  border-radius: 4px;
  padding: ${(props) => {
    if (props.error) return '0 8px 8px 8px';
    return '0px';
  }};
`;

const LicenseWrapper = styled.div`
    margin: 0 0 24px 0;
`;

const DescriptionWrapper = styled.div`
    margin: 0 0 24px 0;
`;

const AddMoreFiles = styled.div`
  width: fit-content;
`;

const Dashed = styled.div<{ error?: boolean }>`
  border: ${(props) => {
    if (props.error) return `1px solid ${Colors.Red500}`;
    return `1px dashed ${Colors.Grey300}`;
  }};
  padding: 12px 16px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

export type UploadListingModalProps = {
  uploadedListing?: IListingGraphQL;
};

const UploadListingModal: React.FC<UploadListingModalProps> = () => {
  /** State */
  const [files, setFiles] = React.useState<IFile[]>([]);
  const [brokerLicense, setBrokerLicense] = React.useState<ILicense>(null);
  const [createdListingId, setCreatedListingId] = React.useState<string>(null);
  const [fileError, setFileError] = React.useState<boolean>(false);
  const [licenseError, setLicenseError] = React.useState<boolean>(false);

  /** Hooks */
  const isMobile = useMobileMedia();

  const formParams: UseFormParams = {
    fields: {
      listingName: '',
      additionalInfo: '',
      fileIds: null,
    },
    fieldOrder: ['/listingName', '/additionalInfo', '/fileIds'],
    schema: ListingUploadJoi.createListingUploadClientParamsSchema,
  };

  const {
    controllers: {
      listingName,
      additionalInfo,
      fileIds,
    },
    isValid,
  } = useForm(formParams);

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => {
    dispatch(AppActions.popModal());
  };
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushAddLicenseModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.AddLicense,
      props: {},
    }),
  );

  const { data: userData } = useQuery<{ user: IUserGraphQL }>(GET_USER);

  const replaceWithConfirmModal = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Success,
        title: 'Your document has been uploaded.',
        text: 'A Biproxi admin will review and create your listing shortly. Please check your email for further information.',
        confirm: (event: React.FormEvent) => {
          event?.preventDefault();
          popModal();
        },
      },
    }),
  );

  /** GraphQL */
  const [uploadFiles, { loading: fileLoading }] = useMutation(CREATE_FILES, {
    onCompleted: async (data) => {
      setFiles([...files, ...data?.createFiles]);
    },
  });

  interface CreateTempListingVars {
    params: Partial<IListingParams>;
  }

  interface CreateTempListingData {
    createListing: IListingGraphQL;
  }

  const [createTempListing] = useMutation<CreateTempListingData, CreateTempListingVars>(CREATE_LISTING, {
    onCompleted: async (newListingData) => {
      setCreatedListingId(newListingData?.createListing?._id);
    },
    onError: async (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);

      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
  });

  interface CreateListingUploadVars {
    params: IListingUploadParams;
  }

  interface CreateListingUploadData {
    createListingUpload: IListingUpload;
  }

  const [createListingUpload, { loading }] = useMutation<CreateListingUploadData, CreateListingUploadVars>(CREATE_LISTING_UPLOAD, {
    onCompleted: async () => {
      replaceWithConfirmModal();
    },
    onError: async (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);

      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
    refetchQueries: [{
      query: LIST_LISTING_UPLOADS,
      variables: {
        params: {
          queryType: ListingUploadQueryTypesEnum.Personal,
        },
      },
    }],
  });

  const onDrop: any = (browserFiles: GraphQLFileUpload[]) => {
    if (fileLoading) return;
    const fileParams: IFileParams[] = browserFiles.map((browserFile: GraphQLFileUpload) => ({
      name: browserFile.name,
      description: browserFile.name,
      mimetype: browserFile.type || 'application/octet-stream',
      encoding: 'utf8',
      upload: browserFile,
    }));

    uploadFiles({
      variables: {
        params: fileParams,
      },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  /** Functions */
  const uploadPropertyDetails = (event: React.FormEvent) => {
    if (event) event.preventDefault();
    if (event) event.stopPropagation();
    if (files?.length < 1) setFileError(true);
    if (!brokerLicense) setLicenseError(true);
    if (!isValid() || files?.length < 1 || !brokerLicense) return;
    createTempListing({
      variables: {
        params: {
          state: ListingStateEnum.Admin,
          name: listingName.value(),
          license: brokerLicense,
          contacts: [{
            _id: userData?.user?._id,
            name: `${userData?.user?.firstName} ${userData?.user?.lastName}`,
            email: userData?.user?.email,
            phoneNumber: userData?.user?.phoneNumber,
          }],
        },
      },
    });
  };

  /** Effects */
  React.useEffect(() => {
    if (createdListingId) {
      const params = {
        listingName: listingName.value(),
        brokerLicense,
        additionalInfo: additionalInfo.value(),
        state: ListingUploadStateEnum.NotStarted,
        listingId: createdListingId,
        media: {
          fileIds: files.map((file) => file._id),
        },
      };
      createListingUpload({
        variables: {
          params,
        },
      });
    }
  }, [createdListingId]);

  React.useEffect(() => {
    if (files?.length > 0) {
      setFileError(false);
      fileIds.setValue(files.map((file) => file?._id));
    } else {
      fileIds.setValue([]);
    }
  }, [files]);

  React.useEffect(() => {
    if (brokerLicense && licenseError) {
      setLicenseError(false);
    }
  }, [brokerLicense]);

  return (
    <ModalContainer margin={isMobile ? '0 16px' : '0px'}>
      <ModalHeader title="Upload document" close={popModal} />
      <ModalContent>
        <Container>
          <Text margin="24px 0">
            Get started by uploading a flyer, offering memorandum, or brochure and our team will do the rest.
            It may take up to
            {' '}
            <b>48 hours</b>
            {' '}
            for your listing to be processed.
          </Text>
          <ListingNameWrapper>
            <Input
              label="Listing name"
              placeholder="Listing name"
              height="120px"
              value={listingName.value()}
              onChange={listingName.setValue}
              ref={listingName.setRef}
              error={listingName.error()}
            />
          </ListingNameWrapper>
          <LicenseWrapper>
            {
                userData?.user?.licenses?.length ? (
                  <>
                    <Dropdown
                      label="License"
                      type={DropdownTypesEnum.Select}
                      value={brokerLicense ? StringUtil.formatLicense(brokerLicense) : ''}
                      placeholder="Select from your licenses"
                      maxDropdownHeight="168px"
                      items={userData?.user?.licenses?.map((value: ILicense) => ({
                        name: StringUtil.formatLicense(value),
                        value,
                      }))}
                      onChange={(value) => setBrokerLicense({
                        _id: value._id.toString(),
                        number: value.number.toString(),
                        owner: value.owner.toString(),
                        state: value.state.toString(),
                      })}
                      error={licenseError ? 'Real estate license required' : null}
                    />
                    <LinkText
                      margin="8px 0 16px"
                      type={TextTypesEnum.Medium12}
                      onClick={() => pushAddLicenseModal()}
                    >
                      Add another license
                    </LinkText>
                  </>
                ) : (
                  <>
                    {licenseError && (
                    <Text margin="0 0 8px 0" type={TextTypesEnum.Regular12} color={Colors.Red500}>
                      Real estate license required. Please add and select a license to continue.
                    </Text>
                    )}
                    <NoLicenseWrapper error={licenseError}>
                      <NoContent
                        height="128px"
                        icon={Icons.FileCertificateSolid}
                        text="You do not appear to have a license in our system. Click the button below to add a license to your profile."
                      />
                      <Button
                        text="Add license"
                        onClick={() => pushAddLicenseModal()}
                        type={ButtonTypesEnum.Primary}
                        size={ButtonSizesEnum.Medium}
                        margin="32px 0px 0px 0px"
                      />
                    </NoLicenseWrapper>
                  </>
                )
              }
          </LicenseWrapper>
          <DescriptionWrapper>
            <Input
              label="Additional notes"
              placeholder="Additional info"
              height="120px"
              value={additionalInfo.value()}
              onChange={additionalInfo.setValue}
              ref={additionalInfo.setRef}
              inputType={InputTypesEnum.TextArea}
            />
          </DescriptionWrapper>
          <FileUploadWrapper>
            {
                fileError && (
                  <Text type={TextTypesEnum.Medium12} color={Colors.Red500} margin="0 0 4px">At least one file is required</Text>
                )
              }
            <Dashed {...getRootProps()} error={fileError}>
              {
                  fileLoading && (
                    <Flex justify="center">
                      <Loader color={Colors.Brand500 || Colors.Blurple500} />
                    </Flex>
                  )
                }
              {(!fileLoading && files?.length <= 0) ? (
                <>
                  <input {...getInputProps()} />

                  <Flex align="center" justify="center">
                    <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>
                      Drag file(s) here or
                      &nbsp;
                      <Text
                        type={TextTypesEnum.Medium14}
                        as={TextAsEnum.Span}
                        color={Colors.Brand700 || Colors.Blurple700}
                      >
                        click to upload
                      </Text>
                    </Text>
                  </Flex>
                </>
              ) : (
                <>
                  {
                  files.map((file, index) => (
                    <FileContainer key={index}>
                      <LOIFile
                        key={file?._id}
                        file={file}
                        deleteFile={() => {
                          setFiles(files.filter((f) => f._id !== file._id));
                        }}
                      />
                    </FileContainer>
                  ))
                }
                </>
              )}

            </Dashed>
            {files.length > 0 && (
              <AddMoreFiles {...getRootProps()}>
                <LinkText type={TextTypesEnum.Medium12} margin="8px 0 0 0">
                  <input {...getInputProps()} />
                  Add more files
                </LinkText>
              </AddMoreFiles>
            )}
          </FileUploadWrapper>
        </Container>
      </ModalContent>
      <ModalFooter>
        <Flex justify={isMobile ? 'space-between' : 'flex-end'} align="center" width="100%">
          <Button
            text="Cancel"
            type={ButtonTypesEnum.Ghost}
            size={ButtonSizesEnum.Medium}
            onClick={() => popModal()}
            margin="0px 8px 0px 0px"
            htmlType="button"
          />
          <Button
            text="Upload property details"
            isLoading={loading}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            onClick={(event) => uploadPropertyDetails(event)}
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default UploadListingModal;
