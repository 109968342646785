/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from '@emotion/styled';
import * as Polished from 'polished';
import OfferStateEnum from '@biproxi/models/enums/OfferStateEnum';
import { IOfferGraphQL } from '@biproxi/models/interfaces/IOffer';
import StringUtil from '@biproxi/models/utils/StringUtil';
import Colors, { ColorsType } from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';

const Container = styled.div<{color: ColorsType, margin?: string}>`
  border-radius: 4px;
  width: fit-content;
  padding: 0px 4px;
  color: ${(props) => props.color};
  background-color: ${(props) => Polished.rgba(props.color, 0.10)};
  margin: ${(props) => props.margin};
`;

export type OfferStatusProps = {
  offer: IOfferGraphQL | null;
  margin?: string;
};

const OfferStatus: React.FC<OfferStatusProps> = ({
  offer,
  margin,
}) => {
  /* State */
  const offerState = (() => {
    switch (offer.state) {
      case OfferStateEnum.New:
      case OfferStateEnum.Old:
      case OfferStateEnum.Active:
        return {
          color: `${Colors.Grey900}`,
          text: `${StringUtil.addNumberOrdinals(offer.offerNumber)} Offer`,
        };
      case OfferStateEnum.Rejected:
        return {
          color: `${Colors.Red500}`,
          text: offer.state,
        };
      case OfferStateEnum.Withdrawn:
        return {
          color: `${Colors.Yellow500}`,
          text: offer.state,
        };
      case OfferStateEnum.Accepted:
        return {
          color: `${Colors.Green500}`,
          text: offer.state,
        };
      default:
        return {
          color: `${Colors.Grey900}`,
          text: `${StringUtil.addNumberOrdinals(offer.offerNumber)} Offer`,
        };
    }
  })();
  /* Render */
  return (
    <Container color={offerState.color} margin={margin}>
      <Text type={TextTypesEnum.Medium14} color={offerState.color}>{offerState.text}</Text>
    </Container>
  );
};

export default React.memo(OfferStatus);
