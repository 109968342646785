import React from 'react';
import styled from '@emotion/styled';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { media } from '../../utils/MediaQuery';
import useUser from '../../hooks/useUser.hook';
import InvestorInfo from '../InvestorInfo';

const Container = styled.div`
  width: 432px;

  ${media.mobile} {
    width: 100%;
  }
`;

export type ProfilePreviewModalProps = {
};

const ProfilePreviewModal: React.FC<ProfilePreviewModalProps> = () => {
  /** Hooks */
  const { user } = useUser();

  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  /** Render */
  return (
    <ModalContainer>
      <ModalHeader title="Profile Preview" close={popModal} />
      <ModalContent>
        <Container>
          <InvestorInfo user={user} preventLinkClicks />
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default ProfilePreviewModal;
