import React from 'react';
import styled from '@emotion/styled';
import * as Polished from 'polished';
import ReactTooltip from 'react-tooltip';
import StringUtil from '@biproxi/models/utils/StringUtil';
import Colors from '../styles/Colors';
import Label from './Label';
import Flex from './Flex';
import Text, { TextTypesEnum } from './Text';
import Icon, { Icons } from './Icon';
import CharLimit from './CharLimit';
import KeyCodesEnum from '../models/enums/KeyCodesEnum';
import PhoneNumberInput from './PhoneNumberInput';
import BiproxiIconEnum from '../models/enums/BiproxiIconEnum';

// CSS ID for hover effects on right/left icons
const leftRightHoverID = 'leftRightHoverID';

export enum InputTypesEnum {
  Text = 'Text',
  FolderText = 'FolderText',
  Number = 'Number',
  Currency = 'Currency',
  Percentage = 'Percentage',
  Coordinate = 'Coordinate',
  Password = 'Password',
  Date = 'Date',
  TextArea = 'TextArea',
  PhoneNumber = 'PhoneNumber',
  Search = 'Search',
  EmailText = 'EmailText',
}

export enum InputSizeEnum {
  Fitted = 'Fitted',
  Small = 'Small',
  Regular = 'Regular',
}

export enum InputThemeEnum {
  Light = 'Light',
  Dark = 'Dark',
  Ghost = 'Ghost',
}

const Container = styled.div`
  width: 100%;
`;

type InputContainerProps = {
  inputType: InputTypesEnum;
  inputSize: InputSizeEnum;
  inputTheme: InputThemeEnum;
  hovered: boolean;
  focused: boolean;
  width: string;
  margin: string;
  error: boolean;
  disabled: boolean;
  noBorder?: boolean; // if set to true, input's border is removed
  'data-cy'?: string;
};

const InputContainer = styled.div<InputContainerProps>`
  box-sizing: border-box;
  height: ${(props) => {
    switch (props.inputType) {
      case InputTypesEnum.TextArea:
        return 'fit-content';
      default:
        switch (props.inputSize) {
          case InputSizeEnum.Small:
            return '40px';
          case InputSizeEnum.Fitted:
            return 'fit-content';
          case InputSizeEnum.Regular:
          default:
            return '48px';
        }
    }
  }};
  border-radius: 4px;
  transition: all 0.2s;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  caret-color: ${(props) => (props.error ? Colors.Red500 : null)};
  data-cy: ${(props) => props['data-cy']};
  border: ${(props) => {
    // If phone number input, let it handle its own styling since its structure is significantly different)
    if (props.inputTheme === InputThemeEnum.Ghost) return null;
    if (props.inputType === InputTypesEnum.PhoneNumber) return null;
    if (props.noBorder) return null;
    if (props.error) return `1px solid ${Colors.Red500}`;
    if (props.focused) return `1px solid ${Colors.Brand700 || Colors.Blurple700}`;
    if (props.inputTheme === InputThemeEnum.Dark) return `1px solid ${Colors.Grey100}`;
    if (props.hovered && !props.disabled) return `1px solid  ${Colors.Grey400}`;
    if (props.inputType === InputTypesEnum.EmailText) return null;
    return `1px solid  ${Colors.Grey300}`;
  }};
  background-color: ${(props) => {
    switch (props.inputTheme) {
      case InputThemeEnum.Dark:
        if (props.inputType === InputTypesEnum.PhoneNumber) return null;
        if (props.error) return Colors.White;
        if (props.focused) return Colors.White;
        if (props.hovered) return Colors.White;
        if (props.inputTheme === InputThemeEnum.Dark) return Colors.Grey100;
        return Colors.Grey100;
      case InputThemeEnum.Light:
        if (props.inputType === InputTypesEnum.PhoneNumber) return null;
        if (props.error) return Colors.White;
        if (props.focused) return Colors.White;
        if (props.hovered) return Colors.White;
        if (props.inputTheme === InputThemeEnum.Light) return Colors.White;
        return Colors.White;
      default:
        return null;
    }
  }};
  box-shadow: ${(props) => {
    if (props.inputTheme === InputThemeEnum.Ghost) return null;
    if (props.inputType === InputTypesEnum.PhoneNumber) return null;
    if (props.noBorder) return null;
    if (props.error && props.focused) return `0px 0px 0px 4px ${Polished.rgba(Colors.Red500, 0.10)}`;
    if (props.focused && !props.noBorder) return `0px 0px 0px 4px ${Polished.rgba(Colors.Brand700 || Colors.Blurple700, 0.10)}`;
    return null;
  }};
  cursor: ${(props) => (props.inputType === InputTypesEnum.FolderText ? 'text' : null)};
`;

const InputHoverWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;

  &:hover {
    #leftRightHoverID {
      background-color: ${Colors.Grey200};
    }
    #BiproxiIcon {
      color: ${Colors.Grey900}
    }
  }
`;

type StyledInputProps = {
  inputType: InputTypesEnum;
  height?: string;
  margin?: string;
  padding?: string;
  error?: boolean;
  disabled?: boolean;
  innerWidth?: string; // pretty sketchy
}

const InputStyled = styled.input<StyledInputProps>`
  position: relative;
  min-height: ${(props) => (props.inputType === InputTypesEnum.Search ? null : props.height || '100%')};
  height: ${({ inputType }) => (inputType === InputTypesEnum.Search ? null : '100%')};
  resize: none;
  margin: 0px;
  padding: ${(props) => {
    switch (props.inputType) {
      case InputTypesEnum.TextArea:
        return '12px 0 0 16px';
      case InputTypesEnum.Search:
        return '0 0 0 8px';
      case InputTypesEnum.EmailText:
        return null;
      case InputTypesEnum.FolderText:
        return null;
      default:
        return '0 0 0 16px';
    }
  }};
  background-color: ${(props) => (props.disabled ? Colors.Grey50 : 'transparent')};
  font-size: ${({ inputType }) => (inputType === InputTypesEnum.FolderText ? '1.4rem' : '1.6rem')};
  line-height: 2.4rem;
  /* cursor: pointer;
  caret-color: transparent; */
  font-weight: ${({ inputType }) => (inputType === InputTypesEnum.FolderText ? 700 : 400)};
  color: ${Colors.Grey900};
  outline: none;
  border: 0px;
  border-radius: 4px;
  transition: all 0.2s;
  font-family: "aktiv-grotesk";
  width: ${(props) => props.innerWidth || '100%'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};

  ::placeholder {
    color: ${(props) => (props.disabled ? Colors.Grey300 : Colors.Grey400)};
  }
`;

const TextAreaStyled = InputStyled.withComponent('textarea');
const PhoneNumberStyled = InputStyled.withComponent(PhoneNumberInput);

const PasswordIconContainer = styled.div`
  margin-right: 16px;
`;

type IconContainerProps = {
  left?: boolean;
  right?: boolean;
};

const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  background-color: ${Colors.Grey100};
  border-right: ${(props) => (props.left ? `1px solid ${Colors.Grey300}` : null)};
  border-left: ${(props) => (props.right ? `1px solid ${Colors.Grey300}` : null)};
  border-radius: ${(props) => (props.left ? '3px 0 0 3px' : '0 3px 3px 0')};
  height: 100%;
  white-space: nowrap;
`;

export type InputProps = {
  autoFocus?: boolean;
  id?: string;
  placeholder?: string;
  label?: string;
  value?: string;
  defaultValue?: string;
  description?: React.ReactChild;
  unit?: string;
  inputType?: InputTypesEnum;
  inputSize?: InputSizeEnum;
  inputTheme?: InputThemeEnum;
  onChange?: any;
  clear?: Function;
  onBlur?: any;
  onKeyPress?: any
  onFocus?: any;
  margin?: string;
  padding?: string;
  width?: string;
  innerWidth?: string;
  error?: string | null;
  disabled?: boolean;
  height?: string | null; // really only for textarea
  readOnly?: boolean;
  charLimit?: number;
  noBorder?: boolean; // if set to true, input's border is removed
  fontWeight?: number;
  'data-cy'?: string;
  iconMarginLeft?: string,
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  autoFocus = false,
  placeholder,
  label,
  value,
  defaultValue,
  description,
  unit,
  inputType = InputTypesEnum.Text,
  inputSize = InputSizeEnum.Regular,
  inputTheme = InputThemeEnum.Light,
  onChange,
  clear,
  onBlur,
  onKeyPress,
  onFocus,
  margin,
  padding,
  width = 'fill-available',
  innerWidth,
  error,
  disabled,
  height,
  readOnly,
  charLimit,
  noBorder = false,
  id,
  'data-cy': dataCy,
  iconMarginLeft = '0',
}, ref) => {
  /* State */
  const [hovered, setHovered] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const isPasswordInput = inputType === InputTypesEnum.Password;

  /* Effects */
  if (typeof window !== 'undefined') {
    React.useLayoutEffect(() => {
      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });
  }

  const type = (() => {
    switch (inputType) {
      case InputTypesEnum.Text:
        return 'text';
      case InputTypesEnum.Number:
        return 'text';
      case InputTypesEnum.Currency:
        return 'text';
      case InputTypesEnum.Percentage:
        return 'text';
      case InputTypesEnum.Password:
        return showPassword ? 'text' : 'password';
      case InputTypesEnum.Date:
        return 'text';
      case InputTypesEnum.TextArea:
        return 'text';
      default:
        return 'text';
    }
  })();

  const leftIcon: React.ReactElement | null = (() => {
    switch (inputType) {
      case InputTypesEnum.Text:
        return null;
      case InputTypesEnum.Number:
        return null;
      case InputTypesEnum.Currency:
        return (
          <IconContainer id={leftRightHoverID} left>
            <Text
              type={TextTypesEnum.Regular16}
              color={disabled ? Colors.Grey300 : Colors.Grey500}
            >
              $
            </Text>
          </IconContainer>
        );
      case InputTypesEnum.Percentage:
        return null;
      case InputTypesEnum.Password:
        return null;
      case InputTypesEnum.Date:
        return null;
      case InputTypesEnum.TextArea:
        return null;
      case InputTypesEnum.PhoneNumber:
        return null;
      case InputTypesEnum.Search:
        return (
          <Icon
            customIcon={BiproxiIconEnum.Search}
            size={16}
            color={Colors.Grey400}
            margin="0 0 0 1.2rem"
          />
        );
      default:
        return null;
    }
  })();

  const rightIcon: string | React.ReactElement | null = (() => {
    if (unit) {
      return (
        <IconContainer id={leftRightHoverID} right>
          <Text
            type={TextTypesEnum.Regular16}
            color={disabled ? Colors.Grey300 : Colors.Grey500}
          >
            {unit}
          </Text>
        </IconContainer>
      );
    }

    switch (inputType) {
      case InputTypesEnum.Text:
        return null;
      case InputTypesEnum.Number:
        return null;
      case InputTypesEnum.Currency:
        return null;
      case InputTypesEnum.Percentage:
        return (
          <IconContainer id={leftRightHoverID} right>
            <Text
              type={TextTypesEnum.Regular16}
              color={disabled ? Colors.Grey300 : Colors.Grey500}
            >
              %
            </Text>
          </IconContainer>
        );
      case InputTypesEnum.Password:
        return null;
      case InputTypesEnum.Date:
        return (
          <IconContainer id={leftRightHoverID} right>
            <Icon
              icon={Icons.CalendarAltSolid}
              color={disabled ? Colors.Grey300 : Colors.Grey500}
              size={16}
            />
          </IconContainer>
        );
      case InputTypesEnum.TextArea:
        return null;
      case InputTypesEnum.PhoneNumber:
        return null;
      case InputTypesEnum.Search:
        return value ? (
          <Icon
            icon={Icons.TimesCircleSolid}
            size={16}
            color={Colors.Grey400}
            margin={`0 12px 0 ${iconMarginLeft}`}
            onClick={() => clear?.()}
          />
        ) : null;
      default:
        return null;
    }
  })();

  const Input = (() => {
    switch (inputType) {
      case InputTypesEnum.Text:
        return InputStyled;
      case InputTypesEnum.Number:
        return InputStyled;
      case InputTypesEnum.Currency:
        return InputStyled;
      case InputTypesEnum.Percentage:
        return InputStyled;
      case InputTypesEnum.Coordinate:
        return InputStyled;
      case InputTypesEnum.Password:
        return InputStyled;
      case InputTypesEnum.Date:
        return InputStyled;
      case InputTypesEnum.TextArea:
        return TextAreaStyled;
      case InputTypesEnum.PhoneNumber:
        return PhoneNumberStyled;
      default:
        return InputStyled;
    }
  })();

  const unmaskTypes = [
    InputTypesEnum.Currency,
    InputTypesEnum.Number,
    InputTypesEnum.Percentage,
    InputTypesEnum.Coordinate,
  ];

  const isUnmaskInputType = unmaskTypes.includes(inputType);

  const inputProps: any = {
    inputType,
    ref: ref as any,
    autoFocus,
    placeholder,
    value,
    defaultValue,
    type,
    dataCy,
    onKeyDown: (e) => {
      /**
       * Character Limit reached, prevent default but allow certain
       * keyboard inputs.
       */
      if ((charLimit && e.currentTarget.value.length >= charLimit)
        && (e.which !== KeyCodesEnum.BACKSPACE
        && e.which !== KeyCodesEnum.DELETE
        && e.which !== KeyCodesEnum.TAB
        && e.which !== KeyCodesEnum.LEFT_ARROW
        && e.which !== KeyCodesEnum.RIGHT_ARROW
        && e.which !== KeyCodesEnum.ENTER)) {
        e.preventDefault();
      }
    },
    onChange: (e) => {
      // prevent paste exceeding character limit
      if ((charLimit && e.currentTarget.value.length > charLimit)) {
        return;
      }
      if (isUnmaskInputType) {
        const unmasked = StringUtil.unmaskNumber(e.currentTarget.value);
        onChange(e, unmasked);
      } else {
        // TODO: revamp 'handleInput' function of @biproxi/react-phone-input-2 to allow unmasking here
        // if (inputType === InputTypesEnum.PhoneNumber) {
        //   const unmasked = StringUtil.unmaskPhoneNumber(e.target.value);
        //   e.target.value = unmasked;
        // }

        /** Boolean parameter is for phone inputs; we need to pull the
         * e.target.value and not e.currentTarget.value in that case.
         * See makeEventHandler file for implementation details
         */
        onChange(e, true);
      }
    },
    onMouseOver: () => setHovered(true),
    onMouseOut: () => setHovered(false),
    onFocus: (e: React.FocusEvent) => {
      setFocused(true);
      if (onFocus) onFocus(e);
    },
    onBlur: (e: React.FocusEvent) => {
      setFocused(false);
      if (onBlur) onBlur(e);
    },
    onKeyPress,
    padding,
    width,
    innerWidth,
    error: Boolean(error),
    disabled,
    readOnly,
  };

  if (isUnmaskInputType) {
    if (inputType === InputTypesEnum.Coordinate) {
      inputProps.mask = StringUtil.coordinateMask;
      inputProps.value = StringUtil.formatCoordinate(value?.toString());
    } else {
      inputProps.mask = StringUtil.numberMask;
      inputProps.value = StringUtil.formatNumber(value?.toString());
    }
  }

  if (inputType === InputTypesEnum.TextArea) {
    inputProps.height = height;
  }

  /* Render */
  return (
    <Container id={id}>
      <Flex justify="space-between" align="center">
        {label && <Label text={label} />}
        {(() => {
          if (error) {
            return <Text type={TextTypesEnum.Medium12} color={Colors.Red500} margin="0 0 4px">{error}</Text>;
          }
          if (charLimit) {
            return <CharLimit value={value} charLimit={charLimit} />;
          }
          return null;
        })()}
      </Flex>
      <InputContainer
        id="InputContainer"
        inputType={inputType}
        inputSize={inputSize}
        inputTheme={inputTheme}
        focused={focused}
        hovered={hovered}
        width={width}
        margin={margin}
        disabled={disabled}
        error={Boolean(error)}
        noBorder={noBorder}
        data-cy={dataCy}
      >
        <InputHoverWrapper id="InputHoverWrapper">
          {leftIcon && leftIcon}
          <Input {...inputProps} />
          {rightIcon && rightIcon}
          {isPasswordInput && (
            <PasswordIconContainer>
              <Icon
                icon={showPassword ? Icons.EyeSlashRegular : Icons.EyeRegular}
                tip={showPassword ? 'Hide Password' : 'Show Password'}
                onClick={() => setShowPassword(!showPassword)}
                width="25px"
              />
            </PasswordIconContainer>
          )}
        </InputHoverWrapper>
        {(() => {
          if (!description) return null;
          if (typeof description === 'string') {
            return (
              <Text
                type={TextTypesEnum.Regular12}
                color={Colors.Grey700}
                margin="4px 0 0"
                width={innerWidth}
              >
                {description}
              </Text>
            );
          }
          return description;
        })()}
      </InputContainer>
    </Container>
  );
});

export default Input;
