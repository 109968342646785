import React from 'react';
import styled from '@emotion/styled';
import { INotificationGraphQL } from '@biproxi/models/interfaces/INotification';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import { useInView } from 'react-intersection-observer';
import Colors from '../styles/Colors';
import Flex from '../elements/Flex';
import Text, { TextTypesEnum } from '../elements/Text';
import Notification, { NotificationSizeEnum } from './Notification';
import PaginationLimitsEnum from '../models/enums/PaginationLimitsEnum';
import InfiniteScroll, { LoadMoreWhenInView, useInViewOptions } from './InfiniteScroll';
import Divider from '../elements/Divider';

const Container = styled.div`
  max-width: calc(100vw - 32px);
`;

const RowContainer = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding};
`;

const Row = styled.div<{ type?: NotificationTableTypesEnum }>`
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  position: relative;
  transition: all 0.2s;
  margin: 16px 0;
  &:hover {
    background: ${Colors.Grey50};
    border-radius: 4px;
    box-shadow: 0px 0px 0px 8px #F9FAFB;
  }

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

`;

const TextContainer = styled.div<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => marginTop};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export enum NotificationTableTypesEnum {
  Page = 'Page',
  Dropdown = 'Dropdown',
}

type NotificationsTableProps = {
  notifications: INotificationGraphQL[];
  type?: NotificationTableTypesEnum,
  fetchMore?: any;
  totalCount?: number;
  search?: string;
};

const NotificationsTable: React.FC<NotificationsTableProps> = ({
  notifications,
  type = NotificationTableTypesEnum.Page,
  fetchMore,
  totalCount,
  search,
}) => {
  /** State */
  const [infiniteLoading, setInfiniteLoading] = React.useState(false);
  const isDropdown = type === NotificationTableTypesEnum.Dropdown;

  /** Hooks */
  const [ref, inView] = useInView(useInViewOptions);

  /** Effects */
  React.useEffect(() => {
    const getData = async () => {
      const hasMore = notifications?.length < totalCount;
      if (hasMore && inView && fetchMore) {
        setInfiniteLoading(true);
        await fetchMore({
          variables: {
            params: {
              search,
              pagination: {
                limit: PaginationLimitsEnum.Notifications,
                offset: notifications?.length,
              },
            },
          },
        });
        setInfiniteLoading(false);
      }
    };
    getData();
  }, [inView]);

  /**
   * Set a counter that increments every 1 minute
   * to re-render the time stamp on the table
   * so that it is not out of date
   */

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [counter, setCounter] = React.useState(0);
  const [interval] = React.useState(
    setInterval(() => {
      setCounter((counter) => counter + 1);
    }, TimeUtil.TimeEnumInMiliseconds.MINUTE),
  );

  /**
   * Clear counter interval on unmount
   */

  React.useEffect(() => () => {
    clearInterval(interval);
  }, []);

  /** Render */
  return (
    <>
      <Container>
        {notifications.map((notification, index) => {
          let timestamp = isDropdown
            ? TimeUtil.date(notification.meta.createdAt).toRelative()
            : TimeUtil.format(
              notification.meta.createdAt,
              'ff',
              TimeUtil.currentBrowserTimezone(),
            );

          if (timestamp.includes('second')) {
            timestamp = 'Just now';
          }

          /**
           * Sketchy, but we want short names for these values
           * and Luxon doesn't have a native way to do this.
           */
          if (isDropdown) {
            timestamp = timestamp.replace('weeks', 'w');
            timestamp = timestamp.replace('week', 'w');

            timestamp = timestamp.replace('days', 'd');
            timestamp = timestamp.replace('day', 'd');

            timestamp = timestamp.replace('hours', 'h');
            timestamp = timestamp.replace('hour', 'h');

            timestamp = timestamp.replace('minutes', 'm');
            timestamp = timestamp.replace('minute', 'm');
            // Remove space before number
            timestamp = timestamp.replace(' w', 'w');
            timestamp = timestamp.replace(' d', 'd');
            timestamp = timestamp.replace(' h', 'h');
            timestamp = timestamp.replace(' month', 'nothing');
            timestamp = timestamp.replace(' m', 'm');
            timestamp = timestamp.replace('nothing', ' month');
          }

          return (
            <RowContainer
              key={`${notification._id}_${index}`}
              padding={isDropdown ? '0 20px' : '0 8px'}
            >
              <Row>
                <LeftContainer>
                  <TextContainer>
                    <Flex width="100%" justify="space-between" align="center">
                      <Notification
                        notification={notification}
                        size={isDropdown ? NotificationSizeEnum.Small : NotificationSizeEnum.Regular}
                      />
                      <Text type={TextTypesEnum.Regular12} color={Colors.Grey500}>
                        {timestamp}
                      </Text>
                    </Flex>
                  </TextContainer>
                </LeftContainer>
              </Row>
              {index !== notifications.length - 1 && (
                <Divider color={Colors.Grey200} />
              )}
            </RowContainer>
          );
        })}
      </Container>
      <LoadMoreWhenInView ref={ref} />
      {infiniteLoading && <InfiniteScroll />}
    </>
  );
};

export default NotificationsTable;
