import { print } from 'graphql';
import shortid from 'shortid';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import CREATE_USER from '../../graphql/mutations/userMutations/createUser.mutation';
import DELETE_USER from '../../graphql/mutations/userMutations/deleteUser.mutation';
import CREATE_LISTING from '../../graphql/mutations/listingMutations/createListing.mutation';
import DELETE_LISTING from '../../graphql/mutations/listingMutations/deleteListing.mutation';
import CREATE_ORGANIZATION from '../../graphql/mutations/organizationMutations/createOrganization.mutation';
import CONNECT_USER_TO_ORG from '../../graphql/mutations/organizationMutations/connectUserToOrg.mutation';
import ADD_ORG_ROLE_TO_USER from '../../graphql/mutations/userMutations/addOrgRoleToUser.mutation';
import ADD_LISTINGS_TO_ORGANIZATION from '../../graphql/mutations/organizationMutations/addListingsToOrganization.mutation';
import REMOVE_LISTING_FROM_ORGANIZATION from '../../graphql/mutations/organizationMutations/removeListingFromOrganization.mutation';
import DELETE_ORGANIZATION from '../../graphql/mutations/organizationMutations/deleteOrganization.mutation';
import UPDATE_USER_ORG_ROLE from '../../graphql/mutations/userMutations/updateUserOrgRole.mutation';
import UPDATE_LISTING_STATE from '../../graphql/mutations/listingMutations/updateListingState.mutation';
import UPDATE_LISTING from '../../graphql/mutations/listingMutations/updateListing.mutation';
import REMOVE_USER_FROM_ORGANIZATION from '../../graphql/mutations/organizationMutations/removeUserFromOrganization.mutation';
import UPDATE_USER from '../../graphql/mutations/userMutations/updateUserBasicInfo.mutation';

function createNewUser(request: INeo4jServiceAPI.ICreateUserRequest) {
  const { params } : { params: INeo4jServiceAPI.ICreateUserParams } = request;

  const mutationInput = {
    _id: params._id,
    firstName: params.firstName,
    lastName: params.lastName,
    email: params?.email ?? '',
    phoneNumber: params?.phoneNumber ?? '',
    passwordHash: params.passwordHash,
    metaCreatedAt: params.metaCreatedAt,
    metaCreatedBy: params._id,
    metaLastUpdatedAt: params.metaLastUpdatedAt,
    metaLastUpdatedBy: params._id,
    role: params.role,
    biproxiRoles: params?.biproxiRoles ?? [],
    biproxiPermissions: params?.biproxiPermissions ?? [],
    ipAddress: params?.ipAddress ?? '',
  };

  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(CREATE_USER),
      variables: {
        input: mutationInput,
      },
    },
  };
  return graphqlRequestBody;
}

function deleteUser(request: INeo4jServiceAPI.IDeleteUserRequest) {
  const { params } : { params: INeo4jServiceAPI.IDeleteUserParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(DELETE_USER),
      variables: {
        where: {
          _id: params.userId,
        },
      },
    },
  };
  return graphqlRequestBody;
}

function createNewListing(request: INeo4jServiceAPI.ICreateListingRequest) {
  const { params } : { params: INeo4jServiceAPI.ICreateListingParams } = request;
  const mutationInput = {
    _id: params._id,
    name: params.name,
    description: params?.description,
    assetClass: params.assetClass,
    propertyType: params.propertyType,
    state: params.state,
    address1: params.address1,
    address2: params?.address2 ?? '',
    cherreId: params?.cherreId ?? '',
    city: params.city,
    addressState: params.addressState,
    country: params.country,
    googlePlaceId: params?.googlePlaceId ?? '',
    locationCoordinates: params?.locationCoordinates ?? [],
    locationType: params?.locationType ?? 'Point',
    timeZoneId: params?.timeZoneId ?? '',
    zip: params.zip,
    mediaFileIds: params.mediaFileIds,
    mediaVideoFileId: params?.mediaVideoFileId ?? '',
    mediaVideoThumbnailFileId: params?.mediaVideoThumbnailFileId ?? '',
    metaCreatedAt: params?.metaCreatedAt ?? '',
    metaCreatedBy: params?.metaCreatedBy ?? params?.createdByUserId,
    metaLastPublishedAt: params.metaLastPublishedAt,
    metaLastUpdatedAt: params.metaLastUpdatedAt,
    metaLastUpdatedBy: params?.metaLastUpdatedBy ?? params?.createdByUserId,
    isPortfolioListing: params?.isPortfolioListing ?? false,
    isPrivateListing: params?.isPrivateListing ?? false,
    createdByUser: {
      connect: {
        where: {
          node: { _id: params.createdByUserId },
        },
        edge: {
          roles: ['OWNER'],
          permissions: [],
          metaConnectedAt: TimeUtil.now(),
        },
      },
    },
  };
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(CREATE_LISTING),
      variables: {
        input: mutationInput,
      },
    },
  };
  return graphqlRequestBody;
}

function deleteListing(request: INeo4jServiceAPI.IDeleteListingRequest) {
  const { params } : { params: INeo4jServiceAPI.IDeleteListingParams } = request;

  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(DELETE_LISTING),
      variables: {
        params: {
          _id: params?.listingId,
        },
      },
    },
  };
  return graphqlRequestBody;
}

function createNewOrganization(request: INeo4jServiceAPI.ICreateOrganizationRequest) {
  const { params } : { params: INeo4jServiceAPI.ICreateOrganizationParams } = request;

  // TODO: need to connect params.listingIds here

  const mutationInput = {
    _id: shortid.generate(),
    name: params.name,
    description: params?.description ?? '',
    metaCreatedAt: TimeUtil.now(),
    users: {
      connect: [
        {
          edge: {
            permissions: [],
            roles: ['OWNER'],
            metaConnectedAt: TimeUtil.now(),
          },
          where: {
            node: {
              _id: params.createdByUserId,
            },
          },
        },
      ],
    },
  };

  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(CREATE_ORGANIZATION),
      variables: {
        input: mutationInput,
      },
    },
  };
  return graphqlRequestBody;
}

function deleteOrganization(request: INeo4jServiceAPI.IDeleteOrganizationRequest) {
  const { params } : { params: INeo4jServiceAPI.IDeleteOrganizationParams } = request;

  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(DELETE_ORGANIZATION),
      variables: {
        where: {
          _id: params?.organizationId,
        },
      },
    },
  };
  return graphqlRequestBody;
}

function connectUserToOrganization(request: INeo4jServiceAPI.IConnectUserToOrgRequest) {
  const { params } : { params: INeo4jServiceAPI.IConnectUserToOrgParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(CONNECT_USER_TO_ORG),
      variables: {
        where: {
          _id: params?.organizationId,
        },
        connect: {
          users: [
            {
              where: {
                node: {
                  email: params?.userEmail,
                },
              },
              edge: {
                roles: params?.userRoles,
                permissions: [],
                metaConnectedAt: TimeUtil.now(),
              },
            },
          ],
        },
      },
    },
  };
  return graphqlRequestBody;
}

// function connectUsersToOrganization(request: INeo4jServiceAPI.IConnectUsersToOrgRequest) {
//   const { params } : { params: INeo4jServiceAPI.IConnectUsersToOrgParams } = request;
//   console.log({ params });
//   const graphqlRequestBody = {
//     graphqlQuery: {
//       query: print(CONNECT_USER_TO_ORG),
//       variables: {
//         where: {
//           _id: params?.organizationId,
//         },
//         connect: {
//           users: [
//             {
//               where: {
//                 node: {
//                   email_IN: Object?.keys(params?.usersToConnect),
//                 },
//               },
//               edge: {
//                 roles: Object?.values(params?.usersToConnect),
//               },
//             },
//           ],
//         },
//       },
//     },
//   };
//   return graphqlRequestBody;
// }

function updateUserBasicInfo(request: INeo4jServiceAPI.IUpdateUserBasicInfoRequest) {
  const { params } : { params: INeo4jServiceAPI.IUpdateUserBasicInfoParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(UPDATE_USER),
      variables: {
        where: {
          email: params.email,
        },
        update: {
          _id: params._id,
          email: params?.email,
          firstName: params?.firstName,
          lastName: params?.lastName,
          metaCreatedAt: params?.metaCreatedAt,
          metaCreatedBy: params?.metaCreatedBy,
          metaLastUpdatedAt: params?.metaLastUpdatedAt,
          metaLastUpdatedBy: params?.metaLastUpdatedBy,
          phoneNumber: params?.phoneNumber,
        },
      },
    },
  };
  return graphqlRequestBody;
}

function updateUserOrganizationRole(request: INeo4jServiceAPI.IUpdateUserOrganizationRoleRequest) {
  const { params } : { params: INeo4jServiceAPI.IUpdateUserOrganizationRoleParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(UPDATE_USER_ORG_ROLE),
      variables: {
        where: {
          _id: params.userId,
        },
        update: {
          organization: [
            {
              where: {
                node: {
                  _id: params.orgId,
                },
              },
              update: {
                edge: {
                  roles: params.orgRoles,
                },
              },
            },
          ],
        },
      },
    },
  };
  return graphqlRequestBody;
}

function updateListingState(request: INeo4jServiceAPI.IUpdateListingStateRequest) {
  const { params } : { params: INeo4jServiceAPI.IUpdateListingStateParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(UPDATE_LISTING_STATE),
      variables: {
        where: {
          _id: params.listingId,
        },
        update: {
          state: params.listingState,
        },
      },
    },
  };
  return graphqlRequestBody;
}

function updateListing(request: INeo4jServiceAPI.IUpdateListingRequest) {
  const { params } : { params: INeo4jServiceAPI.IUpdateListingParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(UPDATE_LISTING),
      variables: {
        where: {
          _id: params._id,
        },
        update: {
          address1: params.address1,
          address2: params.address2,
          addressState: params.addressState,
          assetClass: params.assetClass,
          city: params.city,
          description: params.description,
          isPortfolioListing: params.isPortfolioListing,
          isPrivateListing: params.isPrivateListing,
          mediaFileIds: params.mediaFileIds,
          metaCreatedBy: params.metaCreatedBy,
          metaLastPublishedAt: params.metaLastPublishedAt,
          name: params.name,
          propertyType: params.propertyType,
          state: params.state,
          zip: params.zip,
        },
      },
    },
  };
  return graphqlRequestBody;
}

function addOrganizationRoleToUser(addOrgRoleToUserRequest: INeo4jServiceAPI.IAddOrganizationRoleToUserRequest) {
  const { params } : { params: INeo4jServiceAPI.IAddOrganizationRoleToUserParams } = addOrgRoleToUserRequest;
  const { orgId, role } = params;
  const userQueryIdentifier = params?.userId
    ? { _id: params?.userId }
    : { email: params?.userEmail };
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(ADD_ORG_ROLE_TO_USER),
      variables: {
        where: userQueryIdentifier,
        update: {
          organization: [
            {
              update: {
                edge: {
                  roles_PUSH: role,
                },
              },
              where: {
                node: {
                  _id: orgId,
                },
              },
            },
          ],
        },
      },
    },
  };
  return graphqlRequestBody;
}

function addListingsToOrganization(addListingsToOrganizationRequest: INeo4jServiceAPI.IAddListingsToOrganizationRequest) {
  const { params } : { params: INeo4jServiceAPI.IAddListingsToOrganizationParams } = addListingsToOrganizationRequest;
  const { organizationId, listingIds } = params;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(ADD_LISTINGS_TO_ORGANIZATION),
      variables: {
        where: {
          _id: organizationId,
        },
        connect: {
          listings: [
            {
              where: {
                node: {
                  _id_IN: listingIds,
                },
              },
            },
          ],
        },
      },
    },
  };
  return graphqlRequestBody;
}

function removeUserFromOrganization(removeUserFromOrganization: INeo4jServiceAPI.IRemoveUserFromOrganizationRequest) {
  const { params } : { params: INeo4jServiceAPI.IRemoveUserFromOrganizationParams } = removeUserFromOrganization;
  const { organizationId, userId } = params;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(REMOVE_USER_FROM_ORGANIZATION),
      variables: {
        where: {
          _id: userId,
        },
        disconnect: {
          organization: [
            {
              where: {
                node: {
                  _id: organizationId,
                },
              },
            },
          ],
        },
      },
    },
  };
  return graphqlRequestBody;
}

function removeListingFromOrganization(removeListingFromOrganizationRequest: INeo4jServiceAPI.IRemoveListingFromOrganizationRequest) {
  const { params } : { params: INeo4jServiceAPI.IRemoveListingFromOrganizationParams } = removeListingFromOrganizationRequest;
  const { organizationId, listingId } = params;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(REMOVE_LISTING_FROM_ORGANIZATION),
      variables: {
        where: {
          _id: organizationId,
        },
        disconnect: {
          listings: [
            {
              where: {
                node: {
                  _id: listingId,
                },
              },
            },
          ],
        },
      },
    },
  };
  return graphqlRequestBody;
}

export const Neo4jMutations = {
  createNewUser,
  createNewListing,
  createNewOrganization,
  updateUserOrganizationRole,
  deleteUser,
  deleteListing,
  deleteOrganization,
  connectUserToOrganization,
  addOrganizationRoleToUser,
  addListingsToOrganization,
  removeUserFromOrganization,
  removeListingFromOrganization,
  updateListingState,
  updateListing,
  updateUserBasicInfo,
};
