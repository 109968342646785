const dictionary = {
  building_sq_ft_code: {
    0: 'Unknown',
    10: 'Living Area',
    11: 'Heated Area',
    12: 'Adjusted Area',
    13: 'Gross Area',
    14: 'Effective Area',
    15: 'Building Perimeter',
    16: 'Total Area',
    17: 'Base Area',
    18: 'Garage',
    19: 'Attic',
    20: 'Finished Area',
  },
  construction_code: {
    0: 'None Stated',
    1: 'Type Not Specified',
    2: 'Frame',
    7: 'Light Frame',
    8: 'Heavy Frame',
    9: 'Pole Frame',
    10: 'Wood',
    15: 'Wood Frame',
    19: 'Metal Frame',
    20: 'Steel Frame',
    23: 'Concrete',
    24: 'Steel & Concrete',
    25: 'Masonry/Concrete Masonry Units (Cmus)',
    28: 'Concrete Blocks',
    31: 'Brick',
    38: 'Stone',
    41: 'Adobe',
    42: 'Log',
    43: 'Manufactured',
    46: 'Tilt-Up Concrete',
    50: 'Arched/Dome',
    99: 'Mixed',
  },
  driveway_material_code: {
    D: 'Driveway',
    DA: 'Driveway Asphalt',
    DB: 'Driveway Bomanite',
    DC: 'Driveway Chat',
    DK: 'Driveway Brick',
    DO: 'Driveway Concrete',
    DP: 'Driveway Paver',
    DR: 'Driveway Gravel',
    DT: 'Driveway Tile',
  },
  exterior_code: {
    '0A': 'Unknown',
    '10A': 'Asbestos',
    '11A': 'Plywood',
    '12C': 'Brick Veneer',
    '13A': 'Stone Veneer',
    '14A': 'Shake',
    '15I': 'Logs',
    '16A': 'Protective (Treatment)',
    '17A': 'Vinyl',
    '18V': 'Shingles',
    '19A': 'Marblecrete',
    '1L': 'Stucco',
    '20H': 'Glass',
    '21A': 'None',
    '22A': 'Channel',
    '23A': 'Tong & Groove',
    '24E': 'Composition',
    '25A': 'Rustic',
    '26A': 'Bat & Board',
    '27A': 'Masonite',
    '28A': 'Ribbed',
    '29A': 'Other (Not Classified)',
    '2R': 'Siding',
    '30A': 'Ribbed Aluminum',
    '31A': 'Aluminum Lap',
    '32A': 'Frame Brick',
    '33A': '8 Inch Paint',
    '34K': 'Stone',
    '35T': 'Adobe',
    '36A': 'Steel Panel',
    '37A': 'Pre-Cast',
    '38A': 'Strut Frame',
    '39A': 'Comb',
    '3B': 'Brick',
    '40A': 'Curtain',
    '41A': 'Wood/Steel Stud',
    '42A': 'Single Wall',
    '43A': 'Farm Single',
    '44A': 'Buttressed',
    '45A': 'Aspht Shingle',
    '46A': 'Hardwood Siding',
    '47P': 'Wood Shingle',
    '48D': 'Block',
    '49A': '2X2 Frame',
    '4Y': 'Brick/Stone (Brick And/Or Stone)',
    '50A': '2X3 Frame',
    '51A': '2X4 Frame',
    '52A': '2X6 Frame',
    '53A': 'Modular',
    '54A': 'Pole',
    '55A': 'Baked Enamel',
    '56A': 'Cedar',
    '57A': 'Paper',
    '58A': 'Cinder Block',
    '59A': 'Diagonal',
    '5G': 'Concrete Block',
    '60A': 'Horizontal',
    '61A': 'Vertical',
    '62A': 'Drop',
    '63A': 'Log 1/2 Round',
    '64A': 'Lap',
    '65A': 'Panel',
    '66A': 'T111',
    '67A': 'Frame/Stucco',
    '68A': 'Masonry/Stucco',
    '69A': 'Aluminum Siding',
    '6A': 'Slump Block',
    '70A': 'Board & Batten',
    '71A': 'Frame Siding',
    '72A': 'Frame/Shingle Siding',
    '73A': 'Wood Frame/Siding',
    '74A': 'Brick/Wood',
    '75A': 'Brick Frame/Stone',
    '76A': 'Frame/Masonry',
    '77A': 'Frame/Stone',
    '78A': 'Stone/Wood Frame',
    '79A': 'Block/Masonry',
    '7Q': 'Wood',
    '80A': 'Concrete Block/Stucco',
    '81F': 'Concrete',
    '82A': 'Concrete/Cinder Block',
    '83N': 'Precast Concrete Panel',
    '84A': 'Frame/Wood',
    '85A': 'Prefab Wood',
    '86A': 'Wood Frame',
    '87A': 'Frame/Aluminum',
    '88A': 'Metal Siding',
    '89A': 'Asbestos Frame',
    '8A': 'Bevel (Finishing Style)',
    '90A': 'Frame/Masonry/Veneer',
    '91A': 'Aluminum/Vinyl',
    '92A': 'Frame/Vinyl',
    '98X': 'Combination',
    '99A': 'Wood Siding',
    '9J': 'Metal',
  },
  fence_code: {
    F: 'Fence',
    FB: 'Barbed Wire Fence',
    FC: 'Chain Link Fence',
    FH: 'Hurricane Fence',
    FI: 'Wrought Iron Or Iron Fence',
    FK: 'Brick Fence/Wall',
    FL: 'Concrete Block Fence/Wall',
    FM: 'Metal Fence',
    FP: 'Picket Fence',
    FR: 'Rail Fence',
    FS: 'Stockade Fence',
    FT: 'Stone Fence/Wall',
    FV: 'Vinyl Fence',
    FW: 'Wood Fence',
    FX: 'Combo',
    FY: 'Grapestake',
    FZ: 'Solid Board',
    XX: 'Other',
  },
  fire_resistance_class_code: {
    0: 'Unknown',
    101: 'Fireproofed Structural Steel Frames Carrying All Wall, Floor And Roof Loads. Wall, Floor And Roof Structures Are Built Of Non-Combustible Materials',
    102: 'Fireproofed Reinforced Concrete Frames Carrying All Wall, Floor And Roof Loads Which Are All Non-Combustible',
    103: 'Exterior Walls Built Of A Non-Combustible Material Such As Brick, Concrete, Block Or Poured Concrete. Interior Partitions And Roof Structure Are Built Of Combustible Materials. Floor May Be Concrete Or Wood Frame',
    104: 'Wood Or Wood And Steel Frames',
    105: 'Specialized Buildings That Do Not Fit In Any Of The Above Categories',
  },
  fireplace_code: {
    0: 'Unknown',
    1: 'Yes',
    2: 'Single',
    3: 'Backed',
    4: 'Stacked',
    5: 'Custom',
    6: 'Glass Log',
    7: 'Prefab',
    8: 'Flue Only',
    9: 'See Thru',
    10: 'Wood Stove',
    11: 'Electric',
    12: 'Raised Hearth',
    13: 'Masonry',
    14: 'Heatltr/Circle',
    15: 'Ceiling Hi Brick',
    16: '1 Story Brick',
    17: '2 Story Brick',
    18: '2 Story',
    19: 'Double',
  },
  flooring_material_code: {
    0: 'Not Stated',
    1: 'Type Unspecified',
    2: 'Carpet',
    3: 'Vinyl/Resilient',
    4: 'Vinyl Tile (Vct)',
    6: 'Vinyl Sheet/Linoleum',
    8: 'Laminate Plank',
    13: 'Wood (Unspecified)',
    14: 'Hardwood',
    15: 'Softwood',
    16: 'Parquet',
    17: 'Plywood',
    18: 'Wood Decking',
    19: 'Wood, Pine',
    20: 'Wood, Oak',
    21: 'Wood, Maple',
    22: 'Wood, Fir',
    23: 'Wood, Teak',
    24: 'Cork',
    25: 'Cork Tile',
    28: 'Slate',
    29: 'Marble',
    30: 'Granite',
    31: 'Stone, Other',
    32: 'Ceramic Tile',
    33: 'Ceramic Tile, Glazed',
    34: 'Mosaic Tile',
    35: 'Clay/Quarry Tile',
    36: 'Terrazzo',
    37: 'Concrete/Cement',
    38: 'Concrete Slab',
    39: 'Concrete, Precast',
    40: 'Tile (Unspecified)',
    41: 'Cinder Block',
    42: 'Brick',
    43: 'Asphalt',
    44: 'Gravel',
    45: 'Asbestos (Tiles, Sheet)',
    46: 'Rubber',
    47: 'Earth/Dirt',
    48: 'Acrylic/Epoxy',
    49: 'Carpet, Tile & Wood',
    50: 'Carpet & Wood',
    51: 'Wood & Tile',
    52: 'Carpet & Tile',
    54: 'Vinyl & Wood',
    59: 'Asbestos & Other',
    60: 'Combination, Other',
  },
  foundation_code: {
    0: 'Unknown',
    1: 'No Foundation',
    100: 'Mat/Raft Foundation (Slab)',
    101: 'Concrete Beam & Slab',
    200: 'Footing',
    201: 'Footing - Continuous/Strip',
    202: 'Footing - Spread',
    300: 'Pile',
    301: 'Pile - Concrete',
    302: 'Pile - Wood/Timber',
    400: 'Pier/Post & Beam',
    401: 'Pier/Post - Wood',
    501: 'Raised (Unspecified)',
    510: 'Stem Wall/Crawlspace',
    920: 'Wood (Unspecified)',
    921: 'Stone (Unspecified)',
    922: 'Masonry (Unspecified)',
    923: 'Block (Unspecified)',
    924: 'Brick (Unspecified)',
    930: 'Retaining Wall',
    999: 'Type Not Specified',
  },
  geocode_quality_code: {
    0: 'Rooftop',
    1: 'Street Level',
    5: 'Zip Centroid',
    9: 'County Level',
  },
  hvacc_cooling_code: {
    0: 'Unknown',
    101: 'Refrigerator',
    102: 'Evaporative',
    103: 'Central',
    104: 'Solar',
    105: 'Refrigerator/Evaporative',
    106: 'Wall Unit',
    107: 'Refrigeration Wall Unit',
    108: 'Evaporative Wall Unit',
    109: 'None',
    110: 'Window A/C',
    111: 'Window Evaporative',
    112: 'Yes',
    113: 'Ac.Central',
    114: 'Ac.Chilled Water',
    115: 'Ac.Central Partial',
    116: 'Ac.Central & Unit',
    117: 'Ac.Dual Unit',
    118: 'Ac.Evaporative',
    119: 'Ac.Fan Cooling',
    120: 'Ac.Heat Pump',
    121: 'Ac.Office Only',
    122: 'Ac.Refrigeration',
    123: 'Ac.Package',
    124: 'Ac.Package Roof',
    125: 'Ac.Partial',
    126: 'Ac.Refrigeration/Evaporation',
    127: 'Ac.Separate System',
    128: 'Ac.Solar',
    129: 'Ac.Split System',
    130: 'Ac.Wall Unit',
    131: 'Ac.Window Unit',
    132: 'Ac.Wall/Window Unit',
    133: 'Ac.None',
    134: 'Bypass',
    135: 'Type Unknown',
    136: 'Evaporative Cooler',
    137: 'Other',
    138: 'Partial',
    139: 'Chilled Water',
    140: 'Yes',
    141: 'Ac.Commercial A/C',
  },
  hvacc_heating_code: {
    0: 'Unknown',
    101: 'Other',
    102: 'Forced Air',
    103: 'Baseboard',
    104: 'Gravity',
    105: 'Heat Pump',
    106: 'Solar',
    107: 'Ceiling Cable',
    108: 'Central',
    109: 'Floor/Wall Furnace',
    110: 'Steam',
    111: 'Hot Water',
    112: 'Radiant',
    113: 'Electric',
    114: 'Fireplace',
    115: 'Ceiling Furnace',
    116: 'Stove',
    117: 'Forced Air With Air Conditioning',
    118: 'Floor Furnace',
    119: 'Wall Gas',
    120: 'Space',
    121: 'Package',
    122: 'Wood Furnace',
    219: 'None',
    222: 'Convection',
    226: 'Partial',
    229: 'Coal',
    231: 'Gas',
    232: 'Oil',
    236: 'Yes',
    238: 'Zone',
    239: 'Forced Air',
    243: 'Geo-Thermal',
    244: 'Propane',
  },
  hvacc_heating_fuel_code: {
    0: 'Unknown',
    30: 'Coal',
    80: 'Electric',
    90: 'Gas',
    120: 'Geo-Thermal',
    130: 'Heat Pump',
    160: 'Heat.Electric/Hot Water',
    300: 'Liquefied Petroleum Gas',
    320: 'Oil',
    340: 'Other',
    350: 'Propane',
    360: 'Solar',
    370: 'Solid',
    380: 'Type Unknown',
    400: 'Wood',
  },
  interior_structure_code: {
    0: 'No Interior Walls',
    101: 'Finished/Painted',
    102: 'Custom/Decorative',
    103: 'Combination',
    104: 'Composition',
    110: 'Paneling (Unspecified)',
    180: 'Firewall',
    190: 'Unfinished',
    199: 'Other',
    201: 'Wallboard/Drywall',
    202: 'Drywall & Plaster',
    203: 'Wood & Drywall',
    210: 'Cement Board',
    301: 'Wood (Unspecified)',
    302: 'Wood, Cedar',
    303: 'Wood, Hardwood',
    304: 'Wood, Pine',
    305: 'Wood, Redwood',
    310: 'Wood Paneling',
    320: 'Plywood',
    321: 'Plywood Paneling',
    330: 'Fiber/Woodboard',
    350: 'Log',
    360: 'Wood Shingle',
    401: 'Masonry (Unspecified)',
    402: 'Block (Unspecified)',
    410: 'Brick',
    411: 'Brick & Block',
    412: 'Brick & Stone',
    420: 'Stone',
    421: 'Stone, Marble',
    430: 'Concrete',
    431: 'Concrete Tilt-Up',
    432: 'Concrete Block',
    501: 'Tile',
    590: 'Acoustic Tile/Panels',
    601: 'Metal',
    602: 'Tin',
    603: 'Copper',
    610: 'Enameled Metal',
    701: 'Plaster',
    702: 'Plaster & Masonry',
    703: 'Plaster & Paneling',
    710: 'Stucco',
    720: 'Terrazzo',
    730: 'Wallpaper',
    740: 'Formica',
    750: 'Glass',
    760: 'Vinyl Panel/Coating',
    770: 'Adobe',
    790: 'Fiberglass',
    999: 'Type Not Specified',
  },
  mailing_format_code: {
    4: 'Non-Us',
    A: 'Alias',
    F: 'Company',
    G: 'General Delivery',
    H: 'Highrise',
    P: 'Po Box',
    R: 'Rural Route',
    S: 'Standard Us',
  },
  owner_trust_type_code: {
    Estate: 'The Owner Is An Estate',
    'Name is a Trust': 'The Owner Is A Trust',
    'Not a trust': 'The Owner Is Not A Trust',
    Unknown: 'A Determination Cannot Be Made',
  },
  ownership_vesting_relation_code: {
    0: 'Unknown',
    101: 'Brother And Sister',
    103: 'Company',
    104: 'Corporate Trust',
    105: 'Deceased',
    107: 'Divorced',
    109: 'Husband And Wife',
    111: 'Individual Man',
    113: 'Individual Woman',
    115: 'Married',
    117: 'Married Couple',
    118: 'Married Man',
    120: 'Married Woman',
    122: 'Personal Trust',
    123: 'Single',
    125: 'Single Man',
    127: 'Single Woman',
    129: 'Trustee',
    130: 'Trust',
    131: 'Unmarried Man',
    133: 'Unmarried',
    135: 'Unmarried Woman',
    137: 'Widow',
    139: 'Widower',
    141: 'Heirs',
    142: 'Single',
    143: 'Wife',
    144: 'Owner Has Dba Data',
    145: 'Wholly Exempt',
    146: 'Association',
    147: 'Trustee Corporation',
    148: 'Contract Purchase',
    149: 'Community Property',
    150: 'Estate',
    151: 'Executor',
    152: 'Guardian',
    153: 'Joint Tenant/Joint Tenant W/ Right Of Survivorship',
    154: 'Joint Venture',
    155: 'Lifetime Benefit',
    156: 'Life Estate',
    157: 'Lease From',
    158: 'Limited Partnership',
    159: 'Living Trust',
    160: 'Non Declared Trust',
    161: 'Personal Representative',
    162: 'Partnership',
    163: 'Remainderman',
    164: 'Right Of Survivorship',
    165: 'Revocable Trust',
    166: 'Separate Estate/Property',
    167: 'Sole Owner',
    168: 'Survivor',
    169: 'Tenants In Common',
    170: 'Trust',
    171: 'Tenant By Entirety',
    172: 'Undivided Interest',
    173: 'Name Derived From Affidavit',
    174: 'Also Known As (Aka)',
    175: 'Doing Business As (Dba)',
    176: 'Defendant',
    177: 'Dower Clause',
    178: 'Formerly Known As (Fka)',
    179: 'Government',
    180: 'Individual(S)',
    181: 'Seller Is Owner On Current Assessment File',
    182: 'Life Tenant (Holds A Life Estate Interest Only)',
    183: 'Minor, Ward Or Client (Represented By Trustee)',
    184: 'Now Known As',
    185: 'Not Provided (Name Blurred Or Missing From Document)',
    186: 'Non-Vested Spouse',
    187: 'Plaintiff',
    188: 'Receiver',
    189: 'Revocable Living Trust',
    190: 'Surviving Joint Tenant',
    191: 'Successor Trustee (La County Only)',
    192: 'Separated',
    193: "Trustor/Debtor (Borrower In Default/Foreclosure On Trustee'S Deed)",
    194: 'Who Acquired Title As',
    195: 'Widow Or Widower',
    196: 'Agent Or Guardian',
    197: 'Alternate Beneficiary',
    198: 'Assignee (Buyer/Borrower Only)',
    199: 'Assignor (Seller Only)',
    200: 'Beneficiary / Creditor - When Doc Type Bd, This Buyer Id Code Identifies The Person Designated To Acquire Title Upon Death Of Grantor.',
    201: 'Builder/Developer',
    202: 'Last Will And Testament',
    203: 'Irrevocable Trust',
    204: 'Irrevocable Living Trust',
    205: 'Joint Tenants',
    206: 'Married Man As His Sole And Separate Property',
    207: 'Married Woman As Her Sole And Separate Property',
    208: 'Partnership',
    209: 'Transfer Of Death',
    210: 'Tenant',
    211: 'Trustee/Conservator',
    212: 'Trust',
    213: 'Surviving Tenant By The Entirety',
    214: 'Surviving Spouse',
    215: 'Tax Payer',
    216: 'Domestic Partner',
    217: 'Community Property (Marital Community) With Rights Of Survivorship',
    218: 'Et Al (And Others) - Vendor Data Only',
    219: 'Et Ux (And Wife) - Vendor Data Only',
    220: 'Family Living Trust',
    221: 'Family Trust',
    222: 'Family Revocable Trust',
    223: 'Her Husband',
    224: 'Single Person Or Individual',
    225: 'His Wife',
    226: 'Et Vir (And Husband)',
    227: 'Contract Owner',
    228: 'Tenants In Severalty',
  },
  parking_garage_code: {
    0: 'No Garage',
    1: 'Unknown Or Not Provided',
    4: 'Pole Building Garage',
    11: 'Garage, Attached',
    12: 'Garage, Detached',
    13: 'Garage, Unfinished',
    14: 'Garage, Finished',
    15: 'Garage, Enclosed',
    16: 'Garage, Open',
    17: 'Detached (Unspecified)',
    18: 'Attached (Unspecified)',
    19: 'Detached, Finished',
    20: 'Detached, Unfinished',
    21: 'Attached, Finished',
    22: 'Attached, Unfinished',
    23: 'Detached, 1-Car',
    24: 'Detached, 2-Car',
    25: 'Detached, 3+ Car',
    26: 'Attached, 1-Car',
    27: 'Attached, 2-Car',
    28: 'Attached, 3+ Car',
    30: 'Carport (Unspecified)',
    31: 'Carport, Attached',
    32: 'Carport, Detached',
    33: 'Carport, Enclosed',
    34: 'Carport, Open',
    35: 'Carport, 1-Car',
    36: 'Carport, 2-Car',
    37: 'Carport, 3+ Car',
    38: 'Carport, Finished',
    39: 'Carport, Unfinished',
    40: 'Garage, Basement',
    41: 'Garage, Basement, 1-Car',
    42: 'Garage, Basement, 2-Car',
    43: 'Garage, Basement, 3+ Car',
    44: 'Garage, Basement, Finished',
    45: 'Garage, Basement, Finished, 1-Car',
    46: 'Garage, Basement, Finished, 2-Car',
    47: 'Garage, Basement, Finished, 3+ Car',
    48: 'Garage, Basement, Unfinished',
    49: 'Garage, Basement, Unfinished, 1-Car',
    50: 'Garage, Basement, Unfinished, 2-Car',
    51: 'Garage, Basement, Unfinished, 3+ Car',
    52: 'Garage, Tuckunder',
    53: 'Garage, Built-In',
    54: 'Garage, Built-In, 1-Car',
    55: 'Garage, Built-In, 2-Car',
    57: 'Garage, Built-In, Unfinished',
    58: 'Garage, Built-In, Finished',
    59: 'Garage, 1-Car',
    60: 'Garage, 2-Car',
    61: 'Garage, 3+ Car',
    62: 'Garage, Unfinished, 1-Car',
    63: 'Garage, Unfinished, 2-Car',
    64: 'Garage, Unfinished, 3+ Car',
    65: 'Carport, Detached, Finished',
    67: 'Carport, Detached, Unfinished',
    68: 'Covered',
    69: 'Mixed',
    189: 'Parking Structure',
    999: 'Type Not Specified',
  },
  pool_code: {
    0: 'No Pool',
    101: 'Public/Municipal/Commercial',
    110: 'Above-Ground Pool',
    121: 'In-Ground Vinyl Pool',
    130: 'Indoor Pool',
    210: 'Pool, Gunite',
    220: 'Pool, Fiberglass',
    230: 'Pool, Concrete',
    241: 'Pool, Plastic/Vinyl Lined',
    401: 'Pool (Unspecified), Heated',
    402: 'Pool, Solar Heated',
    502: 'Pool W/Hot Tub/Spa',
    610: 'Pool (Unspecified), Enclosed',
    904: 'Spa/Hot Tub (Only)',
    999: 'Type Not Specified',
  },
  porch_code: {
    P00: 'Porch',
    P0Y: 'Glass Porch',
    P10: 'Finished/Screened Porch',
    PC0: 'Covered Porch',
    PE0: 'Enclosed Porch',
    PO0: 'Open Porch',
    PX0: 'Enclosed/Chattahoochee Porch',
  },
  property_use_standardized_code: {
    0: 'Unknown',
    101: 'Dairy Farm',
    102: 'Desert Or Barren Land',
    103: 'Farm, Crops',
    104: 'Feedlots',
    105: 'Farm (Irrigated Or Dry)',
    106: 'Horticulture, Ornamental (Agricultural)',
    107: 'Irrigation, Flood Control',
    108: 'Livestock, Animals',
    109: 'Miscellaneous Structures - Ranch, Farm Fixtures',
    110: 'Orchard (Fruit, Nut)',
    111: 'Orchards, Groves',
    112: 'Pasture',
    113: 'Poultry Farm (Chicken, Turkey, Fish, Bees, Rabbits)',
    114: 'Ranch',
    115: 'Reservoir, Water Supply',
    116: 'Rural Improved / Non-Residential',
    117: 'Range Land (Grazing)',
    118: 'Agricultural/Rural (General)',
    119: 'Truck Crops',
    120: 'Timberland, Forest, Trees',
    121: 'Vineyard',
    122: 'Well Site (Agricultural)',
    123: 'Wildlife (Refuge)',
    124: 'Convenience Store (7-11)',
    125: 'Appliance Store (Circuit City, Goods Buys, Best Buy)',
    126: 'Auto Repair, Garage',
    127: 'Vehicle Sales, Vehicle Rentals (Auto/Truck/Rv/Boat/Etc)',
    128: 'Bakery',
    129: 'Bar, Tavern',
    130: 'Commercial Building, Mail Order, Show Room (Non-Auto), Warehouse',
    131: 'Bed & Breakfast',
    132: 'Casino',
    133: 'Cemetery, Funeral Home, Mortuary (Commercial)',
    134: 'Common Area (Commercial, Not Shopping Center)',
    135: 'Commercial (General)',
    136: 'Commercial Office (General)',
    137: 'Convenience Store (W/Fuel Pump)',
    138: 'Commercial Condominium (Not Offices)',
    139: 'Condominium Offices',
    140: 'Store/Office (Mixed Use)',
    141: 'Department Store (Apparel, Household Goods, Furniture)',
    142: 'Dental Building',
    143: 'Department Store (Multi-Story)',
    144: 'Garden Center, Home Improvement (Do-It-Yourself)',
    145: 'Drug Store, Pharmacy',
    146: 'Drive-Thru Restaurant, Fast Food',
    147: 'Dry Cleaner',
    148: 'Restaurant',
    149: 'Farm Supply & Equipment (Commercial)',
    150: 'Financial Building',
    151: 'Grocery, Supermarket',
    152: 'Hospital - Private',
    153: 'Hotel/Motel',
    154: 'Hotel-Resort',
    155: 'Hotel',
    156: 'Kennel',
    157: 'Laundromat (Self-Service)',
    158: 'Liquor Store',
    159: 'Mobile Commercial Units',
    160: 'Medical Building',
    161: 'Mixed Use (Commercial/Industrial)',
    162: 'Mobile Home Park, Trailer Park',
    163: 'Motel',
    164: 'Commercial Multi-Parcel Miscellaneous',
    165: 'Commercial Miscellaneous',
    166: 'Nightclub (Cocktail Lounge)',
    167: 'Neighborhood: Shopping Center, Strip Center, Enterprise Zone',
    168: 'Nursery, Greenhouse, Florist (Retail, Wholesale)',
    169: 'Office Building',
    170: 'Office Building (Multi-Story)',
    171: 'Commercial Office/Residential (Mixed Use)',
    172: 'Parking Garage, Parking Structure',
    173: 'Printer - Retail (Pip, Qwikcopy, Etc)',
    174: 'Parking Lot',
    175: 'Day Care, Pre-School (Commercial)',
    176: 'Professional Building (Multi-Story)',
    177: 'Professional Building (Legal, Insurance, Real Estate, Business)',
    178: 'Retail Stores (Personal Services, Photography, Travel)',
    179: 'Regional: Shopping Center, Mall (W/Anchor)',
    180: 'Gas Station',
    181: 'Single Family Residential',
    182: 'Shopping Center Common Area (Parking Etc)',
    183: 'Community: Shopping Center, Mini-Mall',
    184: 'Skyscraper/High-Rise (Commercial Offices)',
    185: 'Service Station W/Convenience Store (Food Mart)',
    186: 'Service Station (Full Service)',
    187: 'Stores & Apartments',
    188: 'Store, Retail Outlet',
    189: 'Take-Out Restaurant (Food Preparation)',
    190: 'Truck Stop (Fuel And Diner)',
    191: 'Service Shop (Tv, Radio, Electric, Plumbing)',
    192: 'Veterinary, Animal Hospital',
    193: 'Car Wash',
    194: 'Wholesale Outlet, Discount Store (Franchise)',
    195: 'Assembly (Light Industrial)',
    196: 'Bulk Storage, Tanks (Gasoline, Fuel, Etc)',
    197: 'Cannery',
    198: 'Construction/Contracting Services (Industrial)',
    199: 'Chemical',
    200: 'Common Area (Industrial)',
    201: 'Condominiums (Industrial)',
    202: 'Cold Storage',
    203: 'Distillery, Brewery, Bottling',
    204: 'Dump Site',
    205: 'Factory (Apparel, Textile, Leather, Medium Mfg)',
    206: 'Food Processing',
    207: 'Foundry, Industrial Plant (Metal, Rubber, Plastic)',
    208: 'Food Packing, Packing Plant (Fruit, Vegetable, Meat, Dairy)',
    209: 'Grain Elevator',
    210: 'Heavy Industrial (General)',
    211: 'Heavy Manufacturing',
    212: 'Industrial (General)',
    213: 'Industrial Park',
    214: 'Labor Camps (Industrial)',
    215: 'Light Industrial (10% Improved Office Space; Machine Shop)',
    216: 'Industrial Loft Building, Loft Building',
    217: 'Lumberyard, Building Materials',
    218: 'Lumber & Wood Product Mfg (Including Furniture)',
    219: 'Marine Facility/Board Repairs (Small Craft, Sailboat)',
    220: 'Manufacturing (Light)',
    221: 'Mill (Feed, Grain, Paper, Lumber, Textile, Pulp',
    222: 'Mining, Mineral, Quarries',
    223: 'Industrial Miscellaneous',
    224: 'Multi-Tenant Industrial Building',
    225: 'Paper Product Mfg & Related Products',
    226: 'Refinery, Petroleum Products',
    227: 'Printing * Publishing (Light Industrial)',
    228: 'Processing Plant (Minerals, Cement, Rock, Gravel, Glass, Clay)',
    229: 'Mini-Warehouse, Storage',
    230: 'Quarries (Sand, Gravel, Rock)',
    231: 'R&D Facility, Laboratory, Research Facility, Cosmetics, Pharmaceutical',
    232: 'Recycling (Metal, Paper, Glass)',
    233: 'Shipyard - Built Or Repaired (Seagoing Vessels)',
    234: 'Slaughter House, Stockyard',
    235: 'Storage Yard (Junk, Auto Wrecking, Salvage)',
    236: 'Storage Yard, Open Storage (Light Equipment, Material)',
    237: 'Sugar Refinery',
    238: 'Warehouse, Storage',
    239: 'Winery',
    240: 'Waste Disposal, Sewage (Processing, Disposal, Storage, Treatment)',
    241: 'Common Area (Misc)',
    242: 'Easement (Misc)',
    243: 'Homestead (Misc)',
    244: 'Leasehold Rights (Misc)',
    245: 'Petroleum & Gas Wells (Misc)',
    246: 'Pipeline Or Right-Of-Way',
    247: 'Possessory Interest (Misc)',
    248: 'Rail (Right-Of-Way & Track)',
    249: 'Road (Right-Of-Way)',
    250: 'Royalty Interest',
    251: 'Right-Of-Way (Not Rail, Road Or Utility)',
    252: 'Sub-Surface Rights (Mineral)',
    253: 'Surface Rights (Grazing, Timber, Coal, Etc.)',
    254: 'Unknown',
    255: 'Utilities (Right-Of-Way Only)',
    256: 'Water Rights (Misc)',
    257: 'Working Interest',
    258: 'Airport & Related',
    259: 'Arcades (Amusement)',
    260: 'Arena, Convention Center',
    261: 'Auditorium',
    262: 'Outdoor Recreation: Beach, Mountain, Desert',
    263: 'Pool Hall, Billiard Parlor',
    264: 'Bowling Alley',
    265: 'Bus Terminal',
    266: 'Commercial Auto Transportation/Storage',
    267: 'Country Club',
    268: 'Centrally Assessed',
    269: 'Charitable Organization, Fraternal',
    270: 'Clubs, Lodges, Professional Associations',
    271: 'Community Center (Exempt)',
    272: 'Communications',
    273: 'Campground, Rv Park',
    274: 'College, University, Vocational School - Private',
    275: 'Crematorium, Mortuary (Exempt)',
    276: 'Cable Tv Station',
    277: 'City, Municipal, Town, Village Owned (Exempt)',
    278: 'County Owned (Exempt)',
    279: 'Dance Hall',
    280: 'Distribution Warehouse (Regional)',
    281: 'Drive-In Theater',
    282: 'Cemetery (Exempt)',
    283: 'Emergency (Police, Fire, Rescue, Shelters, Animal Shelter)',
    284: 'Exempt (Full Or Partial)',
    285: 'Fairgrounds',
    286: 'Federal Property (Exempt)',
    287: 'Fish Camps, Game Club Target Shooting',
    288: 'Forest (Park, Reserve, Recreation, Conservation)',
    289: 'Freeways, State Hwys',
    290: 'Driving Range (Golf)',
    291: 'Transportation (General)',
    292: 'Go-Carts, Miniature Gold, Water Slides',
    293: 'Golf Course',
    294: 'Governmental / Public Use (General)',
    295: 'Govt. Administrative Office (Federal, State, Local, Court House)',
    296: 'Gym, Health Spa',
    297: 'Historical District',
    298: 'Cultural, Historical (Monuments, Homes, Museums, Other)',
    299: 'Historical Transient Lodging (Hotel, Motel)',
    300: 'Harbor & Marine Transportation',
    301: 'Historical Office',
    302: 'Hospital - Public',
    303: 'Historical Park, Site, Misc.',
    304: 'Historical - Private (General)',
    305: 'Historical Recreation, Entertainment',
    306: 'Historical Residence',
    307: 'Historical Retail',
    308: 'Historical Warehouse',
    309: 'Indian Lands',
    310: 'Institutional (General)',
    311: 'Marina, Boat Slips, Yacht Club, Boat Landing',
    312: 'Medical Clinic',
    313: 'Microwave',
    314: 'Military (Office, Base, Post, Port, Reserve, Weapon Range, Test Sites)',
    315: 'Miscellaneous (General)',
    316: 'Museum, Library, Art Gallery (Recreational)',
    317: 'Natural Resources',
    318: 'Recreational Non-Taxable (Camps, Boy Scouts)',
    319: 'Correctional Facility, Jails, Prisons, Insane Asylum',
    320: "Children'S Home, Orphanage",
    321: 'Public Health Care Facility (Exempt)',
    322: 'Park, Playground, Picnic Area',
    323: 'Piers, Wharf (Recreation)',
    324: 'Pollution Control',
    325: 'Post Office',
    326: 'Public Swimming Pool',
    327: 'Amusement Park, Tourist Attraction',
    328: 'Parochial School, Private School',
    329: 'Public Utility (Electric, Water, Gas, Etc.)',
    330: 'Railroad & Related',
    331: 'Racquet Court, Tennis Court',
    332: 'Recreational Center',
    333: 'Regulating Districts & Assessments; Tax Abatement',
    334: 'Recreational/Entertainment (General)',
    335: 'Redevelopment Agency Or Zone',
    336: 'Religious, Church, Worship (Synagogue, Temple, Parsonage)',
    337: 'Riding Stable, Trails',
    338: 'Roads, Streets, Bridges',
    339: 'Homes (Retired, Handicap, Rest, Convalescent, Nursing)',
    340: 'Radio Or Tv Station',
    341: 'Sbe - Special Assessments',
    342: 'Public School (Administration, Campus, Dorms, Instruction)',
    343: 'Skating Rink, Ice Skating, Roller Skating',
    344: 'State Owned (Exempt)',
    345: 'Special Purpose',
    346: 'Stadium',
    347: 'Telegraph, Telephone',
    348: 'Theater (Movie)',
    349: 'Transportation (Air, Rail, Bus)',
    350: 'Race Track (Auto, Dog, Horse)',
    351: 'Truck Terminal (Motor Freight)',
    352: 'Colleges, University - Public',
    353: 'Private Utility (Electric, Water, Gas, Etc.)',
    354: 'Welfare, Social Service, Low Income Housing (Exempt)',
    355: 'Zoo',
    356: 'Other Exempt Property',
    357: 'Garden Apt, Court Apt (5+ Units)',
    358: 'High-Rise Apartments',
    359: 'Apartment House (100+ Units)',
    360: 'Apartments (Generic)',
    361: 'Apartment House (5+ Units)',
    362: 'Boarding/Rooming House, Apt Hotel',
    363: 'Bungalow (Residential)',
    364: 'Cluster Home',
    365: 'Common Area (Residential)',
    366: 'Condominium',
    367: 'Cooperative',
    368: 'Dormitory, Group Quarters (Residential)',
    369: 'Duplex (2 Units, Any Combination)',
    370: 'Fraternity House, Sorority House',
    371: 'Manufactured, Modular, Pre-Fabricated Homes',
    372: 'Multi-Family Dwellings (Generic, Any Combination)',
    373: 'Mobile Home',
    374: 'Residential Multi-Parcel Miscellaneous',
    375: 'Miscellaneous (Residential)',
    376: 'Patio Home',
    377: 'Planned Unit Development (Pud)',
    378: 'Quadplex (4 Units, Any Combination)',
    379: 'Condominium Development (Association Assessment)',
    380: 'Residential (General/Single)',
    381: 'Residential Income (General/Multi-Family)',
    382: 'Row House',
    383: 'Rural Residence',
    384: 'Seasonal, Cabin, Vacation Residence',
    385: 'Single Family Residence',
    386: 'Townhouse',
    387: 'Timeshare',
    388: 'Triplex (3 Units, Any Combination)',
    389: 'Vacant Land',
    390: 'Zero Lot Line (Residential)',
    391: 'Abandoned Site, Contaminated Site',
    392: 'Agricultural (Unimproved) - Vacant Land',
    393: 'Vacant Commercial',
    394: 'Government - Vacant Land',
    395: 'Industrial - Vacant Land',
    396: 'Institutional - Vacant Land',
    397: 'Vacant',
    398: 'Multi-Family - Vacant Land',
    399: 'Private Preserve, Open Space - Vacant Land',
    400: 'Recreational - Vacant Land',
    401: 'Residential - Vacant Land',
    402: 'Under Construction',
    403: 'Unusable Land (Remnant, Steep, Etc.)',
    404: 'Waste Land, Marsh, Swamp, Submerged - Vacant Land',
    406: 'Water Area (Lakes, River, Shore) - Vacant Land',
    407: 'Common Area (Misc.)',
    408: 'Temporary Structures',
    409: 'Vacant Land - Exempt',
    410: 'Sports Complex',
    411: 'Personal Property (General)',
    412: 'Pet Boarding & Grooming',
    413: 'Crops (In Ground)',
    414: 'Structures (General)',
    415: 'Aircraft',
    416: 'Landominium',
    417: 'Surface Rights (Grazing, Timber, Coal, Etc.)',
    418: 'Residential Parking Garage',
    419: 'Inventory',
    420: 'Motor Vehicles (Cars, Trucks, Etc.)',
    421: 'Condominium Building (Residential)',
    422: 'Misc Structures Not Otherwise Classed (Billboards, Etc.)',
    423: 'Barndominium',
    424: 'Sub-Surface Rights (Mineral)',
    425: 'Goods In Transit',
    426: 'Right-Of-Way (Not Rail, Road Or Utility)',
    427: 'Spacecraft',
    428: 'Rail (Right-Of-Way & Track)',
    429: 'Structures On Leased Land',
    430: 'Royalty Interest',
    431: 'Possessory Interest (Misc.)',
    432: 'Watercraft (Ships, Boats, Pwcs, Etc.)',
    433: 'Car Wash - Self-Serve',
    434: 'Rolling Stock (Railroad)',
    435: 'Water Rights (Misc.)',
    436: 'Misc Personal Property Not Otherwise Classed',
    437: 'Intangible Personal Property',
    438: 'Leasehold Rights (Misc.)',
    439: 'Misc. Business Personal Property Not Otherwise Classed',
    440: 'Homestead (Misc.)',
    441: 'Vehicles (General)',
    442: 'Utilities (Right-Of-Way Only)',
    443: 'Pipeline Or Right-Of-Way',
    444: 'Misc Vehicles Not Otherwise Classed (Antiques, Etc.)',
    445: 'Business Personal Property (General)',
    446: 'Crops (Harvested)',
    447: 'Tiny House',
    448: 'Residential Storage Space',
    449: 'Roadside Market',
    450: 'Cannabis Grow Facility',
    451: 'Cellular',
    452: 'Garden Home',
    453: 'Vacant Land - Destroyed/Uninhabitable Improvement',
    454: 'Road (Right-Of-Way)',
    455: 'Equipment / Supplies',
    456: 'Petroleum & Gas Wells (Misc.)',
    457: 'Working Interest',
    458: 'Car Wash - Automated',
    459: 'Cannabis Dispensary',
    460: 'Recreational Vehicles / Travel Trailers',
    461: 'Cooperative Building (Residential)',
    462: 'Vacant Land - Unspecified Improvement',
    463: 'Parcels With Improvements, Use Not Specified',
    464: 'Barber/Hair Salon',
    465: 'Easement (Misc.)',
    466: 'Livestock (Animals, Fish, Birds, Etc.)',
  },
  roof_construction_code: {
    0: 'Unknown',
    101: 'Dormer',
    103: 'Monitor',
    107: 'Barn',
    108: 'Canopy',
    109: 'Frame',
    113: 'Gambrel/Mansard',
    115: 'Butterfly',
    116: 'Complex/Custom',
    117: 'Contemporary',
    118: 'Pitched',
    119: 'Arched',
    120: 'A-Frame',
    121: 'Bubble',
    124: 'Barrel',
    125: 'Pyramid',
    126: 'Swiss Chalet/Alpine',
    127: 'Other',
    197: 'Flat',
    251: 'Gable',
    253: 'Gable/Hip',
    258: 'Hip',
    265: 'Mansard',
    274: 'Shed',
    282: 'Sawtooth',
    283: 'Cathedral/Clerestory',
    289: 'Gambrel',
    311: 'Rigid Frm Bar Jt',
    312: 'Steel Frm/Truss',
    313: 'Bowstring Truss',
    318: 'Wood Truss',
    320: 'Prestress Concrete',
    329: 'Reinforced Concrete',
    345: 'Geodesic / Dome',
  },
  roof_material_code: {
    0: 'Unknown',
    101: 'Unknown Or Not Provided',
    102: 'Wood Shingle',
    103: 'Shake',
    104: 'Tile',
    105: 'Composition Shingle',
    106: 'Roll Composition (Rolled Mineral Roof)',
    107: 'Gravel',
    108: 'Builtup (Layered Asphalt)',
    109: 'Asphalt',
    110: 'Slate',
    111: 'Aluminum',
    112: 'Metal',
    113: 'Enamel',
    114: 'Other (Not Classified)',
    115: 'Asbestos',
    116: 'Roll Paper (Rolled Smooth Roof)',
    117: 'Bi Metal (Two-Ply)',
    118: 'Heavy Composition Shingle',
    119: 'Light Composition Shingle',
    120: 'Average Composition Shingle',
    121: 'Fiberglass',
    122: 'Galvanized',
    123: 'Medium Shake',
    124: 'Bar Tile (Spanish Style)',
    125: 'Clay Tile',
    126: 'Synthetic Tile',
    127: 'Wood Shake/Shingle',
    128: 'Concrete Tile',
    129: 'Rubber/Elastometric',
    130: 'Slate Tile',
    131: 'Wood',
    132: 'Hardwood',
    133: 'Wood.Wood On Steel',
    134: 'Wood',
    135: 'Flat.Wood Shake/Shingle',
    136: 'Gable.Wood Shake/Shingle',
    137: 'Hip.Wood Shake',
    138: 'Hip.Wood Shake/Shingle',
    139: 'Gambrel.Wood Shake/Shingle',
    140: 'Wood Shake',
    141: 'Cedar Shake',
    142: 'Wood Shake/Shingle.Wood Joist',
    143: 'Flat.Tile',
    144: 'Gable.Tile',
    145: 'Hip.Tile',
    146: 'Mansard.Tile',
    147: 'Shed.Tile',
    148: 'Gambrel.Tile',
    149: 'Cement Tile',
    150: 'Ceramic/Glazed Tile',
    151: 'Glazed Tile',
    152: 'A-Frame.Composition Shingle',
    153: 'Flat.Composition Shingle',
    154: 'Gable.Composition Shingle',
    155: 'Hip.Composition Shingle',
    156: 'Mansard.Composition Shingle',
    157: 'Composition',
    158: 'Shingle',
    159: 'Composition Shingle/Metal',
    160: 'Compostion Shingle',
    161: 'Flat.Composition Roll',
    162: 'Gable.Composition Roll',
    163: 'Hip.Composition Roll',
    164: 'Mansard.Composition Roll',
    165: 'Gambrel.Composition Roll',
    166: 'Composition',
    167: 'Flat.Tar & Gravel',
    168: 'Gable.Tar & Gravel',
    169: 'Hip.Tar & Gravel',
    170: 'Shed.Tar & Gravel',
    171: 'Gambrel.Tar & Gravel',
    172: 'Tar & Gravel',
    173: 'Crushed Rock',
    174: 'Gravel & Rock',
    175: 'Rock',
    176: 'Rock & Gravel',
    177: 'Stone/Rock',
    178: 'Stone/Pebble',
    179: 'Stone',
    180: 'Roll Tar & Gravel',
    181: 'Flat.Built Up.Wood',
    182: 'Built Up.Wood Joist',
    183: 'Built Up Composition',
    184: 'Built Up Gypsum',
    185: 'Built Up Metal',
    186: 'Built Up Rock',
    187: 'Built Up Tar & Gravel',
    188: 'Built Up Wood',
    189: 'Built-Up Metal/Gypsum',
    190: 'Built Up Composition/Tar & Gravel',
    191: 'Asphalt Shingle',
    192: 'Asphalt Shingle.Wood Joist',
    193: 'Asphalt Roll',
    194: 'Asphalt Tile',
    195: 'Asphalt/Composition',
    196: 'Asphalt/Composition Shingle',
    197: 'Flat',
    198: 'Flat.Slate/Slag',
    199: 'Gable.Slate/Slag',
    200: 'Hip.Slate/Slag',
    201: 'Mansard.Slate/Slag',
    202: 'Gambrel.Slate/Slag',
    203: 'Aluminum/Shingle',
    204: 'Flat.Metal',
    205: 'Flat.Copper',
    206: 'Gable.Metal',
    207: 'Gable.Steel',
    208: 'Gable.Copper',
    209: 'Hip.Metal',
    210: 'Hip.Copper',
    211: 'Mansard.Metal',
    212: 'Shed.Metal',
    213: 'Gambrel.Metal',
    214: 'Gambrel.Copper/Shingle',
    215: 'Copper',
    216: 'Copper/Shingle',
    217: 'Metal Sheeting',
    218: 'Aluminum.Steel',
    219: 'Copper/Metal',
    220: 'Corrugated Iron',
    221: 'Corrugated Metal',
    222: 'Corrugated Steel',
    223: 'Metal',
    224: 'Metal Tile',
    225: 'Metal/Shingle',
    226: 'Metal/Tar Paper',
    227: 'Metal/Tin',
    228: 'Modular Metal',
    229: 'Prefinished Metal',
    230: 'Tin',
    231: 'Corrugated Metal/Roll Roofing',
    232: 'Corrugated Metal/Tarpaper',
    233: 'Galvanized Sheet Metal.Wood Joist',
    234: 'Metal Sheeting.Steel',
    235: 'Metal Pipe',
    236: 'Copper/Enamel Metal Sheeting',
    237: 'A-Frame',
    238: 'Barn',
    239: 'Canopy',
    240: 'Dormer',
    241: 'Frame',
    242: 'Flat.Concrete',
    243: 'Flat.Wood Beam',
    244: 'Flat.Wood Truss',
    245: 'Flat.Concrete',
    246: 'Flat.Shingle',
    247: 'Gable.Concrete',
    248: 'Gable.Wood Beam',
    249: 'Gable.Wood Joist',
    250: 'Gable.Wood Truss',
    251: 'Gable',
    252: 'Gable.Shingle',
    253: 'Gable/Hip',
    254: 'Hip.Concrete',
    255: 'Hip.Wood Beam',
    256: 'Hip.Wood Joist',
    257: 'Hip.Wood Truss',
    258: 'Hip',
    259: 'Hip.Shingle',
    260: 'Geodesic',
    261: 'Mansard.Concrete',
    262: 'Mansard.Wood Beam',
    263: 'Mansard.Wood Joist',
    264: 'Mansard.Wood Truss',
    265: 'Mansard',
    266: 'Mansard.Shingle',
    267: 'Barrel',
    268: 'Monitor',
    269: 'Contemporary',
    270: 'Shed.Concrete',
    271: 'Shed.Wood Beam',
    272: 'Shed.Wood Joist',
    273: 'Shed.Wood Truss',
    274: 'Shed',
    275: 'Shed.Concrete',
    276: 'Shed.Shingle',
    277: 'Pitched.Concrete',
    278: 'Pitched',
    279: 'Pitched.Composition',
    280: 'Pyramid',
    281: 'Arched',
    282: 'Sawtooth',
    283: 'Cathedral/Clerestory',
    284: 'Bubble',
    285: 'Gambrel.Concrete',
    286: 'Gambrel.Wood Beam',
    287: 'Gambrel.Wood Joist',
    288: 'Gambrel.Wood Truss',
    289: 'Gambrel',
    290: 'Gambrel/Shingle',
    291: 'Swiss Chalet/Alpine',
    292: 'Complex/Custom',
    293: 'Butterfly',
    294: 'Gambrel/Mansard',
    295: 'Steel Joist/Mtl Deck/Slab',
    296: 'Bar Joist/Wd Joist/Sheath',
    297: 'Bar Joist',
    298: 'Concrete',
    299: 'Bar Joist & Wood Deck',
    300: 'Wood Frame',
    301: 'Bowstring',
    302: 'Bar Joist/Gyp/Wd Joist/Sheath',
    303: 'Conc Jst/Slab/Bar/Jst/Cored Pl',
    304: 'Bar Joist & Concrete Deck',
    305: 'Bar Joist/Gypsum',
    306: 'Flexible/Flexicore',
    307: 'Concrete Joist/Slab',
    308: 'Reinforced Concrete',
    309: 'Longspan Truss',
    310: 'Prestress Concrete',
    311: 'Bar Joist & Rigid Frame',
    312: 'Steel',
    313: 'Truss/Joist',
    314: 'Stl Jst/Mtl Dck/Slab/Con Jst',
    315: 'Conc Jst/Slab/Stl Jst',
    316: 'Wood Joist',
    317: 'Wood On Steel',
    318: 'Wood Truss',
    319: 'Custom',
    320: 'Concrete',
    321: 'Gypsum',
    322: 'Cement/Composition',
    323: 'Concrete Deck',
    324: 'Corrugated Composition',
    325: 'Concrete.Concrete',
    326: 'Concrete.Steel',
    327: 'Plastic/Urethane',
    328: 'Precast Concrete',
    329: 'Reinforced Concrete',
    330: 'Bermuda/Clay Tile/Wood Shingle',
    331: 'Synthetic',
    332: 'Bahama/Ceramic Tile/Blt-Up T&G',
    333: 'Shingle/Tar/Gravel/Roll Slate',
    334: 'Bermuda/Conc Pl/Bltup T&G',
    335: 'Gable/Shaped Wood Truss',
    336: 'Prestressed Concrete',
    337: 'Gable/Shaped Steel Truss',
    338: 'Wood Hip/Gable/Wood Truss',
    339: 'Gypsum.Steel',
    340: 'Shingle.Concrete',
    341: 'Irregular.Wood Truss',
    342: 'Irregular',
    343: 'Lean To',
    344: 'Salt Box',
    345: 'Dome',
    346: 'Flat.Asbestos',
    347: 'Gable.Asbestos Shingle',
    348: 'Hip.Asbestos Shingle',
    349: 'Mansard.Asbestos Shingle',
    350: 'Gambrel.Asbestos Shingle',
    351: 'Asbestos Shingle',
    352: 'Asbestos/Wood',
    353: 'Asbestos/Wood/Shingle',
    354: 'Corrugated Asbestos',
    355: 'Flat.Roll',
    356: 'Gable.Roll',
    357: 'Hip.Roll',
    358: 'Mansard.Roll',
    359: 'Shed.Roll',
    360: 'Gambrel/Roll',
    361: 'Roll',
    362: 'Tar Paper',
    363: 'Roll.Metal',
    364: 'Roll.Wood Joist',
    365: 'Tar Paper.Metal',
    366: 'Fiberglass/Plastic',
    367: 'Clay Tile/Glazed Tile',
    368: 'Clay Tile/Slate',
    369: 'Clay/Concrete Tile',
    370: 'Concrete.Truss/Joist',
  },
  sewer_usage_code: {
    0: 'Unknown',
    101: 'None',
    102: 'Yes',
    104: 'Cesspool',
    105: 'Commercial',
    106: 'Private',
    107: 'Public',
    108: 'Septic',
    109: 'Storm',
    110: 'Developed',
    111: 'Sanitary',
    112: 'Public/Septic',
    113: 'Municipal',
  },
  structure_style_code: {
    0: 'Unknown',
    1: 'Conventional',
    2: 'Modern',
    3: 'Rustic',
    4: 'Spanish',
    5: 'English',
    6: 'French',
    7: 'Colonial',
    8: 'Contemporary',
    9: 'Mountain',
    10: 'A-Frame',
    11: 'Ranch',
    12: 'Other',
    13: 'Bungalow',
    14: 'Cape Cod',
    15: 'Daylite Basement',
    16: 'Split Entry',
    17: 'Split Level',
    18: 'Victorian',
    19: 'Mobile Home',
    20: 'Single Story',
    21: '2 Story Plus',
    22: '1.5 Story',
    23: 'Mobile Home 24-Feet Wide',
    24: 'Mobile Home 28-Feet Wide',
    25: 'Unfinished',
    26: 'Finished',
    27: 'Rambler',
    28: '1.5 Story Finished',
    29: '1.5 Story Unfinished',
    30: 'Dome',
    31: 'Duplex Single',
    32: 'Duplex Double',
    33: 'Triplex',
    34: 'Quadraplex',
    35: 'Commercial',
    36: 'Townhouse',
    37: 'Mansion',
    38: 'Cabin',
    39: '4 Gable',
    40: 'Single Eave',
    41: 'Eaves On 2 Sides',
    42: 'Eaves On 4 Sides',
    43: '1 Story With Basement',
    44: '4 Minimal Eaves',
    45: 'Cottage',
    47: 'Manufactured',
    48: 'No Eaves',
    49: 'New Tradition',
    50: 'Traditional',
    51: 'Garrison',
    52: 'Tudor',
    53: 'Row End Or Row Middle',
    54: 'Decker',
    55: 'Stack',
    56: 'Old Style',
    57: 'Gambrel',
    58: 'Semi-Detached',
    59: 'Antique',
    60: 'European',
    61: 'Revival',
    62: 'Suburban',
    63: 'Mixed Buildings',
    64: 'Dutch Colonial',
    65: 'Free Standing',
    66: 'Low Rise',
    67: 'Mid Rise',
    68: 'High Rise',
    69: 'Condo/Apartment',
    70: 'Homestead',
    71: '2.5 Story',
    72: '3 Story',
    73: 'New Construction',
    74: 'Multi-Unit Building',
    75: 'Family Flat',
    76: 'Two Family',
    77: 'Federalist',
    78: 'Cottage House',
    79: 'Studio',
    80: 'Loft',
    81: 'Penthouse',
    82: 'Modular',
    83: 'Salt Box',
    84: 'Post And Beam',
    85: 'Chalet',
    86: 'Custom Design',
    87: 'Farm House',
    88: 'Garage Apartment',
    89: 'Tlog Home',
    90: 'Mediterranean',
    91: 'Southwestern',
    103: 'Raised Ranch',
    110: 'Georgian',
    125: 'Split Foyer',
    126: 'Bi-Level',
    150: 'Historical',
  },
  topography_code: {
    0: 'Unknown',
    101: 'Gentle Slope',
    102: 'Level',
    103: 'Rolling',
    104: 'Steep Side',
    105: 'Low/Wet',
    106: 'Combo',
    107: 'Broken',
    108: 'Low',
    109: 'High',
    110: 'Wet',
    111: 'Steep',
    112: 'Steep/Low',
    113: 'Steep/Wet',
    114: 'Underwater',
    115: 'Other',
    116: 'Below Road/Slope',
    117: 'Above Road/Slope',
    118: 'Above Road/Steep',
    119: 'Below Road/Steep',
    120: 'Partially Developed',
    121: 'Needs Development',
    122: 'Rocky',
    123: 'Marsh',
    124: 'Mountain',
    125: 'Wooded',
    126: 'Brushy',
    A: 'Unknown',
    B: 'Unknown',
    C: 'Unknown',
    D: 'Unknown',
    F: 'Unknown',
  },
  view_code: {
    0: 'Unknown',
    A: 'Freeway Proximity',
    B: 'Waterfront-Beach (Ocean, River Or Lake)',
    C: 'Contamination Site',
    D: 'Cul-De-Sac',
    E: 'Corner',
    H: 'Historical',
    I: 'School Proximity',
    P: 'Proximity To Airport',
    R: 'Proximity To Railroad',
    S: 'Major Street/Thoroughfare',
    T: 'High Traffic Area',
    Z: 'Flood Plain/Flood Zone',
    AAA: 'Bluff',
    BBB: 'Territory',
    V01: 'Yes - Type Unknown',
    VAI: 'Airport',
    VAV: 'Average',
    VBA: 'Bay',
    VBE: 'Best',
    VBR: 'Better',
    VCA: 'Canal',
    VCI: 'City',
    VCL: 'Creek/Lake',
    VCN: 'Canyon',
    VCP: 'Conservation / Protected Area',
    VCV: 'Canyon/Valley',
    VEX: 'Excellent',
    VFA: 'Fair',
    VGC: 'Golf Course',
    VGO: 'Good',
    VGP: 'Greenbelt/Park',
    VGU: 'Gulf',
    VHM: 'Hills/Mountains',
    VHV: 'Hill/Valley',
    VIN: 'Inferior',
    VIT: 'Inland Waterway',
    VIW: 'Intercoastal Waterway',
    VLA: 'Lake',
    VLG: 'Lagoon',
    VLP: 'Lake/Pond',
    VMN: 'Mountain',
    VMO: 'Mountain/Ocean',
    VOB: 'Obstructed',
    VOC: 'Ocean',
    VPA: 'Park',
    VPD: 'Pond',
    VPG: 'Parking',
    VPI: 'Prime',
    VPL: 'Pool',
    VPM: 'Premium',
    VPR: 'Poor',
    VRC: 'Recreational',
    VRD: 'Road',
    VRR: 'River',
    VSD: 'Standard',
    VSN: 'Suburban',
    VSR: 'Superior',
    VST: 'Street',
    VTY: 'Typical',
    VWD: 'Woodland',
    VWR: 'Water',
  },
  water_source_code: {
    0: 'Unknown',
    101: 'None',
    102: 'Yes - Type Unknown',
    103: 'Bypass',
    104: 'Cistern',
    105: 'Commercial',
    106: 'Private',
    107: 'Public',
    108: 'Public Well',
    109: 'Spring/Creek',
    110: 'Well',
  },
  property_use_code_mapped: {
    10: 'Agriculture',
    11: 'Timberland',
    12: 'Hotel',
    13: 'Motels',
    14: 'Industrial',
    15: 'Manufacturing',
    16: 'Storage',
    17: 'Vacant',
    18: 'Vacant - Agricultural',
    19: 'Vacant - Commercial',
    20: 'Vacant - Industrial',
    21: 'Vacant - Residential',
    22: 'Apartments',
    23: 'Multi-Family',
    24: 'Medical Services',
    25: 'Mixed Use',
    26: 'Office',
    27: 'Commercial Condo',
    28: 'Educational',
    29: 'General Commercial',
    30: 'Government',
    31: 'Historical',
    32: 'Hospital',
    33: 'Misc',
    34: 'Parking',
    35: 'Services',
    36: 'Transportation',
    37: 'Unknown',
    38: 'Utilites',
    39: 'Cooperative',
    40: 'Misc Residential',
    41: 'Mobile Homes',
    42: 'Residential - Duplex/Triplex/Quadplex',
    43: 'Residential Condo',
    44: 'Single Family Residence',
    45: 'Attractions',
    46: 'Recreation',
    47: 'Retail',
  },
};

const lookup = (key: string, code: string | number | null): string | null => {
  if (code) return dictionary?.[key]?.[code] ?? 'Unknown';
  return null;
};

const CherreDictionaryUtil = {
  lookup,
};

export default CherreDictionaryUtil;
