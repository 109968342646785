import React from 'react';
import styled from '@emotion/styled';

type FlexProps = {
  height?: string;
  width?: string;
  direction?: string;
  justify?: string;
  align?: string;
  padding?: string;
  margin?: string;
  flex?: string;
  overflow?: string;
  id?: string;
  onClick?: Function | null;
  tip?: string;
  tipPlace?: string;
  gap?: string;
  dataCy?: string;
  children: React.ReactNode;
};

const Container = styled.div<FlexProps>`
  display: flex;
  position: relative;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  flex: ${(props) => props.flex};
  overflow: ${(props) => props.overflow};
  gap: ${(props) => props.gap};
  data-cy: ${(props) => props.dataCy};

  &:hover {
    cursor: ${(props) => (props.onClick ? 'pointer' : null)};
  }
`;

export const Spacer = styled.div`
  width: 16px;
`;

export const CustomSpacer = styled.div<{ height?: string; width?: string; }>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

const Flex: React.FC<FlexProps> = ({
  children,
  height = '',
  width = null,
  direction = 'row',
  justify = '',
  align = '',
  padding = '',
  margin = '',
  flex = '',
  overflow = '',
  id = '',
  onClick = null,
  tip,
  tipPlace,
  gap = null,
  dataCy,
}) => (
  <Container
    id={id}
    height={height}
    width={width}
    direction={direction}
    justify={justify}
    align={align}
    padding={padding}
    margin={margin}
    flex={flex}
    overflow={overflow}
    onClick={onClick ? (e) => onClick(e) : null}
    data-tip={tip}
    data-place={tipPlace}
    gap={gap}
    data-cy={dataCy}
  >
    {children}
  </Container>
);

export default Flex;
