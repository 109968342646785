import AnalyticsEventTypesEnum from '../enums/AnalyticsEventTypesEnum';

type BillingAnalyticsMetaData = {
  planId?: string;
}

export type WindowEventTrackerMetaData = {
    userId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    listingId?: string;
    organizationId?: string;
    listingUserId?: string;
    listingOrganizationId?: string;
    fileId?: string;
    billingData?: BillingAnalyticsMetaData;
};

function trackWindowEvent(
  event: AnalyticsEventTypesEnum,
  window: any,
  eventData?: WindowEventTrackerMetaData,
) {
  const baseEventDataToTrack = {
    userId: eventData?.userId ?? null,
    organizationId: eventData?.organizationId ?? null,
    listingId: eventData?.listingId ?? null,
    listingUserId: eventData?.listingUserId ?? null,
    listingOrganizationId: eventData?.listingOrganizationId ?? null,
  };
  switch (event) {
    case AnalyticsEventTypesEnum.ViewedListing:
      window.analytics.track(
        AnalyticsEventTypesEnum.ViewedListing,
        {
          ...baseEventDataToTrack,
        },
      );
      break;
    case AnalyticsEventTypesEnum.SignedCA:
      window.analytics.track(
        AnalyticsEventTypesEnum.SignedCA,
        {
          ...baseEventDataToTrack,
        },
      );
      break;
    case AnalyticsEventTypesEnum.ReceivedCA:
      window.analytics.track(
        AnalyticsEventTypesEnum.ReceivedCA,
        {
          ...baseEventDataToTrack,
        },
      );
      break;
    case AnalyticsEventTypesEnum.SentMessage:
      window.analytics.track(
        AnalyticsEventTypesEnum.SentMessage,
        {
          ...baseEventDataToTrack,
        },
      );
      break;
    case AnalyticsEventTypesEnum.ReceivedMessage:
      window.analytics.track(
        AnalyticsEventTypesEnum.ReceivedMessage,
        {
          ...baseEventDataToTrack,
        },
      );
      break;
    case AnalyticsEventTypesEnum.DownloadedDueDiligenceDocument:
      window.analytics.track(
        AnalyticsEventTypesEnum.DownloadedDueDiligenceDocument,
        {
          ...baseEventDataToTrack,
          fileId: eventData?.fileId,
        },
      );
      break;
    case AnalyticsEventTypesEnum.ViewedDueDiligenceDocument:
      window.analytics.track(
        AnalyticsEventTypesEnum.ViewedDueDiligenceDocument,
        {
          ...baseEventDataToTrack,
          fileId: eventData?.fileId,
        },
      );
      break;
    case AnalyticsEventTypesEnum.DownloadedDueDiligenceVault:
      window.analytics.track(
        AnalyticsEventTypesEnum.DownloadedDueDiligenceVault,
        {
          ...baseEventDataToTrack,
          fileId: eventData?.fileId,
        },
      );
      break;
    case AnalyticsEventTypesEnum.CompletedInvestorProfile:
      window.analytics.track(
        AnalyticsEventTypesEnum.CompletedInvestorProfile,
        {
          ...baseEventDataToTrack,
        },
      );
      break;
    case AnalyticsEventTypesEnum.ViewedDataExplorer:
      window.analytics.track(
        AnalyticsEventTypesEnum.ViewedDataExplorer,
        {
          ...baseEventDataToTrack,
        },
      );
      break;
    case AnalyticsEventTypesEnum.Became1031Investor:
      window.analytics.track(
        AnalyticsEventTypesEnum.Became1031Investor,
        {
          ...baseEventDataToTrack,
        },
      );
      break;
    case AnalyticsEventTypesEnum.ViewSubscriptionModal:
      window.analytics.track(
        AnalyticsEventTypesEnum.ViewSubscriptionModal,
        {
          userId: eventData?.userId ?? null,
          firstName: eventData?.firstName ?? null,
          lastName: eventData?.lastName ?? null,
          email: eventData?.email ?? null,
          billingData: eventData?.billingData ?? {},
        },
      );
      break;
    case AnalyticsEventTypesEnum.SubscribedToBrokerProMonthy:
      window.analytics.track(
        AnalyticsEventTypesEnum.SubscribedToBrokerProMonthy,
        {
          userId: eventData?.userId ?? null,
          firstName: eventData?.firstName ?? null,
          lastName: eventData?.lastName ?? null,
          email: eventData?.email ?? null,
          billingData: eventData?.billingData ?? {},
        },
      );
      break;
    case AnalyticsEventTypesEnum.SubscribedToBrokerProAnnual:
      window.analytics.track(
        AnalyticsEventTypesEnum.SubscribedToBrokerProAnnual,
        {
          userId: eventData?.userId ?? null,
          firstName: eventData?.firstName ?? null,
          lastName: eventData?.lastName ?? null,
          email: eventData?.email ?? null,
          billingData: eventData?.billingData ?? {},
        },
      );
      break;

    default:
  }
}

const EventTrackerUtil = {
  trackWindowEvent,
};

export default EventTrackerUtil;
