import { logger } from '@biproxi/logger';
import { onError } from 'apollo-link-error';
import Auth from '../../utils/Auth';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      logger.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      if (
        message === 'Authentication Required.'
        || message === 'Authorization Required.'
      ) {
        logger.error('Must login');
        Auth.logout();
      }
    });
  }
  if (networkError) logger.error(`[Network error]: ${networkError}`);
});

export default errorLink;
