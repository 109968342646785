import gql from 'graphql-tag';
import ListingFragments from '../fragments/listing.fragments';

const LIST_LISTINGS = gql`
  query Listings($params: ListListingsParams) {
    listings(params: $params) {
      data {
        ...ListingFields
        ...ListingAddress
        ...ListingPortfolioAddresses
        ...ListingSchedule
        ...ListingMedia
        ...ListingGuidance
        ...ListingVault
        ...ListingLicense
        ...ListingMetrics
        ...ListingMeta
        ...ListingUser
        ...ListingContacts
        ...ListingDealProfile
      }
      info {
        totalCount
      }
    }
  }

  ${ListingFragments.fields}
  ${ListingFragments.address}
  ${ListingFragments.portfolioAddresses}
  ${ListingFragments.schedule}
  ${ListingFragments.media}
  ${ListingFragments.guidance}
  ${ListingFragments.vault}
  ${ListingFragments.license}
  ${ListingFragments.metrics}
  ${ListingFragments.meta}
  ${ListingFragments.user}
  ${ListingFragments.contacts}
  ${ListingFragments.dealProfile}
`;

export default LIST_LISTINGS;
