import React from 'react';
import { useMutation } from '@apollo/client';
import * as UserJoi from '@biproxi/models/joi/user.joi';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Input from '../../elements/Input';
import SEND_PASSWORD_RESET_EMAIL from '../../graphql/mutations/sendPasswordReset.mutation';
import {
  AuthenticationModal,
  Content,
} from '../AuthenticationModalLayout';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import Flex, { CustomSpacer } from '../../elements/Flex';
import Icon, { Icons } from '../../elements/Icon';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import BackArrow from '../../elements/BackArrow';
import { ModalTypesEnum } from './Modal';

export type ForgotPasswordEmailModalProps = {
  isStandalone?: boolean;
};

const ForgotPasswordEmailModal: React.FC<ForgotPasswordEmailModalProps> = ({
  isStandalone = false,
}) => {
  /** Hooks */
  const [emailSent, setEmailSent] = React.useState(false);
  const formParams: UseFormParams = {
    fields: {
      email: '',
    },
    fieldOrder: ['/email'],
    schema: UserJoi.sendPasswordResetParamsSchema,
  };

  const {
    controllers: {
      email,
    },
    isValid,
    setFieldErrors,
  } = useForm(formParams);

  /** Actions */
  const dispatch = useAppDispatch();

  const replaceModalWithLogin = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.Login,
      props: {
        isStandalone,
      },
    }),
  );

  const popModal = () => dispatch(
    AppActions.popModal(),
  );
  /** GraphQL */
  const [sendPasswordResetEmail, { loading }] = useMutation(SEND_PASSWORD_RESET_EMAIL, {
    onCompleted: async () => {
      setEmailSent(true);
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        email.setError(message);
      }
    },
  });

  /** Actions */
  const handleSubmit = (event?: React.FormEvent) => {
    if (event) event.preventDefault();
    if (!isValid()) return;
    sendPasswordResetEmail({
      variables: {
        params: {
          email: email.value().toString(),
        },
      },
    });
  };

  /** Render */
  return (
    <AuthenticationModal fullscreen={isStandalone} showExit={!isStandalone}>
      {emailSent ? (
        <Content onSubmit={(event: React.FormEvent) => event.preventDefault()}>
          <Flex flex="1" align="center" justify="center" direction="column" height="100%">
            <Icon icon={Icons.EnvelopeSolid} size={48} color={Colors.Brand700 || Colors.Blurple700} />
            <Text type={TextTypesEnum.Bold24} color={Colors.Black} margin="12px 0 0">We just emailed you</Text>
            <Text type={TextTypesEnum.Regular16} color={Colors.Grey700} margin="4px 0 24px" align="center">
              A password reset link has been sent to
              <br />
              {' '}
              {email.value() || 'your email'}
            </Text>
          </Flex>
          <Button
            text={isStandalone ? 'Sign in' : 'Close'}
            onClick={() => (isStandalone ? replaceModalWithLogin() : popModal())}
            isLoading={loading}
            type={ButtonTypesEnum.Outline}
            size={ButtonSizesEnum.Large}
            isFullWidth
          />
        </Content>
      ) : (
        <Content onSubmit={(event) => handleSubmit(event)}>
          <Flex align="center">
            <BackArrow onClick={() => replaceModalWithLogin()} />
            <Text type={TextTypesEnum.Bold24} margin="0 0 0 12px">
              Forgot your password?
            </Text>
          </Flex>
          <Text type={TextTypesEnum.Regular16} color={Colors.Grey700} margin="4px 0 0">
            Let&apos;s get you back online.
          </Text>
          <CustomSpacer height="24px" />
          <Input
            autoFocus
            label="Email*"
            placeholder="Email address"
            value={email.value().toString()}
            onChange={email.setValue}
            ref={email.setRef}
            error={email.error()}
            margin="0 0 24px"
          />
          <Button
            text="Continue"
            isLoading={loading}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Large}
            isFullWidth
          />
        </Content>
      )}
    </AuthenticationModal>
  );
};

export default ForgotPasswordEmailModal;
