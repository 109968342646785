import React from 'react';
import styled from '@emotion/styled';
import IFile from '@biproxi/models/interfaces/IFile';
import { pdfjs, Document, Page } from 'react-pdf';
import ReactPlayer from 'react-player';
import AnalyticsEventTypesEnum from '@biproxi/models/enums/AnalyticsEventTypesEnum';
import FileUtil from '@biproxi/models/utils/FileUtil';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { useSwipeable } from 'react-swipeable';
import EventTrackerUtil from '@biproxi/models/utils/EventTrackerUtil';
import Colors from '../styles/Colors';
import { FadeIn } from '../elements/Motion';
import { AppState, useAppDispatch, useAppSelector } from '../redux/store';
import Text, { TextTypesEnum } from '../elements/Text';
import Icon, { Icons } from '../elements/Icon';
import { AppActions } from '../redux/app.redux';
import usePrintFile from '../hooks/usePrintFile.hook';
import { media, useMobileMedia, useTabletMedia } from '../utils/MediaQuery';
import useLockBodyScroll from '../hooks/useLockBodyScroll.hook';
import useUser from '../hooks/useUser.hook';
import { ListingSelectors } from '../redux/listing.redux';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import ZIndexes from '../styles/ZIndexes';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/** ******************************************************************************
 *  File Preview
 ****************************************************************************** */

const Container = styled.div``;

type ContentProps = {}

const Content = styled(FadeIn)<ContentProps>`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: ${Colors.Black75};
  z-index: ${ZIndexes.FilePreview};
  overflow-y: auto;
`;

const Controls = styled.div`
  position: fixed;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${ZIndexes.FilePreview + 1};
`;

const Arrow = styled.div<{right?: boolean}>`
  position: fixed;
  height: 56px;
  width: 56px;
  background-color: ${Colors.Black33};
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc((100% - 56px)/2);
  z-index: ${ZIndexes.FilePreview + 1};
  border-radius: ${(props) => (props.right ? '8px 0px 0px 8px' : '0px 8px 8px 0px')};
  right: ${(props) => (props.right ? '0px' : null)};
  transition: all 0.1s;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.Black66};
  }

  &:active {
    background-color: ${Colors.Black75};
  }
`;

type FilePreviewProps = {};

const FilePreview: React.FC<FilePreviewProps> = () => {
  /* State */
  const { user } = useUser();
  const appState: AppState = useAppSelector((state: AppState) => state.app);
  const listing = useAppSelector(ListingSelectors.selectedListing);
  const swipeable = useSwipeable({
    onSwipedRight: () => moveFilePreview(1),
    onSwipedLeft: () => moveFilePreview(-1),
  });
  const {
    filePreview: {
      fileId,
      files,
      track,
    },
  } = appState;
  const currentFile: IFile = files.find((file) => file._id === fileId);
  const open = Boolean(files.length > 0 || fileId);

  /** Actions */
  const dispatch = useAppDispatch();

  const closeFilePreview = () => dispatch(
    AppActions.closeFilePreview(),
  );

  const moveFilePreview = (direction: 1 | -1) => dispatch(
    AppActions.moveFilePreview({ direction }),
  );

  const downloadFile = (file: IFile): void => {
    window.open(file.url);

    /** Track Document Download */
    if (track && !ListingUtil.isOwner(listing, user._id)) {
      EventTrackerUtil.trackWindowEvent(
        AnalyticsEventTypesEnum.DownloadedDueDiligenceDocument,
        window,
        {
          userId: user?._id ?? null,
          organizationId: user?.organization?._id ?? null,
          listingId: listing?._id,
          listingUserId: listing?.meta.createdBy,
          listingOrganizationId: listing?.organizationId,
          fileId: file._id,
        },
      );
    }
  };

  /** Hooks */
  const printFile = usePrintFile();

  /** Track Viewed Individual Document Event */
  React.useEffect(() => {
    if (track && !ListingUtil.isOwner(listing, user._id)) {
      EventTrackerUtil.trackWindowEvent(
        AnalyticsEventTypesEnum.ViewedDueDiligenceDocument,
        window,
        {
          userId: user?._id ?? null,
          organizationId: user?.organization?._id ?? null,
          listingId: listing?._id,
          listingUserId: listing?.meta.createdBy,
          listingOrganizationId: listing?.organizationId,
          fileId,
        },
      );
    }
  }, [user, fileId]);

  /** Effects */
  useLockBodyScroll(open);

  /** Render */
  if (!open) return null;

  const isTop = true;

  return (
    <Container {...swipeable}>
      {files.length > 1 && (
        <Arrow onClick={() => moveFilePreview(-1)}>
          <Icon
            icon={Icons.ArrowLeftRegular}
            color={Colors.Grey400}
            size={24}
            height="40px"
            width="40px"
          />
        </Arrow>
      )}
      {files.length > 1 && (
        <Arrow onClick={() => moveFilePreview(1)} right>
          <Icon
            icon={Icons.ArrowRightRegular}
            color={Colors.Grey400}
            size={24}
            height="40px"
            width="40px"
          />
        </Arrow>
      )}
      <Controls>
        <Icon
          icon={Icons.ArrowToBottomRegular}
          color={Colors.Grey400}
          size={24}
          onClick={() => downloadFile(currentFile)}
          margin="0 8px 0 0"
          height="40px"
          width="40px"
          hoverContainerColor={Colors.Black33}
          activeContainerColor={Colors.Black75}
        />
        {FileUtil.isPDFMimeType(currentFile.mimetype) && (
          <Icon
            icon={Icons.PrintRegular}
            color={Colors.Grey400}
            size={24}
            onClick={() => printFile(currentFile)}
            margin="0 8px 0 0"
            height="40px"
            width="40px"
            hoverContainerColor={Colors.Black33}
            activeContainerColor={Colors.Black75}
          />
        )}
        <Icon
          icon={Icons.TimesRegular}
          color={Colors.Grey400}
          size={24}
          onClick={() => closeFilePreview()}
          height="40px"
          width="40px"
          hoverContainerColor={Colors.Black33}
          activeContainerColor={Colors.Black75}
        />
      </Controls>
      <Content duration={0.10}>
        {(() => files.map((file: IFile) => {
          if (FileUtil.isVideoMimeType(file.mimetype)) {
            return (
              <RenderVideo
                key={file._id}
                url={file.url}
              />
            );
          }

          if (FileUtil.isImageMimeType(file.mimetype)) {
            return (
              <RenderImage
                key={file._id}
                file={file}
                active={fileId === file._id}
              />
            );
          }

          if (FileUtil.isPDFMimeType(file.mimetype)) {
            return (
              <RenderFile
                key={file._id}
                file={file}
                active={fileId === file._id}
                isTop={isTop}
              />
            );
          }

          return (
            <RenderUnsupported
              key={file._id}
              file={file}
              active={fileId === file._id}
              isTop={isTop}
              downloadFile={downloadFile}
            />
          );
        }))()}
      </Content>
    </Container>
  );
};

export default FilePreview;

/** ******************************************************************************
 *  Render File Preview
 ****************************************************************************** */

 type RenderFileContainerProps = {
   active: boolean;
   isTop: boolean;
 };

const RenderFileContainer = styled.div<RenderFileContainerProps>`
  position: ${(props) => (props.active ? null : 'absolute')};
  top: ${(props) => (props.active ? null : '-10000000px')};
  left: ${(props) => (props.active ? null : '-10000000px')};
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
 `;

const Inner = styled.div`
  margin-top: 64px;
 `;

const DocumentContainer = styled.div`
  padding: 16px;
  background-color: white;

  ${media.mobile} {
    padding: 0px;
  }

  ${media.tablet} {
  padding: 0px;
  }
`;

const Spacer = styled.div`
  height: 16px;
 `;

const BottomSpacer = styled.div`
 height: 120px;
`;

type RenderFileProps = {
  file: IFile;
  active: boolean;
  isTop: boolean;
};

const RenderFile: React.FC<RenderFileProps> = ({
  file,
  active,
  isTop,
}) => {
  /** State */
  const [pages, setPages] = React.useState(0);

  /** Hooks */
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  /** Render */
  return (
    <RenderFileContainer active={active} isTop={isTop}>
      <Inner>
        <Text
          type={TextTypesEnum.Regular12}
          color={Colors.White}
          margin="0 0 8px"
        >
          {file.name}
        </Text>
        <Document
          file={file.url}
          onLoadSuccess={({ numPages }) => setPages(numPages)}
        >
          {new Array(pages).fill(null).map((_, index) => (
            <div key={index}>
              <DocumentContainer>
                <Page pageNumber={index + 1} width={isMobile || isTablet ? window.screen.width : null} />
              </DocumentContainer>
              <Spacer />
            </div>
          ))}
        </Document>
        <BottomSpacer />
      </Inner>
    </RenderFileContainer>
  );
};

/** ******************************************************************************
 *  Render Video Preview
 ****************************************************************************** */

const MediaContainer = styled.div<{ active: boolean }>`
  position: ${(props) => (props.active ? null : 'absolute')};
  top: ${(props) => (props.active ? null : '-10000000px')};
  left: ${(props) => (props.active ? null : '-10000000px')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const InnerVideoContainer = styled.div`
  position: relative;
  width: 1000px;
  height: 562.5px;
  background-color: ${Colors.Grey700};
  border-radius: 8px;
  overflow: hidden;

  ${media.mobile} {
    width: 100%;
    height: auto;
  }

  ${media.tablet} {
  width: 100%;
  height: auto;
  }
`;

type RenderVideoProps = {
  url: string;
}

const RenderVideo: React.FC<RenderVideoProps> = ({
  url,
}) => {
  /** Hooks */
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  /** Render */
  return (
    <MediaContainer active>
      <InnerVideoContainer>
        <ReactPlayer
          url={url}
          width="100%"
          height={!isMobile || !isTablet ? '100%' : undefined}
          controls
        />
      </InnerVideoContainer>
    </MediaContainer>
  );
};

/** ******************************************************************************
 *  Render Image Preview
 ****************************************************************************** */

const ImageDiv = styled.div<{ src: string }>`
  background-size: cover;
  background-position: center;
  background-origin: unset;
  background-image: url(${({ src }) => src});
  width: 800px;
  height: 450px;
  border-radius: 8px;

  ${media.mobile} {
    width: 100%;
    border-radius: 0;
    height: 360px;
  }

  ${media.tablet} {
  width: 100%;
  border-radius: 0;
  height: 360px;
  }
`;

type RenderImageProps = {
  file: IFile;
  active: boolean;
}

const RenderImage: React.FC<RenderImageProps> = ({
  file,
  active,
}) => (
  <MediaContainer active={active}>
    <ImageDiv src={file?.url} />
  </MediaContainer>
);

/** ******************************************************************************
 *  Render Unsupported
 ****************************************************************************** */

 type RenderUnsupportedContainerProps = {
   active: boolean;
   isTop: boolean;
 };

const RenderUnsupportedContainer = styled.div<RenderUnsupportedContainerProps>`
  position: ${(props) => (props.active ? null : 'absolute')};
  top: ${(props) => (props.active ? null : '-10000000px')};
  left: ${(props) => (props.active ? null : '-10000000px')};
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
 `;

const UnsupportedPage = styled.div`
  background-color: ${Colors.White};
  height: 724px;
  width: 644px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${media.mobile} {
    height: 100%;
    min-height: 300px;
    width: calc(100% - 48px);
    padding: 24px;
  }

  ${media.tablet} {
    height: 100%;
    min-height: 300px;
    width: calc(100% - 48px);
    padding: 24px;
  }
 `;

type RenderUnsupportedProps = {
  file: IFile;
  active: boolean;
  isTop: boolean;
  downloadFile: Function;
};

const RenderUnsupported: React.FC<RenderUnsupportedProps> = ({
  file,
  active,
  isTop,
  downloadFile,
}) => (
  <RenderUnsupportedContainer active={active} isTop={isTop}>
    <Inner>
      <Text
        type={TextTypesEnum.Regular12}
        color={Colors.White}
        margin="0 0 8px"
      >
        {file.name}
      </Text>
      <UnsupportedPage>
        <Icon
          icon={Icons.FilePdfRegular}
          color={Colors.Grey400}
          size={36}
          margin="0 0 16px"
        />
        <Text type={TextTypesEnum.Regular16} color={Colors.Black} align="center">
          File preview is not unsupported for this file type.
        </Text>
        <Button
          text="Download File"
          onClick={() => downloadFile(file)}
          type={ButtonTypesEnum.Primary}
          size={ButtonSizesEnum.Large}
          margin="16px 0 0"
        />
      </UnsupportedPage>
      <BottomSpacer />
    </Inner>
  </RenderUnsupportedContainer>
);
