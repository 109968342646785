import { isNode, warn } from '@biproxi/env';
import chalk from 'chalk';
import { format } from 'date-fns';
import { setupAxiosInterceptor } from './logger.axios.interceptor';
import { Label, Level } from './logger.enums';
import { loggerConfig } from './logger.config';
import { logtail } from './logtail.client';
import packageDetails from '../../../../package.json';

enum Color {
  fatal = 'redBright',
  error = 'red',
  warn = 'yellow',
  info = 'blue',
  debug = 'magenta',
  trace = 'cyan'
}

enum SpaColor {
  fatal = 'color: red',
  error = 'color: black',
  warn = 'color: black',
  info = 'color: blue',
  debug = 'color: green',
  trace = 'color: deepskyblue'
}

const outputJson = (o:any) => {
  const label = (o.label === Label.fatal) ? Label.error : (o.label === Label.trace) ? Label.debug : o.label;
  // @ts-ignore
  console[label](chalk.grey(JSON.stringify(o)));
};

const outputLogtail = (o:any) => {
  o.level = o.label.toUpperCase();
  logtail([o]);
};

const outputPretty = (o:any) => {
  const label = (o.label === Label.fatal) ? Label.error : (o.label === Label.trace) ? Label.debug : o.label;

  if (isNode) {
    // @ts-ignore
    console[label](`${format(o.dt, 'yyyy-dd-MM HH:mm:ss.SSS')} ${o.name} ${chalk[Color[o.label]](`[${o.label.toUpperCase()}]`)} ${o.message}`);
  } else {
    // @ts-ignore
    console[label](`%c${format(o.dt, 'yyyy-dd-MM HH:mm:ss.SSS')} ${o.name} ${`[${o.label.toUpperCase()}]`} ${o.message}`, SpaColor[o.label]);
  }
};

export class Logger {
  public base: Record<string, any>;

  constructor(base: Record<string, any>) {
    this.base = base;
  }

  child(base: Record<string, any>) {
    return new Logger(base);
  }

  private write(o:any) {
    if (o.level >= loggerConfig.loggingLevel) {
      o.dt = new Date();
      if (!loggerConfig.disableJSONTransport) outputJson(o);
      if (!loggerConfig.disableLogtailTransport) outputLogtail(o);
      if (!loggerConfig.disablePrettyTransport) outputPretty(o);
    }
  }

  fatal(message:string, obj?:any) {
    this.write({
      label: Label.fatal, level: Level.fatal, name: this.base.name, message, obj,
    });
  }

  error(message:string, obj?:any) {
    this.write({
      label: Label.error, level: Level.error, name: this.base.name, message, obj,
    });
  }

  warn(message:string, obj?:any) {
    this.write({
      label: Label.warn, level: Level.warn, name: this.base.name, message, obj,
    });
  }

  info(message:string, obj?:any) {
    this.write({
      label: Label.info, level: Level.info, name: this.base.name, message, obj,
    });
  }

  debug(message:string, obj?:any) {
    this.write({
      label: Label.debug, level: Level.debug, name: this.base.name, message, obj,
    });
  }

  trace(message:string, obj?:any) {
    this.write({
      label: Label.trace, level: Level.trace, name: this.base.name, message, obj,
    });
  }
}

export const logger = new Logger({ name: isNode ? (process.env.NX_TASK_TARGET_PROJECT ?? 'base') : 'spa' });

setupAxiosInterceptor(logger);

logger.info(`🅱️ℹ️🅿️®️🐂🌵  version: ${packageDetails.version} | env: ${process.env.NEXT_PUBLIC_ENVIRONMENT_NAME} | logging: ${loggerConfig.loggingLevelLabel} | release: ${process.env.SHORT_SHA}`);
// logger.info(`node: ${process.version} - platform: ${process.platform}`);
warn(((m) => { logger.error(m); }));

// for manual testing
// logger.fatal('Logger Test Fatal');
// logger.error('Logger Test Error');
// logger.warn('Logger Test Warn');
// logger.info('Logger Test Info');
// logger.debug('Logger Test Debug');
// logger.trace('Logger Test Trace');
