import React from 'react';
import styled from '@emotion/styled';
import IAnalyticsEvent from '@biproxi/models/interfaces/IAnalyticsEvent';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import AnalyticsEventUtil from '@biproxi/models/utils/AnalyticsEventUtil';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import LeadActivityTimelineAnalyticsEvent from './LeadActivityTimelineAnalyticsEvent';

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Day = styled.div`
  width: 100%;
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 16px;
  }
`;

export type LeadActivityTimelineProps = {
  events: IAnalyticsEvent[];
};

const LeadActivityTimelinerview: React.FC<LeadActivityTimelineProps> = ({
  events,
}) => {
  /* State */
  const byDay = AnalyticsEventUtil.groupByDay(events);

  /* Render */
  return (
    <Container>
      {Object.keys(byDay).reverse().map((key, index) => {
        const millis = key;
        const events = byDay[millis];

        const date = TimeUtil.format(
          millis,
          'DDD',
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        );

        return (
          <Day key={index}>
            <Text type={TextTypesEnum.Regular14} color={Colors.Grey700} margin="0 0 8px">
              {date}
            </Text>
            {events.map((event, index) => (
              <LeadActivityTimelineAnalyticsEvent
                key={index}
                event={event}
                isLast={index === events.length - 1}
              />
            ))}
          </Day>
        );
      })}
    </Container>
  );
};

export default LeadActivityTimelinerview;
