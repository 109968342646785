import gql from 'graphql-tag';

const CREATE_LISTING = gql`
  mutation CreateListing($input: [ListingCreateInput!]!) {
    createListings(input: $input) {
      info {
        nodesCreated
      }
      listings {
        _id
        name
      }
    }
  }
`;

export default CREATE_LISTING;
