enum ListingPropertyTypeEnum {
  AbsoluteNet = 'Absolute Net',
  AssistedLiving = 'Assisted Living',
  BioTech = 'Bio Tech',
  BowlingAlley = 'Bowling Alley',
  Brownfield = 'Brownfield',
  BuilttoSuit = 'Built to Suit',
  Casino = 'Casino',
  CellTowers = 'Cell Towers',
  Childcare = 'Childcare',
  Church = 'Church / Religious',
  DataCenter = 'Data Center',
  Dental = 'Dental',
  Development = 'Development',
  Dialysis = 'Dialysis',
  DistributionWarehouse = 'Distribution Warehouse',
  DoubleNet = 'Double Net (NN)',
  ExtendedStay = 'Extended Stay',
  Federal = 'Federal',
  Flex = 'Flex',
  FullService = 'Full Service',
  Garden = 'Garden',
  GasStation = 'Gas Station/C-Store',
  GeneralPurpose = 'General Purpose/Storage Warehouse',
  GovernmentContractor = 'Government Contractor',
  Greenfield = 'Greenfield / Agricultural',
  Gross = 'Gross',
  GSA = 'GSA',
  HeavyManufacturing = 'Heavy Manufacturing',
  HighRise = 'High Rise',
  Hospital = 'Hospital',
  IndependentLiving = 'Independent Living',
  Infill = 'Infill',
  LightAssembly = 'Light Assembly',
  LimitedService = 'Limited Service',
  Mall = 'Mall',
  Marina = 'Marina',
  MedicalCampus = 'Medical Campus',
  MedicalOffice = 'Medical Office',
  MidRise = 'Mid Rise',
  MixedUseMultiFamily = 'Mixed Use',
  MixedUseOffice = 'Mixed Use',
  MixedUseRetail = 'Mixed Use',
  MobileHome = 'Mobile Home/RV Park',
  ModifiedGross = 'Modified Gross',
  MultiTenant = 'Multi Tenant',
  NeighborhoodCenter = 'Neighborhood Center',
  NonPerforming = 'Non Performing',
  OutParcel = 'Out Parcel',
  ParkingLot = 'Parking Lot',
  PowerCenter = 'Power Center',
  Redevelopment = 'Redevelopment',
  Resort = 'Resort',
  SelectService = 'Select Service',
  SelfStorage = 'Self Storage',
  SeniorHousing = 'Senior Housing',
  SingleNet = 'Single Net',
  SingleTenantOffice = 'Single Tenant',
  SingleTenantRetail = 'Single Tenant',
  SportsandLeisure = 'Sports and Leisure',
  State = 'State',
  StripCenter = 'Strip Center',
  StudentHousing = 'Student Housing',
  SubPerforming = 'Sub Performing',
  Suburban = 'Suburban',
  Theatre = 'Theatre',
  TripleNet = 'Triple Net (NNN)',
  TruckTerminal = 'Truck Terminal',
  Urban = 'Urban',
  UrgentCare = 'Urgent Care',
  USGovernment = 'US Government',
  Veterinarian = 'Veterinarian',
  WalkUp = 'Walk-Up',
  Residential = 'Residential',
  Other = 'Other',
}

export default ListingPropertyTypeEnum;
