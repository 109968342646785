enum ListingAssetClassEnum {
  Retail = 'Retail',
  Industrial = 'Industrial',
  MultiFamily = 'Multi-family',
  Office = 'Office',
  Hospitality = 'Hospitality',
  NetLease = 'Net Lease',
  SelfStorage = 'Self Storage',
  SeniorLiving = 'Senior Living',
  Healthcare = 'Healthcare',
  GovernmentOffice = 'Government Office',
  SpecialPurpose = 'Special Purpose',
  Notes = 'Notes',
  Land = 'Land',
  SFRPortfolio = 'SFR Portfolio',
}

export default ListingAssetClassEnum;
