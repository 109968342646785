import gql from 'graphql-tag';

const UPDATE_OFFER_STATE = gql`
  mutation UpdateOfferState($params: UpdateOfferStateParams!) {
    updateOfferState(params: $params) {
      _id
    }
  }
`;

export default UPDATE_OFFER_STATE;
