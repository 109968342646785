import { ApolloLink, Observable, Operation } from '@apollo/client';
import { GetServerSidePropsContext } from 'next';
import Auth from '../../utils/Auth';

interface IHeaders {
  authorization?: string;
}

const request = (operation: Operation, context?: GetServerSidePropsContext): Promise<void> => new Promise((resolve) => {
  const headers: IHeaders = {};
  const token = Auth.getToken(context?.req?.cookies ?? undefined);
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }
  operation.setContext({ headers });
  resolve();
});

const makeRequestLink = (context?: GetServerSidePropsContext) => new ApolloLink(
  (operation, forward) => new Observable((observer) => {
    let handle: any;
    Promise.resolve(operation)
      .then((oper) => request(oper, context))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  }),
);

export default makeRequestLink;
