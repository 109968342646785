import React from 'react';
import ListingAssetClassEnum from '@biproxi/models/enums/ListingAssetClassEnum';
import ListingPropertyTypeEnum from '@biproxi/models/enums/ListingPropertyTypeEnum';
import AssetClassPropertyTypes from '@biproxi/models/objects/AssetClassPropertyTypes.object';
import Flex from '../elements/Flex';
import { CheckboxTypesEnum } from '../elements/Checkbox';
import Text, { TextTypesEnum } from '../elements/Text';
import Colors from '../styles/Colors';
import ExpansiveCheckboxList, { IExpansiveCheckboxConfig } from '../elements/ExpansiveCheckboxList';

type AssetClassAndPropertyTypeFilterProps = {
    externalAssetClasses?: ListingAssetClassEnum[];
    externalPropertyTypes?: ListingPropertyTypeEnum[];
    setExternalAssetClassState?: React.Dispatch<React.SetStateAction<ListingAssetClassEnum[]>>;
    setExternalPropertyTypeState?: React.Dispatch<React.SetStateAction<ListingPropertyTypeEnum[]>>;
    column?: boolean; // if true, the filters will be displayed in a column instead of row
}

/**
 * Basically a bunch of checkboxes that allow the user to filter listings/properties by asset class and property type
 *
 * The way that the state works for this component is a LITTLE strange. This component can be used independently or within another component
 * If you integrate this with another component, pass the first four props from the other component, otherwise, this component will run off its own state
 */
const AssetClassAndPropertyTypeFilter: React.FC<AssetClassAndPropertyTypeFilterProps> = ({
  externalAssetClasses,
  externalPropertyTypes,
  setExternalAssetClassState,
  setExternalPropertyTypeState,
  column,
}) => {
  /** Helper Functions */
  /**
   * There are quite a few property types that are shared amongst different asset classes. When one of those property types is clicked, all instances of that property type gets checked
   * To remedy this issue, we prepend the unique asset class to each property type to be stored in the 'uniqueSelectedSubtype' state.
   * This state only persists within this component and is frontend only since we query for both the asset class and property type on the backend.
   * The value(s) of either 'externalPropertyTypes' or 'selectedPropertyTypes' is what will be passed to the backend for querying.
   * The value(s) of 'uniqueSelectedSubtype' is what is used on the frontend to determine which property types are checked.
   */
  const prependAssetClassToPropertyType = (assetClass: ListingAssetClassEnum, propertyType: ListingPropertyTypeEnum): string => `${assetClass}-${propertyType}`;

  /** State */
  const [activeAssetClass, setActiveAssetClass] = React.useState<ListingAssetClassEnum>(ListingAssetClassEnum.Retail);
  const [selectedAssetClasses, setSelectedAssetClasses] = React.useState<ListingAssetClassEnum[]>(externalAssetClasses ?? []);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = React.useState<ListingPropertyTypeEnum[]>(externalPropertyTypes ?? []);
  const [uniqueSelectedPropertyTypes, setUniqueSelectedPropertyTypes] = React.useState<string[]>([]);

  /** Configuration */
  const assetClassConfig: IExpansiveCheckboxConfig[] = Object.values(ListingAssetClassEnum).map((assetClass: ListingAssetClassEnum) => {
    const active = assetClass === activeAssetClass;
    const selected = selectedAssetClasses.includes(assetClass);
    return {
      label: assetClass,
      active,
      selected,
      type: CheckboxTypesEnum.Small,
      onClick: () => {
        setActiveAssetClass(assetClass);
      },
      onCheckboxClick: () => {
        if (selectedAssetClasses.includes(assetClass)) {
          setSelectedAssetClasses(selectedAssetClasses.filter((item) => item !== assetClass));
          setExternalAssetClassState?.(selectedAssetClasses.filter((item) => item !== assetClass));
          // remove all property types for this asset class
          const newUniqueSelectedPropertyTypes: any[] = uniqueSelectedPropertyTypes.filter((item) => !item.includes(assetClass));
          setUniqueSelectedPropertyTypes(newUniqueSelectedPropertyTypes);
          /** The map below basically removes all substring instances before the first occurance of '-'
           * It is used to remove the asset class from the property type
           */
          setExternalPropertyTypeState?.(newUniqueSelectedPropertyTypes.map((item) => {
            const splitItem = item.split('-');
            splitItem.shift();
            return splitItem.join('-');
          }));
        } else {
          setSelectedAssetClasses([...selectedAssetClasses, assetClass]);
          setExternalAssetClassState?.([...selectedAssetClasses, assetClass]);
          // set all property types for this asset class to be selected
          const uniquePropertyTypesToBeSelected: string[] = Object.values(AssetClassPropertyTypes[assetClass]).map((propertyType) => prependAssetClassToPropertyType(assetClass, propertyType));
          const propertyTypesToBeSelected: ListingPropertyTypeEnum[] = Object.values(AssetClassPropertyTypes[assetClass]);
          setUniqueSelectedPropertyTypes([...uniqueSelectedPropertyTypes, ...uniquePropertyTypesToBeSelected]);
          setExternalPropertyTypeState?.([...selectedPropertyTypes, ...propertyTypesToBeSelected]);
        }
      },
    };
  });

  const subtypeConfig: IExpansiveCheckboxConfig[] = Object.values(AssetClassPropertyTypes[activeAssetClass]).map((propertyType: ListingPropertyTypeEnum) => {
    const prependedAssetClassToPropertyType = prependAssetClassToPropertyType(activeAssetClass, propertyType);
    const selected = uniqueSelectedPropertyTypes.includes(prependedAssetClassToPropertyType);
    return {
      label: propertyType,
      selected,
      type: CheckboxTypesEnum.Small,
      onCheckboxClick: () => {
        if (uniqueSelectedPropertyTypes.includes(prependedAssetClassToPropertyType)) {
          setSelectedPropertyTypes(selectedPropertyTypes.filter((item) => item !== propertyType));
          setExternalPropertyTypeState?.(selectedPropertyTypes.filter((item) => item !== propertyType));
          setUniqueSelectedPropertyTypes(uniqueSelectedPropertyTypes.filter((item) => item !== prependedAssetClassToPropertyType));
        } else {
          setSelectedPropertyTypes([...selectedPropertyTypes, propertyType]);
          setExternalPropertyTypeState?.([...selectedPropertyTypes, propertyType]);
          setUniqueSelectedPropertyTypes([...uniqueSelectedPropertyTypes, prependedAssetClassToPropertyType]);
        }
        if (!selectedAssetClasses.includes(activeAssetClass)) {
          setSelectedAssetClasses([...selectedAssetClasses, activeAssetClass]);
          setExternalAssetClassState?.([...selectedAssetClasses, activeAssetClass]);
        }
      },
    };
  });

  React.useEffect(() => {
    setSelectedAssetClasses(externalAssetClasses ?? []);
    setSelectedPropertyTypes(externalPropertyTypes ?? []);
    if (externalPropertyTypes.length === 0) {
      setUniqueSelectedPropertyTypes([]);
    }
  }, [externalAssetClasses, externalPropertyTypes]);

  /**
   * Everything below is very ugly and I am far from proud of it. Please, never question it. It works.
   * This is all used to map previously selected property classes to their respective asset classes if a user closes the modal and reopens it.
   */
  React.useEffect(() => {
    if (externalAssetClasses.length > 0 && externalPropertyTypes.length > 0) {
      let tempPropertyTypes: ListingPropertyTypeEnum[] = [...externalPropertyTypes];
      let tempUniquePropertyTypes = [];
      let indicesToRemove: number[] = [];
      externalAssetClasses.forEach((assetClass: ListingAssetClassEnum) => {
        indicesToRemove = [];
        tempPropertyTypes.forEach((propertyType: ListingPropertyTypeEnum, index: number) => {
          if (Object.values(AssetClassPropertyTypes[assetClass]).includes(propertyType) && !tempUniquePropertyTypes.includes(prependAssetClassToPropertyType(assetClass, propertyType))) {
            tempUniquePropertyTypes = [...tempUniquePropertyTypes, prependAssetClassToPropertyType(assetClass, propertyType)];
            indicesToRemove.push(index);
          }
        });
        tempPropertyTypes = tempPropertyTypes.filter((item, index) => !indicesToRemove.includes(index));
      });
      setUniqueSelectedPropertyTypes(tempUniquePropertyTypes);
    }
  }, []);

  return (
    <>
      {column ? (
        <Flex direction="column">
          <ExpansiveCheckboxList
            config={assetClassConfig}
            listItemMargin="0px"
            displayAsColumn
          >
            <ExpansiveCheckboxList config={subtypeConfig} expansive={false} listItemMargin="0 0 0 16px" />
          </ExpansiveCheckboxList>
        </Flex>
      ) : (
        <Flex>
          <Flex direction="column" margin="0 32px 0 0" width="50%">
            <Text type={TextTypesEnum.Medium12} color={Colors.Grey900} margin="0 0 8px 0">Asset class</Text>
            <ExpansiveCheckboxList
              config={assetClassConfig}
            />
          </Flex>
          <Flex direction="column">
            <Text type={TextTypesEnum.Medium12} color={Colors.Grey900} margin="0 0 8px 0">Asset subtype</Text>
            <ExpansiveCheckboxList
              config={subtypeConfig}
              expansive={false}
            />
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default AssetClassAndPropertyTypeFilter;
