import styled from '@emotion/styled';
import React from 'react';
import Colors, { ColorsType } from '../styles/Colors';
import { TextStyles, TextTypesEnum } from './Text';
import { media } from '../utils/MediaQuery';

export enum DividerTypesEnum {
  Vertical = 'Vertical',
  Horizontal = 'Horizontal',
}

type DividerProps = {
  margin?: string;
  type?: DividerTypesEnum;
  color?: ColorsType;
  top?: string; // adding this props is a little hacky. after playing around with different components (with different heights) on different sized screens, i have found that within multiple components, i had to toy around with this property to center the divider. idk man...
  verticalDividerHeight?: string;
  children?: React.ReactNode;
};

const Container = styled.div<DividerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ type }) => (type === DividerTypesEnum.Vertical ? '1px' : '100%')};
  height: ${({ type }) => (type === DividerTypesEnum.Vertical ? '100%' : '1px')};
  margin: ${(props) => props.margin};

  ${media.print} {
    display: none;
  }
`;

const Line = styled.div<DividerProps>`
  position: absolute;
  top: ${(props) => props.top};
  background-color: ${(props) => props.color};
  width: ${({ type }) => (type === DividerTypesEnum.Vertical ? '1px' : '100%')};
  height: ${({ type, verticalDividerHeight }) => (type === DividerTypesEnum.Vertical ? verticalDividerHeight : '1px')};
`;

const Text = styled(TextStyles)`
  background-color: ${Colors.White};
  padding: 0 16px;
`;

const Divider: React.FC<DividerProps> = ({
  children,
  margin = '0',
  top = '0',
  type = DividerTypesEnum.Horizontal,
  color = Colors.Grey300,
  verticalDividerHeight = '100%',
}) => (
  <Container
    margin={margin}
    type={type}
    color={color}
  >
    <Line
      type={type}
      color={color}
      top={top}
      verticalDividerHeight={verticalDividerHeight}
    />
    {children && (
      <Text
        type={TextTypesEnum.Regular16}
        color={Colors.Grey500}
      >
        {children}
      </Text>
    )}
  </Container>
);

export default Divider;
