import { gql } from '@apollo/client';
import ListingFragments from '../fragments/listing.fragments';

const REQUEST_PRIVATE_LISTING_ACCESS = gql`
  mutation RequestPrivateListingAccess($params: RequestPrivateListingAccessParams!) {
    requestPrivateListingAccess(params: $params) {
      ...ListingFields
      ...ListingAddress
      ...ListingPortfolioAddresses
      ...ListingSchedule
      ...ListingMedia
      ...ListingGuidance
      ...ListingVault
      ...ListingLicense
      ...ListingMetrics
      ...ListingMeta
      ...ListingUser
      ...ListingContacts
      ...ListingDealProfile
    }
  }

  ${ListingFragments.fields}
  ${ListingFragments.address}
  ${ListingFragments.portfolioAddresses}
  ${ListingFragments.schedule}
  ${ListingFragments.media}
  ${ListingFragments.guidance}
  ${ListingFragments.vault}
  ${ListingFragments.license}
  ${ListingFragments.metrics}
  ${ListingFragments.meta}
  ${ListingFragments.user}
  ${ListingFragments.contacts}
  ${ListingFragments.dealProfile}
`;

export default REQUEST_PRIVATE_LISTING_ACCESS;
