import { logger } from '@biproxi/logger';
import * as Wait from '@biproxi/utils/wait';
import NextUtil from './NextUtil';

/**
 * Set HubSpot Conversations SDK options
 * on the window object
 */

function remove() {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.remove();
  }
}

function isInitialized(): boolean {
  return Boolean(window.HubSpotConversations);
}

if (NextUtil.hasWindow()) {
  window.hsConversationsSettings = {
    loadImmediately: false,
    enableWidgetCookieBanner: false,
    disableAttachment: false,
    widgetOpen: false,
  };
}

function initialize(userEmail?: string, hubspotToken?: string) {
  if (userEmail && hubspotToken) {
    window.hsConversationsSettings = {
      loadImmediately: false,
      enableWidgetCookieBanner: false,
      disableAttachment: false,
      widgetOpen: false,
      identificationEmail: userEmail,
      identificationToken: hubspotToken,
    };
  }

  function onConversationsAPIReady() {
    logger.info('Conversions API ready');

    /**
      * If the HubSpot Widget is already
      * loaded on the page, remove and re-load
      */

    const { loaded } = window.HubSpotConversations.widget.status();

    if (loaded) {
      remove();
    }

    window.HubSpotConversations.widget.load();
    logger.info('HubSpot Loaded');
  }

  /*
   If external API methods are already available, use them.
  */
  if (window.HubSpotConversations) {
    onConversationsAPIReady();
  } else {
    /*
      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.ff
      These callbacks will be called once the external API has been initialized.
    */
    window.hsConversationsOnReady = [onConversationsAPIReady];
  }
}

/**
 * Open the HubSpot chat window.
 * It does not support checking to see if
 * the window is open or not, and thus
 * toggle on and off is not possible.
 */

async function openChat() {
  await Wait.forTrue(() => Boolean(window.HubSpotConversations));
  window.HubSpotConversations.widget.open();
}

const HubSpotSDK = {
  initialize,
  remove,
  isInitialized,
  openChat,
};

export default HubSpotSDK;
