import gql from 'graphql-tag';
import UserFragments from '../fragments/user.fragments';

const mutation = gql`
  mutation VerifyPhoneNumberVerificationCode($params: VerifyPhoneNumberVerificationCodeParams!) {
    verifyPhoneNumberVerificationCode(params: $params) {
        ...UserFields
        ...UserAddress
        ...UserInvestorProfile
        ...UserLicenses
        ...UserProfileImageFile
        ...UserOAuthProviders
        ...UserNotificationSettings
        ...UserNylasSettings
        ...UserContacts
    }
  }

  ${UserFragments.fields}
  ${UserFragments.address}
  ${UserFragments.investorProfile}
  ${UserFragments.licenses}
  ${UserFragments.profileImageFile}
  ${UserFragments.oAuthProviders}
  ${UserFragments.notificationSettings}
  ${UserFragments.nylasSettings}
  ${UserFragments.contacts}
`;

export default mutation;
