import React from 'react';
import styled from '@emotion/styled';
import ReactDateInput from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Input, { InputTypesEnum } from './Input';
import Colors from '../styles/Colors';
import BoxShadows from '../styles/BoxShadows';
import PopperPlacementTypes from '../models/enums/PopperPlacementTypes';

type ContainerProps = {
  disabled?: boolean;
  margin?: string;
  width?: string;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};

  input {
    &:hover {
      cursor: ${(props) => (props.disabled ? null : 'pointer')};
    }
  }

  * {
    font-family: "aktiv-grotesk";

    &:focus {
      outline: none;
      border: none;
    }
  }

  .react-datepicker-popper {
    box-shadow: ${BoxShadows.Darker};
    border-radius: 8px;
    border: 1px solid ${Colors.Grey300};
    z-index: 250;
  }

  .react-datepicker {
    display: flex;
    border: 0px !important;
    border-radius: 8px;
    overflow: hidden;
    padding: 8px;
  }

  .react-datepicker-wrapper {
    width: 100% !important;
  }

  .react-datepicker__input-container {
    width: 100% !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: ${Colors.Grey500};
    border-radius: 50%;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 3rem;
    width: 3rem;
  }

  .react-datepicker__day {
    color: ${Colors.Black};
    border-radius: 50%;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 3rem;
    width: 3rem;
  }

  .react-datepicker__day--outside-month {
    color: ${Colors.Grey400};
    border-radius: 50%;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 3rem;
    width: 3rem;
  }

  .react-datepicker__time-container {
    width: unset;
  }
  .react-datepicker__time-container .react-datepicker__time {
    width: unset;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: ${Colors.Brand700 || Colors.Blurple700} !important;
    color: ${Colors.White} !important;
    border-radius: 50%
  }

  .react-datepicker__day--selected:hover
    .react-datepicker__day--in-selecting-range:hover
    .react-datepicker__day--in-range:hover
    .react-datepicker__month-text--selected:hover
    .react-datepicker__month-text--in-selecting-range:hover
    .react-datepicker__month-text--in-range:hover
    .react-datepicker__quarter-text--selected:hover
    .react-datepicker__quarter-text--in-selecting-range:hover
    .react-datepicker__quarter-text--in-range:hover {
    background-color: ${Colors.Brand700 || Colors.Blurple700};
    color: ${Colors.White};
    border-radius: 50%;
  }

  .react-datepicker__header {
    background-color: ${Colors.White};
    border-bottom: 0px;
    /* padding-top: 20px; */
    margin-bottom: 12px;
  }

  .react-datepicker__current-month {
    color: ${Colors.Grey900};
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* height: calc(100% - 78px); */
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover {
    background-color: ${Colors.Grey200};
    color: ${Colors.Grey900};
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected {
    background-color: ${Colors.Brand700 || Colors.Blurple700};
    color: ${Colors.White};
    border-radius: 50%;
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover {
    background-color: ${Colors.Brand700 || Colors.Blurple700} !important;
    color: ${Colors.White} !important;
    border-radius: 50%
  }

  /* Month */

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: space-around;
    height: 33px;
    width: 267px;
  }

  /* Time */

  .react-datepicker__time-container {
    border-left: none;
    width: 80px;


  }

  .react-datepicker__header--time {
    display: none;
  }

  /* .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    width: 96px;
  } */

  .react-datepicker__time-list-item {
    position: relative;
    color: ${Colors.Grey900};
    font-size: 1.4rem;
    font-weight: 500;
    height: auto !important;
    margin-bottom: 4px;
    width: 80px;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${Colors.Grey300};
    box-sizing: border-box;
    border-radius: 4px;
  }

  .react-datepicker__time-list-item:hover {
    color: ${Colors.Grey900};
    background-color: ${Colors.Grey200};
  }

  .react-datepicker__time-list-item--selected {
    color: ${Colors.White} !important;
    background-color: ${Colors.Brand700 || Colors.Blurple700} !important;

    &:hover {
      color: ${Colors.White} !important;
      background-color: ${Colors.Brand700 || Colors.Blurple700} !important;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: unset;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    width: 110px;
  }

    /* Year */

  .react-datepicker__year-wrapper {
    max-width: 252px;
  }

  .react-datepicker__header {
    color: ${Colors.Grey900};
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .react-datepicker__year-text, .react-datepicker__year-text--keyboard-selected {
    position: relative;
    color: ${Colors.Grey900};
    background-color: ${Colors.White};
    font-size: 1.4rem;
    font-weight: 500;
    height: auto !important;
    margin-bottom: 4px;
    width: 80px;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${Colors.Grey300};
    box-sizing: border-box;
    border-radius: 4px;
  }

  .react-datepicker__year-text--selected {
    background-color: ${Colors.Brand700 || Colors.Blurple700};
    color: ${Colors.White};
  }

  /* Buttons */

  .react-datepicker__navigation--next {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${Colors.Grey300};
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 4px;
    color: ${Colors.Black};
    background-image: url('/angle-right-solid.svg');
    background-repeat: no-repeat;
  }

  .react-datepicker__navigation--previous {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${Colors.Grey300};
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 4px;
    color: ${Colors.Black};
    background-image: url('/angle-left-solid.svg');
    background-repeat: no-repeat;
  }

  /* .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${Colors.Grey300};
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 4px;
    color: ${Colors.Black};
    top: 10px;
    left: 234px
  } */
`;

export enum DateInputTypeEnum {
  Date = 'Date',
  Time = 'Time',
  DateTime = 'DateTime',
  Year = 'Year',
}

type DateInputProps = {
  value: number | null;
  dataCy?: string;
  onChange: (
    millis: number | null,
    event: React.SyntheticEvent<any, Event> | undefined
  ) => void;
  placeholder?: string;
  width?: string;
  label?: string;
  disabled?: boolean;
  inputType?: DateInputTypeEnum;
  autoFocus?: boolean
  margin?: string;
  popperPlacement?: PopperPlacementTypes;
  error?: string | null;
};

const DateInput: React.FC<DateInputProps> = ({
  value,
  dataCy,
  onChange,
  placeholder,
  width,
  label,
  disabled = false,
  inputType = DateInputTypeEnum.Date,
  autoFocus,
  margin,
  popperPlacement = PopperPlacementTypes.BottomStart,
  error,
}) => {
  const {
    dateFormat,
    showTimeSelect,
    showTimeSelectOnly,
    showYearPicker,
  } = (() => {
    switch (inputType) {
      case DateInputTypeEnum.Date:
      case DateInputTypeEnum.Time:
        return {
          dateFormat: 'p',
          showTimeSelect: true,
          showTimeSelectOnly: true,
          showYearPicker: false,
        };
      case DateInputTypeEnum.DateTime:
        return {
          dateFormat: 'Pp',
          showTimeSelect: true,
          showTimeSelectOnly: false,
          showYearPicker: false,
        };
      case DateInputTypeEnum.Year:
        return {
          dateFormat: 'yyyy',
          showTimeSelect: false,
          showTimeSelectOnly: false,
          showYearPicker: true,
        };
      default:
        return {
          dateFormat: 'P',
          showTimeSelect: false,
          showTimeSelectOnly: false,
          showYearPicker: false,
        };
    }
  })();

  return (
    <Container disabled={disabled} margin={margin} width={width}>
      <ReactDateInput
        disabled={disabled}
        customInput={(
          <Input
            data-cy={dataCy}
            autoFocus={autoFocus}
            value={value ? value.toString() : 'None'}
            placeholder={placeholder}
            onChange={() => {}} // ignore, use onChange below
            width={width}
            label={label}
            disabled={disabled}
            inputType={InputTypesEnum.Date}
            error={error}
          />
        )}
        popperPlacement={popperPlacement}
        placeholderText={value ? undefined : 'None'}
        selected={value ? new Date(value) : null}
        onChange={(date, event) => onChange(new Date(date as Date).getTime(), event)}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        showYearPicker={showYearPicker}
      />
    </Container>
  );
};

export default DateInput;
