import { FieldPolicy } from '@apollo/client';
/**
 * These objects define the field policies for merging and
 * reading specific types of data in the Apollo cache.
 * We use this to determine how to organize pagination results and real-time subscription data.
 * Read: https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results for more information.
 */

const Channels: FieldPolicy = {
  keyArgs: ['params', ['search', 'listingId']],
  merge(existing = [], incoming) {
    let merged = [];

    // Ensure no duplicates
    merged = Array.from(new Set([
      ...existing,
      ...incoming,
    ]));

    return [...merged];
  },
};

const Notifications: FieldPolicy = {
  keyArgs: ['params', ['search']],
  merge(existing = { data: [], info: {} }, incoming, { readField }) {
    const merged = { data: [], info: {} };

    // Ensure no duplicates and sort correctly
    merged.data = [
      ...existing?.data,
      ...incoming?.data,
    ].filter((a, index, self) => self.findIndex((b) => readField?.('_id', b) === readField?.('_id', a)) === index).sort((a, b) => (readField('meta', b) as any).createdAt
        - (readField('meta', a) as any).createdAt);
    merged.info = { ...incoming?.info };

    return { ...merged };
  },
};

const Users: FieldPolicy = {
  keyArgs: ['params', ['query', ['search']]],
  merge(existing = { data: [], info: {} }, incoming) {
    const merged = { data: [], info: {} };

    // Ensure no duplicates
    merged.data = Array.from(new Set([
      ...existing?.data,
      ...incoming?.data,
    ]));
    merged.info = { ...incoming?.info };

    return { ...merged };
  },
};

const Listings: FieldPolicy = {
  keyArgs: ['params', ['query', ['keywords']]],
  merge(existing = { data: [], info: {} }, incoming) {
    const merged = { data: [], info: {} };

    // Ensure no duplicates
    merged.data = Array.from(new Set([
      ...existing?.data,
      ...incoming?.data,
    ]));
    merged.info = { ...incoming?.info };

    return { ...merged };
  },
};

const ListingUploads: FieldPolicy = {
  keyArgs: ['params', ['keywords']],
  merge(existing = { data: [], info: {} }, incoming) {
    const merged = { data: [], info: {} };

    // Ensure no duplicates
    merged.data = Array.from(new Set([
      ...existing?.data,
      ...incoming?.data,
    ]));
    merged.info = { ...incoming?.info };

    return { ...merged };
  },
};

const DataExplorerFilteredProperties: FieldPolicy = {
  keyArgs: false,
  merge(existing = { properties: [] }, incoming, { args }) {
    const merged = { properties: [] };

    if (args.params.pagination.offset === 0) {
      merged.properties = [
        ...(incoming.properties ?? []),
      ];
    } else {
      merged.properties = [
        ...existing?.properties,
        ...(incoming?.properties ?? []),
      ];
    }

    return { ...merged };
  },
};

const DataExplorerSimilarPropertyData: FieldPolicy = {
  keyArgs: false,
  merge(existing = { properties: [] }, incoming, { args }) {
    const merged = { properties: [] };

    if (args.params.pagination.offset === 0) {
      merged.properties = [
        ...(incoming.properties ?? []),
      ];
    } else {
      merged.properties = [
        ...existing?.properties,
        ...(incoming?.properties ?? []),
      ];
    }

    return { ...merged };
  },
};

const DataExplorerCherreCityStateData: FieldPolicy = {
  keyArgs: false,
  merge(existing = { cityState: [] }, incoming, { args }) {
    const merged = { cityState: [] };

    if (args.params.pagination.offset === 0) {
      merged.cityState = [
        ...(incoming.cityState ?? []),
      ];
    } else {
      merged.cityState = [
        ...existing?.cityState,
        ...(incoming?.cityState ?? []),
      ];
    }

    return { ...merged };
  },
};

const FieldPolicies = {
  Channels,
  Notifications,
  Users,
  DataExplorerFilteredProperties,
  DataExplorerSimilarPropertyData,
  DataExplorerCherreCityStateData,
  Listings,
  ListingUploads,
};

export default FieldPolicies;
