import React from 'react';
import { useLocation } from 'react-router-dom';
// import Wait from '@biproxi/utils/wait';
import HubSpotSDK from '../utils/HubSpotSDK';
import { useMobileMedia } from '../utils/MediaQuery';

// export const repositionHubSpotChat = async (isTablet?: boolean) => {
//   await Wait(500);

//   const hubSpotContainer: HTMLElement = document.querySelector('#hubspot-messages-iframe-container.widget-align-left');

//   if (hubSpotContainer) {
//     if (window.location.href.includes('/dashboard')) {
//       if (isTablet) {
//         hubSpotContainer.setAttribute('style', 'z-index: 998 !important; left: 0px !important; bottom: 0px !important; width: 100px; height: 96px; ');
//       } else {
//         hubSpotContainer.setAttribute('style', 'z-index: 998 !important; left: 50px !important; bottom: -10px !important; width: 100px; height: 96px; ');
//       }
//     } else {
//       hubSpotContainer.setAttribute('style', 'z-index: 998 !important; left: 0px !important; bottom: 0px !important; width: 100px; height: 96px; ');
//     }
//   }
// };

type HubSpotProps = {};

const HubSpot: React.FC<HubSpotProps> = () => {
  /** Hooks */
  const isMobile = useMobileMedia();
  // const isTablet = useTabletMedia();
  const location = useLocation();

  /**
   * Initialize HubSpot chat window
   * and HubSpot chat on mobile.
   *
   * After HubSpot chat window is initialized,
   * reposition it as needed.
   */
  React.useEffect(() => {
    const effect = async () => {
      if (isMobile) {
        HubSpotSDK.remove();
      } else {
        if (HubSpotSDK.isInitialized) {
          // repositionHubSpotChat(isTablet);
          return;
        }
        HubSpotSDK.initialize();
        // repositionHubSpotChat(isTablet);
      }
    };
    effect();
  }, [isMobile, location.pathname]);

  /** Render Nothing */
  return null;
};

export default HubSpot;
