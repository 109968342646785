import styled from '@emotion/styled';
import Colors from '../styles/Colors';

export enum BlueDotSizeEnum {
  Six = 'Six',
  Eight = 'Eight',
}

const BlueDot = styled.div<{ marginTop?: string; size?: BlueDotSizeEnum }>`
  border-radius: 50%;
  background: ${Colors.Brand700 || Colors.Blurple700};
  height: ${(props) => (props.size === BlueDotSizeEnum.Six ? '6px' : '8px')};
  width: ${(props) => (props.size === BlueDotSizeEnum.Six ? '6px' : '8px')};
  min-height: ${(props) => (props.size === BlueDotSizeEnum.Six ? '6px' : '8px')};
  min-width: ${(props) => (props.size === BlueDotSizeEnum.Six ? '6px' : '8px')};
  margin-right: 4px;
  margin-top: ${({ marginTop }) => marginTop};
`;

export default BlueDot;
