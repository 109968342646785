import gql from 'graphql-tag';

const RESET_PASSWORD = gql`
  mutation ResetPassword($params: ResetPasswordParams!) {
    resetPassword(params: $params) {
      user {
        _id
        firstName
        lastName
        email
      }
      token
      hubspotToken
    }
  }
`;

export default RESET_PASSWORD;
