import React from 'react';
import styled from '@emotion/styled';
import UserUtil from '@biproxi/models/utils/UserUtil';
import { SCHEDULE_DEMO_URL } from '@biproxi/models/externalLinks';
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import useUser from '../../hooks/useUser.hook';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Flex from '../../elements/Flex';
import ShareListingEmailImage from '../../../public/nylas-feature-preview.png';
import ShareListingEmailImageMobile from '../../../public/nylas-feature-preview-mobile.png';
import UploadListingImage from '../../../public/01-matched-leads.png';
import UploadListingImageMobile from '../../../public/01-matched-leads-mobile.png';
import HostPrivateEventImage from '../../../public/02-private-event.png';
import HostPrivateEventImageMobile from '../../../public/02-private-event-mobile.png';
import DataExplorerImage from '../../../public/03-data-explorer.png';
import DataExplorerImageMobile from '../../../public/03-data-explorer-mobile.png';
import ExploreListingsImage from '../../../public/04-marketplace.png';
import ExploreListingsImageMobile from '../../../public/04-marketplace-mobile.png';
import InvestorProfileImage from '../../../public/05-investor-profile.png';
import InvestorProfileImageMobile from '../../../public/05-investor-profile-mobile.png';
import LinkText from '../../elements/LinkText';
import BoxShadows from '../../styles/BoxShadows';
import { FadeIn } from '../../elements/Motion';
import { ModalTypesEnum } from './Modal';

const Container = styled.div`
  width: 944px;
  max-width: 90vw;

  ${media.mobile} {
    width: 100%;
  }
`;

const ImageDiv = styled(FadeIn)<{ src: string }>`
  background-size: cover;
  background-position: center;
  background-origin: unset;
  background-image: url(${({ src }) => src});
  background-color: ${Colors.Grey300};
  border-radius: 8px;
  box-shadow: ${BoxShadows.Darker};
  box-sizing: border-box;
  width: 100%;
  height: 350px;
`;

const NavLine = styled.div<{ active: boolean }>`
  background: ${(({ active }) => (active ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey300))};
  border-radius: 4px;
  height: 3px;
  width: 24px;
  transition: all 0.2s ease-in-out;
`;

const NavlineClickContainer = styled.div`
  transition: all 0.2s ease-in-out;
  padding: 16px 8px;
  cursor: pointer;

  &:hover {
    #navLine {
      background: ${Colors.Brand700 || Colors.Blurple700};
    }
  }
`;

/** Potential Display States on th screen */
enum BrokerStates {
  ShareListingsEmail = 'ShareListingsEmail',
  UploadListing = 'UploadListing',
  HostPrivateEvent = 'HostPrivateEvent',
  DataExplorer = 'DataExplorer',
}

enum InvestorStates {
  ExploreListing = 'ExploreListing',
  DataExplorer = 'DataExplorer',
  InvestorProfile = 'InvestorProfile',
}

/** Used to iterate through for auto nav every 3 seconds */
const brokerStatesArray: BrokerStates[] = [BrokerStates.ShareListingsEmail, BrokerStates.UploadListing, BrokerStates.HostPrivateEvent, BrokerStates.DataExplorer];
const investorStatesArray: InvestorStates[] = [InvestorStates.ExploreListing, InvestorStates.DataExplorer, InvestorStates.InvestorProfile];

export type QuickStartGuideModalProps = {
  fromTopNav?: boolean // don't show welcome modal if revisiting this modal
};

const QuickStartGuideModal: React.FC<QuickStartGuideModalProps> = ({
  fromTopNav = false,
}) => {
  /** State */
  const [pageState, setPageState] = React.useState<BrokerStates | InvestorStates>(null);
  const [loaded, setLoaded] = React.useState(false);
  const [autoNavStopped, setAutoNavStopped] = React.useState(false);

  /** Actions */
  const dispatch = useAppDispatch();
  const replaceWelcomeModal = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.WelcomeToBiproxiModal,
      props: {},
    }),
  );
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushAuthenticateWithNylasModal = () => {
    popModal();
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.AuthenticateWithNylas,
        props: {},
      }),
    );
  };

  /** Hooks */
  const isMobile = useMobileMedia();
  const { user } = useUser();

  /** Effects */
  React.useEffect(() => {
    // Set initial page state
    if (!loaded && user) {
      if (UserUtil.isBroker(user)) {
        setPageState(BrokerStates.ShareListingsEmail);
      } else {
        setPageState(InvestorStates.ExploreListing);
      }
      setLoaded(true);
    }
  }, [user]);

  // Auto navigate through content every 3 seconds
  React.useEffect(() => {
    let interval;
    if (loaded && !autoNavStopped) {
      const arrayOfPageStates: any = UserUtil.isBroker(user) ? brokerStatesArray : investorStatesArray;
      interval = setInterval(() => {
        const curIndex = arrayOfPageStates.indexOf(pageState);
        const nextItemIndex = (curIndex + 1) >= arrayOfPageStates.length ? 0 : curIndex + 1;
        setPageState(arrayOfPageStates.at(nextItemIndex));
      }, 4000);
    }

    if (autoNavStopped) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [loaded, pageState]);

  /** Render */
  type ConfigType = {
    title: string;
    subtitle: string;
    image: any;
  }

  const ShareListingsWithAudienceEmailConfig: ConfigType = {
    title: 'Share listings with your audience via email',
    subtitle: 'Connect your email and upload a csv file in order to chose some or all of your contacts to increase listing reach and visibility.  Simply go to your settings and connect under Linked accounts.',
    image: isMobile ? ShareListingEmailImageMobile.src : ShareListingEmailImage.src,
  };

  const UploadListingConfig: ConfigType = {
    title: 'Upload a listing',
    subtitle: 'Gain listing exposure to qualified and matched investors with our Buyer Matching Tool in your dashboard.',
    image: isMobile ? UploadListingImageMobile.src : UploadListingImage.src,
  };

  const HostPrivateEventConfig: ConfigType = {
    title: 'Host a Private Event',
    subtitle: 'Live best and final tool to maximize proceeds and eliminate deal fatigue.',
    image: isMobile ? HostPrivateEventImageMobile.src : HostPrivateEventImage.src,
  };

  const DataExplorerConfig: ConfigType = {
    title: 'Explore with Data Explorer',
    subtitle: 'Search Off-Market data, Unmasked Owner information, sales comps, climate risk and more.',
    image: isMobile ? DataExplorerImageMobile.src : DataExplorerImage.src,
  };

  const ExploreListingConfig: ConfigType = {
    title: 'Explore available listings',
    subtitle: 'Navigate to the home icon on the left side navigation to view available properties and save your searches.',
    image: isMobile ? ExploreListingsImageMobile.src : ExploreListingsImage.src,
  };

  const InvestorProfileConfig: ConfigType = {
    title: 'Find your perfect investment',
    subtitle: 'Keep your Investor Profile up to date to get matched with offerings within your investment criteria.',
    image: isMobile ? InvestorProfileImageMobile.src : InvestorProfileImage.src,
  };

  const getConfig = (): ConfigType | null => {
    if (UserUtil.isBroker(user)) {
      switch (pageState) {
        case BrokerStates.ShareListingsEmail:
          return ShareListingsWithAudienceEmailConfig;
        case BrokerStates.UploadListing:
          return UploadListingConfig;
        case BrokerStates.HostPrivateEvent:
          return HostPrivateEventConfig;
        case BrokerStates.DataExplorer:
          return DataExplorerConfig;
        default:
          return null;
      }
    } else {
      switch (pageState) {
        case InvestorStates.ExploreListing:
          return ExploreListingConfig;
        case InvestorStates.DataExplorer:
          return DataExplorerConfig;
        case InvestorStates.InvestorProfile:
          return InvestorProfileConfig;
        default:
          return null;
      }
    }
  };

  const Content: React.FC<ConfigType> = ({ title, subtitle, image }) => (
    <>
      <Text
        type={TextTypesEnum.Bold24}
        color={Colors.Grey900}
        margin="0 0 8px"
      >
        {title}
      </Text>
      <Text
        type={TextTypesEnum.Regular16}
        color={Colors.Grey900}
        margin="0 0 24px"
      >
        {subtitle}
      </Text>
      <ImageDiv
        src={image}
        duration={0.6}
      />
      <Flex margin="8px 0" justify="center">
        {Object.keys(UserUtil.isBroker(user) ? BrokerStates : InvestorStates).map((state: BrokerStates | InvestorStates) => (
          <NavlineClickContainer
            onClick={() => {
              if (!autoNavStopped) {
                setAutoNavStopped(true);
              }
              setPageState(state);
            }}
          >
            <NavLine
              id="navLine"
              active={state === pageState}
            />
          </NavlineClickContainer>
        ))}
      </Flex>
      <Text
        type={TextTypesEnum.Regular16}
        color={Colors.Grey900}
        align="center"
      >
        For additional help schedule a demo with one of our team members.
      </Text>
      {pageState === BrokerStates.ShareListingsEmail ? (
        <Flex justify="center">
          <Button
            text="Connect your email"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            onClick={() => pushAuthenticateWithNylasModal()}
            margin="16px 0"
          />
          <Flex justify="center" align="center" margin="0 16px 0 16px">
            <span>or</span>
          </Flex>
          <Button
            text="Schedule a demo"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            onClick={() => window.open(SCHEDULE_DEMO_URL, '_blank')}
            margin="16px 0"
          />
        </Flex>
      ) : (
        <Flex justify="center">
          <Button
            text="Schedule a demo"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            onClick={() => window.open(SCHEDULE_DEMO_URL, '_blank')}
            margin="16px 0"
          />
        </Flex>
      )}
      <Flex justify="center">
        <LinkText
          dataCy="close-quick-start-guide-link"
          type={TextTypesEnum.Bold16}
          margin="0 0 16px 0"
          onClick={() => (fromTopNav ? popModal() : replaceWelcomeModal())}
        >
          {fromTopNav ? 'Close' : 'Continue'}
        </LinkText>
      </Flex>
      <Text
        type={TextTypesEnum.Regular16}
        color={Colors.Grey900}
        align="center"
      >
        You can access the feature preview guide at any time in your profile menu at the top right.
      </Text>
    </>
  );

  return (
    <ModalContainer data-cy="quick-start-guide-modal" mobileHeight={isMobile ? 'unset' : '100%'}>
      <ModalHeader title="Feature preview" close={fromTopNav ? popModal : null} />
      <ModalContent>
        <Container>
          <Content {...getConfig()} />
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default QuickStartGuideModal;
