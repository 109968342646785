import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import UserUtil from '@biproxi/models/utils/UserUtil';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import ListingInfoFieldNamesEnum from '@biproxi/models/enums/ListingInfoFieldNamesEnum';
import Skeleton from 'react-loading-skeleton';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import Colors from '../styles/Colors';
import Flex from '../elements/Flex';
import Text, { TextTypesEnum } from '../elements/Text';
import Icon, { Icons } from '../elements/Icon';
import ProfileImage, { ProfileImageTypes } from './ProfileImage';
import { media } from '../utils/MediaQuery';
import ListingCardThumbnail from './ListingCardThumbnail';
import BoxShadows from '../styles/BoxShadows';
import ListingCardTypesEnum from '../models/enums/ListingCardTypesEnum';
import useIsPrivateListing from '../hooks/useIsPrivateListing.hook';
import useUser from '../hooks/useUser.hook';
import { AppState, useAppSelector } from '../redux/store';

const Container = styled.div<{ type: string }>`
  width: ${(props) => {
    switch (props.type) {
      case ListingCardTypesEnum.DefaultSearch:
        return '100%';
      case ListingCardTypesEnum.DefaultFavorited:
        return '100%';
      case ListingCardTypesEnum.DefaultMap:
        return '200px';
      case ListingCardTypesEnum.DefaultFavoritedDashboard:
        return null;
      default:
        return '250px';
    }
  }};
  background-color: ${(props) => {
    switch (props.type) {
      case ListingCardTypesEnum.DefaultMap:
        return Colors.White;
      default:
        return null;
    }
  }};
  border-radius: ${(props) => {
    switch (props.type) {
      case ListingCardTypesEnum.DefaultMap:
        return '8px';
      default:
        return null;
    }
  }};
  box-shadow: ${(props) => {
    switch (props.type) {
      case ListingCardTypesEnum.DefaultMap:
        return `${BoxShadows.Standard}`;
      default:
        return null;
    }
  }};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
`;

const ListingCardContent = styled.div<{ type: string }>`
  &, & > div > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  };

  padding: ${(props) => {
    switch (props.type) {
      case ListingCardTypesEnum.DefaultMap:
        return '0px 8px 8px 8px';
      default:
        return null;
    }
  }};

  ${media.mobile} {
    margin-bottom: 16px;
  }
`;

const Dot = styled.span`
  color: ${Colors.Grey400};
`;

type DefaultListingCardProps = {
  type: ListingCardTypesEnum;
  listing: IListingGraphQL | null;
  onClick?: () => void;
}

const ListingCard: React.FC<DefaultListingCardProps> = ({
  type,
  listing,
  onClick,
}) => {
/** ******************************************************************************
*  Contentful STUFF
******************************************************************************* */

  /** Contentful State - Redux */
  const {
    contentful: {
      brandCollection: {
        companyMediaAssets,
      },
    },
  } = useAppSelector((state: AppState) => state);
  const privateListingImage = companyMediaAssets.lockedListingImage.url || companyMediaAssets.lockedListingImage;

  /** ******************************************************************************
*  Contentful STUFF
******************************************************************************* */

  /** State */
  const listingUnavailable = Boolean(listing?.state === ListingStateEnum.Draft || listing?.state === ListingStateEnum.Inactive
    || listing?.state === ListingStateEnum.Scheduled);
  const defaultContactHidden = Boolean(Boolean(listing?.contacts?.length) && !listing?.contacts?.find((contact) => contact._id === listing?.user?._id));
  const { user } = useUser();
  const { isPrivateListing, isPrivateListingAccess } = useIsPrivateListing({
    params: {
      listing,
      user,
    },
  });

  /** Render */
  const sqFt = listing?.info?.find(({ fieldName }) => fieldName === ListingInfoFieldNamesEnum.BuildingSqFt)?.value ?? null;
  const click = onClick ? () => onClick() : null;
  return (
    <Container data-cy="search-listing-card" type={type} onClick={click}>
      <ListingCardThumbnail
        type={type}
        listing={listing}
        privateListingProps={{
          isPrivateListing,
          isPrivateListingAccess,
        }}
        listingImage={isPrivateListing && !isPrivateListingAccess ? privateListingImage : listing?.media?.files?.[0]?.url}
      />
      <ListingCardContent type={type}>
        <Text
          type={
            type === ListingCardTypesEnum.DefaultMap
              ? TextTypesEnum.Bold14
              : TextTypesEnum.Bold16
            }
          margin="8px 0px 0px 0px"
          color={Colors.Black}
          skWidth={160}
        >
          {ListingUtil.name(listing, { allButAddress1: true }, isPrivateListing && !isPrivateListingAccess)}
        </Text>
        {isPrivateListing && !isPrivateListingAccess
          ? null
          : (
            <Flex align="center">
              {listing ? <Icon icon={Icons.MapMarkerAltSolid} color={Colors.Grey700} size={10} width="10px" margin="0 4px 0 0" /> : null}
              <Text type={type === ListingCardTypesEnum.DefaultMap ? TextTypesEnum.Regular12 : TextTypesEnum.Regular14Small} color={Colors.Grey700} skWidth={280}>
                {ListingUtil.isValidPortfolioListing(listing) ? 'Multiple addresses' : AddressUtil.formatAddress(listing?.address, listing?.name ? {} : { address1: true }) || null}
              </Text>
            </Flex>
          )}

        <Flex align="center" margin="4px 0 0">
          <Text
            type={TextTypesEnum.Regular12}
            color={Colors.Grey700}
            skWidth={240}
          >
            {(() => {
              if (!listing?.assetClass) return null;
              return (
                <>
                  {listing?.assetClass}
                  <Dot>&nbsp;&bull;&nbsp;</Dot>
                  {listing?.propertyType}
                  {Boolean(sqFt) && (
                  <>
                    <Dot>&nbsp;&bull;&nbsp;</Dot>
                    {`${StringUtil.formatNumber(sqFt as number)} sq. ft.`}
                  </>
                  )}
                </>
              );
            })()}
          </Text>
        </Flex>
        {type !== ListingCardTypesEnum.DefaultFavoritedDashboard && (
        <Flex margin="8px 0 0">
          <ProfileImage type={ProfileImageTypes.Small} user={defaultContactHidden ? null : listing?.user} contact={defaultContactHidden ? listing?.contacts?.[0] : null} />
          <Flex direction="column">
            <Text
              type={TextTypesEnum.Regular12}
              color={Colors.Grey700}
              skWidth={64}
            >
              {listing ? 'Listed by' : null}
            </Text>
            <Text
              type={TextTypesEnum.Medium12}
              color={Colors.Grey900}
              skWidth={120}
            >
              {(listing?.contacts?.length && defaultContactHidden) ? listing?.contacts[0]?.name : UserUtil.fullName(listing?.user)}
            </Text>
          </Flex>
        </Flex>
        )}
      </ListingCardContent>
      {listingUnavailable && <Skeleton width="100%" height="100%" />}
    </Container>
  );
};

export default React.memo(ListingCard);
