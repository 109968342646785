import React from 'react';
import { useMutation } from '@apollo/client';
import { IAuthenticateWithNylasParams, IAuthenticateWithNylasResponse } from '@biproxi/models/services/INylasService';
import { IUpdateUserNylasSettingsParams } from '@biproxi/models/services/IUserService';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import { NylasProviderDailyLimitsEnum } from '@biproxi/models/enums/NylasProviderLimitsEnums';
import IUserNylasSettings from '@biproxi/models/interfaces/IUserNylasSettings';
import AUTHENTICATE_WITH_NYLAS from '../graphql/mutations/authenticateNylas.mutation';
import UPDATE_NYLAS_SETTINGS from '../graphql/mutations/updateUserNylasSettings.mutation';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { UserActions, UserSelectors } from '../redux/user.redux';
import { IToastConfig, ToastTypesEnum } from '../components/Toast';
import { AppActions } from '../redux/app.redux';

/** Authenticate with Nylas from Settings page or AuthenticateWithNylasModal
 *
 * Returns authentication status and values for rate limit handling.
 */

type UseNylas = {
  authenticated: boolean;
  nylasProvider: string;
  dailyEmailLimit: number;
  handleDailyEmailReset: () => void;
};

type UseNylasHook = () => UseNylas;

const useNylas: UseNylasHook = () => {
  //! State
  const user = useAppSelector(UserSelectors.user);
  const authenticated = Boolean(user?.nylasSettings?.nylasAuthenticated);
  const nylasProvider = user?.nylasSettings?.nylasProvider;
  const dailyEmailLimit = user?.nylasSettings?.dailyEmailLimit;
  const emailLastSentAt = user?.nylasSettings?.emailLastSentAt;
  const eligibleForReset = TimeUtil.is24HoursLater(emailLastSentAt);

  /** Detect nylas code for authentication */
  const queryParameters = new URLSearchParams(window.location.search);
  const nylasCode = queryParameters.get('code');

  /** Error handling */
  const toastMessage = 'Successfully enabled Nylas';
  const toastErrorMessage = 'There was an error authenticating with Nylas';

  //! Actions
  const dispatch = useAppDispatch();
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const setUserNylasSettings = (nylasSettings: Partial<IUserNylasSettings>) => dispatch(
    UserActions.setUserNylasSettings(nylasSettings),
  );

  /** Reset the user's daily email limit if it has been 24 hours since last email and they are not already at the maximum allowed */
  const handleDailyEmailReset = () => {
    if (eligibleForReset && dailyEmailLimit !== (NylasProviderDailyLimitsEnum[nylasProvider] ?? NylasProviderDailyLimitsEnum.Other)) {
      resetUserDailyEmailLimit();
    }
  };

  //! GraphQL
  type AuthenticateNylasParams = {
    params: IAuthenticateWithNylasParams;
  }
  type AuthenticateNylasData = {
    authenticateWithNylas: IAuthenticateWithNylasResponse;
  }

  const [authenticateWithNylas] = useMutation<AuthenticateNylasData, AuthenticateNylasParams>(AUTHENTICATE_WITH_NYLAS, {
    variables: {
      params: {
        code: nylasCode,
      },
    },
    onCompleted: (data) => {
      const { authenticateWithNylas: { nylasProvider, dailyEmailLimit } } = data;
      setUserNylasSettings({
        nylasAuthenticated: true,
        nylasProvider,
        dailyEmailLimit,
      });
      pushToast({
        type: ToastTypesEnum.Notification,
        message: toastMessage,
      });
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: toastErrorMessage,
      });
    },
  });

  type ResetUserDailyEmailLimitParams = {
    params: IUpdateUserNylasSettingsParams;
  };
  type ResetUserDailyEmailLimitData = {
    updateUserNylasSettings: IUserNylasSettings;
  };

  const [resetUserDailyEmailLimit] = useMutation<ResetUserDailyEmailLimitData, ResetUserDailyEmailLimitParams>(UPDATE_NYLAS_SETTINGS, {
    variables: {
      params: {
        dailyEmailLimit: NylasProviderDailyLimitsEnum[nylasProvider] ?? NylasProviderDailyLimitsEnum.Other,
      },
    },
    onCompleted: (data) => {
      const { updateUserNylasSettings: { dailyEmailLimit } } = data;
      setUserNylasSettings({
        dailyEmailLimit,
      });
    },
  });

  //! Effects
  React.useEffect(() => {
    if (nylasCode && !authenticated) {
      authenticateWithNylas();
    }
  }, []);

  //! Return
  return {
    authenticated,
    nylasProvider,
    dailyEmailLimit,
    handleDailyEmailReset,
  };
};

export default useNylas;
