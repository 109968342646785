import gql from 'graphql-tag';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import { DocumentNode } from 'graphql';
import ListingFragments from './listing.fragments';
import UserFragments from './user.fragments';

// Not a perfect type, but it's good enough for now
type TPrivateEventFragments = {
  [K in keyof IPrivateEventGraphQL]?: DocumentNode;
} & {
  fields: DocumentNode;
}

const PrivateEventFragments: TPrivateEventFragments = {
  fields: gql`
    fragment PrivateEventFields on PrivateEvent {
      _id
      userId
      organizationId
      listingId
      startsAt
      endsAt
      psaFileIds
      state
    }
  `,
  psaFiles: gql`
    fragment PrivateEventPSAFiles on PrivateEvent {
      psaFiles {
        _id
        name
        mimetype
        url
      }
    }
  `,
  participants: gql`
    fragment PrivateEventParticipants on PrivateEvent {
      participants {
        _id
        email
        userId
        leadId
        organizationId
        state
        disabled
        user {
          ...UserFields
          ...UserProfileImageFile
        }
      }
    }

    ${UserFragments.fields}
    ${UserFragments.profileImageFile}
  `,
  bids: gql`
    fragment PrivateEventBids on PrivateEvent {
      bids {
        _id
        userId
        organizationId
        totalBidAmount
        bidIncrement
        disabled
        meta {
          createdAt
          createdBy
        }
      }
    }
  `,
  settings: gql`
    fragment PrivateEventSettings on PrivateEvent {
      settings {
        startingBid
        bidIncrement
      }
    }
  `,
  contingencies: gql`
    fragment PrivateEventContingencies on PrivateEvent {
      contingencies {
        dueDiligencePeriod
        closingPeriod
        depositAmount
        additionalTerms
      }
    }
  `,
  meta: gql`
    fragment PrivateEventMeta on PrivateEvent {
      meta {
        createdAt
        createdBy
      }
    }
  `,
  listing: gql`
    fragment PrivateEventListing on PrivateEvent {
      listing {
        ...ListingFields
        ...ListingAddress
        ...ListingPortfolioAddresses
        ...ListingSchedule
        ...ListingMedia
        ...ListingGuidance
        ...ListingVault
        ...ListingLicense
        ...ListingMetrics
        ...ListingMeta
        ...ListingUser
        ...ListingContacts
        ...ListingDealProfile
      }
    }

    ${ListingFragments.fields}
    ${ListingFragments.address}
    ${ListingFragments.portfolioAddresses}
    ${ListingFragments.schedule}
    ${ListingFragments.media}
    ${ListingFragments.guidance}
    ${ListingFragments.vault}
    ${ListingFragments.license}
    ${ListingFragments.metrics}
    ${ListingFragments.meta}
    ${ListingFragments.user}
    ${ListingFragments.contacts}
    ${ListingFragments.dealProfile}
  `,

};

export default PrivateEventFragments;
