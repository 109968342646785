import React from 'react';
import { InputTypesEnum } from '../elements/Input';
import Text, { TextTypesEnum } from '../elements/Text';
import Colors from '../styles/Colors';
import MinMaxFilter from './MinMaxFilter';

type ListingFilterInputSectionProps = {
    sectionTitle: string;
    inputType?: InputTypesEnum;
    minValue: number | string;
    maxValue: number | string;
    minLabel?: string;
    maxLabel?: string;
    setMinValue: (value: number | string) => void;
    setMaxValue: (value: number | string) => void;
  }
const ListingFilterInputSection: React.FC<ListingFilterInputSectionProps> = ({
  sectionTitle,
  inputType = InputTypesEnum.Number,
  minValue,
  maxValue,
  minLabel = 'Min',
  maxLabel = 'Max',
  setMinValue,
  setMaxValue,
}) => (
  <>
    <Text
      type={TextTypesEnum.Bold18}
      color={Colors.Grey900}
      margin="0 0 8px 0"
    >
      {sectionTitle}
    </Text>
    <MinMaxFilter
      minLabel={minLabel}
      maxLabel={maxLabel}
      inputType={inputType}
      minValue={minValue}
      maxValue={maxValue}
      onMinChange={(_event, value) => setMinValue(value)}
      onMaxChange={(_event, value) => setMaxValue(value)}
    />
  </>
);

export default ListingFilterInputSection;
