import Joi from 'joi';
import JoiUtil from '../utils/JoiUtil';

const location = Joi.object().keys({
  __typename: Joi.string().optional().allow(null, ''),
  type: Joi.string().required().allow(null),
  coordinates: Joi.array().items(Joi.string()).required(),
}).prefs({
  abortEarly: false,
});

const requiredLocation = Joi.object().keys({
  __typename: Joi.string().optional().allow(null, ''),
  type: Joi.string().required(),
  coordinates: Joi
    .array()
    .min(2)
    .items(
      Joi.string()
        .required()
        .error(([error]) => JoiUtil.encodeError('Required', error)),
    )
    .required()
    .error(([error]) => JoiUtil.encodeError('Required', error)),
}).prefs({
  abortEarly: false,
});

const addressSchema = Joi.object().keys({
  address1: Joi.string().optional().allow(null, ''),
  address2: Joi.string().optional().allow(null, ''),
  city: Joi.string().optional().allow(null, ''),
  state: Joi.string().optional().allow(null, ''),
  zip: Joi.string().optional().allow(null, ''),
  country: Joi.string().optional().allow(null, ''),
  location: location.optional(),
  googlePlaceId: Joi.string().optional().allow(null, ''),
  timeZoneId: Joi.string().optional().allow(null, ''),
  cherreId: Joi.string().optional().allow(null, ''),
  __typename: Joi.string().optional().allow(null, ''),
}).prefs({
  abortEarly: false,
});

export const requiredAddressSchema = Joi.object().keys({
  address1: Joi.string().required().error(([error]) => JoiUtil.encodeError('Street Required', error)),
  address2: Joi.string().optional().allow(null, ''),
  city: Joi.string().required().error(([error]) => JoiUtil.encodeError('City Required', error)),
  state: Joi.string().required().error(([error]) => JoiUtil.encodeError('State Required', error)),
  zip: Joi.string().required().error(([error]) => JoiUtil.encodeError('Zip Required', error)),
  country: Joi.string().required().error(([error]) => JoiUtil.encodeError('Country Required', error)),
  location: requiredLocation.required(),
  googlePlaceId: Joi.string().optional().allow(null, ''),
  timeZoneId: Joi.string().optional().allow(null, ''),
  cherreId: Joi.string().optional().allow(null, ''),
  __typename: Joi.string().optional().allow(null, ''),
}).prefs({
  abortEarly: false,
});

export default addressSchema;
