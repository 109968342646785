import React from 'react';
import styled from '@emotion/styled';
import Icon, { Icons } from './Icon';
import Colors from '../styles/Colors';

// $
const Container = styled.div`
  background-color: ${Colors.Grey100};
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    background-color: ${Colors.Grey300};
  }

  &:active {
    cursor: pointer;
    background-color: ${Colors.Grey400};
  }
`;

type BackButtonProps = {
  onClick?: () => void;
};

const BackButton: React.FC<BackButtonProps> = ({
  onClick,
}) => (
  <Container onClick={onClick ? () => onClick() : null}>
    <Icon icon={Icons.ArrowLeftRegular} color={Colors.Black} size={16} />
  </Container>
);

export default BackButton;
