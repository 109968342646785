import React from 'react';
import styled from '@emotion/styled';
import Colors, { ColorsType } from '../styles/Colors';
import Icon, { IconsType } from './Icon';
import BoxShadows from '../styles/BoxShadows';

type SquareProps = {
  margin?: string;
  size: string;
  backgroundColor: ColorsType;
  position: string;
  top?: string;
  left?: string;
}

const Square = styled.div<SquareProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  min-width: ${({ size }) => size};
  min-height: ${({ size }) => size};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border: 0.5px solid ${Colors.Grey300};
  margin: ${({ margin }) => margin};
  background: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
  box-sizing: border-box;

  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: ${({ left }) => left};

  &:hover {
    background: ${Colors.Grey200};
    box-shadow: ${BoxShadows.Standard};
    border: 0.5px solid ${Colors.Grey400};
  }

  &:active {
    background: ${Colors.Grey300};
  }
`;

type SquareIconButtonProps = {
  icon: IconsType,
  iconColor?: ColorsType,
  backgroundColor?: ColorsType,
  onClick: React.MouseEventHandler<HTMLDivElement>,
  margin?: string,
  tooltip?: string,
  size?: string;
  iconSize?: number;
  position?: string;
  left?: string;
  top?: string;
};

const SquareIconButton: React.FC<SquareIconButtonProps> = ({
  icon,
  iconColor = Colors.Grey900,
  backgroundColor = Colors.White,
  onClick,
  margin,
  tooltip,
  size,
  iconSize,
  position = 'relative',
  left,
  top,
}) => (
  <Square
    margin={margin}
    backgroundColor={backgroundColor}
    size={size || '32px'}
    onClick={onClick}
    data-tip={tooltip}
    position={position}
    top={top}
    left={left}
  >
    <Icon
      icon={icon}
      color={iconColor}
      size={iconSize || 14}
    />
  </Square>
);

export default SquareIconButton;
