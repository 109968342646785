import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import { useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import Colors from '../../styles/Colors';
import Divider from '../../elements/Divider';
import UserInfo, { UserInfoTypesEnum } from '../UserInfo';
import { media } from '../../utils/MediaQuery';
import UPDATE_LEAD from '../../graphql/mutations/updateLead.mutation';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import Input, { InputTypesEnum } from '../../elements/Input';
import LIST_LEADS from '../../graphql/queries/leads.query';
import { LeadActions } from '../../redux/lead.redux';

const Container = styled.div`
  width: 100%;
  height: 65vh;
  overflow: auto;
  padding-right: 24px;
  position: relative;

  ${media.mobile} {
    height: calc(100vh - 135px);
  }
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  right: 24px;
`;

export type LeadModalGuidanceProps = {
  lead: ILeadGraphQL;
};

const LeadModalGuidance: React.FC<LeadModalGuidanceProps> = ({
  lead,
}) => {
  /** State */
  const [guidance, setGuidance] = React.useState(lead?.guidance || 0);

  /* Actions */
  const dispatch = useAppDispatch();
  const cacheLead = (lead: ILeadGraphQL) => dispatch(LeadActions.recacheLead({ leadId: lead?._id }));
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /** GraphQL */
  const [updateLead, { loading }] = useMutation(UPDATE_LEAD, {
    onCompleted: ({ updateLead }) => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Lead guidance updated.',
      });
      cacheLead(updateLead);
    },
    onError: (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);

      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
    refetchQueries: [{
      query: LIST_LEADS,
      variables: {
        params: {
          listingId: lead.listingId,
          isInvestorMatches: false,
        },
      },
    }],
  });

  /** Render */
  return (
    <Container>
      <UserInfo user={lead.user} type={UserInfoTypesEnum.Large} links includePhone={Boolean(!lead?.isInvestorMatch)} />
      <Divider color={Colors.Grey200} margin="16px 0" />
      <Input
        label="Guidance"
        placeholder="Enter guidance"
        value={guidance?.toString() ?? ''}
        onChange={(_event, unmasked) => setGuidance(unmasked)}
        inputType={InputTypesEnum.Currency}
      />
      <Bottom>
        <Button
          text="Add guidance"
          isLoading={loading}
          type={ButtonTypesEnum.Primary}
          size={ButtonSizesEnum.Small}
          onClick={() => {
            updateLead({
              variables: {
                params: {
                  _id: lead?._id,
                  guidance,
                },
              },
            });
          }}
        />
      </Bottom>
    </Container>
  );
};

export default LeadModalGuidance;
