import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import Icon, { IconsType } from './Icon';
import Icons from './Icons';

export enum ExitButtonSizesEnum {
  Small = 'Small',
  Regular = 'Regular',
}

export enum ExitButtonTypesEnum {
  Dark = 'Dark',
}

type ExitIconContainerProps = {
  type: ExitButtonTypesEnum;
  buttonSize: string;
  position: string;
  right: string;
  top: string;
}

const ExitIconContainer = styled.div<ExitIconContainerProps>`
  position: ${(props) => props.position};
  height: ${(props) => props.buttonSize};
  width: ${(props) => props.buttonSize};
  display: flex;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  align-items: center;
  color: ${Colors.White};
  transition: all 0.1s;
  border-radius: 4px;
  z-index: 1000;

  &:hover {
    cursor: pointer;
    background-color: ${Colors.Grey900};
    color: ${Colors.White};
  }
`;

type ExitButtonProps = {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  size?: ExitButtonSizesEnum;
  type?: ExitButtonTypesEnum;
  icon?: IconsType;
  position?: string;
  right?: string;
  top?: string;
  color?: string;
  dataCy?: string;
}

const ExitButton: React.FC<ExitButtonProps> = ({
  onClick,
  size = ExitButtonSizesEnum.Regular,
  type = ExitButtonTypesEnum.Dark,
  icon = Icons.TimesRegular,
  position = 'absolute',
  right = '16px',
  top = '16px',
  color = 'inherit',
  dataCy,
}) => {
  const { buttonSize, iconSize } = (() => {
    switch (size) {
      case ExitButtonSizesEnum.Small:
        return {
          iconSize: 18,
          buttonSize: '24px',
        };
      case ExitButtonSizesEnum.Regular:
      default:
        return {
          iconSize: 24,
          buttonSize: '32px',
        };
    }
  })();
  /** Render */
  return (
    <ExitIconContainer
      onClick={onClick}
      type={type}
      buttonSize={buttonSize}
      position={position}
      right={right}
      top={top}
      data-cy={dataCy}
    >
      <Icon
        icon={icon}
        color={color}
        size={iconSize}
        width={buttonSize}
        height={buttonSize}
        transitionDuration="0.0s"
      />
    </ExitIconContainer>
  );
};

export default ExitButton;
