import React from 'react';
import styled from '@emotion/styled';
import { createSearchParams, useNavigate } from 'react-router-dom';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import {
  AuthenticationModal,
  AuthLoading,
  Content,
} from '../AuthenticationModalLayout';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Flex from '../../elements/Flex';
import { useAppDispatch } from '../../redux/store';
import Icon from '../../elements/Icon';
import Icons from '../../elements/Icons';
import { UserActions } from '../../redux/user.redux';
import * as UrlUtil from '../../utils/UrlUtil';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from './Modal';

const Circle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  background-color: ${Colors.Green200};
  border-radius: 50%;
`;

export type PhoneVerifiedModalProps = {
  isStandalone?: boolean;
};

const PhoneVerifiedModal: React.FC<PhoneVerifiedModalProps> = ({
  isStandalone = false,
}) => {
  /** State */
  const [redirectLoading, setRedirectLoading] = React.useState(false);
  const navigate = useNavigate();

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushQuickStartGuideModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.QuickStartGuideModal,
      props: {},
    }),
  );

  const completeAuthentication = () => dispatch(
    UserActions.completeAuthentication(),
  );

  const handleFinishRegister = () => {
    setRedirectLoading(true);
    completeAuthentication();
    // If not standalone, don't push any quickstart modal as
    // it may mess with requireAuthentication hook stuff.
    if (isStandalone) {
      const { query } = UrlUtil.parse(window.location.toString());
      const { to, ...rest } = query;
      if (to) {
        navigate({
          pathname: to as string,
          search: createSearchParams(rest).toString(),
        });
      } else {
        popModal();
        navigate('/app/dashboard/search');
        pushQuickStartGuideModal();
      }
    }
  };

  /** Render */
  return (
    <AuthenticationModal fullscreen={isStandalone} showExit={false}>
      {redirectLoading ? (
        <AuthLoading />
      ) : (
        <Content>
          <Flex flex="1" align="center" justify="center" direction="column" height="100%">
            <Circle>
              <Icon icon={Icons.CheckSolid} size={24} color={Colors.Green700} />
            </Circle>
            <Text data-cy="phone-verification-text" type={TextTypesEnum.Bold24} color={Colors.Black} margin="12px 0 0">
              Your phone number is verified
            </Text>
            <Text type={TextTypesEnum.Regular16} color={Colors.Grey700} margin="4px 0 0" align="center">
              Thanks for confirming that you&apos;re a human
            </Text>
          </Flex>
          <Button
            data-cy="continue-register-button"
            margin="32px 0 0"
            text="Continue"
            onClick={handleFinishRegister}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Large}
            isFullWidth
          />
        </Content>
      )}
    </AuthenticationModal>
  );
};

export default PhoneVerifiedModal;
