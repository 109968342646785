import gql from 'graphql-tag';

const UPDATE_LISTING = gql`
  mutation Mutation($where: ListingWhere, $update: ListingUpdateInput) {
    updateListings(where: $where, update: $update) {
      listings {
        _id
        metaLastUpdatedBy
        name
      }
    }
  }
`;

export default UPDATE_LISTING;
