import React from 'react';
import styled from '@emotion/styled';
import usePagination, { Ellipses } from '../hooks/usePagination.hook';
import Flex from './Flex';
import Text, { TextTypesEnum } from './Text';
import Colors from '../styles/Colors';
import Icon from './Icon';
import Icons from './Icons';

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 40px 24px 24px 24px;
  justify-content: center;
`;

type PageNumberProps = {
    active: boolean;
}

const PageNumber = styled.div<PageNumberProps>`
    background-color: ${({ active }) => (active ? Colors.Brand100 || Colors.Blurple100 : null)};
    border-radius: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

enum PaginationDirectionEnum {
    Previous = 'Previous',
    Next = 'Next',
}

type PaginationProps = {
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setPaginationOffset?: React.Dispatch<React.SetStateAction<number>>;
    paginationOffset?: number;
    totalCount: number;
    siblingCount?: number;
    currentPage: number;
    maxVisibleData: number;
};

const Pagination: React.FC<PaginationProps> = ({
  setCurrentPage,
  setPaginationOffset,
  paginationOffset,
  totalCount,
  siblingCount,
  currentPage,
  maxVisibleData,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    maxVisibleData,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const handlePaginationClick = (direction: PaginationDirectionEnum) => {
    const newPage = direction === PaginationDirectionEnum.Previous ? currentPage - 1 : currentPage + 1;
    setCurrentPage(newPage);
    if (direction === PaginationDirectionEnum.Previous) {
      setPaginationOffset?.(paginationOffset - maxVisibleData);
    } else {
      setPaginationOffset?.(paginationOffset + maxVisibleData);
    }
  };

  const handlePageNumberClick = (newPageNumber: number) => {
    setPaginationOffset?.((newPageNumber - 1) * maxVisibleData);
    setCurrentPage(newPageNumber);
  };

  const isFirstPage = currentPage === 1;
  const isFinalPage = paginationRange[paginationRange.length - 1] === currentPage;

  return (
    <PaginationContainer data-cy="pagination-container">
      <Flex align="center">
        <Text
          type={TextTypesEnum.Regular12}
          color={Colors.Grey700}
          margin="0 16px 0 0"
        >
          Showing
          {' '}
          {(currentPage - 1) * maxVisibleData + 1}
          {' '}
          -
          {' '}
          {isFinalPage ? totalCount : currentPage * maxVisibleData}
          {' '}
          of
          {' '}
          {totalCount}
        </Text>
        <Flex align="center">
          <Icon
            icon={Icons.AngleLeftRegular}
            margin="0 8px 0 0"
            color={isFirstPage ? Colors.Grey500 : Colors.Brand700 || Colors.Blurple700}
            onClick={isFirstPage ? null : () => handlePaginationClick(PaginationDirectionEnum.Previous)}
          />
          {paginationRange.map((pageNumber: any) => {
            const isActive = pageNumber === currentPage;
            return (
              <PageNumber key={pageNumber} active={isActive}>
                <Text
                  key={pageNumber}
                  type={TextTypesEnum.Regular12}
                  color={isActive ? Colors.Grey900 : Colors.Grey700}
                  onClick={pageNumber === Ellipses ? null : () => handlePageNumberClick(pageNumber)}
                >
                  {pageNumber}
                </Text>
              </PageNumber>
            );
          })}
          <Icon
            icon={Icons.AngleRightRegular}
            margin="0 0 0 8px"
            color={isFinalPage ? Colors.Grey500 : Colors.Brand700 || Colors.Blurple700}
            onClick={isFinalPage ? null : () => handlePaginationClick(PaginationDirectionEnum.Next)}
          />
        </Flex>
      </Flex>
    </PaginationContainer>
  );
};

export default Pagination;
