import ListingInfoFieldNamesEnum from '../enums/ListingInfoFieldNamesEnum';
import EconomicScaleEnum from '../enums/info-fields/EconomicScaleEnum';
import OwnershipEnum from '../enums/info-fields/OwnershipEnum';
import BuildingClassEnum from '../enums/info-fields/BuildingClassEnum';
import LocationTypeEnum from '../enums/info-fields/LocationTypeEnum';
import DefaultStatusEnum from '../enums/info-fields/DefaultStatusEnum';
import LeinPositionEnum from '../enums/info-fields/LeinPositionEnum';
import ParkingLotTypeEnum from '../enums/info-fields/ParkingLotTypeEnum';
import ChildcareTenancyEnum from '../enums/info-fields/ChildcareTenancyEnum';
import CommunityTypeEnum from '../enums/info-fields/CommunityTypeEnum';
import { unitBreakdownForMultiFamilyState } from '../types/TUnitBreakdownForMultiFamily';
import { unitBreakdownForMobileHomeState } from '../types/TUnitBreakdownForMobileHome';
import { unitBreakdownForSelfStorageState } from '../types/TUnitBreakdownForSelfStorage';

const listingInfoFieldsState = {
  [ListingInfoFieldNamesEnum.YearBuilt]: null, // Date
  [ListingInfoFieldNamesEnum.YearRenovated]: null, // Date
  [ListingInfoFieldNamesEnum.BuildingSqFt]: null, // Number
  [ListingInfoFieldNamesEnum.LotSize]: null, // Number
  [ListingInfoFieldNamesEnum.NumberofUnitsSpaces]: null, // Number
  [ListingInfoFieldNamesEnum.Occupancy]: null, // Number
  [ListingInfoFieldNamesEnum.Anchored]: false,
  [ListingInfoFieldNamesEnum.ClearHeight]: null, // Number
  [ListingInfoFieldNamesEnum.GradeLevelDoors]: null, // Number
  [ListingInfoFieldNamesEnum.LoadingDocks]: null, // Number
  [ListingInfoFieldNamesEnum.ADR]: null, // Number
  [ListingInfoFieldNamesEnum.EconomicScale]: EconomicScaleEnum.Economy,
  [ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily]: unitBreakdownForMultiFamilyState(),
  [ListingInfoFieldNamesEnum.ParkingSpaces]: null, // Number
  [ListingInfoFieldNamesEnum.NumberofUnits]: null, // Number
  [ListingInfoFieldNamesEnum.UnitPriceFor10X10]: null, // Number
  [ListingInfoFieldNamesEnum.NumberOfFloors]: null, // Number
  [ListingInfoFieldNamesEnum.BoatRVStorage]: false,
  [ListingInfoFieldNamesEnum.NumberofRooms]: null, // Number
  [ListingInfoFieldNamesEnum.AgeRestriction]: false,
  [ListingInfoFieldNamesEnum.UnitBreakdownForSelfStorage]: unitBreakdownForSelfStorageState(),
  [ListingInfoFieldNamesEnum.TenantName]: '',
  [ListingInfoFieldNamesEnum.Credit]: false,
  [ListingInfoFieldNamesEnum.Guarantor]: '',
  [ListingInfoFieldNamesEnum.AnnualRent]: null, // Number
  [ListingInfoFieldNamesEnum.Ownership]: OwnershipEnum.FeeSimple,
  [ListingInfoFieldNamesEnum.LeaseExpiration]: null, // Date
  [ListingInfoFieldNamesEnum.NumberofLeaseRenewals]: null, // Number
  [ListingInfoFieldNamesEnum.YearsPerRenewalOption]: null, // Number
  [ListingInfoFieldNamesEnum.BuildingClass]: BuildingClassEnum.ClassA,
  [ListingInfoFieldNamesEnum.NumberofTenants]: null, // Number
  [ListingInfoFieldNamesEnum.OnCampus]: false,
  [ListingInfoFieldNamesEnum.WeightedAverageLeaseTerm]: null, // Number
  [ListingInfoFieldNamesEnum.RevPAR]: null, // Number
  [ListingInfoFieldNamesEnum.CreditRatingAgency]: '',
  [ListingInfoFieldNamesEnum.CreditRating]: '',
  [ListingInfoFieldNamesEnum.LeaseCommencementDate]: null, // Date
  [ListingInfoFieldNamesEnum.RemainingTotalTerm]: null, // Number
  [ListingInfoFieldNamesEnum.KickOutClause]: null, // Number
  [ListingInfoFieldNamesEnum.AnnualRentIncrease]: null, // Number
  [ListingInfoFieldNamesEnum.DevelopableLand]: false,
  [ListingInfoFieldNamesEnum.Zoning]: '',
  [ListingInfoFieldNamesEnum.SIC]: null, // Number
  [ListingInfoFieldNamesEnum.NAICS]: null, // Number
  [ListingInfoFieldNamesEnum.ConservationEasement]: false,
  [ListingInfoFieldNamesEnum.EnvironmentalSurvey]: false,
  [ListingInfoFieldNamesEnum.LocationType]: LocationTypeEnum.Urban,
  [ListingInfoFieldNamesEnum.DefaultStatus]: DefaultStatusEnum.Days90,
  [ListingInfoFieldNamesEnum.DefaultInterest]: null, // Number
  [ListingInfoFieldNamesEnum.AccruedInterestAmount]: null, // Number
  [ListingInfoFieldNamesEnum.InterestRate]: null, // Number
  [ListingInfoFieldNamesEnum.LeinPosition]: LeinPositionEnum.First,
  [ListingInfoFieldNamesEnum.AssetClass]: '',
  [ListingInfoFieldNamesEnum.UPB]: null, // Number
  [ListingInfoFieldNamesEnum.AmortizationRate]: null, // Number
  [ListingInfoFieldNamesEnum.MaturityDate]: null, // Date
  [ListingInfoFieldNamesEnum.NumberofPads]: null, // Number
  [ListingInfoFieldNamesEnum.Type]: ParkingLotTypeEnum.ExteriorOpen,
  [ListingInfoFieldNamesEnum.NumberofSpaces]: null, // Number
  [ListingInfoFieldNamesEnum.NumberofLevels]: null, // Number
  [ListingInfoFieldNamesEnum.OperatingForChildcare]: false,
  [ListingInfoFieldNamesEnum.Tenancy]: ChildcareTenancyEnum.Single,
  [ListingInfoFieldNamesEnum.CeilingHeight]: null, // Number
  [ListingInfoFieldNamesEnum.LabToOfficeSqFtRatio]: null, // Number
  [ListingInfoFieldNamesEnum.TotalSqFt]: null, // Number
  [ListingInfoFieldNamesEnum.FeeSimple]: false,
  [ListingInfoFieldNamesEnum.CommunityType]: CommunityTypeEnum.AllAges,
  [ListingInfoFieldNamesEnum.AverageRentPerPad]: null, // Number
  [ListingInfoFieldNamesEnum.WaterPaidbyPark]: false,
  [ListingInfoFieldNamesEnum.ElectricBilledDirect]: false,
  [ListingInfoFieldNamesEnum.UnitBreakdownForMobileHome]: unitBreakdownForMobileHomeState(),
  [ListingInfoFieldNamesEnum.RedevelopmentOpportunity]: false,
  [ListingInfoFieldNamesEnum.Operating]: false,
  [ListingInfoFieldNamesEnum.AnchorTenant]: '',
  [ListingInfoFieldNamesEnum.AnchorTenantCredit]: '',
  [ListingInfoFieldNamesEnum.AdditionalStructuralCapacity]: false,
  [ListingInfoFieldNamesEnum.AnchoredTenantLeaseExpiration]: null, // Date
  [ListingInfoFieldNamesEnum.MaximumBoatSize]: null, // Number
  [ListingInfoFieldNamesEnum.NumberofWetSlips]: null, // Number
  [ListingInfoFieldNamesEnum.NumberofDryRacks]: null, // Number
  [ListingInfoFieldNamesEnum.NetLease]: false,
};

export default listingInfoFieldsState;
