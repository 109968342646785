import gql from 'graphql-tag';

const typeDefs = gql`

  type User {
    _id: ID!
    firstName: String!
    lastName: String!
    email: String
    ipAddress: String
    metaCreatedAt: Float
    metaCreatedBy: String
    metaLastUpdatedAt: Float
    metaLastUpdatedBy: String
    passwordHash: String
    phoneNumber: String
    role: String
    biproxiRoles: [String]
    biproxiPermissions: [String]
    organization: [Organization!]! @relationship(type: "MEMBER", properties: "Permissions", direction: OUT)
    listings: [Listing!]! @relationship(type: "OWNS", properties: "Permissions" direction: OUT)
  }

  input CreateUserInput {
    firstName: String!
    lastName: String!
    email: String
    phoneNumber: String
  }

  type Listing {
    _id: ID!
    name: String!
    assetClass: String!
    propertyType: String!
    description: String
    isPortfolioListing: Boolean
    isPrivateListing: Boolean
    state: String
    address1: String
    address2: String
    cherreId: String
    city: String
    country: String
    googlePlaceId: String
    locationCoordinates: [String]
    locationType: String
    addressState: String
    timeZoneId: String
    zip: String
    mediaFileIds: [String]
    mediaVideoFileId: String
    mediaVideoThumbnailFileId: String
    metaCreatedAt: Float
    metaCreatedBy: String
    metaLastPublishedAt: Float
    metaLastUpdatedAt: Float
    metaLastUpdatedBy: String
    createdByUser: [User!]! @relationship(type: "OWNS", properties: "Permissions", direction: IN)
    belongsToOrganization: [Organization!]! @relationship(type: "OWNS", properties: "Permissions", direction: IN)
  }

  type Organization {
    _id: ID!
    users: [User!]! @relationship(type: "MEMBER", properties: "Permissions",  direction: IN)
    active: Boolean
    name: String
    description: String
    message: String
    phoneNumber: String
    email: String
    logoUrl: String
    posterUrl: String
    ein: String
    headerImageUrl: String
    footerImageUrl: String
    signatureImageUrl: String
    signatureText: String
    signaturePosition: String
    presetDonations: [Int]
    metaCreatedAt: Float
    listings: [Listing!]! @relationship(type: "OWNS", properties: "Permissions" direction: OUT)
    organization: [Organization!]! @relationship(type: "HAS_ORG", properties: "Permissions", direction: IN)
  }

  interface Permissions @relationshipProperties {
    permissions: [String!]
    roles: [String!]
    metaConnectedAt: Float
  }

`;

export default typeDefs;
