import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import IFileParams, {
  GraphQLFileUpload,
} from '@biproxi/models/interfaces/IFileParams';
import IFile from '@biproxi/models/interfaces/IFile';
import { useDropzone } from 'react-dropzone';
import Image from 'next/image';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import Colors from '../../styles/Colors';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import UPDATE_BASIC_USER_INFO from '../../graphql/mutations/updateBasicUserInformation.mutation';
import GET_USER from '../../graphql/queries/user.query';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import Button, {
  ButtonSizesEnum,
  ButtonTypesEnum,
} from '../../elements/Button';
import CREATE_FILES from '../../graphql/mutations/createFiles.mutation';
import Icon, { Icons } from '../../elements/Icon';
import Text, { TextTypesEnum } from '../../elements/Text';
import Loader, { LoaderSizes } from '../../elements/Loader';
import useUser from '../../hooks/useUser.hook';
import { media } from '../../utils/MediaQuery';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { UserActions } from '../../redux/user.redux';

const ImageUploadContainer = styled.div`
  height: 352px;
  width: 352px;
  position: relative;
  cursor: pointer;
  border: 2px dashed ${Colors.Grey300};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.mobile} {
    height: 300px;
    width: 100%;
  }
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  position: static;
`;

export type ChangeProfilePictureModalProps = {};

const ChangeProfilePictureModal: React.FC<ChangeProfilePictureModalProps> = () => {
  /** Hooks */
  const { user } = useUser();

  /** State */
  const [uploadedImage, setUploadedImage] = React.useState<IFile | null>(null);

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );
  const setUserField = (fields: Partial<IUserGraphQL>) => dispatch(
    UserActions.setUserField(fields),
  );

  /** GraphQl */
  const [updateBasicUserInfoMutation, { loading: saveLoading }] = useMutation(UPDATE_BASIC_USER_INFO, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_USER }],
    variables: {
      params: {
        firstName: user.firstName,
        lastName: user.lastName,
        organizationName: user.organizationName,
        role: user.role,
        address: user.address,
        profileImageFileId: uploadedImage?._id ?? '',
      },
    },
    onCompleted: async () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Profile image successfully updated',
      });
      setUserField({ profileImageFile: uploadedImage });
      popModal();
    },
    onError: async (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);
      logger.error(message);
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error updated your profile image. Please try again or contact support.',
      });
    },
  });

  const [uploadFiles, { loading }] = useMutation(CREATE_FILES, {
    onCompleted: async (data) => {
      const files = data.createFiles;
      setUploadedImage(files[0]);
    },
  });

  const onDrop: any = (browserFiles: GraphQLFileUpload[]) => {
    if (loading) return;
    const fileParams: IFileParams[] = browserFiles.map(
      (browserFile: GraphQLFileUpload) => ({
        name: browserFile.name,
        description: browserFile.name,
        mimetype: browserFile.type,
        encoding: 'utf8',
        upload: browserFile,
      }),
    );
    uploadFiles({
      variables: {
        params: fileParams,
      },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  });

  /* Render */
  return (
    <ModalContainer width="400px" height="536px">
      <ModalHeader title="Change profile picture" close={popModal} />
      <ModalContent>
        {(() => {
          if (uploadedImage) {
            return (
              <Image
                height={352}
                width={352}
                alt="uploaded-image"
                src={uploadedImage?.url}
                style={{
                  objectFit: 'cover',
                }}
              />
            );
          }

          return (
            <ImageUploadContainer {...getRootProps()}>
              <input {...getInputProps()} />
              {loading ? (
                <>
                  <Loader size={LoaderSizes.Medium} color={Colors.Brand700 || Colors.Blurple700} />
                  <Text
                    type={TextTypesEnum.Regular14}
                    color={Colors.Black}
                    margin="8px 0 0"
                  >
                    Uploading...
                  </Text>
                </>
              ) : (
                <>
                  <Icon
                    icon={Icons.ImageLight}
                    color={Colors.Grey700}
                    size={40}
                    margin="0 0 8px 0"
                  />
                  <Text type={TextTypesEnum.Medium12} color={Colors.Grey500}>
                    Drag photo(s) here or
                  </Text>
                  <Text type={TextTypesEnum.Medium12} color={Colors.Brand700 || Colors.Blurple700}>
                    click to upload
                  </Text>
                </>
              )}
            </ImageUploadContainer>
          );
        })()}
      </ModalContent>
      <ModalFooter>
        <ModalButtonsContainer>
          <Button
            type={ButtonTypesEnum.Outline}
            margin="0 5px 0 0"
            size={ButtonSizesEnum.Medium}
            text="Cancel"
            onClick={popModal}
          />
          <Button
            size={ButtonSizesEnum.Medium}
            margin="0 0 0 5px"
            text="Save"
            isLoading={saveLoading}
            onClick={updateBasicUserInfoMutation}
            disabled={loading}
          />
        </ModalButtonsContainer>
      </ModalFooter>
    </ModalContainer>
  );
};

export default ChangeProfilePictureModal;
