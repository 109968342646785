import React, { useCallback } from 'react';
import { GraphQLFileUpload } from '@biproxi/models/interfaces/IFileParams';
import { useDropzone } from 'react-dropzone';
import CSVUtil from '@biproxi/models/utils/CSVUtil';
import styled from '@emotion/styled';
import StringUtil from '@biproxi/models/utils/StringUtil';
import { NylasProviderPerEmailLimitsEnum } from '@biproxi/models/enums/NylasProviderLimitsEnums';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { KB_NYLAS_URL } from '@biproxi/models/externalLinks';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Text, { TextTypesEnum } from '../../elements/Text';
import { ModalTypesEnum } from './Modal';
import LinkText from '../../elements/LinkText';
import Dropdown from '../../elements/Dropdown';
import Colors from '../../styles/Colors';
import Icons from '../../elements/Icons';
import Icon from '../../elements/Icon';
import Flex from '../../elements/Flex';
import Link from '../../elements/Link';
import Input, { InputTypesEnum } from '../../elements/Input';
import useNylas from '../../hooks/useNylas.hook';
import Divider from '../../elements/Divider';

const FlexWithButton = styled.div`
  display: flex;
  align-items: center;
  width: fill-available;
  background-color: ${Colors.Grey100};
  padding: 0px 6px;
  border-radius: 4px;
  margin: 16px auto;

  &:hover {
    background-color: ${Colors.Grey200};
    cursor: pointer;
  }
`;

const ButtonAlignRight = styled.div`
  margin-left: auto;
`;

const ContactsOverflowContainer = styled.div`
  overflow-y: auto;
  max-height: 250px;
  margin: 0px 0px 16px 0px;
`;

const RateLimitLinkWrapper = styled.div`
  margin: 0px 0px 16px 0px;
`;

export type SelectContactsModalProps = {
  listing: IListingGraphQL;
};

const SelectContactsModal: React.FC<SelectContactsModalProps> = ({ listing }) => {
  //! State
  const [contacts, setContacts] = React.useState<string[]>([]);
  /** upload emails via CSV file */
  const [fileName, setFileName] = React.useState<string>('');
  const [contactsData, setContactsData] = React.useState<object>(null);
  const [columnChoice, setColumnChoice] = React.useState<string>('');
  /** manually enter emails */
  const [isManualUpload, setIsManualUpload] = React.useState<boolean>(false);
  const [manualEmails, setManualEmails] = React.useState<string>('');
  const [manualEmailsError, setManualEmailsError] = React.useState<string>('');
  /** rate limit handling */
  const [rateLimitError, setRateLimitError] = React.useState<string>('');

  //! Hooks
  const onDrop: any = useCallback(async (browserFiles: GraphQLFileUpload[]) => {
    /** If file not csv, return */
    if (!browserFiles.length) return;

    await CSVUtil.parseContactList(browserFiles[0], setContactsData);
    setFileName(browserFiles[0].name);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv',
  });

  const { nylasProvider, dailyEmailLimit } = useNylas();

  //! Actions
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(AppActions.popModal());

  const pushEmailPreviewModal = () => {
    /** light validation on manually uploaded emails */
    let formattedManualEmails;
    if (isManualUpload) {
      formattedManualEmails = StringUtil.validateEmailList(manualEmails);
      if (formattedManualEmails.error) {
        setManualEmailsError(formattedManualEmails.error);
        return;
      }
    }

    popModal();

    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.EmailPreview,
        props: {
          listing,
          contacts: isManualUpload ? formattedManualEmails.emails : contacts,
        },
      }),
    );
  };

  //! Effects
  React.useEffect(() => {
    if (contacts.length > NylasProviderPerEmailLimitsEnum[nylasProvider]) {
      setRateLimitError(`Please ensure your contact list does not exceed ${NylasProviderPerEmailLimitsEnum[nylasProvider]} recipients.`);
    } else if (contacts.length > dailyEmailLimit) {
      setRateLimitError(`Sending this email would exceed your daily limit of ${dailyEmailLimit} emails.`);
    } else {
      setRateLimitError('');
    }
  }, [contacts]);

  //! Render
  return (
    <ModalContainer width="424px">
      <ModalHeader title="Select contacts" close={popModal} />
      <ModalContent>
        <Text>
          {`Share this listing with up to ${dailyEmailLimit < NylasProviderPerEmailLimitsEnum[nylasProvider] ? dailyEmailLimit : NylasProviderPerEmailLimitsEnum[nylasProvider]} contacts. You can send to up to ${dailyEmailLimit} more contacts today.`}
        </Text>
        <RateLimitLinkWrapper>
          <Link href={KB_NYLAS_URL} target="_blank">
            Learn more.
          </Link>
        </RateLimitLinkWrapper>
        {rateLimitError && (
          <Text margin="0px 0px 16px 0px" color={Colors.Red500}>{rateLimitError}</Text>
        )}
        {!isManualUpload ? (
          <>
            {!contactsData ? (
              <>
                <Text>
                  Select a CSV file.
                </Text>
                <Button
                  text="Select file"
                  margin="16px 0px"
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Medium}
                  {...getRootProps()}
                />
                <input {...getInputProps()} />
                <Text>
                  or&nbsp;
                  <LinkText
                    type={TextTypesEnum.Regular16}
                    onClick={() => {
                      setIsManualUpload(true);
                      setRateLimitError('');
                      setColumnChoice('');
                      setContacts([]);
                    }}
                  >
                    Add multiple contacts
                  </LinkText>
              &nbsp;instead
                </Text>
                <Divider margin="16px 0px" />
              </>
            ) : (
              <>
                <Text>
                  Select emails from your CSV file.
                </Text>
                <FlexWithButton
                  onClick={
                () => {
                  setContactsData(null);
                  setFileName(null);
                  setContacts([]);
                  setRateLimitError('');
                }
              }
                >
                  <Text type={TextTypesEnum.Regular14}>{fileName}</Text>
                  <ButtonAlignRight>
                    <Icon
                      icon={Icons.TimesRegular}
                      color={Colors.Grey600}
                      size={12}
                      width="16px"
                      height="16px"
                    />
                  </ButtonAlignRight>
                </FlexWithButton>
                <Dropdown
                  label="Select column"
                  placeholder="Column"
                  width="fill-available"
                  margin={contacts ? '0px 0px 4px 0px' : '16px 0px'}
                  items={Object.keys(contactsData).map((key: string) => ({
                    name: key,
                    value: key,
                  }))}
                  value={columnChoice}
                  onChange={(value) => {
                    setContacts(contactsData[value]);
                    setColumnChoice(value);
                  }}
                />
                {contacts && (
                <ContactsOverflowContainer>
                    {contacts.map((contact: string, index: number) => (
                      <FlexWithButton
                        key={index}
                        onClick={() => {
                          setContacts(contacts.filter((_contact: string, _index: number) => _index !== index));
                        }}
                      >
                        <Text type={TextTypesEnum.Regular14}>{contact}</Text>
                        <ButtonAlignRight>
                          <Icon
                            icon={Icons.TimesRegular}
                            color={Colors.Grey600}
                            size={12}
                            width="16px"
                            height="16px"
                          />
                        </ButtonAlignRight>
                      </FlexWithButton>
                    ))}
                </ContactsOverflowContainer>
                )}
                <Text>
                  or&nbsp;
                  <LinkText
                    type={TextTypesEnum.Regular16}
                    onClick={() => {
                      setIsManualUpload(true);
                      setColumnChoice('');
                      setContacts([]);
                    }}
                  >
                    Add multiple contacts
                  </LinkText>
              &nbsp;instead
                </Text>
                <Divider margin="16px 0px 0px 0px" />
              </>
            )}
          </>
        ) : (
          <>
            <Input
              label="Add comma-separated email addresses"
              placeholder="Email addresses"
              inputType={InputTypesEnum.Text}
              value={manualEmails}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setManualEmails(e.target.value);
                setManualEmailsError(null);
              }}
              margin="8px 0px 0"
            />
            {manualEmailsError && (
            <Text type={TextTypesEnum.Medium12} color={Colors.Red500} margin="4px 0px 0px 0px">{manualEmailsError}</Text>
            )}
            <Text margin="16px 0px 0px 0px">
              or&nbsp;
              <LinkText
                type={TextTypesEnum.Regular16}
                onClick={() => {
                  setIsManualUpload(false);
                  setManualEmailsError(null);
                  setManualEmails('');
                }}
              >
                Select a CSV file
              </LinkText>
      &nbsp;instead
            </Text>
            <Divider margin="16px 0px 0px 0px" />
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <Flex justify="flex-end" width="100%">
          <Button
            text="Cancel"
            margin="0px 8px 0px 0px"
            type={ButtonTypesEnum.Outline}
            size={ButtonSizesEnum.Medium}
            onClick={popModal}
          />
          <Button
            text={contacts || isManualUpload ? 'Add contacts' : 'Upload file'}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            onClick={() => pushEmailPreviewModal()}
            disabled={(!isManualUpload && !contacts.length) || (isManualUpload && !manualEmails) || Boolean(rateLimitError)}
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default SelectContactsModal;
