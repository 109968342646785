import React from 'react';
import styled from '@emotion/styled';
import Image from 'next/image';
import IAddress from '@biproxi/models/interfaces/IAddress';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useLocation } from 'react-router-dom';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import Skeleton from 'react-loading-skeleton';
import { logger } from '@biproxi/logger';
import { KB_BASE_URL } from '@biproxi/models/externalLinks';
// import BiproxiPlatformRolesEnum from '@biproxi/models/enums/Neo4jRolesEnums';
import { tacklebox } from '@biproxi/tacklebox';
import PermissionsUtil from '@biproxi/models/utils/PermissionsUtil';
import * as UrlUtil from '../utils/UrlUtil';
import Button, { ButtonTypesEnum, ButtonSizesEnum } from '../elements/Button';
import Colors from '../styles/Colors';
import { AppState, useAppDispatch, useAppSelector } from '../redux/store';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from './modal/Modal';
import BoxShadows from '../styles/BoxShadows';
import Flex from '../elements/Flex';
import { media, useMobileMedia, useTabletMedia } from '../utils/MediaQuery';
import Icon, { Icons } from '../elements/Icon';
import AnchoredMenu from '../elements/AnchoredMenu';
import UserInfo from './UserInfo';
import Auth from '../utils/Auth';
import AutoCompleteAddress from './AutoCompleteAddress';
import { ListingActions, ListingSelectors } from '../redux/listing.redux';
import { InputSizeEnum, InputThemeEnum } from '../elements/Input';
import Divider, { DividerTypesEnum } from '../elements/Divider';
import useRequireAuthentication from '../hooks/useRequireAuthentication.hook';
import useCreateListing from '../hooks/useCreateListing.hook';
import NotificationsListDropdown from './NotificationsListDropdown';
import useUser from '../hooks/useUser.hook';
import Text, { TextTypesEnum } from '../elements/Text';
import BiproxiIconEnum from '../models/enums/BiproxiIconEnum';
import useNotification from '../hooks/useNotification.hook';
import useListing from '../hooks/useListing.hook';
import useHandleRouteChange from '../hooks/useHandleRouteChange.hook';
import { ContentfulActions } from '../redux/contentful.redux';
import useUserPermissions, { Neo4jReducerStatusEnum } from '../hooks/useUserPermissions.hook';

interface ContainerProps {
  mobileSearchOpen?: boolean;
  isActivePDP?: boolean;
  isPreviewPDP?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: ${({ isActivePDP, isPreviewPDP }) => (isActivePDP && !isPreviewPDP ? 'relative' : 'fixed')};
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  background-color: ${Colors.White};
  box-shadow: ${({ mobileSearchOpen }) => (mobileSearchOpen ? null : `${BoxShadows.Standard}`)};
  box-sizing: border-box;
  justify-content: space-between;
  z-index: 3;
  ${media.print}{
      display: none;
  };
`;

const Hover = styled.div`
  cursor: pointer;
  background-color: ${Colors.BrandNavLogo || Colors.Grey900};
  height: 56px;
`;

interface OuterContainerProps {
  isActivePDP?: boolean;
  isPreviewPDP?: boolean;
}

const SubscriptionLevelBox = styled.div<{ bgColor: string; margin: string }>`
  background: ${({ bgColor }) => bgColor};
  border-radius: 4px;
  padding: 8px 12px;
  margin: ${({ margin }) => margin};
  cursor: pointer;
`;

const OuterContainer = styled.div<OuterContainerProps>`
  position: ${({ isActivePDP, isPreviewPDP }) => (isActivePDP && !isPreviewPDP ? 'static' : 'relative')};
  height: 56px;
  z-index: 210;
`;

const Space = styled.div`
  width: 12px;
`;

const UserProfileImageContainer = styled.div<{ profileUrl?: string, ['data-cy']?: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 56px;
  cursor: pointer;
  background-image: ${({ profileUrl }) => (profileUrl ? `url(${profileUrl})` : null)};
  background-color: ${({ profileUrl }) => (profileUrl ? 'transparent' : Colors.Grey900)};
  background-size: cover;
  background-position: center;
  background-origin: unset;
  data-cy: ${(props) => props?.['data-cy'] || 'user-profile-nav'};
`;

const MenuProfileContainer = styled.div`
  height: 64px;
  border-bottom: 1px solid ${Colors.Grey100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MobileSearchContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 56px;
  left: 0;
  padding: 12px;
  box-sizing: border-box;
  background: ${Colors.White};
  box-shadow: ${BoxShadows.Standard};
  border-bottom: 1px solid ${Colors.Grey300};
  z-index: 209;
`;

const MobileSearchIconContainer = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  margin-left: 8px;
  transition: all 0.2s;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${Colors.Grey900};
  position: relative;

  &:hover {
    color: ${Colors.White};
    background: ${Colors.Brand700 || Colors.Blurple700};
  }
`;

const NotificationDot = styled.div`
  width: 8px;
  height: 8px;
  background: ${Colors.Red500};
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 6px;
`;

type MainNavigationProps = {
  isActivePDP?: boolean
  isPreviewPDP?: boolean
};

const SkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const MainNavigation: React.FC<MainNavigationProps> = ({
  isActivePDP, isPreviewPDP,
}) => {
  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */
  /** Contentful - Redux */
  /** Contentful - Dispatch */
  const setCollection = (colors, companyMediaAssets, companyName, cornerLogoUrl) => dispatch(
    ContentfulActions.setContentfulCollection({
      colors, companyMediaAssets, companyName, cornerLogoUrl,
    }),
  );
  const setContentfulFetch = (fetched: boolean) => dispatch(
    ContentfulActions.setContentfulFetch({
      fetched,
    }),
  );

  /** Contentful - Selectors */
  const {
    contentful: {
      brandCollection: {
        companyMediaAssets,
        /** this boolean is for the client */
        fetched,
      },
    },
  } = useAppSelector((state: AppState) => state);
  const contentful = useAppSelector((state: AppState) => state.contentful);

  let dashboardLogo = companyMediaAssets.navigationDesktopLogo.url || companyMediaAssets.navigationDesktopLogo;

  // loading contentful stuff
  const [loadingDashboardLogo, setLoadingDashboardLogo] = React.useState(true);
  const [loadingUserColor, setLoadingUserColor] = React.useState(true);

  // !! We only refetch here because this component is rendered everywhere, so kinda as a parent
  // !! Component, then treating every component that needs contentful as a dummy reader
  // !! This also includes pages like the Slug page
  const refetchBrandCollections = async () => {
    try {
      const {
        colors,
        name,
        favicon,
        cornerLoginLogo,
        lockedListingImage,
        marketingLoginPhoto,
        navigationDesktopLogo,
        navigationMobileLogo,
        cornerLogoUrl,
      } = await tacklebox.getBrandForHostname();

      /** We store like this for redux */
      const mediaAssets = {
        favicon,
        cornerLoginLogo,
        lockedListingImage,
        marketingLoginPhoto,
        navigationDesktopLogo,
        navigationMobileLogo,
      };
      setCollection(colors, mediaAssets, name, cornerLogoUrl);
      setContentfulFetch(true);
    } catch (e) {
      logger.info(e);
      setContentfulFetch(true);
      setLoadingUserColor(false);
      setLoadingDashboardLogo(false);
    }
  };

  React.useEffect(() => {
    /** if the data is fetched */
    if (!fetched) refetchBrandCollections();
    if (fetched) {
      setLoadingUserColor(false);
      setLoadingDashboardLogo(false);
    }
  }, [fetched]);

  React.useEffect(() => {
    /** Not fetched, run the refetch */
    if (!fetched) {
      refetchBrandCollections();
      dashboardLogo = contentful.brandCollection?.companyMediaAssets?.navigationDesktopLogo?.url;
    }
  }, []);

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  /* State */
  const [mobileListingSearchIsVisible, setMobileListingSearchIsVisible] = React.useState(false);
  const userProfileAnchorRef = React.useRef(null);
  const notificationsDropdownRef = React.useRef(null);

  const { pathname } = UrlUtil.parse(window?.location.href);

  // Hide the top nav on auth pages
  const hideNavRoutes = [
    '/login',
    '/register',
    '/reset-password',
    '/app/login',
    '/app/register',
    '/app/reset-password',
  ];

  const hideNavigation = hideNavRoutes.includes(pathname);

  /** Hooks */
  const { user, userId } = useUser();
  const userPermissions = useUserPermissions({ userId });
  const { listing } = useListing();
  const createListing = useCreateListing();
  const requireAuthentication = useRequireAuthentication();
  const handleRouteChange = useHandleRouteChange();

  // const queryClient = useQueryClient();

  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();
  useLocation(); // ensures rerender
  const { data: notificationData, loading, fetchMore } = useNotification();

  /* Actions */
  const dispatch = useAppDispatch();
  const search = useAppSelector(ListingSelectors.search);

  const {
    notification: {
      unreadNotificationsCount,
    },
  } = useAppSelector((state: AppState) => state);

  const pushRegisterModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.Register,
      props: {},
    }),
  );

  const pushLoginModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.Login,
      props: {},
    }),
  );

  const pushQuickStartGuideModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.QuickStartGuideModal,
      props: {
        fromTopNav: true,
      },
    }),
  );

  const openMobileSideNav = () => dispatch(
    AppActions.toggleMobileSideNav(true),
  );

  const setListingSearchAddress = (address: IAddress) => {
    dispatch(ListingActions.setListingSearchQuery({ address }));
  };

  const checkRequiredFields = () => ListingUtil.checkRequiredFields(listing);

  const pushCreateListingActionsModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.CreateListingActions,
      props: {
        listing,
      },
    }),
  );

  const isOnDashboard = window?.location?.href?.includes('/app/dashboard') || false;
  const isOnSearchPage = window?.location?.href?.includes('/app/dashboard/search') || false;
  const isCreateListing = window?.location?.href?.includes('/create-listing') || false;
  const isNotPublished = ListingUtil.isNotPublished(listing);
  const showPublishButton = isCreateListing && isNotPublished;
  const handlePublishListing = () => {
    const { incompleteRequiredFields, incompleteFields } = checkRequiredFields();
    if (incompleteRequiredFields) {
      const firstIncompleteFieldId = document.getElementById(incompleteFields[0]);
      window.scrollTo({
        top: firstIncompleteFieldId?.offsetTop,
        behavior: 'smooth',
      });
      dispatch(
        ListingActions.publishListing({ listing, publishAt: null, expiresAt: null }),
      );
    } else {
      pushCreateListingActionsModal();
    }
  };

  /* Render */
  const menuItems = [
    user?.isSuperUser ? {
      text: 'Super Admin',
      onClick: () => {
        handleRouteChange('/app/super-admin/users');
      },
    } : null,
    user?.isAdmin ? {
      text: 'Admin',
      onClick: () => {
        handleRouteChange('/app/admin');
      },
    } : null,
    {
      text: 'My profile',
      onClick: () => {
        handleRouteChange('/app/dashboard/profile');
      },
    },
    {
      text: 'Settings',
      onClick: () => {
        handleRouteChange('/app/dashboard/settings/account');
      },
    },
    {
      text: 'Billing',
      onClick: () => {
        handleRouteChange('/app/dashboard/settings/billing');
      },
    },
    {
      text: 'Feature preview guide',
      onClick: pushQuickStartGuideModal,
    },
    {
      text: 'Log out',
      onClick: () => {
        Auth.logout();
      },
    },
  ].filter((item) => Boolean(item));

  const onClickOutsideRef = useOnclickOutside(() => {
    setMobileListingSearchIsVisible(false);
  });

  if (hideNavigation) return null;

  /** Render - wait for Contentful to fetch values if any */
  return !!fetched && (
    <>
      <OuterContainer isActivePDP={isActivePDP} isPreviewPDP={isPreviewPDP}>
        <Container mobileSearchOpen={mobileListingSearchIsVisible} isActivePDP={isActivePDP} isPreviewPDP={isPreviewPDP}>
          {isMobile || isTablet ? (
            <>
              {(() => {
                if (user && isOnDashboard) {
                  return (
                    <Icon
                      margin="0 0 0 16px"
                      onClick={() => openMobileSideNav()}
                      color={Colors.Grey700}
                      icon={Icons.BarsRegular}
                      data-cy="open-side-nav"
                    />
                  );
                }

                return (
                  <Hover onClick={() => requireAuthentication(() => handleRouteChange('/app/dashboard/search'))}>
                    <Image
                      src={dashboardLogo}
                      width={60}
                      height={56}
                      alt="biproxi-logo"
                    />
                  </Hover>
                );
              })()}
            </>
          ) : (
            <Flex align="center" justify="flex-start">
              {!loadingDashboardLogo
                ? (
                  <Hover id="Hover" onClick={() => requireAuthentication(() => handleRouteChange('/app/dashboard/search'))}>
                    <Image
                      src={dashboardLogo}
                      width={60}
                      height={56}
                      alt="biproxi-logo"
                    />
                  </Hover>
                ) : (
                  <SkeletonContainer style={{ width: '60px', height: '56px' }}>
                    <Skeleton
                      width="100%"
                      height="100%"
                      style={{ backgroundColor: Colors.Grey300, borderRadius: '0' }}
                    />
                  </SkeletonContainer>
                )}
              <Space />
              {Auth.isAuthenticated() && isOnSearchPage && (
                <AutoCompleteAddress
                  placeholder="Enter a location to find properties"
                  value={search.address}
                  onChange={(address: IAddress) => setListingSearchAddress(address)}
                  width="440px"
                  showUnit={false}
                  inputSize={InputSizeEnum.Regular}
                  inputTheme={InputThemeEnum.Ghost}
                />
              )}
            </Flex>
          )}
          {(() => {
            if (user) {
              return (
                <Flex width={(isMobile || isTablet) ? '100%' : null} justify="flex-end">
                  {isMobile || isTablet ? (
                    <>
                      {isOnDashboard ? (
                        <Flex width="100%" justify="space-between" align="center">
                          <div />
                          <MobileSearchIconContainer ref={onClickOutsideRef}>
                            <IconContainer>
                              <Icon
                                onClick={() => setMobileListingSearchIsVisible(!mobileListingSearchIsVisible)}
                                customIcon={BiproxiIconEnum.Search}
                                color="inherit"
                                transitionDuration="0s"
                              />
                            </IconContainer>
                          </MobileSearchIconContainer>
                          <IconContainer
                            ref={notificationsDropdownRef}
                            onClick={() => null}
                          >
                            <Icon
                              customIcon={BiproxiIconEnum.Notifications}
                              color="inherit"
                              transitionDuration="0s"
                            />
                            {unreadNotificationsCount > 0 && <NotificationDot />}
                          </IconContainer>
                          <NotificationsListDropdown
                            anchorRef={notificationsDropdownRef}
                            data={notificationData}
                            loading={loading}
                            fetchMore={fetchMore}
                          />
                          <IconContainer
                            onClick={() => {
                              window.open(KB_BASE_URL);
                            }}
                          >
                            <Icon
                              customIcon={BiproxiIconEnum.KnowledgeBase}
                              color="inherit"
                              transitionDuration="0s"
                            />
                          </IconContainer>
                          {!userPermissions?.loading && userPermissions?.status !== Neo4jReducerStatusEnum.Idle && (
                            <SubscriptionLevelBox
                              data-cy="subscription-level-container"
                              margin="0"
                              bgColor={PermissionsUtil.isSubscribed(userPermissions) ? Colors.Yellow100 : Colors.Grey200}
                              onClick={() => {
                                handleRouteChange('/app/dashboard/settings/billing');
                              }}
                            >
                              <Text
                                color={PermissionsUtil.isSubscribed(userPermissions) ? Colors.Yellow700 : Colors.Grey700}
                                type={TextTypesEnum.Medium12}
                              >
                                {PermissionsUtil.isSubscribed(userPermissions) ? 'PRO' : 'FREE'}
                              </Text>
                            </SubscriptionLevelBox>
                          )}
                          <div />
                        </Flex>
                      ) : (
                        <Flex>
                          {/* <MobileSearchIconContainer ref={onClickOutsideRef}>
                            <Icon
                              onClick={() => setMobileListingSearchIsVisible(!mobileListingSearchIsVisible)}
                              icon={Icons.SearchRegular}
                              color={Colors.Grey700}
                              margin="0px 16px 0px 0px"
                            />
                          </MobileSearchIconContainer> */}
                          <Icon
                            onClick={() => handleRouteChange('/app/dashboard/home')}
                            icon={Icons.ThLargeRegular}
                            color={Colors.Grey700}
                            margin="0 16px 0 0"
                          />
                        </Flex>
                      )}
                    </>
                  ) : (
                    <Flex align="center" margin="0px 24px 0px 0px">
                      <Button
                        data-cy="main-navigation-listing-button"
                        text={showPublishButton ? 'Publish listing' : 'Create listing'}
                        type={ButtonTypesEnum.Pale}
                        size={ButtonSizesEnum.Small}
                        onClick={() => (showPublishButton ? handlePublishListing() : createListing())}
                      />
                      {!userPermissions?.loading && userPermissions?.status !== Neo4jReducerStatusEnum.Idle && (
                        <SubscriptionLevelBox
                          margin="0 0 0 18px"
                          data-cy="subscription-level-container"
                          bgColor={PermissionsUtil.isSubscribed(userPermissions) ? Colors.Yellow100 : Colors.Grey200}
                          onClick={() => {
                            handleRouteChange('/app/dashboard/settings/billing');
                          }}
                        >
                          <Text
                            color={PermissionsUtil.isSubscribed(userPermissions) ? Colors.Yellow700 : Colors.Grey700}
                            type={TextTypesEnum.Medium12}
                          >
                            {PermissionsUtil.isSubscribed(userPermissions) ? 'PRO' : 'FREE'}
                          </Text>
                        </SubscriptionLevelBox>
                      )}
                    </Flex>
                  )}
                  {user.profileImageFile?.url ? (
                    <UserProfileImageContainer data-cy="user-profile-nav" ref={userProfileAnchorRef} profileUrl={user.profileImageFile?.url} />
                  ) : (
                    <UserProfileImageContainer ref={userProfileAnchorRef}>
                      {!loadingUserColor
                        ? (
                          <Text data-cy="user-profile-nav" color={Colors.White} type={TextTypesEnum.Medium18}>
                            {user.firstName[0]}
                            {user.lastName[0]}
                          </Text>
                        ) : (
                          <SkeletonContainer style={{ width: '60px', height: '56px' }}>
                            <Skeleton
                              width="100%"
                              height="100%"
                              style={{ backgroundColor: Colors.Grey300, borderRadius: '0' }}
                            />
                          </SkeletonContainer>
                        )}
                    </UserProfileImageContainer>
                  )}
                </Flex>
              );
            }
            return (
              <Flex margin={Auth.isAuthenticated() ? null : '0px 8px 0px 0px'}>
                {isMobile || isTablet ? (
                  <>
                    {Auth.isAuthenticated() ? (
                      <MobileSearchIconContainer ref={onClickOutsideRef}>
                        <Icon
                          onClick={() => setMobileListingSearchIsVisible(!mobileListingSearchIsVisible)}
                          icon={Icons.SearchRegular}
                          color={Colors.Grey700}
                          margin="0px 16px 0px 0px"
                        />
                      </MobileSearchIconContainer>
                    ) : (
                      <Flex align="center" margin="0px 24px 0px 0px">
                        <Button
                          text={showPublishButton ? 'Publish listing' : 'Create listing'}
                          type={ButtonTypesEnum.Pale}
                          size={ButtonSizesEnum.Small}
                          onClick={() => requireAuthentication(() => (showPublishButton ? handlePublishListing() : createListing()))}
                        />
                      </Flex>
                    )}
                  </>
                ) : (
                  <Flex align="center" margin="0px 24px 0px 0px">
                    <Button
                      text={showPublishButton ? 'Publish listing' : 'Create listing'}
                      type={ButtonTypesEnum.Pale}
                      size={ButtonSizesEnum.Small}
                      onClick={() => requireAuthentication(() => (showPublishButton ? handlePublishListing() : createListing()))}
                    />
                  </Flex>
                )}
                <div>
                  <Divider type={DividerTypesEnum.Vertical} color={Colors.Grey200} />
                </div>
                <Button
                  text="Sign in"
                  type={ButtonTypesEnum.Ghost}
                  size={ButtonSizesEnum.Small}
                  onClick={() => pushLoginModal()}
                  margin="0 8px"
                />
                <Button
                  text="Join free"
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Small}
                  margin="0 16px 0 0"
                  onClick={() => pushRegisterModal()}
                />
              </Flex>
            );
          })()}
          <>
            {user && (
            <AnchoredMenu
              anchorRef={userProfileAnchorRef}
              menuItems={menuItems}
              offset={[-8, 4]}
            >
              <MenuProfileContainer>
                <UserInfo user={user} margin="0px 16px" />
              </MenuProfileContainer>
            </AnchoredMenu>
            )}
          </>
        </Container>
      </OuterContainer>
      {mobileListingSearchIsVisible && isOnSearchPage && (
        <MobileSearchContainer
          ref={onClickOutsideRef}
        >
          <AutoCompleteAddress
            placeholder="Enter a location to find properties"
            value={search.address}
            onChange={(address: IAddress) => setListingSearchAddress(address)}
            width="100%"
            showUnit={false}
            inputSize={InputSizeEnum.Small}
            inputTheme={InputThemeEnum.Dark}
            autoFocus
          />
        </MobileSearchContainer>
      )}
    </>
  );
};

export default MainNavigation;
