import gql from 'graphql-tag';

const CREATE_SAVED_SEARCH = gql`
  mutation CreateSavedSearch($params: SavedSearchParams!) {
    createSavedSearch(params: $params) {
      _id
    }
  }
`;

export default CREATE_SAVED_SEARCH;
