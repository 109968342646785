import gql from 'graphql-tag';

const GET_USER_ORGANIZATIONS = gql`
  query Organizations($where: OrganizationWhere) {
    organizations(where: $where) {
      _id
      name
      description
      listingsConnection {
        edges {
          node {
            _id
            address1
            address2
            addressState
            assetClass
            propertyType
            city
            description
            name
            metaCreatedAt
            metaLastUpdatedAt
            zip
            state
            mediaFileIds
          }
        }
      }
      usersConnection {
        edges {
          permissions
          roles
          node {
            _id
            firstName
            email
            lastName
          }
        }
        totalCount
      }
    }
  }
`;

export default GET_USER_ORGANIZATIONS;
