import gql from 'graphql-tag';

const UPDATE_BASIC_USER_INFORMATION = gql`
  mutation UpdateBasicUserInformation($params: UpdateBasicUserInformationParams!) {
    updateBasicUserInformation(params: $params) {
      _id
      firstName
      lastName
      email
    }
  }
`;

export default UPDATE_BASIC_USER_INFORMATION;
