import React from 'react';
import styled from '@emotion/styled';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import { useLocation } from 'react-router-dom';
import { KB_CREATE_LISTING_LEASE_URL } from '@biproxi/models/externalLinks';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';
import DateInput, { DateInputTypeEnum } from '../../elements/DateInput';
import PopperPlacementTypes from '../../models/enums/PopperPlacementTypes';
import Text, { TextTypesEnum } from '../../elements/Text';
import ListingLoaderTypesEnum from '../../models/enums/ListingLoaderTypesEnum';
import Colors from '../../styles/Colors';
import ListingStatus from '../ListingStatus';
import Radio from '../../elements/Radio';
import Checkbox from '../../elements/Checkbox';
import Icon, { Icons } from '../../elements/Icon';
import Link from '../../elements/Link';
import LinkText from '../../elements/LinkText';

const Container = styled.div`
  width: 432px;

  ${media.mobile} {
    width: 100%;
  }
`;

const RedBar = styled.div<{ checked: boolean }>`
  width: 4px;
  background: ${({ checked }) => (checked ? Colors.Green400 : Colors.Red400)};
  height: 100%;
`;

const ForLeaseContainer = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ checked }) => (checked ? Colors.Green50 : Colors.Red50)};
  gap: 8px;
  padding: 0 8px;
`;

const ButtonSpacer = styled.div`
  min-width: 8px;
  height: 8px;
`;

/** The date inputs would usually have the same z-index, which means the top input
 * will not display over the bottom when opened. This fixes that issue.
 */
const TopDateInputWrapper = styled.div`
 .react-datepicker {
   z-index: 200;
 }
`;

enum PublishTypesEnum {
  Publish = 'Publish',
  Schedule = 'Schedule',
}

export type CreateListingActionsModalProps = {
  listing: IListingGraphQL;
};

const CreateListingActionsModal: React.FC<CreateListingActionsModalProps> = ({
  listing,
}) => {
  /** State */
  const loading = useAppSelector(ListingSelectors.loading);
  const isPublishLoading = loading[ListingLoaderTypesEnum.PublishListing];
  const isScheduled = ListingUtil.isScheduled(listing);
  const [publishType, setPublishType] = React.useState(isScheduled ? PublishTypesEnum.Schedule : PublishTypesEnum.Publish);
  const [publishAt, setPublishAt] = React.useState(null);
  const [expiresAt, setExpiresAt] = React.useState(listing?.schedule?.expiresAt ?? null);
  const [scheduledExpiresAt, setScheduledExpiresAt] = React.useState(null);
  const [scheduleError, setScheduleError] = React.useState<string>('');
  const [publishError, setPublishError] = React.useState<string>('');
  const [scheduleExpirationError, setScheduleExpirationError] = React.useState<string>('');

  const isUpdateLoading = loading[ListingLoaderTypesEnum.UpdateListing];
  const initialLoading = listing === null;

  /** error handling */
  const publishDateInPast = Boolean(publishAt && publishAt < TimeUtil.now());
  const publishDateMissing = Boolean(publishType === PublishTypesEnum.Schedule && !publishAt);
  const expiresDateInPast = Boolean(expiresAt && expiresAt < TimeUtil.now());
  const expiresDateMissing = Boolean(publishType === PublishTypesEnum.Publish && !expiresAt);
  const [verifiedNotLease, setVerifiedNotLease] = React.useState<boolean>(false);
  const [verifyCertified, setVerifyCertified] = React.useState(false);
  const expireDateBeforePublishDate = Boolean(scheduledExpiresAt && publishAt && scheduledExpiresAt < publishAt);

  /** Hooks */
  const isMobile = useMobileMedia();
  const location = useLocation();

  const isOnCreateListing = location.pathname.includes('/app/create-listing');

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const publishListing = async () => {
    if (publishType === PublishTypesEnum.Publish) {
      if (expiresDateMissing) {
        setPublishError('Expiration date required');
      } else if (expiresDateInPast) {
        setPublishError('Date must be in the future');
      } else {
        dispatch(ListingActions.publishListing({
          listing, publishAt, expiresAt, isPublishTask: false,
        }));
      }
    }
    if (publishType === PublishTypesEnum.Schedule) {
      if (publishDateMissing) {
        setScheduleError('Publish date required');
      } else if (publishDateInPast) {
        setScheduleError('Date must be in the future');
      } else if (expireDateBeforePublishDate) {
        setScheduleExpirationError('Expiration date must be after publish date');
      } else {
        dispatch(ListingActions.publishListing({ listing, publishAt, expiresAt: scheduledExpiresAt }));
      }
    }
  };

  const updateListing = () => {
    dispatch(ListingActions.updateListing({ listing, toast: true }));
    popModal();
  };

  const updateDraft = () => {
    dispatch(ListingActions.updateDraft({ listing, toast: true }));
    popModal();
  };

  /** Effects */
  // When the listing loads, set the inital values
  React.useEffect(() => {
    setPublishAt(listing?.schedule?.publishAt ?? null);
    setExpiresAt(listing?.schedule?.expiresAt ?? null);
    setScheduledExpiresAt(listing?.schedule?.expiresAt ?? null);
    setPublishType(isScheduled ? PublishTypesEnum.Schedule : PublishTypesEnum.Publish);
  }, [initialLoading]);

  // clear error of opposing date input when we switch publish options
  React.useEffect(() => {
    if (publishType === PublishTypesEnum.Publish) setScheduleError('');
    if (publishType === PublishTypesEnum.Schedule) setPublishError('');
  }, [publishType]);

  /* Render */
  return (
    <ModalContainer>
      <ModalHeader dataCy="publish-listing-greeting" title="Publish Listing" close={popModal} />
      <ModalContent>
        <Container data-cy="publish-listing-info-modal">
          <ListingStatus listing={listing} margin="0 0 4px" />
          <Text type={TextTypesEnum.Bold18} color={Colors.Grey900} skWidth={200}>
            {ListingUtil.name(listing, { allButAddress1: true })}
          </Text>
          <Text type={TextTypesEnum.Regular14} color={Colors.Grey700} skWidth={160} margin="0 0 24px 0">
            {ListingUtil.isValidPortfolioListing(listing) ? 'Multiple addresses' : AddressUtil.formatAddress(listing?.address, listing?.name ? { zip: true, country: true } : { address1: true }) || null}
          </Text>
          <Radio
            dataCy="publish-now-radio-list"
            label="Publish this listing immediately"
            active={Boolean(publishType === PublishTypesEnum.Publish)}
            margin="0 0 16px"
            onClick={() => {
              setPublishType(PublishTypesEnum.Publish);
              setPublishAt(null);
            }}
          />
          <TopDateInputWrapper>
            <DateInput
              dataCy="expiration-now-date-input"
              label="Expiration date"
              value={expiresAt}
              onChange={(millis: number) => {
                setPublishError('');
                setExpiresAt(millis);
              }}
              inputType={DateInputTypeEnum.DateTime}
              margin="0 0 0px 24px"
              disabled={publishType === PublishTypesEnum.Schedule}
              popperPlacement={PopperPlacementTypes.TopEnd}
              error={publishError}
            />
          </TopDateInputWrapper>
          <Text margin="0 0 0 24px" type={TextTypesEnum.Regular14} color={Colors.Grey300}>
            Time is in
            {' '}
            {TimeUtil.formattedTimezoneWithOffset(listing?.address?.timeZoneId)}
          </Text>
          <Radio
            dataCy="publish-later-radio-list"
            label="Schedule for later"
            active={Boolean(publishType === PublishTypesEnum.Schedule)}
            margin="0 0 16px"
            onClick={() => {
              setPublishType(PublishTypesEnum.Schedule);
              setExpiresAt(null);
            }}
          />
          <DateInput
            dataCy="publish-later-date-input"
            label="Publish date"
            value={publishAt}
            onChange={(millis: number) => {
              setScheduleError('');
              setPublishAt(millis);
            }}
            inputType={DateInputTypeEnum.DateTime}
            margin="0 0 0px 24px"
            disabled={publishType !== PublishTypesEnum.Schedule}
            popperPlacement={PopperPlacementTypes.TopEnd}
            error={scheduleError}
          />
          <Text margin="0 0 0 24px" type={TextTypesEnum.Regular14} color={Colors.Grey300}>
            Time is in
            {' '}
            {TimeUtil.formattedTimezoneWithOffset(listing?.address?.timeZoneId)}
          </Text>
          <DateInput
            dataCy="expiration-later-date-input"
            label="Expiration date"
            value={scheduledExpiresAt}
            onChange={(millis: number) => {
              setScheduleExpirationError('');
              setScheduledExpiresAt(millis);
            }}
            inputType={DateInputTypeEnum.DateTime}
            margin="8px 0px 0px 24px"
            disabled={publishType !== PublishTypesEnum.Schedule}
            popperPlacement={PopperPlacementTypes.TopEnd}
            error={scheduleExpirationError}
          />
          <Text margin="0 0 0 24px" type={TextTypesEnum.Regular14} color={Colors.Grey300}>
            Time is in
            {' '}
            {TimeUtil.formattedTimezoneWithOffset(listing?.address?.timeZoneId)}
          </Text>
          <Flex height="56px" width="100%">
            <RedBar checked={verifiedNotLease} />
            <ForLeaseContainer checked={verifiedNotLease}>
              <Checkbox
                dataCy="not-lease-checkbox"
            // label="This property is not a lease"
                onClick={() => setVerifiedNotLease(!verifiedNotLease)}
                active={verifiedNotLease}
              />
              {
                verifiedNotLease ? (
                  <Text type={TextTypesEnum.Regular14} color={Colors.Green900}>
                    This property is not a lease.
                    {'\n'}
                    <Link
                      href={KB_CREATE_LISTING_LEASE_URL}
                      target="_blank"
                      isExternal
                    >
                      <LinkText type={TextTypesEnum.Bold14}>Learn More</LinkText>
                    </Link>
                  </Text>
                ) : (
                  <>
                    <Icon
                      icon={Icons.ExclamationTriangleSolid}
                      size="16px"
                      color={Colors.Red400}
                    />
                    <Text type={TextTypesEnum.Regular14} color={Colors.Red900}>
                      *This property is not a lease.
                      {'\n'}
                      <Link
                        href={KB_CREATE_LISTING_LEASE_URL}
                        target="_blank"
                        isExternal
                      >
                        <LinkText type={TextTypesEnum.Bold14}>Learn More</LinkText>
                      </Link>
                    </Text>
                  </>

                )
              }

            </ForLeaseContainer>
          </Flex>
          {listing?.isPrivateListing && (
            <Checkbox
              dataCy="exclusive-rights-checkbox"
              label="I have the exclusive right to market this private listing"
              onClick={() => setVerifyCertified(!verifyCertified)}
              active={verifyCertified}
            />
          )}
        </Container>
      </ModalContent>
      <ModalFooter>
        <Flex justify="center" width="100%" direction={isMobile ? 'column' : 'row'}>
          {isOnCreateListing && (
            <>
              <Button
                text="Save listing"
                onClick={listing.state === ListingStateEnum.Draft ? () => updateDraft() : () => updateListing()}
                isLoading={isUpdateLoading && !isPublishLoading}
                loaderColor={Colors.Brand700 || Colors.Blurple700}
                type={ButtonTypesEnum.Outline}
                size={ButtonSizesEnum.Large}
                isFullWidth
              />
              <ButtonSpacer />
            </>
          )}
          <Button
            data-cy="final-publish-listing-button"
            text={`${publishType} listing`}
            onClick={() => publishListing()}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Large}
            isFullWidth
            isLoading={isPublishLoading || isUpdateLoading}
            disabled={!verifiedNotLease || (listing.isPrivateListing && !verifyCertified)}
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default CreateListingActionsModal;
