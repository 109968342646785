import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { IStringLatLng } from '@biproxi/models/interfaces/IMapBounds';

/**
 * Takes in an array of listings and returns:
 * 1) maxLat: northern most property point
 * 2) minLat: southern most property point
 * 3) maxLng: eastern most property point
 * 4) minLng: western most property point
 * 5) neSwPoints: points that define the bounding box
 * 6) midPoint: center point of the bounding box
 */

const getPropertyBounds = (listings: IListingGraphQL[]) => {
  // create an array of lat/lng object
  const latLngArray: IStringLatLng[] = listings
    .map((listing: IListingGraphQL) => {
      const { coordinates } = listing.address.location;
      return { lat: coordinates[1], lng: coordinates[0] };
    })
    .filter((coordinate) => coordinate.lat && coordinate.lng); // filter out any empty strings representing lat/lng values

  /** Find max coordinates in each cardinal direction */
  let maxLat; // max north
  let maxLng; // max east
  let minLat; // max south
  let minLng;// max west
  let neSwPoints;
  let midPoint;
  if (latLngArray.length > 0) {
    // max north
    maxLat = latLngArray?.reduce((max, coordinate) => (parseFloat(coordinate.lat) > parseFloat(max.lat) ? coordinate : max)).lat;

    // max south
    minLat = latLngArray?.reduce((max, coordinate) => (parseFloat(coordinate.lat) < parseFloat(max.lat) ? coordinate : max)).lat;

    // max east
    maxLng = latLngArray?.reduce((max, coordinate) => ((parseFloat(coordinate.lng) > parseFloat(max.lng) && parseFloat(coordinate.lng) < -60) ? coordinate : max)).lng;

    // max west
    minLng = latLngArray?.reduce((max, coordinate) => ((parseFloat(coordinate.lng) < parseFloat(max.lng) && parseFloat(coordinate.lng) > -125) ? coordinate : max)).lng;

    const ne = { lat: parseFloat(maxLat), lng: parseFloat(maxLng) + 2 }; // add 1.75 to the east to fit eastern-most tags within map view
    const sw = { lat: parseFloat(minLat), lng: parseFloat(minLng) - 1 }; // subtract 1 to the west to fit western-most tags within map view
    neSwPoints = { ne, sw };

    midPoint = {
      lat: (ne.lat + sw.lat) / 2,
      lng: (ne.lng + sw.lng) / 2,
    };
  }
  return {
    maxLat, minLat, maxLng, minLng, neSwPoints, midPoint,
  };
};

export default getPropertyBounds;
