import gql from 'graphql-tag';

const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($where: OrganizationWhere) {
    deleteOrganizations(where: $where) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export default DELETE_ORGANIZATION;
