/* eslint-disable no-continue */
/* eslint-disable no-labels */
/* eslint-disable no-restricted-syntax */

/*
 * light typescript convert after import from react-text-match npm package
 * software provided unlicensed, so explicitly free to modify and use
 */

export const defaultPlaceholderChar = '_';
const emptyArray: Array<any> = [];
const emptyString = '';

function isArray(value: never[]) {
  return (Array.isArray && Array.isArray(value)) || value instanceof Array;
}

const strCaretTrap = '[]';

export function processCaretTraps(mask: any) {
  const indexes: any[] = [];

  let indexOfCaretTrap;
  while (indexOfCaretTrap = mask.indexOf(strCaretTrap), indexOfCaretTrap !== -1) { // eslint-disable-line
    indexes.push(indexOfCaretTrap);

    mask.splice(indexOfCaretTrap, 1);
  }

  return { maskWithoutCaretTraps: mask, indexes };
}

export function convertMaskToPlaceholder(mask: any = emptyArray, placeholderChar = defaultPlaceholderChar) {
  if (!isArray(mask)) {
    throw new Error(
      'Text-mask:convertMaskToPlaceholder; The mask property must be an array.',
    );
  }

  if (mask.indexOf(placeholderChar) !== -1) {
    throw new Error(
      'Placeholder character must not be used as part of the mask. Please specify a character '
      + 'that is not present in your mask as your placeholder character.\n\n'
      + `The placeholder character that was received is: ${JSON.stringify(placeholderChar)}\n\n`
      + `The mask that was received is: ${JSON.stringify(mask)}`,
    );
  }

  return mask.map((char: any) => ((char instanceof RegExp) ? placeholderChar : char)).join('');
}

export function conformToMask(rawValue = emptyString, mask: any, config: any = {}) {
  if (!isArray(mask)) {
    if (typeof mask === 'function') {
      mask = mask(rawValue, config);

      mask = processCaretTraps(mask).maskWithoutCaretTraps;
    } else {
      throw new Error(
        'Text-mask:conformToMask; The mask property must be an array.',
      );
    }
  }

  const {
    guide = true,
    previousConformedValue = emptyString,
    placeholderChar = defaultPlaceholderChar,
    placeholder = convertMaskToPlaceholder(mask, placeholderChar),
    currentCaretPosition,
    keepCharPositions,
  } = config;

  const suppressGuide = guide === false && previousConformedValue !== undefined;

  const rawValueLength = rawValue.length;
  const previousConformedValueLength = previousConformedValue.length;
  const placeholderLength = placeholder.length;
  const maskLength = mask.length;

  const editDistance = rawValueLength - previousConformedValueLength;

  const isAddition = editDistance > 0;

  const indexOfFirstChange = currentCaretPosition + (isAddition ? -editDistance : 0);

  const indexOfLastChange = indexOfFirstChange + Math.abs(editDistance);

  if (keepCharPositions === true && !isAddition) {
    let compensatingPlaceholderChars = emptyString;

    for (let i = indexOfFirstChange; i < indexOfLastChange; i++) {
      if (placeholder[i] === placeholderChar) {
        compensatingPlaceholderChars += placeholderChar;
      }
    }

    rawValue = (
      rawValue.slice(0, indexOfFirstChange)
      + compensatingPlaceholderChars
      + rawValue.slice(indexOfFirstChange, rawValueLength)
    );
  }

  const rawValueArr = rawValue
    .split(emptyString)
    .map((char, i) => ({ char, isNew: i >= indexOfFirstChange && i < indexOfLastChange }));

  for (let i = rawValueLength - 1; i >= 0; i--) {
    const { char } = rawValueArr[i];

    if (char !== placeholderChar) {
      const shouldOffset = i >= indexOfFirstChange && previousConformedValueLength === maskLength;

      if (char === placeholder[(shouldOffset) ? i - editDistance : i]) {
        rawValueArr.splice(i, 1);
      }
    }
  }

  let conformedValue = emptyString;
  let someCharsRejected = false;

  placeholderLoop: for (let i = 0; i < placeholderLength; i++) {
    const charInPlaceholder = placeholder[i];

    if (charInPlaceholder === placeholderChar) {
      if (rawValueArr.length > 0) {
        while (rawValueArr.length > 0) {
          const { char: rawValueChar, isNew } = rawValueArr.shift() as any;

          if (rawValueChar === placeholderChar && suppressGuide !== true) {
            conformedValue += placeholderChar;

            continue placeholderLoop;
          } else if (mask[i].test(rawValueChar)) {
            if (
              keepCharPositions !== true
              || isNew === false
              || previousConformedValue === emptyString
              || guide === false
              || !isAddition
            ) {
              conformedValue += rawValueChar;
            } else {
              const rawValueArrLength = rawValueArr.length;
              let indexOfNextAvailablePlaceholderChar: number = NaN;

              for (let i = 0; i < rawValueArrLength; i++) {
                const charData = rawValueArr[i];

                if (charData.char !== placeholderChar && charData.isNew === false) {
                  break;
                }

                if (charData.char === placeholderChar) {
                  indexOfNextAvailablePlaceholderChar = i;
                  break;
                }
              }

              if (indexOfNextAvailablePlaceholderChar !== null) {
                conformedValue += rawValueChar;
                rawValueArr.splice(indexOfNextAvailablePlaceholderChar, 1);
              } else {
                i--;
              }
            }

            // Since we've mapped this placeholder position. We move on to the next one.
            continue placeholderLoop;
          } else {
            someCharsRejected = true;
          }
        }
      }

      if (suppressGuide === false) {
        conformedValue += placeholder.substr(i, placeholderLength);
      }

      break;
    } else {
      conformedValue += charInPlaceholder;
    }
  }

  if (suppressGuide && isAddition === false) {
    let indexOfLastFilledPlaceholderChar: number = NaN;

    // Find the last filled placeholder position and substring from there
    for (let i = 0; i < conformedValue.length; i++) {
      if (placeholder[i] === placeholderChar) {
        indexOfLastFilledPlaceholderChar = i;
      }
    }

    if (indexOfLastFilledPlaceholderChar !== null) {
      conformedValue = conformedValue.substr(0, indexOfLastFilledPlaceholderChar + 1);
    } else {
      conformedValue = emptyString;
    }
  }

  return { conformedValue, meta: { someCharsRejected } };
}
