import gql from 'graphql-tag';
import NotificationFragments from '../fragments/notification.fragments';

const NOTIFICATION = gql`
  subscription Notification {
    notification {
      ...NotificationFields
      ...NotificationListing
      ...NotificationUser
      ...NotificationLead
      ...NotificationMeta
    }
  }

  ${NotificationFragments.fields}
  ${NotificationFragments.listing}
  ${NotificationFragments.user}
  ${NotificationFragments.lead}
  ${NotificationFragments.meta}
`;

export default NOTIFICATION;
