import axios from 'axios';
import { Logger } from './logger';

const calcDeltaTime = (ts: number | undefined): string => (ts ? `🕔~${((new Date().getTime()) - ts)}` : '');

export const setupAxiosInterceptor = (logger: Logger) => {
  // Add a request interceptor
  axios.interceptors.request.use((config) => {
    logger.info(`>>> ${config.url ?? ''}`);
    // @ts-ignore
    config.meta = config.meta || {};
    // @ts-ignore
    config.meta.requestStartedAt = new Date().getTime();
    return config;
  }, async (error) => {
    logger.error(`>>> Error: ${error.message}`);
    return Promise.reject(error);
  });

  // Add a response interceptor
  axios.interceptors.response.use((response) => {
    // @ts-ignore
    logger.info(`<<< ${calcDeltaTime(response.config?.meta?.requestStartedAt)} ${response?.config.url ?? ''} `);
    return response;
  }, async (error) => {
    logger.error(`<<< Error: ${error.message}`);
    return Promise.reject(error);
  });
};
