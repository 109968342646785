import React from 'react';
import styled from '@emotion/styled';
import Colors, { ColorsType } from '../styles/Colors';
import Text, { TextTypesEnum } from './Text';

type TagContainerProps = {
    backgroundColor: ColorsType,
    borderColor: ColorsType,
    margin: string,
    padding: string,
    active: boolean,
    hover: boolean,
    height?: string,
    width?: string,
  }

const TagContainer = styled.div<TagContainerProps>`
    display: flex;
    background-color: ${({ backgroundColor }) => backgroundColor};
    padding: 0 6px;
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
    border: ${({ active, borderColor, backgroundColor }) => (active ? `1px solid ${borderColor}` : `1px solid ${backgroundColor}`)}; // by default, give the tag a border with the same color as the background to prevent the tags from moving on hover...tricky, tricky css hehe
    border-radius: 4px;
    width: ${({ width }) => width ?? 'fit-content'};
    height: ${({ height }) => height ?? 'fit-content'};
    /* width: fit-content; */
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: ${({ active, hover }) => (active || hover ? 'pointer' : 'default')};
      transition: all 0.2s;
      border: ${({ hover, borderColor }) => (hover ? `1px solid ${borderColor}` : null)};
      background-color: ${({ hover, backgroundColor }) => {
    if (hover) {
      switch (backgroundColor) {
        case Colors.Grey100:
          return Colors.Grey200;
        case Colors.Green100:
          return Colors.Green200;
        case Colors.Red100:
          return Colors.Red200;
        case Colors.Yellow100:
          return Colors.Yellow200;
        case Colors.Brand100 || Colors.Blurple100:
          return Colors.Brand200 || Colors.Blurple200;
        default:
          return null;
      }
    } else {
      return null;
    }
  }}
}
`;

type TagProps = {
    text: string;
    margin?: string;
    padding?: string;
    textSize?: TextTypesEnum;
    customTagColor?: ColorsType;
    customTextColor?: ColorsType;
    color?: ColorsType;
    borderColor?: ColorsType;
    onClick?: (e?: any) => void;
    hover?: boolean; // if true, tag will have hoverable state
    right?: boolean; // if true, children will be aligned to the right
    active?: boolean;
    children?: React.ReactNode;
    height?: string;
    width?: string;
    'data-cy'?: string;
}

const Tag: React.FC<TagProps> = ({
  text,
  margin = '0',
  padding = '0 4px',
  textSize,
  customTagColor = Colors.Grey100,
  customTextColor = Colors.Black,
  borderColor = Colors.Grey500,
  color,
  onClick,
  hover = false,
  right = false,
  active = false,
  height,
  width,
  children,
  'data-cy': dataCy,
}) => {
  let bgColor;
  let textColor;
  if (color) {
    switch (color) {
      case 'Green':
        bgColor = Colors.Green100;
        textColor = Colors.Green800;
        break;
      case 'Yellow':
        bgColor = Colors.Yellow100;
        textColor = Colors.Yellow900;
        break;
      case 'Red':
        bgColor = Colors.Red100;
        textColor = Colors.Red900;
        break;
      case 'Grey':
        bgColor = Colors.Grey100;
        textColor = Colors.Grey900;
        break;
      case 'Blurple':
        bgColor = Colors.Brand100 || Colors.Blurple100;
        textColor = Colors.Brand900 || Colors.Blurple900;
        break;
      default:
        return null;
    }
  }

  return (
    <TagContainer
      data-cy={dataCy && (`${dataCy}-container`)}
      backgroundColor={bgColor ?? customTagColor}
      borderColor={borderColor}
      margin={margin}
      padding={padding}
      onClick={() => onClick?.()}
      active={active}
      hover={hover}
      height={height}
      width={width}
    >
      {children && !right && (
        children
      )}
      <Text data-cy={dataCy && (`${dataCy}-${text}-text`)} color={textColor ?? customTextColor} type={textSize}>{text}</Text>
      {children && right && (
        children
      )}
    </TagContainer>
  );
};

export default Tag;
