import React from 'react';
import styled from '@emotion/styled';
import StringUtil from '@biproxi/models/utils/StringUtil';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import FavoriteButton, { FavoriteButtonTypesEnum } from './FavoriteButton';
import Text, { TextTypesEnum } from '../elements/Text';
import ImageWithAspectRatio from '../elements/ImageWithAspectRatio';
import Colors from '../styles/Colors';
import ListingStatus from './ListingStatus';
import ListingCardTypesEnum from '../models/enums/ListingCardTypesEnum';
import { useMobileMedia } from '../utils/MediaQuery';
import Icon from '../elements/Icon';
import BiproxiIconEnum from '../models/enums/BiproxiIconEnum';

/** CSS Selectors */
const animatedImage = 'animatedImage';

const ThumbnailContainer = styled.div<{ type: string, width: string }>`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: ${(props) => (props.width ? props.width : '100%')};
  border-radius: ${(props) => {
    switch (props.type) {
      case ListingCardTypesEnum.DefaultMap:
        return '0px';
      default:
        return '8px';
    }
  }};
  margin: ${(props) => {
    switch (props.type) {
      case ListingCardTypesEnum.Personal:
        return '0px 16px 0px 0px';
      default:
        return null;
    }
  }};

  &:hover {
  #animatedImage {
      transform: ${(props) => {
    switch (props.type) {
      case ListingCardTypesEnum.DefaultMap:
      case ListingCardTypesEnum.Personal:
        return null;
      default:
        return 'scale(1.2)';
    }
  }};
    }
  }
`;

const AnimatedImage = styled.div`
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
`;

const ImageGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 48px;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
`;

const TopGradient = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  width: 100%;
  height: 48px;
  top: 0;
`;

const StatusOverlay = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
`;

const FavoriteButtonOverlay = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 6px;
  right: 6px;
`;

const PriceOverlay = styled.div`
  position: absolute;
  bottom: 0px;
  margin: 0px 0px 8px 12px;
`;

const CallForOffersOverlay = styled.div`
  position: absolute;
  top: 32px;
  left: 12px;
`;

const PrivateListingOverlay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 50%;
  right: 50%;
  left: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  text-align: center;
  align-items: center;
  justify-content: center;
`;

type ListingThumbnailProps = {
  type: ListingCardTypesEnum;
  listing: IListingGraphQL | null;
  listingImage?: string;
  privateListingProps?: any;
  width?: string;
}

const ListingThumbnail: React.FC<ListingThumbnailProps> = ({
  type,
  listing,
  width,
  privateListingProps,
  listingImage = listing?.media?.files?.[0]?.url,
}) => {
  /** Hooks */
  const isMobile = useMobileMedia();

  /** state */
  const displayStatusOverlay = Boolean(type === ListingCardTypesEnum.DefaultMap || type === ListingCardTypesEnum.DefaultSearch || (type !== ListingCardTypesEnum.Dashboard && type !== ListingCardTypesEnum.Personal
    && (isMobile && type === ListingCardTypesEnum.DefaultFavoritedDashboard)));
  const displayFavoriteOverlay = Boolean(type === ListingCardTypesEnum.DefaultSearch || type === ListingCardTypesEnum.DefaultFavoritedDashboard
    || type === ListingCardTypesEnum.DefaultFavorited);
  const displayPriceOverlay = Boolean(type !== ListingCardTypesEnum.Dashboard && type !== ListingCardTypesEnum.Personal);
  const displayCallForOffersState = Boolean(listing?.guidance?.callForOffersAt && listing?.guidance?.callForOffersAt > TimeUtil.now());
  const displayCallForOffersDate = Boolean((type === ListingCardTypesEnum.DefaultSearch && displayCallForOffersState && !isMobile));

  const { isPrivateListing, isPrivateListingAccess } = privateListingProps || {};
  let skWidth;

  /** Render */
  /** Not ideal, but we must make the Image component consciously aware of width
   * value in order to properly calculate skeleton height dynamically. Perhaps refactor
   * to use Refs somehow to know parent width?
   */
  switch (type) {
    case ListingCardTypesEnum.DefaultSearch:
      skWidth = isMobile ? '100%' : '250px';
      break;
    case ListingCardTypesEnum.DefaultMap:
      skWidth = 200;
      break;
    case ListingCardTypesEnum.DefaultFavoritedDashboard:
      skWidth = 200;
      break;
    case ListingCardTypesEnum.Personal:
      skWidth = isMobile ? '100%' : 146;
      break;
    case ListingCardTypesEnum.Dashboard:
      skWidth = 91;
      break;
    default:
      skWidth = 250;
  }
  return (
    <ThumbnailContainer id="Thumbnail Container" type={type} width={width}>
      <AnimatedImage id={animatedImage}>
        <ImageWithAspectRatio
          src={listingImage}
          aspectRatio={3 / 2}
          skWidth={skWidth}
        />
      </AnimatedImage>
      {listing && (
        <>
          <ImageGradient />
          {isMobile && <TopGradient />}
          {displayStatusOverlay && (
            <StatusOverlay>
              <ListingStatus
                listing={listing}
                status={displayCallForOffersState ? ListingStateEnum.CallForOffers : null}
              />
            </StatusOverlay>
          )}
          {displayPriceOverlay && (
            <>
              {isMobile && type === ListingCardTypesEnum.DefaultFavoritedDashboard ? (
                <PriceOverlay>
                  <Text
                    type={TextTypesEnum.Bold24}
                    color={Colors.White}
                  >
                    {listing?.guidance?.askingPrice ? `$${StringUtil.formatNumber(listing?.guidance?.askingPrice?.toString?.())}` : null}
                  </Text>
                </PriceOverlay>
              ) : (
                <PriceOverlay>
                  <Text
                    type={
                    type === ListingCardTypesEnum.DefaultSearch
                      ? TextTypesEnum.Bold18
                      : TextTypesEnum.Bold16
                }
                    color={Colors.White}
                  >
                    {listing?.guidance?.askingPrice ? `$${StringUtil.formatNumber(listing?.guidance?.askingPrice?.toString?.())}` : null}
                  </Text>
                </PriceOverlay>
              )}
            </>
          )}
          {displayFavoriteOverlay && (
          <FavoriteButtonOverlay>
            <FavoriteButton
              listingId={listing?._id}
              type={FavoriteButtonTypesEnum.Filled}
            />
          </FavoriteButtonOverlay>
          )}
          {displayCallForOffersDate && (
          <CallForOffersOverlay>
            <Text
              type={TextTypesEnum.Bold16}
              color={Colors.White}
            >
              {TimeUtil.format(listing.guidance.callForOffersAt, 'D', TimeUtil.currentBrowserTimezone())}
            </Text>
          </CallForOffersOverlay>
          )}
          {
            isPrivateListing && !isPrivateListingAccess && (
              <PrivateListingOverlay>
                <Icon
                  data-cy="listing-card-locked-thumbnail"
                  customIcon={BiproxiIconEnum.LockClosed}
                  color={Colors.White}
                  size={40}
                />
                <Text
                  data-cy="listing-card-locked-text"
                  color={Colors.White}
                >
                  Request access
                </Text>
              </PrivateListingOverlay>
            )
          }
        </>
      )}
    </ThumbnailContainer>
  );
};

export default ListingThumbnail;
