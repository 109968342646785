import gql from 'graphql-tag';
import ListingFragments from '../fragments/listing.fragments';

const SRP_LIST_LISTINGS = gql`
  query Listings($params: ListListingsParams) {
    listings(params: $params) {
      data {
        ...ListingFields
        ...ListingAddress
        ...ListingPortfolioAddresses
        ...ListingSchedule
        ...ListingMedia
        ...ListingGuidance
        ...ListingMeta
        ...ListingUser
        ...ListingContacts
      }
      info {
        totalCount
      }
    }
  }

  ${ListingFragments.fields}
  ${ListingFragments.address}
  ${ListingFragments.portfolioAddresses}
  ${ListingFragments.schedule}
  ${ListingFragments.media}
  ${ListingFragments.guidance}
  ${ListingFragments.meta}
  ${ListingFragments.user}
  ${ListingFragments.contacts}
`;

export default SRP_LIST_LISTINGS;
