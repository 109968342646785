import React from 'react';
import styled from '@emotion/styled';
import Colors, { ColorsType } from '../styles/Colors';
import Text, { TextTypesEnum } from './Text';
import Anchor, { AnchorProps } from '../components/Anchor';
import PopperPlacementTypes from '../models/enums/PopperPlacementTypes';
import BoxShadows from '../styles/BoxShadows';
import ZIndexes from '../styles/ZIndexes';

const InnerContainer = styled.div<{ radius: string, minWidth?: string; maxWidth?: string; }>`
  width: 100%;
  height: 100%;
  border: 1px solid ${Colors.Grey300};
  border-radius: ${(props) => props.radius};
  box-sizing: border-box;
  background: ${Colors.White};
  box-shadow: ${BoxShadows.Darker};
  z-index: ${ZIndexes.AnchoredMenu};
  padding: 4px 0;
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};

  &, & > *, > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export type StatusCircleProps = {
  color: string;
}

const StatusCircle = styled.div<StatusCircleProps>`
  width: 8px;
  height: 8px;
  margin: 0px 8px 0px 0px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 8px 16px;
  transition: background-color 0.2s;

  > * {
    white-space: nowrap;
  }

  &:hover {
    background-color: ${Colors.Grey200};
  }
`;

export type MenuItems = {
  text: string;
  onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  color?: ColorsType;
}

type AnchoredMenuProps = AnchorProps & {
  menuItems?: MenuItems[];
  radius?: string;
  minWidth?: string;
  maxWidth?: string;
  hasStatusCircle?: boolean;
}

/**
 * Pass in menuItems to render a list of items.
 * Pass in children to render whatever you want.
 * Pass in both to render whatever you want above a list of items.
 * TODO: Add hover event listeners, animations, and more menu item types.
 */
const AnchoredMenu: React.FC<AnchoredMenuProps> = ({
  anchorRef,
  menuItems,
  children,
  placement = PopperPlacementTypes.BottomEnd,
  offset = [0, 8],
  radius = '4px',
  minWidth,
  maxWidth,
  hasStatusCircle = false,
}) => (
  <Anchor
    anchorRef={anchorRef}
    placement={placement}
    offset={offset}
  >
    <InnerContainer
      radius={radius}
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      {children && (children)}
      {menuItems && (
        <>
          {menuItems.map((item, index) => {
            const shouldColorText = Boolean(item.color && !hasStatusCircle);
            return (
              <MenuItem
                key={index}
                onClick={item.onClick}
              >
                {hasStatusCircle && (
                  <StatusCircle color={item.color} />
                )}
                <Text data-cy={`menu-${item.text.replace(/ /g, '-').toLowerCase()}`} type={TextTypesEnum.Regular16} color={shouldColorText ? item.color : Colors.Grey900}>{item.text}</Text>
              </MenuItem>
            );
          })}
        </>
      )}
    </InnerContainer>
  </Anchor>
);

export default AnchoredMenu;
