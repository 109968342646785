import React from 'react';
import styled from '@emotion/styled';
import { IOfferGraphQL } from '@biproxi/models/interfaces/IOffer';
import StringUtil from '@biproxi/models/utils/StringUtil';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import OfferStateEnum from '@biproxi/models/enums/OfferStateEnum';
import { useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import OfferUtil from '@biproxi/models/utils/OfferUtil';
import OfferQueryTypesEnum from '@biproxi/models/enums/OfferQueryTypesEnum';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Icon, { Icons } from '../elements/Icon';
import Flex from '../elements/Flex';
import Divider, { DividerTypesEnum } from '../elements/Divider';
import UserInfo, { UserInfoTypesEnum } from './UserInfo';
import { useAppDispatch } from '../redux/store';
import { LeadActions } from '../redux/lead.redux';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from './modal/Modal';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import { LeadModalPageStateEnum } from './modal/LeadModal';
import OfferStatus from './OfferStatus';
import AnchoredMenu from '../elements/AnchoredMenu';
import { ConfirmChangeModalTypesEnum } from './modal/ConfirmChangeModal';
import UPDATE_OFFER_STATE from '../graphql/mutations/updateOfferState.mutation';
import { IToastConfig, ToastTypesEnum } from './Toast';
import LIST_OFFERS from '../graphql/queries/offers.query';
import { useMobileMedia } from '../utils/MediaQuery';
import { BuyerLeadModalPageStateEnum } from './modal/BuyerLeadModal';

export enum OfferCardTypeEnum {
  Sent = 'Sent',
  Received = 'Received',
  Rows = 'Rows',
  RowsWithActions = 'RowsWithActions'
}

const Container = styled.div`
  background: ${Colors.White};
  border-radius: 8px;
  border: 1px solid ${Colors.Grey300};
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin: 0 0 16px;
  overflow: hidden;
  max-width: calc(100vw - 32px);

  &:hover {
    border: 1px solid ${Colors.Grey400};
  }
`;

const ActionBox = styled.div`
  padding: 8px;
  transition: all 0.2s;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-right: 8px;

  &:hover {
    background: ${Colors.Black05};
  }
  &:active {
    background: ${Colors.Black10}
  }
`;

const GreyBox = styled.div`
  background: ${Colors.Grey100};
  border-radius: 8px;
  padding: 8px;
`;

const Spacer = styled.div`
  min-width: 8px;
`;

const Image = styled.div<{ src: string }>`
  background-size: cover;
  background-position: center;
  background-origin: unset;
  background-image: url(${({ src }) => `${src}`});
  width: 56px;
  min-width: 56px;
  height: 40px;
  border-radius: 4px;
  margin-right: 8px;
`;

const ActionBar = styled.div`
  height: 48px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  background: ${Colors.Grey50};
  position: relative;
`;

const ExtraMobileButtons = styled.div`
  position: absolute;
  right: 16px;
`;

const UserInfoMobileContainer = styled.div`
  padding: 16px;
`;

const RowOfferCardMobileContainer = styled.div`
  padding: 0px 16px 16px;
`;

const ListingInfoContainer = styled.div`
/** Weird, but it works */
  &, & > *, > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

type OfferCardProps = {
  offer: IOfferGraphQL;
  type?: OfferCardTypeEnum;
  hideOtherTerms?: boolean;
  offerClickDisabled?: boolean;
};

const OfferCard: React.FC<OfferCardProps> = ({
  offer,
  type = OfferCardTypeEnum.Received,
  hideOtherTerms = false,
  offerClickDisabled = false,
}) => {
  /** Hooks */
  const ref = React.useRef(null);
  const isMobile = useMobileMedia();

  /** GraphQL */
  interface Data {
    offer: IOfferGraphQL;
  }

  interface Vars {
    params: {
      offerId: string;
      state: OfferStateEnum;
    };
  }

  const [updateOfferState, { loading }] = useMutation<Data, Vars>(UPDATE_OFFER_STATE, {
    variables: {
      params: {
        offerId: offer._id,
        state: OfferStateEnum.Withdrawn,
      },
    },
    onCompleted: async () => {
      pushToast({
        message: 'Offer successfully withdrawn.',
        type: ToastTypesEnum.Notification,
      });
    },
    onError: async (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);
      pushToast({
        message,
        type: ToastTypesEnum.Error,
      });
    },
    refetchQueries: [{
      query: LIST_OFFERS,
      variables: {
        params: {
          queryType: OfferQueryTypesEnum.Sent,
          isActive: true,
        },
      },
    },
    {
      query: LIST_OFFERS,
      variables: {
        params: {
          queryType: OfferQueryTypesEnum.Sent,
          leadId: offer?.leadId,
          hasRevisedOffers: true,
        },
      },
    }],
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const pushLeadModal = (leadId: string) => {
    dispatch(LeadActions.selectLead({
      leadId,
    }));
    dispatch(AppActions.pushModal({
      type: ModalTypesEnum.Lead,
      props: {
        initialState: LeadModalPageStateEnum.Offers,
      },
    }));
  };
  const pushBuyerLeadModal = (listingId: string) => {
    dispatch(AppActions.pushModal({
      type: ModalTypesEnum.BuyerLead,
      props: {
        listingId,
        initialState: BuyerLeadModalPageStateEnum.Offers,
      },
    }));
  };
  const pushRejectOfferModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.RejectOffer,
      props: {
        offer,
      },
    }),
  );
  const viewLOI = () => dispatch(
    AppActions.previewFiles({
      fileId: offer?.loiFileIds[0],
      files: offer?.loiFiles,
      track: false,
    }),
  );
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );
  const popModal = () => dispatch(
    AppActions.popModal(),
  );
  const pushWithdrawOfferModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Warning,
        title: 'Withdraw offer',
        text: 'Are you sure you want to withdraw your offer? You can still make another offer later if you want to.',
        confirm: (event: React.FormEvent) => {
          event?.preventDefault();
          updateOfferState();
          popModal();
        },
        confirmLoading: loading,
      },
    }),
  );
  const pushOfferModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.Offer,
      props: {
        offer,
      },
    }),
  );

  /** Render */
  const menuItems = [
    {
      text: 'View listing',
      onClick: (e) => {
        e.stopPropagation();
        window.open(ListingUtil.slug(offer?.listing), '_blank');
      },
    },
  ];

  if (OfferUtil.isActive(offer)) {
    menuItems.push({
      text: 'Withdraw offer',
      onClick: (e) => {
        e.stopPropagation();
        pushWithdrawOfferModal();
      },
    });
  }

  const isSentOffer = type === OfferCardTypeEnum.Sent;

  const cardClick = () => {
    if (!offerClickDisabled) {
      if (isSentOffer) {
        pushBuyerLeadModal(offer?.listingId);
      } else {
        pushLeadModal(offer?.leadId);
      }
    }
  };

  const UserInfoLayout: React.FC = () => (
    <Flex>
      <UserInfo user={offer.user} type={UserInfoTypesEnum.Small} />
    </Flex>
  );

  const ListingLayout: React.FC = () => (
    <Flex align="center">
      <Image
        src={offer?.listing?.media?.files?.[0]?.url}
      />
      <ListingInfoContainer>
        <Text type={TextTypesEnum.Medium14} color={Colors.Black}>
          {`${ListingUtil.name(offer?.listing, { allButAddress1: true })}`}
        </Text>
        <Flex>
          <Icon
            icon={Icons.MapMarkerAltSolid}
            color={Colors.Grey700}
            margin="0px 4px 0px 0px"
            size={10}
          />
          <Text type={TextTypesEnum.Regular12} color={Colors.Grey700}>
            {`${ListingUtil.isValidPortfolioListing(offer?.listing) ? 'Multiple addresses' : AddressUtil.formatAddress(offer?.listing?.address, offer?.listing?.name ? {} : { address1: true }) || null}`}
          </Text>
        </Flex>
      </ListingInfoContainer>
    </Flex>
  );

  const BottomActionBar: React.FC = () => (
    <ActionBar>
      {offer?.loiFiles?.length > 0 && (
      <ActionBox onClick={(e) => {
        e.stopPropagation();
        viewLOI();
      }}
      >
        <Icon
          icon={Icons.FilePdfLight}
          color={Colors.Grey700}
          margin="0px 8px 0px 0px"
          size={12}
        />
        <Text type={TextTypesEnum.Medium12} color={Colors.Grey700}>
          View LOI
        </Text>
      </ActionBox>
      )}
      {((isSentOffer) || (!isSentOffer && OfferUtil.isActive(offer))) && (
      <ActionBox onClick={(e) => {
        e.stopPropagation();
        if (isSentOffer) {
          pushOfferModal();
        } else {
          pushRejectOfferModal();
        }
      }}
      >
        <Icon
          icon={isSentOffer ? Icons.RedoLight : Icons.ThumbsDownLight}
          color={Colors.Grey700}
          margin="0px 8px 0px 0px"
          size={12}
        />
        <Text type={TextTypesEnum.Medium12} color={Colors.Grey700}>
          {isSentOffer ? 'Revise offer' : 'Reject offer'}
        </Text>
      </ActionBox>
      )}
      {!isMobile && (
      <ActionBox onClick={(e) => {
        e.stopPropagation();
        window.open(ListingUtil.slug(offer?.listing), '_blank');
      }}
      >
        <Icon
          icon={Icons.ExternalLinkRegular}
          color={Colors.Grey700}
          margin="0px 8px 0px 0px"
          size={12}
        />
        <Text type={TextTypesEnum.Medium12} color={Colors.Grey700}>
          View Listing
        </Text>
      </ActionBox>
      )}
      {isSentOffer && !isMobile && OfferUtil.isActive(offer) && (
      <ActionBox onClick={(e) => {
        e.stopPropagation();
        pushWithdrawOfferModal();
      }}
      >
        <Icon
          icon={Icons.ThumbsDownLight}
          color={Colors.Grey700}
          margin="0px 8px 0px 0px"
          size={12}
        />
        <Text type={TextTypesEnum.Medium12} color={Colors.Grey700}>
          Withdraw offer
        </Text>
      </ActionBox>
      )}
      {isSentOffer && isMobile && (
      <>
        <ExtraMobileButtons>
          <ActionBox
            ref={ref}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Icon
              icon={Icons.EllipsisHRegular}
              color={Colors.Grey700}
              size={12}
            />
          </ActionBox>
        </ExtraMobileButtons>
        <AnchoredMenu
          anchorRef={ref}
          menuItems={menuItems}
        />
      </>
      )}
    </ActionBar>
  );

  /** Row Based Layout */
  const RowOfferCardView: React.FC = () => (
    <div data-cy="offer-card">
      <div>
        <OfferStatus offer={offer} />
        <Text type={TextTypesEnum.Bold24} color={Colors.Black} data-cy="offer-card-price">
          {`$${StringUtil.formatNumber(offer.purchasePrice)}`}
        </Text>
      </div>
      <Flex justify="space-between" margin="16px 0 0">
        <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
          Diligence period
        </Text>
        <Text type={TextTypesEnum.Medium14} color={Colors.Grey900}>
          {`${offer.dueDiligencePeriod} days`}
        </Text>
      </Flex>
      <Flex justify="space-between" margin="8px 0 0">
        <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
          Closing period
        </Text>
        <Text type={TextTypesEnum.Medium14} color={Colors.Grey900}>
          {`${offer.closingPeriod} days`}
        </Text>
      </Flex>
      <Flex justify="space-between" margin="8px 0 0">
        <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
          Deposit
        </Text>
        <Text type={TextTypesEnum.Medium14} color={Colors.Grey900}>
          {`$${StringUtil.formatNumber(offer.depositAmount)}`}
        </Text>
      </Flex>
      {offer.financingPeriod && (
      <Flex justify="space-between" margin="8px 0 0">
        <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
          Financing
        </Text>
        <Text type={TextTypesEnum.Medium14} color={Colors.Grey900}>
          {`${offer.financingPeriod} days`}
        </Text>
      </Flex>
      )}
      {offer.exclusivityPeriod && (
      <Flex justify="space-between" margin="8px 0 0">
        <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
          Exclusivity
        </Text>
        <Text type={TextTypesEnum.Medium14} color={Colors.Grey900}>
          {`${offer.exclusivityPeriod} days`}
        </Text>
      </Flex>
      )}
      {offer.otherTerms && !hideOtherTerms && (
      <Flex direction="column" margin="8px 0 0px">
        <Text type={TextTypesEnum.Regular14} color={Colors.Grey500} margin="0px 0px 8px">
          Additional terms
        </Text>
        <GreyBox>
          <Text type={TextTypesEnum.Regular16} color={Colors.Grey900}>
            {offer.otherTerms}
          </Text>
        </GreyBox>
      </Flex>
      )}
      {type === OfferCardTypeEnum.RowsWithActions && (
        <Flex margin="16px 0px 0px">
          {offer?.loiFiles?.length > 0 && (
            <>
              <Button
                text="View LOI"
                icon={Icons.FilePdfLight}
                iconColor={Colors.Grey900}
                iconSize={14}
                onClick={() => {
                  viewLOI();
                }}
                type={ButtonTypesEnum.Outline}
                size={ButtonSizesEnum.Large}
                isFullWidth
              />
              <Spacer />
            </>
          )}
          {OfferUtil.isActive(offer) && (
            <Button
              text="Reject offer"
              icon={Icons.ThumbsDownLight}
              iconColor={Colors.Grey900}
              iconSize={14}
              onClick={() => {
                pushRejectOfferModal();
              }}
              type={ButtonTypesEnum.Outline}
              size={ButtonSizesEnum.Large}
              isFullWidth
            />
          )}
        </Flex>
      )}
    </div>
  );

  /** Column Based Layout */
  const ColumnOfferCardView: React.FC = () => (
    <Container onClick={cardClick} data-cy="offer-card">
      <Flex align="center" padding="16px">
        {type === OfferCardTypeEnum.Received ? (
          <UserInfoLayout />
        ) : (
          <ListingLayout />
        )}
      </Flex>
      <Flex padding="0px 16px 16px">
        <div>
          <OfferStatus offer={offer} />
          <Text type={TextTypesEnum.Medium14} color={Colors.Black} data-cy="offer-card-price">
            {`$${StringUtil.formatNumber(offer.purchasePrice)}`}
          </Text>
        </div>
        <div>
          <Divider type={DividerTypesEnum.Vertical} margin="0px 24px" />
        </div>
        <div>
          <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
            Diligence period
          </Text>
          <Text type={TextTypesEnum.Medium14} color={Colors.Black}>
            {`${offer.dueDiligencePeriod} days`}
          </Text>
        </div>
        <div>
          <Divider type={DividerTypesEnum.Vertical} margin="0px 24px" />
        </div>
        <div>
          <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
            Closing period
          </Text>
          <Text type={TextTypesEnum.Medium14} color={Colors.Black}>
            {`${offer.closingPeriod} days`}
          </Text>
        </div>
        <div>
          <Divider type={DividerTypesEnum.Vertical} margin="0px 24px" />
        </div>
        <div>
          <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
            Deposit
          </Text>
          <Text type={TextTypesEnum.Medium14} color={Colors.Black}>
            {`$${StringUtil.formatNumber(offer.depositAmount)}`}
          </Text>
        </div>
        {offer.financingPeriod && (
        <>
          <div>
            <Divider type={DividerTypesEnum.Vertical} margin="0px 24px" />
          </div>
          <div>
            <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
              Financing
            </Text>
            <Text type={TextTypesEnum.Medium14} color={Colors.Black}>
              {`${offer.financingPeriod} days`}
            </Text>
          </div>
        </>
        )}
        {offer.exclusivityPeriod && (
        <>
          <div>
            <Divider type={DividerTypesEnum.Vertical} margin="0px 24px" />
          </div>
          <div>
            <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
              Exclusivity
            </Text>
            <Text type={TextTypesEnum.Medium14} color={Colors.Black}>
              {`${offer.exclusivityPeriod} days`}
            </Text>
          </div>
        </>
        )}
      </Flex>
      <BottomActionBar />
    </Container>
  );

  function MobileOfferCardView() {
    return (
      <Container onClick={cardClick}>
        <UserInfoMobileContainer>
          {hideOtherTerms && type === OfferCardTypeEnum.Sent ? (
            <ListingLayout />
          ) : (
            <UserInfoLayout />
          )}
        </UserInfoMobileContainer>
        <RowOfferCardMobileContainer>
          <RowOfferCardView />
        </RowOfferCardMobileContainer>
        <BottomActionBar />
      </Container>
    );
  }

  if (type === OfferCardTypeEnum.Rows || type === OfferCardTypeEnum.RowsWithActions) {
    return <RowOfferCardView />;
  } if (isMobile && (type === OfferCardTypeEnum.Received || type === OfferCardTypeEnum.Sent)) {
    return <MobileOfferCardView />;
  }
  return <ColumnOfferCardView />;
};

export default OfferCard;
