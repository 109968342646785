import React from 'react';
import styled from '@emotion/styled';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import { useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import PrivateEventUtil from '@biproxi/models/utils/PrivateEventUtil';
import PrivateEventStatesEnum from '@biproxi/models/enums/PrivateEventStatesEnum';
import JoiUtil from '@biproxi/models/utils/JoiUtil';
import * as PrivateEventJoi from '@biproxi/models/joi/privateEvent.joi';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Flex, { CustomSpacer } from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import Input, { InputTypesEnum } from '../../elements/Input';
import DateInput, { DateInputTypeEnum } from '../../elements/DateInput';
import UPDATE_PRIVATE_EVENT from '../../graphql/mutations/updatePrivateEvent.mutation';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { PrivateEventActions, PrivateEventSelectors } from '../../redux/privateEvent.redux';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';

const Container = styled.div`
  width: 600px;

  ${media.mobile} {
    width: 100%;
  }
`;

export type ModifyBiddingRoomModalProps = {
  privateEvent: IPrivateEventGraphQL;
};

const ModifyBiddingRoomModal: React.FC<ModifyBiddingRoomModalProps> = ({
  privateEvent,
}) => {
  /** State */
  const [fields, setFields] = React.useState<Partial<IPrivateEventGraphQL>>({
    startsAt: privateEvent.startsAt,
    endsAt: privateEvent.endsAt,
    settings: {
      startingBid: privateEvent.settings.startingBid,
      bidIncrement: privateEvent.settings.bidIncrement,
    },
    contingencies: {
      depositAmount: privateEvent.contingencies.depositAmount,
      dueDiligencePeriod: privateEvent.contingencies.dueDiligencePeriod,
      closingPeriod: privateEvent.contingencies.closingPeriod,
      additionalTerms: privateEvent.contingencies.additionalTerms,
    },
  });
  /** Hooks */
  const isMobile = useMobileMedia();

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );
  const setValidationErrors = (errors: Record<string, string>) => dispatch(
    PrivateEventActions.setErrors(errors),
  );
  const errors = useAppSelector(PrivateEventSelectors.errors);

  /** GraphQL */
  const [updatePrivateEvent, { loading }] = useMutation(UPDATE_PRIVATE_EVENT, {
    variables: {
      params: {
        privateEventId: privateEvent._id,
        updateParams: {
          startsAt: fields.startsAt,
          endsAt: fields.endsAt,
          settings: {
            startingBid: parseInt(fields.settings.startingBid as any, 10), // not sure what is going on with the weird typing here. TODO: fix
            bidIncrement: parseInt(fields.settings.bidIncrement as any, 10),
          },
          contingencies: {
            depositAmount: parseInt(fields.contingencies.depositAmount as any, 10),
            dueDiligencePeriod: parseInt(fields.contingencies.dueDiligencePeriod as any, 10),
            closingPeriod: parseInt(fields.contingencies.closingPeriod as any, 10),
            additionalTerms: fields.contingencies.additionalTerms,
          },
        },
      },
    },
    onCompleted: async () => {
      popModal();
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Private event successfully updated',
      });
    },
    onError: (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);

      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
  });

  /* Render */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e?.preventDefault();

    const { errors } = JoiUtil.validate(
      PrivateEventJoi.updatePrivateEventFrontendSchema,
      { ...fields },
    );

    if (JoiUtil.hasErrors(errors)) {
      setValidationErrors(JoiUtil.parseErrors(errors));
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'Please ensure all fields are valid.',
      });
      return;
    }

    updatePrivateEvent();
  };

  const inputHandler = (field: Partial<IPrivateEventGraphQL>) => {
    if (errors) setValidationErrors(null);
    setFields(field);
  };

  return (
    <ModalContainer>
      <ModalHeader title="Modify bidding room" close={popModal} />
      <ModalContent>
        <Container>
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Grey700}
            margin="0 0 16px 0"
          >
            All participants will be notified of the change.
          </Text>
          <Flex direction={isMobile && 'column'}>
            <Input
              label="Starting bid"
              placeholder="Enter a price"
              value={fields.settings.startingBid.toString()}
              onChange={(_e, unmasked) => inputHandler({ ...fields, settings: { ...fields.settings, startingBid: unmasked } })}
              inputType={InputTypesEnum.Currency}
              disabled={PrivateEventUtil?.getTotalBids(privateEvent) > 0}
              error={errors?.['/settings'] || errors?.['/settings/startingBid']}
              margin="0 0 24px"
            />
            {!isMobile && <CustomSpacer width="16px" />}
            <Input
              label="Bid increments"
              placeholder="Enter a bid increment"
              value={fields.settings.bidIncrement.toString()}
              onChange={(_e, unmasked) => inputHandler({ ...fields, settings: { ...fields.settings, bidIncrement: unmasked } })}
              inputType={InputTypesEnum.Currency}
              error={errors?.['/settings'] || errors?.['/settings/bidIncrement']}
              margin="0 0 24px"
            />
          </Flex>
          <Flex direction={isMobile && 'column'}>
            <DateInput
              label="Start Date"
              width="100%"
              value={fields.startsAt}
              onChange={(millis: number) => inputHandler({ ...fields, startsAt: millis })}
              inputType={DateInputTypeEnum.DateTime}
              disabled={privateEvent?.state === PrivateEventStatesEnum.InProgress}
              error={errors?.['/startsAt']}
              margin="0 0 24px"
            />
            {!isMobile && <CustomSpacer width="16px" />}
            <DateInput
              label="End Date"
              width="100%"
              value={fields.endsAt}
              onChange={(millis: number) => inputHandler({ ...fields, endsAt: millis })}
              inputType={DateInputTypeEnum.DateTime}
              error={errors?.['/endsAt']}
              margin="0 0 24px"
            />
          </Flex>
          <Input
            label="Earnest money deposit"
            placeholder="5,000"
            value={fields?.contingencies?.depositAmount?.toString()}
            onChange={(_e, unmasked) => inputHandler({
              ...fields,
              contingencies: {
                ...fields.contingencies,
                depositAmount: parseInt(unmasked, 10),
              },
            })}
            inputType={InputTypesEnum.Currency}
            margin="0 0 24px"
            error={errors?.['/contingencies'] || errors?.['/contingencies/depositAmount']}
          />
          <Flex direction={isMobile && 'column'}>
            <Input
              label="Due diligence period"
              placeholder="30"
              value={fields?.contingencies?.dueDiligencePeriod?.toString()}
              onChange={(_e, unmasked) => inputHandler({
                ...fields,
                contingencies: {
                  ...fields.contingencies,
                  dueDiligencePeriod: parseInt(unmasked, 10),
                },
              })}
              inputType={InputTypesEnum.Number}
              error={errors?.['/contingencies'] || errors?.['/contingencies/dueDiligencePeriod']}
              margin="0 0 24px"
            />
            {!isMobile && <CustomSpacer width="16px" />}
            <Input
              label="Closing period"
              placeholder="30"
              value={fields?.contingencies?.closingPeriod?.toString()}
              onChange={(_e, unmasked) => inputHandler({
                ...fields,
                contingencies: {
                  ...fields.contingencies,
                  closingPeriod: parseInt(unmasked, 10),
                },
              })}
              inputType={InputTypesEnum.Number}
              error={errors?.['/contingencies'] || errors?.['/contingencies/closingPeriod']}
              margin="0 0 24px"
            />
          </Flex>
          <Input
            label="Additional terms"
            width="100%"
            placeholder="Enter any additional terms"
            value={fields?.contingencies?.additionalTerms}
            onChange={(e) => inputHandler({
              ...fields,
              contingencies: {
                ...fields.contingencies,
                additionalTerms: e.target.value,
              },
            })}
            error={errors?.['/contingencies'] || errors?.['/contingencies/additionalTerms']}
            inputType={InputTypesEnum.TextArea}
            height="140px"
          />
        </Container>
      </ModalContent>
      <ModalFooter>
        <Flex justify="flex-end" width="100%">
          <Button
            text="Cancel"
            type={ButtonTypesEnum.Ghost}
            size={ButtonSizesEnum.Medium}
            onClick={() => popModal()}
            margin="0px 8px 0px 0px"
            htmlType="button"
          />
          <Button
            text="Modify bidding room"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            htmlType="submit"
            isLoading={loading}
            onClick={handleSubmit}
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default ModifyBiddingRoomModal;
