export enum BrowserEnum {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Edge = 'Edge',
}

export enum SafariVersionsEnum {
  Safari15Plus = 'Safari15Plus',
  Safari14 = 'Safari14',
  SafariPre14 = 'SafariPre14',
}

const detectBrowser = () => {
  const { userAgent } = navigator;
  let browser;
  let version;

  /** Detect browser. Once detected, determine version. */

  if (userAgent.includes('Chrome/')) {
    // const version = userAgent.split('Chrome/')[1].slice(0, userAgent.split('Chrome/')[1].indexOf(' '));

    browser = BrowserEnum.Chrome;
  }
  if (userAgent.includes('Firefox/')) {
    // const version = userAgent.split('Firefox/')[1];

    browser = BrowserEnum.Firefox;
  }
  if (userAgent.includes('Version/')) {
    const browserVersion = userAgent.split('Version/')[1].slice(0, userAgent.split('Version/')[1].indexOf(' '));

    if (browserVersion.includes('15')) {
      browser = BrowserEnum.Safari;
      version = SafariVersionsEnum.Safari15Plus;
    } else if (browserVersion.includes('14')) {
      browser = BrowserEnum.Safari;
      version = SafariVersionsEnum.Safari14;
    } else {
      browser = BrowserEnum.Safari;
      version = SafariVersionsEnum.SafariPre14;
    }
  }
  if (userAgent.includes('Edg/')) {
    // const version = userAgent.split('Edg/')[1];
    browser = BrowserEnum.Edge;
  }
  return {
    browser,
    version,
  };
};

const BrowserUtil = {
  detectBrowser,
};

export default BrowserUtil;
