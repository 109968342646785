import React from 'react';
import styled from '@emotion/styled';
import { CheckboxTypesEnum } from './Checkbox';
import Text, { TextAsEnum, TextTypesEnum } from './Text';
import Colors from '../styles/Colors';
import ISelectionBoxConfig from '../models/interfaces/ISelectionBoxConfig';
import BoxShadows from '../styles/BoxShadows';
import Icon from './Icon';
import Icons from './Icons';
import Flex from './Flex';
import { media, useMobileMedia } from '../utils/MediaQuery';

type ContainerProps = {
  margin?: string;
  width?: string;
}

const Container = styled.div<ContainerProps>`
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  ${media.mobile} {
    width: 100%;
  }
`;

type CheckboxItemContainerProps = {
    active: boolean;
    margin: string;
}

const CheckboxItemContainer = styled.div<CheckboxItemContainerProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${({ active }) => (active ? Colors.Brand50 || Colors.Blurple50 : null)};
    padding: 8px;
    margin: ${(props) => props.margin};
    border-radius: 4px;
`;

type BoxProps = {
    active: boolean;
    onClick?: () => void;
    type: CheckboxTypesEnum;
}

const Box = styled.div<BoxProps>`
  height: ${(props) => (props.type === CheckboxTypesEnum.Small ? '16px' : '24px')};
  width: ${(props) => (props.type === CheckboxTypesEnum.Small ? '16px' : '24px')};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
  border-radius: 2px;
  background-color: ${(props) => (props.active ? Colors.Brand700 || Colors.Blurple700 : null)};
  border: ${(props) => (props.active ? null : props.type === CheckboxTypesEnum.Small ? `1px solid ${Colors.Grey400}` : `2px solid ${Colors.Grey400}`)};
  box-sizing: border-box;
  margin-right: 8px;
  cursor: pointer;
  &:hover {
    box-shadow: ${BoxShadows.Darker};
  }
`;

type CheckboxItemProps = {
    active: boolean; // an item is active if a user clicks on the container (onClick)
    selected?: boolean; // an item is selected is a user clicks on the checkbox (onCheckboxClick)
    label?: string;
    labelTextType?: TextTypesEnum
    checkboxType: CheckboxTypesEnum;
    expansive: boolean;
    onClick?: () => void; // onClick handler for when you click on the entire container div
    onCheckboxClick?: () => void; // onClick handler for when you click on the checkbox
    margin?: string;
    displayAsColumn?: boolean;
    children?: React.ReactNode;
    'data-cy'?: string;
}

const CheckboxItem: React.FC<CheckboxItemProps> = ({
  active,
  selected,
  label,
  labelTextType = TextTypesEnum.Regular12,
  checkboxType = CheckboxTypesEnum.Small,
  expansive,
  onClick,
  onCheckboxClick,
  margin = '0px',
  displayAsColumn,
  children,
  'data-cy': dataCy,
}) => {
  /** Hooks */
  const isMobile = useMobileMedia();
  displayAsColumn = isMobile ? true : displayAsColumn;
  return (
    <>
      <CheckboxItemContainer data-cy={dataCy && (`${dataCy}-container`)} active={active} onClick={() => onClick?.()} margin={margin}>
        <Flex>
          <Box data-cy={dataCy} active={selected} onClick={() => onCheckboxClick?.()} type={checkboxType}>
            {selected && <Icon icon={Icons.MinusRegular} color={Colors.White} size={10} />}
          </Box>
          {label && <Text type={labelTextType} as={TextAsEnum.Label}>{label}</Text>}
        </Flex>
        {expansive && (
          displayAsColumn ? (
            <Icon icon={active ? Icons.AngleUpRegular : Icons.AngleDownRegular} color={Colors.Grey400} onClick={() => onClick?.()} size={12} />
          ) : (
            <Icon icon={Icons.AngleRightRegular} color={Colors.Grey400} size={12} />
          )

        )}

      </CheckboxItemContainer>
      {active && displayAsColumn && children}
    </>
  );
};

export interface IExpansiveCheckboxConfig extends Omit<ISelectionBoxConfig, 'active'|'onClick'> {
    onCheckboxClick: () => void;
    onClick?: () => void;
    selected?: boolean;
    active?: boolean;
}

type ExpansiveCheckboxListProps = {
  margin?: string;
  listItemMargin?: string;
  expansive?: boolean;
  config: IExpansiveCheckboxConfig[];
  children?: React.ReactNode;
  displayAsColumn?: boolean;
  width?: string;
}

/**
 * Very similar to the CheckboxList component, but with a few differences:
 * (1) The checkbox items have both a 'selected' and 'active' state:
 * The active state is set when a user clicks anywhere within the item that is NOT the checkbox
 * The selected state is set when a user clicks on the checkbox
 * (2) This component has icons that are displayed on the right side when 'expansive' is set to true
 * (3) This component can take in children components that can be rendered underneath an Active item...Currently this is only used for mobile views but could be expanded upon to work accordingly for desktop views as well
 */
const ExpansiveCheckboxList: React.FC<ExpansiveCheckboxListProps> = ({
  config,
  margin,
  listItemMargin,
  expansive = true,
  displayAsColumn = false,
  width = '100%',
  children,
}) => (
  <Container data-cy="save-search-asset-class-checkbox-list" margin={margin} width={width}>
    {config.map((config, index) => (
      <CheckboxItem
        data-cy={`save-search-${config.label.replace(/ /g, '-').toLowerCase()}-checkbox`}
        key={index}
        active={config.active}
        selected={config.selected}
        checkboxType={config.type}
        label={config.label}
        labelTextType={config.labelTextType}
        expansive={expansive}
        onClick={config.onClick}
        onCheckboxClick={config.onCheckboxClick}
        margin={listItemMargin}
        displayAsColumn={displayAsColumn}
      >
        {children}
      </CheckboxItem>
    ))}
  </Container>
);
export default ExpansiveCheckboxList;
