/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from '@emotion/styled';
import * as Polished from 'polished';
import Link from 'next/link';
import { useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { logger } from '@biproxi/logger';
import UPDATE_BASIC_USER_INFORMATION from '../../graphql/mutations/updateBasicUserInformation.mutation';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import {
  ModalContainer,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Icon, { Icons } from '../../elements/Icon';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import Flex from '../../elements/Flex';
import GET_USER from '../../graphql/queries/user.query';
import useUser from '../../hooks/useUser.hook';

const ModalWrapper = styled.div`
  max-width: 480px;
`;

const ModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  background-color: ${Colors.Brand700 || Colors.Blurple700};
  border-radius: 0 0 16px 16px;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: ${Colors.Brand800 || Colors.Blurple800};
  }
`;

const FeatureListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
`;

const PromoInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const PromoCodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 12px;
  width: 420px;
  height: 40px;
  background: ${Colors.Brand100 || Colors.Blurple100};
  border-radius: 4px;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background: ${Polished.darken(0.05, Colors.Brand100 || Colors.Blurple100)};
  }
`;

export type LoginUpgradeAccountReminderModalProps = {
  modalHeader?: string;
  promoCode?: string;
}

const LoginUpgradeAccountReminderModal: React.FC<LoginUpgradeAccountReminderModalProps> = ({
  modalHeader = 'Upgrade to Pro to take advantage of all features such as:',
  promoCode = 'EARLYACCESS99',
}) => {
  /** State */
  const featureList: string[] = [
    'Host unlimited Private Events™',
    'Access to premium data sets (coming soon)',
    'Access to Buyer Matching',
    'Unlimited off-market listing support with Private Listings™',
    'In-app chat & email support',
  ];

  /** Hooks */
  const { user } = useUser();
  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /** GraphQL */
  const [updateBasicUserInfoMutation] = useMutation(UPDATE_BASIC_USER_INFORMATION, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_USER }],
    variables: {
      params: {
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        viewedSubscriptionEarlyAccessModal: true,
      },
    },
    onCompleted: () => {
      popModal();
    },
    onError: async (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);
      logger.warn(`Failed to update viewedSubscriptionEarlyAccessModal field in mutation in LoginUpgradeAccountReminderModal: ${message}`);
    },
  });

  /** Functions */
  const handleCopyPromoCodeToClipboard = () => {
    navigator.clipboard.writeText(promoCode);
    pushToast({
      type: ToastTypesEnum.Notification,
      message: 'Promo code copied to clipboard',
    });
  };

  return (
    <ModalContainer
      height="fit-content"
      data-cy="upgrade-reminder-modal"
    >
      <ModalWrapper>
        <ModalContent>
          <ModalHeaderWrapper>
            <Text
              type={TextTypesEnum.Medium18}
              color={Colors.Black}
              align="left"
            >
              {modalHeader}
            </Text>
            <Icon
              data-cy="close-upgrade-reminder-modal"
              icon={Icons.TimesRegular}
              size={24}
              color={Colors.Brand700 || Colors.Blurple700}
              onClick={updateBasicUserInfoMutation}
            />
          </ModalHeaderWrapper>
          <FeatureListWrapper>
            <ul style={{ margin: 0, padding: 0 }}>
              {featureList?.map((feature: string) => (
                <li>
                  <Text
                    color={Colors.Grey700}
                  >
                    {feature}
                  </Text>
                </li>
              ))}
            </ul>
          </FeatureListWrapper>
          <PromoInfoWrapper>
            <Text
              color={Colors.Grey900}
            >
              Use promo code
            </Text>
            <PromoCodeWrapper
              onClick={handleCopyPromoCodeToClipboard}
            >
              <Text
                type={TextTypesEnum.Medium16}
                color={Colors.Brand700 || Colors.Blurple700}
              >
                {promoCode}
              </Text>
            </PromoCodeWrapper>
            <Flex gap="3px">
              <Text
                color={Colors.Green500}
                type={TextTypesEnum.Bold16}
              >
                to save $100/month
              </Text>
              <Text
                color={Colors.Grey900}
              >
                for the first 6 months
              </Text>
            </Flex>
          </PromoInfoWrapper>
        </ModalContent>
        <Link
          href="/app/dashboard/settings/billing"
          target="_blank"
          style={{ width: '100%' }}
        >
          <FooterContainer
            data-cy="paywall-checkout-button"
          >
            <Text
              color={Colors.White}
              type={TextTypesEnum.Bold18}
            >
              Upgrade now
            </Text>
          </FooterContainer>
        </Link>
      </ModalWrapper>
    </ModalContainer>
  );
};

export default LoginUpgradeAccountReminderModal;
