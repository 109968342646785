enum ListingRequiredFieldsEnum {
    Address = 'Address',
    PortfolioAddresses = 'Portfolio Addresses', // at least 2 required when flag is set
    Image = 'Image',
    PropertyDetails = 'Property Details',
    AssetClass = 'Asset Class',
    PropertyType = 'Property Type',
    Description = 'Description',
    License = 'License',
    DealProfile = 'DealProfile', // only required if at least one DealProfile field filled out
    DueDiligence = 'DueDiligence', // for use when subfolders are unnamed
}

export default ListingRequiredFieldsEnum;
