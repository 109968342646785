import gql from 'graphql-tag';
import UserFragments from '../fragments/user.fragments';

const GET_USER = gql`
  query User {
    user {
      ...UserFields
      ...UserAddress
      ...UserInvestorProfile
      ...UserLicenses
      ...UserProfileImageFile
      ...UserOrganization
      ...UserOAuthProviders
      ...UserNotificationSettings
      ...UserNylasSettings
      ...UserContacts
    }
  }

  ${UserFragments.fields}
  ${UserFragments.address}
  ${UserFragments.investorProfile}
  ${UserFragments.licenses}
  ${UserFragments.profileImageFile}
  ${UserFragments.organization}
  ${UserFragments.oAuthProviders}
  ${UserFragments.notificationSettings}
  ${UserFragments.nylasSettings}
  ${UserFragments.contacts}
`;

export default GET_USER;
