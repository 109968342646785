import { ApolloError as ApolloClientError } from '@apollo/client/core';
// import { ApolloError as ApolloServerError } from 'apollo-server-errors';
import { GraphQLError as ApolloServerError } from 'graphql';
import { IResponse, IError, IAuthorizationData } from '../interfaces/common';
import APIUtil from './APIUtil';

const handleApolloServerError = (response: IResponse): ApolloServerError => {
  const { status } = response;

  if (!APIUtil.isErrorResponse(response)) {
    return new ApolloServerError('An unknown error occurred', {
      extensions: { code: status.toString() },
    });
  }

  const { error } = response;

  if (APIUtil.isFieldError(error)) {
    return new ApolloServerError(error.message, {
      extensions: { code: status.toString(), fields: error.fields },
    });
  }
  return new ApolloServerError(error.message, {
    extensions: { code: status.toString() },
  });
};

function parseApolloClientError(error: ApolloClientError, paramsPath = '/params'): IError {
  const fields = convertRequestErrorToParamsError(error, paramsPath);

  return {
    message: error.message,
    fields,
  };
}

function convertRequestErrorToParamsError(error: ApolloClientError, paramsPath = '/params'): Record<string, string> | undefined {
  const errorFields = error?.graphQLErrors?.[0]?.extensions?.fields ?? null;

  if (errorFields) {
    return Object.entries(errorFields).reduce((cur: Record<string, string>, [pointerKey, value]) => {
      const newPointerKey = pointerKey.substring(paramsPath.length);
      cur[newPointerKey] = value as string;
      return cur;
    }, {});
  }

  return undefined;
}

function authorizationFromContext(context: any): IAuthorizationData {
  return context?.req?.authInfo ?? context?.authInfo ?? null;
}

const ApolloUtil = {
  handleApolloServerError, // 114 uses
  parseApolloClientError, // 53 uses
  authorizationFromContext, // 122 uses
};

export default ApolloUtil;
