import gql from 'graphql-tag';

const UPDATE_USER_ORG_ROLE = gql`
  mutation UpdateUserOrgRole($where: UserWhere, $update: UserUpdateInput) {
    updateUsers(where: $where, update: $update) {
      info {
        relationshipsCreated
      }
      users {
        _id
        email
        firstName
        lastName
        organizationConnection {
          edges {
            roles
            permissions
          }
        }
      }
    }
  }
`;

export default UPDATE_USER_ORG_ROLE;
