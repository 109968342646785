import React from 'react';
import styled from '@emotion/styled';

/** An image to render for listing card thumbnails on Safari pre version 15. This is necessary because SafariPre15
 * does not have 'aspect-ratio' property support and because it plays very poorly with the Next Image component.
 */

const SafariImageContainer = styled.div<{ isOnDashboard: boolean }>`
  position: relative;
  width: 100%;
  max-height: ${({ isOnDashboard }) => (isOnDashboard ? '150px' : '300px')};

  img {
    min-height: '100%';
  }
`;

type ImagePreSafari15Props = {
  src: string;
  isOnDashboard: boolean;
};

const ImagePreSafari15: React.FC<ImagePreSafari15Props> = ({ src, isOnDashboard }) => (
  <SafariImageContainer isOnDashboard={isOnDashboard}>
    <img
      src={src}
      width="100%"
      height="100%"
      alt="Listing thumbnail"
      loading="lazy"
    />
  </SafariImageContainer>
);

export default ImagePreSafari15;
