import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';

export enum LoaderSizes {
  ExtraSmall = 'ExtraSmall',
  VerySmall = 'VerySmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large'
}

const LoaderSizesMap: Record<LoaderSizes, number> = {
  [LoaderSizes.ExtraSmall]: 20,
  [LoaderSizes.VerySmall]: 24,
  [LoaderSizes.Small]: 30,
  [LoaderSizes.Medium]: 40,
  [LoaderSizes.Large]: 60,
};

type StyledLoaderProps = {
  size: number;
  color: string;
}

const scale = (size: number, scale: number) => `${size * scale}px`;

const StyledLoader = styled.div<StyledLoaderProps>`
  position: relative;
  top: 1.5px;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: ${(props) => scale(props.size, 1)};
    height: ${(props) => scale(props.size, 1)};
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => scale(props.size, 0.8)};
    height: ${(props) => scale(props.size, 0.8)};
    margin: ${(props) => scale(props.size, 0.1)};
    border: ${(props) => scale(props.size, 0.066)} solid ${(props) => props.color};
    border-radius: 50%;
    animation: lds-ring 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.color} transparent transparent transparent;
  }
  .lds-ring div:nth-of-type(1) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-of-type(2) {
    animation-delay: -0.2s;
  }
  .lds-ring div:nth-of-type(3) {
    animation-delay: -0.1s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

type LoaderProps = {
  size?: LoaderSizes;
  color?: string;
  'data-cy'?: string;
};

const Loader: React.FC<LoaderProps> = ({
  size = LoaderSizes.Medium,
  color = Colors.White,
  'data-cy': dataCy,
}) => (
  <StyledLoader
    size={LoaderSizesMap[size]}
    color={color}
    data-cy={dataCy}
  >
    <div className="lds-ring">
      <div />
      <div />
      {/* <div></div> */}
      <div />
    </div>
  </StyledLoader>
);

export default Loader;
