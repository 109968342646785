import React from 'react';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import Wait from '@biproxi/utils/wait';
import { config } from '@biproxi/env';
import { initializeApollo } from '../graphql/client';
import { AppActions } from '../redux/app.redux';
import { useAppDispatch } from '../redux/store';
import { IToastConfig, ToastTypesEnum } from '../components/Toast';
import SEND_PHONE_NUMBER_VERIFICATION_CODE from '../graphql/mutations/sendPhoneNumberVerification.mutation';

export type UseVerifyPhoneNumber = {
  loading: boolean;
  startRecaptcha: () => Promise<string | null>;
  sendPhoneNumberVerificationSMS: (recaptchaToken: string) => Promise<any>;
};

export type UseVerifyPhoneNumberParams = {
  recaptchaRef: React.MutableRefObject<any>;
  setFieldErrors: (fields: any) => void;
  setError: (message: string) => void;
}

type UseVerifyPhoneNumberHook = (params: UseVerifyPhoneNumberParams) => UseVerifyPhoneNumber;

const useVerifyPhoneNumberHook: UseVerifyPhoneNumberHook = (params: UseVerifyPhoneNumberParams) => {
  /** State */
  const [loading, setLoading] = React.useState(false);
  const client = initializeApollo();

  /** Actions */
  const dispatch = useAppDispatch();

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const sendPhoneNumberVerificationSMS = React.useCallback(async (recaptchaToken: string) => {
    try {
      await client.mutate({
        mutation: SEND_PHONE_NUMBER_VERIFICATION_CODE,
        variables: {
          params: {
            recaptchaToken,
          },
        },
      });
      setLoading(false);
      return true;
    } catch (e) {
      setLoading(false);
      const { message, fields } = ApolloUtil.parseApolloClientError(e);

      if (fields) {
        params.setFieldErrors(fields);
      } else {
        params.setError(message);
      }
      return false;
    }
  }, []);

  const startRecaptcha = React.useCallback(async () => {
    setLoading(true);

    try {
      let token;

      if (config.NEXT_PUBLIC_ENVIRONMENT_NAME === 'test') {
        token = 'test-v2-token';
      } else {
        token = await Promise.race([Wait(20000, null), params.recaptchaRef.current.executeAsync()]);
      }

      if (!token) {
        throw new Error('Invalid recaptcha token. Try again.');
      }

      return token;
    } catch (e) {
      setLoading(false);
      pushToast({
        type: ToastTypesEnum.Error,
        message: e.message,
      });
      return null;
    }
  }, []);

  return {
    loading,
    startRecaptcha,
    sendPhoneNumberVerificationSMS,
  };
};

export default useVerifyPhoneNumberHook;
