import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Icon, { Icons } from '../elements/Icon';
import Flex from '../elements/Flex';
import Button, { ButtonTypesEnum, ButtonSizesEnum } from '../elements/Button';

export enum InlineAlertTypesEnum {
  GreenNotice = 'Green Notice',
  BlurpleNotice = 'Blurple Notice',
  GreyNotice = 'Grey Notice',
  YellowWarning = 'Yellow Warning',
  RedWarning = 'Red Warning',
}

export enum InlineAlertStylesEnum {
  NoTop ='No Top',
  WithTop = 'With Top',
  WithButtons = 'With Buttons',
}

type ContainerProps = {
    styleOption: InlineAlertStylesEnum,
    color: string;
    margin?: string;
}

const Container = styled.div<ContainerProps>`
margin: ${(props) => (props.margin ? props.margin : null)};
  min-height: ${(props) => {
    switch (props.styleOption) {
      case InlineAlertStylesEnum.NoTop:
        return '40px';
      case InlineAlertStylesEnum.WithTop:
        return '80px';
      case InlineAlertStylesEnum.WithButtons:
        return '120px';
      default:
        return '40px';
    }
  }};
  display: flex;
  background: ${(props) => props.color};
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
`;

const ContentContainer = styled.div<{ styleOption: string }>`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const Rectangle = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  border-radius: 4px 0px 0px 4px;
  min-width: 4px;
  max-width: 4px;
`;

type ButtonProps = {
    text: string;
    onClick: () => void;
}

type InlineAlertProps = {
  type: InlineAlertTypesEnum;
  text: string,
  margin?: string;
  styleOption?: InlineAlertStylesEnum;
  dataCy?: string,
  label?: string,
  buttonOne?: ButtonProps;
  buttonTwo?: ButtonProps;
}

const InlineAlert: React.FC<InlineAlertProps> = ({
  type,
  text,
  margin,
  styleOption = InlineAlertStylesEnum.NoTop,
  dataCy,
  label,
  buttonOne,
  buttonTwo,
}) => {
  const icon = type.includes('Notice') ? Icons.CheckCircleSolid : Icons.ExclamationTriangleSolid;
  let rectangleColor;
  let iconColor;
  let backgroundColor;
  let labelColor;

  /** Determine the rectangle, icon, container background, and label color based on alert type */
  switch (type) {
    case InlineAlertTypesEnum.GreenNotice:
      rectangleColor = Colors.Green400;
      iconColor = Colors.Green500;
      backgroundColor = Colors.Green50;
      labelColor = Colors.Green900;
      break;
    case InlineAlertTypesEnum.BlurpleNotice:
      rectangleColor = Colors.Brand700 || Colors.Blurple700;
      iconColor = Colors.Brand500 || Colors.Blurple500;
      backgroundColor = Colors.Brand50 || Colors.Blurple50;
      labelColor = Colors.Brand700 || Colors.Blurple700;
      break;
    case InlineAlertTypesEnum.GreyNotice:
      rectangleColor = Colors.Grey900;
      iconColor = Colors.Grey700;
      backgroundColor = Colors.Grey100;
      labelColor = Colors.Black;
      break;
    case InlineAlertTypesEnum.YellowWarning:
      rectangleColor = Colors.Yellow400;
      iconColor = Colors.Yellow400;
      backgroundColor = Colors.Yellow50;
      labelColor = Colors.Yellow900;
      break;
    case InlineAlertTypesEnum.RedWarning:
      rectangleColor = Colors.Red400;
      iconColor = Colors.Red400;
      backgroundColor = Colors.Red50;
      labelColor = Colors.Red900;
      break;
    default:
      rectangleColor = Colors.Green400;
      iconColor = Colors.Green500;
      backgroundColor = Colors.Green50;
      labelColor = Colors.Green900;
  }

  return (
    <Container color={backgroundColor} styleOption={styleOption} margin={margin}>
      <Rectangle color={rectangleColor} />
      <ContentContainer styleOption={styleOption}>
        {(styleOption === InlineAlertStylesEnum.WithTop || styleOption === InlineAlertStylesEnum.WithButtons) && (
          <Flex align="center" margin="0px 0px 8px 0px">
            <Icon
              icon={icon}
              color={iconColor}
              size={16}
              margin="0px 8px 0px 0px"
            />
            <Text
              type={TextTypesEnum.Regular16}
              color={labelColor}
            >
              {label}
            </Text>
          </Flex>
        )}
        <Text
          type={TextTypesEnum.Regular16}
          color={Colors.Black}
          margin={styleOption === InlineAlertStylesEnum.NoTop ? '0px 0px 0px 0px' : '0px 0px 8px 0px'}
        >
          {text}
        </Text>
        {styleOption === InlineAlertStylesEnum.WithButtons && (
        <Flex justify="flex-start">
          <Button
            data-cy={dataCy}
            text={buttonOne?.text}
            onClick={() => {
              buttonOne.onClick();
            }}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
          />
          {buttonTwo && (
          <Button
            text={buttonTwo?.text}
            onClick={() => {
              buttonTwo.onClick();
            }}
            type={ButtonTypesEnum.Outline}
            size={ButtonSizesEnum.Medium}
            margin="0px 0px 0px 8px"
          />
          )}
        </Flex>
        )}
      </ContentContainer>
    </Container>
  );
};

export default InlineAlert;
