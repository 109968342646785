import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import IFile from '@biproxi/models/interfaces/IFile';
import 'react-image-crop/dist/ReactCrop.css';
import { useMutation } from '@apollo/client';
import ReactTooltip from 'react-tooltip';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import Button, { ButtonTypesEnum, ButtonSizesEnum } from '../../elements/Button';
import { AppActions } from '../../redux/app.redux';
import Flex, { CustomSpacer } from '../../elements/Flex';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import Colors from '../../styles/Colors';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import CREATE_FILES from '../../graphql/mutations/createFiles.mutation';
import { ListingActions } from '../../redux/listing.redux';
import CropImage from '../CropImage';
import Loader, { LoaderSizes } from '../../elements/Loader';

const Container = styled.div`
  overflow-y: auto;
  min-width: 480px;
  max-width: 724px;
  max-height: 80vh;

  ${media.mobile} {
    min-width: 0px;
    width: 100%;
  }
`;

const CroppedImage = styled.img`
  z-index: 2;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  min-height: 300px;
  position: relative;
`;

const LoaderContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type AspectSelectionProps = {
  active: boolean;
  width: string;
  height: string;
  disabled: boolean;
}

const AspectSelection = styled.div<AspectSelectionProps>`
  background: ${({ active }) => (active ? Colors.Brand100 || Colors.Blurple100 : Colors.Grey50)};
  border: ${({ active }) => `1px dashed ${active ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey300}`};
  border-radius: 4px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export enum ListingImageTypeEnum {
  Primary = 'Primary',
  Highlight = 'Highlight',
  Other = 'Other',
}

enum AspectTypeEnum {
  Widescreen = 16 / 9,
  Fullscreen = 4 / 3,
}

export type CropListingImageModalProps = {
  file: IFile;
  type: ListingImageTypeEnum;
};

const CropListingImageModal: React.FC<CropListingImageModalProps> = ({
  file,
  type = ListingImageTypeEnum.Primary,
}) => {
  /* State */
  const [blob, setBlob] = React.useState<Blob>();
  const [aspect, setAspect] = React.useState<number>(
    type === ListingImageTypeEnum.Highlight
      ? AspectTypeEnum.Fullscreen
      : AspectTypeEnum.Widescreen,
  );

  /* Actions */
  const dispatch = useDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));
  const swapListingMediaFilesAfterCrop = (originalFileId: string, newFileId: string, newFile: IFile) => {
    dispatch(
      ListingActions.swapListingMediaFilesAfterCrop({
        originalFileId,
        newFileId,
        newFile,
      }),
    );
    popModal();
    pushToast({
      type: ToastTypesEnum.Notification,
      message: 'Image cropped successfully.',
    });
  };

  const pushErrorToast = () => {
    pushToast({
      type: ToastTypesEnum.Error,
      message: 'There was an error cropping your image. Please try again or contact support.',
    });
  };

  /** GraphQL */
  const [uploadFiles, { loading }] = useMutation(CREATE_FILES, {
    onCompleted: async (data) => {
      const newFile = data?.createFiles?.[0];
      if (newFile?._id) {
        swapListingMediaFilesAfterCrop(file._id, newFile._id, newFile);
      }
    },
    onError: () => {
      pushErrorToast();
    },
  });

  /** Effects */
  React.useLayoutEffect(() => {
    ReactTooltip.hide();
    ReactTooltip.rebuild();
  });

  /** Refs */
  type CropHandle = React.ElementRef<typeof CropImage>;
  const cropRef = React.useRef<CropHandle>(null);

  /** Render */
  const isMobile = useMobileMedia();
  return (
    <ModalContainer>
      <ModalHeader title="Crop image" close={popModal} />
      <ModalContent>
        <Container>
          <ImageContainer>
            <LoaderContainer>
              <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
            </LoaderContainer>
            {blob ? (
              <CroppedImage
                src={window.URL.createObjectURL(blob)}
                width={isMobile ? '100%' : '724px'}
              />
            ) : (
              <CropImage
                imageUrl={file.url}
                mimetype={file.mimetype}
                aspect={aspect}
                imageWidth={isMobile ? '100%' : '724px'}
                onError={pushErrorToast}
                setBlob={setBlob}
                ref={cropRef}
              />
            )}
          </ImageContainer>
          <Flex
            width="100%"
            justify="space-between"
            margin="24px 0 0"
            direction={isMobile ? 'column' : 'row'}
          >
            <Flex
              margin={isMobile ? '0 0 16px 0' : null}
              justify="right"
            >
              <AspectSelection
                width="62.32px"
                height="32px"
                active={aspect === AspectTypeEnum.Widescreen}
                disabled={type === ListingImageTypeEnum.Highlight}
                data-tip={type === ListingImageTypeEnum.Highlight
                  ? 'Widescreen is disabled for highlight images'
                  : null}
                onClick={() => {
                  if (!(type === ListingImageTypeEnum.Highlight)) {
                    setAspect(AspectTypeEnum.Widescreen);
                  }
                }}
              />
              <CustomSpacer width="8px" />
              <AspectSelection
                width="37.05px"
                height="32px"
                active={aspect === AspectTypeEnum.Fullscreen}
                disabled={type === ListingImageTypeEnum.Primary}
                data-tip={type === ListingImageTypeEnum.Primary
                  ? 'Fullscreen is disabled for primary images'
                  : null}
                onClick={() => {
                  if (!(type === ListingImageTypeEnum.Primary)) {
                    setAspect(AspectTypeEnum.Fullscreen);
                  }
                }}
              />
            </Flex>
            <Flex
              justify="right"
            >
              <Button
                type={ButtonTypesEnum.Outline}
                size={ButtonSizesEnum.Small}
                margin="0 8px 0 0"
                text={blob ? 'Re-edit' : 'Cancel'}
                customWidth="121px"
                onClick={() => (blob ? setBlob(null) : popModal())}
              />
              <Button
                type={ButtonTypesEnum.Primary}
                size={ButtonSizesEnum.Small}
                text={blob ? 'Confirm' : 'Crop'}
                isLoading={loading}
                customWidth="121px"
                onClick={() => {
                  if (blob) {
                    uploadFiles({
                      variables: {
                        params: {
                          name: file.name,
                          description: file.name,
                          mimetype: file.mimetype,
                          encoding: 'utf8',
                          upload: blob,
                        },
                      },
                    });
                  } else {
                    cropRef?.current?.executeCrop();
                  }
                }}
              />
            </Flex>
          </Flex>
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default CropListingImageModal;
