import gql from 'graphql-tag';
import { IOfferGraphQL } from '@biproxi/models/interfaces/IOffer';
import { DocumentNode } from 'graphql';
import UserFragments from './user.fragments';
import ListingFragments from './listing.fragments';

// Not a perfect type, but it's good enough for now
type TOfferFragments = {
  [K in keyof IOfferGraphQL]?: DocumentNode;
} & {
  offerFields: DocumentNode;
}

const OfferFragments: TOfferFragments = {
  offerFields: gql`
    fragment OfferFields on Offer {
      _id
      leadId
      userId
      listingId
      listingUserId
      listingOrganizationId
      purchasePrice
      depositAmount
      dueDiligencePeriod
      closingPeriod
      financingPeriod
      exclusivityPeriod
      loiFileIds
      otherTerms
      coverLetter
      state
      offerNumber
      hasRevisedOffers
    }
  `,
  loiFiles: gql`
    fragment OfferLoiFiles on Offer {
      loiFiles {
        _id
        name
        mimetype
        url
      }
    }
  `,
  user: gql`
    fragment OfferUser on Offer {
      user {
        ...UserFields
        ...UserProfileImageFile
      }
    }

    ${UserFragments.fields}
    ${UserFragments.profileImageFile}
  `,
  listing: gql`
    fragment OfferListing on Offer {
      listing {
        ...ListingFields
        ...ListingAddress
        ...ListingPortfolioAddresses
        ...ListingMedia
#        ...ListingUser  // broken
      }
    }

    ${ListingFragments.fields}
    ${ListingFragments.address}
    ${ListingFragments.portfolioAddresses}
    ${ListingFragments.media}
  `,
};

export default OfferFragments;
