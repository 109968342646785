import OfferStateEnum from '../enums/OfferStateEnum';
import IOffer from '../interfaces/IOffer';
import StringUtil from './StringUtil';

const isActive = (offer: IOffer) => (offer.state === OfferStateEnum.New) || (offer.state === OfferStateEnum.Active);

/**
 * Used in OfferStatus and ListingReport
 * but couldn't bring out of offer status because the colors file is in web
 * and not models.
 */
const getOfferStateText = (offer: IOffer) => {
  switch (offer.state) {
    case OfferStateEnum.New:
    case OfferStateEnum.Old:
    case OfferStateEnum.Active:
      return {
        text: `${StringUtil.addNumberOrdinals(offer.offerNumber)} Offer`,
      };
    case OfferStateEnum.Rejected:
      return {
        text: offer.state,
      };
    case OfferStateEnum.Withdrawn:
      return {
        text: offer.state,
      };
    case OfferStateEnum.Accepted:
      return {
        text: offer.state,
      };
    default:
      return {
        text: `${StringUtil.addNumberOrdinals(offer.offerNumber)} Offer`,
      };
  }
};

const OfferUtil = {
  isActive,
  getOfferStateText,
};

export default OfferUtil;
