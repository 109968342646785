import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

// Not a perfect type, but it's good enough for now
type TFileFragments = {
  fields: DocumentNode;
}

const FileFragments: TFileFragments = {
  fields: gql`
    fragment FileFields on File {
      _id
      organizationId
      name
      description
      mimetype
      encoding
      url
      meta {
        createdAt
        createdBy
      }
    }
  `,
};

export default FileFragments;
