enum ListingVaultFolderNamesEnum {
  OfferingMemorandum = 'Offering Memorandum',
  PropertyInformation = 'Property Information',
  'Operation&Financials' = 'Operation & Financials',
  ThirdPartyReports = 'Third Party Reports',
  Legal = 'Legal',
  Other = 'Other',
}

export default ListingVaultFolderNamesEnum;
