import React from 'react';
import styled from '@emotion/styled';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import UserUtil from '@biproxi/models/utils/UserUtil';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Flex from '../elements/Flex';
import ProfileImage, { ProfileImageTypes } from './ProfileImage';
import Icons from '../elements/Icons';
import Icon from '../elements/Icon';
import Divider, { DividerTypesEnum } from '../elements/Divider';
import { useAppDispatch } from '../redux/store';
import { LeadModalPageStateEnum } from './modal/LeadModal';
import { LeadActions } from '../redux/lead.redux';
import Tag from '../elements/Tag';

const Container = styled.div<{ margin: string }>`
  display: flex;
  margin: ${({ margin }) => margin};
`;

const Column = styled.div`
  max-width: 180px;
  &, & > *, > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

type UserProfileAndContactProps = {
  user: IUserGraphQL;
  margin?: string;
  preventLinkClicks?: boolean;
  isInvestorMatch?: boolean;
};

const UserProfileAndContact: React.FC<UserProfileAndContactProps> = ({
  user,
  margin,
  preventLinkClicks = false,
  isInvestorMatch = false,
}) => {
  const userPhoneNumber = user?.displayedPhoneNumber ? StringUtil.formatPhoneNumber(user?.displayedPhoneNumber) : StringUtil.formatPhoneNumber(user?.phoneNumber);
  /** Actions */
  const dispatch = useAppDispatch();
  const replaceWithChat = () => {
    dispatch(LeadActions.setModalPageState({ modalPageState: LeadModalPageStateEnum.Chat }));
  };

  /** Render */
  if (!user) return null;
  return (
    <Container margin={margin}>
      <ProfileImage type={ProfileImageTypes.Large} user={user} />
      <div>
        <Flex align="center">
          <Text type={TextTypesEnum.Medium16} color={Colors.Grey900}>
            {UserUtil.fullName(user)}
          </Text>
          {
          user?.investorProfile?.exchangeInvestor && (
            <Tag
              text="Exchange"
              customTagColor={Colors.Green100}
              customTextColor={Colors.Green900}
              padding="0 8px"
              margin="0 0 0 4px"
              textSize={TextTypesEnum.Medium14}
            />
          )
        }
        </Flex>
        <Flex>
          <Column>
            <Flex>
              <Icon
                icon={Icons.UserRegular}
                color={Colors.Grey500}
                size={14}
                margin="0px 8px 0px 0px"
              />
              <Text
                type={TextTypesEnum.Regular14}
                color={Colors.Grey500}
              >
                {user.role}
              </Text>
            </Flex>
            {user.address?.city && (
              <Flex>
                <Icon
                  icon={Icons.MapMarkerAltRegular}
                  color={Colors.Grey500}
                  size={14}
                  margin="0px 8px 0px 0px"
                />
                <Text
                  type={TextTypesEnum.Regular14}
                  color={Colors.Grey500}
                >
                  {AddressUtil.formatAddress(user.address, {
                    address1: true, address2: true, zip: true, country: true,
                  })}
                </Text>
              </Flex>
            )}
            {user.organizationName && (
              <Flex>
                <Icon
                  icon={Icons.SuitcaseRegular}
                  color={Colors.Grey500}
                  size={14}
                  margin="0px 8px 0px 0px"
                />
                <Text
                  type={TextTypesEnum.Regular14}
                  color={Colors.Grey500}
                >
                  {user.organizationName}
                </Text>
              </Flex>
            )}
          </Column>
          <Column>
            <Divider type={DividerTypesEnum.Vertical} color={Colors.Grey200} margin="0px 16px" />
          </Column>
          <Column>
            <Flex
              onClick={() => (preventLinkClicks ? null : replaceWithChat())}
              tip={preventLinkClicks ? 'Disabled for preview' : ''}
              tipPlace="right"
            >
              <Icon
                icon={Icons.CommentsAltRegular}
                color={Colors.Brand700 || Colors.Blurple700}
                size={14}
                margin="0px 8px 0px 0px"
              />
              <Text
                type={TextTypesEnum.Regular14}
                color={Colors.Brand700 || Colors.Blurple700}
              >
                Message me
              </Text>
            </Flex>
            {!isInvestorMatch && (
            <Flex
              onClick={() => (preventLinkClicks ? null : window.open(`tel:${user.phoneNumber}`, '_self'))}
              tip={preventLinkClicks ? 'Disabled for preview' : ''}
              tipPlace="right"
            >
              <Icon
                icon={Icons.PhoneRegular}
                color={Colors.Brand700 || Colors.Blurple700}
                size={14}
                margin="0px 8px 0px 0px"
              />
              <Text
                type={TextTypesEnum.Regular14}
                color={Colors.Brand700 || Colors.Blurple700}
              >
                {userPhoneNumber}
              </Text>
            </Flex>
            )}
            <Flex
              onClick={() => (preventLinkClicks ? null : window.open(`mailto:${user.email}`))}
              tip={preventLinkClicks ? 'Disabled for preview' : ''}
              tipPlace="right"
            >
              <Icon
                icon={Icons.EnvelopeRegular}
                color={Colors.Brand700 || Colors.Blurple700}
                size={14}
                margin="0px 8px 0px 0px"
              />
              <Text
                type={TextTypesEnum.Regular14}
                color={Colors.Brand700 || Colors.Blurple700}
              >
                {user?.email}
              </Text>
            </Flex>
          </Column>
        </Flex>
      </div>
    </Container>
  );
};

export default UserProfileAndContact;
