import React from 'react';
import { useMutation } from '@apollo/client';
import { useMutation as rqMutation } from 'react-query';
import * as IUserService from '@biproxi/models/services/IUserService';
import * as UserJoi from '@biproxi/models/joi/user.joi';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import ReCAPTCHA from 'react-google-recaptcha';
import StringUtil from '@biproxi/models/utils/StringUtil';
import UserRolesEnum from '@biproxi/models/enums/UserRolesEnum';
import { config } from '@biproxi/env';
import {
  PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL,
} from '@biproxi/models/externalLinks';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Input, { InputTypesEnum } from '../../elements/Input';
import COMPLETE_REGISTER from '../../graphql/mutations/completeRegister.mutation';
import {
  AuthenticationModal,
  Content,
} from '../AuthenticationModalLayout';
import Text, { TextTypesEnum } from '../../elements/Text';
import Flex, { Spacer } from '../../elements/Flex';
import Colors from '../../styles/Colors';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import { ModalTypesEnum } from './Modal';
import GET_USER from '../../graphql/queries/user.query';
import Loader, { LoaderSizes } from '../../elements/Loader';
import useVerifyPhoneNumber from '../../hooks/useVerifyPhoneNumber.hook';
import Checkbox from '../../elements/Checkbox';
import LinkText from '../../elements/LinkText';
import Link from '../../elements/Link';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import Dropdown from '../../elements/Dropdown';
import useUser from '../../hooks/useUser.hook';
import InlineAlert, { InlineAlertTypesEnum } from '../InlineAlert';
import UtmUtil from '../../utils/UTMUtil';
import Auth from '../../utils/Auth';
import createChargebeeCustomerMutation from '../../next-api/mutations/createChargeebeeCustomer.mutation';

export type CompleteRegisterModalProps = {
  isStandalone?: boolean;
};

const CompleteRegisterModal: React.FC<CompleteRegisterModalProps> = ({
  isStandalone = false,
}) => {
  /** State */
  const recaptchaRef = React.useRef(null);
  const [eulaSigned, setEulaSigned] = React.useState(false);

  /** Hooks */
  const { user, loading: userLoading } = useUser();

  const formParams: UseFormParams = {
    fields: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      role: '',
    },
    errors: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      role: '',
    },
    fieldOrder: ['/firstName', '/lastName', '/phoneNumber', '/role'],
    schema: UserJoi.completeUserRegistrationParamsSchema,
  };

  const {
    controllers: {
      firstName,
      lastName,
      phoneNumber,
      role,
    },
    setFieldErrors,
    isValid,
    setValues,
  } = useForm(formParams);

  const {
    loading,
    startRecaptcha,
    sendPhoneNumberVerificationSMS,
  } = useVerifyPhoneNumber({
    recaptchaRef,
    setFieldErrors,
    setError: (message) => phoneNumber.setError(message),
  });

  /** GraphQL */
  type TPayload = IUserService.TCompleteRegisterUserPayload;

  type TData = {
    completeRegister: IUserService.ICompleteRegisterUserResponse;
  };

  const [completeRegisterMutation, { loading: completeRegisterLoading }] = useMutation<TData, TPayload>(COMPLETE_REGISTER, {
    refetchQueries: [{ query: GET_USER }],
    awaitRefetchQueries: true,
    variables: {
      params: {
        firstName: firstName.value(),
        lastName: lastName.value(),
        phoneNumber: StringUtil.unmaskPhoneNumber(phoneNumber.value()),
        role: role.value(),
        utmParams: UtmUtil.getUtmParameters(),
      },
    },
    onCompleted: async (data) => {
      const {
        completeRegister: {
          user: {
            _id, firstName, lastName, email, phoneNumber,
          },
        },
      } = data;

      const token = await startRecaptcha();

      if (!token) return;

      const smsSuccess = await sendPhoneNumberVerificationSMS(token);

      if (!smsSuccess) return;

      enterUserIntoChargebee.mutate({
        userId: _id,
        firstName,
        lastName,
        email,
        phone: phoneNumber,
      });

      replaceModalWithVerifyPhoneNumber();
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        phoneNumber.setError(message);
      }
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const replaceModalWithVerifyPhoneNumber = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.VerifyPhoneNumber,
      props: {
        isStandalone,
      },
    }),
  );

  const completeRegister = async (event?: React.FormEvent) => {
    if (event) event.preventDefault();
    if (!eulaSigned) {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'You must accept the Terms of Use and Privacy Policy',
      });
      return;
    }
    if (!isValid()) return;
    await completeRegisterMutation();
  };

  /** React query */
  const enterUserIntoChargebee = rqMutation((userData: any) => createChargebeeCustomerMutation(userData));

  /* Effects */
  React.useEffect(() => {
    if (user) {
      setValues({
        firstName: user?.firstName,
        lastName: user?.lastName,
        phoneNumber: user?.phoneNumber,
        role: user?.role,
      });
    }
    /**
     * Focus on the phone number input if the
     * first name and last name are set.
     * This happens in the case of an OAuth flow
     */
    if (user?.firstName && user?.lastName) {
      phoneNumber.focus();
    }
  }, [user !== null]);

  /** Render */
  return (
    // Don't allow exit on this modal as we pop it up on the dashboard
    // if they haven't verified their phone number.
    // However, they could still get around it by deleting the modal in the inspector.
    // Should probably be fixed in the future.
    <AuthenticationModal fullscreen={isStandalone} showExit={false}>
      {(() => {
        if (userLoading) {
          return (
            <Flex flex="1" align="center" justify="center" direction="column">
              <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
            </Flex>
          );
        }

        return (
          <Content onSubmit={(event) => completeRegister(event)}>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={config.NEXT_PUBLIC_FIREBASE_RECAPTCHA_SITE_KEY}
            />
            <Text type={TextTypesEnum.Bold24}>Account details</Text>
            <Text type={TextTypesEnum.Regular16} color={Colors.Grey700} margin="4px 0 0">
              Let us gather some information to complete your registration, or,
              <LinkText
                onClick={() => Auth.logout()}
                margin="0 0 0 4px"
              >
                return to login.
              </LinkText>
            </Text>
            <Flex direction="row" justify="space-between" margin="16px 0">
              <Flex flex="1">
                <Input
                  autoFocus
                  data-cy="register-first-name-input"
                  label="First name*"
                  placeholder="First name"
                  value={firstName.value()}
                  onChange={firstName.setValue}
                  ref={firstName.setRef}
                  error={firstName.error()}
                  inputType={InputTypesEnum.Text}
                />
              </Flex>
              <Spacer />
              <Flex flex="1">
                <Input
                  data-cy="register-last-name-input"
                  label="Last name*"
                  placeholder="Last name"
                  value={lastName.value()}
                  onChange={lastName.setValue}
                  ref={lastName.setRef}
                  error={lastName.error()}
                  inputType={InputTypesEnum.Text}
                />
              </Flex>
            </Flex>
            <Flex direction="row" justify="space-between" margin="0 0 24px">
              {/* <Flex flex="1">
                  <Input
                    label="Company name*"
                    placeholder="Company name"
                    value={lastName.value()}
                    onChange={lastName.setValue}
                    ref={lastName.setRef}
                    error={lastName.error()}
                    inputType={InputTypesEnum.Text}
                  />
                </Flex>
                <Spacer /> */}
              <Flex flex="1">
                <Dropdown
                  data-cy="register-role-dropdown"
                  label="CRE role*"
                  value={role.value()}
                  onChange={(value) => role.setRawValue(value)}
                  placeholder="Select your role"
                  error={role.error()}
                  items={Object.values(UserRolesEnum).map((value) => ({
                    name: value,
                    value,
                  }))}
                />
              </Flex>
            </Flex>
            <Flex direction="row" justify="space-between" margin="0 0 24px">
              <Flex flex="1">
                <Input
                  data-cy="register-phone-number-input"
                  label="Mobile phone*"
                  placeholder="(XXX) XXX-XXXX"
                  onChange={phoneNumber.setValue}
                  ref={phoneNumber.setRef}
                  error={phoneNumber.error()}
                  inputType={InputTypesEnum.PhoneNumber}
                />
              </Flex>
            </Flex>
            <InlineAlert
              type={InlineAlertTypesEnum.YellowWarning}
              text="We ask for a phone number to prevent spam, bots, and fake accounts. We do not sell your personal information outside of the Biproxi Platform. View the Privacy Policy below for more information."
              margin="0 0 24px"
            />
            <Flex direction="row" justify="space-between">
              <Flex flex="1">
                <Checkbox
                  dataCy="eula-checkbox"
                  active={eulaSigned}
                  onClick={() => setEulaSigned(!eulaSigned)}
                  label={(
                    <Text type={TextTypesEnum.Medium14}>
                      I agree to the
                      {' '}
                      <Link href={TERMS_OF_SERVICE_URL}>
                        <LinkText type={TextTypesEnum.Medium14}>Terms of Use</LinkText>
                      </Link>
                      {' '}
                      &
                      {' '}
                      <Link href={PRIVACY_POLICY_URL}>
                        <LinkText type={TextTypesEnum.Medium14}>Privacy Policy</LinkText>
                      </Link>
                    </Text>
                  )}
                />
              </Flex>
            </Flex>
            <Button
              data-cy="complete-register-button"
              margin="24px 0 0"
              text="Continue"
              isLoading={completeRegisterLoading || loading}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Large}
              isFullWidth
            />
          </Content>
        );
      })()}
    </AuthenticationModal>
  );
};

export default CompleteRegisterModal;
