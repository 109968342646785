export const BIPROXI_WEBSITE_URL = 'https://biproxi.com';
export const KB_BASE_URL = 'https://support.biproxi.com';

export const PRIVACY_POLICY_URL = `${BIPROXI_WEBSITE_URL}/legal/privacy`;
export const TERMS_OF_SERVICE_URL = `${BIPROXI_WEBSITE_URL}/legal/terms`;
export const CONFIDENTIALITY_URL = `${BIPROXI_WEBSITE_URL}/legal/confidentiality-agreement`;
export const SCHEDULE_DEMO_URL = `${BIPROXI_WEBSITE_URL}/get-a-demo`;

export const KB_CREATE_LISTING_BASIC_URL = `${KB_BASE_URL}/create-a-new-listing#basic-information`;
export const KB_CREATE_LISTING_LEASE_URL = `${KB_BASE_URL}/create-a-new-listing#for-lease-properties`;
export const KB_CREATE_LISTING_DETAILS_URL = `${KB_BASE_URL}/create-a-new-listing#property-details`;
export const KB_CREATE_LISTING_GUIDANCE_URL = `${KB_BASE_URL}/create-a-new-listing#price-and-guidance`;
export const KB_CREATE_LISTING_INSIGHTS_URL = `${KB_BASE_URL}/create-a-new-listing#data-insights`;
export const KB_CREATE_LISTING_HIGHLIGHTS_URL = `${KB_BASE_URL}/create-a-new-listing#highlights`;
export const KB_CREATE_LISTING_DESC_URL = `${KB_BASE_URL}/create-a-new-listing#description`;
export const KB_CREATE_LISTING_DD_URL = `${KB_BASE_URL}/create-a-new-listing#due-diligence`;
export const KB_CREATE_LISTING_LICENSE_URL = `${KB_BASE_URL}/create-a-new-listing#license`;
export const KB_CREATE_LISTING_DEALPROFILE_URL = `${KB_BASE_URL}/create-a-new-listing#deal-profile`;
export const KB_CREATE_LISTING_CONTACTS_URL = `${KB_BASE_URL}/create-a-new-listing#contacts`;

export const KB_LISTING_SCORES_URL = `${KB_BASE_URL}/listing-scores`;
export const KB_FAVORITES_URL = `${KB_BASE_URL}/how-to-favorite-properties`;
export const KB_LEADS_URL = `${KB_BASE_URL}/leads`;
export const KB_OFFERS_URL = `${KB_BASE_URL}/offers`;
export const KB_NYLAS_URL = `${KB_BASE_URL}/share-a-listing-with-nylas`;

export const KB_PROFILE_BASE_URL = `${KB_BASE_URL}/what-are-user-profiles`;
export const KB_PROFILE_INFO_URL = `${KB_PROFILE_BASE_URL}#basic-information`;
export const KB_PROFILE_PREFERENCES_URL = `${KB_PROFILE_BASE_URL}#investor-preferences`;
export const KB_PROFILE_SCORES_URL = `${KB_PROFILE_BASE_URL}#profile-score`;

export const KB_PRIVATE_EVENT_USE_URL = `${KB_BASE_URL}/how-to-use-private-event`;

export const BIPROXI_FEATURE_INVESTOR_MATCH_URL = 'https://www.biproxi.com/products/investor-matching?';
export const BIPROXI_PLANS_BROKER = 'https://www.biproxi.com/solutions/broker?';
export const BIPROXI_PLANS_INVESTOR = 'https://www.biproxi.com/solutions/investor?';
/* SUPPORT */
export const PRIVATE_EVENT_HELP = 'https://support.biproxi.com/how-to-use-private-events';
