/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import StringUtil from '@biproxi/models/utils/StringUtil';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import ListingCard from './ListingCard.default';
import ListingCardTypesEnum from '../models/enums/ListingCardTypesEnum';
import { FadeIn } from '../elements/Motion';
import ZIndexes from '../styles/ZIndexes';

const Container = styled.div`
  &:hover {
    cursor: pointer;
    position: absolute;
    z-index: ${ZIndexes.ListingMapMarker};

    #ListingMarker {
      background-color: ${Colors.Black};
      border: 1px solid ${Colors.Black};
      z-index: 100;
    }
  }
`;

const ListingMarker = styled.div`
  position: relative;
  height: 24px;
  border-radius: 8px;
  background-color: ${Colors.Grey700};
  border: 0.5px solid ${Colors.Grey500};
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  transition: all 0.1s;
  display: inline-flex;
  margin-bottom: 4px;
`;

export type ListingMapMarkerProps = {
  listing: IListingGraphQL;
  lat?: number;
  lng?: number;
};

const ListingMapMarker: React.FC<ListingMapMarkerProps> = ({
  listing,
}) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <Container
      onClick={() => window.open(ListingUtil.slug(listing), 'blank')}
    >
      <ListingMarker
        id="ListingMarker"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Text type={TextTypesEnum.Bold14} color={Colors.White}>
          {listing?.guidance?.askingPrice ? `$${StringUtil.condenseNumber(listing?.guidance?.askingPrice)}` : 'Unpriced'}
        </Text>

      </ListingMarker>
      {hovered && (
        <FadeIn>
          <ListingCard listing={listing} type={ListingCardTypesEnum.DefaultMap} />
        </FadeIn>
      )}
    </Container>
  );
};

export default React.memo(ListingMapMarker);
