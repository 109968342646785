import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { IOfferGraphQL } from '@biproxi/models/interfaces/IOffer';
import OfferQueryTypesEnum from '@biproxi/models/enums/OfferQueryTypesEnum';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Input, { InputTypesEnum } from '../../elements/Input';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { media } from '../../utils/MediaQuery';
import REJECT_OFFER from '../../graphql/mutations/rejectOffer.mutation';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Divider from '../../elements/Divider';
import LIST_OFFERS from '../../graphql/queries/offers.query';
import LIST_LISTINGS_WITH_OFFERS from '../../graphql/queries/listingsWithOffers.query';

const Container = styled.div`
  width: 432px;

  ${media.mobile} {
    width: 100%;
  }
`;

const Form = styled.form``;

export type RejectOfferModalProps = {
  offer: IOfferGraphQL;
};

const RejectOfferModal: React.FC<RejectOfferModalProps> = ({
  offer,
}) => {
  /** Hooks */
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState('');

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const handleSubmit = async (event?: React.FormEvent) => {
    if (event) event.preventDefault();
    await rejectOffer();
  };

  /** GraphQL */
  interface Data {
    offer: IOfferGraphQL;
  }

  interface Vars {
    params: {
      offerId: string;
      message?: string;
    };
  }

  const [rejectOffer, { loading }] = useMutation<Data, Vars>(REJECT_OFFER, {
    variables: {
      params: {
        offerId: offer._id,
        message,
      },
    },
    onCompleted: () => {
      popModal();
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Offer has been rejected.',
      });
    },
    onError: (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);
      setError(message);
    },
    refetchQueries: [{
      query: LIST_OFFERS,
      variables: {
        params: {
          queryType: OfferQueryTypesEnum.Received,
          listingId: offer.listingId,
          isActive: true,
        },
      },
    },
    {
      query: LIST_OFFERS,
      variables: {
        params: {
          queryType: OfferQueryTypesEnum.Received,
          isActive: true,
          pagination: {
            limit: 3,
            offset: 0,
          },
        },
      },
    },
    {
      query: LIST_LISTINGS_WITH_OFFERS,
      variables: {
        params: {
          query: {
            queryType: ListingQueryTypesEnum.Personal,
          },
        },
      },
    }],
  });

  /* Render */
  return (
    <ModalContainer>
      <Form onSubmit={(event) => handleSubmit(event)}>
        <ModalHeader title="Reject offer" close={popModal} />
        <ModalContent>
          <Container>
            <Text
              type={TextTypesEnum.Regular16}
              color={Colors.Grey700}
            >
              Are you sure you want to reject this offer? This will notify the user that their offer has been rejected and will move the offer to the inactive section.
            </Text>
            <Text
              type={TextTypesEnum.Regular16}
              color={Colors.Grey700}
              margin="24px 0px 0px"
            >
              They can still send you another revised offer if they wish.
            </Text>
            <Divider color={Colors.Grey200} margin="24px 0px" />
            <Input
              label="Send an optional message"
              placeholder="Type your message here"
              value={message}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.currentTarget.value)}
              error={error}
              inputType={InputTypesEnum.TextArea}
              height="160px"
            />
          </Container>
        </ModalContent>
        <ModalFooter>
          <Flex justify="flex-end" width="100%">
            <Button
              text="Cancel"
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Medium}
              onClick={() => popModal()}
              margin="0px 8px 0px 0px"
              htmlType="button"
            />
            <Button
              text="Reject offer"
              isLoading={loading}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Medium}
              htmlType="submit"
            />
          </Flex>
        </ModalFooter>
      </Form>
    </ModalContainer>
  );
};

export default RejectOfferModal;
