import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from './Text';

type ContainerProps = {
  height?: string;
  margin?: string;
  width?: string;
}

const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border: .5px solid ${Colors.Grey300};
    padding: 0 12px;
    border-radius: 4px;
    transition: all 0.1s;;
    height: ${(props) => props.height};
    margin: ${(props) => props.margin};
    width: ${(props) => props.width};

    &:hover {
      cursor: pointer;
      border: .5px solid ${Colors.Grey400};
    }

    &:active {
      border: 1px solid ${Colors.Black};
      background-color: ${Colors.Grey100};
    }
`;

type FilterButtonProps = {
  value: string;
  onClick?: Function;
  margin?: string;
  height?: string;
  icon?: React.ReactNode;
  width?: string;
  dataCy?: string;
};

const FilterButton = React.forwardRef<HTMLDivElement, FilterButtonProps>(({
  value,
  onClick,
  margin,
  height = '32px',
  icon,
  width = 'fit-content',
  dataCy,
}, ref) => (
  <Container data-cy={dataCy} ref={ref} onClick={() => onClick?.()} margin={margin} height={height} width={width}>
    <Text type={TextTypesEnum.Medium12} color={Colors.Grey900} noWrap>{value}</Text>
    {icon && icon}
  </Container>
));

export default FilterButton;
