import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import IAbsolutePositioningConfig from '../models/interfaces/IAbsolutePositioningConfig';

type ListProps = {
  open: boolean;
  width: string;
  right: boolean;
  maxDropdownHeight: string;
  absolutePositionConfig: IAbsolutePositioningConfig;
}

const List = styled.div<ListProps>`
  position: absolute;
  left: ${({ absolutePositionConfig }) => (absolutePositionConfig?.left ? absolutePositionConfig.left : null)};
  top: ${({ absolutePositionConfig }) => (absolutePositionConfig?.top ? absolutePositionConfig.top : null)};
  bottom: ${({ absolutePositionConfig }) => (absolutePositionConfig?.bottom ? absolutePositionConfig.bottom : null)};
  right: ${(props) => (props.right ? '0px' : null)};
  right: ${(props) => {
    if (props?.right) return '0px';
    if (props?.absolutePositionConfig?.right) return props.absolutePositionConfig.right;
    return null;
  }};
  margin-top: 16px;
  width: ${(props) => props.width};
  box-sizing: border-box;
  border: ${(props) => (props.open ? `1px solid ${Colors.Grey100}` : null)};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background-color: ${Colors.White};
  max-height: ${({ maxDropdownHeight }) => maxDropdownHeight || '328px'};
  height: ${(props) => (props.open ? null : '0px')};
  z-index: ${(props) => (props.open ? 200 : 0)};
  &, & > *, > div > p {
    white-space: nowrap;
    /* overflow-x: hidden; */
    text-overflow: ellipsis;
  }
  overflow-y: auto;
  &:focus {
    outline: none;
  }
`;

const ItemContainer = styled.div<{isHovered: boolean}>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  transition: all 0.1s;
  color: ${({ isHovered }) => (isHovered ? `${Colors.Grey900}` : `${Colors.Grey900}`)};
  background-color: ${({ isHovered }) => (isHovered ? `${Colors.Brand50 || Colors.Blurple50}` : null)};
  &:hover {
    background-color: ${Colors.Brand50 || Colors.Blurple50};
    color: ${Colors.Black};
    cursor: pointer
  }
`;

export type TDropdownItem<TValueType> = {
  name: string;
  value: TValueType;
  active?: boolean,
}

export type TDropdownItems<TValueType> = TDropdownItem<TValueType>[];

export type TDropdownItemProps = {
  item: TDropdownItem<any>;
  onChange: (value: any) => void;
  isHovered: boolean;
  setOpen: (open: boolean) => void;
}

export type DropdownListProps = {
  value: string;
  cyList?: string;
  open: boolean;
  search?: string;
  setOpen: (open: boolean) => void;
  onChange: (value: any) => void;
  items: TDropdownItems<any>;
  maxDropdownHeight?: string;
  width?: string;
  right?: boolean;
  ItemComponent?: React.ForwardRefExoticComponent<TDropdownItemProps & React.RefAttributes<HTMLDivElement>>;
  absolutePositionConfig?: IAbsolutePositioningConfig;
};

export enum ArrowKeyTypesEnum {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Enter = 'Enter'
}

const DropdownList = React.forwardRef<HTMLDivElement, DropdownListProps>(({
  value,
  cyList,
  open,
  search,
  setOpen,
  onChange,
  items,
  maxDropdownHeight,
  width = '100%',
  right = false,
  ItemComponent,
  absolutePositionConfig,
}, _ref) => {
  const [hoveredItemIndex, setHoveredItemIndex] = React.useState<number>(0);
  const listRef = React.useRef(null);
  const itemRefs = React.useRef([]);

  /**
   * Handles arrow key support for dropdown menus
   * @param e {object}, event handler used to get the key that a user presses
   */
  const handleKeyPressed = (event: KeyboardEvent): void => {
    const { key } = event;

    /**
     * If the key pressed is enter or an arrow key,
     *  prevent the default to avoid form submissions in
     * the case that the dropdown list is rendered within a form.
     */
    if (Object.values(ArrowKeyTypesEnum).includes(key as ArrowKeyTypesEnum)) {
      event?.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }

    if (key === ArrowKeyTypesEnum.Enter) {
      if (items?.[hoveredItemIndex]?.value ?? value) {
        onChange(items?.[hoveredItemIndex]?.value ?? value);
        setOpen(false);
      }
      return;
    }

    // hehe
    const direction = key === ArrowKeyTypesEnum.ArrowUp
      ? -1
      : key === ArrowKeyTypesEnum.ArrowDown
        ? 1
        : 0;

    setHoveredItemIndex((prevState) => {
      let newHoveredIndex = prevState + direction;
      if (newHoveredIndex < 0) newHoveredIndex = 0;
      if (newHoveredIndex > items.length - 1) newHoveredIndex = items.length - 1;

      const currentItem = itemRefs?.current[newHoveredIndex];

      const currentItemHeight = currentItem?.clientHeight;
      const currentItemOffsetTop = currentItem?.offsetTop;
      const currentList = listRef?.current;
      const listHeight = currentList?.clientHeight;
      const listScrollTop = currentList?.scrollTop;

      // Scroll Down
      if ((currentItemOffsetTop + currentItemHeight) > listHeight && key === ArrowKeyTypesEnum.ArrowDown) {
        currentList.scrollTop += currentItemHeight;
      }

      // Scroll Up
      if ((currentItemOffsetTop) < listScrollTop && key === ArrowKeyTypesEnum.ArrowUp) {
        currentList.scrollTop -= currentItemHeight;
      }

      return newHoveredIndex;
    });
  };

  /** Effects */
  React.useLayoutEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleKeyPressed);
    } else {
      document.removeEventListener('keydown', handleKeyPressed);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPressed);
    };
  }, [open, hoveredItemIndex, items]);

  React.useLayoutEffect(() => {
    if (search) {
      const newHoveredIndex = items.findIndex((item) => item.name.charAt(0).toLowerCase() === search.toLowerCase());

      const currentItem = itemRefs?.current[newHoveredIndex];
      const currentList = listRef?.current;

      if (currentList) {
        currentList.scrollTop = currentItem?.offsetTop;
      }
      setHoveredItemIndex(newHoveredIndex);
    }
  }, [search]);

  /** Render */
  const RenderItem = ItemComponent ?? Item;

  return (
    <>
      {items?.length > 0 && (
      <List
        data-cy={cyList}
        ref={listRef}
        tabIndex={0}
        open={open}
        maxDropdownHeight={maxDropdownHeight}
        width={width}
        right={right}
        absolutePositionConfig={absolutePositionConfig}
      >
        {items.map((item, index) => (
          <RenderItem
            ref={(ref) => { itemRefs.current[index] = ref; }}
            key={index}
            item={item}
            onChange={(currentItem: TDropdownItem<any>) => {
              const itemIndex = items.findIndex((listItem) => listItem.name === currentItem.name);
              setHoveredItemIndex(itemIndex);
              onChange(currentItem.value);
            }}
            isHovered={index === hoveredItemIndex}
            setOpen={setOpen}
          />
        ))}
      </List>
      )}
    </>
  );
});

const Item = React.forwardRef<HTMLDivElement, TDropdownItemProps>(({
  item,
  onChange,
  isHovered,
  setOpen,
}, ref) => (
  <ItemContainer
    ref={ref}
    data-cy={`${item.name?.replace(/\s+/g, '-').toLowerCase()}-dropdown-selection`}
    onClick={() => {
      onChange(item);
      setOpen(false);
    }}
    isHovered={isHovered}
  >
    <Text margin="0 16px" color="inherit" type={TextTypesEnum.Regular14}>{item.name}</Text>
  </ItemContainer>
));

export default DropdownList;
