import { ApolloServer, BaseContext } from '@apollo/server';
import { logger } from '@biproxi/logger';
import { graphqlHandler } from '@biproxi/graphql';
import { ok, err, Result } from 'neverthrow';
import StatusCodeEnum from '@biproxi/models/enums/StatusCodeEnum';
import configureAuraServer from '../aura/aura.config';

type Neo4jQueryResponseType = {
  data: any,
  errors: any,
  status: StatusCodeEnum
}

export class Neo4jService {
  private server: ApolloServer<BaseContext>;

  private async configureAuraServer(): Promise<ApolloServer<BaseContext>> {
    if (!this.server) {
      logger.info('Spinning up Aura GraphQL server...');
      const auraServer = await configureAuraServer();
      this.server = auraServer;
    }
    return this.server;
  }

  public async makeQuery(graphqlRequest: any): Promise<Result<Neo4jQueryResponseType, Error>> {
    const server = await this.configureAuraServer();
    let res: Neo4jQueryResponseType;
    try {
      logger.info('>>>> Calling GraphQL handler....');
      const startTime = new Date().getTime();
      res = await graphqlHandler(server, graphqlRequest);
      const endTime = new Date().getTime();
      const deltaTime = endTime - startTime;
      logger.info(`<<<< 🕔 Time for GraphQL handler to get response: ~${deltaTime} ms`);
    } catch (e) {
      return err(new Error(`Neo4j make query!: ${e}`));
    }
    if (res?.errors || res?.errors?.length || !res?.data) {
      return err(new Error(`There was an issue querying Aura: ${res?.errors?.[0]?.message}`));
    }
    return ok(res);
  }
}
