import {
  createAction, createReducer, PayloadAction,
} from '@reduxjs/toolkit';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import ReduxUtil from '@biproxi/models/utils/ReduxUtil';
import TCache from '@biproxi/models/types/TCache';
import ILeadParams from '@biproxi/models/interfaces/ILeadParams';
import ILeadQuery from '@biproxi/models/interfaces/ILeadQuery';
import LeadQuerySortByEnum from '@biproxi/models/enums/LeadQuerySortByEnum';
import LeadQuerySortDirectionEnum from '@biproxi/models/enums/LeadQuerySortDirectionEnum';
import * as UrlUtil from '../utils/UrlUtil';
import IPageConfiguration from '../models/interfaces/IPageConfiguration';
import { AppState } from './store';
import NextUtil from '../utils/NextUtil';
import LeadLoaderTypesEnum from '../models/enums/LeadLoaderTypesEnum';
import TLeadLoaders from '../models/types/TLeadLoaders';
import ILeadSearch from '../models/interfaces/ILeadSearch';
import { LeadModalPageStateEnum } from '../components/modal/LeadModal';

export type LeadReducerState = {
  leadId: string | null;
  leads: TCache<ILeadGraphQL>;
  loading: TLeadLoaders;
  modalPageState: LeadModalPageStateEnum;
  search: ILeadSearch;
  investorTableRefetchTrigger: object;
  investorTableNeedsRefetch: boolean;
  leadTableRowExpandedLeadIds: string[];
};

export function leadReducerState(config?: IPageConfiguration): LeadReducerState {
  let { leadId = null } = NextUtil.query(config);

  const leads = ReduxUtil.makeCache<ILeadGraphQL>([], {});

  if (!leadId && config?.lead) {
    leadId = config.lead._id;
  }

  const loading = Object.keys(LeadLoaderTypesEnum).reduce((cur, next) => {
    cur[next] = false;
    return cur;
  }, {} as TLeadLoaders);

  return {
    leadId,
    leads,
    loading,
    modalPageState: null,
    search: {
      query: {
        term: '',
        leadType: null,
        sortBy: LeadQuerySortByEnum.Created,
        sortDirection: LeadQuerySortDirectionEnum.Descending,
      },
    },
    investorTableRefetchTrigger: {},
    investorTableNeedsRefetch: false,
    leadTableRowExpandedLeadIds: [],
  };
}

export enum LeadActionTypesEnum {
  SelectLead = 'SelectLead',
  CacheLeads = 'CacheLeads',
  RecacheLead = 'RecacheLead',
  SetLeadSearch = 'SetLeadSearch',
  SetLeadSearchQuery = 'SetLeadSearchQuery',
  SetLeadLoading = 'SetLeadLoading',
  SetModalPageState = 'SetModalPageState',

  // Create Lead
  CreateLead = 'CreateLead',
  CreateLeadSuccess = 'CreateLeadSuccess',
  CreateLeadFailure = 'CreateLeadFailure',
  // Update Lead
  UpdateLead = 'UpdateLead',
  UpdateLeadSuccess = 'UpdateLeadSuccess',
  UpdateLeadFailure = 'UpdateLeadFailure',
  SetInvestorMatchChanged = 'SetInvestorMatchChanged',
  SetInvestorTableNeedsRefetch = 'SetInvestorTableNeedsRefetch',
  PushLeadTableRowExpandedLeadIds = 'PushLeadTableRowExpandedLeadIds',
  PullLeadTableRowExpandedLeadIds = 'PullLeadTableRowExpandedLeadIds',
}

/** ******************************************************************************
 *  Select Lead
 ****************************************************************************** */

// Payload
type SelectLeadPayloadType = {
  leadId: string | null;
};

// Action
const selectLead = createAction<SelectLeadPayloadType, LeadActionTypesEnum.SelectLead>(LeadActionTypesEnum.SelectLead);

// Reducer
function selectLeadReducer(state: LeadReducerState, action: PayloadAction<SelectLeadPayloadType>) {
  const { leadId } = action.payload;
  UrlUtil.setQueryString({ leadId });
  state.leadId = leadId;
  return state;
}

/** ******************************************************************************
 *  Cache Leads
 ****************************************************************************** */

// Payload
type CacheLeadPayloadType = {
  leads: ILeadGraphQL[];
};

// Action
const cacheLeads = createAction<CacheLeadPayloadType, LeadActionTypesEnum.CacheLeads>(LeadActionTypesEnum.CacheLeads);

// Reducer
function cacheLeadsReducer(state: LeadReducerState, action: PayloadAction<CacheLeadPayloadType>) {
  state.leads = ReduxUtil.makeCache<ILeadGraphQL>(action.payload.leads, state.leads);
  return state;
}

/** ******************************************************************************
 *  Recache Lead
 ****************************************************************************** */

// Payload
type RecacheLeadPayloadType = {
  leadId: string;
};

// Action
const recacheLead = createAction<RecacheLeadPayloadType, LeadActionTypesEnum.RecacheLead>(LeadActionTypesEnum.RecacheLead);

// Reducer
function recacheLeadReducer(state: LeadReducerState, _action: PayloadAction<RecacheLeadPayloadType>) {
  return state;
}

/** ******************************************************************************
 *  Set Lead Search
 ****************************************************************************** */

// Payload
type SetLeadSearchPayloadType = Partial<ILeadSearch>;

// Action
const setLeadSearch = createAction<SetLeadSearchPayloadType, LeadActionTypesEnum.SetLeadSearch>(LeadActionTypesEnum.SetLeadSearch);

// Reducer
function setLeadSearchReducer(state: LeadReducerState, action: PayloadAction<SetLeadSearchPayloadType>) {
  state.search = {
    ...state.search,
    ...action.payload,
  };

  return state;
}

/** ******************************************************************************
 *  Set Lead Search Query
 ****************************************************************************** */

// Payload
type SetLeadSearchQueryPayloadType = Partial<ILeadQuery>;

// Action
const setLeadSearchQuery = createAction<SetLeadSearchQueryPayloadType, LeadActionTypesEnum.SetLeadSearchQuery>(LeadActionTypesEnum.SetLeadSearchQuery);

// Reducer
function setLeadSearchQueryReducer(state: LeadReducerState, action: PayloadAction<SetLeadSearchQueryPayloadType>) {
  const query = {
    ...state.search.query,
    ...action.payload,
  };

  return setLeadSearchReducer(state, setLeadSearch({ query }));
}

/** ******************************************************************************
 *  Set Lead Loading
 ****************************************************************************** */

// Payload
type SetLeadLoadingPayloadType = Partial<TLeadLoaders>;

// Action
const setLeadLoading = createAction<SetLeadLoadingPayloadType, LeadActionTypesEnum.SetLeadLoading>(LeadActionTypesEnum.SetLeadLoading);

// Reducer
function setLeadLoadingReducer(state: LeadReducerState, action: PayloadAction<SetLeadLoadingPayloadType>) {
  state.loading = {
    ...state.loading,
    ...action.payload,
  };
  return state;
}

/** ******************************************************************************
 *  Create Lead
 ****************************************************************************** */

// Payload
type CreateLeadPayloadType = ILeadParams;

// Action
const createLead = createAction<CreateLeadPayloadType, LeadActionTypesEnum.CreateLead>(LeadActionTypesEnum.CreateLead);

// Reducer
function createLeadReducer(state: LeadReducerState, _action: PayloadAction<CreateLeadPayloadType>) {
  // state.leads = ReduxUtil.makeCache<ILeadGraphQL | ILeadParams>(action.payload.leads, state.leads);
  state = setLeadLoadingReducer(state, setLeadLoading({ [LeadLoaderTypesEnum.CreateLead]: true }));
  return state;
}

/** ******************************************************************************
 *  Create Lead Success
 ****************************************************************************** */

// Payload
type CreateLeadSuccessPayloadType = {
  lead: ILeadGraphQL;
};

// Action
const createLeadSuccess = createAction<CreateLeadSuccessPayloadType, LeadActionTypesEnum.CreateLeadSuccess>(LeadActionTypesEnum.CreateLeadSuccess);

// Reducer
function createLeadSuccessReducer(state: LeadReducerState, action: PayloadAction<CreateLeadSuccessPayloadType>) {
  const { lead } = action.payload;
  state.leads = ReduxUtil.makeCache<ILeadGraphQL>([lead], state.leads);
  state = selectLeadReducer(state, selectLead({ leadId: lead._id }));
  state = setLeadLoadingReducer(state, setLeadLoading({ [LeadLoaderTypesEnum.CreateLead]: false }));
  return state;
}

/** ******************************************************************************
 *  Create Lead Failure
 ****************************************************************************** */

// Payload
type CreateLeadFailurePayloadType = {
  leads: ILeadGraphQL[];
};

// Action
const createLeadFailure = createAction<CreateLeadFailurePayloadType, LeadActionTypesEnum.CreateLeadFailure>(LeadActionTypesEnum.CreateLeadFailure);

// Reducer
function createLeadFailureReducer(state: LeadReducerState, _action: PayloadAction<CreateLeadFailurePayloadType>) {
  // state.leads = ReduxUtil.makeCache<ILeadGraphQL | ILeadParams>(action.payload.leads, state.leads);
  state = setLeadLoadingReducer(state, setLeadLoading({ [LeadLoaderTypesEnum.CreateLead]: false }));
  return state;
}

/** ******************************************************************************
 *  Update Lead
 ****************************************************************************** */

// Payload
type UpdateLeadPayloadType = {
  lead: ILeadGraphQL;
  toast?: boolean;
};

// Action
const updateLead = createAction<UpdateLeadPayloadType, LeadActionTypesEnum.UpdateLead>(LeadActionTypesEnum.UpdateLead);

// Reducer
function updateLeadReducer(state: LeadReducerState, _action: PayloadAction<UpdateLeadPayloadType>) {
  // state.leads = ReduxUtil.makeCache<ILeadGraphQL | ILeadParams>(action.payload.leads, state.leads);
  state = setLeadLoadingReducer(state, setLeadLoading({ [LeadLoaderTypesEnum.UpdateLead]: true }));
  return state;
}

/** ******************************************************************************
 *  Update Lead Success
 ****************************************************************************** */

// Payload
type UpdateLeadSuccessPayloadType = {
  lead: ILeadGraphQL;
};

// Action
const updateLeadSuccess = createAction<UpdateLeadSuccessPayloadType, LeadActionTypesEnum.UpdateLeadSuccess>(LeadActionTypesEnum.UpdateLeadSuccess);

// Reducer
function updateLeadSuccessReducer(state: LeadReducerState, action: PayloadAction<UpdateLeadSuccessPayloadType>) {
  const { lead } = action.payload;
  state.leads = ReduxUtil.makeCache<ILeadGraphQL>([lead], state.leads);
  state = selectLeadReducer(state, selectLead({ leadId: lead._id }));
  state = setLeadLoadingReducer(state, setLeadLoading({ [LeadLoaderTypesEnum.UpdateLead]: false }));
  return state;
}

/** ******************************************************************************
 *  Update Lead Failure
 ****************************************************************************** */

// Payload
type UpdateLeadFailurePayloadType = {
  leads: ILeadGraphQL[];
};

// Action
const updateLeadFailure = createAction<UpdateLeadFailurePayloadType, LeadActionTypesEnum.UpdateLeadFailure>(LeadActionTypesEnum.UpdateLeadFailure);

// Reducer
function updateLeadFailureReducer(state: LeadReducerState, _action: PayloadAction<UpdateLeadFailurePayloadType>) {
  // state.leads = ReduxUtil.makeCache<ILeadGraphQL | ILeadParams>(action.payload.leads, state.leads);
  state = setLeadLoadingReducer(state, setLeadLoading({ [LeadLoaderTypesEnum.UpdateLead]: false }));
  return state;
}

/** ******************************************************************************
 *  Set InvestorMatchChanged
 ****************************************************************************** */

// Payload
type SetInvestorMatchChangedPayloadType = {};

// Action
const setInvestorMatchChanged = createAction<SetInvestorMatchChangedPayloadType, LeadActionTypesEnum.SetInvestorMatchChanged>(LeadActionTypesEnum.SetInvestorMatchChanged);

// Reducer
function SetInvestorMatchChangedReducer(state: LeadReducerState, action: PayloadAction<SetInvestorMatchChangedPayloadType>) {
  state.investorTableRefetchTrigger = action.payload;
}

/** ******************************************************************************
 *  Set InvestorTableNeedsRefetch
 ****************************************************************************** */

// Payload
type SetInvestorTableNeedsRefetchPayloadType = {
  needsRefetch: boolean;
};

// Action
const setInvestorTableNeedsRefetch = createAction<SetInvestorTableNeedsRefetchPayloadType, LeadActionTypesEnum.SetInvestorTableNeedsRefetch>(LeadActionTypesEnum.SetInvestorTableNeedsRefetch);

// Reducer
function SetInvestorTableNeedsRefetchReducer(state: LeadReducerState, action: PayloadAction<SetInvestorTableNeedsRefetchPayloadType>) {
  state.investorTableNeedsRefetch = action.payload.needsRefetch;
}

/** ******************************************************************************
 *  Set Modal Page State - used to set the page state after initial load, not from the nav bar
 ****************************************************************************** */

// Payload
type SetModalPageStatePayloadType = {
  modalPageState: LeadModalPageStateEnum;
};

// Action
const setModalPageState = createAction<SetModalPageStatePayloadType, LeadActionTypesEnum.SetModalPageState>(LeadActionTypesEnum.SetModalPageState);

// Reducer
function setModalPageStateReducer(state: LeadReducerState, action: PayloadAction<SetModalPageStatePayloadType>) {
  state.modalPageState = action.payload.modalPageState;
  return state;
}

/** ******************************************************************************
 *  Push to lead table expanded array
 ****************************************************************************** */

// Payload
type PushLeadTableRowExpandedLeadIdsPayloadType = {
  leadIds: string[];
};

// Action
const pushLeadTableRowExpandedLeadIds = createAction<PushLeadTableRowExpandedLeadIdsPayloadType, LeadActionTypesEnum.PushLeadTableRowExpandedLeadIds>(LeadActionTypesEnum.PushLeadTableRowExpandedLeadIds);

// Reducer
function pushLeadTableRowExpandedLeadIdsReducer(state: LeadReducerState, action: PayloadAction<PushLeadTableRowExpandedLeadIdsPayloadType>) {
  const arr: string[] = state.leadTableRowExpandedLeadIds.concat(action?.payload?.leadIds);
  state.leadTableRowExpandedLeadIds = [...new Set(arr)];
  return state;
}

/** ******************************************************************************
 *  Pull from lead table expanded array
 ****************************************************************************** */

// Payload
type PullLeadTableRowExpandedLeadIdsPayloadType = {
  leadIds: string[];
};

// Action
const pullLeadTableRowExpandedLeadIds = createAction<PullLeadTableRowExpandedLeadIdsPayloadType, LeadActionTypesEnum.PullLeadTableRowExpandedLeadIds>(LeadActionTypesEnum.PullLeadTableRowExpandedLeadIds);

// Reducer
function pullLeadTableRowExpandedLeadIdsReducer(state: LeadReducerState, action: PayloadAction<PullLeadTableRowExpandedLeadIdsPayloadType>) {
  state.leadTableRowExpandedLeadIds = state.leadTableRowExpandedLeadIds.filter((i) => !action?.payload?.leadIds.includes(i));
  return state;
}

/** ******************************************************************************
 *  Exports
 ****************************************************************************** */

export const LeadSelectors = {
  selectedLeadId: (state: AppState): LeadReducerState['leadId'] => {
    const { lead: { leadId } } = state;
    return leadId ?? null;
  },
  lead: (state: AppState, leadId: string): ILeadGraphQL | null => {
    const { lead: { leads } } = state;
    return leads[leadId] ?? null;
  },
  leads: (state: AppState): any => {
    const { lead: { leads } } = state;
    return leads ?? null;
  },
  selectedLead: (state: AppState, paramLeadId?: string): ILeadGraphQL | null => {
    const { lead: { leads, leadId } } = state;
    return leads[paramLeadId ?? leadId] ?? null;
  },
  leadCache: (state: AppState): LeadReducerState['leads'] => state?.lead?.leads ?? {},
  searchQuery: (state: AppState): LeadReducerState['search']['query'] => state?.lead?.search?.query,
  modalPageState: (state: AppState): LeadReducerState['modalPageState'] => state?.lead?.modalPageState,
  investorTableRefetchTrigger: (state: AppState): LeadReducerState['investorTableRefetchTrigger'] => state?.lead?.investorTableRefetchTrigger,
  investorTableNeedsRefetch: (state: AppState): LeadReducerState['investorTableNeedsRefetch'] => state?.lead?.investorTableNeedsRefetch,
  leadTableRowExpandedLeadIds: (state: AppState): LeadReducerState['leadTableRowExpandedLeadIds'] => state?.lead?.leadTableRowExpandedLeadIds,
};

export type LeadActionPayloadTypes = {
  SelectLeadPayloadType: SelectLeadPayloadType;
  CacheLeadPayloadType: CacheLeadPayloadType;
  RecacheLeadPayloadType: RecacheLeadPayloadType;
  SetLeadSearchPayloadType: SetLeadSearchPayloadType;
  SetLeadSearchQueryPayloadType: SetLeadSearchQueryPayloadType;
  SetLeadLoadingPayloadType: SetLeadLoadingPayloadType;
  SetModalPageStatePayloadType: SetModalPageStatePayloadType;
  // Create Lead
  CreateLeadPayloadType: CreateLeadPayloadType;
  CreateLeadSuccessPayloadType: CreateLeadSuccessPayloadType;
  CreateLeadFailurePayloadType: CreateLeadFailurePayloadType;
  // Update Lead
  UpdateLeadPayloadType: UpdateLeadPayloadType;
  UpdateLeadSuccessPayloadType: UpdateLeadSuccessPayloadType;
  UpdateLeadFailurePayloadType: UpdateLeadFailurePayloadType;
  SetInvestorMatchChangedPayloadType: SetInvestorMatchChangedPayloadType;
  SetInvestorTableNeedsRefetchPayloadType: SetInvestorTableNeedsRefetchPayloadType;
};

export const LeadActions = {
  selectLead,
  cacheLeads,
  recacheLead,
  setLeadSearch,
  setLeadSearchQuery,
  setLeadLoading,
  setModalPageState,
  // Create Lead
  createLead,
  createLeadSuccess,
  createLeadFailure,
  // Update Lead
  updateLead,
  updateLeadSuccess,
  updateLeadFailure,
  setInvestorMatchChanged,
  setInvestorTableNeedsRefetch,
  pushLeadTableRowExpandedLeadIds,
  pullLeadTableRowExpandedLeadIds,
};

const reducer = createReducer(leadReducerState(), (builder) => builder
  .addCase(selectLead, selectLeadReducer)
  .addCase(cacheLeads, cacheLeadsReducer)
  .addCase(recacheLead, recacheLeadReducer)
  .addCase(setLeadSearch, setLeadSearchReducer)
  .addCase(setLeadSearchQuery, setLeadSearchQueryReducer)
  .addCase(setLeadLoading, setLeadLoadingReducer)
  .addCase(setModalPageState, setModalPageStateReducer)
  // Create Lead
  .addCase(createLead, createLeadReducer)
  .addCase(createLeadSuccess, createLeadSuccessReducer)
  .addCase(createLeadFailure, createLeadFailureReducer)
  // Update Lead
  .addCase(updateLead, updateLeadReducer)
  .addCase(updateLeadSuccess, updateLeadSuccessReducer)
  .addCase(updateLeadFailure, updateLeadFailureReducer)
  .addCase(setInvestorMatchChanged, SetInvestorMatchChangedReducer)
  .addCase(pushLeadTableRowExpandedLeadIds, pushLeadTableRowExpandedLeadIdsReducer)
  .addCase(pullLeadTableRowExpandedLeadIds, pullLeadTableRowExpandedLeadIdsReducer)
  .addCase(setInvestorTableNeedsRefetch, SetInvestorTableNeedsRefetchReducer));

export default reducer;
