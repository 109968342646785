import React from 'react';
import { useMutation } from '@apollo/client';
import * as UserJoi from '@biproxi/models/joi/user.joi';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import * as url from 'url';
import { createSearchParams, useNavigate } from 'react-router-dom';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Input, { InputTypesEnum } from '../../elements/Input';
import RESET_PASSWORD from '../../graphql/mutations/resetPassword.mutation';
import { AppActions } from '../../redux/app.redux';
import {
  AuthenticationModal,
  Content,
  AuthLoading,
} from '../AuthenticationModalLayout';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import Flex, { CustomSpacer } from '../../elements/Flex';
import Icon, { Icons } from '../../elements/Icon';
import { AppState, useAppDispatch, useAppSelector } from '../../redux/store';
import { UserActions } from '../../redux/user.redux';
import * as UrlUtil from '../../utils/UrlUtil';

export type ResetPasswordModalProps = {
  isStandalone?: boolean;
};

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  isStandalone = false,
}) => {
  /** Hooks */
  const [newPasswordSet, setNewPasswordSet] = React.useState(false);
  const formParams: UseFormParams = {
    fields: {
      password: '',
    },
    fieldOrder: ['/password'],
    schema: UserJoi.resetPasswordParamsSchema,
  };

  const {
    controllers: {
      password,
    },
    isValid,
    setFieldErrors,
  } = useForm(formParams);
  const navigate = useNavigate();

  /** Actions */
  const { authRedirectLoading } = useAppSelector((state: AppState) => state.user);
  const dispatch = useAppDispatch();
  const setAuthRedirectLoading = (loading: boolean) => dispatch(
    UserActions.setAuthRedirectLoading({ loading }),
  );
  const popModal = () => dispatch(
    AppActions.popModal(),
  );
  const completeAuthentication = (token: string, hubspotToken: string) => dispatch(
    UserActions.completeAuthentication({
      token,
      hubspotToken,
    }),
  );

  /** GraphQL */
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: async ({ resetPassword: { token, hubspotToken } }) => {
      completeAuthentication(token, hubspotToken);
      setNewPasswordSet(true);
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        password.setError(message);
      }
    },
  });

  /** Render */
  const resetUserPassword = (event?: React.FormEvent) => {
    if (event) event.preventDefault();
    const { query: { code } } = url.parse(window.location.toString(), true);
    if (!isValid()) return;
    resetPassword({
      variables: {
        params: {
          resetPasswordCode: code || 'error',
          password: password.value().toString(),
        },
      },
    });
  };

  return (
    <AuthenticationModal fullscreen={isStandalone} showExit={!isStandalone}>
      {(() => {
        if (authRedirectLoading) {
          return (
            <AuthLoading />
          );
        }

        return (
          <>
            {newPasswordSet ? (
              <Content>
                <Flex flex="1" align="center" justify="center" direction="column" height="100%">
                  <Icon icon={Icons.CheckCircleSolid} size={48} color={Colors.Green500} />
                  <Text type={TextTypesEnum.Bold24} color={Colors.Black} margin="12px 0 0">Your new password is set</Text>
                  <Text type={TextTypesEnum.Regular16} color={Colors.Grey700} margin="4px 0 24px" align="center">
                    You&apos;re all good to go!
                  </Text>
                </Flex>
                <Button
                  text="Sign in"
                  onClick={() => {
                    if (isStandalone) {
                      setAuthRedirectLoading(true);
                      const { query } = UrlUtil.parse(window.location.toString());
                      const { to, ...rest } = query;
                      if (to) {
                        navigate({
                          pathname: to as string,
                          search: createSearchParams(rest).toString(),
                        });
                      } else {
                        popModal();
                        navigate('/app/dashboard/search');
                      }
                    } else {
                      popModal();
                    }
                  }}
                  type={ButtonTypesEnum.Outline}
                  size={ButtonSizesEnum.Large}
                  isFullWidth
                />
              </Content>
            ) : (
              <Content onSubmit={(event) => resetUserPassword(event)}>
                <Text type={TextTypesEnum.Bold24}>Create a new password</Text>
                <Text type={TextTypesEnum.Regular16} color={Colors.Grey700} margin="4px 0 0">
                  Enter a new password for your Biproxi account
                </Text>
                <CustomSpacer height="24px" />
                <Input
                  autoFocus
                  label="Set a new password"
                  placeholder="Enter a password"
                  inputType={InputTypesEnum.Password}
                  value={password.value().toString()}
                  onChange={password.setValue}
                  ref={password.setRef}
                  error={password.error()}
                  margin="0 0 24px"
                />
                <Button
                  text="Continue"
                  isLoading={loading}
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Large}
                  isFullWidth
                />
              </Content>
            )}
          </>
        );
      })()}
    </AuthenticationModal>
  );
};

export default ResetPasswordModal;
