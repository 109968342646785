import React from 'react';
import PhoneInput from '@biproxi/react-phone-input-2';
import '@biproxi/react-phone-input-2/lib/style.css';
import * as Polished from 'polished';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

type InnerContainerProps = {
  error?: string;
  focused?: boolean;
  readOnly?: boolean;
};

// overwrite the preset style set from the import
const InnerContainer = styled.div<InnerContainerProps>`
  .PhoneInputContainer {
    font-family: "aktiv-grotesk", sans-serif;
    font-size: 1.6rem;
    position: relative;
    transition: all 0.2s;
    border-radius: 8px;
    width: 100%;
    border: ${(props) => {
    if (props.error) return `1px solid ${Colors.Red500}`;
    if (props.focused) return `1px solid ${Colors.Brand700 || Colors.Blurple700}`;
    return `1px solid ${Colors.Grey300}`;
  }};

    &:hover {
      border: ${(props) => {
    if (props.error) return `1px solid ${Colors.Red500}`;
    if (props.focused) return `1px solid ${Colors.Brand700 || Colors.Blurple700}`;
    return `1px solid ${Colors.Grey400}`;
  }};
    }

    box-shadow: ${(props) => {
    if (props.error && props.focused) return `0px 0px 0px 4px ${Polished.rgba(Colors.Red500, 0.10)}`;
    if (props.focused) return `0px 0px 0px 4px ${Polished.rgba(Colors.Brand700 || Colors.Blurple700, 0.10)}`;
    return null;
  }};

    &:focus {
      border: 5px solid red;
      border: ${(props) => (props.error ? `1px solid ${Colors.Red500}` : `1px solid ${Colors.Brand700 || Colors.Blurple700}`)};
      box-shadow: ${(props) => (props.error ? `0px 0px 0px 4px ${Polished.rgba(Colors.Red500, 0.10)}` : `0px 0px 0px 4px ${Polished.rgba(Colors.Brand700 || Colors.Blurple700, 0.10)}`)};
    }

    :disabled {
      cursor: not-allowed;
    }

    .PhoneInput {
      font-family: "aktiv-grotesk", sans-serif;
      text-indent: 15px;
      transition: all 0.2s;
      color: ${Colors.Grey900};
      font-weight: 400;
      position: relative;
      font-size: 1.6rem;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 50px;
      padding-left: 0;
      background: ${Colors.White};
      border: 0px;
      border-radius: 8px;
      height: 48px;
      width: calc(100% - 50px);
      z-index: 202;
      outline: none;

      &.invalid-number {
        border: ${(props) => (props.error ? `1px solid ${Colors.Red500}` : `1px solid ${Colors.Grey300}`)};
        background-color: ${Colors.White};
        border-left-color: ${Colors.Grey300};
        &:focus {
          border: ${(props) => (props.error ? `1px solid ${Colors.Red500}` : `1px solid ${Colors.Grey400}`)};
          border-left-color: ${Colors.Grey300};
          background-color: ${Colors.White};
        }
      }
      ::placeholder {
        color: ${Colors.Grey400};
      }
    }

    .flag-dropdown {
      z-index: 201;
      outline: none;
      position: absolute;
      width: 99%;
      top: 0;
      bottom: 0;
      padding: 0;
      border: 0px;
      border-radius: 8px;
      background-color: ${Colors.Grey50};
      &.open {
        background: ${Colors.White};
        border-radius: 8px 8px 0 0;
        .selected-flag {
          background: ${Colors.Grey200};
          border-radius: 8px 0 0 0;
        }
      }
      &:hover, &:focus {
        cursor: pointer;
        .selected-flag {
          background-color: ${Colors.Grey200};
        }
      }
    }

    input[disabled] {
      &+.flag-dropdown {
        &:hover {
          cursor: default;
          .selected-flag {
            background-color: transparent;
          }
        }
      }
    }

    .selected-flag {
      transition: all 0.2s;
      background: ${Colors.Grey100};
      position: relative;
      width: 50px;
      height: 100%;
      padding: 0 0 0 10px;
      border-radius: 8px 0 0 8px;
      border-right: 1px solid ${Colors.Grey300};
      .flag {
        position: absolute;
        top: 50%;
        left: ${(props) => (props.readOnly ? '35%' : null)};
        margin-top: -5px;
      }
      .arrow {
        display: ${(props) => (props.readOnly ? 'none' : 'block')};
        position: relative;
        top: 50%;
        margin-top: -2px;
        left: 25px;
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 4px solid #555;
        &.up {
          border-top: none;
          border-bottom: 4px solid #555;
        }
      }
      &.open {
        z-index: 2;
      }
    }

    .country-list {
      z-index: 2;
      border-radius: 0 0 4px 4px;
      border: 1px solid ${Colors.Grey300};
      border-top: none;
      list-style: none;
      position: absolute;
      padding: 0;
      margin: 0px 0 10px -1px;
      box-shadow: unset;
      background-color: ${Colors.White};
      width: calc(100% + 2px);
      max-height: 200px;
      overflow-y: scroll;
      .flag {
        display: inline-block;
      }
      .divider {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid #ccc;
      }
      .country {
        padding: 7px 9px;
        .dial-code {
          color: #6b6b6b;
        }
        &:hover {
          background-color: #f1f1f1;
        }
        &.highlight {
          background-color: #f1f1f1;
        }
      }
      .flag {
        margin-right: 7px;
        margin-top: 2px;
      }
      .country-name {
        margin-right: 6px;
      }
      .search {
        position: sticky;
        top: 0;
        background-color: #fff;
        padding: 5px 0 6px 10px;
      }
      .search-emoji {
        display: none;
        font-size: 1.6rem;
      }
      .search-box {
        border: ${(props) => (props.error ? `1px solid ${Colors.Red500}` : `1px solid ${Colors.Grey400}`)};
        border-radius: 3px;
        font-size: 1.6rem;
        line-height: 15px;
        padding: 3px 8px 5px;
        outline: none;
      }
      .no-entries-message {
        padding: 7px 10px 11px;
        opacity: .7;
      }
      &::-webkit-scrollbar { width: 12px; }
      &::-webkit-scrollbar-track { background-color: #e6e6e6; }
      &::-webkit-scrollbar-thumb { background-color: #c5c5c4; border-radius: 5px; }
    }
  }
`;

type PhoneNumberInputProps = {
  onChange?: any;
  autoFocus?: boolean;
  error?: string | null;
  placeholder?: string;
  readOnly?: boolean;
  value?: string;
};

const PhoneNumberInput = React.forwardRef<HTMLElement, PhoneNumberInputProps>(({
  value,
  onChange,
  autoFocus = false,
  placeholder,
  error,
  readOnly = false,
}, ref) => {
  /** State */
  const [focused, setFocused] = React.useState<boolean>(autoFocus);

  /** Render */
  /** If readOnly, we can pass the value to the input to display. We do not want to
   * do this when the input is active, however, as it will interfere with masking
   * and cause other issues
   */
  let inputProps;
  if (readOnly) {
    inputProps = {
      value,
    };
  } else {
    inputProps = {
      autoFocus,
    };
  }

  return (
    <Container>
      <InnerContainer
        error={error}
        focused={focused}
        readOnly={readOnly}
      >
        <PhoneInput
          country="us"
          value={value}
          ref={ref}
          onFocus={readOnly ? () => null : () => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(_value, _country, _event, _formattedValue) => {
            onChange(_event, true);
          }}
          placeholder={placeholder || 'Enter your phone number'}
          containerClass="PhoneInputContainer"
          inputClass="PhoneInput"
          countryCodeEditable
          inputProps={inputProps}
          disabled={readOnly}
          // regions={['north-america']} // comment this out to get list of all countries
        />
      </InnerContainer>
    </Container>
  );
});

export default PhoneNumberInput;
