import React from 'react';
import styled from '@emotion/styled';
import {
  ModalContainer, ModalContent, ModalFooter,
} from '../../styles/components/Modal.styles';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { useMobileMedia, media } from '../../utils/MediaQuery';
import Icon, { Icons } from '../../elements/Icon';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Flex from '../../elements/Flex';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';

const Container = styled.form`
  width: 336px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.mobile} {
    width: 100%;
  }
`;

const Circle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  background-color: ${Colors.Green200};
  margin-bottom: 24px;
  border-radius: 50%;
`;

const WarningContainer = styled.div`
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 8px 0 8px;

  ${media.mobile} {
    width: 100%;
  }
`;

interface IClickableText {
  text: string;
  onClick: () => void;
}

export enum ConfirmChangeModalTypesEnum {
  Success,
  Warning,
}

export type ConfirmChangeModalProps = {
  type?: ConfirmChangeModalTypesEnum;
  dataCy?: string;
  title?: string;
  text?: string;
  clickableText?: IClickableText;
  confirm: Function;
  confirmLoading?: boolean;
  confirmButton?: boolean;
  buttonText?: string;
};

const ConfirmChangeModal: React.FC<ConfirmChangeModalProps> = ({
  type,
  dataCy,
  title,
  text,
  clickableText,
  confirm,
  confirmLoading = false,
  confirmButton = true,
  buttonText,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  /** Hooks */
  const isMobile = useMobileMedia();

  if (type === ConfirmChangeModalTypesEnum.Success) {
    return (
      <ModalContainer data-cy="confirm-modal-container" width={isMobile ? 'calc(100% - 32px)' : null}>
        <ModalContent>
          <Flex align="center" justify="center">
            <Circle>
              <Icon icon={Icons.CheckSolid} size={24} color={Colors.Green700} />
            </Circle>
          </Flex>
          <Container>
            <Text
              type={TextTypesEnum.Bold24}
              color={Colors.Black}
              align="center"
              data-cy="success-confirm-modal-title"
            >
              {title}
            </Text>
            <Text
              type={TextTypesEnum.Regular16}
              color={Colors.Grey700}
              align="center"
              margin="24px 0"
              data-cy="success-confirm-modal-body"
            >
              {text}
            </Text>
            {clickableText && (
              <Text
                type={TextTypesEnum.Bold16}
                color={Colors.Brand700 || Colors.Blurple700}
                align="center"
                onClick={clickableText.onClick}
                margin="0 0 24px 0"
              >
                {clickableText.text}
              </Text>
            )}
            <Button
              text="Okay"
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Large}
              onClick={confirm}
              htmlType="submit"
              isFullWidth
              data-cy="confirm-change-modal-button"
            />
          </Container>
        </ModalContent>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer
      data-cy="confirm-modal-container"
    >
      <ModalContent>
        <WarningContainer>
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Black}
            data-cy="warning-confirm-modal-title"
          >
            {title}
          </Text>
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Grey900}
            margin="8px 0 0"
            data-cy="warning-confirm-modal-body"
          >
            {text}
          </Text>
        </WarningContainer>
      </ModalContent>
      <ModalFooter>
        <Flex justify="flex-end" width="100%">
          <Button
            data-cy="cancel-button"
            text="Cancel"
            type={ButtonTypesEnum.Outline}
            size={ButtonSizesEnum.Medium}
            onClick={() => popModal()}
            margin="0px 8px 0px 0px"
          />
          {confirmButton && (
          <Button
            data-cy={dataCy ?? 'confirm-button'}
            text={buttonText || title}
            isLoading={confirmLoading}
            type={ButtonTypesEnum.Warning}
            size={ButtonSizesEnum.Medium}
            onClick={confirm}
          />
          )}
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default ConfirmChangeModal;
