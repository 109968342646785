import gql from 'graphql-tag';
import LeadFragments from '../fragments/lead.fragments';

const CREATE_LEAD = gql`
  mutation createLead($params: LeadParams!) {
    createLead(params: $params) {
      ...LeadFields
      ...LeadLoiFiles
      ...LeadNotes
      ...LeadSellerNotes
      ...LeadMeta
    }
  }

  ${LeadFragments.fields}
  ${LeadFragments.loiFiles}
  ${LeadFragments.notes}
  ${LeadFragments.sellerNotes}
  ${LeadFragments.sellerNotes}
  ${LeadFragments.meta}
`;

export default CREATE_LEAD;
