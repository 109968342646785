import gql from 'graphql-tag';

const LISTING_OVERVIEW_ANALYTICS = gql`
    query ListingOverviewAnalytics($params: ListingOverviewAnalyticsParams) {
        listingOverviewAnalytics(params: $params) {
            totalListingViews
            uniqueListingViews
            totalListingLeads
            totalCAsSigned
        }
    }
`;

export default LISTING_OVERVIEW_ANALYTICS;
