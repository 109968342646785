import gql from 'graphql-tag';

const GET_ORGANIZATION_USERS = gql`
  query Organizations($where: OrganizationWhere) {
    organizations(where: $where) {
      usersConnection {
        edges {
          permissions
          roles
          node {
            _id
            firstName
            email
            lastName
          }
        }
        totalCount
      }
    }
  }
`;

export default GET_ORGANIZATION_USERS;
