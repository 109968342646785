import React from 'react';
import NextLink from 'next/link';
import {
  Link as RRLink,
} from 'react-router-dom';
import NextUtil from '../utils/NextUtil';

type LinkProps = {
  href: string;
  target?: string;
  isExternal?: boolean;
  shallow?: boolean;
  children?: React.ReactNode;
  dataCy?: string;
};

const Link: React.FC<LinkProps> = ({
  href,
  target,
  isExternal = false, // does the href refer to a page off biproxi.com?
  shallow = false, // use only if you are using NextLink on a single page and want to change the URL without changing state or re-running Next data fetching methods (i.e. getServerSideProps, getStaticProps, etc.) within the page
  children,
  dataCy,
}) => {
  const isOnSPAPage = NextUtil.hasWindow() ? NextUtil.isSPAUrl(window.location.href) : false;
  const isToSPAPage = NextUtil.isSPAUrl(href);

  if (isExternal || target) {
    return <a href={href} target={target} data-cy={dataCy}>{children}</a>;
  }

  if (isOnSPAPage && isToSPAPage) {
    return <RRLink to={href}>{children}</RRLink>;
  }

  // TODO: Fix legacyBehavior prop in NextLink
  return <NextLink shallow={shallow} href={href}>{children}</NextLink>;
};

export default Link;
