/* eslint-disable camelcase */
import IUtmParameters from '@biproxi/models/interfaces/IUtmParameters';
import * as url from 'url';
import LocalStorageUtil from './LocalStorageUtil';

/** Save UTM parameters from a marketing effort to local storage.
 * Preserved through page and browser refresh.
 */
const saveUtmParameters = (): void => {
  const { query } = url.parse(window.location.toString(), true);

  const lowerCased: any = Object.keys(query).reduce((accumulator, key) => {
    accumulator[key.toLowerCase()] = query[key];
    return accumulator;
  }, {});

  const UtmParameters = {
    utm_id: lowerCased?.utm_id ?? '',
    utm_source: lowerCased?.utm_source ?? '',
    utm_medium: lowerCased?.utm_medium ?? '',
    utm_campaign: lowerCased?.utm_campaign ?? '',
    utm_term: lowerCased?.utm_term ?? '',
    utm_content: lowerCased?.utm_content ?? '',
  };

  LocalStorageUtil.setMultipleInStorage(UtmParameters);
};

/** Get UTM parameters out of local storage. We add another level
 * of nullish coalescing to the getter to prevent errors when
 * empty UTM parameters are not set in local storage, which can
 * occur with some browser/OS/ combinations.
 */
const getUtmParameters = (): IUtmParameters => {
  const utmParams = LocalStorageUtil.getMultipleFromStorage(['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']) as IUtmParameters;
  return {
    utm_id: utmParams.utm_id ?? '',
    utm_source: utmParams.utm_source ?? '',
    utm_medium: utmParams.utm_medium ?? '',
    utm_campaign: utmParams.utm_campaign ?? '',
    utm_term: utmParams.utm_term ?? '',
    utm_content: utmParams.utm_content ?? '',
  };
};

/** Clear UTM parameters from local storage. We have no use for them
 * after visitor registers.
 */
const clearUtmParameters = (): void => {
  const UtmParameters = ['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  LocalStorageUtil.clearMultipleFromStorage(UtmParameters);
};

const UtmUtil = {
  saveUtmParameters,
  getUtmParameters,
  clearUtmParameters,
};

export default UtmUtil;
