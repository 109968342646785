import useHandleRouteChange from './useHandleRouteChange.hook';

type UseCreateListingHook = () => void;

const useCreateListing = (): UseCreateListingHook => {
  /** Hooks */
  const handleRouteChange = useHandleRouteChange();

  return () => {
    handleRouteChange('/app/create-listing');
  };
};

export default useCreateListing;
