import ListingInfoFieldNamesEnum from '../enums/ListingInfoFieldNamesEnum';

type TUnitBreakdownForSelfStorage = {
  [ListingInfoFieldNamesEnum.FiveByFive]: number | null;
  [ListingInfoFieldNamesEnum.TenByTen]: number | null;
  [ListingInfoFieldNamesEnum.TenByFifteen]: number | null;
  [ListingInfoFieldNamesEnum.TenByTwenty]: number | null;
  [ListingInfoFieldNamesEnum.TenByThirty]: number | null;
}

export const unitBreakdownForSelfStorageState = (current?: TUnitBreakdownForSelfStorage): TUnitBreakdownForSelfStorage => ({
  [ListingInfoFieldNamesEnum.FiveByFive]: null,
  [ListingInfoFieldNamesEnum.TenByTen]: null,
  [ListingInfoFieldNamesEnum.TenByFifteen]: null,
  [ListingInfoFieldNamesEnum.TenByTwenty]: null,
  [ListingInfoFieldNamesEnum.TenByThirty]: null,
  ...current,
});

export default TUnitBreakdownForSelfStorage;
