import ListingAssetClassEnum from '../enums/ListingAssetClassEnum';
import ListingPropertyTypeEnum from '../enums/ListingPropertyTypeEnum';

export default {
  // Retail
  [ListingAssetClassEnum.Retail]: [
    ListingPropertyTypeEnum.SingleTenantRetail,
    ListingPropertyTypeEnum.StripCenter,
    ListingPropertyTypeEnum.NeighborhoodCenter,
    ListingPropertyTypeEnum.PowerCenter,
    ListingPropertyTypeEnum.Mall,
    ListingPropertyTypeEnum.MixedUseRetail,
    ListingPropertyTypeEnum.GasStation,
    ListingPropertyTypeEnum.Childcare,
    ListingPropertyTypeEnum.Other,
  ],
  // Industrial
  [ListingAssetClassEnum.Industrial]: [
    ListingPropertyTypeEnum.HeavyManufacturing,
    ListingPropertyTypeEnum.LightAssembly,
    ListingPropertyTypeEnum.DistributionWarehouse,
    ListingPropertyTypeEnum.GeneralPurpose,
    ListingPropertyTypeEnum.Flex,
    ListingPropertyTypeEnum.TruckTerminal,
    ListingPropertyTypeEnum.Other,
  ],
  // Multi-family
  [ListingAssetClassEnum.MultiFamily]: [
    ListingPropertyTypeEnum.HighRise,
    ListingPropertyTypeEnum.MidRise,
    ListingPropertyTypeEnum.Garden,
    ListingPropertyTypeEnum.WalkUp,
    ListingPropertyTypeEnum.StudentHousing,
    ListingPropertyTypeEnum.SeniorHousing,
    ListingPropertyTypeEnum.MixedUseMultiFamily,
    ListingPropertyTypeEnum.AssistedLiving,
    ListingPropertyTypeEnum.IndependentLiving,
    ListingPropertyTypeEnum.MobileHome,
    ListingPropertyTypeEnum.Other,
  ],
  // Office
  [ListingAssetClassEnum.Office]: [
    ListingPropertyTypeEnum.MultiTenant,
    ListingPropertyTypeEnum.Urban,
    ListingPropertyTypeEnum.Suburban,
    ListingPropertyTypeEnum.MedicalOffice,
    ListingPropertyTypeEnum.BuilttoSuit,
    ListingPropertyTypeEnum.SingleTenantOffice,
    ListingPropertyTypeEnum.MixedUseOffice,
    ListingPropertyTypeEnum.MedicalCampus,
    ListingPropertyTypeEnum.Other,
  ],
  // Hospitality
  [ListingAssetClassEnum.Hospitality]: [
    ListingPropertyTypeEnum.LimitedService,
    ListingPropertyTypeEnum.SelectService,
    ListingPropertyTypeEnum.ExtendedStay,
    ListingPropertyTypeEnum.FullService,
    ListingPropertyTypeEnum.Resort,
    ListingPropertyTypeEnum.Casino,
    ListingPropertyTypeEnum.Other,
  ],
  // Net Lease
  [ListingAssetClassEnum.NetLease]: [
    ListingPropertyTypeEnum.SingleNet,
    ListingPropertyTypeEnum.DoubleNet,
    ListingPropertyTypeEnum.TripleNet,
    ListingPropertyTypeEnum.AbsoluteNet,
    ListingPropertyTypeEnum.Gross,
    ListingPropertyTypeEnum.ModifiedGross,
    ListingPropertyTypeEnum.Other,
  ],
  // Self Storage
  [ListingAssetClassEnum.SelfStorage]: [
    ListingPropertyTypeEnum.SelfStorage,
    ListingPropertyTypeEnum.Other,
  ],
  // Senior / Assisted Living
  [ListingAssetClassEnum.SeniorLiving]: [
    ListingPropertyTypeEnum.HighRise,
    ListingPropertyTypeEnum.MidRise,
    ListingPropertyTypeEnum.Garden,
    ListingPropertyTypeEnum.SeniorHousing,
    ListingPropertyTypeEnum.AssistedLiving,
    ListingPropertyTypeEnum.IndependentLiving,
    ListingPropertyTypeEnum.Other,
  ],
  // Healthcare
  [ListingAssetClassEnum.Healthcare]: [
    ListingPropertyTypeEnum.UrgentCare,
    ListingPropertyTypeEnum.Dialysis,
    ListingPropertyTypeEnum.MedicalCampus,
    ListingPropertyTypeEnum.Dental,
    ListingPropertyTypeEnum.Veterinarian,
    ListingPropertyTypeEnum.Hospital,
    ListingPropertyTypeEnum.MedicalOffice,
    ListingPropertyTypeEnum.Other,
  ],
  // Government Office
  [ListingAssetClassEnum.GovernmentOffice]: [
    ListingPropertyTypeEnum.GSA,
    ListingPropertyTypeEnum.Federal,
    ListingPropertyTypeEnum.State,
    ListingPropertyTypeEnum.USGovernment,
    ListingPropertyTypeEnum.GovernmentContractor,
    ListingPropertyTypeEnum.Other,
  ],
  // Special Purpose
  [ListingAssetClassEnum.SpecialPurpose]: [
    ListingPropertyTypeEnum.ParkingLot,
    ListingPropertyTypeEnum.Church,
    ListingPropertyTypeEnum.MobileHome,
    ListingPropertyTypeEnum.SportsandLeisure,
    ListingPropertyTypeEnum.CellTowers,
    ListingPropertyTypeEnum.BioTech,
    ListingPropertyTypeEnum.DataCenter,
    ListingPropertyTypeEnum.Childcare,
    ListingPropertyTypeEnum.Theatre,
    ListingPropertyTypeEnum.BowlingAlley,
    ListingPropertyTypeEnum.Marina,
    ListingPropertyTypeEnum.Other,
  ],
  // Notes -- TODO -- Performing?
  [ListingAssetClassEnum.Notes]: [
    ListingPropertyTypeEnum.SubPerforming,
    ListingPropertyTypeEnum.NonPerforming,
    ListingPropertyTypeEnum.Other,
  ],
  // Land
  [ListingAssetClassEnum.Land]: [
    ListingPropertyTypeEnum.OutParcel,
    ListingPropertyTypeEnum.Greenfield,
    ListingPropertyTypeEnum.Infill,
    ListingPropertyTypeEnum.Brownfield,
    ListingPropertyTypeEnum.Development,
    ListingPropertyTypeEnum.Redevelopment,
    ListingPropertyTypeEnum.Other,
  ],
  // SFR Portfolio
  [ListingAssetClassEnum.SFRPortfolio]: [
    ListingPropertyTypeEnum.Residential,
    ListingPropertyTypeEnum.Other,
  ],
};
