import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import Text, { TextAsEnum, TextTypesEnum } from './Text';

type ContainerProps = {
  margin: string;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${(props) => props.margin};
  height: 16px;
`;

type CharLimitProps = {
  value: string;
  charLimit: number;
  margin?: string
};

const CharLimit: React.FC<CharLimitProps> = ({ value, charLimit, margin = '0 0 4px' }) => (
  <Container margin={margin}>
    <Text
      type={TextTypesEnum.Medium12}
      as={TextAsEnum.Label}
      color={value.length === charLimit ? Colors.Red500 : Colors.Grey500}
    >
      {value.length}
      /
      {charLimit}
    </Text>
  </Container>
);

export default CharLimit;
