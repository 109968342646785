import gql from 'graphql-tag';
import NotificationFragments from '../fragments/notification.fragments';

const LIST_NOTIFICATIONS = gql`
  query Notifications($params: ListNotificationsParams) {
    notifications(params: $params) {
      data {
        ...NotificationFields
        ...NotificationListing
        ...NotificationUser
        ...NotificationLead
        ...NotificationMeta
      }
      info {
        totalCount
        totalUnreadCount
      }
    }
  }

  ${NotificationFragments.fields}
  ${NotificationFragments.listing}
  ${NotificationFragments.user}
  ${NotificationFragments.lead}
  ${NotificationFragments.meta}
`;

export default LIST_NOTIFICATIONS;
