import gql from 'graphql-tag';
import ListingFragments from '../fragments/listing.fragments';
import OfferFragments from '../fragments/offer.fragments';

const LIST_LISTINGS_WITH_OFFERS = gql`
  query Listings($params: ListListingsParams) {
    listings(params: $params) {
      data {
        ...ListingFields
        ...ListingAddress
        ...ListingPortfolioAddresses
        ...ListingMedia
        ...ListingUser

        offers {
          ...OfferFields
          ...OfferLoiFiles
          ...OfferUser
          ...OfferListing
        }
      }
    }
  }

  ${ListingFragments.fields}
  ${ListingFragments.address}
  ${ListingFragments.portfolioAddresses}
  ${ListingFragments.media}
  ${ListingFragments.user}
  ${OfferFragments.offerFields}
  ${OfferFragments.loiFiles}
  ${OfferFragments.user}
  ${OfferFragments.listing}
`;

export default LIST_LISTINGS_WITH_OFFERS;
