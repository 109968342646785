import React from 'react';
import styled from '@emotion/styled';
import Colors, { ColorsType } from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Icon, { IconsType } from '../elements/Icon';

type ContainerProps = {
  height: string;
  'data-cy'?: string;
  padding?: string;
  flexDirection: string;
}

const Container = styled.div<ContainerProps>`
  width: fill-available;
  height: ${(props) => props.height};
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${(props) => props.padding};
  data-cy: ${(props) => props['data-cy']};
`;

type NoContentProps = {
  height: string;
  'data-cy'?: string;
  text?: React.ReactNode;
  icon: IconsType;
  iconSize?: string;
  iconColor?: ColorsType;
  iconBorderBackground?: ColorsType;
  iconBorderRadius?: string;
  iconBorderWidth?: string;
  iconBorderHeight?: string;
  iconMargin?: string;
  padding?: string;
  flexDirection?: string;
};

const NoContent: React.FC<NoContentProps> = ({
  height,
  'data-cy': dataCy,
  text,
  icon,
  iconSize = '24px',
  iconBorderWidth = '64px',
  iconBorderHeight = '64px',
  iconColor = Colors.Grey700,
  iconBorderBackground = Colors.Grey200,
  iconBorderRadius = '100px',
  iconMargin,
  flexDirection = 'column',
  padding,
}) => (
  <Container
    data-cy={dataCy}
    height={height}
    padding={padding}
    flexDirection={flexDirection}
  >
    <Icon
      icon={icon}
      color={iconColor}
      borderRadius={iconBorderRadius}
      background={iconBorderBackground}
      width={iconBorderWidth}
      height={iconBorderHeight}
      margin={iconMargin}
      size={iconSize}
    />
    <Text
      type={TextTypesEnum.Regular14}
      color={Colors.Grey900}
      align={flexDirection === 'column' ? 'center' : 'left'}
    >
      {text}
    </Text>
  </Container>
);
export default NoContent;
