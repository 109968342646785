import ISaveChanges from '../interfaces/ISaveChanges';

const saveChangesState = (): ISaveChanges => ({
  hasChanges: false,
  saveChanges: null,
  discardChanges: null,
  nextUrl: null,
});

export default saveChangesState;
