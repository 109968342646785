import { logger } from '@biproxi/logger';
import axios from 'axios';
import { config } from '@biproxi/env';

const getTimezoneInfo = async (lat: number, lng: number): Promise<any> => {
  // timestamp needs to be in seconds
  const baseUrl = 'https://maps.googleapis.com/maps/api/timezone/json';
  const url = `${baseUrl}?location=${lat},${lng}&timestamp=${Date.now() / 1000}&key=${config.NEXT_PUBLIC_GOOGLE_BROWSER_KEY}`;
  let response;
  try {
    response = await axios.get(url);
    return response?.data;
  } catch (e) {
    logger.error('https://maps.googleapis.com/maps/api/timezone/json error', e);
    return e;
  }
};

const GoogleTimezoneUtil = {
  getTimezoneInfo,
};

export default GoogleTimezoneUtil;
