import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import ChatLayoutCustom from '../ChatLayoutCustom';
import { media } from '../../utils/MediaQuery';

const Container = styled.div`
  width: 100%;
  height: 65vh;

  ${media.mobile} {
    height: calc(100vh - 135px);
  }
`;

export type LeadModalChatProps = {
  lead: ILeadGraphQL;
};

const LeadModalChat: React.FC<LeadModalChatProps> = ({
  lead,
}) => (
  <Container data-cy="leads-chat-container">
    <ChatLayoutCustom
      channel={lead?.channel}
      leadId={lead._id}
      isInvestorMatch={Boolean(lead.isInvestorMatch)}
    />
  </Container>
);

export default LeadModalChat;
