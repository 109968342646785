import React from 'react';
import styled from '@emotion/styled';
import { useMutation as rqMutation } from 'react-query';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import { TeamRolesEnum } from '@biproxi/models/enums/Neo4jRolesEnums';
import { connectUserToOrganization } from '@biproxi/next-api-requests';
import StringUtil from '@biproxi/models/utils/StringUtil';
import { TeamPermissionsEnum } from '@biproxi/models/enums/PermissionsEnum';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { media } from '../../utils/MediaQuery';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Input from '../../elements/Input';
import Dropdown from '../../elements/Dropdown';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { ModalTypesEnum } from './Modal';
import { ConfirmChangeModalTypesEnum } from './ConfirmChangeModal';

const Container = styled.div`
  width: 632px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;

  ${media.mobile} {
    width: 100%;
  }
`;

export interface IInviteUserError {
  message: string,
  errors: boolean,
}

export enum InviteUserTeamErrorEnum {
  InvalidEmail = 'Invalid email. Please enter a valid email belonging to the user you would like to invite.',
  Duplicate = 'This email has already been sent an invite or is already a part of the team.',
  MissingRole = 'A role is required. Please select a role for the invited user to have.',
}

export type InviteUserToTeamModalProps = {
  refetchUsers: any,
  organizationId: string,
  organizationName: string,
  currentUsers: any,
  userPermissions: TeamPermissionsEnum[]
}

const InviteUserToTeamModal: React.FC<InviteUserToTeamModalProps> = ({
  refetchUsers,
  organizationId,
  organizationName,
  currentUsers,
  userPermissions,
}) => {
  /** State */
  const [email, setEmail] = React.useState<string>('');
  const [userRole, setUserRole] = React.useState<TeamRolesEnum>(null);

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushUserInvitationConfirmationModal = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Success,
        title: 'Your team member has been added',
        text: 'Once your team member accepts the invitation, he/she should have access to the team.',
        confirm: () => popModal(),
      },
    }),
  );

  /** React Query */
  const { mutate: handleAddUserToOrganization, isLoading } = rqMutation((params: INeo4jServiceAPI.IConnectUserToOrgParams) => connectUserToOrganization(params), {
    onSuccess: () => handleAddUserToOrganizationSuccess(),
    onError: (err) => handleAddUserToOrganizationError(err),
  });

  /** Functions */
  const errorHandler = (cleanedEmail: string): IInviteUserError => {
    const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // should use joi/other standard
    const isInvalidEmail = !emailReg.test(cleanedEmail);

    // prevents messing things up with permissions, should probably provide a way to resend the invite email though.
    const isDuplicate = currentUsers.map((user) => user?.node?.email).includes(email);

    const errorConfig: IInviteUserError = {
      message: null,
      errors: false,
    };
    if (isInvalidEmail) {
      errorConfig.message = InviteUserTeamErrorEnum.InvalidEmail;
      errorConfig.errors = true;
    }
    if (isDuplicate) {
      errorConfig.message = InviteUserTeamErrorEnum.Duplicate;
      errorConfig.errors = true;
    }
    if (!userRole || !userRole?.length) {
      errorConfig.message = InviteUserTeamErrorEnum.MissingRole;
      errorConfig.errors = true;
    }
    return errorConfig;
  };

  const handleInviteMember = (userEmail: string): boolean => {
    const cleanedEmail = StringUtil.lowerCaseTrim(userEmail);
    const errors = errorHandler(cleanedEmail);

    if (errors.errors) {
      pushToast({
        type: ToastTypesEnum.Error,
        message: errors.message,
      });
      return false;
    }
    const mutationParams: INeo4jServiceAPI.IConnectUserToOrgParams = {
      userEmail: cleanedEmail,
      organizationName,
      organizationId,
      userRoles: [TeamRolesEnum.Pending, userRole?.toUpperCase() as TeamRolesEnum],
    };
    handleAddUserToOrganization(mutationParams);
    return true;
  };

  const handleAddUserToOrganizationSuccess = () => {
    refetchUsers?.();
    pushUserInvitationConfirmationModal();
  };

  const handleAddUserToOrganizationError = (error) => {
    pushToast({
      type: ToastTypesEnum.Error,
      message: error,
    });
  };

  const inviteUserRoles: string[] = Object.keys(TeamRolesEnum)?.filter((role: TeamRolesEnum) => TeamRolesEnum[role] !== TeamRolesEnum.Owner
  && TeamRolesEnum[role] !== TeamRolesEnum.Pending);
  return (
    <ModalContainer
      data-cy="invite-users-to-team-modal"
    >
      <ModalHeader
        title="Add member"
        close={popModal}
      />
      <ModalContent>
        <Container>
          <Input
            label="Email*"
            placeholder="Enter email address"
            onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => setEmail(event.target.value)}
            data-cy="invite-user-email-input"
          />
          <Dropdown
            label="Role*"
            placeholder="Select member role"
            items={inviteUserRoles.filter(
              (role: TeamRolesEnum) => {
                if (!userPermissions?.includes(TeamPermissionsEnum.AddAdmin) && role.toUpperCase() === TeamRolesEnum.Admin) {
                  return false;
                }
                return true;
              },
            ).map((role: TeamRolesEnum) => ({
              name: role,
              value: role,
              active: userRole === role,
            }))}
            value={userRole}
            onChange={(value: any) => setUserRole(value.charAt(0)?.toUpperCase() + value.slice(1).toLowerCase())}
            data-cy="invite-user-role-dropdown"
          />
          <Flex
            width="100%"
            gap="12px"
            justify="flex-end"
          >
            <Button
              text="Cancel"
              size={ButtonSizesEnum.Small}
              type={ButtonTypesEnum.Outline}
              onClick={popModal}
            />
            <Button
              text="Invite member"
              size={ButtonSizesEnum.Small}
              type={ButtonTypesEnum.Primary}
              onClick={() => handleInviteMember(email)}
              isLoading={isLoading}
              data-cy="invite-user-button"
            />
          </Flex>
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default InviteUserToTeamModal;
