import ListingInfoFieldNamesEnum from '../enums/ListingInfoFieldNamesEnum';

export type TUnitBreakdownForMultiFamily = {
  [ListingInfoFieldNamesEnum.Studio]: number | null;
  [ListingInfoFieldNamesEnum.OneBedroom]: number | null;
  [ListingInfoFieldNamesEnum.TwoBedroom]: number | null;
  [ListingInfoFieldNamesEnum.ThreePlusBedroom]: number | null;
}

export const unitBreakdownForMultiFamilyState = (current?: TUnitBreakdownForMultiFamily): TUnitBreakdownForMultiFamily => ({
  [ListingInfoFieldNamesEnum.Studio]: null,
  [ListingInfoFieldNamesEnum.OneBedroom]: null,
  [ListingInfoFieldNamesEnum.TwoBedroom]: null,
  [ListingInfoFieldNamesEnum.ThreePlusBedroom]: null,
  ...current,
});

export default TUnitBreakdownForMultiFamily;
