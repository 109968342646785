import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../redux/store';
import { PrivateEventActions } from '../redux/privateEvent.redux';

type NavigateToPrivateEventParticipation = (privateEventId?: string, isModal?: boolean) => void;

type NavigateToPrivateEventParticipationHook = () => NavigateToPrivateEventParticipation;

const useNavigateToPrivateEventParticipation: NavigateToPrivateEventParticipationHook = () => {
  /* Hooks */
  const navigate = useNavigate();

  /* Actions */
  const dispatch = useAppDispatch();
  const setPrivateEventId = (privateEventId: string) => dispatch(PrivateEventActions.selectPrivateEvent({ privateEventId }));

  const managePrivateEvent = React.useCallback((privateEventId) => {
    setPrivateEventId(privateEventId);
    navigate(`/app/dashboard/events/participate?privateEventId=${privateEventId}`);
  }, []);

  /** Return */
  return managePrivateEvent;
};

export default useNavigateToPrivateEventParticipation;
