import TCache from '../types/TCache';

function makeCache<T>(items: T[], start: TCache<T>, key: string = '_id'): TCache<T> {
  return items.reduce((cur, item) => {
    cur[item[key]] = item;
    return cur;
  }, { ...start });
}

function moveArrayItem<T>(array: T[], currentIndex, newIndex): T[] {
  const [reorderedItem] = array.splice(currentIndex, 1);
  array.splice(newIndex, 0, reorderedItem);
  return array;
}

function removeArrayItems<T>(array: T[], ids: string[], fieldKey: string | null = '_id'): T[] {
  return array.filter((item) => !ids.includes(fieldKey ? item[fieldKey] : item));
}

export default {
  makeCache,
  moveArrayItem,
  removeArrayItems,
};
