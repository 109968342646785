import gql from 'graphql-tag';

const UPDATE_OAUTH_PROVIDERS = gql`
  mutation UpdateUserOAuthProviders($params: UpdateUserOAuthProvidersParams!) {
    updateUserOAuthProviders(params: $params) {
        _id
        oAuthProviders {
          google
          linkedin
        }
    }
  }
`;

export default UPDATE_OAUTH_PROVIDERS;
