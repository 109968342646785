import gql from 'graphql-tag';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import { DocumentNode } from 'graphql';

// Not a perfect type, but it's good enough for now
type TUserFragments = {
  [K in keyof IUserGraphQL]?: DocumentNode;
} & {
  fields: DocumentNode;
}

const UserFragments: TUserFragments = {
  fields: gql`
    fragment UserFields on User {
      _id
      firstName
      lastName
      phoneNumber
      phoneNumberVerifiedAt
      secondaryPhoneNumbers
      displayedPhoneNumber
      email
      role
      isSuperUser
      isAdmin
      organizationId
      organizationName
      bio
      favoritedListings
      isDeactivated
      profileImageFileId
      hideQuickStartGuide
      viewedSubscriptionEarlyAccessModal
      passwordLastSet
    }
  `,
  address: gql`
    fragment UserAddress on User {
      address {
        address1
        address2
        city
        state
        zip
        country
        location {
          type
          coordinates
        }
        googlePlaceId
        timeZoneId
        cherreId
      }
    }
  `,
  investorProfile: gql`
    fragment UserInvestorProfile on User {
      investorProfile {
        exchangeInvestor
        investorType
        assetsOwned
        targetPriceRanges
        preferredInvestmentTypes
        targetAssetClasses
        geographicalFocus
        nationalOrGlobal
      }
    }
  `,
  licenses: gql`
    fragment UserLicenses on User {
      licenses {
        _id
        owner
        state
        number
      }
    }
  `,
  profileImageFile: gql`
    fragment UserProfileImageFile on User {
      profileImageFile {
        url
      }
    }
  `,
  organization: gql`
    fragment UserOrganization on User {
      organization {
        _id
        userId
        active
        name
        description
        message
        phoneNumber
        email
        logoUrl
        posterUrl
      }
    }
  `,
  oAuthProviders: gql`
    fragment UserOAuthProviders on User {
      oAuthProviders {
        google
        linkedin
      }
    }
  `,
  notificationSettings: gql`
    fragment UserNotificationSettings on User {
      notificationSettings {
        disableMessages
        disableOfferUpdates
        disableFavoritedListingsUpdates
        disableInvestmentOpportunities
        disableListingUpdates
        disableNewOffers
        disableRevisedOffer
        disableNewLeads
        disableAccountReminders
      }
    }
  `,
  nylasSettings: gql`
    fragment UserNylasSettings on User {
      nylasSettings {
        nylasAuthenticated
        nylasProvider
        dailyEmailLimit
        emailLastSentAt
      }
    }
  `,
  contacts: gql`
    fragment UserContacts on User {
      contacts {
        _id
        name
        title
        company
        email
        phoneNumber
        license
        profileImageUrl
      }
    }
  `,
  latestActivityEvent: gql`
    fragment UserLatestActivityEvent on User {
      latestActivityEvent {
        eventType
        userId
        createdAt
      }
    }
  `,
  geolocAddress: gql`
    fragment UserGeolocAddress on User {
      geolocAddress {
        city
        state
        country
      }
    }
  `,
  meta: gql`
    fragment UserMeta on User {
      meta {
        createdAt
        lastUpdatedAt
      }
    }
  `,
};

export default UserFragments;
