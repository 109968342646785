import React from 'react';
import styled from '@emotion/styled';
import Image from 'next/image';
import Skeleton from 'react-loading-skeleton';
import ImagePreSafari15 from './ImageSafariPre15';
import BrowserUtil, { BrowserEnum, SafariVersionsEnum } from '../utils/BrowserUtil';

const Container = styled.div<{ aspectRatio: number }>`
  position: relative;
  width: 100%;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
`;

type ImageWithAspectRatioProps = {
  aspectRatio: number;
  src: string;
  skWidth?: number;
}

const ImageWithAspectRatio: React.FC<ImageWithAspectRatioProps> = ({
  aspectRatio,
  src,
  skWidth,
}) => {
  /** State */

  /** Render an alternative element if user is on Safari pre version 15 */
  const { browser, version } = BrowserUtil.detectBrowser();
  const isPre15Safari = Boolean(browser === BrowserEnum.Safari && version !== SafariVersionsEnum.Safari15Plus);
  const isPre15SafariOnDashboardHome = Boolean(browser === BrowserEnum.Safari && version !== SafariVersionsEnum.Safari15Plus && typeof window !== 'undefined' && window.location.pathname.includes('/dashboard/home'));

  /** Render */

  /** Since we are trying to let height be dynamic to account for different aspect
   * ratios, we need to manually calculate height for the skeleton until the image
   * loads */
  let height;
  if (typeof skWidth === 'number') {
    height = `${skWidth * (1 / aspectRatio)}px`;
  } else {
    height = '100%';
  }

  return (
    <Container
      id="AspectRatioContainer"
      aspectRatio={aspectRatio}
    >
      {!src ? (
        <>
          {!isPre15Safari ? (
            <Skeleton height={height} width="100%" />
          ) : (
            <Skeleton height="300px" width="100%" />
          )}
        </>
      ) : (
        <>
          {!isPre15Safari ? (
            <Image
              src={src}
              alt="property-image"
              fill
              sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
              style={{
                objectFit: 'cover',
              }}
              loading="lazy" // change this back to eager at some point when we have better image optimization and descaling. Right now, brokers are uploading images with RIDICULOUSLY high resolutions which take up far too many resources.
            />
          ) : (
            <ImagePreSafari15 src={src} isOnDashboard={isPre15SafariOnDashboardHome} />
          )}
        </>
      )}
    </Container>
  );
};

export default ImageWithAspectRatio;
