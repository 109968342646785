/* eslint-disable no-case-declarations */
import React from 'react';
import styled from '@emotion/styled';
import { INotificationGraphQL } from '@biproxi/models/interfaces/INotification';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import NotificationTypeEnum from '@biproxi/models/enums/NotificationTypeEnum';
import NotificationStateEnum from '@biproxi/models/enums/NotificationStateEnum';
import { useLazyQuery, useMutation } from '@apollo/client';
import FileAccessLevelsEnum from '@biproxi/models/enums/FileAccessLevelsEnum';
import LeadUtil from '@biproxi/models/utils/LeadUtil';
import * as ILeadService from '@biproxi/models/services/ILeadService';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import { IChannelGraphQL } from '@biproxi/models/interfaces/IChannel';
import UpdateLeadTypeEnum from '@biproxi/models/enums/UpdateLeadTypeEnum';
import { useNavigate } from 'react-router-dom';
import Colors from '../styles/Colors';
import Text, { TextAsEnum, TextTypesEnum } from '../elements/Text';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';
import { AppState, useAppDispatch, useAppSelector } from '../redux/store';
import { LeadActions } from '../redux/lead.redux';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from './modal/Modal';
import useNavigateToListingDetails from '../hooks/useNavigateToListingDetails.hook';
import { LeadModalPageStateEnum } from './modal/LeadModal';
import BlueDot, { BlueDotSizeEnum } from '../elements/BlueDot';
import READ_NOTIFICATIONS from '../graphql/mutations/readNotifications.mutation';
import { NotificationActions } from '../redux/notification.redux';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import Flex from '../elements/Flex';
import SET_LEAD_FILE_ACCESS_LEVEL from '../graphql/mutations/setLeadFileAccessLevel.mutation';
import REJECT_LEAD_FILE_ACCESS_LEVEL from '../graphql/mutations/rejectLeadFileAccessLevel.mutation';
import { ListingActions } from '../redux/listing.redux';
import { IToastConfig, ToastTypesEnum } from './Toast';
import { ChatActions } from '../redux/chat.redux';
import GET_LEAD from '../graphql/queries/lead.query';
import Loader, { LoaderSizes } from '../elements/Loader';
import useUser from '../hooks/useUser.hook';
import { PrivateListingPermissionRequestModalProps } from './modal/PrivateListingPermissionRequestModal';
import UPDATE_LEAD from '../graphql/mutations/updateLead.mutation';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fill-available;
  padding-right: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Circle = styled.div`
  min-height: 32px;
  min-width: 32px;
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Blurple5};
`;

const TextContainer = styled.div`
  white-space: pre-wrap;
`;

const BlueDotContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export enum NotificationSizeEnum {
  Small = 'Small',
  Regular = 'Regular',
}

type NotificationContentProps = {
  notification: INotificationGraphQL;
  onClick?: Function;
  size?: NotificationSizeEnum;
};

const NotificationContent: React.FC<NotificationContentProps> = ({
  notification,
  onClick,
  size = NotificationSizeEnum.Regular,
}) => {
  /** Hooks */
  const navigateToListingDetails = useNavigateToListingDetails();
  const navigate = useNavigate();
  const { userId } = useUser();

  /** State */
  const isRegular = size === NotificationSizeEnum.Regular;
  const listingUnavailable = notification?.listingUnavailable;
  const [showApproveReject, setShowApproveReject] = React.useState(LeadUtil.level2Requested(notification.lead));
  const [channels, setChannels] = React.useState<IChannelGraphQL[]>([]);

  /* Actions */
  const dispatch = useAppDispatch();
  const pushLeadModal = (leadId: string, initialState: LeadModalPageStateEnum, isInvestorMatch?: boolean) => {
    dispatch(LeadActions.selectLead({ leadId }));
    dispatch(AppActions.pushModal({
      type: ModalTypesEnum.Lead,
      props: {
        initialState,
        isInvestorMatch: isInvestorMatch ?? false,
      },
    }));
  };
  const pushPrivateRequestAccessModal = (props: PrivateListingPermissionRequestModalProps) => {
    const { requestedUserId, listingId } = props;
    dispatch(AppActions.pushModal({
      type: ModalTypesEnum.PrivateListingPermissionRequest,
      props: {
        requestedUserId,
        listingId,
      },
    }));
  };
  const decrementUnreadNotificationsCount = () => {
    dispatch(NotificationActions.decrementUnreadNotificationsCount({}));
  };
  const { channelsOpenedUrls } = useAppSelector((state: AppState) => state.chat);
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushActiveChatChannel = (channel) => dispatch(
    ChatActions.pushActiveChatChannels({ channel }),
  );

  const pushChannelOpenedUrl = (channelUrl) => dispatch(
    ChatActions.pushChannelsOpenedUrls({ channelUrl }),
  );

  /** GraphQL */
  const [readNotifications] = useMutation(READ_NOTIFICATIONS);
  const [setLeadFileAccessLevel] = useMutation(SET_LEAD_FILE_ACCESS_LEVEL);
  const [rejectLeadFileAccessLevelChange] = useMutation(REJECT_LEAD_FILE_ACCESS_LEVEL);
  const [updateLead] = useMutation(UPDATE_LEAD);
  type Data = {
    lead: ILeadGraphQL;
  }
  type Vars = ILeadService.TGetLeadPayload;
  /** TODO: Chat needs to be rearchitected on the frontend so we don't have to do this here */
  const [getLead, { loading: leadLoading }] = useLazyQuery<Data, Vars>(GET_LEAD, {
    onCompleted: async (data) => {
      if (!channelsOpenedUrls.includes(data?.lead?.channel?.channelUrl)) {
        pushChannelOpenedUrl(data?.lead?.channel?.channelUrl);
      }
      if (data?.lead?.channel) {
        pushActiveChatChannel(data?.lead?.channel);
        setChannels([...channels, data?.lead?.channel]);
      }
    },
    onError: async () => {
      // Error probably isn't related to messages but for now, with doing this
      // this way, it is more clear to the user to say that.
      // const { message } = ApolloUtil.parseApolloClientError(error);
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error loading your messages.',
      });
    },
  });

  /* Effects */
  React.useEffect(() => {
    setShowApproveReject(LeadUtil.level2Requested(notification.lead));
  }, [notification.lead]);

  /** Render */
  const { text, icon, action } = (() => {
    switch (notification.type) {
      case NotificationTypeEnum.NewMessage:
      case NotificationTypeEnum.SignedCA:
        return {
          icon: Icons.SignatureLight,
          text: (
            <TextContainer>
              <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  {notification.lead?.user?.firstName}
                  {' '}
                  {notification.lead?.user?.lastName}
                </Text>
                {' '}
                has signed a CA for
                {' '}
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  {ListingUtil.name(notification.listing, { allButAddress1: true })}
                </Text>
              </Text>
            </TextContainer>
          ),
          action: () => pushLeadModal(notification.leadId, LeadModalPageStateEnum.Details),
        };
      case NotificationTypeEnum.ListingPublished:
        return {
          icon: Icons.BuildingLight,
          text: (
            <TextContainer>
              <Text type={TextTypesEnum.Regular14Small} data-cy="listing-notification-row">
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  {ListingUtil.name(notification.listing, { allButAddress1: true })}
                </Text>
                {' '}
                has been published
              </Text>
            </TextContainer>
          ),
          action: () => navigateToListingDetails(notification.listingId),
        };
      case NotificationTypeEnum.NewOffer:
        return {
          icon: Icons.EnvelopeOpenDollarLight,
          text: (
            <TextContainer>
              <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                You have a new offer from
                {' '}
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  {notification.lead?.user?.firstName}
                  {' '}
                  {notification.lead?.user?.lastName}
                </Text>
              </Text>
            </TextContainer>
          ),
          action: () => pushLeadModal(notification.leadId, LeadModalPageStateEnum.Offers),
        };
      case NotificationTypeEnum.RevisedOffer:
        return {
          icon: Icons.RedoLight,
          text: (
            <TextContainer>
              <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                {notification.lead?.user?.firstName}
                {' '}
                {notification.lead?.user?.lastName}
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  {' '}
                  has revised their offer on
                  {' '}
                  {ListingUtil.name(notification.listing, { allButAddress1: true })}
                </Text>
              </Text>
            </TextContainer>
          ),
          action: () => pushLeadModal(notification.leadId, LeadModalPageStateEnum.Offers),
        };
      case NotificationTypeEnum.ListingStatusUpdated:
        return {
          icon: Icons.BuildingLight,
          text: (
            <TextContainer>
              {listingUnavailable ? (
                <Text type={TextTypesEnum.Regular14Small}>
                  <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                    {ListingUtil.name(notification.listing, { allButAddress1: true })}
                  </Text>
                  {' '}
                  is no longer available.
                </Text>
              ) : (
                <Text type={TextTypesEnum.Regular14Small}>
                  The status for
                  <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                    {' '}
                    {ListingUtil.name(notification.listing, { allButAddress1: true })}
                    {' '}
                  </Text>
                  has changed.
                </Text>
              )}
            </TextContainer>
          ),
          action: () => navigateToListingDetails(notification.listingId),
        };
      case NotificationTypeEnum.ListingUploadReadyForReview:
        return {
          icon: Icons.BuildingLight,
          text: (
            <TextContainer>
              <Text type={TextTypesEnum.Regular14Small}>
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  {ListingUtil.name(notification.listing, { allButAddress1: true })}
                </Text>
                {' '}
                is ready for you to review.
              </Text>
            </TextContainer>
          ),
          action: () => {
            dispatch(ListingActions.selectListing({ listingId: notification.listingId }));
            navigate(`/app/create-listing?listingId=${notification?.listingId}&preview=true`);
          },
        };
      case NotificationTypeEnum.ListingUploadAdminAssigned:
        return {
          icon: Icons.BuildingLight,
          text: (
            <TextContainer>
              <Text type={TextTypesEnum.Regular14Small}>
                You have been assigned a new listing.
                {' '}
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  {ListingUtil.name(notification.listing, { allButAddress1: true })}
                </Text>
                {' '}
                is ready for you to create.
              </Text>
            </TextContainer>
          ),
          action: () => {
            navigate('/app/admin');
          },
        };
      case NotificationTypeEnum.FileAccessLevelChangeRequested:
        const approveButtons = (
          <>
            <Button
              text="Approve"
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Small}
              onClick={() => {
                setLeadFileAccessLevel({
                  variables: {
                    params: {
                      leadId: notification.leadId,
                      accessLevel: FileAccessLevelsEnum.Level2,
                    },
                  },
                });
                setShowApproveReject(false);
              }}
            />
            <Button
              text="Reject"
              type={ButtonTypesEnum.Outline}
              size={ButtonSizesEnum.Small}
              margin="0 0 0 8px"
              onClick={() => {
                rejectLeadFileAccessLevelChange({
                  variables: {
                    params: {
                      leadId: notification.leadId,
                    },
                  },
                });
                setShowApproveReject(false);
              }}
            />
          </>
        );
        return {
          icon: Icons.LockAltRegular,
          text: (
            <Container>
              <TextContainer>
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  {notification.lead?.user?.firstName}
                  {' '}
                  {notification.lead?.user?.lastName}
                  {' '}
                  <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                    has requested access to
                    {' '}
                  </Text>
                  Level 2 Diligence Vault
                  {' '}
                  <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                    for
                    {' '}
                  </Text>
                  {ListingUtil.name(notification.listing, { allButAddress1: true })}

                </Text>
                {showApproveReject && !isRegular && (
                  <Flex margin="8px 0 0">
                    {approveButtons}
                  </Flex>
                )}
              </TextContainer>

              {showApproveReject && isRegular && (
                <Flex margin="0 0 0 16px">
                  {approveButtons}
                </Flex>
              )}

            </Container>
          ),
          action: () => pushLeadModal(notification.leadId, LeadModalPageStateEnum.Files),
        };
      case NotificationTypeEnum.PrivateListingAccessRequest:
        const approvePrivateListingAccessButtons = (
          <>
            <Button
              text="Approve"
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Small}
              onClick={() => {
                updateLead({
                  variables: {
                    params: {
                      _id: notification?.lead?._id,
                      userId: notification?.lead?.user?._id,
                      privateListingAccess: {
                        accessRequested: true,
                        accessDenied: false,
                        accessApproved: true,
                      },
                      updateType: UpdateLeadTypeEnum.ApprovePrivateListingAccess,
                    },
                  },
                });
                setShowApproveReject(false);
              }}
            />
            <Button
              text="Reject"
              type={ButtonTypesEnum.Outline}
              size={ButtonSizesEnum.Small}
              margin="0 0 0 8px"
              onClick={() => {
                updateLead({
                  variables: {
                    params: {
                      _id: notification?.lead?._id,
                      userId: notification?.lead?.user?._id,
                      privateListingAccess: {
                        accessRequested: true,
                        accessDenied: true,
                        accessApproved: false,
                      },
                      updateType: UpdateLeadTypeEnum.DenyPrivateListingAccess,
                    },
                  },
                });
              }}
            />
          </>
        );
        return {
          icon: Icons.LockAltRegular,
          text: (
            <Container>
              <TextContainer>
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  {notification.lead?.user?.firstName}
                  {' '}
                  {notification.lead?.user?.lastName}
                  {' '}
                  <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                    has requested access to
                    {' '}
                  </Text>
                  your private listing
                  {' '}
                  <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                    for
                    {' '}
                  </Text>
                  {ListingUtil.name(notification.listing, { allButAddress1: true })}

                </Text>
                {showApproveReject && !isRegular && (
                  <Flex margin="8px 0 0">
                    {approvePrivateListingAccessButtons}
                  </Flex>
                )}
              </TextContainer>

              {showApproveReject && isRegular && (
                <Flex margin="0 0 0 16px">
                  {approvePrivateListingAccessButtons}
                </Flex>
              )}

            </Container>
          ),
          action: () => pushPrivateRequestAccessModal({
            requestedUserId: notification?.lead?.user?._id,
            listingId: notification?.listingId,
          }),
        };
      case NotificationTypeEnum.PrivateListingAccessDenied:
        return {
          icon: Icons.ThumbsDownLight,
          text: (
            <TextContainer>
              <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                  Access to the
                  {' '}
                </Text>
                private listing
                {' '}
                <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                  for
                  {' '}
                </Text>
                {ListingUtil.name(notification.listing, { allButAddress1: true })}
                {' '}
                <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                  has been
                  {' '}
                </Text>
                denied
              </Text>
            </TextContainer>
          ),
          action: () => {},
        };
      case NotificationTypeEnum.PrivateListingAccessApproved:
        return {
          icon: Icons.ThumbsUpLight,
          text: (
            <TextContainer>
              <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                  Access to the
                  {' '}
                </Text>
                private listing
                {' '}
                <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                  for
                  {' '}
                </Text>
                {ListingUtil.name(notification.listing, { allButAddress1: true })}
                {' '}
                <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                  has been
                  {' '}
                </Text>
                approved!
              </Text>
            </TextContainer>
          ),
          action: () => {},
        };
      case NotificationTypeEnum.FileAccessLevelChanged:
        return {
          icon: Icons.ThumbsUpLight,
          text: (
            <TextContainer>
              <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                  Access to the
                  {' '}
                </Text>
                Level 2 Diligence Vault
                {' '}
                <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                  for
                  {' '}
                </Text>
                {ListingUtil.name(notification.listing, { allButAddress1: true })}
                {' '}
                <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                  has been
                  {' '}
                </Text>
                approved
              </Text>
            </TextContainer>
          ),
          // action: () => pushLeadModal(notification.leadId, LeadModalPageStateEnum.Files),
          action: () => {},
        };
      case NotificationTypeEnum.FileAccessRequestDenied:
        return {
          icon: Icons.ThumbsDownLight,
          text: (
            <TextContainer>
              <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                  Access to the
                  {' '}
                </Text>
                Level 2 Diligence Vault
                {' '}
                <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                  for
                  {' '}
                </Text>
                {ListingUtil.name(notification.listing, { allButAddress1: true })}
                {' '}
                <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                  has been
                  {' '}
                </Text>
                rejected
              </Text>
            </TextContainer>
          ),
          // action: () => pushLeadModal(notification.leadId, LeadModalPageStateEnum.Files),
          action: () => {},
        };
      case NotificationTypeEnum.NewLead:
        return {
          icon: Icons.SignatureLight,
          text: (
            <TextContainer>
              <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  You have a new lead for
                  {' '}
                </Text>
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  {ListingUtil.name(notification.listing, { allButAddress1: true })}
                </Text>
              </Text>
            </TextContainer>
          ),
          action: () => {
            pushLeadModal(notification.leadId, LeadModalPageStateEnum.Details);
          },
        };
        // TODO: revamp this notification to be an investor match summary that links to investor match table
      case NotificationTypeEnum.InvestorMatchSummary:
        return {
          icon: Icons.LinkRegular,
          text: (
            <TextContainer>
              <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  Here are your investor matches for
                  {' '}
                </Text>
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  {ListingUtil.name(notification.listing, { allButAddress1: true })}
                </Text>
              </Text>
            </TextContainer>
          ),
          action: () => {
            navigateToListingDetails(notification.listing._id, '/investor-matches');
          },
        };
      case NotificationTypeEnum.NewListingMatchingInvestmentProfile:
        return {
          icon: Icons.LinkRegular,
          text: (
            <TextContainer>
              <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  A new listing matching your investment criteria is available:
                  {' '}
                </Text>
                <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                  {ListingUtil.name(notification.listing, { allButAddress1: true })}
                </Text>
              </Text>
            </TextContainer>
          ),
          action: () => {
            navigate(ListingUtil.slug(notification.listing));
          },
        };
      default:
        return {
          icon: Icons.CommentAltLinesLight,
          text: (
            <>
              {leadLoading ? (
                <LoaderContainer>
                  <Loader size={LoaderSizes.Small} color={Colors.Brand700 || Colors.Blurple700} />
                </LoaderContainer>
              ) : (
                <TextContainer>
                  {userId === notification?.listing?.meta?.createdBy ? (
                    <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                      <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                        {notification.lead?.user?.firstName || notification?.user?.firstName}
                        {' '}
                        {notification.lead?.user?.lastName || notification?.user?.lastName || 'Someone'}
                      </Text>
                      {' '}
                      has sent you a message regarding
                      {' '}
                      <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                        {ListingUtil.name(notification?.listing, { allButAddress1: true })}
                      </Text>
                    </Text>
                  ) : (
                    <Text type={TextTypesEnum.Regular14Small} as={TextAsEnum.Span}>
                      You have received a message regarding
                      {' '}
                      <Text type={TextTypesEnum.Medium14Small} as={TextAsEnum.Span}>
                        {ListingUtil.name(notification?.listing, { allButAddress1: true })}
                      </Text>
                    </Text>
                  )}
                </TextContainer>
              )}
            </>
          ),
          action: () => {
            const channel = channels.find((channel) => channel?.leadId === notification?.leadId);
            if (channel?.channelUrl) {
              pushActiveChatChannel(channel);
            } else {
              getLead({
                variables: {
                  params: userId === notification?.listing?.meta?.createdBy
                    ? { leadId: notification.leadId }
                    : { listingId: notification?.listingId },
                },
              });
            }
          },
        };
    }
  })();

  return (
    <Container onClick={() => {
      onClick?.();
      action();

      /**
       * Mark the notication as read on
       * the backend and decrement the
       * unread count on the frontend if needed
       */
      if (notification.state === NotificationStateEnum.Unread) {
        decrementUnreadNotificationsCount();
        readNotifications({
          variables: {
            params: {
              notificationIds: [notification._id],
            },
          },
        });
      }
    }}
    >
      <Circle>
        <Icon
          icon={icon}
          color={Colors.Brand700 || Colors.Blurple700}
          size={14}
        />
      </Circle>
      {(notification.state === NotificationStateEnum.Unread) && (
        <BlueDotContainer>
          <BlueDot
            size={isRegular ? BlueDotSizeEnum.Eight : BlueDotSizeEnum.Six}
          />
        </BlueDotContainer>
      )}
      {text}
    </Container>
  );
};

export default NotificationContent;
