import React from 'react';
import styled from '@emotion/styled';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import UserUtil from '@biproxi/models/utils/UserUtil';
import Divider from '../elements/Divider';
import Text, { TextTypesEnum } from '../elements/Text';
import Colors from '../styles/Colors';
import UserProfileAndContact from './UserProfileAndContact';
import NoContent from './NoContent';
import Icons from '../elements/Icons';

const HTMLText = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "aktiv-grotesk";
  line-height: 28px;

  > p {
    padding: 8px 0 0;
  }
`;

const ColumnItem = styled.div`
  margin-top: 16px;
`;

const ColumnContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;

`;

export type InvestorInfoProps = {
  user: IUserGraphQL;
  preventLinkClicks?: boolean;
  isInvestorMatch?: boolean;
};

const InvestorInfo: React.FC<InvestorInfoProps> = ({
  user,
  preventLinkClicks = false,
  isInvestorMatch = false,
}) => {
  /** Render */
  const i = user?.investorProfile;
  const investorType = i?.investorType;
  const assets = i?.assetsOwned;
  const hasPrefInvest = i?.preferredInvestmentTypes?.length > 0;
  const prefInvest = i?.preferredInvestmentTypes?.join(', ');
  const hasGeoFocus = i?.geographicalFocus?.length > 0;
  const geoFocus = i?.geographicalFocus?.join(', ');
  const hasTargetPrice = i?.targetPriceRanges?.length > 0;
  const targetPrices = i?.targetPriceRanges?.join(', ');
  const hasTargetAssets = i?.targetAssetClasses?.length > 0;
  const targetAssets = i?.targetAssetClasses?.join(', ');
  const bio = user?.bio;

  const hasInvestorProfile = assets || prefInvest || hasGeoFocus || targetPrices || hasTargetAssets;
  const hasFirstColumn = investorType || assets || prefInvest;
  return (
    <>
      <UserProfileAndContact user={user} preventLinkClicks={preventLinkClicks} isInvestorMatch={isInvestorMatch} />
      {UserUtil.isInvestor(user) && <Divider color={Colors.Grey200} margin="16px 0px" />}
      {hasInvestorProfile ? (
        <>
          <Text type={TextTypesEnum.Bold18} color={Colors.Grey900}>
            Investor Info
          </Text>
          <ColumnContainer>
            {hasFirstColumn && (
              <div>
                {investorType && (
                  <ColumnItem>
                    <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                      Investor type
                    </Text>
                    <Text type={TextTypesEnum.Bold14Small} color={Colors.Grey900}>
                      {investorType}
                    </Text>
                  </ColumnItem>
                )}
                {assets && (
                  <ColumnItem>
                    <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                      Owned assets
                    </Text>
                    <Text type={TextTypesEnum.Bold14Small} color={Colors.Grey900}>
                      {assets}
                    </Text>
                  </ColumnItem>
                )}
                {hasPrefInvest && (
                  <ColumnItem>
                    <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                      Investment opportunities
                    </Text>
                    <Text type={TextTypesEnum.Bold14Small} color={Colors.Grey900}>
                      {prefInvest}
                    </Text>
                  </ColumnItem>
                )}
              </div>
            )}
            <div>
              {hasTargetPrice && (
                <ColumnItem>
                  <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                    Target price range
                  </Text>
                  <Text type={TextTypesEnum.Bold14Small} color={Colors.Grey900}>
                    {targetPrices}
                  </Text>
                </ColumnItem>
              )}
              {hasGeoFocus && (
                <ColumnItem>
                  <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                    Geographic areas of interest
                  </Text>
                  <Text type={TextTypesEnum.Bold14Small} color={Colors.Grey900}>
                    {geoFocus?.length >= 50 ? 'National' : geoFocus}
                  </Text>
                </ColumnItem>
              )}
              {hasTargetAssets && (
                <ColumnItem>
                  <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                    Asset class interest
                  </Text>
                  <Text type={TextTypesEnum.Bold14Small} color={Colors.Grey900}>
                    {targetAssets}
                  </Text>
                </ColumnItem>
              )}
            </div>
          </ColumnContainer>
        </>
      ) : (
        <>
          {UserUtil.isInvestor(user) && (
            <NoContent
              height="176px"
              icon={Icons.UserLight}
              text="This user has not filled out an investor profile yet."
              padding="0 20px"
            />
          )}
        </>
      )}
      {bio && (
        <>
          <Divider color={Colors.Grey200} margin="16px 0px" />
          <Text type={TextTypesEnum.Bold18} color={Colors.Grey900}>
            About
            {' '}
            {UserUtil.fullName(user)}
          </Text>
          <HTMLText dangerouslySetInnerHTML={{ __html: bio }} />
        </>
      )}
    </>
  );
};

export default InvestorInfo;
