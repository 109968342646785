import { useQuery } from '@apollo/client';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import { IError } from '@biproxi/models/interfaces/common';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import * as ILeadService from '@biproxi/models/services/ILeadService';
import { LeadActions, LeadSelectors } from '../redux/lead.redux';
import GET_LEAD from '../graphql/queries/lead.query';
import { useAppDispatch, useAppSelector } from '../redux/store';

type UseLead = {
  lead: ILeadGraphQL | null;
  leadId: string | null;
  loading: boolean;
  error: IError | null;
};

type UseLeadHook = (leadId?: string) => UseLead;

const useLead: UseLeadHook = (leadId) => {
  /* State */
  const selectedLeadId = useAppSelector(LeadSelectors.selectedLeadId);
  const leadCache = useAppSelector(LeadSelectors.leadCache);

  leadId = selectedLeadId ?? leadId;

  const lead = leadCache[leadId];

  /* Actions */
  const dispatch = useAppDispatch();
  const cacheLeads = (lead: ILeadGraphQL) => dispatch(LeadActions.cacheLeads({ leads: [lead] }));

  /* Hooks */
  type Data = {
    lead: ILeadGraphQL;
  }

  type Vars = ILeadService.TGetLeadPayload;

  const { loading, error } = useQuery<Data, Vars>(GET_LEAD, {
    skip: !leadId, // not for now
    variables: {
      params: {
        leadId,
      },
    },
    onCompleted: (data) => {
      if (data.lead && !lead) {
        cacheLeads(data.lead);
      }
    },
  });

  if (!leadId) {
    return {
      lead: null,
      leadId: null,
      loading: true,
      error: null,
    };
  }

  return {
    lead,
    leadId,
    loading: lead ? false : loading,
    error: error ? ApolloUtil.parseApolloClientError(error) : null,
  };
};

export default useLead;
