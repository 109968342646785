import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import UserUtil from '@biproxi/models/utils/UserUtil';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { CardPop } from '../../elements/Motion';
import Colors from '../Colors';
import Icon, { Icons } from '../../elements/Icon';
import Text, { TextTypesEnum } from '../../elements/Text';
import BoxShadows from '../BoxShadows';
import { media } from '../../utils/MediaQuery';
import ProfileImage, { ProfileImageTypes } from '../../components/ProfileImage';
import Flex from '../../elements/Flex';
import ExitButton from '../../elements/ExitButton';
import IButtonOptions from '../../models/interfaces/IButtonOptions';
import PrivateListingSrpImage from '../../../public/private-listing-srp.png';

/** ******************************************************************************
 *  Exported Modal Styles - for use in modal implementations
 ****************************************************************************** */

type ModalContainerProps = {
  height?: string;
  width?: string;
  overflow?: string;
  margin?: string;
  mobileHeight?: string;
  minimalist?: boolean;
  'data-cy'?: string;
};

export const ModalContainer = styled(CardPop)<ModalContainerProps>`
  position: relative;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: 16px;
  background-color: ${Colors.White};
  overflow: ${(props) => props.overflow ?? 'visible'};
  box-shadow: ${BoxShadows.Standard};

  ${media.mobile} {
    width: 100vw;
    width: ${(props) => props.width};
    border-radius: 0px;
    height: ${(props) => props.mobileHeight || '100%'}; // lil hack cause i dont wanna test every single one
  }
`;

const ModalHeaderContainer = styled.div`
  position: relative;
  height: 80px;
  border-radius: 16px 16px 0px 0px;
  background-color: ${Colors.White};
  padding: 0px 24px;

  ${media.mobile} {
    height: 80px;
    padding: 0px 16px;
    border-radius: 0px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const BottomBorder = styled.div`
  width: 100%;
  height: 1px;
  background: ${Colors.Grey200};
`;

type ModalHeaderProps = {
  title: string;
  dataCy?: string;
  closeCy?: string;
  close?: Function;
  minimalist?: boolean;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  title, close, minimalist, dataCy, closeCy,
}) => {
  /** Render */
  if (minimalist) {
    return null;
  }

  return (
    <ModalHeaderContainer>
      <Row>
        <Text
          type={TextTypesEnum.Bold24}
          color={Colors.Black}
          data-cy={dataCy}
        >
          {title}
        </Text>
        {close && (
        <Flex
          height="30px"
          width="30px"
          align="center"
          justify="flex-end"
          onClick={close}
          dataCy={closeCy}
        >
          <Icon
            icon={Icons.TimesRegular}
            color={Colors.Grey300}
            hoverColor={Colors.Red500}
            size={24}
          />
        </Flex>
        )}
      </Row>
      <BottomBorder />
    </ModalHeaderContainer>
  );
};

type ModalContentProps = {
  height?: string;
  padding?: string;
  minimalist?: boolean;
};

export const ModalContent = styled.div<ModalContentProps>`
  position: relative;
  padding: ${(props) => (props.minimalist ? '0px' : '24px')};
  height: ${(props) => props.height};
  background-color: ${Colors.White};
  border-radius: 16px;

  ${media.mobile} {
    padding: 24px 16px;
  }
`;

export const ModalFooter = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 24px;
  background-color: ${Colors.White};
  border-radius: 0px 0px 16px 16px;
`;

const ModalHeaderListingImage = styled.div<{ src: string; height: string; }>`
  background-size: cover;
  background-position: center;
  background-origin: unset;
  background-image: url(${({ src }) => `${src}`});
  width: 100%;
  height: ${({ height }) => height};
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
  position: relative;

  ${media.mobile} {
    border-radius: 0px;
  }
`;

const ImageOverLay = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.Black50};
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const LeadNavContainer = styled.div`
  display: flex;
  height: 40px;
  overflow-x: auto;
  align-items: center;
  background: ${Colors.Black50};
  width: 100%;
  padding: 0px 16px;
  position: absolute;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
`;

const ModalNavItem = styled.div<{ active?: boolean }>`
  transition: all 0.2s;
  display: flex;
  height: 24px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin-right: 8px;
  border-radius: 4px;
  padding: 0px 8px;
  background: ${({ active }) => (active ? ` ${Colors.White}` : 'transparent')};
  color: ${({ active }) => (active ? `${Colors.Brand700 || Colors.Blurple700}` : `${Colors.White}`)};
  box-shadow: ${({ active }) => (active ? `${BoxShadows.Hard}` : null)};

  &:hover {
    background: ${({ active }) => (active ? ` ${Colors.White}` : `${Colors.White10}`)};
  }

  &:active {
    background: ${({ active }) => (active ? ` ${Colors.White}` : `${Colors.White20}`)};
  }
`;

const ListingNameContainer = styled.div`
  &, & > *, > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  width: 100%;
  margin: 2px 32px 0 0;
`;

const TextContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
`;

type ModalHeaderLeadProps = {
  listing: IListingGraphQL;
  listingOnly?: boolean
  user?: IUserGraphQL;
  close: () => {};
  minimize?: () => void;
  navItems?: IButtonOptions[];
  privateListingProps?: any;
};

export const ModalHeaderLead: React.FC<ModalHeaderLeadProps> = ({
  listing,
  listingOnly,
  user,
  close,
  minimize,
  navItems,
  privateListingProps,
}) => (
  <ModalHeaderListingImage
    src={privateListingProps?.isPrivateListing && !privateListingProps?.isPrivateListingAccess ? PrivateListingSrpImage.src : listing?.media?.files?.[0]?.url}
    height={listingOnly ? '96px' : navItems?.length > 0 ? '124px' : '84px'}
  >
    <ImageOverLay>
      <TextContainer>
        <Flex justify="space-between" align="center" width="100%">
          {listingOnly ? (
            <ListingNameContainer>
              <Text
                type={TextTypesEnum.Bold18}
                color={Colors.White}
                skWidth={200}
              >
                {ListingUtil.name(listing, { allButAddress1: true })}
              </Text>
            </ListingNameContainer>
          ) : (
            <Flex align="center">
              <ProfileImage type={ProfileImageTypes.Small} user={user} />
              <Text
                type={TextTypesEnum.Bold18}
                color={Colors.White}
                skWidth={200}
              >
                {UserUtil.fullName(user)}
              </Text>
            </Flex>
          )}
          {minimize && (
            <ExitButton
              onClick={minimize}
              icon={Icons.MinusRegular}
              top="0"
              right="40px"
              dataCy="modal-exit-button"
            />
          )}
          {close && (
            <ExitButton
              onClick={close}
              top="0"
              right="0"
              dataCy="modal-exit-button"
            />
          )}
        </Flex>
        {!listingOnly && (
          <ListingNameContainer>
            <Text
              type={TextTypesEnum.Regular16}
              color={Colors.White}
              skWidth={180}
            >
              {ListingUtil.name(listing, { allButAddress1: true })}
            </Text>
          </ListingNameContainer>
        )}
      </TextContainer>
      {navItems?.length > 0 && (
        <LeadNavContainer>
          {navItems.map((navItem, index) => (
            <ModalNavItem
              key={index}
              onClick={() => navItem.onClick()}
              active={navItem.active as boolean}
            >
              <Text
                type={TextTypesEnum.Medium14}
                color="inherit"
                data-cy={`lead-modal-${navItem.text.toLowerCase()}-header-item`}
              >
                {navItem.text}
              </Text>
            </ModalNavItem>
          ))}
        </LeadNavContainer>
      )}
    </ImageOverLay>
  </ModalHeaderListingImage>
);
