import React from 'react';
import styled from '@emotion/styled';
import { IChannelGraphQL } from '@biproxi/models/interfaces/IChannel';
import {
  ModalContainer,
  ModalHeaderLead,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import ChatLayoutCustom from '../ChatLayoutCustom';
import { ChatActions } from '../../redux/chat.redux';
import useUser from '../../hooks/useUser.hook';
import useIsPrivateListing from '../../hooks/useIsPrivateListing.hook';

const Container = styled.div`
  width: 344px;
  margin-top: -24px;

  ${media.mobile} {
    width: 100%;
    height: calc(100vh - 110px);
  }
`;

const Content = styled.div`
  width: 100%;
  height: 440px;

  ${media.mobile} {
    height: 100%;
  }
`;

export type ChatModalProps = {
  channel?: IChannelGraphQL;
};

const ChatModal: React.FC<ChatModalProps> = ({
  channel,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();
  const popActiveChatModal = (channel) => dispatch(
    ChatActions.popActiveChatChannels({ channelUrl: channel.channelUrl }),
  );

  const pushMinimizedChatChannel = (channel) => dispatch(
    ChatActions.pushMinimizedChatChannels({ channel }),
  );

  /** Hooks */
  const isMobile = useMobileMedia();
  const { user } = useUser();
  const { isPrivateListing, isPrivateListingAccess } = useIsPrivateListing({
    params: {
      listing: channel.listing,
      user,
    },
  });

  /* Render */
  return (
    <ModalContainer>
      <ModalHeaderLead
        user={channel.user}
        listing={channel.listing}
        privateListingProps={{
          isPrivateListing,
          isPrivateListingAccess,
        }}
        minimize={isMobile ? null : () => {
          pushMinimizedChatChannel(channel);
        }}
        close={() => popActiveChatModal(channel) as any}
      />
      <ModalContent>
        <Container>
          <Content>
            <ChatLayoutCustom channel={channel} />
          </Content>
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default ChatModal;
