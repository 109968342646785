import { gql } from '@apollo/client';

const MESSAGE_ALL_PRIVATE_EVENT_PARTICIPANTS = gql`
  mutation MessageAllPrivateEventParticipants($params: MessageAllPrivateEventParticipantsParams!) {
    messageAllPrivateEventParticipants(params: $params) {
      _id
    }
  }
`;

export default MESSAGE_ALL_PRIVATE_EVENT_PARTICIPANTS;
