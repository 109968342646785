import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import { media } from '../../utils/MediaQuery';
import InvestorInfo from '../InvestorInfo';

const Container = styled.div`
  width: 100%;
  height: 65vh;
  overflow: auto;
  padding-right: 24px;

  ${media.mobile} {
    height: calc(100vh - 135px);
  }
`;

export type LeadModalDetailsProps = {
  lead: ILeadGraphQL;
};

const LeadModalDetails: React.FC<LeadModalDetailsProps> = ({
  lead,
}) => (
  <Container data-cy="leads-details-container">
    <InvestorInfo user={lead?.user} isInvestorMatch={lead?.isInvestorMatch} />
  </Container>
);

export default LeadModalDetails;
