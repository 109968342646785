export function nextApiRequests(): string {
  return 'next-api-requests';
}

export * from './queries/neo4j.queries';
export * from './queries/email.queries';
export * from './queries/billing.queries';
export * from './queries/bobber.queries';
export * from './mutations/neo4j.mutations';
export * from './mutations/email.mutations';
export * from './mutations/billing.mutations';
export * from './mutations/bobber.mutations';
