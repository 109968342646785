/*
 * Do Not Manually Modify
 * Generated with env.types.generator.ts
 */

export interface BiproxiRuntimeConfig {
  AURA_ENDPOINT: string | undefined,
  AURA_INSTANCENAME: string | undefined,
  AURA_PASSWORD: string | undefined,
  AURA_USERNAME: string | undefined,
  BIPROXI_FONTAWESOME_NPM_AUTH_TOKEN: string | undefined,
  BUILDOUT_API_KEY: string | undefined,
  CHARGEBEE_KEY: string | undefined,
  CHARGEBEE_WEBHOOK_AUTH: string | undefined,
  CHARGEBEE_WEBHOOK_AUTH_BASE64: string | undefined,
  CHERRE_API_KEY: string | undefined,
  CUBEJS_API_SECRET: string | undefined,
  CUBEJS_CACHE_AND_QUEUE_DRIVER: string | undefined,
  CUBEJS_DB_HOST: string | undefined,
  CUBEJS_DB_NAME: string | undefined,
  CUBEJS_DB_PASS: string | undefined,
  CUBEJS_DB_PORT: string | undefined,
  CUBEJS_DB_SSL: boolean | undefined,
  CUBEJS_DB_TYPE: string | undefined,
  CUBEJS_DB_USER: string | undefined,
  CUBEJS_DEV_MODE: boolean | undefined,
  CUBEJS_GCP_BUCKET_NAME: string | undefined,
  CUBEJS_LOCAL_SERVER: boolean | undefined,
  CUBEJS_LOG_LEVEL: string | undefined,
  CUBEJS_REDIS_PASSWORD: string | undefined,
  CUBEJS_REDIS_URL: string | undefined,
  CUBE_CLOUD_AUTH_TOKEN: string | undefined,
  CUBE_CLOUD_URI: string | undefined,
  DEBUG_ENABLED: string | undefined,
  ENABLE_FEATURE_GEOGRAPHIES: boolean | undefined,
  FIREBASE_API_KEY: string | undefined,
  GCP_BUCKET_NAME: string | undefined,
  GOOGLE_APPLICATION_CREDENTIALS: string | undefined,
  GOOGLE_APPLICATION_CREDENTIALS_BASE64: string | undefined,
  GOOGLE_OAUTH_CLIENT_ID: string | undefined,
  GOOGLE_OAUTH_CLIENT_SECRET: string | undefined,
  HUBSPOT_API_KEY: string | undefined,
  JWT_SECRET: string | undefined,
  LINKEDIN_OAUTH_CLIENT_ID: string | undefined,
  LINKEDIN_OAUTH_CLIENT_SECRET: string | undefined,
  LOGGER_JSON: boolean | undefined,
  LOGGER_LEVEL: string | undefined,
  LOGGER_PRETTY: boolean | undefined,
  LOGTAIL_AUTH_TOKEN: string | undefined,
  MAPBOX_SECRET_API_KEY: string | undefined,
  MAXMIND_ACCOUNT: string | undefined,
  MAXMIND_KEY: string | undefined,
  MONGO_CONNECTION_STRING: string | undefined,
  MONGO_DATABASE_NAME: string | undefined,
  MONGO_INLET_DATABASE_NAME: string | undefined,
  MONGO_PASSWORD: string | undefined,
  MONGO_USERNAME: string | undefined,
  NEXT_PUBLIC_API_URL: string | undefined,
  NEXT_PUBLIC_CHARGEBEE_SITE: string | undefined,
  NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN: string | undefined,
  NEXT_PUBLIC_CONTENTFUL_ENV: string | undefined,
  NEXT_PUBLIC_CONTENTFUL_URI: string | undefined,
  NEXT_PUBLIC_ENVIRONMENT_NAME: string | undefined,
  NEXT_PUBLIC_FIREBASE_RECAPTCHA_SITE_KEY: string | undefined,
  NEXT_PUBLIC_GA_TRACKING_ID: string | undefined,
  NEXT_PUBLIC_GOOGLE_BROWSER_KEY: string | undefined,
  NEXT_PUBLIC_HUBSPOT_ACCOUNT_ID: string | undefined,
  NEXT_PUBLIC_LOGGER_JSON: boolean | undefined,
  NEXT_PUBLIC_LOGGER_LEVEL: string | undefined,
  NEXT_PUBLIC_LOGGER_PRETTY: boolean | undefined,
  NEXT_PUBLIC_LOGTAIL_AUTH_TOKEN: string | undefined,
  NEXT_PUBLIC_NYLAS_CLIENT_ID: string | undefined,
  NEXT_PUBLIC_PUSHER_CLUSTER: string | undefined,
  NEXT_PUBLIC_PUSHER_KEY: string | undefined,
  NEXT_PUBLIC_SANITY_STUDIO_API_DATASET: string | undefined,
  NEXT_PUBLIC_SANITY_STUDIO_API_PROJECT_ID: string | undefined,
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: string | undefined,
  NEXT_PUBLIC_SEGMENT_WRITE_KEY_BASE64: string | undefined,
  NEXT_PUBLIC_SENDBIRD_APP_ID: string | undefined,
  NEXT_PUBLIC_SUPABASE_ANON_KEY: string | undefined,
  NEXT_PUBLIC_SUPABASE_URL: string | undefined,
  NEXT_PUBLIC_TCOM_GTM_TRACKING_ID: string | undefined,
  NEXT_PUBLIC_WEBSOCKET_URL: string | undefined,
  WEB_UI_URL: string | undefined,
  NODE_ENV: string | undefined,
  NYLAS_CLIENT_SECRET: string | undefined,
  NYLAS_DEV_TOKEN: string | undefined,
  PUSHER_APP_ID: string | undefined,
  PUSHER_SECRET: string | undefined,
  RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED: boolean | undefined,
  REDIS_API_KEY: string | undefined,
  REDIS_PORT: string | undefined,
  REDIS_URL: string | undefined,
  REQUEST_QUEUE_URL: string | undefined,
  RESO_API_TOKEN: string | undefined,
  SEGMENT_WEBHOOK_SECRET: string | undefined,
  SENDBIRD_API_TOKEN: string | undefined,
  SENDGRID_API_KEY: string | undefined,
  SENTRY_AUTH_TOKEN: string | undefined,
  SENTRY_DSN: string | undefined,
  SENTRY_ENVIRONMENT: string | undefined,
  SENTRY_ORG: string | undefined,
  SENTRY_PROJECT: string | undefined,
  SENTRY_URL: string | undefined,
  SNOWFLAKE_ACCOUNT: string | undefined,
  SNOWFLAKE_PASSWORD: string | undefined,
  SNOWFLAKE_USERNAME: string | undefined,
  WEBFLOW_API_TOKEN: string | undefined,
  ZEPLO_API_TOKEN: string | undefined,
  WARDEN_WEB_UI_URL: string | undefined,
}

export const getRuntimeConfig = (cfg: any): BiproxiRuntimeConfig => ({
  AURA_ENDPOINT: cfg.AURA_ENDPOINT,
  AURA_INSTANCENAME: cfg.AURA_INSTANCENAME,
  AURA_PASSWORD: cfg.AURA_PASSWORD,
  AURA_USERNAME: cfg.AURA_USERNAME,
  BIPROXI_FONTAWESOME_NPM_AUTH_TOKEN: cfg.BIPROXI_FONTAWESOME_NPM_AUTH_TOKEN,
  BUILDOUT_API_KEY: cfg.BUILDOUT_API_KEY,
  CHARGEBEE_KEY: cfg.CHARGEBEE_KEY,
  CHARGEBEE_WEBHOOK_AUTH: cfg.CHARGEBEE_WEBHOOK_AUTH,
  CHARGEBEE_WEBHOOK_AUTH_BASE64: cfg.CHARGEBEE_WEBHOOK_AUTH_BASE64,
  CHERRE_API_KEY: cfg.CHERRE_API_KEY,
  CUBEJS_API_SECRET: cfg.CUBEJS_API_SECRET,
  CUBEJS_CACHE_AND_QUEUE_DRIVER: cfg.CUBEJS_CACHE_AND_QUEUE_DRIVER,
  CUBEJS_DB_HOST: cfg.CUBEJS_DB_HOST,
  CUBEJS_DB_NAME: cfg.CUBEJS_DB_NAME,
  CUBEJS_DB_PASS: cfg.CUBEJS_DB_PASS,
  CUBEJS_DB_PORT: cfg.CUBEJS_DB_PORT,
  CUBEJS_DB_SSL: cfg.CUBEJS_DB_SSL ? process.env.CUBEJS_DB_SSL === 'true' : undefined,
  CUBEJS_DB_TYPE: cfg.CUBEJS_DB_TYPE,
  CUBEJS_DB_USER: cfg.CUBEJS_DB_USER,
  CUBEJS_DEV_MODE: cfg.CUBEJS_DEV_MODE ? process.env.CUBEJS_DEV_MODE === 'true' : undefined,
  CUBEJS_GCP_BUCKET_NAME: cfg.CUBEJS_GCP_BUCKET_NAME,
  CUBEJS_LOCAL_SERVER: cfg.CUBEJS_LOCAL_SERVER ? process.env.CUBEJS_LOCAL_SERVER === 'true' : undefined,
  CUBEJS_LOG_LEVEL: cfg.CUBEJS_LOG_LEVEL,
  CUBEJS_REDIS_PASSWORD: cfg.CUBEJS_REDIS_PASSWORD,
  CUBEJS_REDIS_URL: cfg.CUBEJS_REDIS_URL,
  CUBE_CLOUD_AUTH_TOKEN: cfg.CUBE_CLOUD_AUTH_TOKEN,
  CUBE_CLOUD_URI: cfg.CUBE_CLOUD_URI,
  DEBUG_ENABLED: cfg.DEBUG_ENABLED,
  ENABLE_FEATURE_GEOGRAPHIES: cfg.ENABLE_FEATURE_GEOGRAPHIES ? process.env.ENABLE_FEATURE_GEOGRAPHIES === 'true' : undefined,
  FIREBASE_API_KEY: cfg.FIREBASE_API_KEY,
  GCP_BUCKET_NAME: cfg.GCP_BUCKET_NAME,
  GOOGLE_APPLICATION_CREDENTIALS: cfg.GOOGLE_APPLICATION_CREDENTIALS,
  GOOGLE_APPLICATION_CREDENTIALS_BASE64: cfg.GOOGLE_APPLICATION_CREDENTIALS_BASE64,
  GOOGLE_OAUTH_CLIENT_ID: cfg.GOOGLE_OAUTH_CLIENT_ID,
  GOOGLE_OAUTH_CLIENT_SECRET: cfg.GOOGLE_OAUTH_CLIENT_SECRET,
  HUBSPOT_API_KEY: cfg.HUBSPOT_API_KEY,
  JWT_SECRET: cfg.JWT_SECRET,
  LINKEDIN_OAUTH_CLIENT_ID: cfg.LINKEDIN_OAUTH_CLIENT_ID,
  LINKEDIN_OAUTH_CLIENT_SECRET: cfg.LINKEDIN_OAUTH_CLIENT_SECRET,
  LOGGER_JSON: cfg.LOGGER_JSON ? process.env.LOGGER_JSON === 'true' : undefined,
  LOGGER_LEVEL: cfg.LOGGER_LEVEL,
  LOGGER_PRETTY: cfg.LOGGER_PRETTY ? process.env.LOGGER_PRETTY === 'true' : undefined,
  LOGTAIL_AUTH_TOKEN: cfg.LOGTAIL_AUTH_TOKEN,
  MAPBOX_SECRET_API_KEY: cfg.MAPBOX_SECRET_API_KEY,
  MAXMIND_ACCOUNT: cfg.MAXMIND_ACCOUNT,
  MAXMIND_KEY: cfg.MAXMIND_KEY,
  MONGO_CONNECTION_STRING: cfg.MONGO_CONNECTION_STRING,
  MONGO_DATABASE_NAME: cfg.MONGO_DATABASE_NAME,
  MONGO_INLET_DATABASE_NAME: cfg.MONGO_INLET_DATABASE_NAME,
  MONGO_PASSWORD: cfg.MONGO_PASSWORD,
  MONGO_USERNAME: cfg.MONGO_USERNAME,
  NEXT_PUBLIC_API_URL: cfg.NEXT_PUBLIC_API_URL,
  NEXT_PUBLIC_CHARGEBEE_SITE: cfg.NEXT_PUBLIC_CHARGEBEE_SITE,
  NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN: cfg.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
  NEXT_PUBLIC_CONTENTFUL_ENV: cfg.NEXT_PUBLIC_CONTENTFUL_ENV,
  NEXT_PUBLIC_CONTENTFUL_URI: cfg.NEXT_PUBLIC_CONTENTFUL_URI,
  NEXT_PUBLIC_ENVIRONMENT_NAME: cfg.NEXT_PUBLIC_ENVIRONMENT_NAME,
  NEXT_PUBLIC_FIREBASE_RECAPTCHA_SITE_KEY: cfg.NEXT_PUBLIC_FIREBASE_RECAPTCHA_SITE_KEY,
  NEXT_PUBLIC_GA_TRACKING_ID: cfg.NEXT_PUBLIC_GA_TRACKING_ID,
  NEXT_PUBLIC_GOOGLE_BROWSER_KEY: cfg.NEXT_PUBLIC_GOOGLE_BROWSER_KEY,
  NEXT_PUBLIC_HUBSPOT_ACCOUNT_ID: cfg.NEXT_PUBLIC_HUBSPOT_ACCOUNT_ID,
  NEXT_PUBLIC_LOGGER_JSON: cfg.NEXT_PUBLIC_LOGGER_JSON ? process.env.NEXT_PUBLIC_LOGGER_JSON === 'true' : undefined,
  NEXT_PUBLIC_LOGGER_LEVEL: cfg.NEXT_PUBLIC_LOGGER_LEVEL,
  NEXT_PUBLIC_LOGGER_PRETTY: cfg.NEXT_PUBLIC_LOGGER_PRETTY ? process.env.NEXT_PUBLIC_LOGGER_PRETTY === 'true' : undefined,
  NEXT_PUBLIC_LOGTAIL_AUTH_TOKEN: cfg.NEXT_PUBLIC_LOGTAIL_AUTH_TOKEN,
  NEXT_PUBLIC_NYLAS_CLIENT_ID: cfg.NEXT_PUBLIC_NYLAS_CLIENT_ID,
  NEXT_PUBLIC_PUSHER_CLUSTER: cfg.NEXT_PUBLIC_PUSHER_CLUSTER,
  NEXT_PUBLIC_PUSHER_KEY: cfg.NEXT_PUBLIC_PUSHER_KEY,
  NEXT_PUBLIC_SANITY_STUDIO_API_DATASET: cfg.NEXT_PUBLIC_SANITY_STUDIO_API_DATASET,
  NEXT_PUBLIC_SANITY_STUDIO_API_PROJECT_ID: cfg.NEXT_PUBLIC_SANITY_STUDIO_API_PROJECT_ID,
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: cfg.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  NEXT_PUBLIC_SEGMENT_WRITE_KEY_BASE64: cfg.NEXT_PUBLIC_SEGMENT_WRITE_KEY_BASE64,
  NEXT_PUBLIC_SENDBIRD_APP_ID: cfg.NEXT_PUBLIC_SENDBIRD_APP_ID,
  NEXT_PUBLIC_SUPABASE_ANON_KEY: cfg.NEXT_PUBLIC_SUPABASE_ANON_KEY,
  NEXT_PUBLIC_SUPABASE_URL: cfg.NEXT_PUBLIC_SUPABASE_URL,
  NEXT_PUBLIC_TCOM_GTM_TRACKING_ID: cfg.NEXT_PUBLIC_TCOM_GTM_TRACKING_ID,
  NEXT_PUBLIC_WEBSOCKET_URL: cfg.NEXT_PUBLIC_WEBSOCKET_URL,
  WEB_UI_URL: cfg.WEB_UI_URL,
  NODE_ENV: cfg.NODE_ENV,
  NYLAS_CLIENT_SECRET: cfg.NYLAS_CLIENT_SECRET,
  NYLAS_DEV_TOKEN: cfg.NYLAS_DEV_TOKEN,
  PUSHER_APP_ID: cfg.PUSHER_APP_ID,
  PUSHER_SECRET: cfg.PUSHER_SECRET,
  RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED: cfg.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED ? process.env.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED === 'true' : undefined,
  REDIS_API_KEY: cfg.REDIS_API_KEY,
  REDIS_PORT: cfg.REDIS_PORT,
  REDIS_URL: cfg.REDIS_URL,
  REQUEST_QUEUE_URL: cfg.REQUEST_QUEUE_URL,
  RESO_API_TOKEN: cfg.RESO_API_TOKEN,
  SEGMENT_WEBHOOK_SECRET: cfg.SEGMENT_WEBHOOK_SECRET,
  SENDBIRD_API_TOKEN: cfg.SENDBIRD_API_TOKEN,
  SENDGRID_API_KEY: cfg.SENDGRID_API_KEY,
  SENTRY_AUTH_TOKEN: cfg.SENTRY_AUTH_TOKEN,
  SENTRY_DSN: cfg.SENTRY_DSN,
  SENTRY_ENVIRONMENT: cfg.SENTRY_ENVIRONMENT,
  SENTRY_ORG: cfg.SENTRY_ORG,
  SENTRY_PROJECT: cfg.SENTRY_PROJECT,
  SENTRY_URL: cfg.SENTRY_URL,
  SNOWFLAKE_ACCOUNT: cfg.SNOWFLAKE_ACCOUNT,
  SNOWFLAKE_PASSWORD: cfg.SNOWFLAKE_PASSWORD,
  SNOWFLAKE_USERNAME: cfg.SNOWFLAKE_USERNAME,
  WEBFLOW_API_TOKEN: cfg.WEBFLOW_API_TOKEN,
  ZEPLO_API_TOKEN: cfg.ZEPLO_API_TOKEN,
  WARDEN_WEB_UI_URL: cfg.WARDEN_WEB_UI_URL,
});
