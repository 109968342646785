/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import axios from 'axios';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import { logger } from '@biproxi/logger';
import StatusCodeEnum from '@biproxi/models/enums/StatusCodeEnum';
import IUsersToInvite from '@biproxi/models/interfaces/teams/IUsersToInvite';
import { TeamRolesEnum } from '@biproxi/models/enums/Neo4jRolesEnums';

export const createUserMutation = async (createUserParams: INeo4jServiceAPI.ICreateUserParams) => {
  const res = await axios.post('/api/neo4j/createUser', { params: createUserParams });
  const result = await res.data;
  return result;
};

export const connectListingsToOrganization = async (connectListingsToOrganizationParams: INeo4jServiceAPI.IConnectListingsToOrgParams) => {
  const res = await axios.post('/api/neo4j/connectListingsToOrg', { connectListingsToOrganizationParams });
  const result = await res.data;
  return result;
};

export const connectUserToOrganization = async (connectUserToOrgParams: object) => {
  let result;
  try {
    const res = await axios.post('/api/neo4j/connectUserToOrg', { params: connectUserToOrgParams });
    result = res?.data;
  } catch (e) {
    logger.error(`There was an error with the connectUserToOrg mutation: ${e}`);
  }
  return result;
};

export const createListingMutation = async (listingData: INeo4jServiceAPI.ICreateListingParams) => {
  const params = listingData;
  const res = await axios.post('/api/neo4j/createListing', { params });
  const result = await res.data;
  return result;
};

export const createOrganizationMutation = async (params: INeo4jServiceAPI.ICreateOrganizationParams) => {
  const result = {
    status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    data: null,
  };
  let organizationId: string = '';
  /**
   * Create the organization in Aura
   */
  try {
    const res = await axios.post('/api/neo4j/createOrganization', { params });
    const createOrgResponse = await res.data;
    organizationId = createOrgResponse?.data?.createOrganizations?.organizations?.[0]?._id;
    result.status = createOrgResponse?.status;
    result.data = createOrgResponse?.data?.createOrganizations?.[0]?.info;
  } catch (error) {
    logger.warn(`${error}`);
  }
  if (params?.users?.length && result?.status === StatusCodeEnum.OK) {
    try {
      const usersToInvite: IUsersToInvite[] = params?.users;
      usersToInvite?.forEach((userToInvite: IUsersToInvite) => {
        const userInfo = Object?.entries(userToInvite)?.flat(); // Takes { 'jesse@biproxi.com': 'Member' } -> ['jesse@biproxi.com', 'Member']
        const connectUserParams: INeo4jServiceAPI.IConnectUserToOrgParams = {
          organizationId,
          organizationName: params?.name,
          userEmail: userInfo?.[0],
          userRoles: [TeamRolesEnum.Pending, userInfo?.[1] as TeamRolesEnum],
        };
        connectUserToOrganization(connectUserParams); // don't await this because why would we care to in this scenario?
      });
    } catch (error) {
      result.status = StatusCodeEnum.INTERNAL_SERVER_ERROR;
      logger.warn(`${error}`);
    }
  }
  if (params?.listingIds?.length && result?.status === StatusCodeEnum.OK) {
    try {
      const listingsToConnect: string[] = params?.listingIds;
      const connectListingsParams: INeo4jServiceAPI.IConnectListingsToOrgParams = {
        organizationId,
        listingIds: listingsToConnect,
      };
      addListingsToOrganization(connectListingsParams);
    } catch (error) {
      result.status = StatusCodeEnum.INTERNAL_SERVER_ERROR;
      logger.warn(`${error}`);
    }
  }
  return result;
};

export const deleteOrganizationMutation = async (params: INeo4jServiceAPI.IDeleteOrganizationParams) => {
  const res = await axios.post('/api/neo4j/deleteOrganization', { params });
  const result = await res.data;
  return result;
};

export const deleteListingMutation = async (deleteListingParams: INeo4jServiceAPI.IDeleteListingParams) => {
  const res = await axios.post('/api/neo4j/deleteListing', { deleteListingParams });
  const result = await res.data;
  return result;
};

export const addOrgRoleToUserMutation = async (addOrgRoleToUserParams: INeo4jServiceAPI.IAddOrganizationRoleToUserParams) => {
  let result;
  try {
    const res = await axios.post('/api/neo4j/addOrgRoleToUser', { params: addOrgRoleToUserParams });
    result = res?.data;
  } catch (e) {
    logger.error(`There was an error with the addOrgRoleToUserMutation mutation: ${e}`);
  }

  return result;
};

export const updateUserOrganizationRole = async (updateUserOrganizationRoleParams: INeo4jServiceAPI.IUpdateUserOrganizationRoleParams) => {
  let result;
  try {
    const res = await axios.post('/api/neo4j/updateUserOrgRole', { params: updateUserOrganizationRoleParams });
    result = res?.data;
  } catch (e) {
    logger.error(`There was an error with the addOrgRoleToUserMutation mutation: ${e}`);
  }

  return result;
};

export const addListingsToOrganization = async (addListingsToOrganizationParams: INeo4jServiceAPI.IAddListingsToOrganizationParams) => {
  let result;
  try {
    const res = await axios.post('/api/neo4j/addListingsToOrganization', { params: addListingsToOrganizationParams });
    result = res?.data;
  } catch (e) {
    logger.error(`There was an error with the addListingsToOrganization mutation: ${e}`);
  }
  return result;
};

export const removeUserFromOrganization = async (params: INeo4jServiceAPI.IRemoveUserFromOrganizationParams) => {
  let result;
  try {
    const res = await axios.post('/api/neo4j/removeUserFromOrganization', { params });
    result = res?.data;
  } catch (e) {
    logger.error(`There was an error with the removeListingFromOrganization mutation: ${e}`);
  }
  return result;
};

export const removeListingFromOrganization = async (removeListingFromOrganizationParams: INeo4jServiceAPI.IRemoveListingFromOrganizationParams) => {
  let result;
  try {
    const res = await axios.post('/api/neo4j/removeListingFromOrganization', { params: removeListingFromOrganizationParams });
    result = res?.data;
  } catch (e) {
    logger.error(`There was an error with the removeListingFromOrganization mutation: ${e}`);
  }
  return result;
};
