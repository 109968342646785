enum ListingStateEnum {
  Draft = 'Draft',
  Scheduled = 'Scheduled',
  Active = 'Active',
  UnderContract = 'Under Contract',
  Closed = 'Closed',
  Inactive = 'Inactive',
  Expired = 'Expired',
  CallForOffers = 'Call For Offers', // front-end only
  Admin = 'Admin', // used when an admin creates a listing for a broker. Really, is only checked on 'CreateListing.page.tsx' to determine some stuff
  ReadyForReview = 'Ready For Review',
  Unlisted = 'Unlisted',
  Archived = 'Archived',
  Private = 'Private'
}

// If you add to this enum, make sure to update any functions that rely on checking values in this enum like 'isNotPublished' in ListingUtil.tsx
// Do a global search for 'ListingStatesEnum.' to see all the occurences.

export default ListingStateEnum;
