import PermissionTypesEnum from '../enums/PermissionTypesEnum';

export const SUPER_USERS = [
  'mike@biproxi.com',
  // 'tabitha@biproxi.com',
  'ember@biproxi.com',
  'gordon@biproxi.com',
  'alissa@biproxi.com',
  'jesse@biproxi.com',
  'aaron@biproxi.com',
  'graham@biproxi.com',
  'dan@biproxi.com',
  'kevinhein@biproxi.com',
  'joshua@biproxi.com',
  'daniel@biproxi.com',
  'kade@biproxi.com',
  'casey@biproxi.com',
];

export const ADMIN_USERS = [
  ...SUPER_USERS,
  // add new admins here
];

/**
 * Function used to check if a user has permission to access (super) admin features.
 * Pass the email associated with the user along with the permission you want to check against
 */
const hasPermission = (email: string, permission: PermissionTypesEnum): boolean => {
  if (permission === PermissionTypesEnum.Admin) return ADMIN_USERS.includes(email);
  if (permission === PermissionTypesEnum.SuperAdmin) return SUPER_USERS.includes(email);
  return false;
};

const PermissionUtil = {
  hasPermission,
};

export default PermissionUtil;
