import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { INotificationGraphQL } from '@biproxi/models/interfaces/INotification';
import { useSubscription } from '@apollo/client';
import { CardPop } from '../elements/Motion';
import Colors from '../styles/Colors';
import NOTIFICATION from '../graphql/subscriptions/notification.subscription';
import BoxShadows from '../styles/BoxShadows';
import ExitButton, { ExitButtonSizesEnum } from '../elements/ExitButton';
import Notification from './Notification';
import { useMobileMedia, useTabletMedia } from '../utils/MediaQuery';
import Auth from '../utils/Auth';

const Container = styled.div`
  position: fixed;
  right: 8px;
  top: 64px;
  z-index: 900;
`;

type NotificationContainerProps = {};

const NotificationContainer = styled(CardPop)<NotificationContainerProps>`
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  width: 334px;
  padding: 16px;
  margin-bottom: 8px;
  background-color: ${Colors.White};
  box-shadow: ${BoxShadows.Standard};
  border: 1px solid ${Colors.Grey300};
  border-radius: 8px;
  z-index: 900;
  display: flex;
  align-items: center;
  transition: all 0.2s;
`;

interface INoticationGraphQLWithTimeout extends INotificationGraphQL {
  // eslint-disable-next-line no-undef
  timeout: NodeJS.Timeout;
}

type NotificationPopUpProps = {};

const NotificationPopUp: React.FC<NotificationPopUpProps> = () => {
  /** Hooks */
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  /** State */
  const [notifications, setNotifications] = React.useState<INoticationGraphQLWithTimeout[]>([]);

  const addNotification = (notification: INoticationGraphQLWithTimeout) => {
    setNotifications((notifications) => [...notifications, notification]);
  };

  const removeNotification = (notification: INoticationGraphQLWithTimeout) => {
    clearTimeout(notification.timeout);
    setNotifications((notifications) => notifications.filter((n) => n._id !== notification._id));
  };

  /** GraphQL */
  useSubscription(NOTIFICATION, {
    skip: !Auth.isAuthenticated(),
    onSubscriptionData: ({ subscriptionData: { data: { notification }, error } }) => {
      if (error) {
        logger.error('NotificationPopUp useSubscription error', error);
      }

      notification.timeout = setTimeout(() => {
        removeNotification(notification);
      }, 12000);

      // Display the notification
      addNotification(notification);
    },

  });

  /** Render */
  if (isMobile || isTablet) {
    return null;
  }

  return (
    <Container>
      {[...notifications.reverse()].map((notification) => (
        <NotificationContainer key={notification._id}>
          <Notification
            notification={notification}
          />
          <ExitButton
            onClick={() => removeNotification(notification)}
            size={ExitButtonSizesEnum.Small}
          />
        </NotificationContainer>
      ))}
    </Container>
  );
};

export default NotificationPopUp;
