import axios from 'axios';
import { config } from '@biproxi/env';

export const queryUserCurrentSubscription = async (userId: string, userEmail: string) => {
  const res = await axios(`/api/billing/subscription?id=${userId}&email=${userEmail}`, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const currentSubscription = res.data;
  return currentSubscription;
};

export const queryChargebeeCustomer = async (userId: string) => {
  const res = await axios(`/api/billing/customer?id=${userId}`, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const customer = await res.data;
  return customer;
};

export const queryChargebeeHostedPage = async (hostedPageId: string) => {
  const res = await axios(`/api/billing/hosted-page?id=${hostedPageId}`, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const hostedPage = await res.data;
  return hostedPage;
};

export const queryInvoicePdfUrl = async (invoiceId: string) => {
  const res = await axios(`/api/billing/invoice-pdf?id=${invoiceId}`, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const queryInvoicePdfUrl = await res.data;
  return queryInvoicePdfUrl;
};

export const queryBillingInvoices = async (userId: string) => {
  const res = await axios(`/api/billing/invoices?id=${userId}`, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const invoices = await res.data;
  return invoices;
};

export const queryBillingPlans = async () => {
  const res = await axios('/api/billing/plans', {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const plans = await res.data;
  return plans;
};
