import {
  ApolloServer,
  BaseContext,
} from '@apollo/server';
import { config } from '@biproxi/env';
import { Neo4jGraphQL } from '@neo4j/graphql';
import neo4j, { Driver } from 'neo4j-driver';
import typeDefs from '../graphql/schema';

const AURA_ENDPOINT: string = config.AURA_ENDPOINT ?? '';
const AURA_USERNAME: string = config.AURA_USERNAME ?? '';
const AURA_PASSWORD: string = config.AURA_PASSWORD ?? '';

async function configureAuraServer(): Promise<ApolloServer<BaseContext>> {
  const driver: Driver = neo4j.driver(
    AURA_ENDPOINT,
    neo4j.auth.basic(AURA_USERNAME, AURA_PASSWORD),
  );

  const neoSchema = new Neo4jGraphQL({ typeDefs, driver });
  const schema = await neoSchema.getSchema();

  const apolloServer: ApolloServer<BaseContext> = new ApolloServer({
    schema,
    csrfPrevention: false,
    stopOnTerminationSignals: false,
  });
  apolloServer.startInBackgroundHandlingStartupErrorsByLoggingAndFailingAllRequests(); // Note from past Jesse to future Jesse: synchronous method. don't await
  return apolloServer;
}

export default configureAuraServer;
