enum ListingInfoFieldNamesEnum {
  AccruedInterestAmount = 'Accrued Interest Amount',
  AdditionalStructuralCapacity = 'Additional Structural Capacity',
  ADR = 'ADR',
  AgeRestriction = 'Age Restriction',
  AmortizationRate = 'Amortization Rate',
  Anchored = 'Anchored',
  AnchoredTenantLeaseExpiration = 'Anchored Tenant Lease Expiration',
  AnchorTenant = 'Anchor Tenant',
  AnchorTenantCredit = 'Anchor Tenant Credit',
  AnnualRent = 'Annual Rent',
  AnnualRentIncrease = 'Annual Rent Increase',
  AssetClass = 'Asset Class',
  AssumableFinancing = 'Assumable Financing',
  AverageRentPerPad = 'Average Rent Per Pad',
  AverageSqft = 'Average Sq Ft',
  AverageNumberOfBedAndBath = 'Average Number of Bed and Bath',
  BoatRVStorage = 'Boat/RV Storage',
  BuildingClass = 'Building Class',
  BuildingSqFt = 'Building Sq Ft',
  Contiguous = 'Contiguous',
  RentControl = 'Rent Control',
  CeilingHeight = 'Ceiling Height',
  ClearHeight = 'Clear Height',
  CommunityType = 'Community Type',
  ConservationEasement = 'Conservation Easement',
  Credit = 'Credit',
  CreditRating = 'Credit Rating',
  CreditRatingAgency = 'Credit Rating Agency',
  DefaultInterest = 'Default Interest',
  DefaultStatus = 'Default Status',
  DevelopableLand = 'Developable Land',
  EconomicScale = 'Economic Scale',
  ElectricBilledDirect = 'Electric Billed Direct',
  EnvironmentalSurvey = 'Environmental Survey',
  FeeSimple = 'Fee Simple',
  GradeLevelDoors = 'Grade Level Doors',
  Guarantor = 'Guarantor',
  InterestRate = 'Interest Rate',
  KickOutClause = 'Kick Out Clause',
  LabToOfficeSqFtRatio = 'Lab/Office Sq Ft Ratio',
  LeaseCommencementDate = 'Lease Commencement Date',
  LeaseExpiration = 'Lease Expiration',
  LeinPosition = 'Lein Position',
  LoadingDocks = 'Loading Docks',
  LocationType = 'Location',
  LotSize = 'Lot Size',
  MaturityDate = 'Maturity Date',
  MaximumBoatSize = 'Maximum Boat Size',
  NAICS = 'NAICS',
  NetLease = 'Net Lease',
  NumberofDryRacks = 'Number of Dry Racks',
  NumberOfFloors = 'Number of Floors',
  NumberofLeaseRenewals = 'Number of Lease Renewals',
  NumberOfExistingLeases = 'Number of Existing Leases',
  NumberofLevels = 'Number of Levels',
  NumberofPads = 'Number of Pads',
  NumberofRooms = 'Number of Rooms',
  NumberofSpaces = 'Number of Spaces',
  NumberofTenants = 'Number of Tenants',
  NumberofUnits = 'Number of Units',
  NumberofUnitsSpaces = 'Number of Units/Spaces',
  NumberofWetSlips = 'Number of Wet Slips',
  NumberOfHomes = 'Number of Homes',
  Occupancy = 'Occupancy',
  OnCampus = 'On-Campus',
  Operating = 'Operating',
  OperatingForChildcare = 'Operating (childcare)',
  Ownership = 'Ownership',
  ParkingSpaces = 'Parking Spaces',
  RedevelopmentOpportunity = 'Redevelopment Opportunity',
  RemainingTotalTerm = 'Remaining Total Term',
  RevPAR = 'Rev PAR',
  SIC = 'SIC',
  Tenancy = 'Tenancy',
  TenantName = 'Tenant Name',
  TotalSqFt = 'Total Sq Ft',
  Type = 'Type',
  UnitBreakdownForMobileHome = 'Unit Breakdown - Mobile Home',
  Singlewide = 'Singlewide',
  Doublewide = 'Doublewide',
  Triplewide = 'Triplewide',
  RV = 'RV',
  UnitBreakdownForMultiFamily = 'Unit Breakdown - Multifamily',
  Studio = 'Studio',
  OneBedroom = '1BR',
  TwoBedroom = '2BR',
  ThreePlusBedroom = '3BR+',
  UnitBreakdownForSelfStorage = 'Unit Breakdown - Self Storage',
  FiveByFive = '5X5',
  TenByTen = '10X10',
  TenByFifteen = '10X15',
  TenByTwenty = '10X20',
  TenByThirty = '10X30',
  UnitPriceFor10X10 = '10X10 Unit Price',
  UPB = 'UPB',
  WaterPaidbyPark = 'Water Paid by Park',
  WeightedAverageLeaseTerm = 'Weighted Average Lease Term',
  YearBuilt = 'Year Built',
  YearBuiltRange = 'Year Built Range',
  YearRenovated = 'Year Renovated',
  YearsPerRenewalOption = 'Years per Renewal Option',
  Zoning = 'Zoning',
  CapRate = 'Cap Rate',
  SameSubdivision = 'Same Subdivision',
}

export default ListingInfoFieldNamesEnum;
