/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {
  Page, Text, View, Document, StyleSheet, Image, Font,
  // PDFViewer,
} from '@react-pdf/renderer';
import ICherreDataInsights from '@biproxi/models/interfaces/ICherreDataInsights';
import ICherreDataDemographics from '@biproxi/models/interfaces/ICherreDataDemographics';
import { config } from '@biproxi/env';
import CherreDictionaryUtil from '@biproxi/models/utils/CherreDictionaryUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import Colors from '../../styles/Colors';
import DataExplorerDataFrameSectionsContent from '../data-explorer/@types/data-frame-types/objects/DataExplorerDataFrameSectionsContent';
import DataExplorerDataFrameDemographicsSections from '../data-explorer/@types/data-frame-types/objects/DataExplorerDataFrameDemographicsSections';

// for developing as its way easier
// const selectedProperty2 = {
//   cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId: {
//     __typename: 'Insights',
//     insights: {
//       address: '1720 W OLIVE ST',
//       city: 'BOZEMAN',
//       state: 'MT',
//       zip: '59715',
//       assessor_parcel_number_raw: '06-0798-12-3-02-22-0000',
//       last_sale_amount: 0,
//       property_group_type: 'Residential',
//       property_use_standardized_code: '378',
//       property_use_code_mapped: 42,
//       zone_code: null,
//       tax_assessor_last_update_date: '2022-11-29',
//       latitude: 45.677014,
//       longitude: -111.061007,
//       lot_size_sq_ft: 12023,
//       lot_width: null,
//       lot_depth_ft: null,
//       gross_sq_ft: 4096,
//       lot_size_acre: 0.28,
//       year_built: 1980,
//       units_count: 4,
//       building_sq_ft: 4096,
//       tax_bill_amount: 4360.17,
//       jurisdiction: 'GALLATIN',
//       assessed_value_total: 516800,
//       assessed_tax_year: 2022,
//       assessed_value_improvements: 0,
//       assessed_value_land: 0,
//       market_value_year: 2021,
//       market_value_total: 516800,
//       market_value_improvements: 336284,
//       the_value_land: 180516,
//       upper_floor_sq_ft: 2048,
//       sewer_usage_code: '113',
//       water_source_code: '105',
//       construction_code: '10',
//       fire_resistance_class_code: null,
//       exterior_code: '0A',
//       topography_code: 102,
//       basement_sq_ft: 0,
//       hvacc_cooling_code: null,
//       hvacc_heating_code: '113',
//       has_elevator: null,
//       porch_code: 'PO0',
//       porch_sq_ft: 0,
//       fence_code: null,
//       fence_sq_ft: 0,
//       has_canopy: null,
//       canopy_sq_ft: null,
//     },
//     demographics: {
//       median_household_income: 60461,
//       household_income_0_10_count: 1037,
//       household_income_10_15_count: 547,
//       household_income_15_20_count: 540,
//       household_income_20_25_count: 747,
//       household_income_25_30_count: 745,
//       household_income_30_35_count: 539,
//       household_income_35_40_count: 753,
//       household_income_40_45_count: 647,
//       household_income_45_50_count: 620,
//       household_income_50_60_count: 1055,
//       household_income_60_75_count: 1389,
//       household_income_75_100_count: 1558,
//       household_income_100_125_count: 1253,
//       household_income_125_150_count: 813,
//       household_income_150_200_count: 1206,
//       household_income_200_250_count: 617,
//       household_income_250_300_count: 262,
//       household_income_over_500_count: 220,
//       crime_total_risk: 46,
//       crime_assault_risk: 50,
//       crime_burglary_risk: 15,
//       crime_larceny_risk: 87,
//       crime_murder_risk: 9,
//       crime_motor_vehicle_risk: 26,
//       crime_personal_risk: 27,
//       crime_property_risk: 54,
//       crime_rape_risk: 50,
//       crime_robbery_risk: 7,
//       population_median_age: 34.54,
//       population_age_00_04_count: 2037,
//       population_age_05_09_count: 1834,
//       population_age_10_14_count: 1638,
//       population_age_15_19_count: 3434,
//       population_age_20_24_count: 4659,
//       population_age_25_29_count: 1412,
//       population_age_30_34_count: 2914,
//       population_age_35_39_count: 2638,
//       population_age_40_44_count: 2040,
//       population_age_45_49_count: 1746,
//       population_age_50_54_count: 1638,
//       population_age_55_59_count: 1821,
//       population_age_60_64_count: 2042,
//       population_age_65_69_count: 1962,
//       population_age_70_74_count: 1572,
//       population_age_75_79_count: 970,
//       population_age_80_84_count: 556,
//       population_age_over_85_count: 411,
//       education_less_than_9_count: 114,
//       education_some_high_school_count: 370,
//       education_high_school_graduate_count: 2487,
//       education_some_college_count: 4455,
//       education_associate_degree_count: 1127,
//       education_bachelors_degree_count: 7387,
//       education_graduate_degree_count: 5782,
//       employee_business_finance_count: 817,
//       employee_arts_count: 480,
//       employee_cleaning_maintenance_count: 927,
//       employee_admin_support_count: 4954,
//       employee_computer_math_science_count: 462,
//       employee_construction_count: 734,
//       employee_social_services_count: 943,
//       employee_education_count: 1976,
//       employee_architecture_engineering_count: 454,
//       employee_food_service_count: 1709,
//       employee_healthcare_count: 1595,
//       employee_healthcare_practitioner_count: 897,
//       employee_repair_count: 803,
//       employee_military_count: 0,
//       employee_personal_care_count: 845,
//       employee_farming_count: 59,
//       employee_production_count: 1264,
//       employee_protective_service_count: 294,
//       employee_sales_count: 2955,
//       employee_social_science_count: 266,
//       employee_transportation_count: 1218,
//       employee_executive_count: 1585,
//       employee_unclassified_count: 219,
//     },
//     priorYearDemographics: {
//       crime_total_risk: 32,
//       median_household_income: 56088,
//       population_median_age: 33.75,
//     },
//   },
// };
// const properties2 = {
//   cherreInsightsAndDemographicDataFromManyTaxIds: {
//     __typename: 'InsightsFromManyIds',
//     insights: [
//       {
//         address: '552 W SHORE DR',
//         city: 'BELGRADE',
//         state: 'MT',
//         zip: '59714',
//         assessor_parcel_number_raw: '06-0903-03-3-13-06-0000',
//         last_sale_amount: 0,
//         property_group_type: 'Residential',
//         property_use_standardized_code: '378',
//         property_use_code_mapped: 42,
//         zone_code: '1',
//         tax_assessor_last_update_date: '2022-11-29',
//         latitude: 45.777138,
//         longitude: -111.224505,
//         lot_size_sq_ft: 12807,
//         lot_width: null,
//         lot_depth_ft: null,
//         gross_sq_ft: 5132,
//         lot_size_acre: 0.29,
//         year_built: 2000,
//         units_count: 4,
//         building_sq_ft: 5132,
//         tax_bill_amount: 5054.6,
//         jurisdiction: 'GALLATIN',
//         assessed_value_total: 613300,
//         assessed_tax_year: 2022,
//         assessed_value_improvements: 0,
//         assessed_value_land: 0,
//         market_value_year: 2021,
//         market_value_total: 613300,
//         market_value_improvements: 527266,
//         the_value_land: 86034,
//         upper_floor_sq_ft: 2566,
//         sewer_usage_code: '113',
//         water_source_code: '105',
//         construction_code: '10',
//         fire_resistance_class_code: null,
//         exterior_code: '0A',
//         topography_code: 102,
//         basement_sq_ft: 0,
//         hvacc_cooling_code: null,
//         hvacc_heating_code: '111',
//         has_elevator: null,
//         porch_code: 'PO0',
//         porch_sq_ft: 0,
//         fence_code: null,
//         fence_sq_ft: 0,
//         has_canopy: null,
//         canopy_sq_ft: null,
//       },
//       {
//         address: '1013 S BOZEMAN AVE',
//         city: 'BOZEMAN',
//         state: 'MT',
//         zip: '59715',
//         assessor_parcel_number_raw: '06-0799-18-2-33-08-0000',
//         last_sale_amount: 0,
//         property_group_type: 'Residential',
//         property_use_standardized_code: '378',
//         property_use_code_mapped: 42,
//         zone_code: null,
//         tax_assessor_last_update_date: '2022-11-29',
//         latitude: 45.66873,
//         longitude: -111.034658,
//         lot_size_sq_ft: 15638,
//         lot_width: null,
//         lot_depth_ft: null,
//         gross_sq_ft: 4096,
//         lot_size_acre: 0.36,
//         year_built: 1972,
//         units_count: 4,
//         building_sq_ft: 4096,
//         tax_bill_amount: 4560.48,
//         jurisdiction: 'GALLATIN',
//         assessed_value_total: 540600,
//         assessed_tax_year: 2022,
//         assessed_value_improvements: 0,
//         assessed_value_land: 0,
//         market_value_year: 2021,
//         market_value_total: 540600,
//         market_value_improvements: 234249,
//         the_value_land: 306351,
//         upper_floor_sq_ft: 2048,
//         sewer_usage_code: '113',
//         water_source_code: '105',
//         construction_code: '10',
//         fire_resistance_class_code: null,
//         exterior_code: '4Y',
//         topography_code: 102,
//         basement_sq_ft: 0,
//         hvacc_cooling_code: null,
//         hvacc_heating_code: '236',
//         has_elevator: null,
//         porch_code: 'PO0',
//         porch_sq_ft: 0,
//         fence_code: null,
//         fence_sq_ft: 0,
//         has_canopy: null,
//         canopy_sq_ft: null,
//       },
//     ],
//     demographics: {
//       median_household_income: 60461,
//       household_income_0_10_count: 1037,
//       household_income_10_15_count: 547,
//       household_income_15_20_count: 540,
//       household_income_20_25_count: 747,
//       household_income_25_30_count: 745,
//       household_income_30_35_count: 539,
//       household_income_35_40_count: 753,
//       household_income_40_45_count: 647,
//       household_income_45_50_count: 620,
//       household_income_50_60_count: 1055,
//       household_income_60_75_count: 1389,
//       household_income_75_100_count: 1558,
//       household_income_100_125_count: 1253,
//       household_income_125_150_count: 813,
//       household_income_150_200_count: 1206,
//       household_income_200_250_count: 617,
//       household_income_250_300_count: 262,
//       household_income_over_500_count: 220,
//       crime_total_risk: 46,
//       crime_assault_risk: 50,
//       crime_burglary_risk: 15,
//       crime_larceny_risk: 87,
//       crime_murder_risk: 9,
//       crime_motor_vehicle_risk: 26,
//       crime_personal_risk: 27,
//       crime_property_risk: 54,
//       crime_rape_risk: 50,
//       crime_robbery_risk: 7,
//       population_median_age: 34.54,
//       population_age_00_04_count: 2037,
//       population_age_05_09_count: 1834,
//       population_age_10_14_count: 1638,
//       population_age_15_19_count: 3434,
//       population_age_20_24_count: 4659,
//       population_age_25_29_count: 1412,
//       population_age_30_34_count: 2914,
//       population_age_35_39_count: 2638,
//       population_age_40_44_count: 2040,
//       population_age_45_49_count: 1746,
//       population_age_50_54_count: 1638,
//       population_age_55_59_count: 1821,
//       population_age_60_64_count: 2042,
//       population_age_65_69_count: 1962,
//       population_age_70_74_count: 1572,
//       population_age_75_79_count: 970,
//       population_age_80_84_count: 556,
//       population_age_over_85_count: 411,
//       education_less_than_9_count: 114,
//       education_some_high_school_count: 370,
//       education_high_school_graduate_count: 2487,
//       education_some_college_count: 4455,
//       education_associate_degree_count: 1127,
//       education_bachelors_degree_count: 7387,
//       education_graduate_degree_count: 5782,
//       employee_business_finance_count: 817,
//       employee_arts_count: 480,
//       employee_cleaning_maintenance_count: 927,
//       employee_admin_support_count: 4954,
//       employee_computer_math_science_count: 462,
//       employee_construction_count: 734,
//       employee_social_services_count: 943,
//       employee_education_count: 1976,
//       employee_architecture_engineering_count: 454,
//       employee_food_service_count: 1709,
//       employee_healthcare_count: 1595,
//       employee_healthcare_practitioner_count: 897,
//       employee_repair_count: 803,
//       employee_military_count: 0,
//       employee_personal_care_count: 845,
//       employee_farming_count: 59,
//       employee_production_count: 1264,
//       employee_protective_service_count: 294,
//       employee_sales_count: 2955,
//       employee_social_science_count: 266,
//       employee_transportation_count: 1218,
//       employee_executive_count: 1585,
//       employee_unclassified_count: 219,
//     },
//     priorYearDemographics: {
//       crime_total_risk: 32,
//       median_household_income: 56088,
//       population_median_age: 33.75,
//     },
//   },
// };

/**
 * Should get some of the styling/fonts componentized at some point
 */

// can't get the fonts to work how I want
// const aktivGrotesk = 'https://use.typekit.net/ivz8awd.css';
// got this open type font by 'curling' the above url and copying the opentype urls given
// Font weights don't seem to work well.
const aktivGrotesk700 = 'https://use.typekit.net/af/a798a9/000000000000000077359d55/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3';
Font.register({
  family: 'aktiv-grotesk700',
  format: 'opentype',
  src: aktivGrotesk700,
});

const aktivGrotesk400 = 'https://use.typekit.net/af/ab3e12/000000000000000077359d4f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3';
Font.register({
  family: 'aktiv-grotesk400',
  format: 'opentype',
  src: aktivGrotesk400,
});

const aktivGrotesk500 = 'https://use.typekit.net/af/c11a71/000000000000000077359d5e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3';
Font.register({
  family: 'aktiv-grotesk500',
  format: 'opentype',
  src: aktivGrotesk500,
});

// Create styles
const styles = StyleSheet.create({
  // GENERAL
  page: {
    backgroundColor: Colors.White,
    padding: 24,
  },
  logoBox: {
    marginBottom: 24,
  },
  logo: {
    height: 'auto',
    width: 120,
  },

  // LISTING PREVIEW
  listingPreviewContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  listingImage: {
    width: 'auto',
    height: '40',
    borderRadius: 4,
    marginRight: 12,
  },
  listingName: {
    fontFamily: 'aktiv-grotesk700',
    color: Colors.Grey900,
    fontWeight: 700,
    fontSize: 16,
    textOverflow: 'ellipsis',
    marginBottom: 5,
  },
  listingAddress: {
    fontFamily: 'aktiv-grotesk500',
    color: Colors.Grey900,
    fontWeight: 500,
    fontSize: 12,
    textOverflow: 'ellipsis',
    marginBottom: 5,
  },

  // INSIGHTS
  sectionHeader: {
    fontFamily: 'aktiv-grotesk700',
    color: Colors.Grey900,
    fontWeight: 700,
    fontSize: 16,
    textOverflow: 'ellipsis',
    marginBottom: 4,
    marginTop: 8,
  },
  insightsBox: {
    backgroundColor: Colors.Grey100,
    borderRadius: 8,
    padding: 16,
    marginBottom: 16,
  },
  insightsInnerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  insightsItem: {
    width: '33.33%',
  },
  heading: {
    fontFamily: 'aktiv-grotesk700',
    color: Colors.Blurple700,
    fontWeight: 700,
    fontSize: 16,
    textOverflow: 'ellipsis',
    marginBottom: 8,
  },
  fieldnName: {
    fontFamily: 'aktiv-grotesk400',
    color: Colors.Grey400,
    fontWeight: 400,
    fontSize: 12,
    textOverflow: 'ellipsis',
  },
  fieldValue: {
    fontFamily: 'aktiv-grotesk700',
    color: Colors.Grey900,
    fontWeight: 700,
    fontSize: 14,
    textOverflow: 'ellipsis',
    marginBottom: 8,
  },

  // DEMOGRAPHICS
  demographicsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  demNumber: {
    fontFamily: 'aktiv-grotesk400',
    color: Colors.Grey900,
    fontWeight: 400,
    fontSize: 12,
    textOverflow: 'ellipsis',
  },
  demographicsInnerBox: {
    backgroundColor: Colors.Grey100,
    borderRadius: 8,
    padding: 16,
    width: '48%',
    marginBottom: 24,
  },
  demographicsColumn: {
    width: '48%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  demographicsItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  blueBar: {
    backgroundColor: Colors.Blurple700,
    height: 10,
    marginRight: 8,
  },
});

enum PropertyTypeEnum {
  SelectedProperty = 'Selected property',
  SimilarProperty = 'Similar property',
}

// Do these types exist somewhere else already?
interface ISelectedProperty {
  cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId: {
    insights: ICherreDataInsights,
    demographics: ICherreDataDemographics,
    priorYearDemographics: ICherreDataDemographics,
  }
}

interface IComparedProperties {
  cherreInsightsAndDemographicDataFromManyTaxIds: {
    insights: ICherreDataInsights[],
    demographics: ICherreDataDemographics,
    priorYearDemographics: ICherreDataDemographics,
  }
}

export type ListingReportProps = {
  selectedProperty?: ISelectedProperty;
  properties?: IComparedProperties;
};

const ListingReport: React.FC<ListingReportProps> = ({
  selectedProperty,
  properties,
}) => {
  const ListingHeader: React.FC<{ insights: ICherreDataInsights, type: PropertyTypeEnum }> = ({
    insights,
    type,
  }) => (
    <View>
      <Text style={styles.listingAddress}>
        {type}
      </Text>
      <View style={styles.listingPreviewContainer}>
        <Image
          style={styles.listingImage}
          src={`https://maps.googleapis.com/maps/api/streetview?key=${config.NEXT_PUBLIC_GOOGLE_BROWSER_KEY}&source=outdoor&radius=75&location=${insights.latitude},${insights.longitude}&pitch=5&size=46x45`}
        />
        <View>
          <Text style={styles.listingName}>
            {insights.address}
          </Text>
          <Text style={styles.listingAddress}>
            {insights.city}
            ,
            {' '}
            {insights.state}
            {' '}
            {insights.zip}
          </Text>
        </View>
      </View>
    </View>
  );

  const Insights: React.FC<{ insights: ICherreDataInsights }> = ({
    insights,
  }) => (
    <View>
      <Text style={styles.sectionHeader}>
        Data Insights
      </Text>
      {Object.keys(DataExplorerDataFrameSectionsContent).map((dataSection) => (
        <View style={styles.insightsBox}>
          <Text style={styles.heading}>
            {dataSection}
          </Text>
          <View style={styles.insightsInnerContainer}>
            {(DataExplorerDataFrameSectionsContent[dataSection].map((info) => {
              let insightData = insights?.[info?.cherreVariableName];
              if (insightData === null) {
                insightData = '--';
              }
              const infoTitle: string = info.item;
              if (infoTitle === 'Property use') {
                insightData = CherreDictionaryUtil.lookup('property_use_code_mapped', insightData);
              }
              if (typeof insightData === 'number' && !info.item.toLowerCase().includes('year')) insightData = StringUtil.formatNumber(insightData);
              return (
                <View style={styles.insightsItem}>
                  <Text style={styles.fieldnName}>
                    {infoTitle}
                  </Text>
                  <Text style={styles.fieldValue}>
                    {insightData}
                  </Text>
                </View>
              );
            }))}
          </View>
        </View>
      ))}
    </View>
  );

  const Demographics: React.FC<{ demographics: ICherreDataDemographics }> = ({
    demographics,
  }) => {
    const getBarWidth = (value: number, dataSection: string) => {
      if (value >= 0) {
        const max = Math.max(...DataExplorerDataFrameDemographicsSections[dataSection]?.slice(1)?.map((info) => demographics?.[info?.cherreVariableName]));
        return ((value / max) * 100);
      }
      return 0;
    };

    return (
      <View>
        <Text style={styles.sectionHeader}>
          Demographic Data
        </Text>
        <View style={styles.demographicsContainer}>
          {Object.keys(DataExplorerDataFrameDemographicsSections).map((dataSection) => (
            <View style={styles.demographicsInnerBox}>
              <Text style={styles.heading}>
                {dataSection}
              </Text>
              <View>
                {DataExplorerDataFrameDemographicsSections[dataSection].slice(1).filter((info) => (
                  demographics?.[info.cherreVariableName] !== null
                )).map((info) => {
                  let demographicData = demographics?.[info.cherreVariableName];
                  const width = `${getBarWidth(demographicData, dataSection)}%`;
                  if (typeof demographicData === 'number') demographicData = StringUtil.formatNumber(demographicData);

                  return (
                    <View style={styles.demographicsItem}>
                      <View style={styles.demographicsColumn}>
                        <Text style={styles.fieldnName}>
                          {info.item}
                        </Text>
                      </View>
                      <View style={styles.demographicsColumn}>
                        <View style={[styles.blueBar, { width }]} />
                        <Text style={styles.demNumber}>
                          {demographicData}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          ))}
        </View>
      </View>
    );
  };

  const selectedInsights: ICherreDataInsights = selectedProperty?.cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId?.insights;
  const selectedDemographics: ICherreDataDemographics = selectedProperty?.cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId?.demographics;

  const comparableInsights: ICherreDataInsights[] = properties?.cherreInsightsAndDemographicDataFromManyTaxIds?.insights; // only thing that is an array
  const comparableDemographics: ICherreDataDemographics = properties?.cherreInsightsAndDemographicDataFromManyTaxIds?.demographics;

  /** Render */
  return (
    // Uncomment PDFViewer and render somewhere for easier development
  // <PDFViewer style={{ width: '100vw', height: '100vh' }} showToolbar>
    <Document
      title="Biproxi-properties-report"
    >
      <Page
        size="LETTER"
        style={styles.page}
      >
        <View style={styles.logoBox}>
          <Image
            style={styles.logo}
            src="/biproxi-logo-higher-quality-black.png"
          />
        </View>
        {/* Selected Listing */}
        <ListingHeader
          insights={selectedInsights}
          type={PropertyTypeEnum.SelectedProperty}
        />
        <Insights insights={selectedInsights} />
        <Demographics demographics={selectedDemographics} />
        {/* Compared Listing */}
        {comparableInsights.map((insight) => (
          <>
            <ListingHeader
              insights={insight}
              type={PropertyTypeEnum.SimilarProperty}
            />
            <Insights insights={insight} />
            <Demographics demographics={comparableDemographics} />
          </>
        ))}
      </Page>
    </Document>
  // </PDFViewer>
  );
};

export default ListingReport;
