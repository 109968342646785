import React from 'react';
import styled from '@emotion/styled';
import UserUtil from '@biproxi/models/utils/UserUtil';
import { KB_PROFILE_BASE_URL } from '@biproxi/models/externalLinks';
import { useNavigate } from 'react-router-dom';
import Colors from '../../styles/Colors';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import ProfileImage from '../ProfileImage';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import Button, {
  ButtonSizesEnum,
  ButtonTypesEnum,
} from '../../elements/Button';
import Icon, { Icons } from '../../elements/Icon';
import Text, { TextTypesEnum } from '../../elements/Text';
import useUser from '../../hooks/useUser.hook';
import { media } from '../../utils/MediaQuery';
import LinkText from '../../elements/LinkText';
import Flex from '../../elements/Flex';
import { ModalTypesEnum } from './Modal';

const Container = styled.div`
  width: 944px;
  max-width: 90vw;

  ${media.mobile} {
    width: 100%;
  }
`;

// the div that contains the entirety of all elements within the 'profile picture' block
const ProfilePictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: static;
`;

// the div that contains the actual picture
const ProfileImageWrapper = styled.div`
  width: 72px;
  height: 72px;
  background: ${Colors.Grey200};
  border-radius: 100px;
  margin: 5px 0px;
  position: static;
  top: 21px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PenIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0;
  bottom: 0;
  background: ${Colors.White};
  border: 1px solid ${Colors.Grey300};
  box-sizing: border-box;
  border-radius: 100px;
  &:hover {
    background-color: ${Colors.Grey100};
    cursor: pointer;
  }
`;

const ProfileIconsWrapper = styled.div`
  position: relative;
`;

export type WelcomeToBiproxiModalProps = {};

const WelcomeToBiproxiModal: React.FC<WelcomeToBiproxiModalProps> = () => {
  /** Hooks */
  const { user } = useUser();
  const navigate = useNavigate();

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  const pushChangeProfilePictureModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ChangeProfilePicture,
      props: {},
    }),
  );

  const replaceWelcomeModal = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.LoginUpgradeAccountReminder,
      props: {},
    }),
  );

  const welcomeText = !UserUtil.isInvestor(user)
    ? 'Let’s create your broker profile and start creating your listings. Don’t have time? You can always update your profile at anytime in “My profile” settings.'
    : 'Let’s create your investor profile and start looking at properties that better match your criteria. Don’t have time? You can always update your profile at anytime in “My profile” settings.';

  /* Render */
  return (
    <ModalContainer>
      <ModalHeader title="Welcome to Biproxi" />
      <ModalContent>
        <Container>
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Grey700}
            margin="0 0 24px"
          >
            {welcomeText}
            <LinkText onClick={() => window.open(KB_PROFILE_BASE_URL, '_blank')} margin="0 0 0 4px">
              Learn more.
            </LinkText>
          </Text>
          <Flex direction="column" align="center">
            {/* This code and css is in two places, here and ProfileBasicInfo.tsx could compentize but I'm lazy rn */}
            <ProfilePictureContainer>
              <Text type={TextTypesEnum.Medium12} color={Colors.Grey700}>
                Profile picture
              </Text>
              <ProfileIconsWrapper>
                <ProfileImageWrapper onClick={pushChangeProfilePictureModal}>
                  {user ? (
                    <ProfileImage user={user} margin="0px" />
                  ) : (
                    <Icon icon={Icons.UserLight} color={Colors.Grey700} size={24} margin="0 0 8px 0" />
                  )}
                </ProfileImageWrapper>
                <PenIconWrapper onClick={pushChangeProfilePictureModal}>
                  <Icon icon={Icons.PenRegular} color={Colors.Grey700} size={12} margin="8px 0 0 0" />
                </PenIconWrapper>
              </ProfileIconsWrapper>
            </ProfilePictureContainer>
            <Button
              text="Update my profile now"
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Large}
              onClick={() => {
                navigate('/app/dashboard/profile');
                popModal();
              }}
              margin="24px 0"
            />
            <LinkText
              dataCy="cancel-photo-update-link"
              onClick={() => replaceWelcomeModal()}
              margin="0 0 16px 0"
            >
              Do this at a later time
            </LinkText>
            <Text
              type={TextTypesEnum.Regular16}
              color={Colors.Grey900}
              align="center"
            >
              You can access your profile settings at any time in your profile menu at the top right.
            </Text>
          </Flex>
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default WelcomeToBiproxiModal;
