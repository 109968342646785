import React from 'react';
import Flex from '../elements/Flex';
import { TextTypesEnum } from '../elements/Text';
import Colors from '../styles/Colors';
import Icons from '../elements/Icons';
import Input from '../elements/Input';
import Tag from '../elements/Tag';
import Icon from '../elements/Icon';

type KeywordFilterProps = {
  values?: string[];
  onChange?: (value: string[]) => void; // to be used with controlled component
  setKeywordState?: React.Dispatch<React.SetStateAction<string[]>>; // to be used with uncontrolled component
  'data-cy'?: string;
}

const KeywordFilter: React.FC<KeywordFilterProps> = ({
  values = [],
  onChange,
  setKeywordState,
  'data-cy': dataCy,
}) => {
  const [keywords, setKeywords] = React.useState<string[]>(values);

  React.useEffect(() => {
    setKeywordState?.(keywords);
  }, [keywords]);

  // used to reset the keywords state when external props change
  // e.g. on srp filers, when someone clears the filters, we want to reset the keywords state
  React.useEffect(() => {
    setKeywords(values);
  }, [values]);

  return (
    <Flex direction="column">
      <Input
        data-cy={dataCy}
        placeholder="E.g. city, retail, hospitality"
        label="Use comma-seperated keywords"
        value={keywords.join(', ')}
        onChange={(event) => {
          const { value } = event.target;
          const commaSeparatedKeywords = value.split(',').map((keyword) => keyword.trim());
          setKeywords(commaSeparatedKeywords);
          onChange?.(commaSeparatedKeywords);
        }}
      />

      {keywords.length > 0 && (
      <Flex margin="16px 0 0 0">
        {keywords.map((keyword) => (
          <Tag
            data-cy="save-search-keyword-tag"
            text={keyword}
            textSize={TextTypesEnum.Medium14}
            customTextColor={Colors.Brand800 || Colors.Blurple800}
            customTagColor={Colors.Brand50 || Colors.Blurple50}
            right
            padding="0 8px"
            margin="0 8px 0 0"
          >
            <Icon
              icon={Icons.TimesRegular}
              color={Colors.Brand700 || Colors.Blurple700}
              size={10}
              margin="0 0 0 8px"
              onClick={() => {
                setKeywords(keywords.filter((value: string) => value !== keyword));
              }}
            />
          </Tag>
        ))}
      </Flex>
      )}
    </Flex>
  );
};

export default KeywordFilter;
