import gql from 'graphql-tag';

const GET_ORGANIZATION_LISTINGS = gql`
  query getOrganizationListings($where: OrganizationWhere) {
    organizations(where: $where) {
      name
      listingsConnection {
        edges {
          node {
            _id
            address1
            address2
            addressState
            assetClass
            propertyType
            city
            description
            name
            metaCreatedAt
            metaLastUpdatedAt
            zip
            state
            mediaFileIds
            createdByUser {
              _id
              lastName
              firstName
            }
            belongsToOrganization(where: $where) {
              name
              _id
            }
          }
        }
      }
    }
  }
`;

export default GET_ORGANIZATION_LISTINGS;
