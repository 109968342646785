import React from 'react';
import styled from '@emotion/styled';
import { useQuery, useMutation } from '@apollo/client';
import { AmericanStatesEnum } from '@biproxi/models/enums/AmericanStatesEnums';
import * as UserJoi from '@biproxi/models/joi/user.joi';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import UserUtil from '@biproxi/models/utils/UserUtil';
import ILicense from '@biproxi/models/interfaces/ILicense';
import GET_USER from '../../graphql/queries/user.query';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Input, { InputTypesEnum } from '../../elements/Input';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Dropdown from '../../elements/Dropdown';
import ADD_USER_LICENSE from '../../graphql/mutations/addUserLicense.mutation';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { media } from '../../utils/MediaQuery';
import { ListingActions } from '../../redux/listing.redux';

const Container = styled.div`
  width: 432px;

  ${media.mobile} {
    width: 100%;
  }
`;

const Form = styled.form``;

const Spacer = styled.div`
  height: 24px;
`;

enum LicenseOwnershipTypeEnum {
  Myself = 'Myself',
  SeparateEntity = 'A separate entity',
}

export type AddLicenseModalProps = {
  createListing: boolean | undefined;
};

const AddLicenseModal: React.FC<AddLicenseModalProps> = ({
  createListing,
}) => {
  /** State */
  const [currentUsersName, setCurrentUsersName] = React.useState('');
  const [ownerType, setOwnerType] = React.useState(LicenseOwnershipTypeEnum.Myself);

  /** Actions */
  const setListingField = (license: ILicense) => dispatch(
    ListingActions.setListingField({ license }),
  );

  /** Hooks */
  const formParams: UseFormParams = {
    fields: {
      owner: '',
      state: '',
      number: '',
    },
    fieldOrder: ['/owner', '/state', '/number'],
    schema: UserJoi.addUserLicenseParamsSchema,
  };

  const {
    controllers: {
      owner,
      state,
      number,
    },
    setFieldErrors,
    setValues,
    isValid,
  } = useForm(formParams);

  /** GraphQL */
  type TUserData = {
    user?: IUserGraphQL;
  };

  useQuery<TUserData>(GET_USER, {
    onCompleted: async ({ user }) => {
      if (user) {
        setValues({
          owner: UserUtil.fullName(user),
          state: '',
          number: '',
        });
        setCurrentUsersName(UserUtil.fullName(user));
      }
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const handleSubmit = async (event?: React.FormEvent) => {
    if (event) event.preventDefault();
    if (!isValid()) return;

    await addLicenseMutation({
      variables: {
        params: {
          owner: owner.value().toString(),
          state: state.value().toString(),
          number: number.value().toString(),
        },
      },
    });
  };

  /** GraphQL */
  const [addLicenseMutation, { loading }] = useMutation(ADD_USER_LICENSE, {
    refetchQueries: [{ query: GET_USER }],
    onCompleted: async (data) => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'License successfully added',
      });
      if (createListing) {
        const license: ILicense = {
          _id: data?.addUserLicense,
          owner: owner.value().toString(),
          state: state.value().toString(),
          number: number.value().toString(),
        };
        setListingField(license);
      }

      popModal();
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        owner.setError(message);
      }
    },
  });

  /* Render */
  return (
    <ModalContainer>
      <Form onSubmit={(event) => handleSubmit(event)}>
        <ModalHeader title="Add license" close={popModal} />
        <ModalContent>
          <Container>
            <Dropdown
              label="Who holds this license?"
              value={ownerType}
              onChange={(value) => {
                setOwnerType(value);
                if (value === LicenseOwnershipTypeEnum.Myself) {
                  owner.setRawValue(currentUsersName);
                } else {
                  owner.setRawValue('');
                }
              }}
              placeholder="Select the license owner"
              items={Object.values(LicenseOwnershipTypeEnum).map((value) => ({
                name: value,
                value,
              }))}
            />
            {ownerType === LicenseOwnershipTypeEnum.SeparateEntity && (
              <>
                <Spacer />
                <Input
                  label="Name on license"
                  placeholder="Enter the name on the license"
                  value={owner.value()}
                  onChange={owner.setValue}
                  ref={owner.setRef}
                  error={owner.error()}
                  inputType={InputTypesEnum.Text}
                />
              </>
            )}
            <Spacer />
            <Dropdown
              label="State of license"
              value={state.value()}
              error={state.error()}
              onChange={(value) => state.setRawValue(value)}
              placeholder="Select a state"
              items={Object.values(AmericanStatesEnum).map((value) => ({
                name: value,
                value,
              }))}
            />
            <Spacer />
            <Input
              label="License #"
              placeholder="Enter the license number"
              value={number.value()}
              onChange={number.setValue}
              ref={number.setRef}
              error={number.error()}
              inputType={InputTypesEnum.Text}
            />
          </Container>
        </ModalContent>
        <ModalFooter>
          <Flex justify="flex-end" width="100%">
            <Button
              text="Cancel"
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Medium}
              onClick={() => popModal()}
              margin="0px 8px 0px 0px"
              htmlType="button"
            />
            <Button
              text="Add license"
              isLoading={loading}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Medium}
              htmlType="submit"
            />
          </Flex>
        </ModalFooter>
      </Form>
    </ModalContainer>
  );
};

export default AddLicenseModal;
