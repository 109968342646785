import gql from 'graphql-tag';

const REMOVE_USER_FROM_ORGANIZATION = gql`
  mutation removeUserFromOrganization($disconnect: UserDisconnectInput, $where: UserWhere) {
    updateUsers(disconnect: $disconnect, where: $where) {
      info {
        relationshipsDeleted
      }
      users {
        _id
        firstName
        lastName
      }
    }
  }
`;

export default REMOVE_USER_FROM_ORGANIZATION;
