import { ApolloServer } from '@apollo/server';
import { NextApiRequest, NextApiResponse } from 'next';
import StatusCodeEnum from '@biproxi/models/enums/StatusCodeEnum';
import startNextGraphQLHandler, { GraphQLAPIHandler } from './graphql.handler';

export interface IGraphQLHandlerParams {
  req: NextApiRequest;
  res?: NextApiResponse;
  graphqlRequest: any;
}

type Neo4jQueryResponseType = {
  data: any,
  errors: any,
  status: StatusCodeEnum
}

export async function graphqlHandler(server: ApolloServer, graphqlRequest: any): Promise<Neo4jQueryResponseType> {
  const { req, res, graphqlQuery } = graphqlRequest;
  const handler: GraphQLAPIHandler = await startNextGraphQLHandler(server, {
    context: async (req: NextApiRequest, res: NextApiResponse) => ({ req, res, graphqlQuery }),
  });

  return handler(req, res);
}
