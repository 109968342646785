import React from 'react';

/**
  * Prevents background scrolling when overlay is popped
  * such as a modal or file preview.
  */
const useLockBodyScroll = (locked: boolean): void => {
  if (typeof window !== 'undefined') {
    const [originalStyle] = React.useState(window?.getComputedStyle(document.body).overflow);

    React.useLayoutEffect((): () => void => {
      if (locked) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = originalStyle;
      }
      return () => { document.body.style.overflow = originalStyle; };
    }, [locked]);
  }
};

export default useLockBodyScroll;
