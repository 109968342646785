/**
 * These limits are subject to change.
 * We need to test more with our data to really
 * determine the best values to use.
 */
enum PaginationLimitsEnum {
  SuperAdminUsers = 100,
  Channels = 100,
  Notifications = 100,
  DataExplorerProperties = 100,
  SRPListings = 36,
  SRPListingsMobile = 36,
  DataExplorerTable = 30,
  DataExplorerTableMobile = 30,
  MyListings = 12,
}

export default PaginationLimitsEnum;
