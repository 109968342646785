import { IUserGraphQL } from '../interfaces/IUser';
import IPrivateEvent, { IPrivateEventBid, IPrivateEventGraphQL, IPrivateEventParticipant } from '../interfaces/IPrivateEvent';

/**
 * Currently used to grab user info from the userId on the bid
 * since the user object is only stored in the participant array.
 */
const getPrivateEventUser = (userId: string, privateEvent: IPrivateEventGraphQL): IUserGraphQL | undefined => privateEvent?.participants?.find((participant) => participant.userId === userId)?.user;

/**
 * From the passed in bid, get the corresponding participant.
 */
const getParticipantFromBid = (bid: IPrivateEventBid, privateEvent: IPrivateEventGraphQL | IPrivateEvent): IPrivateEventParticipant | null => privateEvent?.participants?.find((participant) => participant?.userId === bid?.userId) || null;

/**
 * Get the participant from a userId.
 */
const getParticipantFromUserId = (userId: string, privateEvent: IPrivateEventGraphQL | IPrivateEvent): IPrivateEventParticipant | null => privateEvent?.participants?.find((participant) => participant?.userId === userId) || null;

/**
 * Get the participant from an email address.
 */
const getParticipantFromEmail = (email: string, privateEvent: IPrivateEventGraphQL | IPrivateEvent): IPrivateEventParticipant | null => privateEvent?.participants?.find((participant) => participant?.email === email) || null;

/**
 * Get the total number of unique users who have bid on the private event.
 */
const getTotalBidders = (privateEvent: IPrivateEventGraphQL | IPrivateEvent): number => new Set(privateEvent?.bids?.map((bid) => bid.userId)).size;

/**
 * Get the total number of bids on the private event.
 */
const getTotalBids = (privateEvent: IPrivateEventGraphQL | IPrivateEvent): number => privateEvent?.bids?.length ?? 0;

/**
 * Gets the list of bids that a single user has placed on the private event.
 */
const getAllUserBids = (userId: string, privateEvent: IPrivateEventGraphQL | IPrivateEvent): IPrivateEventBid[] => privateEvent?.bids?.filter((bid) => bid.userId === userId) || [];

/**
 * Get the bid with the highest dollar value for a specific user.
 */
const getUsersTopBid = (userId: string, privateEvent: IPrivateEventGraphQL | IPrivateEvent): IPrivateEventBid => {
  const usersBids = getAllUserBids(userId, privateEvent);
  return usersBids.reduce((topBid, bid) => (topBid?.totalBidAmount > bid?.totalBidAmount ? topBid : bid), usersBids[0]);
};

/**
 * Get the bid with the highest dollar value
 */
const getTopBid = (privateEvent: IPrivateEventGraphQL | IPrivateEvent): IPrivateEventBid => privateEvent?.bids?.reduce((topBid, bid) => (topBid?.totalBidAmount > bid?.totalBidAmount ? topBid : bid), privateEvent.bids[0]);

/**
 * Get all participants from that lost that placed at least one bid.
 */
const getLosingParticipantsThatPlacedABids = (privateEvent: IPrivateEventGraphQL | IPrivateEvent): IPrivateEventParticipant[] => privateEvent?.participants?.filter((participant) => participant.userId !== getTopBid(privateEvent)?.userId && hasUserPlacedBid(participant.userId, privateEvent));

/**
 * Determines if the userId passed in matches the userId of the top bid.
 */
const isUserTopBidder = (userId: string, privateEvent: IPrivateEventGraphQL): boolean => getTopBid(privateEvent)?.userId === userId;

/**
 * Determines if the user has placed a bid on the private event.
 */
const hasUserPlacedBid = (userId: string | undefined, privateEvent: IPrivateEventGraphQL | IPrivateEvent): boolean => {
  if (!userId) return false;
  return Boolean(privateEvent?.bids?.find((bid) => bid.userId === userId));
};

const PrivateEventUtil = {
  getAllUserBids,
  getUsersTopBid,
  getPrivateEventUser,
  getParticipantFromBid,
  getParticipantFromEmail,
  getTotalBidders,
  getTotalBids,
  getTopBid,
  isUserTopBidder,
  hasUserPlacedBid,
  getLosingParticipantsThatPlacedABids,
  getParticipantFromUserId,
};

export default PrivateEventUtil;
