import { logger } from '@biproxi/logger';
import {
  all, call, put, takeEvery, select,
} from 'redux-saga/effects';
import {
  PayloadAction,
} from '@reduxjs/toolkit';
import Wait from '@biproxi/utils/wait';
import { AppActionTypesEnum, AppActionPayloadTypes, AppActions } from '../app.redux';
import { AppState } from '../store';

export default function* appSaga() {
  try {
    yield all([
      pushToastWatch(),
    ]);
  } catch (e) {
    logger.error('appSage() error', e);
  }
}

/** ******************************************************************************
 *  Push Toast
 ****************************************************************************** */

function* pushToastWatch() {
  yield takeEvery(
    AppActionTypesEnum.PushToast,
    pushToastSaga,
  );
}

function* pushToastSaga(
  action: PayloadAction<AppActionPayloadTypes['PushToastPayloadType']>,
) {
  const { payload: { _id, duration = 4000 } } = action;

  const { toasts } = yield select((state: AppState) => state.app);

  // limit toasts max length to 5
  // by immediately removing the longest
  // living toast if another is pushed
  if (toasts.length > 5) {
    yield put(AppActions.popToast({}));
  }

  yield call(async () => Wait(duration));

  yield put(AppActions.popToast({ _id }));
}
