import Joi from 'joi';
import ListingUploadQueryTypesEnum from '../enums/ListingUploadQueryTypesEnum';
import JoiUtil from '../utils/JoiUtil';
import { authorizedDataSchema } from './authentication.joi';
import paginationSchema from './pagination.joi';

export const listingUploadMedia = Joi.object().keys({
  fileIds: Joi.array().items(Joi.string()).required(),
});

const listingUploadBrokerLicense = Joi.object().keys({
  _id: Joi.string().optional().allow(null).allow(''),
  owner: Joi.string().required(),
  state: Joi.string().regex(/^[A-Z]{2}$/).required(),
  number: Joi.string().required(),
});

export const listingUploadAdminInfo = Joi.object().keys({
  userId: Joi.string().optional(),
  firstName: Joi.string().optional(),
  lastName: Joi.string().optional(),
  email: Joi.string().optional(),
});

export const listingUpload = {
  _id: () => Joi.string(),
  organizationId: () => Joi.string(),
  queryType: () => Joi.string().valid(...Object.values(ListingUploadQueryTypesEnum)),
  userId: () => Joi.string(),
  listingId: () => Joi.string(),
  listingName: () => Joi.string(),
  additionalInfo: () => Joi.string(),
  state: () => Joi.string(), // not started, in progress, waiting for review, published
  media: () => listingUploadMedia,
  brokerLicense: () => listingUploadBrokerLicense,
  adminInfo: () => listingUploadAdminInfo.optional(),
};

/** ******************************************************************************
*  Create Listing Upload
******************************************************************************* */
export const createListingUploadParamsSchema = Joi.object().keys({
  _id: listingUpload._id().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  organizationId: listingUpload.organizationId().optional().error(([error]) => JoiUtil.encodeError('Required', error)),
  userId: listingUpload.userId().optional().error(([error]) => JoiUtil.encodeError('Required', error)),
  listingId: listingUpload.listingId().optional().error(([error]) => JoiUtil.encodeError('Required', error)),
  listingName: listingUpload.listingName().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  additionalInfo: listingUpload.additionalInfo().optional().allow(null).allow(''),
  state: listingUpload.state().optional().error(([error]) => JoiUtil.encodeError('Required', error)),
  brokerLicense: listingUpload.brokerLicense().optional().error(([error]) => JoiUtil.encodeError('Required', error)),
  media: listingUpload.media().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  adminInfo: listingUpload.adminInfo().optional().allow(null).error(([error]) => JoiUtil.encodeError('Required', error)),
});

export const createListingUploadRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: createListingUploadParamsSchema,
});

/** ******************************************************************************
 * List Listing Uploads
 * ******************************************************************************* */

export const listListingUploadsParamsSchema = Joi.object().keys({
  queryType: listingUpload.queryType().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  pagination: paginationSchema.optional(),
  keywords: Joi.array().items(Joi.string().optional().allow('', null)).optional(),
});

export const listListingUploadsRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: listListingUploadsParamsSchema,
});

/** ******************************************************************************
 * Get Listing Upload
 * ******************************************************************************* */
export const getListingUploadParamsSchema = Joi.object().keys({
  listingUploadId: listingUpload._id().optional(),
  listingId: listingUpload.listingId().optional(),
});

export const getListingUploadRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: getListingUploadParamsSchema,
});

/** ******************************************************************************
 * Update Listing Upload
 * ******************************************************************************* */
export const updateListingUploadParamsSchema = Joi.object().keys({
  _id: listingUpload._id().optional().error(([error]) => JoiUtil.encodeError('Listing upload id required', error)),
  state: listingUpload.state().optional(),
  adminInfo: listingUpload.adminInfo().optional(),
  listingId: listingUpload.listingId().optional(),
  listingName: listingUpload.listingName().optional(),
  media: Joi.object().keys({
    fileIds: Joi.array().items(Joi.string()).min(1).required(),
  }).error(([error]) => JoiUtil.encodeError('At Least One Image Required', error)),
}).prefs({
  abortEarly: false,
});

export const updateListingUploadRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: updateListingUploadParamsSchema,
});

/** ******************************************************************************
 * Delete Listing Upload
 * ******************************************************************************* */
export const deleteListingUploadParamsSchema = Joi.object().keys({
  listingUploadId: listingUpload._id().required().error(([error]) => JoiUtil.encodeError('Listing upload id required', error)),
}).prefs({
  abortEarly: false,
});

export const deleteListingUploadRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: deleteListingUploadParamsSchema,
});

/** ******************************************************************************
 * Create Listing Upload (client)
 * ******************************************************************************* */
export const createListingUploadClientParamsSchema = Joi.object().keys({
  listingName: listingUpload.listingName().required().error(([error]) => JoiUtil.encodeError('Listing name is required', error)),
  // brokerLicense: listingUpload.brokerLicense().optional().allow(null).allow(''),
  additionalInfo: listingUpload.additionalInfo().optional().allow(null).allow(''),
  fileIds: () => Joi.array().items(Joi.string()).optional().error(([error]) => JoiUtil.encodeError('At least one file is required', error)),
}).prefs({
  abortEarly: false,
});
