import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';

const Container = styled.div`
  height: 72px;
  box-sizing: border-box;
  padding: 0 16px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: ${Colors.Grey50};
  border: 1px solid ${Colors.Grey300};
  color: ${Colors.Black};
  font-size: 3.6rem;
  font-family: 'Satisfy', cursive;
`;

export type SignaturePadProps = {
  text: string;
}

const SignaturePad: React.FC<SignaturePadProps> = ({
  text,
}) => (
  <Container>
    {text}
  </Container>
);

export default SignaturePad;
