import gql from 'graphql-tag';

const mutation = gql`
  mutation UpdateUserEmail($params: UpdateUserEmailParams!) {
    updateUserEmail(params: $params) {
      token
    }
  }
`;

export default mutation;
