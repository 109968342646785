import gql from 'graphql-tag';
import ListingFragments from '../fragments/listing.fragments';

const LIST_MY_LISTINGS = gql`
  query Listings($params: ListListingsParams) {
    listings(params: $params) {
      data {
        ...ListingFields
        ...ListingAddress
        ...ListingPortfolioAddresses
        ...ListingSchedule
        ...ListingMedia
        ...ListingMetrics
        ...ListingMeta
      }
      info {
        totalCount
      }
    }
  }

  ${ListingFragments.fields}
  ${ListingFragments.address}
  ${ListingFragments.portfolioAddresses}
  ${ListingFragments.schedule}
  ${ListingFragments.media}
  ${ListingFragments.metrics}
  ${ListingFragments.meta}
`;

export default LIST_MY_LISTINGS;
