import React from 'react';
import styled from '@emotion/styled';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import StringUtil from '@biproxi/models/utils/StringUtil';
import UserUtil from '@biproxi/models/utils/UserUtil';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Flex from '../elements/Flex';
import ProfileImage, { ProfileImageTypes } from './ProfileImage';
import LinkText from '../elements/LinkText';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';
import Tag from '../elements/Tag';

const Container = styled.div<{ margin: string, type: UserInfoTypesEnum}>`
  height: ${(props) => (props.type === UserInfoTypesEnum.Large ? '64px' : '32px')};
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
  background-color: ${(props) => (props.type === UserInfoTypesEnum.Map ? Colors.White : null)};
  border-radius: ${(props) => (props.type === UserInfoTypesEnum.Map ? '8px' : 0)};
  width: ${(props) => (props.type === UserInfoTypesEnum.Map ? '188px' : '100%')};
  padding: ${(props) => (props.type === UserInfoTypesEnum.Map ? '8px' : 0)};
  z-index: 2;
  position: relative;

  > * {
    white-space: nowrap;
  }
`;

export enum UserInfoTypesEnum {
  ExtraSmall = 'ExtraSmall',
  Small = 'Small',
  Large = 'Large',
  Map = 'Map'
}

const typeMap = {
  [UserInfoTypesEnum.ExtraSmall]: ProfileImageTypes.ExtraSmall,
  [UserInfoTypesEnum.Small]: ProfileImageTypes.Small,
  [UserInfoTypesEnum.Map]: ProfileImageTypes.Small,
  [UserInfoTypesEnum.Large]: ProfileImageTypes.Large,
};

type UserInfoProps = {
  user: IUserGraphQL;
  margin?: string;
  type?: UserInfoTypesEnum;
  links?: boolean;
  nameOnly?: boolean;
  city?: boolean;
  includePhone?: boolean;
};

const UserInfo: React.FC<UserInfoProps> = ({
  user,
  margin,
  type = UserInfoTypesEnum.Small,
  links = false,
  nameOnly = false,
  city = false,
  includePhone = false,
}) => {
  /* State */
  if (!user) return null;

  /* Render */
  const isLarge = type === UserInfoTypesEnum.Large;
  const textType = isLarge ? TextTypesEnum.Regular14Small : TextTypesEnum.Regular12;

  const renderText = (text: string, onClick) => {
    if (links) {
      return <LinkText onClick={onClick} type={textType}>{text}</LinkText>;
    }

    return (
      <Text type={textType} color={Colors.Grey500} data-cy={text}>
        {text}
      </Text>
    );
  };

  return (
    <Container margin={margin} type={type}>
      <ProfileImage type={typeMap[type]} user={user} />
      <Flex direction="column">
        <Flex align="center">
          <Text type={isLarge ? TextTypesEnum.Medium14Small : TextTypesEnum.Medium12} color={Colors.Grey900}>
            {UserUtil.fullName(user)}
          </Text>
          {user?.investorProfile?.exchangeInvestor && (
            <Tag
              text="Exchange"
              customTagColor={Colors.Green100}
              customTextColor={Colors.Green900}
              padding="0 8px"
              margin="0 0 0 4px"
              textSize={TextTypesEnum.Medium14}
              width="80px"
              height="20px"
            />

          )}
        </Flex>
        {city && user?.address?.country && (
          <Flex>
            <Icon
              icon={Icons.MapMarkerAltRegular}
              color={Colors.Grey500}
              size={14}
              margin="0px 8px 0px 0px"
            />
            <Text
              type={isLarge ? TextTypesEnum.Medium14Small : TextTypesEnum.Medium12}
              color={Colors.Grey500}
            >
              {AddressUtil.formatAddress(user.address, {
                address1: true, address2: true, zip: true, country: true,
              })}
            </Text>
          </Flex>
        )}
        {!nameOnly && (
          <>
            {renderText(user.email, () => { window.open(`mailto:${user.email}`); })}
            {(includePhone) && renderText(StringUtil.formatPhoneNumber(user.phoneNumber), () => { window.open(`tel:${user.phoneNumber}`, '_self'); })}
          </>
        )}
      </Flex>
    </Container>
  );
};

export default UserInfo;
