import React from 'react';
import styled from '@emotion/styled';
import { AmericanStatesEnum } from '@biproxi/models/enums/AmericanStatesEnums';
import * as ListingJoi from '@biproxi/models/joi/listing.joi';
import IListingContact from '@biproxi/models/interfaces/IListingContact';
import shortid from 'shortid';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import Input, { InputTypesEnum } from '../../elements/Input';
import { ListingActions } from '../../redux/listing.redux';
import Dropdown, { DropdownTypesEnum } from '../../elements/Dropdown';
import CreateListingContactPhotoUpload from '../create-listing/CreateListingContactPhotoUpload';
import { useMobileMedia, media } from '../../utils/MediaQuery';
import useUserHook from '../../hooks/useUser.hook';
import Divider from '../../elements/Divider';

const Container = styled.div`
  width: 380px;

  ${media.mobile} {
    width: 100%;
  }
`;

const OverflowContainer = styled.div`
  overflow-y: scroll;
  height: 70vh;
`;

const InputSpacer = styled.div`
  height: 16px;
`;

const Form = styled.form``;

export type AddOrEditListingContactModalProps = {
  contacts: IListingContact[];
  contact: IListingContact;
  index?: number;
  isEdit?: boolean;
};

const AddOrEditListingContactModal: React.FC<AddOrEditListingContactModalProps> = ({
  contacts, isEdit, contact, index,
}) => {
  /** Hooks */
  const { user } = useUserHook();

  /** State */
  const [profileImageUrl, setProfileImageUrl] = React.useState<string>('');
  const isDefaultContact = Boolean(contact?._id === user._id);
  const [dropdownContact, setDropdownContact] = React.useState<IListingContact>(null);
  const duplicateContact = Boolean(dropdownContact && contacts.find(({ _id }) => _id === dropdownContact._id));

  /** Hooks */
  const isMobile = useMobileMedia();

  const formParams: UseFormParams = {
    fields: {
      _id: shortid.generate(),
      name: '',
      email: '',
      phoneNumber: '',
      title: '',
      company: '',
      licenseNumber: '',
      licenseState: '',
    },
    fieldOrder: ['/_id', '/name', '/title', '/company', '/email', './phoneNumber', '/licenseNumber', '/licenseState'],
    schema: ListingJoi.addOrEditListingContactFrontendSchema,
  };

  const {
    controllers: {
      name,
      title,
      company,
      email,
      phoneNumber,
      licenseNumber,
      licenseState,
    },
    isValid,
    setValues,
  } = useForm(formParams);

  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const setListingContacts = (contacts: IListingContact[]) => dispatch(
    ListingActions.setListingContacts({ contacts }),
  );

  const handleSubmit = (event?: React.FormEvent) => {
    if (event) event.preventDefault();
    if (!isValid()) return;
    if (duplicateContact) {
      popModal();
      return;
    }

    const updatedContacts = [...contacts];

    const newOrEditedContact = {
      _id: isDefaultContact || isEdit ? contact._id : dropdownContact ? dropdownContact._id : shortid.generate(),
      name: isDefaultContact ? contact.name : dropdownContact ? dropdownContact.name : name.value(),
      email: isDefaultContact ? contact.email : dropdownContact ? dropdownContact.email : email.value(),
      phoneNumber: isDefaultContact ? contact.phoneNumber : dropdownContact ? dropdownContact.phoneNumber : phoneNumber.value(),
      title: title.value(),
      company: company.value(),
      license: licenseState.value() ? `${licenseState.value()} #${licenseNumber.value()}` : '',
      profileImageUrl,
    };

    if (isEdit) {
      updatedContacts[index] = newOrEditedContact;
      setListingContacts(updatedContacts);
    } else {
      setListingContacts([...updatedContacts, newOrEditedContact]);
    }

    popModal();
  };

  /** Effects */
  React.useEffect(() => {
    if (contact) {
      setValues({
        _id: contact._id,
        name: contact.name,
        email: contact.email,
        phoneNumber: contact.phoneNumber,
        title: contact.title,
        company: contact.company,
        licenseNumber: contact.license ? contact.license.split('#')[1] : '',
        licenseState: contact.license ? contact.license.split('#')[0] : '',
      });
      setProfileImageUrl(contact.profileImageUrl);
    }
  }, []);

  /** if selecting existing contact from dropdown */
  React.useEffect(() => {
    if (dropdownContact) {
      setValues({
        _id: dropdownContact._id,
        name: dropdownContact.name,
        email: dropdownContact.email,
        phoneNumber: dropdownContact.phoneNumber,
        title: dropdownContact.title,
        company: dropdownContact.company,
        licenseNumber: dropdownContact.license ? dropdownContact.license.split('#')[1] : '',
        licenseState: dropdownContact.license ? dropdownContact.license.split('#')[0] : '',
      });
      setProfileImageUrl(dropdownContact.profileImageUrl);
    }
  }, [dropdownContact]);

  /* Render */
  return (
    <ModalContainer height="80vh" width={isMobile ? '100%' : null}>
      <ModalHeader title={isEdit ? 'Edit contact' : 'Add contact'} close={popModal} />
      <OverflowContainer id="Overflow Container">
        <Form onSubmit={(event) => handleSubmit(event)}>
          <ModalContent>
            <Container id="Container">
              {!isEdit && Boolean(user.contacts?.length) && (
                <>
                  <Dropdown
                    label="Existing contacts"
                    placeholder={user.contacts[0].name}
                    type={DropdownTypesEnum.Select}
                    value={dropdownContact?.name ?? ''}
                    onChange={(value) => {
                      setDropdownContact(value);
                    }}
                    items={Object.values(user.contacts).map((value) => ({
                      name: value.name,
                      value,
                    }))}
                  />
                  <Divider margin="16px 0px" />
                </>
              )}
              <Input
                autoFocus
                label="Name"
                placeholder="Enter name"
                value={name.value()}
                onChange={name.setValue}
                ref={name.setRef}
                error={name.error()}
                inputType={InputTypesEnum.Text}
                disabled={isDefaultContact || Boolean(dropdownContact)}
              />
              <InputSpacer />
              <Input
                label="Email"
                placeholder="Enter email"
                value={email.value()}
                onChange={email.setValue}
                ref={email.setRef}
                error={email.error()}
                inputType={InputTypesEnum.Text}
                disabled={isDefaultContact || Boolean(dropdownContact)}
              />
              <InputSpacer />
              <Input
                label="Phone"
                placeholder="(XXX) XXX-XXXX"
                value={phoneNumber.value()}
                onChange={phoneNumber.setValue}
                ref={phoneNumber.setRef}
                inputType={InputTypesEnum.PhoneNumber}
                error={phoneNumber.error()}
                readOnly={Boolean(isDefaultContact || Boolean(dropdownContact))}
              />
              <InputSpacer />
              <Input
                label="Title"
                placeholder="Enter title"
                value={title.value()}
                onChange={title.setValue}
                ref={title.setRef}
                error={title.error()}
                inputType={InputTypesEnum.Text}
                disabled={Boolean(dropdownContact)}
              />
              <InputSpacer />
              <Input
                label="Company"
                placeholder="Enter company"
                value={company.value()}
                onChange={company.setValue}
                ref={company.setRef}
                error={company.error()}
                inputType={InputTypesEnum.Text}
                disabled={Boolean(dropdownContact)}
              />
              <InputSpacer />
              <Input
                label="License number"
                placeholder="Enter license number"
                value={licenseNumber.value()}
                onChange={licenseNumber.setValue}
                ref={licenseNumber.setRef}
                error={licenseNumber.error()}
                inputType={InputTypesEnum.Text}
                disabled={Boolean(dropdownContact)}
              />
              <InputSpacer />
              <Dropdown
                label="State of license"
                value={licenseState.value()}
                error={licenseState.error()}
                onChange={(value) => licenseState.setRawValue(value)}
                placeholder="Select a state"
                items={Object.values(AmericanStatesEnum).map((value) => ({
                  name: value,
                  value,
                }))}
                disabled={Boolean(dropdownContact)}
              />
              <InputSpacer />
              <CreateListingContactPhotoUpload setProfileImageUrl={setProfileImageUrl} existingImageUrl={profileImageUrl} disabled={Boolean(dropdownContact)} />
              <Flex justify="flex-end" width="100%" margin="24px 0px 0px 0px">
                <Button
                  text="Cancel"
                  type={ButtonTypesEnum.Ghost}
                  size={ButtonSizesEnum.Medium}
                  onClick={() => popModal()}
                  margin="0px 8px 0px 0px"
                  htmlType="button"
                />
                <Button
                  text={isEdit ? 'Save' : 'Add Contact'}
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Medium}
                  htmlType="submit"
                />
              </Flex>
            </Container>
          </ModalContent>
        </Form>
      </OverflowContainer>
    </ModalContainer>
  );
};

export default AddOrEditListingContactModal;
