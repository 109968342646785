import { logger } from '@biproxi/logger';
import axios from 'axios';
import { IBobberDashboard } from '../models/IBobberInterfaces';
// import { IBobberCsvParsedData, IBobberDashboard } from '../models/IBobberInterfaces';

/** Master Dashboard List */
export const fetchDashboards = async () => {
  let result;
  try {
    const res = await axios.get('/api/bobber/fetch-dashboards');
    result = res;
  } catch (e) {
    logger.error(e as any);
  }
  return result;
};

/** Single Dashboard Fetch */
export const getDashboard = async (_id: string) => {
  let result;
  try {
    const res = await axios.get('/api/bobber/get-dashboard', { params: { _id } });
    result = res;
  } catch (e) {
    logger.error(e as any);
  }
  return result;
};

/** Single CSV data for single dashboard */
export const fetchCsvData = async (_id: string): Promise<IBobberDashboard> => {
  let result;
  try {
    const res = await axios.get('/api/bobber/fetch-csv-data', { params: { _id } });
    result = res?.data;
  } catch (e) {
    logger.error(e as any);
  }
  return result;
};
