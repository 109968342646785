import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import { useNavigate } from 'react-router-dom';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { media } from '../../utils/MediaQuery';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Flex from '../../elements/Flex';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';

const Container = styled.form`
  width: 352px;
  display: flex;
  flex-direction: column;

  ${media.mobile} {
    width: 100%;
  }
`;

export type SaveChangesModalProps = {
  title?: string;
  text?: string;
  listing?: IListingGraphQL;
};

const SaveChangesModal: React.FC<SaveChangesModalProps> = ({
  title = 'Leave page?',
  text = 'Any unsaved changes will be lost. Are you sure you want to continue?',
  listing,
}) => {
  /** Hooks */
  const navigate = useNavigate();

  /** Actions */
  const dispatch = useAppDispatch();

  const errors = useAppSelector(ListingSelectors.errors);

  const updateListing = () => dispatch(ListingActions.updateListing({ listing, toast: true }));
  const updateDraft = () => dispatch(ListingActions.updateDraft({ listing, toast: true }));
  // const updateListingUpload = () => dispatch(ListingActions.updateListingUpload({ listing, toast: true }));
  const resetListingErrors = () => dispatch(ListingActions.resetListingErrors({}));
  const recacheListing = () => dispatch(ListingActions.recacheListing({ listingId: listing._id }));
  // const save = listing.state === ListingStateEnum.Draft ? updateDraft : updateListing;
  let save;
  switch (listing.state) {
    case ListingStateEnum.Draft:
    case ListingStateEnum.Admin:
      save = updateDraft;
      break;
    // case ListingStateEnum.Admin:
    //   save = updateListing;
    //   break;
    default:
      save = updateListing;
      break;
  }

  const saveAnyChanges = () => {
    save();
    popModal();
    /** clear errors if use attempted to publish or update.
     * otherwise, they persist in state and will be displayed on next page load,
     * even for a different listing.
     */
    if (errors) {
      resetListingErrors();
    }
    if (listing.state === ListingStateEnum.Admin) {
      navigate('/app/admin');
    } else {
      navigate('/app/dashboard/listings');
    }
  };

  const discardAnyChanges = () => {
    recacheListing();
    popModal();
    if (errors) {
      resetListingErrors();
    }
    if (listing.state === ListingStateEnum.Admin) {
      navigate('/app/admin');
    } else {
      navigate('/app/dashboard/listings');
    }
  };

  const popModal = () => dispatch(AppActions.popModal());

  /* Render */
  return (
    <ModalContainer>
      <ModalHeader title={title} close={popModal} />
      <ModalContent>
        <Container>
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Grey900}
          >
            {text}
          </Text>
        </Container>
      </ModalContent>
      <ModalFooter>
        <Flex justify="flex-end" width="100%">
          <Button
            text="Don't save"
            data-cy="dont-save-listing-button"
            type={ButtonTypesEnum.Pale}
            size={ButtonSizesEnum.Medium}
            onClick={() => discardAnyChanges()}
            htmlType="button"
            margin="0 8px 0 0"
          />
          <Button
            text="Save & exit"
            data-cy="save-exit-listing-button"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            onClick={() => saveAnyChanges()}
            htmlType="button"
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default SaveChangesModal;
