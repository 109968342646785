import gql from 'graphql-tag';
import { IChannelGraphQL } from '@biproxi/models/interfaces/IChannel';
import { DocumentNode } from 'graphql';
import UserFragments from './user.fragments';
import ListingFragments from './listing.fragments';

// Not a perfect type, but it's good enough for now
type TChannelFragments = {
  [K in keyof IChannelGraphQL]?: DocumentNode;
} & {
  fields: DocumentNode;
}

const ChannelFragments: TChannelFragments = {
  fields: gql`
    fragment ChannelFields on Channel {
      userId
      channelUrl
      leadId
      listingId
      lastMessageText
      lastMessageAt
      hasUnreadMessages
      otherMemberUserIds {
        userId
      }
    }
  `,
  user: gql`
    fragment ChannelUser on Channel {
      user {
        ...UserFields
        ...UserNotificationSettings
        ...UserProfileImageFile
        ...UserOrganization
      }
    }

    ${UserFragments.fields}
    ${UserFragments.notificationSettings}
    ${UserFragments.profileImageFile}
    ${UserFragments.organization}
  `,
  listing: gql`
    fragment ChannelListing on Channel {
      listing {
        ...ListingFields
        ...ListingMedia
        ...ListingAddress
        ...ListingPortfolioAddresses
        ...ListingMeta
      }
    }

    ${ListingFragments.fields}
    ${ListingFragments.media}
    ${ListingFragments.address}
    ${ListingFragments.portfolioAddresses}
    ${ListingFragments.meta}
  `,
};

export default ChannelFragments;
