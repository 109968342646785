import React from 'react';
import styled from '@emotion/styled';
import useOnclickOutside from 'react-cool-onclickoutside';
import Colors from '../styles/Colors';
import Input, { InputSizeEnum, InputThemeEnum, InputTypesEnum } from './Input';
import Icon, { Icons } from './Icon';
import DropdownList, { DropdownListProps } from '../components/DropdownList';
import IAbsolutePositioningConfig from '../models/interfaces/IAbsolutePositioningConfig';

export enum DropdownTypesEnum {
  Search = 'Search',
  Select = 'Select',
}

type ContainerProps = {
  width?: string;
  margin?: string;
  open: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  outline: none;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  z-index: ${({ open }) => (open ? 203 : 100)};
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export type TDropdownItem<TValueType> = {
  name: string;
  value: TValueType;
}

export type TDropdownItems<TValueType> = TDropdownItem<TValueType>[];

type DropdownProps = {
  value: string;
  cyInput?: string;
  cyList?: string;
  type?: DropdownTypesEnum;
  onChange: (value: any) => void;
  clear?: () => void;
  items: TDropdownItems<any>;
  label?: string;
  placeholder?: string;
  error?: string;
  width?: string;
  dropDownListWidth?: string;
  onSearch?: Function;
  margin?: string;
  iconMarginLeft?: string;
  maxDropdownHeight?: string;
  inputSize?: InputSizeEnum;
  inputTheme?: InputThemeEnum;
  ItemComponent?: DropdownListProps['ItemComponent'];
  disabled?: boolean;
  autoFocus?: boolean;
  border?: boolean;
  absolutePositionConfig?: IAbsolutePositioningConfig;
  keepOpen?: boolean; // disable onClick from closing dropdown
  'data-cy'?: string;
};

const Dropdown: React.FC<DropdownProps> = ({
  value,
  cyList,
  cyInput,
  type = DropdownTypesEnum.Select,
  onChange,
  clear,
  onSearch = null,
  items,
  label,
  placeholder,
  error,
  width = 'fill-available',
  margin,
  iconMarginLeft,
  maxDropdownHeight,
  dropDownListWidth,
  inputSize = InputSizeEnum.Regular,
  inputTheme = InputThemeEnum.Light,
  ItemComponent,
  disabled,
  autoFocus = false,
  border = true,
  absolutePositionConfig,
  keepOpen = false,
  'data-cy': dataCy,
}) => {
  const listRef = React.createRef<HTMLInputElement>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>('');
  const inputRef = React.useRef(null);

  const isLowerOffset = Boolean(label) || Boolean(error);

  const ref = useOnclickOutside(() => {
    setOpen(false);
  });

  React.useEffect(() => {
    if (items?.length === 0) {
      setOpen(false);
    }
  }, [items]);

  /** Render */
  return (
    <Container
      onClick={disabled ? null : () => {
        if (items?.length > 0) {
          if (keepOpen && open) {
            return;
          }
          setOpen(!open);
        }
      }}
      tabIndex={0}
      width={width}
      margin={margin}
      ref={ref}
      open={open}
      data-cy={dataCy}
    >
      <InputContainer>
        <Input
          ref={inputRef}
          value={value ?? ''}
          label={label}
          placeholder={placeholder}
          inputType={type === DropdownTypesEnum.Search ? InputTypesEnum.Search : InputTypesEnum.Text}
          inputSize={inputSize}
          inputTheme={inputTheme}
          innerWidth="calc(100% - 48px)" // prevent text overflowing under dropdown icon
          error={error}
          width={width}
          onChange={(e) => {
            if (onSearch) {
              if (!open) setOpen(true);
              onSearch(e);
            }

            /**
             * Always use the last character of
             * the current value as the search
             * character. This limits search terms
             * to the single, most recently pressed
             * character, which is the desired behavior.
             */

            if (type === DropdownTypesEnum.Select) {
              const { value } = e.currentTarget;
              setSearch(value.charAt(value.length - 1));
            }
          }}
          clear={clear}
          disabled={disabled}
          autoFocus={autoFocus}
          noBorder={!border}
          data-cy={cyInput}
          iconMarginLeft={iconMarginLeft}
        />
        {type === DropdownTypesEnum.Select && (
          <Icon
            onClick={disabled ? null : () => {
              inputRef?.current?.focus();
            }}
            icon={Icons.CaretDownSolid}
            position="absolute"
            right="12px"
            width="12px"
            top={isLowerOffset ? '36px' : '16px'}
            size={16}
            color={Colors.Grey700}
            rotation={open ? 180 : null}
          />
        )}
      </InputContainer>
      <DropdownList
        cyList={cyList}
        ref={listRef}
        value={value}
        open={open}
        setOpen={setOpen}
        maxDropdownHeight={maxDropdownHeight}
        onChange={onChange}
        items={items}
        search={search}
        ItemComponent={ItemComponent}
        absolutePositionConfig={absolutePositionConfig}
        width={dropDownListWidth}
      />
    </Container>
  );
};

export default Dropdown;
