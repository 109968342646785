import UserRolesEnum from '../enums/UserRolesEnum';
import IUser from '../interfaces/IUser';

const phoneIsVerified = (user: IUser): boolean => Boolean((user?.phoneNumberVerifiedAt ?? 0) > 0);

const emailIsVerified = (user: IUser): boolean => Boolean((user?.emailVerifiedAt ?? 0) > 0);

const fullName = (user?: IUser): string | null => (user ? `${user.firstName} ${user.lastName}` : null);

const initials = (user?: IUser): string | null => (user ? `${user.firstName.charAt(0)}${user.lastName.charAt(0)}` : null);

const isFavoriteListing = (user: IUser, listingId: string): boolean => user?.favoritedListings?.includes(listingId) ?? false;

// true if "Investor" or "Investor & Broker"
const isInvestor = (user: IUser): boolean => user?.role !== UserRolesEnum.Broker;

// true if "Broker" or "Investor & Broker"
const isBroker = (user: IUser): boolean => user?.role !== UserRolesEnum.Investor;

const hasInvestorProfileFields = (user: IUser, hasAll?: boolean): boolean => {
  const i = user?.investorProfile;
  const investorType = i?.investorType;
  const assets = i?.assetsOwned;
  const prefInvests = (i?.preferredInvestmentTypes?.length ?? 0) > 0;
  const hasGeoFocus = (i?.nationalOrGlobal || (i?.geographicalFocus?.length ? i?.geographicalFocus?.length > 0 : null));
  const targetPrices = (i?.targetPriceRanges?.length ?? 0) > 0;
  const hasTargetAssets = (i?.targetAssetClasses?.length ?? 0) > 0;
  if (hasAll) {
    return Boolean(investorType && assets && prefInvests && hasGeoFocus && targetPrices && hasTargetAssets);
  }
  return Boolean(investorType || assets || prefInvests || hasGeoFocus || targetPrices || hasTargetAssets);
};

const filterPlansBasedOnRole = (user: IUser, plans: any[]): any[] => {
  if (!plans) return [];

  const filteredPlans = plans?.filter((plan) => plan?.item_price?.status === 'active');
  return [...filteredPlans];
};

const UserUtil = {
  phoneIsVerified,
  emailIsVerified,
  fullName,
  initials,
  isFavoriteListing,
  isInvestor,
  isBroker,
  hasInvestorProfileFields,
  filterPlansBasedOnRole,
};

export default UserUtil;
