import DataExplorerDataFrameSectionsEnum from '../enums/DataExplorerDataFrameSectionsEnum';

const DataExplorerDataFrameDemographicsSections = {
  [DataExplorerDataFrameSectionsEnum.HousholdIncome]: [
    {
      item: 'Median Income',
      cherreVariableName: 'median_household_income',
    },
    {
      item: '$0 - 10K',
      cherreVariableName: 'household_income_0_10_count',
    },
    {
      item: '$10 - 15K',
      cherreVariableName: 'household_income_10_15_count',
    },
    {
      item: '$15 - 20K',
      cherreVariableName: 'household_income_15_20_count',
    },
    {
      item: '$20 - 25K',
      cherreVariableName: 'household_income_20_25_count',
    },
    {
      item: '$25 - 30K',
      cherreVariableName: 'household_income_25_30_count',
    },
    {
      item: '$30 - 35K',
      cherreVariableName: 'household_income_30_35_count',
    },
    {
      item: '$35 - 40K',
      cherreVariableName: 'household_income_35_40_count',
    },
    {
      item: '$40 - 45K',
      cherreVariableName: 'household_income_40_45_count',
    },
    {
      item: '$45 - 50K',
      cherreVariableName: 'household_income_45_50_count',
    },
    {
      item: '$50 - 60K',
      cherreVariableName: 'household_income_50_60_count',
    },
    {
      item: '$60 - 75K',
      cherreVariableName: 'household_income_60_75_count',
    },
    {
      item: '$75 - 100K',
      cherreVariableName: 'household_income_75_100_count',
    },
    {
      item: '$100 - 125K',
      cherreVariableName: 'household_income_100_125_count',
    },
    {
      item: '$125 - 150K',
      cherreVariableName: 'household_income_125_150_count',
    },
    {
      item: '$150 - 200K',
      cherreVariableName: 'household_income_150_200_count',
    },
    {
      item: '$200 - 250K',
      cherreVariableName: 'household_income_200_250_count',
    },
    {
      item: '$250 - 300K',
      cherreVariableName: 'household_income_250_300_count',
    },
    {
      item: '$500K+',
      cherreVariableName: 'household_income_over_500_count',
    },
  ],
  [DataExplorerDataFrameSectionsEnum.Crime]: [
    {
      item: 'Total Crime Risk',
      cherreVariableName: 'crime_total_risk',
    },
    {
      item: 'Assault Risk',
      cherreVariableName: 'crime_assault_risk',
    },
    {
      item: 'Burglary Risk',
      cherreVariableName: 'crime_burglary_risk',
    },
    {
      item: 'Larceny Risk',
      cherreVariableName: 'crime_larceny_risk',
    },
    {
      item: 'Murder Risk',
      cherreVariableName: 'crime_murder_risk',
    },
    {
      item: 'Motor Vehicle Risk',
      cherreVariableName: 'crime_motor_vehicle_risk',
    },
    {
      item: 'Personal Risk',
      cherreVariableName: 'crime_personal_risk',
    },
    {
      item: 'Property Risk',
      cherreVariableName: 'crime_property_risk',
    },
    {
      item: 'Rape Risk',
      cherreVariableName: 'crime_rape_risk',
    },
    {
      item: 'Robbery Risk',
      cherreVariableName: 'crime_robbery_risk',
    },
  ],
  [DataExplorerDataFrameSectionsEnum.Population]: [
    {
      item: 'Median Age',
      cherreVariableName: 'population_median_age',
    },
    {
      item: '0 - 4',
      cherreVariableName: 'population_age_00_04_count',
    },
    {
      item: '5 - 9',
      cherreVariableName: 'population_age_05_09_count',
    },
    {
      item: '10 - 14',
      cherreVariableName: 'population_age_10_14_count',
    },
    {
      item: '15 - 19',
      cherreVariableName: 'population_age_15_19_count',
    },
    {
      item: '20 - 24',
      cherreVariableName: 'population_age_20_24_count',
    },
    {
      item: '25 - 29',
      cherreVariableName: 'population_age_25_29_count',
    },
    {
      item: '30 - 34',
      cherreVariableName: 'population_age_30_34_count',
    },
    {
      item: '35 - 39',
      cherreVariableName: 'population_age_35_39_count',
    },
    {
      item: '40 - 44',
      cherreVariableName: 'population_age_40_44_count',
    },
    {
      item: '45 - 49',
      cherreVariableName: 'population_age_45_49_count',
    },
    {
      item: '50 - 54',
      cherreVariableName: 'population_age_50_54_count',
    },
    {
      item: '55 - 59',
      cherreVariableName: 'population_age_55_59_count',
    },
    {
      item: '60 - 64',
      cherreVariableName: 'population_age_60_64_count',
    },
    {
      item: '65 - 69',
      cherreVariableName: 'population_age_65_69_count',
    },
    {
      item: '70 - 74',
      cherreVariableName: 'population_age_70_74_count',
    },
    {
      item: '75 - 79',
      cherreVariableName: 'population_age_75_79_count',
    },
    {
      item: '80 - 84',
      cherreVariableName: 'population_age_80_84_count',
    },
    {
      item: '85+',
      cherreVariableName: 'population_age_over_85_count',
    },
  ],
  [DataExplorerDataFrameSectionsEnum.Emplyoment]: [
    {
      item: 'Business/Finance',
      cherreVariableName: 'employee_business_finance_count',
    },
    {
      item: 'The Arts',
      cherreVariableName: 'employee_arts_count',
    },
    {
      item: 'Cleaning/Maintenance',
      cherreVariableName: 'employee_cleaning_maintenance_count',
    },
    {
      item: 'Admin/Support',
      cherreVariableName: 'employee_admin_support_count',
    },
    {
      item: 'Computes/Math/Science',
      cherreVariableName: 'employee_computer_math_science_count',
    },
    {
      item: 'Construction',
      cherreVariableName: 'employee_construction_count',
    },
    {
      item: 'Social Services',
      cherreVariableName: 'employee_social_services_count',
    },
    {
      item: 'Education',
      cherreVariableName: 'employee_education_count',
    },
    {
      item: 'Architecture/Engineering',
      cherreVariableName: 'employee_architecture_engineering_count',
    },
    {
      item: 'Food Service',
      cherreVariableName: 'employee_food_service_count',
    },
    {
      item: 'Healthcare',
      cherreVariableName: 'employee_healthcare_count',
    },
    {
      item: 'Healthcare Practitioner',
      cherreVariableName: 'employee_healthcare_practitioner_count',
    },
    {
      item: 'Repair',
      cherreVariableName: 'employee_repair_count',
    },
    {
      item: 'Military',
      cherreVariableName: 'employee_military_count',
    },
    {
      item: 'Personal Care',
      cherreVariableName: 'employee_personal_care_count',
    },
    {
      item: 'Farming',
      cherreVariableName: 'employee_farming_count',
    },
    {
      item: 'Production',
      cherreVariableName: 'employee_production_count',
    },
    {
      item: 'Protective Service',
      cherreVariableName: 'employee_protective_service_count',
    },
    {
      item: 'Sales',
      cherreVariableName: 'employee_sales_count',
    },
    {
      item: 'Social Science',
      cherreVariableName: 'employee_social_science_count',
    },
    {
      item: 'Transportation',
      cherreVariableName: 'employee_transportation_count',
    },
    {
      item: 'Executive',
      cherreVariableName: 'employee_executive_count',
    },
    {
      item: 'Unclassified',
      cherreVariableName: 'employee_unclassified_count',
    },
  ],
  [DataExplorerDataFrameSectionsEnum.Education]: [
    {
      item: 'Less than 9 years',
      cherreVariableName: 'education_less_than_9_count',
    },
    {
      item: 'Some high school',
      cherreVariableName: 'education_some_high_school_count',
    },
    {
      item: 'High school',
      cherreVariableName: 'education_high_school_graduate_count',
    },
    {
      item: 'Some college',
      cherreVariableName: 'education_some_college_count',
    },
    {
      item: 'Associate\'s degree',
      cherreVariableName: 'education_associate_degree_count',
    },
    {
      item: 'Bachelor\'s degree',
      cherreVariableName: 'education_bachelors_degree_count',
    },
    {
      item: 'Graduate degree',
      cherreVariableName: 'education_graduate_degree_count',
    },
  ],
};

export default DataExplorerDataFrameDemographicsSections;
