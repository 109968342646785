/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { logger } from '@biproxi/logger';
import StatusCodeEnum from '@biproxi/models/enums/StatusCodeEnum';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import BillingPlanIdEnum from '@biproxi/models/enums/BillingPlanIdEnum';
import axios from 'axios';

export const getOrganizationUsers = async (params: INeo4jServiceAPI.IGetOrganizationUsersParams) => {
  const res = await axios.get('/api/neo4j/getOrganizationUsers', {
    params: { ...params },
  });
  const result = await res.data;
  return result;
};

export const getUser = async (userQueryParams: object) => {
  const res = await axios.get('/api/neo4j/getUser', {
    params: { userQueryParams },
  });
  const result = res.data;
  return result;
};

export const getUserListingsAndOrganizations = async (userQueryParams: object) => {
  const res = await axios.get('/api/neo4j/getUserListingsAndOrgs', {
    params: { userQueryParams },
  });
  const result = await res.data;
  return result;
};

export const getUserOrganizations = async (params: INeo4jServiceAPI.IGetUserOrganizationParams) => {
  const res = await axios.get('/api/neo4j/getUserOrganizations', {
    params: { ...params },
  });
  const result = await res.data;
  return result;
};

export const getOrganization = async (params: INeo4jServiceAPI.IGetOrganizationParams) => {
  const result = {
    data: null as any,
    status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
  };
  try {
    const res = await axios.get('/api/neo4j/getOrganization', {
      params,
    });
    result.status = res?.status;
    result.data = res?.data;
  } catch (e) {
    logger.warn(`There was an issue with the getOrganization query: ${e}`);
  }
  return result;
};

export const getOrganizationListings = async (params: INeo4jServiceAPI.IGetOrganizationListingsParams, dateRange?: string) => {
  const result: any = {
    data: null,
    status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
  };
  try {
    logger.info('>>>> Neo4j organization listings query....');
    const startTime = new Date().getTime();

    const res = await axios.get('/api/neo4j/getOrganizationListings', {
      params: { ...params },
    });
    const endTime = new Date().getTime();
    const deltaTime = endTime - startTime;
    logger.info(`<<<< 🕔 Neo4j organization listings query resolved: ~${deltaTime} ms`);
    const listingEdges = res?.data?.data;
    result.status = res?.status;
    result.data = listingEdges;
  } catch (e) {
    logger.warn(`There was an issue with the getOrganizationListings query: ${e}`);
  }

  /** Get analytics associated with listings */
  try {
    const listings = result?.data?.map((listingData: any) => listingData);
    const listingIds = listings?.map((listing: INeo4jServiceAPI.INeo4jListing) => listing?._id);
    const cubeData = await axios.get('/api/cube/getListingOverviewAnalytics', {
      params: {
        params: {
          listingId: listingIds,
          listingUserId: '',
          dateRange,
          segmentByListing: true,
        },
      },
    });
    const analyticsArr = Object?.entries(cubeData?.data?.overviewAnalyticsByListingId)?.map(([key, value]) => ({ [key]: value }));
    result.data = result?.data?.reduce((acc: any, curr: INeo4jServiceAPI.INeo4jListing) => {
      const listingAnalytics: any = analyticsArr?.find((analytic: any) => Object?.keys(analytic)?.[0] === curr?._id);
      return ([
        ...acc,
        { ...curr, ...{ analytics: [...Object?.values(listingAnalytics)]?.[0] } },
      ]);
    }, []);
  } catch (error) {
    logger.warn(`There was an issue fetching Cube data within the getOrganizationListings query: ${error}`);
  }
  return result;
};

export const getUserOrganizationsAndRoles = async (params: INeo4jServiceAPI.IGetUserOrganizationParams) => {
  const result = {
    data: null,
    userSubscriptionPlan: BillingPlanIdEnum.FreemiumMonthly,
    status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
  };
  try {
    const res = await axios.get('/api/neo4j/getUserOrganizationsAndRoles', {
      params,
    });
    result.data = res?.data;
    result.status = res?.status;

    const chargebeeRes = await axios.get(`/api/billing/subscription?id=${params?.userId}`);
    const customerSubscriptions = chargebeeRes?.data;
    result.userSubscriptionPlan = customerSubscriptions;
  } catch (e) {
    logger.warn(`There was an issue with the getUserOrganizationsAndRoles query: ${e}`);
  }

  return result;
};

export const getUserOrgsAndPlans = async (params: INeo4jServiceAPI.IGetUserOrganizationParams) => {
  const res = await axios.get('/api/neo4j/getUserOrgsAndPlans', {
    params: { ...params },
  });
  const result = await res.data;
  return result;
};
