import React from 'react';
import styled from '@emotion/styled';
import Icon, { Icons } from './Icon';
import Colors from '../styles/Colors';
import Text, { TextAsEnum, TextTypesEnum } from './Text';
import BoxShadows from '../styles/BoxShadows';

const Container = styled.div<{margin: string, disabled: boolean}>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin};
  width: fit-content;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : null)};

  &:hover {
    #BoxId {
      cursor: pointer;
      box-shadow: ${BoxShadows.Darker};
    }

    #CheckboxTextId {
      cursor: pointer;
    }
  }
`;

export enum CheckboxTypesEnum {
  Small = 'Small',
  Large = 'Large',
}

const Box = styled.div<CheckboxProps>`
  height: ${(props) => (props.type === CheckboxTypesEnum.Small ? '16px' : '24px')};
  width: ${(props) => (props.type === CheckboxTypesEnum.Small ? '16px' : '24px')};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
  border-radius: 2px;
  background-color: ${(props) => {
    if (props.disabled) return Colors.Grey100;
    if (props.active) return Colors.Brand700 || Colors.Blurple700;
    return null;
  }};
  border: ${(props) => (props.active ? null : props.type === CheckboxTypesEnum.Small ? `1px solid ${Colors.Grey400}` : `2px solid ${Colors.Grey400}`)};
  box-sizing: border-box;
  margin-right: 8px;
`;

type CheckboxProps = {
  active: boolean;
  dataCy?: string;
  onClick?: () => void;
  label?: string | React.ReactChild;
  margin?: string;
  labelTextType?: TextTypesEnum;
  type?: CheckboxTypesEnum;
  disabled?: boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({
  active,
  dataCy,
  onClick,
  label,
  margin,
  type = CheckboxTypesEnum.Small,
  labelTextType = TextTypesEnum.Medium14,
  disabled = false,
}) => (
  <Container
    margin={margin}
    onClick={() => onClick?.()}
    disabled={disabled}
  >
    <Box id="BoxId" active={active} disabled={disabled} type={type} data-cy={dataCy}>
      {active && <Icon icon={Icons.CheckSolid} color={Colors.White} size={10} />}
    </Box>
    {label && <Text id="CheckboxTextId" type={labelTextType} as={TextAsEnum.Label}>{label}</Text>}
  </Container>
);
export default Checkbox;
