import gql from 'graphql-tag';

const CREATE_LISTING = gql`
  mutation CreateOffer($params: OfferParams!) {
    createOffer(params: $params) {
      _id
    }
  }
`;

export default CREATE_LISTING;
