import React from 'react';
import styled from '@emotion/styled';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import { useQuery } from '@apollo/client';
import { useMutation as rqMutation } from 'react-query';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import IListingQuery from '@biproxi/models/interfaces/IListingQuery';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import { addListingsToOrganization } from '@biproxi/next-api-requests';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { media } from '../../utils/MediaQuery';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Icon, { Icons } from '../../elements/Icon';
import Dropdown from '../../elements/Dropdown';
import LIST_LISTINGS from '../../graphql/queries/listings.query';
import useUser from '../../hooks/useUser.hook';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { ModalTypesEnum } from './Modal';
import { ConfirmChangeModalTypesEnum } from './ConfirmChangeModal';
import Loader from '../../elements/Loader';

const Container = styled.div`
  width: 632px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;

  ${media.mobile} {
    width: 100%;
  }
`;

export type AddListingsToTeamModalProps = {
  organizationId: string
  teamListings: any
  refetchListings: any
}

const AddListingsToTeamModal: React.FC<AddListingsToTeamModalProps> = ({
  organizationId,
  teamListings,
  refetchListings,
}) => {
  /** State */
  const [listingsLoading, setListingsLoading] = React.useState<boolean>(true);
  const [userListings, setUserListings] = React.useState<IListingGraphQL[]>([]);
  const [selectedListingIds, setSelectedListingIds] = React.useState<string[]>([]);
  const [selectedListingNames, setSelectedListingNames] = React.useState<string[]>([]);

  /** Hooks */
  const user = useUser();

  /** React Query */
  const { mutate: handleAddListingsToOrganization, isLoading } = rqMutation((params: INeo4jServiceAPI.IAddListingsToOrganizationParams) => addListingsToOrganization(params), {
    onSuccess: () => handleAddListingsToOrganizationSuccess(),
    onError: (err) => handleAddListingsToOrganizationError(err),
  });

  /** GraphQL */
  type Data = {
    listings: {
      data: IListingGraphQL[],
    }
  }

  type Vars = {
    params: {
      query: IListingQuery
    }
  }

  useQuery<Data, Vars>(LIST_LISTINGS, {
    skip: Boolean(userListings?.length) || !user?.userId,
    variables: {
      params: {
        query: {
          queryType: ListingQueryTypesEnum.Personal,
          brokerUserId: user.userId,
        },
      },
    },
    onCompleted: (data) => {
      const { listings: { data: listingData } } = data;
      const teamListingIds: string[] = teamListings?.map((listing) => listing?._id);
      setUserListings(listingData?.filter((listing) => !teamListingIds?.includes(listing?._id))); // filter out listings that are already added to the team.
      setListingsLoading(false);
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushListingAddedConfirmationModal = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Success,
        title: 'Your listings have been added',
        text: 'Your listings are now associated with your team.',
        confirm: () => popModal(),
      },
    }),
  );

  /** Functions */
  const handleAddListingsToOrganizationSuccess = () => {
    refetchListings();
    pushListingAddedConfirmationModal();
  };

  const handleAddListingsToOrganizationError = (error) => {
    pushToast({
      type: ToastTypesEnum.Error,
      message: error,
    });
  };

  return (
    <ModalContainer
      data-cy="add-listings-to-team-modal"
    >
      <ModalHeader
        title="Add Listings"
        close={popModal}
      />
      <ModalContent>
        {listingsLoading ? (
          <Flex
            width="600px"
            justify="center"
            align="center"
          >
            <Loader
              color={Colors.Blurple700}
            />
          </Flex>
        ) : (
          <Container>
            <Dropdown
              label="Listings"
              placeholder="Add listings to team"
              items={userListings?.map((listing: IListingGraphQL) => ({
                name: ListingUtil.name(listing, { allButAddress1: true }) || '',
                value: listing,
              }))}
              value={selectedListingNames?.[selectedListingNames?.length ?? '']}
              disabled={listingsLoading}
              onChange={(value: IListingGraphQL) => {
                if (!selectedListingIds.includes(value._id)) {
                  setSelectedListingNames([...selectedListingNames, ListingUtil.name(value, { allButAddress1: true })]);
                  setSelectedListingIds([...selectedListingIds, value._id]);
                }
              }}
              data-cy="add-listings-dropdown"
            />
            {
            Boolean(selectedListingNames?.length) && (
              <Flex
                direction="column"
                width="100%"
                dataCy="selected-listings"
              >
                {selectedListingNames?.map((listingName: string, index) => (
                  <Flex
                    width="100%"
                    justify="space-between"
                    key={index}
                  >
                    <Text
                      type={TextTypesEnum.Regular14}
                      color={Colors.Brand700 || Colors.Blurple700}
                    >
                      {listingName}
                    </Text>
                    <Icon
                      icon={Icons.TimesRegular}
                      color={Colors.Brand700 || Colors.Blurple700}
                      onClick={() => {
                        setSelectedListingNames(selectedListingNames.filter((filterListingName) => filterListingName !== listingName));
                      }}
                    />
                  </Flex>
                ))}
              </Flex>
            )
          }
            <Flex
              width="100%"
              gap="12px"
              justify="flex-end"
            >
              <Button
                text="Cancel"
                size={ButtonSizesEnum.Small}
                type={ButtonTypesEnum.Outline}
                onClick={popModal}
              />
              <Button
                text="Add Listings"
                size={ButtonSizesEnum.Small}
                type={ButtonTypesEnum.Primary}
                isLoading={isLoading}
                onClick={() => {
                  const mutationParams: INeo4jServiceAPI.IAddListingsToOrganizationParams = {
                    listingIds: selectedListingIds,
                    organizationId,
                  };
                  handleAddListingsToOrganization(mutationParams);
                }}
                disabled={!selectedListingIds?.length}
                data-cy="add-listing-button"
              />
            </Flex>
          </Container>
        )}

      </ModalContent>
    </ModalContainer>
  );
};

export default AddListingsToTeamModal;
