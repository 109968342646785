import React from 'react';
import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone';
import { GraphQLFileUpload } from '@biproxi/models/interfaces/IFileParams';
import { IChannelGraphQL } from '@biproxi/models/interfaces/IChannel';
import Colors from '../styles/Colors';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';
import SendBirdClientSDK from '../utils/SendBirdClientSDK';
import { useAppDispatch } from '../redux/store';
import { ChatActions } from '../redux/chat.redux';
import TextAreaWithButton from '../elements/TextAreaWithButton';

const SendButton = styled.button`
  background: ${Colors.Brand700 || Colors.Blurple700};
  border-radius: 4px;
  height: 32px;
  width: 32px;
  outline: none;
  border: none;
  cursor: pointer;
`;

type ChatInputProps = {
  value: string;
  onChange: (e: any) => void
  onEnter: (e: React.KeyboardEvent<HTMLTextAreaElement>) => Promise<void>;
  channel: IChannelGraphQL
  error?: string;
};

const ChatInput: React.FC<ChatInputProps> = ({
  value,
  onChange,
  onEnter,
  channel,
  error,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();
  const setNewMessage = (channel, message) => dispatch(
    ChatActions.setNewMessageReceived({ channel, message }),
  );

  /* Hooks */
  const onDrop: any = async (browserFiles: GraphQLFileUpload[]) => {
    const res = await SendBirdClientSDK.sendFileMessage(browserFiles[0], channel.channelUrl);

    const updatedChannel = {
      channelUrl: res.channelUrl,
      hasUnreadMessages: false,
      lastMessageAt: res.createdAt,
      lastMessageText: res.message,
      listingId: channel.listingId,
    };

    const cleanedMessage = {
      channelUrl: res.channelUrl,
      messageId: res.messageId,
      sender: {
        userId: res.sender.userId,
      },
      createdAt: res.createdAt,
      message: 'File message sent',
    };

    setNewMessage(updatedChannel, cleanedMessage);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const SendMessageButton = (
    <>
      {value ? (
        <SendButton
          type="submit"
        >
          <Icon
            icon={Icons.PaperPlaneRegular}
            color={Colors.White}
            size={14}
          />
        </SendButton>
      ) : (
        <SendButton
          type="button"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Icon
            icon={Icons.PaperclipRegular}
            color={Colors.White}
            size={14}
          />
        </SendButton>
      )}
    </>
  );

  return (
    <TextAreaWithButton
      data-cy="custom-chat-layout-input"
      value={value}
      onChange={onChange}
      onEnter={onEnter}
      SendButton={SendMessageButton}
      placeholder="Type your message here"
      error={error}
    />
  );
};

export default ChatInput;
