import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import * as UserJoi from '@biproxi/models/joi/user.joi';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { config } from '@biproxi/env';
import ReCAPTCHA from 'react-google-recaptcha';
import useVerifyPhoneNumber from '../../hooks/useVerifyPhoneNumber.hook';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import UPDATE_USER_PHONE_NUMBER from '../../graphql/mutations/updateUserPhoneNumber.mutation';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import { ModalTypesEnum } from './Modal';
import { media } from '../../utils/MediaQuery';
import Input, { InputTypesEnum } from '../../elements/Input';

const Container = styled.div`
  width: 432px;

  ${media.mobile} {
    width: 100%;
  }
`;

const Form = styled.form``;

export type ChangePhoneNumberModalProps = {};

const ChangePhoneNumberModal: React.FC<ChangePhoneNumberModalProps> = () => {
  /** State */
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  /** Hooks */
  const formParams: UseFormParams = {
    fields: {
      phoneNumber: '',
    },
    fieldOrder: ['/phoneNumber'],
    schema: UserJoi.updateUserPhoneNumberParamsSchema,
  };

  const {
    controllers: {
      phoneNumber,
    },
    setFieldErrors,
    isValid,
  } = useForm(formParams);

  const {
    // loading,
    startRecaptcha,
    sendPhoneNumberVerificationSMS,
  } = useVerifyPhoneNumber({
    recaptchaRef,
    setFieldErrors,
    setError: (message) => phoneNumber.setError(message),
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const replaceModalWithChangePhoneNumberCode = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ChangePhoneNumberCode,
      props: {
        phoneNumber: phoneNumber.value().toString(),
      },
    }),
  );

  const handleSubmit = async (event?: React.FormEvent) => {
    if (event) event.preventDefault();
    if (!isValid()) return;
    await updateUserPhoneNumber({
      variables: {
        params: {
          phoneNumber: phoneNumber.value().toString(),
        },
      },
    });
  };

  /** GraphQL */
  const [updateUserPhoneNumber, { loading }] = useMutation(UPDATE_USER_PHONE_NUMBER, {
    onCompleted: async () => {
      const token = await startRecaptcha();

      if (!token) return;

      const smsSuccess = await sendPhoneNumberVerificationSMS(token);

      if (!smsSuccess) return;

      replaceModalWithChangePhoneNumberCode();
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        phoneNumber.setError(message);
      }
    },
  });

  /* Render */
  return (
    <>
      <ModalContainer>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <ModalHeader title="Change phone number" close={popModal} />
          <ModalContent>
            <Container>
              <Input
                label="Phone number"
                placeholder="(XXX) XXX-XXXX"
                onChange={phoneNumber.setValue}
                autoFocus
                ref={phoneNumber.setRef}
                inputType={InputTypesEnum.PhoneNumber}
                error={phoneNumber.error()}
              />
            </Container>
          </ModalContent>
          <ModalFooter>
            <Flex justify="flex-end" width="100%">
              <Button
                text="Cancel"
                type={ButtonTypesEnum.Ghost}
                size={ButtonSizesEnum.Medium}
                onClick={() => popModal()}
                margin="0px 8px 0px 0px"
                htmlType="button"
              />
              <Button
                text="Change number"
                isLoading={loading}
                type={ButtonTypesEnum.Primary}
                size={ButtonSizesEnum.Medium}
                htmlType="submit"
              />
            </Flex>
          </ModalFooter>
        </Form>
      </ModalContainer>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={config.NEXT_PUBLIC_FIREBASE_RECAPTCHA_SITE_KEY}
      />
    </>
  );
};

export default ChangePhoneNumberModal;
