import gql from 'graphql-tag';
import ChannelFragments from '../fragments/channel.fragments';

const LIST_CHANNELS = gql`
  query Channels($params: ListChannelsParams)  {
    channels(params: $params) {
      ...ChannelFields
      ...ChannelUser
      ...ChannelListing
    }
  }

  ${ChannelFragments.fields}
  ${ChannelFragments.user}
  ${ChannelFragments.listing}
`;

export default LIST_CHANNELS;
