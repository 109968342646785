import React from 'react';
import styled from '@emotion/styled';
import IListingQuery from '@biproxi/models/interfaces/IListingQuery';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import ListingAssetClassEnum from '@biproxi/models/enums/ListingAssetClassEnum';
import ListingPropertyTypeEnum from '@biproxi/models/enums/ListingPropertyTypeEnum';
import IMinMax from '@biproxi/models/interfaces/IMinMax';
import IListingQueryMiscellaneous from '@biproxi/models/interfaces/IListingQueryMiscellaneous';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Colors from '../../styles/Colors';
import Flex from '../../elements/Flex';
import Text, { TextTypesEnum } from '../../elements/Text';
import AssetClassAndPropertyTypeFilter from '../AssetClassAndPropertyTypeFilter';
import KeywordFilter from '../KeywordFilter';
import PriceFilter from '../PriceFilter';
import Button, { ButtonSizesEnum } from '../../elements/Button';
import Divider from '../../elements/Divider';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';
import { useMobileMedia } from '../../utils/MediaQuery';
import Checkbox from '../../elements/Checkbox';
import ListingFilterInputSection from '../ListingFilterInputSection';
import { InputTypesEnum } from '../../elements/Input';

const PropertyTypeContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const KeywordsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const MiscellaneousContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface IListingFilterInputSection {
  title: string;
  inputType?: InputTypesEnum;
  minValue: number | string;
  maxValue: number | string;
  minLabel?: string;
  maxLabel?: string;
  setMinValue: (value: number | string) => void;
  setMaxValue: (value: number | string) => void;
}

export type ListingFiltersModalProps = {}

const ListingFiltersModal: React.FC<ListingFiltersModalProps> = () => {
  /** State */
  const {
    assetClasses,
    propertyTypes,
    keywords,
    price,
    buildingSize,
    capRate,
    occupancyRate,
    miscellaneous,
  }: {
    assetClasses?: ListingAssetClassEnum[];
    propertyTypes?: ListingPropertyTypeEnum[];
    keywords?: string[];
    price?: IMinMax;
    buildingSize?: IMinMax;
    capRate?: IMinMax;
    occupancyRate?: IMinMax;
    miscellaneous?: IListingQueryMiscellaneous
  } = useAppSelector(ListingSelectors.searchQuery);

  /** State */
  const [selectedAssetClasses, setSelectedAssetClasses] = React.useState<ListingAssetClassEnum[]>(assetClasses ?? []);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = React.useState<ListingPropertyTypeEnum[]>(propertyTypes ?? []);
  const [selectedKeywords, setSelectedKeywords] = React.useState<string[]>(keywords ?? []);
  const [minPrice, setMinPrice] = React.useState<number>(price?.min ?? null);
  const [maxPrice, setMaxPrice] = React.useState<number>(price?.max ?? null);
  const [showUnpriced, setShowUnpriced] = React.useState<boolean>(price?.includeUndefined ?? true);
  const [minBuildingSize, setMinBuildingSize] = React.useState<number>(buildingSize?.min ?? null);
  const [maxBuildingSize, setMaxBuildingSize] = React.useState<number>(buildingSize?.max ?? null);
  const [minCapRate, setMinCapRate] = React.useState<number>(capRate?.min ?? null);
  const [maxCapRate, setMaxCapRate] = React.useState<number>(capRate?.max ?? null);
  const [minOccupancyRate, setMinOccupancyRate] = React.useState<number>(occupancyRate?.min ?? null);
  const [maxOccupancyRate, setMaxOccupancyRate] = React.useState<number>(occupancyRate?.max ?? null);
  const [brokerCoOpAvailable, setBrokerCoOpAvailable] = React.useState<boolean>(miscellaneous?.brokerCoOpAvailable ?? false);
  const [netLease, setNetLease] = React.useState<boolean>(miscellaneous?.netLease ?? false);
  const [creditTenant, setCreditTenant] = React.useState<boolean>(miscellaneous?.creditTenant ?? false);
  const [offMarket, setOffMarket] = React.useState<boolean>(miscellaneous?.offMarket ?? false);
  const [forSaleByOwner, setForSaleByOwner] = React.useState<boolean>(miscellaneous?.forSaleByOwner ?? false);
  const [isPortfolio, setIsPortfolio] = React.useState<boolean>(miscellaneous?.isPortfolio ?? false);

  const listingFilterSections: IListingFilterInputSection[] = [
    {
      title: 'Building Size',
      minValue: minBuildingSize,
      maxValue: maxBuildingSize,
      minLabel: 'Min sqft',
      maxLabel: 'Max sqft',
      setMinValue: (value: number) => setMinBuildingSize(value),
      setMaxValue: (value: number) => setMaxBuildingSize(value),
    },
    {
      title: 'Cap Rate',
      inputType: InputTypesEnum.Percentage,
      minValue: minCapRate,
      maxValue: maxCapRate,
      setMinValue: (value: number) => setMinCapRate(value),
      setMaxValue: (value: number) => setMaxCapRate(value),
    },
    {
      title: 'Occupancy Rate',
      inputType: InputTypesEnum.Percentage,
      minValue: minOccupancyRate,
      maxValue: maxOccupancyRate,
      setMinValue: (value: number) => setMinOccupancyRate(value),
      setMaxValue: (value: number) => setMaxOccupancyRate(value),
    },
  ];

  /** Hooks */
  const isMobile = useMobileMedia();

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const setListingSearchQuery = (query: IListingQuery) => dispatch(
    ListingActions.setListingSearchQuery(query),
  );

  return (
    <ModalContainer
      data-cy="main-filter-modal"
      width={isMobile ? '100vw' : null}
      height="calc(100% - 32px)"
      overflow="auto"
    >
      {' '}
      <ModalHeader
        title="Filters"
        closeCy="main-filter-close-button"
        close={() => popModal()}
      />
      <ModalContent height="fit-content">
        <PropertyTypeContainer>
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Grey900}
            margin="0 0 16px 0"
          >
            Property Type
          </Text>
          <AssetClassAndPropertyTypeFilter
            externalAssetClasses={selectedAssetClasses}
            externalPropertyTypes={selectedPropertyTypes}
            setExternalAssetClassState={setSelectedAssetClasses}
            setExternalPropertyTypeState={setSelectedPropertyTypes}
            column={isMobile}
          />
        </PropertyTypeContainer>
        <KeywordsContainer>
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Grey900}
            margin="16px 0 8px 0"
          >
            Keywords
          </Text>
          <KeywordFilter
            values={selectedKeywords}
            setKeywordState={setSelectedKeywords}
          />
        </KeywordsContainer>
        <Divider margin="16px 0" />
        <PriceContainer>
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Grey900}
            margin="0 0 8px 0"
          >
            Price
          </Text>
          <PriceFilter
            minValue={minPrice}
            maxValue={maxPrice}
            checkboxActive={showUnpriced}
            onMinChange={(_event, value) => setMinPrice(value)}
            onMaxChange={(_event, value) => setMaxPrice(value)}
            onCheckboxClick={() => setShowUnpriced(!showUnpriced)}
          />
        </PriceContainer>
        <Divider margin="16px 0" />
        <>
          {listingFilterSections.map((section, index) => (
            <>
              <ListingFilterInputSection
                key={index}
                sectionTitle={section.title}
                inputType={section.inputType}
                minValue={section.minValue}
                maxValue={section.maxValue}
                minLabel={section.minLabel}
                maxLabel={section.maxLabel}
                setMinValue={section.setMinValue}
                setMaxValue={section.setMaxValue}
              />
              <Divider margin="16px 0" />
            </>
          ))}
        </>
        <MiscellaneousContainer>
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Grey900}
            margin="0 0 8px 0"
          >
            Miscellaneous
          </Text>
          <Flex direction="column">
            <Flex direction={isMobile ? 'column' : 'row'}>
              <Checkbox
                label="Broker Co-Op Available"
                active={brokerCoOpAvailable}
                onClick={() => setBrokerCoOpAvailable(!brokerCoOpAvailable)}
                margin={isMobile ? '0 16px 0 0' : '0 32px 0 0'}
              />
              <Checkbox
                label="Net Lease"
                active={netLease}
                onClick={() => setNetLease(!netLease)}
                margin={isMobile ? '0 16px 0 0' : '0 32px 0 0'}
              />
              <Checkbox
                label="Credit Tenant"
                active={creditTenant}
                onClick={() => setCreditTenant(!creditTenant)}
                margin={isMobile ? '0 16px 0 0' : '0 32px 0 0'}
              />
            </Flex>
            <Flex margin={isMobile ? null : '16px 0 0'} direction={isMobile ? 'column' : 'row'}>
              <Checkbox
                label="Off Market"
                active={offMarket}
                onClick={() => setOffMarket(!offMarket)}
                margin={isMobile ? '0 16px 0 0' : '0 32px 0 0'}
              />
              <Checkbox
                label="For Sale By Owner"
                active={forSaleByOwner}
                onClick={() => setForSaleByOwner(!forSaleByOwner)}
                margin={isMobile ? '0 16px 0 0' : '0 32px 0 0'}
              />
              <Checkbox
                label="Portfolio"
                active={isPortfolio}
                onClick={() => setIsPortfolio(!isPortfolio)}
                margin={isMobile ? '0 16px 0 0' : '0 32px 0 0'}
              />
            </Flex>
          </Flex>
        </MiscellaneousContainer>
        <Divider margin="16px 0" />
        <Flex justify="space-between" align="center">
          <Button
            text="Apply"
            size={ButtonSizesEnum.Medium}
            onClick={() => {
              const query: IListingQuery = {
                queryType: ListingQueryTypesEnum.Active,
                assetClasses: selectedAssetClasses,
                propertyTypes: selectedPropertyTypes,
                price: {
                  min: minPrice,
                  max: maxPrice,
                  includeUndefined: showUnpriced,
                },
                buildingSize: {
                  min: minBuildingSize,
                  max: maxBuildingSize,
                },
                capRate: {
                  min: minCapRate,
                  max: maxCapRate,
                },
                occupancyRate: {
                  min: minOccupancyRate,
                  max: maxOccupancyRate,
                },
                miscellaneous: {
                  brokerCoOpAvailable,
                  netLease,
                  creditTenant,
                  offMarket,
                  forSaleByOwner,
                  isPortfolio,
                },
                keywords: selectedKeywords,
              };
              setListingSearchQuery(query);
              popModal();
            }}
          />
        </Flex>
      </ModalContent>
    </ModalContainer>
  );
};

export default ListingFiltersModal;
