import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import PrivateEventUtil from '@biproxi/models/utils/PrivateEventUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Divider from '../../elements/Divider';
import UserInfo, { UserInfoTypesEnum } from '../UserInfo';
import { media } from '../../utils/MediaQuery';
import usePrivateEventHook from '../../hooks/usePrivateEvent.hook';
import Flex from '../../elements/Flex';
import Loader, { LoaderSizes } from '../../elements/Loader';
import NoContent from '../NoContent';
import Icons from '../../elements/Icons';
import BidOverviewLeadModalStatItem from '../private-event/BidOverviewLeadModalStatItem';

const Container = styled.div`
  width: 100%;
  height: 65vh;
  overflow: auto;
  padding-right: 24px;

  ${media.mobile} {
    height: calc(100vh - 135px);
  }
`;

export type LeadModalBidsProps = {
  lead: ILeadGraphQL;
};

const LeadModalBids: React.FC<LeadModalBidsProps> = ({
  lead,
}) => {
  /** Hooks */
  const { privateEvent, loading } = usePrivateEventHook();
  // highest bids at the top
  const bids = [...PrivateEventUtil.getAllUserBids(lead?.userId, privateEvent)]?.reverse();

  /** Render */
  if (loading) {
    return (
      <Flex height="100%" align="center" justify="center">
        <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
      </Flex>
    );
  }
  return (
    <Container>
      <UserInfo user={lead.user} type={UserInfoTypesEnum.Large} links />
      <Divider color={Colors.Grey200} margin="16px 0" />
      <Text type={TextTypesEnum.Bold16} color={Colors.Black} margin="0 0 8px">
        {`${lead?.user?.firstName}'s`}
        {' '}
        bid overview
      </Text>
      <Flex margin="0 0 8px" justify="space-between">
        <Text type={TextTypesEnum.Regular16} color={Colors.Grey500}>Highest bid</Text>
        <Text type={TextTypesEnum.Regular16} color={Colors.Black}>{StringUtil.formatNumber(PrivateEventUtil.getUsersTopBid(lead?.userId, privateEvent)?.totalBidAmount || 0)}</Text>
      </Flex>
      <Flex margin="0 0 8px" justify="space-between">
        <Text type={TextTypesEnum.Regular16} color={Colors.Grey500}>Total bids</Text>
        <Text type={TextTypesEnum.Regular16} color={Colors.Black}>{bids?.length || 0}</Text>
      </Flex>
      <Divider color={Colors.Grey200} margin="16px 0" />
      <Text type={TextTypesEnum.Bold16} color={Colors.Black} margin="0 0 8px">Activity</Text>
      {bids?.length > 0 ? (
        <>
          {bids.map((bid, index) => (
            <BidOverviewLeadModalStatItem
              key={bid._id}
              bid={bid}
              isLast={index === bids?.length - 1}
            />
          ))}
        </>
      ) : (
        <NoContent
          height="200px"
          icon={Icons.EnvelopeOpenDollarLight}
          text="This user has not placed a bid yet."
          padding="0 20px"
        />
      )}
    </Container>
  );
};

export default LeadModalBids;
