import { print } from 'graphql';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import GET_ORGANIZATION_USERS from '../../graphql/queries/organizationQueries/organizationUsers.query';
import GET_USER_ORGANIZATIONS from '../../graphql/queries/organizationQueries/userOrganizations.query';
import GET_USER_ORGANIZATIONS_AND_ROLES from '../../graphql/queries/organizationQueries/userOrganizationsAndRoles.query';
import GET_USER from '../../graphql/queries/userQueries/getUser.query';
import GET_ORGANIZATION_LISTINGS from '../../graphql/queries/organizationQueries/organizationListings.query';
import GET_ORGANIZATION from '../../graphql/queries/organizationQueries/organization.query';
import GET_USER_BY_EMAIL from '../../graphql/queries/userQueries/getUserByEmail.query';
import GET_LISTING from '../../graphql/queries/listingQueries/listing.query';

function getUser(request: INeo4jServiceAPI.IGetUserRequest) {
  const { params } : { params: INeo4jServiceAPI.IGetUserParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(GET_USER),
      variables: {
        userWhere: {
          _id: params.userId,
        },
      },
    },
  };
  return graphqlRequestBody;
}

function getUserByEmail(request: INeo4jServiceAPI.IGetUserByEmailRequest) {
  const { params } : { params: INeo4jServiceAPI.IGetUserByEmailParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(GET_USER_BY_EMAIL),
      variables: {
        userWhere: {
          email: params.userEmail,
        },
      },
    },
  };
  return graphqlRequestBody;
}

function getListing(request: INeo4jServiceAPI.IGetListingRequest) {
  const { params } : { params: INeo4jServiceAPI.IGetListingParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(GET_LISTING),
      variables: {
        where: {
          _id: params._id,
        },
      },
    },
  };
  return graphqlRequestBody;
}

function getOrganization(request: INeo4jServiceAPI.IGetOrganizationRequest) {
  const { params } : { params: INeo4jServiceAPI.IGetOrganizationParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(GET_ORGANIZATION),
      variables: {
        where: {
          _id: params.organizationId,
        },
      },
    },
  };
  return graphqlRequestBody;
}

function getUserOrganizations(request: INeo4jServiceAPI.IGetUserOrganizationRequest) {
  const { params } : { params: INeo4jServiceAPI.IGetUserOrganizationParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(GET_USER_ORGANIZATIONS),
      variables: {
        where: {
          usersConnection_SINGLE: {
            node: {
              _id: params.userId,
            },
          },
        },
      },
    },
  };
  return graphqlRequestBody;
}

function getUserOrganizationsAndRoles(request: INeo4jServiceAPI.IGetUserOrganizationsAndRolesRequest) {
  const { params } : { params: INeo4jServiceAPI.IGetUserOrganizationsAndRolesParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(GET_USER_ORGANIZATIONS_AND_ROLES),
      variables: {
        where: {
          _id: params.userId,
        },
        sort: [
          {
            edge: {
              metaConnectedAt: 'DESC',
            },
          },
        ],
        organizationConnectionWhere2: {
          edge: {
            roles_NOT_INCLUDES: 'PENDING',
          },
        },
      },
    },
  };
  return graphqlRequestBody;
}

function getOrganizationUsers(request: INeo4jServiceAPI.IGetOrganizationUsersRequest) {
  const { params }: { params: INeo4jServiceAPI.IGetOrganizationUsersParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(GET_ORGANIZATION_USERS),
      variables: {
        where: {
          _id: params.organizationId,
        },
      },
    },
  };
  return graphqlRequestBody;
}

function getOrganizationListings(request: INeo4jServiceAPI.IGetOrganizationListingsRequest) {
  const { params }: { params: INeo4jServiceAPI.IGetOrganizationListingsParams } = request;
  const graphqlRequestBody = {
    graphqlQuery: {
      query: print(GET_ORGANIZATION_LISTINGS),
      variables: {
        where: {
          _id: params.organizationId,
        },
      },
    },
  };
  return graphqlRequestBody;
}

export const Neo4jQueries = {
  getListing,
  getUser,
  getUserByEmail,
  getUserOrganizations,
  getUserOrganizationsAndRoles,
  getOrganizationUsers,
  getOrganizationListings,
  getOrganization,
};
