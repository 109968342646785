import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Icon, { Icons } from '../elements/Icon';
import Flex from '../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';

const Container = styled.div<{ display?: string; height?: string; margin?: string; bottomBorder?: string; }>`
  background: ${Colors.Yellow50};
  padding: 16px;
  margin: ${(props) => props.margin};
  border-radius: 4px;
  display: ${(props) => props.display};
  height: ${(props) => props.height};
  border-bottom: ${(props) => props.bottomBorder};
`;

export type InlineLeadTableAlertProps = {
  lead: ILeadGraphQL;
  isTableAlert: boolean;
  alertHeading?: string; // This is only really used if the alert is NOT a table alert. This will be rendered above the 'alertText'
  alertText: string;
  setRenderAlert?: boolean | (() => boolean);
  onAccept: (masterParentFunction?: any) => void;
  onDecline: (masterParentFunction?: any) => void;
};

type InlineLeadTableAlertConfig = {
  config: InlineLeadTableAlertProps;
}

const InlineLeadTableAlert: React.FC<InlineLeadTableAlertConfig> = ({
  config,
}) => {
  const [renderAlert, setRenderAlert] = React.useState(true);

  const {
    isTableAlert,
    alertText,
    onAccept,
    onDecline,
  } = config;

  return (
    <>
      {renderAlert && (
      <Container display={isTableAlert ? 'flex' : 'block'} height={isTableAlert ? '16px' : '96px'} margin={isTableAlert ? '0' : '0 0 16px'} bottomBorder={isTableAlert ? `1px inset ${Colors.Grey200}` : null}>
        {(!isTableAlert && config?.alertHeading) && (
        <Flex margin="0 0 8px 0">
          <Icon icon={Icons.ExclamationTriangleSolid} color={Colors.Yellow400} size={24} margin="0px 16px 0 0" />
          <Text type={isTableAlert ? TextTypesEnum.Regular14Small : TextTypesEnum.Regular14} color={Colors.Yellow900}>
            {config?.alertHeading}
          </Text>
        </Flex>
        )}
        <Text type={isTableAlert ? TextTypesEnum.Regular14Small : TextTypesEnum.Regular14} color={Colors.Grey600}>
          {alertText}
        </Text>
        <Flex margin={isTableAlert ? '24px 0 0 24px' : '8px 0 0 0'} align="center">
          <Button
            text="Approve"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Small}
            margin="0 0 32px 0"
            onClick={() => onAccept(
              () => setRenderAlert(false),
            )}
          />
          <Button
            text="Reject"
            type={ButtonTypesEnum.Outline}
            size={ButtonSizesEnum.Small}
            margin="0 0 32px 8px"
            onClick={() => onDecline(
              () => setRenderAlert(false),
            )}
          />
        </Flex>
      </Container>
      )}
    </>
  );
};

export default InlineLeadTableAlert;
