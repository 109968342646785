import React, { Suspense } from 'react';
import { useMutation } from '@apollo/client';
import * as IUserService from '@biproxi/models/services/IUserService';
import * as UserJoi from '@biproxi/models/joi/user.joi';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import AuthenticationStrategyEnum from '@biproxi/models/enums/AuthenticationStrategyEnum';
import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';
// const Button = React.lazy(() => import('../../elements/Button'));
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Input, { InputTypesEnum } from '../../elements/Input';
import LOGIN from '../../graphql/mutations/login.mutation';
import {
  AuthenticationModal,
  Content,
  OAuthScreen,
  AuthLoading,
} from '../AuthenticationModalLayout';
import Text, { TextTypesEnum } from '../../elements/Text';
import LinkText from '../../elements/LinkText';
import Flex, { Spacer } from '../../elements/Flex';
import Colors from '../../styles/Colors';
import Divider from '../../elements/Divider';
import { AppActions } from '../../redux/app.redux';
import { UserActions } from '../../redux/user.redux';
import { ModalTypesEnum } from './Modal';
import { AppState, useAppDispatch, useAppSelector } from '../../redux/store';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import { Icons } from '../../elements/Icon';
import useCompleteAuthentication from '../../hooks/useCompleteAuthentication.hook';
// import getUser from '../../next-api/queries/neo4j/getUser.query';

export type LoginModalProps = {
  isStandalone?: boolean;
};

const SkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const LoginModal: React.FC<LoginModalProps> = ({
  isStandalone = false,
}) => {
  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  const [loadCompanyName, setLoadCompanyName] = React.useState(false);

  /** Contentful State - Redux */
  const {
    contentful: {
      brandCollection: {
        companyName,
      },
    },
  } = useAppSelector((state: AppState) => state);

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  /** State */
  const { oAuthLoading, oAuthError, authRedirectLoading } = useAppSelector((state: AppState) => state.user);

  /** Hooks */
  const formParams: UseFormParams = {
    fields: {
      email: '',
      password: '',
    },
    fieldOrder: ['/email', '/password'],
    schema: UserJoi.loginUserParamsSchema,
  };

  const {
    controllers: {
      email,
      password,
    },
    isValid,
    setFieldErrors,
  } = useForm(formParams);

  const completeAuthentication = useCompleteAuthentication(isStandalone);

  /** Actions */
  const dispatch = useAppDispatch();

  const replaceWithRegisterModal = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.Register,
      props: {
        isStandalone,
      },
    }),
  );

  const replaceWithForgotPasswordEmailModal = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ForgotPasswordEmail,
      props: {
        isStandalone,
      },
    }),
  );

  const startOAuthFlow = (strategy: AuthenticationStrategyEnum) => {
    dispatch(
      UserActions.startOAuthFlow({
        strategy,
        isStandalone,
      }),
    );
  };

  /** GraphQL */
  type TPayload = IUserService.TRegisterUserPayload;

  type TData = {
    login: IUserService.IAuthenticateUserResponse;
  };

  const [loginMutation, { loading }] = useMutation<TData, TPayload>(LOGIN, {
    variables: {
      params: {
        email: email.value().toString(),
        password: password.value().toString(),
      },
    },
    onCompleted: async ({ login }) => {
      completeAuthentication(login.token, login.hubspotToken, login.user);
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        email.setError(message);
      }
    },
  });

  const login = (event?: React.FormEvent) => {
    if (event) event.preventDefault();
    if (!isValid()) return;
    loginMutation();
  };

  // React.useEffect(() => {
  //   if (userId) {
  //     refetch();
  //   }
  // }, [userId]);

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  React.useEffect(() => {
    setLoadCompanyName(true);
    if (companyName) setLoadCompanyName(false);
  }, []);

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  /** Render */
  return (
    <AuthenticationModal
      fullscreen={isStandalone}
      showExit={!isStandalone}
    >
      {(() => {
        if (oAuthLoading || oAuthError) {
          return <OAuthScreen />;
        }

        if (authRedirectLoading) {
          return (
            <AuthLoading />
          );
        }

        return (
          <Content onSubmit={(event) => login(event)}>
            <Text data-cy="login-greeting" type={TextTypesEnum.Bold24}>
              Welcome back to
              {' '}
              {!loadCompanyName ? companyName : (
                <SkeletonContainer style={{ width: '100%', height: '100%' }}>
                  <Skeleton
                    width="100%"
                    height="100%"
                    style={{ backgroundColor: Colors.Grey500 }}
                  />
                </SkeletonContainer>
              )}
            </Text>
            <Text type={TextTypesEnum.Regular16} color={Colors.Grey700} margin="4px 0 0">
              New here?
              <LinkText dataCy="create-account-link" onClick={() => replaceWithRegisterModal()} margin="0 0 0 4px">Create an account</LinkText>
            </Text>
            <Flex direction="row" justify="space-between" margin="24px 0">
              <Flex flex="1">
                {/* <Suspense fallback={(
                  <SkeletonContainer style={{ width: '100%', height: '100%' }}>
                    <Skeleton
                      width="100%"
                      height="100%"
                      style={{ backgroundColor: Colors.Grey300, borderRadius: '0' }}
                    />
                  </SkeletonContainer>
                )}
                > */}
                <Button
                  icon={Icons.Google}
                  iconColor={Colors.Black}
                  iconSize={16}
                  onClick={() => {
                    startOAuthFlow(AuthenticationStrategyEnum.GoogleLogin);
                  }}
                  type={ButtonTypesEnum.Outline}
                  size={ButtonSizesEnum.Medium}
                  isFullWidth
                  htmlType="button"
                />
                {/* </Suspense> */}
              </Flex>
              <Spacer />
              <Flex flex="1" align="center">
                <Suspense fallback={(
                  <SkeletonContainer style={{ width: '100%', height: '100%' }}>
                    <Skeleton
                      width="100%"
                      height="100%"
                      style={{ backgroundColor: Colors.Grey300, borderRadius: '0' }}
                    />
                  </SkeletonContainer>
                )}
                >
                  <Button
                    icon={Icons.Linkedin}
                    iconColor={Colors.Black}
                    iconSize={16}
                    onClick={() => {
                      startOAuthFlow(AuthenticationStrategyEnum.LinkedInLogin);
                    }}
                    type={ButtonTypesEnum.Outline}
                    size={ButtonSizesEnum.Medium}
                    isFullWidth
                    htmlType="button"
                  />
                </Suspense>
              </Flex>
            </Flex>
            <Divider margin="0 0 16px">Or continue with</Divider>
            <Input
              autoFocus
              label="Email*"
              placeholder="Email address"
              value={email.value().toString()}
              onChange={email.setValue}
              ref={email.setRef}
              error={email.error()}
              margin="0 0 24px"
              data-cy="email"
            />
            <Input
              label="Password*"
              placeholder="Enter your password"
              value={password.value().toString()}
              inputType={InputTypesEnum.Password}
              onChange={password.setValue}
              ref={password.setRef}
              error={password.error()}
              description={(
                <LinkText
                  margin="4px 0 0"
                  type={TextTypesEnum.Medium12}
                  onClick={() => replaceWithForgotPasswordEmailModal()}
                >
                  Forgot Password?
                </LinkText>
              )}
              margin="0 0 4px"
              data-cy="password"
            />
            {/* <Suspense fallback={(
              <SkeletonContainer style={{ width: '100%', height: '100%' }}>
                <Skeleton
                  width="100%"
                  height="100%"
                  style={{ backgroundColor: Colors.Grey300, borderRadius: '0' }}
                />
              </SkeletonContainer>
                )}
            > */}
            <Button
              margin="32px 0 0px"
              text="Sign in"
              isLoading={loading}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Large}
              isFullWidth
              data-cy="submit"
            />
            {/* </Suspense> */}
          </Content>
        );
      })()}
    </AuthenticationModal>
  );
};

export default LoginModal;
