import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import SecondaryPhoneNumberActionsEnum from '@biproxi/models/enums/SecondaryPhoneNumberActionsEnum';
import IUpdateListingContactParams from '@biproxi/models/interfaces/IUpdateListingContactParams';
import StringUtil from '@biproxi/models/utils/StringUtil';
import { MutationFunctionOptions, useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import {
  ModalContainer, ModalHeader, ModalContent, ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { media } from '../../utils/MediaQuery';
import Input, { InputTypesEnum } from '../../elements/Input';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Flex from '../../elements/Flex';
import UPDATE_USER_DISPLAY_PHONE_NUMBER from '../../graphql/mutations/updateUserDisplayPhoneNumber.mutation';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { UserActions } from '../../redux/user.redux';
import UPDATE_LISTING_CONTACT from '../../graphql/mutations/updateListingContact.mutation';
import useUser from '../../hooks/useUser.hook';

const Container = styled.div`
  white-space: prewrap;
  max-width: 488px;

  ${media.mobile} {
    width: 100%;
  }
`;

export type SecondaryPhoneNumberActionsModalProps = {
    actionType: SecondaryPhoneNumberActionsEnum;
    verifiedPhoneNumber: string;
    phoneNumber?: string;
    secondaryPhoneNumbers?: string[];
    displayedPhoneNumber?: string;
    updateUserSecondaryPhoneNumber: (options?: MutationFunctionOptions) => Promise<any>;
}

const SecondaryPhoneNumberActionsModal: React.FC<SecondaryPhoneNumberActionsModalProps> = ({
  actionType,
  verifiedPhoneNumber,
  phoneNumber,
  secondaryPhoneNumbers,
  displayedPhoneNumber,
  updateUserSecondaryPhoneNumber,
}) => {
  /** State */
  const formattedPhoneNumber = StringUtil.formatPhoneNumber(phoneNumber);
  const [changedPhoneNumber, setChangedPhoneNumber] = React.useState<string>('');
  const user = useUser();

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const updateUserSecondaryNumbersRedux = (updatedValue: string[]) => dispatch(
    UserActions.setUserField({ secondaryPhoneNumbers: updatedValue }),
  );

  const updateUserDisplayNumberRedux = (updatedValue: string) => dispatch(
    UserActions.setUserField({ displayedPhoneNumber: updatedValue }),
  );

  /** GraphQL */
  type UpdateUserSecondaryPhoneNumberMutationVars = {
    params: {
      phoneNumber: string
    }
  }
  type UpdateUserSecondaryPhoneNumberMutationData = {
    updateUserDisplayPhoneNumber: boolean
  }
  const [updateUserDisplayPhoneNumber] = useMutation<UpdateUserSecondaryPhoneNumberMutationData, UpdateUserSecondaryPhoneNumberMutationVars>(UPDATE_USER_DISPLAY_PHONE_NUMBER, {
    onCompleted: async (data) => {
      if (data?.updateUserDisplayPhoneNumber) {
        await updateListingsWithUserContact({
          variables: {
            params: {
              userId: user?.userId,
              phoneNumber: phoneNumber === displayedPhoneNumber ? verifiedPhoneNumber : phoneNumber,
            },
          },
        });
      }
    },
    onError: async (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);

      pushToast({
        type: ToastTypesEnum.Error,
        message: 'An error occurred while updating your secondary phone numbers. Plesae try again.',
      });

      logger.error('Error updating secondary phone numbers', message);
    },
  });

  type UpdateContactListingsMutationVars = {
    params: IUpdateListingContactParams
  }

  type UpdateContactListingsMutationData = {
    updateListingContact: boolean
  }

  const [updateListingsWithUserContact, { loading: isUpdateListingsWithUserContactLoading }] = useMutation<UpdateContactListingsMutationData, UpdateContactListingsMutationVars>(UPDATE_LISTING_CONTACT, {
    onCompleted: (data) => {
      if (data?.updateListingContact) {
        pushToast({
          type: ToastTypesEnum.Notification,
          message: 'Your default phone number has been successfully changed.',
        });
        popModal();
      }
    },
    onError: async (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);

      pushToast({
        type: ToastTypesEnum.Error,
        message: 'An error occurred while updating your secondary phone numbers. Please try again.',
      });

      logger.error('Error updating secondary phone numbers', message);
    },
  });

  /**
   * Render
   */
  const renderModalTitle = () => {
    switch (actionType) {
      case SecondaryPhoneNumberActionsEnum.Remove:
        return 'Remove phone number';
      case SecondaryPhoneNumberActionsEnum.Edit:
        return 'Change phone number';
      case SecondaryPhoneNumberActionsEnum.MakeDefault:
        return 'Default  phone number';
      default:
        return 'Verify your phone number';
    }
  };

  const renderModalBody = () => {
    switch (actionType) {
      case SecondaryPhoneNumberActionsEnum.Remove:
        return (
          <Container>
            {
              phoneNumber === displayedPhoneNumber ? (
                <Text
                  type={TextTypesEnum.Regular16}
                  color={Colors.Grey700}
                >
                  This is your default phone number. If you remove this number, the default number on your listings will go back to your verified phone number. Are you sure you want to remove
                  {' '}
                  {formattedPhoneNumber}
                </Text>
              ) : (
                <Text
                  type={TextTypesEnum.Regular16}
                  color={Colors.Grey700}
                >
                  Are you sure you want to remove
                  {' '}
                  {formattedPhoneNumber}
                  ?
                </Text>
              )
            }
          </Container>
        );
      case SecondaryPhoneNumberActionsEnum.Edit:
        return (
          <Container>
            <Input
              label="Add phone number"
              placeholder="(XXX) XXX-XXXX"
              onChange={(event) => setChangedPhoneNumber(event.target.value)}
              inputType={InputTypesEnum.PhoneNumber}
              margin="0px 8px 0 0"
              autoFocus
            />
          </Container>
        );
      case SecondaryPhoneNumberActionsEnum.MakeDefault:
        return (
          <Container>
            <Text
              type={TextTypesEnum.Regular16}
              color={Colors.Grey700}
            >
              Are you sure you want to make
              {' '}
              {formattedPhoneNumber}
              {' '}
              your default phone number?
            </Text>
          </Container>
        );
      default:
        return 'Please enter the code sent to your phone number.';
    }
  };

  const renderModalFooter = () => {
    switch (actionType) {
      case SecondaryPhoneNumberActionsEnum.Remove:
        return (
          <Flex justify="flex-end" width="100%">
            <Button
              text="Cancel"
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Medium}
              onClick={() => popModal()}
              margin="0px 8px 0px 0px"
            />
            <Button
              text="Remove"
              onClick={async () => {
                const filteredSecondaryPhoneNumbers = secondaryPhoneNumbers.filter((number) => number !== phoneNumber);
                await updateUserSecondaryPhoneNumber({
                  variables: {
                    params: {
                      phoneNumbers: filteredSecondaryPhoneNumbers,
                    },
                  },
                });
                updateUserSecondaryNumbersRedux(filteredSecondaryPhoneNumbers);
                if (phoneNumber === displayedPhoneNumber) {
                  updateUserDisplayNumberRedux(verifiedPhoneNumber);
                  await updateUserDisplayPhoneNumber({
                    variables: {
                      params: {
                        phoneNumber: verifiedPhoneNumber,
                      },
                    },
                  });
                }
              }}
              type={ButtonTypesEnum.Warning}
              size={ButtonSizesEnum.Medium}
            />
          </Flex>
        );
      case SecondaryPhoneNumberActionsEnum.Edit:
        return (
          <Flex justify="flex-end" width="100%">
            <Button
              text="Cancel"
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Medium}
              onClick={() => popModal()}
              margin="0px 8px 0px 0px"
            />
            <Button
              text="Change"
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Medium}
              onClick={async () => {
                const updatedSecondaryPhoneNumbers = [...secondaryPhoneNumbers.filter((number) => number !== phoneNumber), StringUtil.unmaskPhoneNumber(changedPhoneNumber)];
                await updateUserSecondaryPhoneNumber({
                  variables: {
                    params: {
                      phoneNumbers: updatedSecondaryPhoneNumbers,
                    },
                  },
                });
                setChangedPhoneNumber('');
                updateUserSecondaryNumbersRedux(updatedSecondaryPhoneNumbers);
              }}
            />
          </Flex>
        );
      case SecondaryPhoneNumberActionsEnum.MakeDefault:
        return (
          <Flex justify="flex-end" width="100%">
            <Button
              text="Cancel"
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Medium}
              onClick={() => popModal()}
              margin="0px 8px 0px 0px"
            />
            <Button
              text="Make default"
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Medium}
              isLoading={isUpdateListingsWithUserContactLoading}
              onClick={async () => {
                await updateUserDisplayPhoneNumber({
                  variables: {
                    params: {
                      phoneNumber,
                    },
                  },
                });
                updateUserDisplayNumberRedux(phoneNumber);
              }}
            />
          </Flex>
        );
      default:
        return ('Verify');
    }
  };

  return (
    <Container>
      <ModalContainer>
        <ModalHeader
          title={renderModalTitle()}
          close={popModal}
        />
        <ModalContent>
          {renderModalBody()}
        </ModalContent>
        <ModalFooter>
          {renderModalFooter()}
        </ModalFooter>
      </ModalContainer>
    </Container>
  );
};

export default SecondaryPhoneNumberActionsModal;
