enum BiproxiIconEnum {
  Org = 'Org',
  Teams = 'Teams',
  Biproximate = 'Biproximate',
  Portfolio = 'Portfolio',
  FolderOpened = 'FolderOpened',
  LockOpened = 'LockOpened',
  FolderClosed = 'FolderClosed',
  LockClosed = 'LockClosed',
  Dashboard = 'Dashboard',
  DateExplorer = 'DataExplorer',
  Favorites = 'Favorites',
  Filter = 'Filter',
  Home = 'Home',
  KnowledgeBase = 'KnowledgeBase',
  Listings = 'Listings',
  Map = 'Map',
  Messages = 'Messages',
  Notifications = 'Notifications',
  OffersReceived = 'OffersReceived',
  OffersSent = 'OffersSent',
  Phone = 'Phone',
  PrivateEvents = 'PrivateEvents',
  SavedSearches = 'SavedSearches',
  Search = 'Search',
  Settings = 'Settings',
  Support = 'Support',
  Trash = 'Trash',
}

export default BiproxiIconEnum;
