import DataExplorerDataFrameSectionsEnum from '../enums/DataExplorerDataFrameSectionsEnum';

const DataExplorerDataFrameSectionsContent = {
  [DataExplorerDataFrameSectionsEnum.LotInformation]: [
    {
      item: 'APN',
      cherreVariableName: 'assessor_parcel_number_raw',
    },
    {
      item: 'Property type',
      cherreVariableName: 'property_group_type',
    },
    {
      item: 'Property use',
      cherreVariableName: 'property_use_code_mapped',
    },
    {
      item: 'Last sale',
      cherreVariableName: 'last_sale_amount',
    },
    {
      item: 'Zone',
      cherreVariableName: 'zone_code',
    },
    {
      item: 'Last county assessor update',
      cherreVariableName: 'tax_assessor_last_update_date',
    },
  ],
  [DataExplorerDataFrameSectionsEnum.LotSize]: [
    {
      item: 'Size',
      cherreVariableName: 'lot_size_sq_ft',
    },
    {
      item: 'Lot size (acres)',
      cherreVariableName: 'lot_size_acre',
    },
    {
      item: 'Width',
      cherreVariableName: 'lot_width',
    },
    {
      item: 'Depth',
      cherreVariableName: 'lot_depth_ft',
    },
    {
      item: 'Gross area',
      cherreVariableName: 'gross_sq_ft',
    },
  ],
  [DataExplorerDataFrameSectionsEnum.BuildingInfo]: [
    {
      item: 'Year built',
      cherreVariableName: 'year_built',
    },
    {
      item: 'Number of units',
      cherreVariableName: 'units_count',
    },
    {
      item: 'Building area',
      cherreVariableName: 'building_sq_ft',
    },
  ],
  [DataExplorerDataFrameSectionsEnum.Taxes]: [
    {
      item: 'Current tax',
      cherreVariableName: 'tax_bill_amount', // check to ensure this is the correct variable
    },
    {
      item: 'Jurisdiction',
      cherreVariableName: 'jurisdiction',
    },
    {
      item: 'Assessed total value',
      cherreVariableName: 'assessed_value_total',
    },
    {
      item: 'Assessed tax year',
      cherreVariableName: 'assessed_tax_year',
    },
    {
      item: 'Assessed improvements',
      cherreVariableName: 'assessed_value_improvements',
    },
    {
      item: 'Assessed land value',
      cherreVariableName: 'assessed_value_land',
    },
    {
      item: 'Market total value',
      cherreVariableName: 'market_value_total',
    },
    {
      item: 'Market value year',
      cherreVariableName: 'market_value_year',
    },
    {
      item: 'Market improvements value',
      cherreVariableName: 'market_value_improvements',
    },
    {
      item: 'Market value land',
      cherreVariableName: 'the_value_land', // also check this
    },
  ],
  [DataExplorerDataFrameSectionsEnum.PropertyDetails]: [
    {
      item: 'Upper floor square footage',
      cherreVariableName: 'upper_floor_sq_ft',
    },
    {
      item: 'Sewer usage',
      cherreVariableName: 'sewer_usage_code',
    },
    {
      item: 'Water usage',
      cherreVariableName: 'water_source_code',
    },
    {
      item: 'Construction',
      cherreVariableName: 'construction_code',
    },
    {
      item: 'Fire resistance class',
      cherreVariableName: 'fire_resistance_class_code',
    },
    {
      item: 'Exterior',
      cherreVariableName: 'exterior_code',
    },
    {
      item: 'Topography',
      cherreVariableName: 'topography_code',
    },
  ],
  [DataExplorerDataFrameSectionsEnum.AdditionalPropertyDetails]: [
    {
      item: 'Basement size (sqft)',
      cherreVariableName: 'basement_sq_ft',
    },
    {
      item: 'Heating',
      cherreVariableName: 'hvacc_heating_code',
    },
    {
      item: 'Cooling',
      cherreVariableName: 'hvacc_cooling_code',
    },
    {
      item: 'Has elevator',
      cherreVariableName: 'has_elevator',
    },
    {
      item: 'Porch',
      cherreVariableName: 'porch_code',
    },
    {
      item: 'Porch size (sqft)',
      cherreVariableName: 'porch_sq_ft',
    },
    {
      item: 'Fence',
      cherreVariableName: 'fence_code',
    },
    {
      item: 'Fence size (sqft)',
      cherreVariableName: 'fence_sq_ft',
    },
    {
      item: 'Has canopy',
      cherreVariableName: 'has_canopy',
    },
  ],
};

export default DataExplorerDataFrameSectionsContent;
