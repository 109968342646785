import gql from 'graphql-tag';

const GET_LISTING = gql`
  query Query($where: ListingWhere) {
    listings(where: $where) {
      _id
      address1
      address2
      addressState
      assetClass
      city
      cherreId
      country
      description
      isPortfolioListing
      isPrivateListing
      mediaFileIds
      metaCreatedBy
      metaCreatedAt
      metaLastUpdatedAt
      metaLastUpdatedBy
      name
      propertyType
      state
      zip
    }
  }
`;

export default GET_LISTING;
