import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
// import * as Polished from 'polished';
import ColorsJson from '../styles/Colors.json';
import IPageConfiguration from '../models/interfaces/IPageConfiguration';
import PrivateListingSrpImage from '../../public/private-listing-srp.png';
import BiproxiFavicon from '../../public/favicon.ico';
import { IBrand, IBrandAsset } from '../models/interfaces/IBrand';

export type ContentfulReducerState = {
  brandCollection: IBrand;
};

export function contentfulReducerState(_config?: IPageConfiguration): ContentfulReducerState {
  return {
    brandCollection: {
      companyName: 'Biproxi',
      cornerLogoUrl: '',
      companyMediaAssets: {
        favicon: BiproxiFavicon.src,
        cornerLoginLogo: '/biproxi-logo-wt.svg',
        marketingLoginPhoto: '/biproxi-login.svg',
        navigationDesktopLogo: '/biproxi-nav-icon.svg',
        navigationMobileLogo: '/biproxi-logo-wt.svg',
        lockedListingImage: PrivateListingSrpImage.src,
      },
      colors: ColorsJson,
      fetched: false,
    },
  };
}

export enum ContentfulActionTypesEnum {
  SetContentfulCollection = 'SetContentfulCollection',
  SetContentfulFetch = 'SetContentfulFetch',
}

/** ******************************************************************************
 *  Set Contentful Collection
 ****************************************************************************** */

// Payload
type SetContentfulCollectionPayloadType = {
  colors?: Record<string, string>;
  companyMediaAssets?: IBrandAsset;
  companyName?: string;
  cornerLogoUrl?: string;
}

// Action
const setContentfulCollection = createAction<SetContentfulCollectionPayloadType, ContentfulActionTypesEnum.SetContentfulCollection>(ContentfulActionTypesEnum.SetContentfulCollection);

// Reducer
function setContentfulCollectionReducer(state: ContentfulReducerState, action: PayloadAction<SetContentfulCollectionPayloadType>) {
  const {
    colors,
    companyName,
    companyMediaAssets,
    cornerLogoUrl,
  } = action.payload;

  state.brandCollection.colors = colors;
  state.brandCollection.companyName = companyName;
  state.brandCollection.companyMediaAssets = companyMediaAssets;
  state.brandCollection.cornerLogoUrl = cornerLogoUrl;

  return state;
}

/** ******************************************************************************
 *  Set Contentful IF fetch happened
 ****************************************************************************** */

// Payload
type SetContentfulFetchPayloadType = {
  fetched?: boolean;
}

// Action
const setContentfulFetch = createAction<SetContentfulFetchPayloadType, ContentfulActionTypesEnum.SetContentfulFetch>(ContentfulActionTypesEnum.SetContentfulFetch);

// Reducer
function setContentfulFetchReducer(state: ContentfulReducerState, action: PayloadAction<SetContentfulFetchPayloadType>) {
  const { fetched } = action.payload;

  state.brandCollection.fetched = fetched;
}

/** ******************************************************************************
 *  Exports
 ****************************************************************************** */

export const ContentfulActions = {
  setContentfulCollection,
  setContentfulFetch,
};

const reducer = createReducer(contentfulReducerState(), (builder) => builder
  .addCase(setContentfulCollection, setContentfulCollectionReducer)
  .addCase(setContentfulFetch, setContentfulFetchReducer));

export default reducer;
