import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import DeleteFromWebflowTypeEnum from '@biproxi/models/enums/DeleteFromWebflowTypeEnum';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { media } from '../../utils/MediaQuery';
import DELETE_LISTING_FROM_WEBFLOW from '../../graphql/mutations/deleteListingFromWebflow.mutation';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Input, { InputTypesEnum } from '../../elements/Input';
import Divider from '../../elements/Divider';

const Container = styled.div`
  width: 432px;

  ${media.mobile} {
    width: 100%;
  }
`;

export type DeleteFromWebflowModalProps = {
  listingId: string;
};

const DeleteFromWebflowModal: React.FC<DeleteFromWebflowModalProps> = ({
  listingId,
}) => {
  /** State */
  const [whichLoading, setWhichLoading] = React.useState(null);
  const [customCollectionId, setCustomCollectionId] = React.useState('');

  const dispatch = useAppDispatch();

  const popModal = () => dispatch(AppActions.popModal());
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /** GraphQl */
  const [deleteListingFromWebflow] = useMutation(DELETE_LISTING_FROM_WEBFLOW, {
    onCompleted: () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Success',
      });
      popModal();
      setWhichLoading(null);
    },
    onError: (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);
      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
      popModal();
      setWhichLoading(null);
    },
  });

  /* Render */
  return (
    <ModalContainer>
      <ModalHeader title="Delete listing from Webflow" close={popModal} />
      <ModalContent>
        <Container>
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Grey900}
            margin="8px 0 0"
          >
            Are you sure you want to delete this listing from Webflow? Please select where you would like to delete it from.
          </Text>
          <Button
            text="Delete from featured listings section"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Large}
            margin="16px 0 0"
            isLoading={whichLoading === DeleteFromWebflowTypeEnum.Featured}
            onClick={() => {
              if (!whichLoading) {
                setWhichLoading(DeleteFromWebflowTypeEnum.Featured);
                deleteListingFromWebflow({
                  variables: {
                    params: {
                      listingId,
                      type: DeleteFromWebflowTypeEnum.Featured,
                    },
                  },
                });
              }
            }}
          />
          <Button
            text="Delete from asset class page"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Large}
            margin="16px 0 0"
            isLoading={whichLoading === DeleteFromWebflowTypeEnum.AssetClass}
            onClick={() => {
              if (!whichLoading) {
                setWhichLoading(DeleteFromWebflowTypeEnum.AssetClass);
                deleteListingFromWebflow({
                  variables: {
                    params: {
                      listingId,
                      type: DeleteFromWebflowTypeEnum.AssetClass,
                    },
                  },
                });
              }
            }}
          />
          <Divider margin="16px 0" />
          <Input
            placeholder="Enter a collectionId from Webflow"
            value={customCollectionId}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCustomCollectionId(e.target.value);
            }}
            inputType={InputTypesEnum.Text}
          />
          <Button
            text="Delete from custom collection"
            disabled={!customCollectionId}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Large}
            margin="16px 0 0"
            isLoading={whichLoading === DeleteFromWebflowTypeEnum.Custom}
            onClick={() => {
              if (!whichLoading) {
                setWhichLoading(DeleteFromWebflowTypeEnum.Custom);
                deleteListingFromWebflow({
                  variables: {
                    params: {
                      listingId,
                      type: DeleteFromWebflowTypeEnum.Custom,
                      customCollectionId,
                    },
                  },
                });
              }
            }}
          />
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default DeleteFromWebflowModal;
