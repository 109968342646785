import gql from 'graphql-tag';

const GET_USER = gql`
  query GetUser($userWhere: UserWhere) {
    users(where: $userWhere) {
      _id
      firstName
      lastName
      email
      ipAddress
      metaCreatedAt
      metaCreatedBy
      metaLastUpdatedAt
      metaLastUpdatedBy
      passwordHash
      phoneNumber
      role
      biproxiRoles
      biproxiPermissions
    }
  }
`;

export default GET_USER;
