import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { isProduction } from '@biproxi/env';
import createSagaMiddleware from 'redux-saga';
import { logger } from 'redux-logger';
import rootSaga from './sagas/root.saga';
import appReducer, { appReducerState } from './app.redux';
import userReducer, { userReducerState } from './user.redux';
import listingReducer, { listingReducerState } from './listing.redux';
import IPageConfiguration from '../models/interfaces/IPageConfiguration';
import leadReducer, { leadReducerState } from './lead.redux';
import chatReducer, { chatReducerState } from './chat.redux';
import contentfulReducer, { contentfulReducerState } from './contentful.redux';
import privateEventReducer, { privateEventReducerState } from './privateEvent.redux';
import notificationReducer, { notificationReducerState } from './notification.redux';

const configureAppStore = (preloadedState?: any) => {
  const sagaMiddleware = createSagaMiddleware();

  const middleware: any = [sagaMiddleware];

  if (!isProduction) {
    middleware.push(logger);
  }

  const store = configureStore({
    reducer: {
      app: appReducer,
      user: userReducer,
      listing: listingReducer,
      lead: leadReducer,
      chat: chatReducer,
      notification: notificationReducer,
      privateEvent: privateEventReducer,
      contentful: contentfulReducer,
    },
    preloadedState: preloadedState ?? undefined,
    devTools: !isProduction,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false, serializableCheck: false })
      .concat(middleware),
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

let store = null;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = useSelector;

const initStore = (config: IPageConfiguration) => configureAppStore({
  app: appReducerState(config),
  user: userReducerState(config),
  listing: listingReducerState(config),
  lead: leadReducerState(config),
  chat: chatReducerState(config),
  notification: notificationReducerState(config),
  privateEvent: privateEventReducerState(config),
  contentful: contentfulReducerState(config),
});

export const initializeStore = (config: IPageConfiguration) => {
  let _store = store ?? initStore(config);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (store) {
    _store = initStore(config);
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(config: IPageConfiguration) {
  const store = React.useMemo(() => initializeStore(config), [config]);
  return store;
}
