import { css } from '@emotion/react';

/** This file houses all our custom css (fonts, icons). This function is called
 * in the top-level container of our application (_app.tsx) to make these styles
 * available across the application.
 */

const BiproxiCustomCss = () => css`
    /** apply custom font to icons */
    [class^="bp"], [class*=" bp"] {
    font-family: 'bp-icons' !important;
    font-style: normal;
    font-weight: normal;
    font-display: block;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }

  /** enable custom icons */
  .bpOrg:before {
    content: "\\e919";
  }
  .bpBiproximate:before {
    content: "\\e918";
  }
  .bpPortfolio:before {
    content: "\\e917";
  }
  .bpFolderOpened:before {
    content: "\\e913";
  }
  .bpLockOpened:before {
    content: "\\e914";
  }
  .bpFolderClosed:before {
    content: "\\e915";
  }
  .bpLockClosed:before {
    content: "\\e916";
  }
  .bpDashboard:before {
    content: "\\e900";
  }
  .bpDataExplorer:before {
    content: "\\e901";
  }
  .bpFavorites:before {
    content: "\\e902";
  }
  .bpFilter:before {
    content: "\\e903";
  }
  .bpHome:before {
    content: "\\e904";
  }
  .bpKnowledgeBase:before {
    content: "\\e905";
  }
  .bpListings:before {
    content: "\\e906";
  }
  .bpMap:before {
    content: "\\e907";
  }
  .bpMessages:before {
    content: "\\e908";
  }
  .bpNotifications:before {
    content: "\\e909";
  }
  .bpOffersReceived:before {
    content: "\\e90a";
  }
  .bpOffersSent:before {
    content: "\\e90b";
  }
  .bpPhone:before {
    content: "\\e90c";
  }
  .bpPrivateEvents:before {
    content: "\\e90d";
  }
  .bpSavedSearches:before {
    content: "\\e90e";
  }
  .bpSearch:before {
    content: "\\e90f";
  }
  .bpSettings:before {
    content: "\\e910";
  }
  .bpSupport:before {
    content: "\\e911";
  }
  .bpTrash:before {
    content: "\\e912";
  }
  `;

export default BiproxiCustomCss;
