import TValueOf from '../models/types/TValueOf';

/**
 * This is an ongoing project.
 * Eventually we want to have all Z-indexes in this file
 * so that it is easier to dicsern the correct levels of each
 * and edit them when needed. We also want to standardize these
 * numbers so that they are not all over the place but that will be easier
 * once we have them all here.
 */
const ZIndexes = {
  Anchor: 200,
  MobileFooterActions: 400,
  ListingMapMarker: 9999,
  Modal: 10000,
  FilePreview: 12000,
  AnchoredMenu: 100000,
  Tooltip: 99999999999,
};

export type ZIndexesType = TValueOf<typeof ZIndexes>;

export default ZIndexes;
