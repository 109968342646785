import React from 'react';
import styled from '@emotion/styled';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { media } from '../../utils/MediaQuery';
import { ListingActions } from '../../redux/listing.redux';
import Input, { InputTypesEnum } from '../../elements/Input';

const Container = styled.div`
  width: 432px;

  ${media.mobile} {
    width: 100%;
  }
`;

const Form = styled.form``;

export type EditHighlightModalProps = {
  index: number;
  highlights: string[];
};

const EditHighlightModal: React.FC<EditHighlightModalProps> = ({
  index,
  highlights,
}) => {
  const [editedHighlight, setEditedHighlight] = React.useState(highlights[index]);

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const editHighlight = (event?: React.FormEvent) => {
    if (event) event.preventDefault();
    if (!editedHighlight) return;
    const h = [...highlights];
    h[index] = editedHighlight;
    dispatch(ListingActions.setListingHighlights({ highlights: h }));
    popModal();
  };

  /* Render */
  return (
    <ModalContainer>
      <Form onSubmit={(event) => editHighlight(event)}>
        <ModalHeader title="Edit Highlight" close={popModal} />
        <ModalContent>
          <Container>
            <Input
              autoFocus
              label="Edit the highlight"
              placeholder="Edit the highlight"
              value={editedHighlight}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditedHighlight(e.currentTarget.value)}
              inputType={InputTypesEnum.Text}
            />
          </Container>
        </ModalContent>
        <ModalFooter>
          <Flex justify="flex-end" width="100%">
            <Button
              text="Cancel"
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Medium}
              onClick={() => popModal()}
              margin="0px 8px 0px 0px"
              htmlType="button"
            />
            <Button
              text="Update"
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Medium}
              htmlType="submit"
            />
          </Flex>
        </ModalFooter>
      </Form>
    </ModalContainer>
  );
};

export default EditHighlightModal;
