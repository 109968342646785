enum DataExplorerDataFrameSectionsEnum {
    LotInformation = 'Lot Information',
    LotSize = 'Lot Size',
    BuildingInfo = 'Building Info',
    Taxes = 'Taxes',
    PropertyDetails = 'Property Details',
    AdditionalPropertyDetails = 'Additional Property Details',
    Demographics = 'Demographics',
    Crime = 'Crime',
    HousholdIncome = 'Household Income',
    Emplyoment = 'Employment',
    Population = 'Population',
    Income = 'Income',
    Education = 'Education',
    PropertyOwners = 'Property Owners',
    TransactionTimeline = 'Transaction Timeline',
}

export default DataExplorerDataFrameSectionsEnum;
