import { logger } from '@biproxi/logger';
import {
  createAction, createReducer, PayloadAction,
} from '@reduxjs/toolkit';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ReduxUtil from '@biproxi/models/utils/ReduxUtil';
import TCache from '@biproxi/models/types/TCache';
import IListingGuidance from '@biproxi/models/interfaces/IListingGuidance';
import { IListingMediaGraphQL } from '@biproxi/models/interfaces/IListingMedia';
import IListingQuery from '@biproxi/models/interfaces/IListingQuery';
import TListingInfoField from '@biproxi/models/types/TListingInfoField';
import ListingPropertyTypeEnum from '@biproxi/models/enums/ListingPropertyTypeEnum';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import ListingAssetClassEnum from '@biproxi/models/enums/ListingAssetClassEnum';
import ListingQuerySortByEnum from '@biproxi/models/enums/ListingQuerySortByEnum';
import ListingVaultFolderNamesEnum from '@biproxi/models/enums/ListingVaultFolderNamesEnum';
import IFile from '@biproxi/models/interfaces/IFile';
import { IListingVaultFolderGraphQL } from '@biproxi/models/interfaces/IListingVaultFolder';
import * as IListingService from '@biproxi/models/services/IListingService';
import IAddress from '@biproxi/models/interfaces/IAddress';
import ILocation from '@biproxi/models/interfaces/ILocation';
import { IVideoGraphQL } from '@biproxi/models/interfaces/IVideo';
import ISavedSearch from '@biproxi/models/interfaces/ISavedSearch';
import IListingContact from '@biproxi/models/interfaces/IListingContact';
import IListingCounts from '@biproxi/models/interfaces/IListingCounts';
import shortid from 'shortid';
import StringUtil from '@biproxi/models/utils/StringUtil';
import IListingDealProfile from '@biproxi/models/interfaces/IListingDealProfile';
import * as UrlUtil from '../utils/UrlUtil';
import IPageConfiguration from '../models/interfaces/IPageConfiguration';
import { AppState } from './store';
import NextUtil from '../utils/NextUtil';
import ListingLoaderTypesEnum from '../models/enums/ListingLoaderTypesEnum';
import TListingLoaders from '../models/types/TListingLoaders';
import IListingSearch from '../models/interfaces/IListingSearch';

export type ListingReducerState = {
  listingId: string | null;
  listings: TCache<IListingGraphQL>;
  loading: TListingLoaders;
  errors: Record<string, string>;
  showPreview: boolean;
  search: IListingSearch;
  savedSearch: ISavedSearch
  videoUploadInProgress: boolean;
  listingCounts: IListingCounts;
};

const listingSearchState = {
  address: null,
  query: {
    queryType: ListingQueryTypesEnum.Active,
    sortBy: ListingQuerySortByEnum.Trending,
    price: {
      includeUndefined: true,
      min: null,
      max: null,
    },
  },
  isSavedSearch: false,
};

export function listingReducerState(config?: IPageConfiguration): ListingReducerState {
  let { listingId = null } = NextUtil.query(config);

  const listings = config?.listing ? ReduxUtil.makeCache<IListingGraphQL>([config.listing], {}) : {};
  const search: IListingSearch = listingSearchState;

  if (!listingId && config?.listing) {
    listingId = config.listing._id;
  }

  const loading = Object.keys(ListingLoaderTypesEnum).reduce((cur, next) => {
    cur[next] = false;
    return cur;
  }, {} as TListingLoaders);

  return {
    listingId,
    listings,
    loading,
    errors: {},
    showPreview: false,
    search,
    savedSearch: {
      query: listingSearchState.query,
      address: null,
      name: '',
      notifications: true,
    },
    videoUploadInProgress: false,
    listingCounts: {
      All: 0,
      Active: 0,
      CallForOffers: 0,
      Scheduled: 0,
      Draft: 0,
      UnderContract: 0,
      Closed: 0,
      Inactive: 0,
      Expired: 0,
    },
  };
}

export enum ListingActionTypesEnum {
  SelectListing = 'SelectListing',
  CacheListings = 'CacheListings',
  RecacheListing = 'RecacheListing',
  SetListingLoading = 'SetListingLoading',
  SetShowPreview = 'SetShowPreview',
  // Search Listings
  SetListingSearch = 'SetListingSearch',
  SetListingSearchQuery = 'SetListingSearchQuery',
  ResetListingSearchQuery = 'ResetListingSearchQuery',
  // Create Listing
  CreateListing = 'CreateListing',
  CreateListingSuccess = 'CreateListingSuccess',
  CreateListingFailure = 'CreateListingFailure',
  // Update Listing
  UpdateListing = 'UpdateListing',
  UpdateListingSuccess = 'UpdateListingSuccess',
  UpdateListingFailure = 'UpdateListingFailure',
  // Update Draft
  UpdateDraft = 'UpdateDraft',
  UpdateDraftSuccess = 'UpdateDraftSuccess',
  UpdateDraftFailure = 'UpdateDraftFailure',
  // Publish Listing
  PublishListing = 'PublishListing',
  PublishListingSuccess = 'PublishListingSuccess',
  PublishListingFailure = 'PublishListingFailure',
  // Set Listing State
  SetListingState = 'SetListingState',
  SetListingStateSuccess = 'SetListingStateSuccess',
  SetListingStateFailure = 'SetListingStateFailure',
  // Delete Listing
  DeleteListing = 'DeleteListing',
  DeleteListingSuccess = 'DeleteListingSuccess',
  DeleteListingFailure = 'DeleteListingFailure',
  // Listing Navigation
  PreviewListingVault = 'PreviewListingVault',
  PreviewListingVideo = 'PreviewListingVideo',
  PreviewListingImages = 'PreviewListingImages',
  // Set Listing Fields
  SetListingField = 'SetListingField',
  SetListingId = 'SetListingId',
  SetListingAddress = 'SetListingAddress',
  SetListingAddressLocation = 'SetListingAddressLocation',
  SetListingCherreId = 'setListingCherreId',
  SetListingGuidanceField = 'SetListingGuidanceField',
  SetListingMediaField = 'SetListingMediaField',
  AddListingMediaFiles = 'AddListingMediaFiles',
  DeleteListingMediaFiles = 'DeleteListingMediaFiles',
  SwapListingMediaFilesAfterCrop = 'SwapListingMediaFilesAfterCrop',
  ReOrderListingMediaFiles = 'ReOrderListingMediaFiles',
  SetListingMediaVideo = 'SetListingMediaVideo',
  SetListingAssetClass = 'SetListingAssetClass',
  SetListingPropertyType = 'SetListingPropertyType',
  SetListingScore = 'SetListingScore',
  SetListingInfoFields = 'SetListingInfoFields',
  SetListingInfoField = 'SetListingInfoField',
  AddListingVaultSubFolder = 'AddListingVaultSubFolder',
  RemoveListingVaultSubFolder = 'RemoveListingVaultSubFolder',
  RenameListingVaultSubFolder = 'RenameListingVaultSubFolder',
  ReOrderListingVaultFile = 'ReOrderListingVaultFile',
  AddListingVaultFiles = 'AddListingVaultFiles',
  MoveListingVaultFile = 'MoveListingVaultFile',
  AddListingVaultCAFile = 'AddListingVaultCAFile',
  RemoveListingVaultCAFile = 'RemoveListingVaultCAFile',
  RemoveListingVaultFiles = 'RemoveListingVaultFiles',
  SetListingHighlights = 'SetListingHighlights',
  SetListingContacts = 'SetListingContacts',
  SetListingListedByOwner = 'SetListingListedByOwner',
  SetListingDealProfileField = 'SetListingDealProfileField',
  // Saved Search
  SetSavedSearch = 'SetSavedSearch',
  RemoveSavedSearch = 'RemoveSavedSearch',
  SetSavedSearchQuery = 'SetSavedSearchQuery',
  // Misc
  SetListingCounts = 'SetListingCounts',
  SetVideoUploadInProgress = 'SetVideoUploadInProgress',
  ResetListingErrors = 'ResetListingErrors',
}

/** ******************************************************************************
 *  Select Listing
 ****************************************************************************** */

// Payload
type SelectListingPayloadType = {
  listingId: string | null;
};

// Action
const selectListing = createAction<SelectListingPayloadType, ListingActionTypesEnum.SelectListing>(ListingActionTypesEnum.SelectListing);

// Reducer
function selectListingReducer(state: ListingReducerState, action: PayloadAction<SelectListingPayloadType>) {
  const { listingId } = action.payload;
  UrlUtil.setQueryString({ listingId });
  state.listingId = listingId;
  return state;
}

/** ******************************************************************************
 *  Cache Listings
 ****************************************************************************** */

// Payload
type CacheListingPayloadType = {
  listings: IListingGraphQL[];
};

// Action
const cacheListings = createAction<CacheListingPayloadType, ListingActionTypesEnum.CacheListings>(ListingActionTypesEnum.CacheListings);

// Reducer
function cacheListingsReducer(state: ListingReducerState, action: PayloadAction<CacheListingPayloadType>) {
  state.listings = ReduxUtil.makeCache<IListingGraphQL>(action.payload.listings, state.listings);
  return state;
}

/** ******************************************************************************
 *  Recache Listing
 ****************************************************************************** */

// Payload
type RecacheListingPayloadType = {
  listingId: string;
};

// Action
const recacheListing = createAction<RecacheListingPayloadType, ListingActionTypesEnum.RecacheListing>(ListingActionTypesEnum.RecacheListing);

// Reducer
function recacheListingReducer(state: ListingReducerState, _action: PayloadAction<RecacheListingPayloadType>) {
  return state;
}

/** ******************************************************************************
 *  Set Listing Loading
 ****************************************************************************** */

// Payload
type SetListingLoadingPayloadType = Partial<TListingLoaders>;

// Action
const setListingLoading = createAction<SetListingLoadingPayloadType, ListingActionTypesEnum.SetListingLoading>(ListingActionTypesEnum.SetListingLoading);

// Reducer
function setListingLoadingReducer(state: ListingReducerState, action: PayloadAction<SetListingLoadingPayloadType>) {
  state.loading = {
    ...state.loading,
    ...action.payload,
  };
  return state;
}

/** ******************************************************************************
 *  Set Show Preview
 ****************************************************************************** */

// Payload
type SetShowPreviewPayloadType = {
  showPreview: boolean;
};

// Action
const setShowPreview = createAction<SetShowPreviewPayloadType, ListingActionTypesEnum.SetShowPreview>(ListingActionTypesEnum.SetShowPreview);

// Reducer
function setShowPreviewReducer(state: ListingReducerState, action: PayloadAction<SetShowPreviewPayloadType>) {
  state.showPreview = action.payload.showPreview;
  return state;
}

/** ******************************************************************************
 *  Set Listing Search
 ****************************************************************************** */

// Payload
type SetListingSearchPayloadType = Partial<IListingSearch>;

// Action
const setListingSearch = createAction<SetListingSearchPayloadType, ListingActionTypesEnum.SetListingSearch>(ListingActionTypesEnum.SetListingSearch);

// Reducer
function setListingSearchReducer(state: ListingReducerState, action: PayloadAction<SetListingSearchPayloadType>) {
  state.search = {
    ...state.search,
    ...action.payload,
  };

  return state;
}

/** ******************************************************************************
 *  Set Listing Search Query
 ****************************************************************************** */

// Payload
type SetListingSearchQueryPayloadType = Partial<IListingQuery>;

// Action
const setListingSearchQuery = createAction<SetListingSearchQueryPayloadType, ListingActionTypesEnum.SetListingSearchQuery>(ListingActionTypesEnum.SetListingSearchQuery);

// Reducer
function setListingSearchQueryReducer(state: ListingReducerState, action: PayloadAction<SetListingSearchQueryPayloadType>) {
  const query = {
    ...state.search.query,
    ...action.payload,
  };
  return setListingSearchReducer(state, setListingSearch({ query }));
}

/** ******************************************************************************
 *  Reset Listing Search Query
 ****************************************************************************** */

// Payload
type ResetListingSearchQueryPayloadType = null;

// Action
const resetListingSearchQuery = createAction<ResetListingSearchQueryPayloadType, ListingActionTypesEnum.ResetListingSearchQuery>(ListingActionTypesEnum.ResetListingSearchQuery);

// Reducer
function resetListingSearchQueryReducer(state: ListingReducerState, _action: PayloadAction<ResetListingSearchQueryPayloadType>) {
  const query = {
    queryType: ListingQueryTypesEnum.Active,
    sortBy: ListingQuerySortByEnum.Trending,
    price: {
      includeUndefined: true,
      min: null,
      max: null,
    },
  };
  return setListingSearchReducer(state, setListingSearch({ query }));
}

/** ******************************************************************************
 *  Set Saved Search Query
 ****************************************************************************** */
// Payload
type SetSavedSearchQueryPayloadType = Partial<IListingQuery>;

// Action
const setSavedSearchQuery = createAction<SetSavedSearchQueryPayloadType, ListingActionTypesEnum.SetSavedSearchQuery>(ListingActionTypesEnum.SetSavedSearchQuery);

// Reducer
function setSavedSearchQueryReducer(state: ListingReducerState, action: PayloadAction<SetSavedSearchQueryPayloadType>) {
  const query = {
    ...state.savedSearch?.query,
    ...action.payload,
  };

  return cacheSavedSearchReducer(state, cacheSavedSearch({ query }));
}

/** ******************************************************************************
 *  Create Listing
 ****************************************************************************** */

// Payload
type CreateListingPayloadType = {};

// Action
const createListing = createAction<CreateListingPayloadType, ListingActionTypesEnum.CreateListing>(ListingActionTypesEnum.CreateListing);

// Reducer
function createListingReducer(state: ListingReducerState, _action: PayloadAction<CreateListingPayloadType>) {
  // state.listings = ReduxUtil.makeCache<IListingGraphQL | IListingParams>(action.payload.listings, state.listings);
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.CreateListing]: true }));
  return state;
}

/** ******************************************************************************
 *  Create Listing Success
 ****************************************************************************** */

// Payload
type CreateListingSuccessPayloadType = {
  listing: IListingGraphQL;
};

// Action
const createListingSuccess = createAction<CreateListingSuccessPayloadType, ListingActionTypesEnum.CreateListingSuccess>(ListingActionTypesEnum.CreateListingSuccess);

// Reducer
function createListingSuccessReducer(state: ListingReducerState, action: PayloadAction<CreateListingSuccessPayloadType>) {
  const { listing } = action.payload;
  state.listings = ReduxUtil.makeCache<IListingGraphQL>([listing], state.listings);
  state = selectListingReducer(state, selectListing({ listingId: listing._id }));
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.CreateListing]: false }));
  return state;
}

/** ******************************************************************************
 *  Create Listing Failure
 ****************************************************************************** */

// Payload
type CreateListingFailurePayloadType = {
  listings: IListingGraphQL[];
};

// Action
const createListingFailure = createAction<CreateListingFailurePayloadType, ListingActionTypesEnum.CreateListingFailure>(ListingActionTypesEnum.CreateListingFailure);

// Reducer
function createListingFailureReducer(state: ListingReducerState, _action: PayloadAction<CreateListingFailurePayloadType>) {
  // state.listings = ReduxUtil.makeCache<IListingGraphQL | IListingParams>(action.payload.listings, state.listings);
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.CreateListing]: false }));
  return state;
}

/** ******************************************************************************
 *  Update Listing
 ****************************************************************************** */

// Payload
type UpdateListingPayloadType = {
  listing: IListingGraphQL;
  toast?: boolean;
};

// Action
const updateListing = createAction<UpdateListingPayloadType, ListingActionTypesEnum.UpdateListing>(ListingActionTypesEnum.UpdateListing);

// Reducer
function updateListingReducer(state: ListingReducerState, _action: PayloadAction<UpdateListingPayloadType>) {
  // state.listings = ReduxUtil.makeCache<IListingGraphQL | IListingParams>(action.payload.listings, state.listings);
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.UpdateListing]: true }));
  return state;
}

/** ******************************************************************************
 *  Update Listing Success
 ****************************************************************************** */

// Payload
type UpdateListingSuccessPayloadType = {
  listing: IListingGraphQL;
};

// Action
const updateListingSuccess = createAction<UpdateListingSuccessPayloadType, ListingActionTypesEnum.UpdateListingSuccess>(ListingActionTypesEnum.UpdateListingSuccess);

// Reducer
function updateListingSuccessReducer(state: ListingReducerState, action: PayloadAction<UpdateListingSuccessPayloadType>) {
  const { listing } = action.payload;
  state.listings = ReduxUtil.makeCache<IListingGraphQL>([listing], state.listings);
  state.errors = {};
  state = selectListingReducer(state, selectListing({ listingId: listing._id }));
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.UpdateListing]: false }));
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.PublishListing]: false }));
  return state;
}

/** ******************************************************************************
 *  Update Listing Failure
 ****************************************************************************** */

// Payload
type UpdateListingFailurePayloadType = {
  listings?: IListingGraphQL[];
  errors: ListingReducerState['errors'];
};

// Action
const updateListingFailure = createAction<UpdateListingFailurePayloadType, ListingActionTypesEnum.UpdateListingFailure>(ListingActionTypesEnum.UpdateListingFailure);

// Reducer
function updateListingFailureReducer(state: ListingReducerState, action: PayloadAction<UpdateListingFailurePayloadType>) {
  // state.listings = ReduxUtil.makeCache<IListingGraphQL | IListingParams>(action.payload.listings, state.listings);
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.UpdateListing]: false }));
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.PublishListing]: false }));
  state.errors = action?.payload?.errors ?? {};
  return state;
}

/** ******************************************************************************
 *  Update Draft
 ****************************************************************************** */

// Payload
type UpdateDraftPayloadType = {
  listing: IListingGraphQL;
  toast?: boolean;
};

// Action
const updateDraft = createAction<UpdateDraftPayloadType, ListingActionTypesEnum.UpdateDraft>(ListingActionTypesEnum.UpdateDraft);

// Reducer
function updateDraftReducer(state: ListingReducerState, _action: PayloadAction<UpdateDraftPayloadType>) {
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.UpdateDraft]: true }));
  return state;
}

/** ******************************************************************************
 *  Update Draft Success
 ****************************************************************************** */

// Payload
type UpdateDraftSuccessPayloadType = {
  listing: IListingGraphQL;
};

// Action
const updateDraftSuccess = createAction<UpdateDraftSuccessPayloadType, ListingActionTypesEnum.UpdateDraftSuccess>(ListingActionTypesEnum.UpdateDraftSuccess);

// Reducer
function updateDraftSuccessReducer(state: ListingReducerState, action: PayloadAction<UpdateDraftSuccessPayloadType>) {
  const { listing } = action.payload;
  state.listings = ReduxUtil.makeCache<IListingGraphQL>([listing], state.listings);
  state = selectListingReducer(state, selectListing({ listingId: listing._id }));
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.UpdateDraft]: false }));
  return state;
}

/** ******************************************************************************
 *  Update Draft Failure
 ****************************************************************************** */

// Payload
type UpdateDraftFailurePayloadType = {
  listings?: IListingGraphQL[];
  errors: ListingReducerState['errors'];
};

// Action
const updateDraftFailure = createAction<UpdateDraftFailurePayloadType, ListingActionTypesEnum.UpdateDraftFailure>(ListingActionTypesEnum.UpdateDraftFailure);

// Reducer
function updateDraftFailureReducer(state: ListingReducerState, action: PayloadAction<UpdateDraftFailurePayloadType>) {
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.UpdateDraft]: false }));
  state.errors = action?.payload?.errors ?? {};
  return state;
}

/** ******************************************************************************
 *  Publish Listing
 ****************************************************************************** */

// Payload
type PublishListingPayloadType = {
  listing: IListingGraphQL;
  publishAt: number | null;
  expiresAt: number | null;
  isPublishTask?: boolean;
 }

// Action
const publishListing = createAction<PublishListingPayloadType, ListingActionTypesEnum.PublishListing>(ListingActionTypesEnum.PublishListing);

// Reducer
function publishListingReducer(state: ListingReducerState, _action: PayloadAction<PublishListingPayloadType>) {
  // state.listings = ReduxUtil.makeCache<IListingGraphQL | IListingParams>(action.payload.listings, state.listings);
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.PublishListing]: true }));
  return state;
}

/** ******************************************************************************
 *  Publish Listing Success
 ****************************************************************************** */

// Payload
type PublishListingSuccessPayloadType = {
  listing: IListingGraphQL;
};

// Action
const publishListingSuccess = createAction<PublishListingSuccessPayloadType, ListingActionTypesEnum.PublishListingSuccess>(ListingActionTypesEnum.PublishListingSuccess);

// Reducer
function publishListingSuccessReducer(state: ListingReducerState, action: PayloadAction<PublishListingSuccessPayloadType>) {
  const { listing } = action.payload;
  state.listings = ReduxUtil.makeCache<IListingGraphQL>([listing], state.listings);
  state.errors = {};
  state = selectListingReducer(state, selectListing({ listingId: listing._id }));
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.PublishListing]: false }));
  return state;
}

/** ******************************************************************************
 *  Publish Listing Failure
 ****************************************************************************** */

// Payload
type PublishListingFailurePayloadType = {
  errors: ListingReducerState['errors'];
};

// Action
const publishListingFailure = createAction<PublishListingFailurePayloadType, ListingActionTypesEnum.PublishListingFailure>(ListingActionTypesEnum.PublishListingFailure);

// Reducer
function publishListingFailureReducer(state: ListingReducerState, action: PayloadAction<PublishListingFailurePayloadType>) {
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.PublishListing]: false }));
  state.errors = action?.payload?.errors ?? {};
  return state;
}

/** ******************************************************************************
 *  Reset Listing Errors
 ****************************************************************************** */

// Payload
type ResetListingErrorsPayloadType = {};

// Actions
const resetListingErrors = createAction<ResetListingErrorsPayloadType, ListingActionTypesEnum.ResetListingErrors>(ListingActionTypesEnum.ResetListingErrors);

// Reducer
function resetListingErrorsReducer(state: ListingReducerState, _action: PayloadAction<ResetListingErrorsPayloadType>) {
  state.errors = {};
  return state;
}

/** ******************************************************************************
 *  Set Listing State
 ****************************************************************************** */

// Payload
type SetListingStatePayloadType = {
  listingState: IListingService.ISetListingStateParams;
  toast?: boolean | null;
}

// Action
const setListingState = createAction<SetListingStatePayloadType, ListingActionTypesEnum.SetListingState>(ListingActionTypesEnum.SetListingState);

// Reducer
function setListingStateReducer(state: ListingReducerState, _action: PayloadAction<SetListingStatePayloadType>) {
  // state.listings = ReduxUtil.makeCache<IListingGraphQL | IListingParams>(action.payload.listings, state.listings);
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.SetListingState]: true }));
  return state;
}

/** ******************************************************************************
 *  Set Listing State Success
 ****************************************************************************** */

// Payload
type SetListingStateSuccessPayloadType = {
  listing: IListingGraphQL;
};

// Action
const setListingStateSuccess = createAction<SetListingStateSuccessPayloadType, ListingActionTypesEnum.SetListingStateSuccess>(ListingActionTypesEnum.SetListingStateSuccess);

// Reducer
function setListingStateSuccessReducer(state: ListingReducerState, action: PayloadAction<SetListingStateSuccessPayloadType>) {
  const { listing } = action.payload;
  state.listings = ReduxUtil.makeCache<IListingGraphQL>([listing], state.listings);
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.SetListingState]: false }));
  return state;
}

/** ******************************************************************************
 *  Set Listing State Failure
 ****************************************************************************** */

// Payload
type SetListingStateFailurePayloadType = {
  listings: IListingGraphQL[];
};

// Action
const setListingStateFailure = createAction<SetListingStateFailurePayloadType, ListingActionTypesEnum.SetListingStateFailure>(ListingActionTypesEnum.SetListingStateFailure);

// Reducer
function setListingStateFailureReducer(state: ListingReducerState, _action: PayloadAction<SetListingStateFailurePayloadType>) {
  // state.listings = ReduxUtil.makeCache<IListingGraphQL | IListingParams>(action.payload.listings, state.listings);
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.SetListingState]: false }));
  return state;
}

/** ******************************************************************************
 *  Delete Listing
 ****************************************************************************** */

// Payload
type DeleteListingPayloadType = {
  listingId: string;
};

// Action
const deleteListing = createAction<DeleteListingPayloadType, ListingActionTypesEnum.DeleteListing>(ListingActionTypesEnum.DeleteListing);

// Reducer
function deleteListingReducer(state: ListingReducerState, action: PayloadAction<DeleteListingPayloadType>) {
  delete state.listings[action.payload.listingId];
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.DeleteListing]: true }));

  return state;
}

/** ******************************************************************************
 *  Delete Listing Success
 ****************************************************************************** */

// Payload
type DeleteListingSuccessPayloadType = null;

// Action
const deleteListingSuccess = createAction<DeleteListingSuccessPayloadType, ListingActionTypesEnum.DeleteListingSuccess>(ListingActionTypesEnum.DeleteListingSuccess);

// Reducer
function deleteListingSuccessReducer(state: ListingReducerState, _action: PayloadAction<DeleteListingSuccessPayloadType>) {
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.DeleteListing]: false }));
  return state;
}

/** ******************************************************************************
 *  Delete Listing Failure
 ****************************************************************************** */

// Payload
type DeleteListingFailurePayloadType = null;

// Action
const deleteListingFailure = createAction<DeleteListingFailurePayloadType, ListingActionTypesEnum.DeleteListingFailure>(ListingActionTypesEnum.DeleteListingFailure);

// Reducer
function deleteListingFailureReducer(state: ListingReducerState, _action: PayloadAction<DeleteListingFailurePayloadType>) {
  state = setListingLoadingReducer(state, setListingLoading({ [ListingLoaderTypesEnum.DeleteListing]: false }));
  return state;
}

/** ******************************************************************************
 *  Preview Listing Vault
 ****************************************************************************** */

// Payload
type PreviewListingVaultPayloadType = {
  listingId: string;
  fileId: string;
  isCAFile?: boolean;
};

// Action
const previewListingVault = createAction<PreviewListingVaultPayloadType, ListingActionTypesEnum.PreviewListingVault>(ListingActionTypesEnum.PreviewListingVault);

// Reducer
function previewListingVaultReducer(state: ListingReducerState, _action: PayloadAction<PreviewListingVaultPayloadType>) {
  return state;
}

/** ******************************************************************************
 *  Preview Listing Video
 ****************************************************************************** */

// Payload
type PreviewListingVideoPayloadType = {
  listingId: string;
  video: IVideoGraphQL
};

// Action
const previewListingVideo = createAction<PreviewListingVideoPayloadType, ListingActionTypesEnum.PreviewListingVideo>(ListingActionTypesEnum.PreviewListingVideo);

// Reducer
function previewListingVideoReducer(state: ListingReducerState, _action: PayloadAction<PreviewListingVideoPayloadType>) {
  return state;
}

/** ******************************************************************************
 *  Preview Listing Images
 ****************************************************************************** */

// Payload
type PreviewListingImagesPayloadType = {
  listingId: string;
  fileId: string;
};

// Action
const previewListingImages = createAction<PreviewListingImagesPayloadType, ListingActionTypesEnum.PreviewListingImages>(ListingActionTypesEnum.PreviewListingImages);

// Reducer
function previewListingImagesReducer(state: ListingReducerState, _action: PayloadAction<PreviewListingImagesPayloadType>) {
  return state;
}

/** ******************************************************************************
 *  Set Listing Field
 ****************************************************************************** */

// Payload
type SetListingFieldPayloadType = Partial<IListingGraphQL>;

// Action
const setListingField = createAction<SetListingFieldPayloadType, ListingActionTypesEnum.SetListingField>(ListingActionTypesEnum.SetListingField);

// Reducer
function setListingFieldReducer(state: ListingReducerState, action: PayloadAction<SetListingFieldPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];
  listings[listingId] = {
    ...listing,
    ...action.payload,
  };

  return state;
}

/** ******************************************************************************
 *  Set Listing Address
 ****************************************************************************** */

// Payload
type SetListingAddressPayloadType = Partial<IAddress>;

// Action
const setListingAddress = createAction<SetListingAddressPayloadType, ListingActionTypesEnum.SetListingAddress>(ListingActionTypesEnum.SetListingAddress);

// Reducer
function setListingAddressReducer(state: ListingReducerState, action: PayloadAction<SetListingAddressPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  listings[listingId].address = {
    ...listing.address,
    ...action.payload,
  };

  return state;
}

/** ******************************************************************************
 *  Set Listing ID
 ****************************************************************************** */

// Payload
type SetListingIdPayloadType = {
  listingId: string;
};

// Action
const setListingId = createAction<SetListingIdPayloadType, ListingActionTypesEnum.SetListingId>(ListingActionTypesEnum.SetListingId);

// Reducer
function setListingIdReducer(state: ListingReducerState, action: PayloadAction<SetListingIdPayloadType>) {
  state.listingId = action.payload.listingId;

  return state;
}

/** ******************************************************************************
 *  Set Listing Address Location
 ****************************************************************************** */

// Payload
type SetListingAddressLocationPayloadType = Partial<ILocation>;

// Action
const setListingAddressLocation = createAction<SetListingAddressLocationPayloadType, ListingActionTypesEnum.SetListingAddressLocation>(ListingActionTypesEnum.SetListingAddressLocation);

// Reducer
function setListingAddressLocationReducer(state: ListingReducerState, action: PayloadAction<SetListingAddressLocationPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  listings[listingId].address.location = {
    ...listing.address.location,
    ...action.payload,
  };

  return state;
}

/** ******************************************************************************
 *  Set Listing Cherre ID
 ****************************************************************************** */

// Payload
type SetListingCherreIdPayloadType = Partial<IListingGraphQL>;

// Action
const setListingCherreId = createAction<SetListingCherreIdPayloadType, ListingActionTypesEnum.SetListingCherreId>(ListingActionTypesEnum.SetListingCherreId);

// Reducer
function setListingCherreIdReducer(state: ListingReducerState, action: PayloadAction<SetListingCherreIdPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  listings[listingId].address = {
    ...listing.address,
    ...action.payload,
  };

  return state;
}

/** ******************************************************************************
 *  Set Saved Search
 ****************************************************************************** */
// Payload
type CacheSavedSearchPayloadType = Partial<ISavedSearch>

// Action
const cacheSavedSearch = createAction<CacheSavedSearchPayloadType, ListingActionTypesEnum.SetSavedSearch>(ListingActionTypesEnum.SetSavedSearch);

// Reducer
function cacheSavedSearchReducer(state: ListingReducerState, action: PayloadAction<CacheSavedSearchPayloadType>) {
  state.savedSearch = {
    ...state.savedSearch,
    ...action.payload,
  };
  return state;
}
/** ******************************************************************************
 *  Remove Saved Search
 ****************************************************************************** */
// Payload
type RemoveSavedSearchPayloadType = {}

// Action
const removeSavedSearch = createAction<RemoveSavedSearchPayloadType, ListingActionTypesEnum.RemoveSavedSearch>(ListingActionTypesEnum.RemoveSavedSearch);

// Reducer
function removeSavedSearchReducer(state: ListingReducerState) {
  state.savedSearch = null;
  return state;
}

/** ******************************************************************************
 *  Set Listing Guidance Field
 ****************************************************************************** */

// Payload
type SetListingGuidanceFieldPayloadType = Partial<IListingGuidance>;

// Action
const setListingGuidanceField = createAction<SetListingGuidanceFieldPayloadType, ListingActionTypesEnum.SetListingGuidanceField>(ListingActionTypesEnum.SetListingGuidanceField);

// Reducer
function setListingGuidanceFieldReducer(state: ListingReducerState, action: PayloadAction<SetListingGuidanceFieldPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  listings[listingId].guidance = {
    ...listing.guidance,
    ...action.payload,
  };

  return state;
}

/** ******************************************************************************
 *  Set Listing Media Field
 ****************************************************************************** */

// Payload
type SetListingMediaFieldPayloadType = Partial<IListingMediaGraphQL>;

// Action
const setListingMediaField = createAction<SetListingMediaFieldPayloadType, ListingActionTypesEnum.SetListingMediaField>(ListingActionTypesEnum.SetListingMediaField);

// Reducer
function setListingMediaFieldReducer(state: ListingReducerState, action: PayloadAction<SetListingMediaFieldPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  listings[listingId].media = {
    ...listing.media,
    ...action.payload,
  };

  return state;
}

/** ******************************************************************************
 *  Add Listing Media Files
 ****************************************************************************** */

// Payload
type AddListingMediaFilesPayloadType = {
  files: IFile[];
};

// Action
const addListingMediaFiles = createAction<AddListingMediaFilesPayloadType, ListingActionTypesEnum.AddListingMediaFiles>(ListingActionTypesEnum.AddListingMediaFiles);

// Reducer
function addListingMediaFilesReducer(state: ListingReducerState, action: PayloadAction<AddListingMediaFilesPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const {
    payload: {
      files,
    },
  } = action;

  if (!(listing?.media?.files)) {
    listing.media.files = [];
  }

  listing?.media?.files?.push(...files);
  listing?.media?.fileIds?.push(...files.map((file) => file._id));

  return state;
}

/** ******************************************************************************
 *  Delete Listing Media File
 ****************************************************************************** */

// Payload
type DeleteListingMediaFilesPayloadType = {
  fileId: string;
};

// Action
const deleteListingMediaFiles = createAction<DeleteListingMediaFilesPayloadType, ListingActionTypesEnum.DeleteListingMediaFiles>(ListingActionTypesEnum.DeleteListingMediaFiles);

// Reducer
function deleteListingMediaFilesReducer(state: ListingReducerState, action: PayloadAction<DeleteListingMediaFilesPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const {
    payload: {
      fileId,
    },
  } = action;

  listing.media.fileIds = [...listing.media.fileIds.filter((id: string) => id !== fileId)];
  listing.media.files = [...listing.media.files.filter((file) => file._id !== fileId)];

  return state;
}

/** ******************************************************************************
 *  Swap Files
 ****************************************************************************** */

// Payload
type SwapListingMediaFilesAfterCropPayloadType = {
  originalFileId: string;
  newFileId: string;
  newFile: IFile;
};

// Action
const swapListingMediaFilesAfterCrop = createAction<SwapListingMediaFilesAfterCropPayloadType, ListingActionTypesEnum.SwapListingMediaFilesAfterCrop>(ListingActionTypesEnum.SwapListingMediaFilesAfterCrop);

// Reducer
function swapListingMediaFilesAfterCropReducer(state: ListingReducerState, action: PayloadAction<SwapListingMediaFilesAfterCropPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const {
    payload: {
      originalFileId,
      newFileId,
      newFile,
    },
  } = action;

  listing.media.fileIds[listing.media.fileIds.indexOf(originalFileId)] = newFileId;
  listing.media.files[listing.media.files.findIndex((file) => file._id === originalFileId)] = newFile;

  return state;
}

/** ******************************************************************************
 *  ReOrder Listing Media Files
 ****************************************************************************** */

// Payload
type ReOrderListingMediaFilesPayloadType = {
  oldIndex: number;
  newIndex: number;
};

// Action
const reOrderListingMediaFiles = createAction<ReOrderListingMediaFilesPayloadType, ListingActionTypesEnum.ReOrderListingMediaFiles>(ListingActionTypesEnum.ReOrderListingMediaFiles);

// Reducer
function reOrderListingMediaFilesReducer(state: ListingReducerState, action: PayloadAction<ReOrderListingMediaFilesPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const {
    payload: {
      oldIndex,
      newIndex,
    },
  } = action;

  listing.media.fileIds = ReduxUtil.moveArrayItem(listing.media.fileIds, oldIndex, newIndex);
  listing.media.files = ReduxUtil.moveArrayItem(listing.media.files, oldIndex, newIndex);

  return state;
}

/** ******************************************************************************
 *  Set Listing Media Video
 ****************************************************************************** */

// Payload
type SetListingMediaVideoPayloadType = {
  video: IVideoGraphQL;
};

// Action
const setListingMediaVideo = createAction<SetListingMediaVideoPayloadType, ListingActionTypesEnum.SetListingMediaVideo>(ListingActionTypesEnum.SetListingMediaVideo);

// Reducer
function setListingMediaVideoReducer(state: ListingReducerState, action: PayloadAction<SetListingMediaVideoPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const {
    payload: {
      video,
    },
  } = action;

  if (video) {
    listing.media.video = {
      fileId: video.file._id,
      file: video.file,
    };
  } else {
    listing.media.video = {
      fileId: null,
      file: null,
    };
  }

  return state;
}

/** ******************************************************************************
 *  Set Listing Asset Class
 ****************************************************************************** */

// Payload
type SetListingAssetClassPayloadType = {
  assetClass: ListingAssetClassEnum;
};

// Action
const setListingAssetClass = createAction<SetListingAssetClassPayloadType, ListingActionTypesEnum.SetListingAssetClass>(ListingActionTypesEnum.SetListingAssetClass);

// Reducer
function setListingAssetClassReducer(state: ListingReducerState, action: PayloadAction<SetListingAssetClassPayloadType>) {
  const { listings, listingId } = state;
  const { assetClass } = action.payload;
  const listing = listings[listingId];

  if (assetClass !== listing.assetClass) {
    listing.assetClass = assetClass;
    listing.propertyType = null;
    listing.info = [];
  }

  return state;
}

/** ******************************************************************************
 *  Set Listing Property Type
 ****************************************************************************** */

// Payload
type SetListingPropertyTypePayloadType = {
  propertyType: ListingPropertyTypeEnum;
};

// Action
const setListingPropertyType = createAction<SetListingPropertyTypePayloadType, ListingActionTypesEnum.SetListingPropertyType>(ListingActionTypesEnum.SetListingPropertyType);

// Reducer
function setListingPropertyTypeReducer(state: ListingReducerState, action: PayloadAction<SetListingPropertyTypePayloadType>) {
  const { listings, listingId } = state;
  const { propertyType } = action.payload;
  const listing = listings[listingId];

  listing.propertyType = propertyType;
  listing.info = ListingUtil.listingInfoFieldState(listing.propertyType, listing.info);

  return state;
}

/** ******************************************************************************
 *  Set Listing Score
 ****************************************************************************** */

// Payload
type SetListingScorePayloadType = {
  score: number;
};

// Action
const setListingScore = createAction<SetListingScorePayloadType, ListingActionTypesEnum.SetListingScore>(ListingActionTypesEnum.SetListingScore);

// Reducer
function setListingScoreReducer(state: ListingReducerState, action: PayloadAction<SetListingScorePayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  listing.score = action.payload.score;

  return state;
}

/** ******************************************************************************
 *  Set Listing InfoFields for Cherre Autocomplete
 ****************************************************************************** */

// Payload
type SetListingInfoFieldsPayloadType = {
  info: TListingInfoField[];
}

// Action
const setListingInfoFields = createAction<SetListingInfoFieldsPayloadType, ListingActionTypesEnum.SetListingInfoFields>(ListingActionTypesEnum.SetListingInfoFields);

// Reducer
function setListingInfoFieldsReducer(state: ListingReducerState, action: PayloadAction<SetListingInfoFieldsPayloadType>) {
  const { listings, listingId } = state;
  const newInfo = action.payload.info;
  const listing = listings[listingId];

  listing.info = ListingUtil.listingInfoFieldsAutocompleteState(listing.info, newInfo);

  return state;
}

/** ******************************************************************************
 *  Set Listing Info Field
 ****************************************************************************** */

// Payload
type SetListingInfoFieldPayloadType = {
  info: TListingInfoField;
};

// Action
const setListingInfoField = createAction<SetListingInfoFieldPayloadType, ListingActionTypesEnum.SetListingInfoField>(ListingActionTypesEnum.SetListingInfoField);

// Reducer
function setListingInfoFieldReducer(state: ListingReducerState, action: PayloadAction<SetListingInfoFieldPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  listing.info = listing.info.map((info) => {
    const newInfo = action.payload.info;
    if (info.fieldName === newInfo.fieldName) {
      return {
        ...info,
        ...newInfo,
      };
    }
    return info;
  });

  return state;
}

/** ******************************************************************************
 *  Add Listing Vault Sub-folder
 ****************************************************************************** */

// Payload
type AddListingVaultSubFolderPayloadType = {
  folderName: ListingVaultFolderNamesEnum;
  count: number;
};

// Action
const addListingVaultSubFolder = createAction<AddListingVaultSubFolderPayloadType, ListingActionTypesEnum.AddListingVaultSubFolder>(ListingActionTypesEnum.AddListingVaultSubFolder);

// Reducer
function addListingVaultSubFolderReducer(state: ListingReducerState, action: PayloadAction<AddListingVaultSubFolderPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const {
    payload: {
      folderName,
      count,
    },
  } = action;

  const targetFolder = listing.vault.folders.find((folder) => folder.name === folderName);
  const subFolders = targetFolder.subFolders ?? [];
  const newSubFolder = {
    name: `Untitled${count}`,
    subFolderId: shortid.generate(),
    fileIds: [],
    files: [],
    subFolders: [],
  };

  targetFolder.subFolders = [
    ...subFolders,
    newSubFolder,
  ];

  return state;
}

/** ******************************************************************************
 *  Remove Listing Vault Sub-Folder
 ****************************************************************************** */

// Payload
type RemoveListingVaultSubFolderPayloadType = {
  folderName: string;
};

// Action
const removeListingVaultSubFolder = createAction<RemoveListingVaultSubFolderPayloadType, ListingActionTypesEnum.RemoveListingVaultSubFolder>(ListingActionTypesEnum.RemoveListingVaultSubFolder);

// Reducer
function removeListingVaultSubFolderReducer(state: ListingReducerState, action: PayloadAction<RemoveListingVaultSubFolderPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const {
    payload: {
      folderName,
    },
  } = action;

  const targetFolder = listing.vault.folders.find((folder) => folder.subFolders.find((subFolder) => subFolder.name === folderName));
  const newSubFolders = targetFolder.subFolders.filter((folder) => folder.name !== folderName);

  targetFolder.subFolders = newSubFolders;

  return state;
}

/** ******************************************************************************
 *  Rename Listing Vault Sub-Folder
 ****************************************************************************** */

// Payload
type RenameListingVaultSubFolderPayloadType = {
  subFolderId: string;
  newName: string;
};

// Action
const renameListingVaultSubFolder = createAction<RenameListingVaultSubFolderPayloadType, ListingActionTypesEnum.RenameListingVaultSubFolder>(ListingActionTypesEnum.RenameListingVaultSubFolder);

// Reducer
function renameListingVaultSubFolderReducer(state: ListingReducerState, action: PayloadAction<RenameListingVaultSubFolderPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const {
    payload: {
      subFolderId,
      newName,
    },
  } = action;

  const targetFolder = listing.vault.folders.find((folder) => folder.subFolders?.find((subFolder) => subFolder.subFolderId === subFolderId));

  targetFolder.subFolders = targetFolder?.subFolders?.map((subFolder) => {
    if (subFolder.subFolderId === subFolderId) {
      return {
        ...subFolder,
        name: newName,
      };
    }
    return subFolder;
  });

  return state;
}

/** ******************************************************************************
 *  Add Listing Vault Files
 ****************************************************************************** */

// Payload
type AddListingVaultFilesPayloadType = {
  folderName: ListingVaultFolderNamesEnum;
  files: IFile[];
};

// Action
const addListingVaultFiles = createAction<AddListingVaultFilesPayloadType, ListingActionTypesEnum.AddListingVaultFiles>(ListingActionTypesEnum.AddListingVaultFiles);

// Reducer
function addListingVaultFilesReducer(state: ListingReducerState, action: PayloadAction<AddListingVaultFilesPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const {
    payload: {
      folderName,
      files,
    },
  } = action;

  listing.vault.folders = listing.vault.folders.map((vaultFolder: IListingVaultFolderGraphQL): IListingVaultFolderGraphQL => {
    if (vaultFolder.name === folderName) {
      vaultFolder.files.push(...files);
      vaultFolder.fileIds.push(...files.map((file) => file._id));
    }
    return vaultFolder;
  });

  return state;
}

/** ******************************************************************************
 *  Move Listing Vault File
 ****************************************************************************** */

// Payload
type MoveListingVaultFilePayloadType = {
  fileId: string;
  source: ListingVaultFolderNamesEnum | string;
  destination: ListingVaultFolderNamesEnum | string;
};

// Action
const moveListingVaultFile = createAction<MoveListingVaultFilePayloadType, ListingActionTypesEnum.MoveListingVaultFile>(ListingActionTypesEnum.MoveListingVaultFile);

// Reducer
function moveListingVaultFileReducer(state: ListingReducerState, action: PayloadAction<MoveListingVaultFilePayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const {
    payload: {
      fileId,
      source,
      destination,
    },
  } = action;

  /** find file in source folder, create reference, and delete from source folder */
  let fileToMove;
  const isSourceRoot = Boolean(ListingVaultFolderNamesEnum[StringUtil.removeSpaces(source)]);
  const isDestinationRoot = Boolean(ListingVaultFolderNamesEnum[StringUtil.removeSpaces(destination)]);

  if (isSourceRoot) {
    const sourceFolder = listing.vault.folders.find((folder) => folder.name === source);
    fileToMove = sourceFolder.files.find((file) => file._id === fileId);
    sourceFolder.files = sourceFolder.files.filter((file) => file._id !== fileId);
    sourceFolder.fileIds = sourceFolder.fileIds.filter((_fileId) => _fileId !== fileId);
  } else {
    const rootFolder = listing.vault.folders.find((folder) => folder.subFolders?.find((subFolder) => subFolder.name === source));
    const sourceFolder = rootFolder.subFolders.find((folder) => folder.name === source);
    fileToMove = sourceFolder.files.find((file) => file._id === fileId);
    sourceFolder.files = sourceFolder.files.filter((file) => file._id !== fileId);
    sourceFolder.fileIds = sourceFolder.fileIds.filter((_fileId) => _fileId !== fileId);
  }

  /** add file to destination folder */
  if (isDestinationRoot) {
    const destinationFolder = listing.vault.folders.find((folder) => folder.name === destination);
    destinationFolder.files.push(fileToMove);
    destinationFolder.fileIds.push(fileToMove._id);
  } else {
    const rootFolder = listing.vault.folders.find((folder) => folder.subFolders?.find((subFolder) => subFolder.name === destination));
    const destinationFolder = rootFolder.subFolders.find((folder) => folder.name === destination);
    destinationFolder.files.push(fileToMove);
    destinationFolder.fileIds.push(fileToMove._id);
  }

  return state;
}

/** ******************************************************************************
 *  Add Listing Vault CA File
 ****************************************************************************** */

// Payload
type AddListingVaultCAFilePayloadType = {
  file: IFile,
}

// Action
const addListingVaultCAFile = createAction<AddListingVaultCAFilePayloadType, ListingActionTypesEnum.AddListingVaultCAFile>(ListingActionTypesEnum.AddListingVaultCAFile);

// Reducer
function addListingVaultCAFileReducer(state: ListingReducerState, action: PayloadAction<AddListingVaultCAFilePayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const {
    payload: {
      file,
    },
  } = action;

  listing.vault.caFile = file;
  listing.vault.caFileId = file._id;

  return state;
}

/** ******************************************************************************
 *  Remove Listing Vault CA File
 ****************************************************************************** */

// Payload
type RemoveListingVaultCAFilePayloadType = {}

// Action
const removeListingVaultCAFile = createAction<RemoveListingVaultCAFilePayloadType, ListingActionTypesEnum.RemoveListingVaultCAFile>(ListingActionTypesEnum.RemoveListingVaultCAFile);

// Reducer
function removeListingVaultCAFileReducer(state: ListingReducerState) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  listing.vault.caFile = null;
  listing.vault.caFileId = '';

  return state;
}

/** ******************************************************************************
 *  Remove Listing Vault Files
 ****************************************************************************** */

// Payload
type RemoveListingVaultFilesPayloadType = {
  folderName: ListingVaultFolderNamesEnum | string;
  fileIds: string[];
};

// Action
const removeListingVaultFiles = createAction<RemoveListingVaultFilesPayloadType, ListingActionTypesEnum.RemoveListingVaultFiles>(ListingActionTypesEnum.RemoveListingVaultFiles);

// Reducer
function removeListingVaultFilesReducer(state: ListingReducerState, action: PayloadAction<RemoveListingVaultFilesPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const {
    payload: {
      folderName,
      fileIds,
    },
  } = action;

  const isRootFolder = Boolean(ListingVaultFolderNamesEnum[StringUtil.removeSpaces(folderName)]);

  if (isRootFolder) {
    listing.vault.folders = listing.vault.folders.map((vaultFolder: IListingVaultFolderGraphQL): IListingVaultFolderGraphQL => {
      if (vaultFolder.name === folderName) {
        vaultFolder.fileIds = ReduxUtil.removeArrayItems(vaultFolder.fileIds, fileIds, null);
        vaultFolder.files = ReduxUtil.removeArrayItems(vaultFolder.files, fileIds);
      }
      return vaultFolder;
    });
  } else {
    const rootFolder = listing.vault.folders.find((folder) => folder.subFolders?.find((subFolder) => subFolder.name === folderName));
    rootFolder.subFolders = rootFolder.subFolders.map((vaultFolder: IListingVaultFolderGraphQL): IListingVaultFolderGraphQL => {
      if (vaultFolder.name === folderName) {
        vaultFolder.fileIds = ReduxUtil.removeArrayItems(vaultFolder.fileIds, fileIds, null);
        vaultFolder.files = ReduxUtil.removeArrayItems(vaultFolder.files, fileIds);
      }
      return vaultFolder;
    });
  }

  return state;
}

/** ******************************************************************************
 *  ReOrder Listing Vault Files
 ****************************************************************************** */

// Payload
type ReOrderListingVaultFilesPayloadType = {
  folderName: ListingVaultFolderNamesEnum | string;
  currentFileIndex: number;
  newFileIndex: number;
  currentFileId?: string;
  newFileId?: string;
};

// Action
const reOrderListingVaultFile = createAction<ReOrderListingVaultFilesPayloadType, ListingActionTypesEnum.ReOrderListingVaultFile>(ListingActionTypesEnum.ReOrderListingVaultFile);

// Reducer
function reOrderListingVaultFileReducer(state: ListingReducerState, action: PayloadAction<ReOrderListingVaultFilesPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const {
    payload: {
      folderName,
      currentFileIndex,
      newFileIndex,
    },
  } = action;

  const isRootFolder = Boolean(ListingVaultFolderNamesEnum[StringUtil.removeSpaces(folderName)]);

  if (isRootFolder) {
    listing.vault.folders = listing.vault.folders.map((vaultFolder: IListingVaultFolderGraphQL): IListingVaultFolderGraphQL => {
      if (vaultFolder.name === folderName) {
        vaultFolder.fileIds = ReduxUtil.moveArrayItem(vaultFolder.fileIds, currentFileIndex, newFileIndex);
        vaultFolder.files = ReduxUtil.moveArrayItem(vaultFolder.files, currentFileIndex, newFileIndex);
      }
      return vaultFolder;
    });
  } else {
    logger.info('figure out re-arranging sub-folder files later');
  }

  return state;
}

/** ******************************************************************************
 *  Set Listing Highlights
 ****************************************************************************** */

// Payload
type SetListingHighlightsPayloadType = {
  highlights: string[];
};

// Action
const setListingHighlights = createAction<SetListingHighlightsPayloadType, ListingActionTypesEnum.SetListingHighlights>(ListingActionTypesEnum.SetListingHighlights);

// Reducer
function setListingHighlightsReducer(state: ListingReducerState, action: PayloadAction<SetListingHighlightsPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  listing.highlights = action.payload.highlights;

  return state;
}

/** ******************************************************************************
 *  Set Listing Contacts
 ****************************************************************************** */

// Payload
type SetListingContactsPayloadType = {
  contacts: IListingContact[];
};

// Action
const setListingContacts = createAction<SetListingContactsPayloadType, ListingActionTypesEnum.SetListingContacts>(ListingActionTypesEnum.SetListingContacts);

// Reducer
function setListingContactsReducer(state: ListingReducerState, action: PayloadAction<SetListingContactsPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  listing.contacts = action.payload.contacts;

  return state;
}

/** ******************************************************************************
 *  Set Listing 'Listed By Owner'
 ****************************************************************************** */

// Payload
type SetListingListedByOwnerPayloadType = {
  listedByOwner: boolean;
};

// Action
const setListingListedByOwner = createAction<SetListingListedByOwnerPayloadType, ListingActionTypesEnum.SetListingListedByOwner>(ListingActionTypesEnum.SetListingListedByOwner);

// Reducer
function setListingListedByOwnerReducer(state: ListingReducerState, action: PayloadAction<SetListingListedByOwnerPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  listing.listedByOwner = action.payload.listedByOwner;
  return state;
}

/** ******************************************************************************
 *  Set Listing Counts
 ****************************************************************************** */

// Payload
type SetListingCountsPayloadType = IListingCounts;

// Action
const setListingCounts = createAction<SetListingCountsPayloadType, ListingActionTypesEnum.SetListingCounts>(ListingActionTypesEnum.SetListingCounts);

// Reducer
function setListingCountsReducer(state: ListingReducerState, action: PayloadAction<SetListingCountsPayloadType>) {
  const { listingCounts } = state;

  const newListingCounts = {
    ...listingCounts,
    ...action.payload,
  };

  state.listingCounts = newListingCounts;

  return state;
}

/** ******************************************************************************
 *  Set Listing Deal Profiles Field
 ****************************************************************************** */

// Payload
type SetListingDealProfileFieldPayloadType = Partial<IListingDealProfile>;

// Action
const setListingDealProfileField = createAction<SetListingDealProfileFieldPayloadType, ListingActionTypesEnum.SetListingDealProfileField>(ListingActionTypesEnum.SetListingDealProfileField);

// Reducer
function setListingDealProfileFieldReducer(state: ListingReducerState, action: PayloadAction<SetListingDealProfileFieldPayloadType>) {
  const { listings, listingId } = state;

  const listing = listings[listingId];

  const dealProfile = listing.dealProfile ?? {} as IListingDealProfile;

  if (action.payload) {
    const updatedDealProfile = {
      ...dealProfile,
      ...action.payload,
    };
    listing.dealProfile = updatedDealProfile;
    return state;
  }

  listing.dealProfile = null;
  return state;
}

/** ******************************************************************************
 *  Set Video Upload In Progress
 ****************************************************************************** */

// Payload
type SetVideoUploadInProgressPayloadType = {
  videoUploadInProgress: boolean;
};

// Action
const setVideoUploadInProgress = createAction<SetVideoUploadInProgressPayloadType, ListingActionTypesEnum.SetVideoUploadInProgress>(ListingActionTypesEnum.SetVideoUploadInProgress);

// Reducer
function setVideoUploadInProgressReducer(state: ListingReducerState, action: PayloadAction<SetVideoUploadInProgressPayloadType>) {
  const { payload: { videoUploadInProgress } } = action;
  state.videoUploadInProgress = videoUploadInProgress;
  return state;
}

/** ******************************************************************************
 *  Exports
 ****************************************************************************** */

export const ListingSelectors = {
  selectedListingId: (state: AppState): ListingReducerState['listingId'] => {
    const { listing: { listingId } } = state;
    return listingId ?? null;
  },
  listing: (state: AppState, listingId: string): IListingGraphQL | null => {
    const { listing: { listings } } = state;
    return listings[listingId] ?? null;
  },
  selectedListing: (state: AppState, paramListingId?: string): IListingGraphQL | null => {
    const { listing: { listings, listingId } } = state;
    return listings[paramListingId ?? listingId] ?? null;
  },
  listingCache: (state: AppState): ListingReducerState['listings'] => state?.listing?.listings ?? {},
  loading: (state: AppState): ListingReducerState['loading'] => state?.listing?.loading ?? null,
  showPreview: (state: AppState): ListingReducerState['showPreview'] => state?.listing?.showPreview,
  search: (state: AppState): ListingReducerState['search'] => state?.listing?.search,
  searchQuery: (state: AppState): ListingReducerState['search']['query'] => state?.listing?.search?.query,
  errors: (state: AppState): ListingReducerState['errors'] => state?.listing?.errors ?? [],
  videoUploadInProgress: (state: AppState): ListingReducerState['videoUploadInProgress'] => state?.listing?.videoUploadInProgress,
  savedSearchQuery: (state: AppState): ListingReducerState['savedSearch'] => state?.listing?.savedSearch,
  listingCounts: (state: AppState): ListingReducerState['listingCounts'] => state?.listing?.listingCounts,
};

export type ListingActionPayloadTypes = {
  SelectListingPayloadType: SelectListingPayloadType;
  CacheListingPayloadType: CacheListingPayloadType;
  RecacheListingPayloadType: RecacheListingPayloadType;
  SetListingLoadingPayloadType: SetListingLoadingPayloadType;
  SetShowPreviewPayloadType: SetShowPreviewPayloadType;
  // Search Listings
  SetListingSearchPayloadType: SetListingSearchPayloadType;
  SetListingSearchQueryPayloadType: SetListingSearchQueryPayloadType;
  ResetListingSearchQueryPayloadType: ResetListingSearchQueryPayloadType;

  // Create Listing
  CreateListingPayloadType: CreateListingPayloadType;
  CreateListingSuccessPayloadType: CreateListingSuccessPayloadType;
  CreateListingFailurePayloadType: CreateListingFailurePayloadType;
  // Update Listing
  UpdateListingPayloadType: UpdateListingPayloadType;
  UpdateListingSuccessPayloadType: UpdateListingSuccessPayloadType;
  UpdateListingFailurePayloadType: UpdateListingFailurePayloadType;
  // Update Draft
  UpdateDraftPayloadType: UpdateDraftPayloadType;
  UploadDraftSuccessPayloadType: UpdateDraftSuccessPayloadType;
  UploadDraftFailurePayloadType: UpdateDraftFailurePayloadType;
  // Publish Listing
  PublishListingPayloadType: PublishListingPayloadType;
  PublishListingSuccessPayloadType: PublishListingSuccessPayloadType;
  PublishListingFailurePayloadType: PublishListingFailurePayloadType;
  // Set Listing State
  SetListingStatePayloadType: SetListingStatePayloadType;
  SetListingStateSuccessPayloadType: SetListingStateSuccessPayloadType;
  SetListingStateFailurePayloadType: SetListingStateFailurePayloadType;
  // Delete Listing
  DeleteListingPayloadType: DeleteListingPayloadType;
  DeleteListingSuccessPayloadType: DeleteListingSuccessPayloadType;
  DeleteListingFailurePayloadType: DeleteListingFailurePayloadType;
  // Listing Navigation
  PreviewListingVaultPayloadType: PreviewListingVaultPayloadType,
  PreviewListingVideoPayloadType: PreviewListingVideoPayloadType,
  PreviewListingImagesPayloadType: PreviewListingImagesPayloadType,
  // Set Listing Fields
  SetListingFieldPayloadType: SetListingFieldPayloadType,
  SetListingAddressPayloadType: SetListingAddressPayloadType,
  SetListingAddressLocationPayloadType: SetListingAddressLocationPayloadType,
  SetListingCherreIdPayloadType: SetListingCherreIdPayloadType,
  SetListingGuidanceFieldPayloadType: SetListingGuidanceFieldPayloadType,
  SetListingMediaFieldPayloadType: SetListingMediaFieldPayloadType,
  AddListingMediaFilesPayloadType: AddListingMediaFilesPayloadType;
  DeleteListingMediaFilesPayloadType: DeleteListingMediaFilesPayloadType;
  SwapListingMediaFilesAfterCropPayloadType: SwapListingMediaFilesAfterCropPayloadType;
  ReOrderListingMediaFilesPayloadType: ReOrderListingMediaFilesPayloadType;
  SetListingMediaVideoPayloadType: SetListingMediaVideoPayloadType;
  SetListingAssetClassPayloadType: SetListingAssetClassPayloadType,
  SetListingPropertyTypePayloadType: SetListingPropertyTypePayloadType,
  SetListingScorePayloadType: SetListingScorePayloadType,
  SetListingInfoFieldsPayloadType: SetListingInfoFieldsPayloadType,
  SetListingInfoFieldPayloadType: SetListingInfoFieldPayloadType,
  AddListingVaultFilesPayloadType: AddListingVaultFilesPayloadType,
  MoveListingVaultFilePayloadType: MoveListingVaultFilePayloadType,
  RemoveListingVaultFilesPayloadType: RemoveListingVaultFilesPayloadType,
  ReOrderListingVaultFilesPayloadType: ReOrderListingVaultFilesPayloadType,
  RenameListingVaultSubFolderPayloadType: RenameListingVaultSubFolderPayloadType,
  SetListingHighlightsPayloadType: SetListingHighlightsPayloadType,
  setListingContactsPayloadType: SetListingContactsPayloadType,
  SetListingListedByOwnerPayloadType: SetListingListedByOwnerPayloadType,
  SetListingDealProfileFieldPayloadType: SetListingDealProfileFieldPayloadType,
  // Saved Search
  SetSavedSearchPayloadType: CacheSavedSearchPayloadType,
  SetSavedSearchQueryPayloadType: SetSavedSearchQueryPayloadType,
  // Misc
  SetVideoUploadInProgressPayloadType: SetVideoUploadInProgressPayloadType,
  ResetListingErrorsPayloadType: ResetListingErrorsPayloadType,
  SetListingCountsPayloadType: SetListingCountsPayloadType,
};

export const ListingActions = {
  selectListing,
  cacheListings,
  recacheListing,
  setListingLoading,
  setShowPreview,
  // Search Listings
  setListingSearch,
  setListingSearchQuery,
  resetListingSearchQuery,
  // Create Listing
  createListing,
  createListingSuccess,
  createListingFailure,
  // Update Listing
  updateListing,
  updateListingSuccess,
  updateListingFailure,
  // Update Draft
  updateDraft,
  updateDraftSuccess,
  updateDraftFailure,
  // Publish Listing
  publishListing,
  publishListingSuccess,
  publishListingFailure,
  // Set Listing State
  setListingState,
  setListingStateSuccess,
  setListingStateFailure,
  // Delete Listing
  deleteListing,
  deleteListingSuccess,
  deleteListingFailure,
  // Listing Navigation
  previewListingVault,
  previewListingImages,
  previewListingVideo,
  // Set Listing Fields
  setListingField,
  setListingId,
  setListingAddress,
  setListingAddressLocation,
  setListingCherreId,
  setListingGuidanceField,
  setListingMediaField,
  addListingMediaFiles,
  deleteListingMediaFiles,
  swapListingMediaFilesAfterCrop,
  reOrderListingMediaFiles,
  setListingMediaVideo,
  setListingAssetClass,
  setListingPropertyType,
  setListingScore,
  setListingInfoFields,
  setListingInfoField,
  addListingVaultSubFolder,
  removeListingVaultSubFolder,
  renameListingVaultSubFolder,
  addListingVaultFiles,
  moveListingVaultFile,
  addListingVaultCAFile,
  removeListingVaultCAFile,
  removeListingVaultFiles,
  reOrderListingVaultFile,
  setListingHighlights,
  setListingContacts,
  setListingListedByOwner,
  setListingDealProfileField,
  // Saved Search
  cacheSavedSearch,
  removeSavedSearch,
  setSavedSearchQuery,
  // Misc
  setListingCounts,
  setVideoUploadInProgress,
  resetListingErrors,
};

const reducer = createReducer(listingReducerState(), (builder) => builder
  .addCase(selectListing, selectListingReducer)
  .addCase(cacheListings, cacheListingsReducer)
  .addCase(recacheListing, recacheListingReducer)
  .addCase(setListingLoading, setListingLoadingReducer)
  .addCase(setShowPreview, setShowPreviewReducer)
  // Search Listings
  .addCase(setListingSearch, setListingSearchReducer)
  .addCase(setListingSearchQuery, setListingSearchQueryReducer)
  .addCase(resetListingSearchQuery, resetListingSearchQueryReducer)
  // Create Listing
  .addCase(createListing, createListingReducer)
  .addCase(createListingSuccess, createListingSuccessReducer)
  .addCase(createListingFailure, createListingFailureReducer)
  // Update Listing
  .addCase(updateListing, updateListingReducer)
  .addCase(updateListingSuccess, updateListingSuccessReducer)
  .addCase(updateListingFailure, updateListingFailureReducer)
  // Update Draft
  .addCase(updateDraft, updateDraftReducer)
  .addCase(updateDraftSuccess, updateDraftSuccessReducer)
  .addCase(updateDraftFailure, updateDraftFailureReducer)
  // Publish Listing
  .addCase(publishListing, publishListingReducer)
  .addCase(publishListingSuccess, publishListingSuccessReducer)
  .addCase(publishListingFailure, publishListingFailureReducer)
  // Set Listing State
  .addCase(setListingState, setListingStateReducer)
  .addCase(setListingStateSuccess, setListingStateSuccessReducer)
  .addCase(setListingStateFailure, setListingStateFailureReducer)
  // Delete Listing
  .addCase(deleteListing, deleteListingReducer)
  .addCase(deleteListingSuccess, deleteListingSuccessReducer)
  .addCase(deleteListingFailure, deleteListingFailureReducer)
  // Listing Navigation
  .addCase(previewListingVault, previewListingVaultReducer)
  .addCase(previewListingVideo, previewListingVideoReducer)
  .addCase(previewListingImages, previewListingImagesReducer)
  // Set Listing Fields
  .addCase(setListingField, setListingFieldReducer)
  .addCase(setListingId, setListingIdReducer)
  .addCase(setListingAddress, setListingAddressReducer)
  .addCase(setListingAddressLocation, setListingAddressLocationReducer)
  .addCase(setListingCherreId, setListingCherreIdReducer)
  .addCase(setListingGuidanceField, setListingGuidanceFieldReducer)
  .addCase(setListingMediaField, setListingMediaFieldReducer)
  .addCase(addListingMediaFiles, addListingMediaFilesReducer)
  .addCase(deleteListingMediaFiles, deleteListingMediaFilesReducer)
  .addCase(swapListingMediaFilesAfterCrop, swapListingMediaFilesAfterCropReducer)
  .addCase(reOrderListingMediaFiles, reOrderListingMediaFilesReducer)
  .addCase(setListingMediaVideo, setListingMediaVideoReducer)
  .addCase(setListingAssetClass, setListingAssetClassReducer)
  .addCase(setListingPropertyType, setListingPropertyTypeReducer)
  .addCase(setListingScore, setListingScoreReducer)
  .addCase(setListingInfoFields, setListingInfoFieldsReducer)
  .addCase(setListingInfoField, setListingInfoFieldReducer)
  .addCase(addListingVaultSubFolder, addListingVaultSubFolderReducer)
  .addCase(removeListingVaultSubFolder, removeListingVaultSubFolderReducer)
  .addCase(renameListingVaultSubFolder, renameListingVaultSubFolderReducer)
  .addCase(addListingVaultFiles, addListingVaultFilesReducer)
  .addCase(moveListingVaultFile, moveListingVaultFileReducer)
  .addCase(addListingVaultCAFile, addListingVaultCAFileReducer)
  .addCase(removeListingVaultCAFile, removeListingVaultCAFileReducer)
  .addCase(removeListingVaultFiles, removeListingVaultFilesReducer)
  .addCase(reOrderListingVaultFile, reOrderListingVaultFileReducer)
  .addCase(setListingHighlights, setListingHighlightsReducer)
  .addCase(setListingContacts, setListingContactsReducer)
  .addCase(setListingDealProfileField, setListingDealProfileFieldReducer)
  .addCase(setListingListedByOwner, setListingListedByOwnerReducer)
  // Saved Search
  .addCase(cacheSavedSearch, cacheSavedSearchReducer)
  .addCase(removeSavedSearch, removeSavedSearchReducer)
  .addCase(setSavedSearchQuery, setSavedSearchQueryReducer)
  // Misc
  .addCase(setVideoUploadInProgress, setVideoUploadInProgressReducer)
  .addCase(resetListingErrors, resetListingErrorsReducer)
  .addCase(setListingCounts, setListingCountsReducer));

export default reducer;
