const localStorageAvailable = (): boolean => {
  try {
    const storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

const setInStorage = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

const setMultipleInStorage = (data: object): void => {
  const keyValuePairs = Object.entries(data);

  keyValuePairs.forEach(([key, value]) => {
    localStorage.setItem(key, value);
  });
};

const getFromStorage = (key: string): string => localStorage.getItem(key);

const getMultipleFromStorage = (keys: string[]): object => {
  const data = {};
  keys.forEach((key) => {
    data[key] = localStorage.getItem(key);
  });
  return data;
};

const clearFromStorage = (key: string): void => {
  localStorage.removeItem(key);
};

const clearMultipleFromStorage = (keys: string[]) => {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

const LocalStorageUtil = {
  localStorageAvailable,
  setInStorage,
  setMultipleInStorage,
  getFromStorage,
  getMultipleFromStorage,
  clearFromStorage,
  clearMultipleFromStorage,
};

export default LocalStorageUtil;
