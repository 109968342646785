import gql from 'graphql-tag';
import FileFragments from '../fragments/file.fragments';

const CREATE_FILES = gql`
  mutation CreateFiles($params: [FileParams!]!) {
    createFiles(params: $params) {
      ...FileFields
    }
  }

  ${FileFragments.fields}
`;

export default CREATE_FILES;
