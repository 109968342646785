enum BiproxiPlatformPermissionsEnum {
  CreatePrivateEvent = 'Create private event',
  CreatePrivateListing = 'Create private listing',
  ViewInvestorMatches = 'View investor matches',
  DataExplorer = 'Data explorer',
  OMUpload = 'OM upload',
}

export enum TeamPermissionsEnum {
  AddListings = 'Add listings',
  AddMembers = 'Add members',
  RemoveListings = 'Remove listings',
  RemoveMembers = 'Remove members',
  AddAdmin = 'Add admin',
  RemoveAdmin = 'Remove admin'
}

export default BiproxiPlatformPermissionsEnum;
