import Joi from 'joi';
import JoiUtil from '../utils/JoiUtil';
import { authorizedDataSchema } from './authentication.joi';
import PrivateEventsQueryTypeEnum from '../enums/PrivateEventsQueryTypeEnum';
import PrivateEventStatesEnum from '../enums/PrivateEventStatesEnum';
import MessageAllPrivateEventParticipantsTypeEnum from '../enums/MessageAllPrivateEventParticipantsTypeEnum';

/** ******************************************************************************
*  Create Private Event
******************************************************************************* */

export const createPrivateEventParamsSchema = Joi.object().keys({
  listingId: Joi.string().required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  participants: Joi.array().items(Joi.object().keys({
    email: Joi.string().email({ tlds: { allow: false } }).required(),
  })).required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  startsAt: Joi.number().required().error(([error]) => JoiUtil.encodeError('Invalid.', error)),
  endsAt: Joi.number().required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  psaFileIds: Joi.array().items(Joi.string()).optional(),
  settings: Joi.object().keys({
    startingBid: Joi.number().required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
    bidIncrement: Joi.number().required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  }).required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  contingencies: Joi.object().keys({
    depositAmount: Joi.number().optional().allow('', null).error(([error]) => JoiUtil.encodeError('Invalid', error)),
    dueDiligencePeriod: Joi.number().optional().allow('', null).error(([error]) => JoiUtil.encodeError('Invalid', error)),
    closingPeriod: Joi.number().optional().allow('', null).error(([error]) => JoiUtil.encodeError('Invalid', error)),
    additionalTerms: Joi.string().optional().allow('', null).error(([error]) => JoiUtil.encodeError('Invalid', error)),
  }).optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
}).prefs({
  abortEarly: false,
});

export const createPrivateEventRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: createPrivateEventParamsSchema,
});

/** ******************************************************************************
*  List Private Event
******************************************************************************* */
export const listPrivateEventParamsSchema = Joi.object().keys({
  queryType: Joi.string().valid(...Object.values(PrivateEventsQueryTypeEnum)).required(),
}).prefs({
  abortEarly: false,
});

export const listPrivateEventRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: listPrivateEventParamsSchema,
});

/** ******************************************************************************
*  Get Private Event
******************************************************************************* */

export const getPrivateEventParamsSchema = Joi.object().keys({
  privateEventId: Joi.string().required(),
}).prefs({
  abortEarly: false,
});

export const getPrivateEventRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: getPrivateEventParamsSchema,
});

/** ******************************************************************************
*  Accept Private Event Invitation
******************************************************************************* */

export const acceptPrivateEventInvitationParamsSchema = Joi.object().keys({
  privateEventId: Joi.string().required(),
  listingId: Joi.string().required(),
  listingUserId: Joi.string().required(),
  listingOrganizationId: Joi.string().required(),
}).prefs({
  abortEarly: false,
});

export const acceptPrivateEventInvitationRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: acceptPrivateEventInvitationParamsSchema,
});

/** ******************************************************************************
*  Place Private Event Bid
******************************************************************************* */

export const placePrivateEventBidParamsSchema = Joi.object().keys({
  privateEventId: Joi.string().required(),
  totalBidAmount: Joi.number().required(),
  bidIncrement: Joi.number().required(),
}).prefs({
  abortEarly: false,
});

export const placePrivateEventBidRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: placePrivateEventBidParamsSchema,
});

/** ******************************************************************************
*  Update Private Event
******************************************************************************* */

// Needed for the modifyBiddingRoomModal frontend validation.
export const updatePrivateEventFrontendSchema = Joi.object().keys({
  startsAt: Joi.number().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  endsAt: Joi.number().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  settings: Joi.object().keys({
    startingBid: Joi.number().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
    bidIncrement: Joi.number().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  }).error(([error]) => JoiUtil.encodeError('Invalid', error)),
  contingencies: Joi.object().keys({
    depositAmount: Joi.number().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
    dueDiligencePeriod: Joi.number().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
    closingPeriod: Joi.number().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
    additionalTerms: Joi.string().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  }).optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
}).prefs({
  abortEarly: false,
});

export const updatePrivateEventParamsSchema = Joi.object().keys({
  privateEventId: Joi.string().required(),
  doNotNotifyOfUpdate: Joi.boolean().optional(),
  updateParams: Joi.object().keys({
    state: Joi.string().valid(...Object.values(PrivateEventStatesEnum)).optional(),
    startsAt: Joi.number().optional(),
    endsAt: Joi.number().optional(),
    settings: Joi.object().keys({
      startingBid: Joi.number().optional(),
      bidIncrement: Joi.number().optional(),
    }),
    contingencies: Joi.object().keys({
      depositAmount: Joi.number().optional(),
      dueDiligencePeriod: Joi.number().optional(),
      closingPeriod: Joi.number().optional(),
      additionalTerms: Joi.string().optional(),
    }),
  }),
}).prefs({
  abortEarly: false,
});

export const updatePrivateEventRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: updatePrivateEventParamsSchema,
});

/** ******************************************************************************
*  Send Private Event Invitation
******************************************************************************* */

export const sendPrivateEventInviteParamsSchema = Joi.object().keys({
  privateEventId: Joi.string().required(),
  email: Joi.string().email({ tlds: { allow: false } }).required().error(([error]) => JoiUtil.encodeError('Invalid email format. Please try again.', error)),
}).prefs({
  abortEarly: false,
});

export const sendPrivateEventInviteRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: sendPrivateEventInviteParamsSchema,
});

/** ******************************************************************************
*  Place Private Event Bid
******************************************************************************* */

export const updatePrivateEventParticipantParamsSchema = Joi.object().keys({
  privateEventId: Joi.string().required(),
  privateEventParticipantId: Joi.string().required(),
  updateParams: Joi.object().keys({
    disabled: Joi.boolean().optional(),
  }),
}).prefs({
  abortEarly: false,
});

export const updatePrivateEventParticipantRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: updatePrivateEventParticipantParamsSchema,
});

/** ******************************************************************************
*  Place Private Event Bid
******************************************************************************* */

export const messageAllPrivateEventParticipantsParamsSchema = Joi.object().keys({
  privateEventId: Joi.string().required(),
  message: Joi.string().required(),
  type: Joi.string().valid(...Object.values(MessageAllPrivateEventParticipantsTypeEnum)).required(),
}).prefs({
  abortEarly: false,
});

export const messageAllPrivateEventParticipantsRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: messageAllPrivateEventParticipantsParamsSchema,
});

/** ******************************************************************************
*  resend private event email
******************************************************************************* */

export const resendPrivateEventInviteEmailParamsSchema = Joi.object().keys({
  privateEventId: Joi.string().required(),
  privateEventParticipantId: Joi.string().required(),
}).prefs({
  abortEarly: false,
});

export const resendPrivateEventInviteEmailRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: resendPrivateEventInviteEmailParamsSchema,
});
