import {
  config, isNode, isJest, isServerless,
} from '@biproxi/env';
import { Label, Level } from './logger.enums';

export interface LoggerConfig {
  loggingLevel: Level,
  loggingLevelLabel: Label,
  disableJSONTransport: boolean,
  disablePrettyTransport: boolean,
  disableLogtailTransport: boolean,
  logtailToken: string,
}

export const loggerConfig: LoggerConfig = isNode ? {
  loggingLevel: Level[(config.LOGGER_LEVEL || 'info').toLowerCase() as keyof typeof Level],
  loggingLevelLabel: Label[(config.LOGGER_LEVEL as string || 'info').toLowerCase() as keyof typeof Label],
  disableJSONTransport: !config.LOGGER_JSON,
  disablePrettyTransport: !config.LOGGER_PRETTY || isJest,
  disableLogtailTransport: isJest || !config.LOGTAIL_AUTH_TOKEN || isServerless,
  logtailToken: config.LOGTAIL_AUTH_TOKEN || '',
} : {
  loggingLevel: Level[(config.NEXT_PUBLIC_LOGGER_LEVEL || 'info').toLowerCase() as keyof typeof Level],
  loggingLevelLabel: Label[(config.NEXT_PUBLIC_LOGGER_LEVEL || 'info').toLowerCase() as keyof typeof Label],
  disableJSONTransport: !config.NEXT_PUBLIC_LOGGER_JSON,
  disablePrettyTransport: !config.NEXT_PUBLIC_LOGGER_PRETTY || isJest,
  disableLogtailTransport: isJest || !config.NEXT_PUBLIC_LOGTAIL_AUTH_TOKEN,
  logtailToken: config.NEXT_PUBLIC_LOGTAIL_AUTH_TOKEN || '',
};
