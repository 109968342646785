import gql from 'graphql-tag';
import ListingUploadFragments from '../fragments/listingUpload.fragments';

const CREATE_LISTING_UPLOAD = gql`
  mutation CreateFiles($params: ListingUploadParams!) {
    createListingUpload(params: $params) {
      ...ListingUploadFields
      ...ListingUploadMedia
      ...ListingUploadMeta
      ...ListingUploadUser
      ...ListingUploadAdminInfo
    }
  }

  ${ListingUploadFragments.fields}
  ${ListingUploadFragments.media}
  ${ListingUploadFragments.meta}
  ${ListingUploadFragments.user}
  ${ListingUploadFragments.adminInfo}
`;

export default CREATE_LISTING_UPLOAD;
