import gql from 'graphql-tag';
import ListingUploadFragments from '../fragments/listingUpload.fragments';

const LIST_LISTING_UPLOADS = gql`
  query ListingUploads($params: ListListingUploadsParams!) {
    listingUploads(params: $params) {
      data {
        ...ListingUploadFields
        ...ListingUploadMedia
        ...ListingUploadMeta
        ...ListingUploadUser
        ...ListingUploadAdminInfo
        ...ListingUploadListing
      }
      info {
        totalCount
      }
    }
  }

  ${ListingUploadFragments.fields}
  ${ListingUploadFragments.media}
  ${ListingUploadFragments.meta}
  ${ListingUploadFragments.user}
  ${ListingUploadFragments.adminInfo}
  ${ListingUploadFragments.listing}
`;

export default LIST_LISTING_UPLOADS;
