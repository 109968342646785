enum NotificationTypeEnum {
  NewMessage = 'New Message',
  NewOffer = 'New Offer',
  NewLead = 'New Lead',
  InvestorMatchSummary = 'New Investor Match',
  NewListingMatchingInvestmentProfile = 'New Listing Matching Investor Profile',
  RevisedOffer = 'Revised Offer',
  ListingPublished = 'Listing Published',
  ListingExpired = 'Listing Expired',
  SignedCA = 'SignedCA',
  ListingStatusUpdated = 'Listing Status Updated',
  FileAccessLevelChangeRequested = 'File Access Level Change Requested',
  FileAccessLevelChanged = 'File Access Level Changed',
  FileAccessRequestDenied = 'File Access Request Denied',
  ListingUploadReadyForReview = 'New Listing Ready For Review',
  ListingUploadAdminAssigned = 'Assigned Listing Upload',
  PrivateListingAccessRequest = 'Private Listing Access Request',
  PrivateListingAccessApproved = 'Private Listing Access Approved',
  PrivateListingAccessDenied = 'Private Listing Access Denied',
}

export default NotificationTypeEnum;
