import gql from 'graphql-tag';

const ADD_ORG_ROLE_TO_USER = gql`
  mutation Mutation($where: UserWhere, $update: UserUpdateInput) {
    updateUsers(where: $where, update: $update) {
      info {
        relationshipsCreated
      }
      users {
        organizationConnection {
          edges {
            roles
          }
        }
      }
    }
  }
`;

export default ADD_ORG_ROLE_TO_USER;
