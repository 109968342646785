import { logger } from '@biproxi/logger';
import {
  all, takeLatest, call, put,
} from 'redux-saga/effects';
import {
  PayloadAction,
} from '@reduxjs/toolkit';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import * as ILeadService from '@biproxi/models/services/ILeadService';
import {
  LeadActionTypesEnum, LeadActionPayloadTypes, LeadActions,
} from '../lead.redux';
import { initializeApollo } from '../../graphql/client';
import CREATE_LEAD from '../../graphql/mutations/createLead.mutation';
import { AppActions } from '../app.redux';
import { ToastTypesEnum } from '../../components/Toast';
import GET_LEAD from '../../graphql/queries/lead.query';

export default function* leadSaga() {
  try {
    yield all([
      recacheLeadWatch(),
      createLeadWatch(),
    ]);
  } catch (e) {
    logger.error('leadSaga() error', e);
  }
}

/** ******************************************************************************
 *  Recache Lead
 ****************************************************************************** */

function* recacheLeadWatch() {
  yield takeLatest(
    LeadActionTypesEnum.RecacheLead,
    recacheLeadSaga,
  );
}

function* recacheLeadSaga(
  action: PayloadAction<LeadActionPayloadTypes['RecacheLeadPayloadType']>,
) {
  /* Create GraphQL Client */
  const client = initializeApollo();

  try {
    interface Data {
      lead: ILeadGraphQL;
    }

    interface Vars {
      params: {
        leadId: string;
      }
    }

    const { data } = yield call(async () => {
      const { payload: { leadId } } = action;
      return client.query<Data, Vars>({
        query: GET_LEAD,
        variables: {
          params: {
            leadId,
          },
        },
      });
    });

    yield put(LeadActions.cacheLeads({ leads: [data.lead] }));
  } catch (e) {
    logger.error('RecacheLeadSaga error', e);
    const message = 'Failed to recache lead. Please try again or contact support.';
    yield put(AppActions.pushToast({ type: ToastTypesEnum.Error, message }));
  }
}

/** ******************************************************************************
 *  Create Lead
 ****************************************************************************** */

function* createLeadWatch() {
  yield takeLatest(
    LeadActionTypesEnum.CreateLead,
    createLeadSaga,
  );
}

function* createLeadSaga(
  action: PayloadAction<LeadActionPayloadTypes['CreateLeadPayloadType']>,
) {
  /* Create GraphQL Client */
  const client = initializeApollo();

  try {
    type Data = {
      lead: ILeadGraphQL;
    }

    type Vars = ILeadService.TCreateLeadPayload;

    const { data } = yield call(async () => client.mutate<Data, Vars>({
      mutation: CREATE_LEAD,
      variables: { params: action.payload },
    }));
    yield put(LeadActions.createLeadSuccess({ lead: data.createLead }));
  } catch (e) {
    yield put(LeadActions.createLeadFailure(null));
  }
}
