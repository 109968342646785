/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { config } from '@biproxi/env';
import { KB_NYLAS_URL } from '@biproxi/models/externalLinks';
import {
  ModalHeader,
  ModalContainer,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Text from '../../elements/Text';
import Flex from '../../elements/Flex';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import useUser from '../../hooks/useUser.hook';
import Link from '../../elements/Link';

export type AuthenticateWithNylasModalProps = {};

const AuthenticateWithNylasModal: React.FC<AuthenticateWithNylasModalProps> = () => {
  /* Hooks */
  const { user } = useUser();

  /* Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(AppActions.popModal());

  const startNylasFlow = () => {
    window.open(nylasRedirectURL, '_self');
  };

  /* Render */
  const nylasRedirectURL = `https://api.nylas.com/oauth/authorize?client_id=${config.NEXT_PUBLIC_NYLAS_CLIENT_ID}&response_type=code&login_hint=${encodeURIComponent(user?.email)}&redirect_uri=${window.location.origin}/app/dashboard/settings&scopes=email.send`;

  return (
    <ModalContainer width="424px">
      <ModalHeader title="Authenticate" close={popModal} />
      <ModalContent style={{ paddingTop: '0px' }}>
        <Text margin="0px 0px 8px 0px">
          In order to share this listing with your contacts, you will need to connect your email.
          {'\n'}
          <Link href={KB_NYLAS_URL} target="_blank">
            Learn more
          </Link>
        </Text>
        <Text>
          Click "Connect Now" to continue.
        </Text>
      </ModalContent>
      <ModalFooter>
        <Flex justify="flex-end" width="100%">
          <Button
            text="Cancel"
            margin="0px 8px 0px 0px"
            type={ButtonTypesEnum.Outline}
            size={ButtonSizesEnum.Medium}
            onClick={popModal}
          />
          <Button
            text="Connect Now"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            onClick={() => startNylasFlow()}
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default AuthenticateWithNylasModal;
