import { AppActions } from '../redux/app.redux';
import { useAppDispatch } from '../redux/store';
import Auth from '../utils/Auth';
import { ModalTypesEnum } from '../components/modal/Modal';
import IRequireAuthentication from '../models/interfaces/IRequireAuthentication';

type UseRequireAuthentication = (callback: Function, isCallbackValid?: (appState: any) => boolean) => void;

type UseRequiredAuthenticationHook = () => UseRequireAuthentication;

const useRequireAuthentication: UseRequiredAuthenticationHook = (): UseRequireAuthentication => {
  /** Actions */
  const dispatch = useAppDispatch();
  const pushRegisterModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.Register,
      props: {},
    }),
  );

  const setRequireAuthentication = (requireAuthentication: IRequireAuthentication) => dispatch(
    AppActions.setRequireAuthentication(requireAuthentication),
  );

  return (callback: Function, isCallbackValid?: (appState: any) => boolean): void => {
    if (Auth.isAuthenticated()) {
      callback();
    } else {
      setRequireAuthentication({ callback, isCallbackValid });
      pushRegisterModal();
    }
  };
};

export default useRequireAuthentication;
