import { config, hostname } from '@biproxi/env';
import { logger } from '@biproxi/logger';
import { err, ok, Result } from 'neverthrow';
import { mergeWith } from 'lodash';
import { contentfulClient } from './contentful.client';
import { defaultBrand } from './tacklebox.default.brand';
import {
  GET_BRAND, GET_BRANDS, GET_BRAND_FOR_HOSTNAME, GET_BRAND_NAMES, GET_HOSTNAMES,
} from './contentful.queries';
import { Brand } from './tacklebox.types';

class TackleboxService {
  public getBrandNames = async (): Promise<Result<Array<string>, Error>> => {
    try {
      const entries = await contentfulClient.query({ query: GET_BRAND_NAMES, variables: { preview: true } });
      const brandNames = entries.data.brandCollection.items.map((brand: { name: string }) => brand.name);
      return ok(brandNames);
    } catch (error) {
      return err(new Error(`Error getBrandName(): ${error}`));
    }
  };

  public getBrands = async (): Promise<Result<Array<Brand>, Error>> => {
    try {
      const entries = await contentfulClient.query({ query: GET_BRANDS, variables: { preview: true } });
      const brands = entries.data.brandCollection.items;
      return ok(brands);
    } catch (error) {
      // console.log(JSON.stringify(error), null, 2);
      return err(new Error(`Error getBrands(): ${error}`));
    }
  };

  public getBrand = async (name: string): Promise<Brand> => {
    try {
      const entry = await contentfulClient.query({ query: GET_BRAND, variables: { name, preview: true } });
      const brand = ok(entry.data.brandCollection.items[0]);
      const unwrappedBrand = brand.unwrapOr(defaultBrand);
      // eslint-disable-next-line consistent-return
      const mergedBrand: Brand = mergeWith({}, defaultBrand, unwrappedBrand, (objValue, srcValue) => {
        if (srcValue === null || srcValue === undefined) {
          return objValue;
        }
      });
      return mergedBrand;
    } catch (error) {
      logger.error(`Unable To Get Brand for "${name}"`);
      return new Error(`Error getBrands(): ${error}`);
    }
  };
  // asdijna
  public getHostnames = async (): Promise<Array<string>> => {
    try {
      const entries = await contentfulClient.query({ query: GET_HOSTNAMES, variables: { preview: true } });
      const hostnames = entries.data.hostnameBrandCollection.items.map((item: { hostname: string }) => item.hostname);
      return hostnames;
    } catch (error) {
      logger.warn('Unable To Get Hostnames, []');
      return [];
    }
  };

  public getBrandForHostname = async (): Promise<Brand> => {
    const hn = config.NEXT_PUBLIC_CONTENTFUL_ENV || hostname();
    try {
      const entry = await contentfulClient.query({ query: GET_BRAND_FOR_HOSTNAME, variables: { hostname: hn, preview: true } });
      const brand = entry.data.hostnameBrandCollection.items[0].brand;
      logger.info(`BrandForHostname: "${hn}"`);
      // Use Lodash's defaults method to merge the brand object with defaultBrand object
      // eslint-disable-next-line consistent-return
      const mergedBrand = mergeWith({}, defaultBrand, brand, (objValue, srcValue) => {
        if (srcValue === null || srcValue === undefined) {
          return objValue;
        }
      });
      return mergedBrand as Brand;
    } catch (error) {
      logger.warn(`Unable To Get BrandForHostname: "${hn}", Using Default`);
      return defaultBrand;
    }
  };
}

export const tacklebox = new TackleboxService();
