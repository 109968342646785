import React from 'react';
import styled from '@emotion/styled';
import {
  ModalContainer,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Button, { ButtonTypesEnum } from '../../elements/Button';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Icon from '../../elements/Icon';
import Icons from '../../elements/Icons';
import { useMobileMedia } from '../../utils/MediaQuery';

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
`;

export type GeneralErrorModalProps = {
    modalText: string;
    textType?: TextTypesEnum;
}

const GeneralErrorModal: React.FC<GeneralErrorModalProps> = ({
  modalText,
  textType = TextTypesEnum.Bold24,
}) => {
  /** State */
  const isMobile = useMobileMedia();
  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );
  return (
    <ModalContainer
      width={isMobile ? '100vw' : '400px'}
    >
      <ModalContent>
        <ContentWrapper>
          <Icon
            icon={Icons.TimesRegular}
            color={Colors.Red600}
            borderRadius="100px"
            background={Colors.Red100}
            width="48px"
            height="48px"
            size="24px"
          />
          <Text
            type={textType}
          >
            {modalText}
          </Text>
          <Button
            type={ButtonTypesEnum.Primary}
            text="Done"
            onClick={popModal}
            isFullWidth
          />
        </ContentWrapper>
      </ModalContent>
    </ModalContainer>
  );
};

export default GeneralErrorModal;
