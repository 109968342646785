import gql from 'graphql-tag';

const CONNECT_USER_TO_ORG = gql`
  mutation ConnectUserToOrg($where: OrganizationWhere, $connect: OrganizationConnectInput) {
    updateOrganizations(where: $where, connect: $connect) {
      info {
        relationshipsCreated
      }
    }
  }
`;

export default CONNECT_USER_TO_ORG;
