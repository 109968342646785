import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import Text, { TextAsEnum, TextTypesEnum } from './Text';

type ContainerProps = {
  margin: string;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${(props) => props.margin};
  height: 16px;
`;

type LabelProps = {
  text: string
  margin?: string;
};

const Label: React.FC<LabelProps> = ({
  text,
  margin = '0 0 4px',
}) => (
  <Container margin={margin}>
    <Text
      type={TextTypesEnum.Medium12}
      as={TextAsEnum.Label}
      color={Colors.Grey900}
    >
      {text}
    </Text>
  </Container>
);

export default Label;
