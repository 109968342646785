/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from '@emotion/styled';
import PaywallFeaturesEnum from '@biproxi/models/enums/PaywallFeaturesEnum';
import useOnclickOutside from 'react-cool-onclickoutside';
import Link from 'next/link';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import {
  ModalContainer,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';

const ModalWrapper = styled.div``;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  background-color: ${Colors.Brand700 || Colors.Blurple700};
  border-radius: 0 0 16px 16px;

  &:hover {
    cursor: pointer;
    background-color: ${Colors.Brand800 || Colors.Blurple800};
  }
`;

export const BillingPaywallModalTitleObject = {
  [PaywallFeaturesEnum.CreatePrivateEvent]: 'Upgrade your plan to begin creating your Private Events',
  [PaywallFeaturesEnum.CreatePrivateListing]: 'Upgrade your plan to begin creating your Private Listings',
  [PaywallFeaturesEnum.InvestorMatching]: 'Upgrade your plan to take advantage of Investor Matches',
  [PaywallFeaturesEnum.DataExplorer]: 'Upgrade your plan to take full advantage of Data Explorer',
  [PaywallFeaturesEnum.OMUpload]: 'Upgrade your plan to upload a listing',
};

export const BillingPaywallModalDescriptionObject = {
  [PaywallFeaturesEnum.CreatePrivateEvent]: 'Private Events is the first and only self-service broker-controlled auction product on the market. Upgrade your plan to begin creating your Private Events.',
  [PaywallFeaturesEnum.CreatePrivateListing]: 'Private listings allows you to create more exclusive listings on our marketplace that gives you the power to control who views your listings.',
  [PaywallFeaturesEnum.InvestorMatching]: 'Investor matching allow you to access investors who have shared their investment criteria that matches your offering.',
  [PaywallFeaturesEnum.DataExplorer]: 'Data Explorer allows you to view property tax and demographic data for over 158 million properties across the United States. We also offer unmasked owner and transaction history data for select properties. Upgrade now for full access to all the data we offer.',
  [PaywallFeaturesEnum.OMUpload]: 'With a Biproxi premium subscription, you have the ability to upload an offering memorandum. Once uploaded, someone from our team will review the information and create a listing for you based on the information you submit.',
};

export type BillingPaywallModalProps = {
  paywallFeature: PaywallFeaturesEnum;
  title?: string;
  description?: string;
};

const BillingPaywallModal: React.FC<BillingPaywallModalProps> = ({
  paywallFeature,
  title,
  description,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  /** Hooks */
  const onClickOutside = useOnclickOutside(() => popModal());

  /** Render */
  const modalTitle = title ?? BillingPaywallModalTitleObject[paywallFeature];
  const subscriptionInfo = description ?? BillingPaywallModalDescriptionObject[paywallFeature];
  return (
    <ModalContainer
      width="420px"
      data-cy="upgrade-plan-modal"
    >
      <ModalWrapper
        data-cy="outside-wrapper"
        ref={onClickOutside}
      >
        <ModalContent>
          <ModalBody>
            <Text
              type={TextTypesEnum.Medium18}
              color={Colors.Black}
              align="center"
            >
              {modalTitle}
            </Text>
            <Text
              type={TextTypesEnum.Regular16}
              color={Colors.Black}
            >
              {subscriptionInfo}
            </Text>
          </ModalBody>
        </ModalContent>
        <Link href="/app/dashboard/settings/billing" target="_blank">
          <FooterContainer
            data-cy="paywall-checkout-button"
          >
            <Text
              color={Colors.White}
              type={TextTypesEnum.Bold18}
            >
              Upgrade now
            </Text>
          </FooterContainer>
        </Link>
      </ModalWrapper>
    </ModalContainer>
  );
};

export default BillingPaywallModal;
