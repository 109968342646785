import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { GetServerSidePropsContext } from 'next';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { config } from '@biproxi/env';
import { createClient } from 'graphql-ws';
import uploadLink from './links/uploadLink';
import makeRequestLink from './links/requestLink';
import errorLink from './links/errorLink';
import IPageConfiguration from '../models/interfaces/IPageConfiguration';
import omitTypeNameLink from './links/omitTypeNameLink';
import debounceLink from './links/debounceLink';
import NextUtil from '../utils/NextUtil';
import Auth from '../utils/Auth';
import FieldPolicies from './fieldPolicies';

let apolloClient: ApolloClient<NormalizedCacheObject> = null;

export function createApolloClient(context?: GetServerSidePropsContext) {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          dataExplorerFilterProperties: FieldPolicies.DataExplorerFilteredProperties,
          users: FieldPolicies.Users,
          notifications: FieldPolicies.Notifications,
          channels: FieldPolicies.Channels,
          dataExplorerSimilarProperties: FieldPolicies.DataExplorerSimilarPropertyData,
          dataExplorerCherreCityState: FieldPolicies.DataExplorerCherreCityStateData,
          listings: FieldPolicies.Listings,
          listingUploads: FieldPolicies.ListingUploads,
        },
      },
    },
  });

  const wsConnectionParams = {
    token: Auth.getToken(context?.req?.cookies ?? undefined),
  };

  const wsLink = NextUtil.hasWindow() ? new GraphQLWsLink(
    // Param options listed here
    // https://github.com/enisdenjo/graphql-ws/blob/master/docs/interfaces/client.ClientOptions.md
    createClient({
      url: `${config.NEXT_PUBLIC_WEBSOCKET_URL}/subscriptions`,
      connectionParams: wsConnectionParams,
    }),
  ) : null;

  const wsChainLink = NextUtil.hasWindow() ? ApolloLink.from([
    makeRequestLink(context),
    wsLink,
  ]) : null;

  const httpLinkChain = NextUtil.hasWindow() ? ApolloLink.from([
    makeRequestLink(context),
    errorLink,
    omitTypeNameLink,
    debounceLink,
    uploadLink,
  ]) : null;

  const splitLink = NextUtil.hasWindow() ? split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
      );
    },
    wsChainLink,
    httpLinkChain,
  ) : httpLinkChain;

  const client = new ApolloClient({
    ssrMode: true,
    link: splitLink,
    cache,
  });

  /**
   * Seems Kind of stange and needs more testing but this works.
   * The issue comes from not having the token initially
   * when the intial connection is made so the websocket auth piece fails.
   * We reset the store right after the token is set when a user authenticates
   *
   * EDIT: We changed implementations, so may no longer be needed.
   * Our plan is to switch to Pusher though so may hold off and doing a deep dive since it works.
   */
  // https://github.com/apollographql/subscriptions-transport-ws/issues/171#issuecomment-895691114
  client.onResetStore(async () => {
    wsConnectionParams.token = Auth.getToken(context?.req?.cookies ?? undefined);
    client.setLink(splitLink);
  });

  return client;
}

export function initializeApollo(context?: GetServerSidePropsContext, config: IPageConfiguration = null): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient(context);
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (config?.initialApolloState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(config?.initialApolloState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(config: IPageConfiguration) {
  const store = React.useMemo(() => initializeApollo(undefined, config), [config]);
  return store;
}
