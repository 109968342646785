import React from 'react';
import styled from '@emotion/styled';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import IListingContact from '@biproxi/models/interfaces/IListingContact';
import Colors, { ColorsType } from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';

export enum ProfileImageTypes {
  ExtraSmall = 'ExtraSmall',
  Small = 'Small',
  Large = 'Large',
  ExtraLarge = 'ExtraLarge',
}

type ContainerProps = { type: ProfileImageTypes;
  margin: string;
  onClick: () => void;
}

const Container = styled.div<ContainerProps>`
  height: ${({ type }) => {
    switch (type) {
      case ProfileImageTypes.ExtraSmall:
        return '24px';
      case ProfileImageTypes.Small:
        return '32px';
      case ProfileImageTypes.Large:
        return '48px';
      case ProfileImageTypes.ExtraLarge:
        return '64px';
      default:
        return '72px';
    }
  }};
  display: flex;
  margin: ${({ margin, type }) => {
    if (margin) return margin;
    if (type === ProfileImageTypes.ExtraSmall) return '0px 8px 0px 0px';
    if (type === ProfileImageTypes.Small) return '0px 8px 0px 0px';
    return '0px 16px 0px 0px';
  }};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
  transition: all 0.2s;
`;

interface UserProfileProps {
  type?: ProfileImageTypes;
  profileUrl?: string;
  backgroundColor?: ColorsType;
  showBorder: boolean;
  borderColor: ColorsType;
}

const UserProfile = styled.div<UserProfileProps>`
  position: relative;
  height: ${({ type }) => {
    switch (type) {
      case ProfileImageTypes.ExtraSmall:
        return '24px';
      case ProfileImageTypes.Small:
        return '32px';
      case ProfileImageTypes.Large:
        return '48px';
      case ProfileImageTypes.ExtraLarge:
        return '64px';
      default:
        return '72px';
    }
  }};
  width: ${({ type }) => {
    switch (type) {
      case ProfileImageTypes.ExtraSmall:
        return '24px';
      case ProfileImageTypes.Small:
        return '32px';
      case ProfileImageTypes.Large:
        return '48px';
      case ProfileImageTypes.ExtraLarge:
        return '64px';
      default:
        return '72px';
    }
  }};
  border-radius: 50%;
  transition: all 0.2s;
  background-color: ${({ backgroundColor }) => (backgroundColor || Colors.Black)};
  background-image: ${({ profileUrl }) => (profileUrl ? `url(${profileUrl})` : null)};
  background-size: cover;
  background-position: center;
  background-origin: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ showBorder, borderColor }) => (showBorder ? `4px solid ${borderColor}` : null)};
`;

type ProfileImageProps = {
  user?: IUserGraphQL;
  contact?: IListingContact;
  type?: ProfileImageTypes;
  margin?: string;
  onClick?: () => void;
  showBorder?: boolean;
  borderColor?: ColorsType;
};

const ProfileImage: React.FC<ProfileImageProps> = ({
  user,
  contact,
  type,
  margin,
  onClick,
  showBorder = false,
  borderColor = Colors.Green400,
}) => {
  /** If user, generate initials as usual. If contact on create listing page, if first-last
   * name, use first letter of first name and last name. If first-last name is not available,
   * use first two letters of provided name
   */
  const initials = user ? `${user?.firstName?.substring(0, 1)}${user?.lastName?.substring(0, 1)}`
    : contact?.name.split(' ')[1]
      ? contact?.name.split(' ')[0].substring(0, 1) + contact?.name.split(' ')[1].substring(0, 1)
      : contact?.name.slice(0, 2);

  const click = onClick ? () => onClick() : null;
  return (
    <Container
      type={type}
      margin={margin}
      onClick={click}
    >
      {user || contact ? (
        <>
          {user ? (
            <>
              {user?.profileImageFile ? (
                <UserProfile
                  type={type}
                  profileUrl={user?.profileImageFile?.url}
                  showBorder={showBorder}
                  borderColor={borderColor}
                />
              ) : (
                <UserProfile
                  type={type}
                  showBorder={showBorder}
                  borderColor={borderColor}
                >
                  <Text
                    type={type === ProfileImageTypes.Small
                      ? TextTypesEnum.Medium14
                      : type === ProfileImageTypes.ExtraSmall
                        ? TextTypesEnum.Medium12
                        : TextTypesEnum.Medium16}
                    color={Colors.White}
                  >
                    {initials}
                  </Text>
                </UserProfile>
              )}
            </>
          ) : (
            <>
              {contact.profileImageUrl ? (
                <UserProfile
                  type={type}
                  profileUrl={contact?.profileImageUrl}
                  showBorder={showBorder}
                  borderColor={borderColor}
                />
              ) : (
                <UserProfile
                  type={type}
                  showBorder={showBorder}
                  borderColor={borderColor}
                >
                  <Text
                    type={type === ProfileImageTypes.Small
                      ? TextTypesEnum.Medium14
                      : type === ProfileImageTypes.ExtraSmall
                        ? TextTypesEnum.Medium12
                        : TextTypesEnum.Medium16}
                    color={Colors.White}
                  >
                    {initials}
                  </Text>
                </UserProfile>
              )}
            </>
          )}
        </>
      ) : (
        <UserProfile
          type={type}
          backgroundColor={Colors.Grey200}
          showBorder={showBorder}
          borderColor={borderColor}
        >
          <Icon icon={Icons.UserSolid} color={Colors.Grey700} size={16} />
        </UserProfile>
      )}
    </Container>
  );
};

export default ProfileImage;
