import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import {
  createAction, createReducer, PayloadAction,
} from '@reduxjs/toolkit';
import { AppState } from './store';
import IPageConfiguration from '../models/interfaces/IPageConfiguration';
import NextUtil from '../utils/NextUtil';
import * as UrlUtil from '../utils/UrlUtil';

export type PrivateEventReducerState = {
  privateEventId: string | null;
  privateEvent: IPrivateEventGraphQL | null;
  errors: Record<string, string> | null;
  isNewListing: boolean;
};

export function privateEventReducerState(config?: IPageConfiguration): PrivateEventReducerState {
  let { privateEventId = null } = NextUtil.query(config);

  if (!privateEventId && config?.lead) {
    privateEventId = config.lead._id;
  }

  return {
    privateEventId,
    privateEvent: config?.privateEvent ?? null,
    errors: null,
    isNewListing: false,
  };
}

export enum PrivateEventActionTypesEnum {
  SelectPrivateEvent = 'SelectPrivateEvent',
  SetPrivateEventField = 'SetPrivateEventField',
  SetIsNewPrivateEventListing = 'SetIsNewPrivateEventListing',
  SetErrors = 'SetErrors',
}

/** ******************************************************************************
 *  Select Private Event
 ****************************************************************************** */

// Payload
type SelectPrivateEventPayloadType = {
  privateEventId: string | null;
};

// Action
const selectPrivateEvent = createAction<SelectPrivateEventPayloadType, PrivateEventActionTypesEnum.SelectPrivateEvent>(PrivateEventActionTypesEnum.SelectPrivateEvent);

// Reducer
function selectPrivateEventReducer(state: PrivateEventReducerState, action: PayloadAction<SelectPrivateEventPayloadType>) {
  const { privateEventId } = action.payload;
  UrlUtil.setQueryString({ privateEventId });
  state.privateEventId = privateEventId;
  return state;
}

/** ******************************************************************************
 *  Set Private Event Field
 ****************************************************************************** */

// Payload
type SetPrivateEventFieldPayloadType = Partial<IPrivateEventGraphQL>;

// Action
const setPrivateEventField = createAction<SetPrivateEventFieldPayloadType, PrivateEventActionTypesEnum.SetPrivateEventField>(PrivateEventActionTypesEnum.SetPrivateEventField);

// Reducer
function setPrivateEventFieldReducer(state: PrivateEventReducerState, action: PayloadAction<SetPrivateEventFieldPayloadType>) {
  const { privateEvent } = state;

  const updatedPrivateEvent = {
    ...privateEvent,
    ...action.payload,
  };

  state.privateEvent = updatedPrivateEvent;

  return state;
}

/** ******************************************************************************
 *  Select Private Event
 ****************************************************************************** */

// Payload
type SetIsNewPrivateEventListingType = boolean;

// Action
const setIsNewPrivateEventListing = createAction<SetIsNewPrivateEventListingType, PrivateEventActionTypesEnum.SetIsNewPrivateEventListing>(PrivateEventActionTypesEnum.SetIsNewPrivateEventListing);

// Reducer
function setIsNewPrivateEventListingReducer(state: PrivateEventReducerState, action: PayloadAction<SetIsNewPrivateEventListingType>) {
  state.isNewListing = action.payload;
  return state;
}

/** ******************************************************************************
 *  Set errors for frontend validation when form hook can't be used
 ****************************************************************************** */

// Payload
type SetErrorsPayloadType = Record<string, string> | null;

// Action
const setErrors = createAction<SetErrorsPayloadType, PrivateEventActionTypesEnum.SetErrors>(PrivateEventActionTypesEnum.SetErrors);

// Reducer
function setErrorsReducer(state: PrivateEventReducerState, action: PayloadAction<SetErrorsPayloadType>) {
  state.errors = { ...action.payload };
  return state;
}

/** ******************************************************************************
 *  Exports
 ****************************************************************************** */

export const PrivateEventSelectors = {
  selectedPrivateEventId: (state: AppState): PrivateEventReducerState['privateEventId'] => {
    const { privateEvent: { privateEventId } } = state;
    return privateEventId ?? null;
  },
  privateEvent: (state: AppState): IPrivateEventGraphQL => state?.privateEvent?.privateEvent ?? null,
  errors: (state: AppState): PrivateEventReducerState['errors'] => state?.privateEvent?.errors ?? null,
  isNewListing: (state: AppState): PrivateEventReducerState['isNewListing'] => state?.privateEvent?.isNewListing ?? false,
};

export type PrivateEventActionPayloadTypes = {
  SetPrivateEventFieldPayloadType: SetPrivateEventFieldPayloadType,
  SelectPrivateEventPayloadType: SelectPrivateEventPayloadType,
  SetErrorsPayloadType: SetErrorsPayloadType,
  SetIsNewPrivateEventListingType: SetIsNewPrivateEventListingType,
};

export const PrivateEventActions = {
  setPrivateEventField,
  selectPrivateEvent,
  setErrors,
  setIsNewPrivateEventListing,
};

const reducer = createReducer(privateEventReducerState(), (builder) => builder
  .addCase(selectPrivateEvent, selectPrivateEventReducer)
  .addCase(setErrors, setErrorsReducer)
  .addCase(setIsNewPrivateEventListing, setIsNewPrivateEventListingReducer)
  .addCase(setPrivateEventField, setPrivateEventFieldReducer));

export default reducer;
