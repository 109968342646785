import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import IListingQuery from '@biproxi/models/interfaces/IListingQuery';
import ListingQuerySortByEnum from '@biproxi/models/enums/ListingQuerySortByEnum';
import useOnclickOutside from 'react-cool-onclickoutside';
import Colors from '../styles/Colors';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { ListingActions, ListingSelectors } from '../redux/listing.redux';
import { AppActions } from '../redux/app.redux';
import FilterButton from '../elements/FilterButton';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import DropdownList from './DropdownList';
import Icon, { Icons } from '../elements/Icon';
import { media, useMobileMedia } from '../utils/MediaQuery';
import { ModalTypesEnum } from './modal/Modal';
import Flex from '../elements/Flex';
import Text, { TextTypesEnum } from '../elements/Text';

/**
 * This component copies a lot from Dropdown
 * It would be nice to refactor them both to do
 * more code sharing but this will work in the meantime
 */

type SortMenuProps = {
  value: ListingQuerySortByEnum | null;
  save: (sortBy: ListingQuerySortByEnum) => void;
}

const SortMenu: React.FC<SortMenuProps> = ({
  value,
  save,
}) => {
  /** State */
  const [isOpen, setIsOpen] = React.useState(false);
  const sortButtonRef = React.useRef<HTMLDivElement>();
  const isMobile = useMobileMedia();

  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  const items = Object.values(ListingQuerySortByEnum).map((value) => ({
    name: value,
    value,
  }));

  /** Actions */
  const saveAll = (sortBy: ListingQuerySortByEnum) => {
    setIsOpen(false);
    save(sortBy);
  };

  /** Render */
  return (
    <div ref={ref}>
      <FilterButton
        dataCy="trending-filter-button"
        ref={sortButtonRef}
        value={isMobile ? `${value}` : `Sort by: ${value}`}
        onClick={() => setIsOpen(true)}
        icon={<Icon icon={Icons.AngleDownRegular} color={Colors.Grey900} size={10} margin="0 0 0 8px" />}
      />
      <DropdownList
        cyList="trending-filter-dropdown"
        ref={sortButtonRef}
        value={value}
        open={isOpen}
        setOpen={setIsOpen}
        onChange={(value) => saveAll(value)}
        items={items}
        width="fit-content"
        right
      />
    </div>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 32px 0;

  ${media.mobile} {
    margin: 16px 16px 0 16px;
  }

  ${media.tablet} {
    margin: 16px 16px 0 16px;
  }
`;

export type SearchListingsFiltersProps = {
  listings: IListingGraphQL[] | null[];
  loading: boolean;
  querying: boolean;
};

const SearchListingsFilters: React.FC<SearchListingsFiltersProps> = () => {
  /** State */
  const query: IListingQuery = useAppSelector(ListingSelectors.searchQuery);

  /** Actions */
  const dispatch = useAppDispatch();
  const setListingSearchQuery = (query: Partial<IListingQuery>) => dispatch(
    ListingActions.setListingSearchQuery(query),
  );

  const openFiltersModal = () => dispatch(AppActions.pushModal({
    type: ModalTypesEnum.ListingFilters,
    props: {},
  }));

  /** Helper Functions */
  const handleClearFilters = () => dispatch(
    ListingActions.resetListingSearchQuery(),
  );

  /**
   * Function that determines if filters have been applied
   * The basic logic behind this is as follows:
   * The query object will always contain at least three fields: price, queryType and sortBy.
   * If a user applied filters, the length of the query object will be greater than 3 so we check for that.
   * However, there is the case that a user only applies price filters, so we also check for non-null values within the price filter
   */
  const determineAppliedFilters = (): boolean => {
    let filterFlag = false;
    if (Object.values(query).length > 3 || query?.price?.min || query?.price?.max) filterFlag = true;

    return filterFlag;
  };

  /** Render */
  return (
    <Container>
      <Flex align="center">
        <Button
          type={ButtonTypesEnum.Outline}
          size={ButtonSizesEnum.Medium}
          icon={Icons.VerticalSliders}
          iconColor={Colors.Grey900}
          iconSize={12}
          onClick={() => openFiltersModal()}
          text="Filters"
          margin="0 8px 0 0"
          data-cy="main-filter-button"
        />
        {determineAppliedFilters() && (
        <Text
          type={TextTypesEnum.Medium12}
          color={Colors.Brand700 || Colors.Blurple700}
          onClick={() => handleClearFilters()}
        >
          Clear all filters
        </Text>
        )}
      </Flex>
      <SortMenu
        value={query?.sortBy}
        save={(sortBy) => setListingSearchQuery({ sortBy })}
      />
    </Container>
  );
};

export default React.memo(SearchListingsFilters);
