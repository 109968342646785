/* eslint-disable no-undef */
import { logger } from '@biproxi/logger';
import IAddress from '../interfaces/IAddress';

declare const google: { maps: { Geocoder: new () => any; }; };

/**
 * Pass in an address object to receive a nicely formatted string
 * of the address. Pass in the skip field object like { address2: true }
 * to skip address2 in the formatted output.
 */
export type TSkipFormatAddressFields = {
  address1?: boolean
  address2?: boolean
  city?: boolean;
  state?: boolean;
  zip?: boolean;
  country?: boolean;
  allButAddress1?: boolean // common pattern
};

function formatAddress(a: IAddress, skipField?: TSkipFormatAddressFields): string {
  if (skipField?.allButAddress1 && a?.address1) return a.address1;

  let s = '';
  if (a?.address1 && !skipField?.address1) s = s.concat(`${a.address1}, `);
  if (a?.address2 && !skipField?.address2) s = s.concat(`${a.address2}, `);
  if (a?.city && !skipField?.city) s = s.concat(`${a.city}, `);
  if (a?.state && !skipField?.state) s = s.concat(`${a.state} `);
  if (a?.zip && !skipField?.zip) s = s.concat(`${a.zip}, `);
  if (a?.country && !skipField?.country) s = s.concat(`${a.country}`);
  return s;
}

/**
 * Returns the latitude of the address.
 * GeoJson coordindates are in [lng, lat] order.
 */
function getLatitude(a: Partial<IAddress>): string | null {
  return a?.location?.coordinates[1] ?? null;
}

/**
 * Returns the longitude of the address.
 * GeoJson coordindates are in [lng, lat] order.
 */
function getLongitude(a: Partial<IAddress>): string | null {
  return a?.location?.coordinates[0] ?? null;
}

/**
 * Returns the latitude of the address as a
 * floating pointer number.
 *
 * GeoJson coordindates are in [lng, lat] order.
 */

function getLatitudeAsFloat(a: IAddress): number | null {
  return convertLatLngStringToFloat(a?.location?.coordinates[1] ?? '');
}

/**
 * Returns the longitude of the address as a
 * floating pointer number.
 *
 * GeoJson coordindates are in [lng, lat] order.
 */

function getLongitudeAsFloat(a: IAddress): number | null {
  return convertLatLngStringToFloat(a?.location?.coordinates[0] ?? '');
}

/**
 * Converts a string latitude or longitude value
 * to a float.
 *
 * Returns a 0 upon failure.
 */

function convertLatLngStringToFloat(value: string): number {
  const returnValue = Number.parseFloat(value);
  if (Number.isNaN(returnValue)) return 0;
  return returnValue;
}

function hasLatAndLng(address: IAddress): boolean {
  return Boolean(getLatitude(address) && getLongitude(address));
}

/**
 * Cherre requires a special ID to query
 * for geographies. The ID is the zip code
 * of the address prepended witht the string 'ZI'
 */

function cherreGeographyId(address: IAddress): string {
  return `ZI${address?.zip}`;
}

/**
 * Cherre required a standardized address format
 * to query their API by address. Cherre provides an
 * API endpoint to retreive this the formatted address,
 * but the input to this endpoint must also be formatted.
 * The function below formats our IAdress model in a way
 * that Cherre can parse in their standardized address format
 */

function formatCherreAddress(address: IAddress): string {
  return formatAddress(address);
}

/** Cherre can accept latitude and longitude fields and get a cherreParcelId
 * that we in turn use for getting tax listings. This option exists
 * when a user decides to go to the manual entry form for their listing
 * and chooses their location from the map, getting the property's
 * latitude and longitude. The function below formats the lat and long
 * properties on the address so that the cherre query can accept them.
 */

function formatCherreLatLong(address: IAddress): object {
  return {
    latitude: getLatitudeAsFloat(address),
    longitude: getLongitudeAsFloat(address),
  };
}

/**
 * True if the address passed in contains all of the required fields
 * for it to be usable.
 */
function isCompleteAddress(address: IAddress): boolean {
  if (!address) return false;
  return !!(address.address1
    && address.city
    && address.state
    && address.country
    && address.zip
    && (address?.location?.coordinates?.length ?? 0) > 0
    && address.location?.type);
}

/**
 * Function takes in a lat-lng object and uses Google's geocoder to return a formatted address hehe
 */
async function reverseGeocode(latLng: string[]) {
  const geocoder = new google.maps.Geocoder();
  const latLngFloat = {
    lat: parseFloat(latLng[1]),
    lng: parseFloat(latLng[0]),
  };

  let address: IAddress | null = null;
  try {
    const geocodedAddress = await geocoder.geocode({ location: latLngFloat });
    if (geocodedAddress?.results[0]) {
      const formattedAddress = geocodedAddress.results[0]?.formatted_address;
      const addressComponents = formattedAddress?.split(',');

      address = {
        address1: addressComponents[0],
        city: addressComponents[1].slice(1, addressComponents[1].length),
        state: addressComponents[2].slice(1, 3),
        zip: addressComponents[2].slice(addressComponents[2].length - 5),
        location: {
          type: 'Point',
          coordinates: latLng,
        },
        googlePlaceId: geocodedAddress.results[0]?.place_id,
        timeZoneId: null,
      };
    }
  } catch (e) {
    logger.error('reverseGeocode error', e);
  }

  return address;
}

/**
 * Function takes in an address as a string. The address can be anything from a full address such as `06 OXFORD DRIVE, BOZEMAN, MT 59715` to a city, state such as `Bozeman, MT`.
 * Original useage of this bad boy is to take a city and state and get the centralized zip code of that city. E.g. given 'Bozeman, MT' as input, the zip code associated with the center of Bozeman is 59715, so that is what is returned
 * Will also work for a more specific address tho.
 * I wanted this function to return all zip codes associated with a city but unfortunately, without some heavy lifting on our end, I am not sure if that is possible. I could potentially cluster up a city based on the bounds of that state and find the centroid of each cluster, then get the zip for each centroid, but to make it accurate, I would have to make a bunch of cluster and it would be a computationally taxing process. This is easier and will work for now. Is whateva
 */
async function geocodeZipCode(address: string) {
  const geocoder = new google.maps.Geocoder();

  let zipCode: string = '';
  try {
    const geocodedAddress = await geocoder.geocode({ address });
    if (geocodedAddress?.results[0]) {
      const geocodedLocation = await geocoder.geocode({ location: geocodedAddress?.results[0]?.geometry?.location, bounds: geocodedAddress?.results[0]?.geometry?.bounds });
      if (geocodedLocation?.results[0]) {
        const addressComponents = geocodedLocation?.results[0]?.address_components;
        if (addressComponents) {
          addressComponents.forEach((component: any) => {
            if (component?.types?.includes('postal_code')) {
              zipCode = component.short_name;
            }
          });
        }
      }
    }
  } catch (e) {
    logger.error('geocodeZipCode error', e);
  }

  return zipCode;
}

/**
 * This function is meant to parse a one line address into it's separate components
 * e.g. giving 606 OXFORD DRIVE, BOZEMAN, MT 59715 as input, this function would return an IAddress object with '606 Oxford Drive' as address 1, 'Bozeman' as the city, 'MT' as the state and '59715' as the zip
 * Obviously, addresses are tricky so this function may not work for EVERY address but it is designed to work for basically every address returned by Cherre
 * Trick: Work backwards.
 * Assumption: The last 5 characters will be the zip. Every character up until the first occurring comma will be the state, everything between that comma and the next comma will be the city, and everything beyond that is address 1. This is a very simple function that will not take address 2 into consideration
 */
function parseOneLineAddress(oneLineAddress: string): IAddress {
  if (oneLineAddress) {
    const zipCode = oneLineAddress?.substring((oneLineAddress?.length ?? 0) - 5);
    const splitAddress = oneLineAddress.split(',');
    const state = splitAddress[splitAddress.length - 1]?.substring(1, 3);
    const city = splitAddress[splitAddress.length - 2]?.substring(1, splitAddress[splitAddress.length - 2].length);
    const address = splitAddress[0];

    return {
      address1: address,
      city,
      state,
      zip: zipCode,
      googlePlaceId: null,
      timeZoneId: null,
    };
  }

  return {
    address1: '',
    city: '',
    state: '',
    zip: '',
    googlePlaceId: null,
    timeZoneId: null,
  };
}

/**
 * Checks if the lat lng coordinates for all passed in addresses are the same.
 * Used in portoflio listing to fix an issue where lat and lng were the same for all addresses
 * and the map wasn't rendering correctly because doing the math to calculate the viewport results in 0.
 */
const doAllAddressesHaveExactSameLocation = (addresses: IAddress[]): boolean => [...new Map(addresses
  ?.map((address) => [JSON.stringify(address?.location?.coordinates), address?.location?.coordinates]))
  ?.values() || null]?.length === 1;

const AddressUtil = {
  formatAddress,
  getLatitude,
  getLongitude,
  getLatitudeAsFloat,
  getLongitudeAsFloat,
  hasLatAndLng,
  convertLatLngStringToFloat,
  cherreGeographyId,
  formatCherreAddress,
  formatCherreLatLong,
  reverseGeocode,
  geocodeZipCode,
  parseOneLineAddress,
  isCompleteAddress,
  doAllAddressesHaveExactSameLocation,
};

export default AddressUtil;
