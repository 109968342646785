import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import styled from '@emotion/styled';
import { logger } from '@biproxi/logger';
import { config } from '@biproxi/env';
import ReactTooltip from 'react-tooltip';
import {
  BrowserRouter,
} from 'react-router-dom';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import { tacklebox } from '@biproxi/tacklebox';
import BiproxiCustomCss from '../styles/customCss';
import '../styles/index.css';
import { useApollo } from '../graphql/client';
import { useStore } from '../redux/store';
import Modal from '../components/modal/Modal';
import MainNavigation from '../components/MainNavigation';
import Toast from '../components/Toast';
import FilePreview from '../components/FilePreview';
import NotificationPopUp from '../components/NotificationPopUp';
import Colors from '../styles/Colors';
import { TAppPageProps } from '../models/interfaces/IPageConfiguration';
import ErrorBoundary from '../containers/ErrorBoundary.container';
import NextUtil from '../utils/NextUtil';
import HubSpot from '../components/HubSpot';
import Pusher from '../components/Pusher';
import ZIndexes from '../styles/ZIndexes';
import BiproxiFavicon from '../../public/favicon.ico';

logger.info(`STARTING WEB [${config.NEXT_PUBLIC_ENVIRONMENT_NAME}]`);

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;

  ${BiproxiCustomCss()};
`;

const Tooltip = styled(ReactTooltip)`
  color: ${Colors.White};
  background-color: ${Colors.Black95} !important;
  border-radius: 8px;
  padding: 12px !important;
  max-width: 200px;
  z-index: ${ZIndexes.Tooltip} !important;
`;

type AppProps = {
  Component: any;
  pageProps: TAppPageProps;
}

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const client = useApollo(pageProps.config);
  const store = useStore(pageProps.config);
  const [hasDom, sethasDom] = React.useState(false);
  const [title, setTitle] = React.useState('Biproxi');

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */
  /** Contentful */
  const fetchHead = async () => {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    try {
      const { name, favicon } = await tacklebox.getBrandForHostname();

      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
        link.href = favicon.url;
      }
      if (title !== name) {
        setTitle(name);
      }
    } catch (e) {
      logger.info(e);
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
        link.href = BiproxiFavicon.src;
      }
    }
  };

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  /**
   *  This solution gets us to the point where we were before React 18.
   *  However, I'm still not sure if this is optimal for our entire site.
   *  We are in this weird state where we are half using ssr and half not and it is quite confusing
   *  determining what should or shouldn't be server side rendered or use nextjs functions, etc.
   *  I think we need to come up with some clarification and standards on what we want.
   *
   *  Remove the hasDom stuff to crash the whole site and see the errors.
   *  https://stackoverflow.com/questions/71706064/react-18-hydration-failed-because-the-initial-ui-does-not-match-what-was-render
   */
  React.useEffect(() => {
    sethasDom(true);
    fetchHead();
  }, []);

  if (!hasDom) {
    return null;
  }

  const queryClient = new QueryClient();

  /**
   * Sketchy check to check if the user is on an active PDP page
   * Regex is, unfortunately, necessary since we have to check for an exact match for 'listing'
   */
  const isActivePDP = typeof window !== 'undefined' ? window?.location?.href?.search(/listing\b/) > 0 : false;
  const isPreviewPDP = typeof window !== 'undefined' ? window?.location?.href?.search(/create-listing\b/) > 0 : false;

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <ReduxProvider store={store}>
            <>
              <Head>
                {/* This line apparently breaks all the icons if we try to change it dynamically ?? :/ */}
                {/* <link rel="shortcut icon" href={favicon} /> */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content={`${Colors.Brand700 || Colors.Blurple700}`} />
                <title>
                  {' '}
                  $
                  {title}
                  {' '}
                  | CRE Listings
                </title>
              </Head>
              <Container suppressHydrationWarning>
                {(() => {
                  if (!NextUtil.hasWindow()) {
                    return <></>;
                  }

                  return (
                    <BrowserRouter>
                      <DefaultSeo
                        defaultTitle={`${title} | CRE Listings`}
                        titleTemplate={`${title} | %s`}
                      />
                      <HubSpot />
                      <Modal />
                      <Pusher />
                      <NotificationPopUp />
                      <Toast />
                      <FilePreview />
                      <Tooltip effect="solid" />
                      <MainNavigation
                        isActivePDP={isActivePDP}
                        isPreviewPDP={isPreviewPDP}
                      />
                      <Component {...pageProps} />
                      <ReactQueryDevtools initialIsOpen={false} />
                    </BrowserRouter>
                  );
                })()}
              </Container>
            </>
          </ReduxProvider>
        </QueryClientProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
