/* eslint-disable react/no-unused-prop-types */
import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { media } from '../../utils/MediaQuery';
import Input, { InputTypesEnum } from '../../elements/Input';
import INITIATE_CHAT from '../../graphql/mutations/initiateChat.mutation';
import { ListingSelectors } from '../../redux/listing.redux';
import { ModalTypesEnum } from './Modal';
import { ConfirmChangeModalTypesEnum } from './ConfirmChangeModal';
import KeyCodesEnum from '../../models/enums/KeyCodesEnum';

const Container = styled.div`
  width: 432px;

  ${media.mobile} {
    width: 100%;
  }
`;

export type ContactBrokerModalProps = {
  listingIdProp?: string;
  modalHeader?: string;
  modalCancelButtonText?: string;
  modalSubmitButtonText?: string;
};

export type ContactBrokerModalConfig = {
  onComplete?: (result?: any) => void,
  onError?: (error: Error) => void,
  onSubmit?: (submitParams?: any) => void;
  onCancel?: () => void,
  params?: ContactBrokerModalProps;
};

const ContactBrokerModal: React.FC<ContactBrokerModalConfig> = (
  config: ContactBrokerModalConfig = { params: {} as ContactBrokerModalProps },
) => {
  /** State */
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState('');

  /** Actions */
  const dispatch = useAppDispatch();
  const listingId = config?.params?.listingIdProp ?? useAppSelector(ListingSelectors.selectedListingId);

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const replaceWithConfirmModal = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Success,
        title: 'Your message has been sent to the broker',
        text: 'Go to your messages tab in your dashboard to view and respond to new messages.',
        confirm: (event: React.FormEvent) => {
          event?.preventDefault();
          popModal();
        },
      },
    }),
  );

  /** GraphQL */
  const [initiateChat, { loading }] = useMutation(INITIATE_CHAT, {
    onCompleted: () => {
      // perhaps a better way to handle this in the future but the idea is that if you pass an onSubmit method, you will also pass a custom modal within that onSubmit method, thus no need to push this one as well
      if (config?.onSubmit?.() === undefined) {
        replaceWithConfirmModal();
      }
    },
    onError: (error) => {
      logger.error('INITIATE_CHAT error', error);
      const { message } = ApolloUtil.parseApolloClientError(error);
      setError(message);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    await config?.onSubmit?.();

    if (!message || loading) return;

    initiateChat({
      variables: {
        params: {
          message,
          listingId,
        },
      },
    });
  };

  /* Render */
  return (
    <ModalContainer>
      <ModalHeader title={config?.params?.modalHeader ?? 'Contact Broker'} close={popModal} />
      <ModalContent>
        <Container onSubmit={handleSubmit}>
          <Input
            autoFocus
            placeholder="Enter your message to the broker"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            error={error}
            inputType={InputTypesEnum.TextArea}
            height="120px"
            data-cy="contact-broker-modal-input"
            onKeyPress={(e) => {
              if (e.which === KeyCodesEnum.ENTER && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
          />
        </Container>
      </ModalContent>
      <ModalFooter>
        <Flex justify="flex-end" width="100%">
          <Button
            text={config?.params?.modalCancelButtonText ?? 'Cancel'}
            type={ButtonTypesEnum.Ghost}
            size={ButtonSizesEnum.Medium}
            onClick={() => popModal()}
            margin="0px 8px 0px 0px"
            htmlType="button"
          />
          <Button
            text={config?.params?.modalSubmitButtonText ?? 'Send'}
            isLoading={loading}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            onClick={(event) => handleSubmit(event)}
            data-cy="contact-broker-modal-send-button"
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default ContactBrokerModal;
