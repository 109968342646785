// eslint-disable-next-line import/no-extraneous-dependencies
import getConfig from 'next/config';
import { getRuntimeConfig } from './env.types';

enum EnvironmentNamesEnum {
  dev = 'dev',
  test = 'test',
  development = 'development',
  staging = 'staging',
  production = 'production',
}

const nextConfig = getConfig();

export const isNext = !!nextConfig;
export const isNode = (typeof window === 'undefined');
export const isJest = !!process.env.JEST_WORKER_ID;
export const isServerless = isNode && !process.env.POD_NAME;

export const config = getRuntimeConfig(!isNext ? process.env : isNode ? nextConfig.serverRuntimeConfig : nextConfig.publicRuntimeConfig);

export const hostname = (defaultHostname: string = config.WEB_UI_URL ?? 'unknown') => (
  typeof window !== 'undefined' && window.location.hostname !== 'undefined'
    ? window.location.hostname
    : defaultHostname
);

export const hostNameAggregator = (defaultHostname: string = config.WARDEN_WEB_UI_URL ?? 'unknown') => (
  typeof window !== 'undefined' && window.location.hostname !== 'undefined'
    ? window.location.hostname
    : defaultHostname
);

export const isProduction = () => config.NEXT_PUBLIC_ENVIRONMENT_NAME === EnvironmentNamesEnum.production;
export const isStaging = () => config.NEXT_PUBLIC_ENVIRONMENT_NAME === EnvironmentNamesEnum.staging;
export const isDevelopment = () => config.NEXT_PUBLIC_ENVIRONMENT_NAME === EnvironmentNamesEnum.development;
export const isLocal = () => config.NEXT_PUBLIC_ENVIRONMENT_NAME === EnvironmentNamesEnum.dev;
export const isTest = () => config.NEXT_PUBLIC_ENVIRONMENT_NAME === EnvironmentNamesEnum.test;

// console.info(JSON.stringify(env2, null, 2))
export const warn = (warnCallback: (msg: string) => void) => {
  Object.entries(config).forEach(([key, value]) => {
    if (value === undefined) {
      if (isNode || key.startsWith('NEXT_PUBLIC_')) {
        // console.error(`Missing key ${key} in config.env`);
        warnCallback(`ENVIRONMENT:  Missing key ${key}`);
      }
    }
  });
};
