import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../redux/store';
import { ListingActions } from '../redux/listing.redux';

type NavigateToListingDetails = (listingId?: string, path?: string) => void;

type NavigateToListingDetailsHook = () => NavigateToListingDetails;

const useNavigateToListingDetails: NavigateToListingDetailsHook = () => {
  /* Hooks */
  const navigate = useNavigate();

  /* Actions */
  const dispatch = useAppDispatch();
  const setListingId = (listingId: string) => dispatch(ListingActions.selectListing({ listingId }));

  const listingDetails = React.useCallback((listingId, path = '/overview') => {
    setListingId(listingId);
    navigate(`/app/dashboard/listings/details${path}?listingId=${listingId}`);
  }, []);

  /** Return */
  return listingDetails;
};

export default useNavigateToListingDetails;
