import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import UserUtil from '@biproxi/models/utils/UserUtil';
import Colors from '../styles/Colors';
import Icon, { Icons } from '../elements/Icon';
import Text, { TextTypesEnum } from '../elements/Text';
import FAVORITE_LISTING from '../graphql/mutations/favoriteListing.mutation';
import { AppActions } from '../redux/app.redux';
import { AppState, useAppDispatch } from '../redux/store';
import useUser from '../hooks/useUser.hook';
import { UserActions, UserSelectors } from '../redux/user.redux';
import { IToastConfig, ToastTypesEnum } from './Toast';
import useRequireAuthentication from '../hooks/useRequireAuthentication.hook';

export enum FavoriteButtonTypesEnum {
  Outline = 'Outline',
  Filled = 'Filled',
  Text = 'Text',
}

const Circle = styled.div<{ isFavorited: boolean, type: string }>`
  display: flex;
  transition: background-color 0.2s;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  color: ${({ isFavorited, type }) => {
    if (isFavorited) return `${Colors.Yellow500}`;
    return type === FavoriteButtonTypesEnum.Outline ? `${Colors.Black}` : `${Colors.White}`;
  }};

  &:hover {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  }
`;

const TextContainer = styled.div`
  transition: all 0.2s;
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0px 8px;
  border-radius: 8px;
  cursor: pointer;
  min-width: fit-content;

  &:hover {
    background: ${Colors.Grey200};
  }
`;

type FavoriteButtonProps ={
  listingId: string;
  type: FavoriteButtonTypesEnum;
  dataCy?: string;
};

const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  listingId,
  type,
  dataCy,
}) => {
  /** Hooks */
  const { user } = useUser();
  const requireAuthentication = useRequireAuthentication();

  /** State */
  const isFavorited = UserUtil.isFavoriteListing(user, listingId);
  const [toastMessage, setToastMessage] = React.useState('Listing successfully unfavorited');

  /* Actions */
  const dispatch = useAppDispatch();

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const pushFavoritedListing = () => dispatch(
    UserActions.pushUserFavoritedListings({ listingId }),
  );

  const popFavoritedListing = () => dispatch(
    UserActions.popUserFavoritedListings({ listingId }),
  );

  /* GraphQL */
  const [favoriteListing] = useMutation(FAVORITE_LISTING, {
    onCompleted: () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: toastMessage,
      });
    },
    onError: (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);

      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
  });

  const setFavoriteListing = () => {
    if (isFavorited) { // user is unfavoriting
      popFavoritedListing();
      setToastMessage('Listing successfully unfavorited');
    } else { // user is favoriting
      pushFavoritedListing();
      setToastMessage('Listing successfully favorited');
    }
    favoriteListing({
      variables: {
        params: {
          listingId,
          favorite: !isFavorited,
        },
      },
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    requireAuthentication(setFavoriteListing, (store: AppState) => {
      const cachedUser = UserSelectors.user(store);
      return !UserUtil.isFavoriteListing(cachedUser, listingId);
    });
  };

  return (
    <>
      {type === FavoriteButtonTypesEnum.Text ? (
        <TextContainer
          data-cy={dataCy}
          onClick={(e) => handleClick(e)}
        >
          <Text
            type={TextTypesEnum.Medium14}
            color={Colors.Grey700}
          >
            Favorite
          </Text>
          <Icon
            icon={isFavorited ? Icons.StarSolid : Icons.StarLight}
            color={isFavorited ? `${Colors.Yellow500}` : 'inherit'}
            size={16}
            margin="0px 0px 0px 8px"
            transitionDuration="0"
          />
        </TextContainer>
      ) : (
        <Circle
          type={type}
          isFavorited={isFavorited}
          onClick={(e) => handleClick(e)}
        >
          <Icon
            icon={isFavorited ? Icons.StarSolid : Icons.StarLight}
            color="inherit"
            size={16}
            transitionDuration="0"
          />
        </Circle>
      )}
    </>
  );
};

export default FavoriteButton;
