import gql from 'graphql-tag';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import { DocumentNode } from 'graphql';
import UserFragments from './user.fragments';
import OfferFragments from './offer.fragments';
import ListingFragments from './listing.fragments';
import ChannelFragments from './channel.fragments';

// Not a perfect type, but it's good enough for now
type TLeadFragments = {
  [K in keyof ILeadGraphQL]?: DocumentNode;
} & {
  fields: DocumentNode;
}

const LeadFragments: TLeadFragments = {
  fields: gql`
    fragment LeadFields on Lead {
      _id
      userId
      listingId
      listingUserId
      listingOrganizationId
      isNewLead
      isInterested
      isFavorite
      isInvestorMatch
      sendbirdChannelUrl
      state
      guidance
      offerAmount
      loiFileIds
    }
  `,
  loiFiles: gql`
    fragment LeadLoiFiles on Lead {
      loiFiles {
        _id
        name
        mimetype
        url
      }
    }
  `,
  offers: gql`
    fragment LeadOffers on Lead {
      offers {
        ...OfferFields
        ...OfferLoiFiles
      }
    }

    ${OfferFragments.offerFields}
    ${OfferFragments.loiFiles}
  `,
  fileAccessLevel: gql`
    fragment LeadFileAccessLevel on Lead {
      fileAccessLevel {
        accessLevel
        requestedAccessLevel
        hasBeenDenied
        hasSignedCA
      }
    }
  `,
  privateListingAccess: gql`
    fragment LeadPrivateListingAccess on Lead {
      privateListingAccess {
        accessRequested
        accessDenied
        accessApproved
      }
    }
  `,
  notes: gql`
    fragment LeadNotes on Lead {
      notes {
        _id
        text
        meta {
          createdAt
        }
      }
    }
  `,
  sellerNotes: gql`
    fragment LeadSellerNotes on Lead {
      sellerNotes {
        _id
        text
        meta {
          createdAt
        }
      }
    }
  `,
  user: gql`
    fragment LeadUser on Lead {
      user {
        ...UserFields
        ...UserAddress
        ...UserInvestorProfile
        ...UserProfileImageFile
      }
    }

    ${UserFragments.fields}
    ${UserFragments.address}
    ${UserFragments.investorProfile}
    ${UserFragments.profileImageFile}
  `,
  listing: gql`
    fragment LeadListing on Lead {
      listing {
        ...ListingFields
        ...ListingMedia
        ...ListingAddress
        ...ListingPortfolioAddresses
        ...ListingMeta
      }
    }

    ${ListingFragments.fields}
    ${ListingFragments.media}
    ${ListingFragments.address}
    ${ListingFragments.portfolioAddresses}
    ${ListingFragments.meta}
  `,
  metrics: gql`
    fragment LeadMetrics on Lead {
      metrics {
        pageViews
        lastPageViewedAt
        offersMade
        signedCA
        documentViews
        documentDownloads
      }
    }
  `,
  meta: gql`
    fragment LeadMeta on Lead {
      meta {
        createdAt
      }
    }
  `,
  analyticsEvents: gql`
    fragment LeadAnalyticsEvents on Lead {
      analyticsEvents {
        eventType
        createdAt
        fileId
      }
    }
  `,
  channel: gql`
    fragment LeadChannel on Lead {
      channel {
        ...ChannelFields
        ...ChannelUser
        ...ChannelListing
      }
    }

    ${ChannelFragments.fields}
    ${ChannelFragments.user}
    ${ChannelFragments.listing}
  `,
};

export default LeadFragments;
