import { logger } from '@biproxi/logger';
import SendBird from 'sendbird';
import { v4 as uuidv4 } from 'uuid';
import { IChannelGraphQL } from '@biproxi/models/interfaces/IChannel';
import { config } from '@biproxi/env';

const sb = new SendBird({ appId: config.NEXT_PUBLIC_SENDBIRD_APP_ID });
const CHANNEL_HANDLER_ID = uuidv4();
const USER_HANDLER_ID = uuidv4();

export enum SendBirdMessageTypes {
  User = 'user',
  File = 'file',
}

/**
 * Connect current user to Sendbird.
 */
const connectUser = async (userId: string) => {
  if (userId) {
    try {
      const res = await sb.connect(userId);
      // console.log(`Successfully connected user ${userId} to Sendbird.`);
      return res;
    } catch (e) {
      logger.error(`Error: The user ${userId}: could not connect to Sendbird.`, e);
      return null;
    }
  }
  return null;
};

/**
 * Get the channel
 */
const getChannel = async (channelUrl: string) => {
  try {
    const groupChannel = await sb.GroupChannel.getChannel(channelUrl);
    return groupChannel;
  } catch (e) {
    logger.error('getChannel error', e);
    return null;
  }
};

/**
 * Send message to desired channel
 */
const sendMessage = async (message: string, channel: IChannelGraphQL) => {
  const params = new sb.UserMessageParams();
  params.message = message;

  let groupChannel;
  try {
    groupChannel = await getChannel(channel.channelUrl);
    if (!groupChannel) return null;
  } catch (e) {
    logger.error('sendMessage error', e);
    return null;
  }

  // Only works if the callback parameter is there for some reason..
  try {
    const res = await groupChannel.sendUserMessage(params, () => {});
    return res;
  } catch (e) {
    logger.error('groupChannel.sendUserMessage error', e);
    return null;
  }
};

/**
 * Sends a binary file message that gets hosted on Sendbird servers.
 * Currently only supports one file at a time.
 */
const sendFileMessage = async (file, channelUrl: string) => {
  const params = new sb.FileMessageParams();
  params.file = file;
  // Future enhancement would be to use file thumbnails
  // it's a premium feature and I'm not sure if it even supports pdfs.
  // params.thumbnailSizes = [{ maxWidth: 200, maxHeight: 200 }];

  // https://sendbird.com/docs/chat/v3/javascript/guides/group-channel-advanced#2-track-file-upload-progress-using-a-handler

  let groupChannel;
  try {
    groupChannel = await getChannel(channelUrl);
    if (!groupChannel) return null;
  } catch (e) {
    logger.error('getChannel error', e);
    return null;
  }

  try {
    // Only works if the callback parameter is there for some reason..
    const res = await groupChannel.sendFileMessage(params, () => {});
    return res;
  } catch (e) {
    logger.error('sendFileMessage error', e);
    return null;
  }
};

/**
 * Send message to desired channel
 */
const markChannelAsRead = async (channelUrl: string) => {
  let groupChannel;
  try {
    groupChannel = await getChannel(channelUrl);
    if (!groupChannel) return null;
  } catch (e) {
    logger.error('markChannelAsRead error', e);
    return null;
  }

  if (groupChannel?.unreadMessageCount > 0) {
    try {
      const res = await groupChannel.markAsRead();
      return res;
    } catch (e) {
      logger.error('groupChannel.markAsRead() error', e);
      return null;
    }
  }
  return null;
};

const markAllChannelsAsRead = async () => {
  try {
    const res = await sb.markAsReadAll();
    return res;
  } catch (e) {
    logger.error('markAllChannelsAsRead error', e);
    return null;
  }
};

/**
 * Gets all channel messages.
 */
const getChannelMessages = async (channelUrl: string) => {
  let groupChannel;
  try {
    groupChannel = await getChannel(channelUrl);
    if (!groupChannel) return [];
  } catch (e) {
    logger.error('getChannelMessages error', e);
    return [];
  }

  const listQuery = groupChannel?.createPreviousMessageListQuery();

  const limit = 30; // max messages loaded per call
  const reverse = false; // messaging order
  const type = ''; // loads all

  try {
    const messages = await listQuery.load(limit, reverse, type);
    return messages;
  } catch (e) {
    logger.error('listQuery.load() error', e);
    return [];
  }
};

/**
 * Listens for new messages received by the currrent user with a persistent websocket connection.
 */
const connectChannelEventHandler = (setNewMessageReceived: (channel: any, message: any) => void) => {
  const channelHandler = new sb.ChannelHandler();

  channelHandler.onMessageReceived = async (channel: any, message: any) => {
    const metadata = await channel.getMetaData(['listingId']);

    const channelInfo = {
      channelUrl: channel.url,
      lastMessageText: channel?.lastMessage?.message,
      lastMessageAt: channel?.lastMessage?.createdAt,
      hasUnreadMessages: channel?.unreadMessageCount > 0,
      listingId: metadata?.listingId,
    };

    const messageInfo = {
      channelUrl: message.channelUrl,
      messageId: message.messageId,
      sender: {
        userId: message.sender.userId,
      },
      createdAt: message.createdAt,
      message: message.message,
      // used for file message
      name: message.name,
      url: message.url,
      messageType: message.messageType,
    };

    setNewMessageReceived(channelInfo, messageInfo);
  };
  sb.addChannelHandler(CHANNEL_HANDLER_ID, channelHandler);
};

/**
 * Disconnects the channel event handler
 */
const disconnectChannelEventHandler = () => {
  sb.removeChannelHandler(CHANNEL_HANDLER_ID);
};

/**
 * Listens for updates to the current unread messages for a user with a persistent websocket connection.
 */
const connectUserEventHandler = (setUnreadMessageCount: (unreadMessageCount: number) => void) => {
  const userEventHandler = new sb.UserEventHandler();

  userEventHandler.onTotalUnreadMessageCountUpdated = (totalCount) => {
    setUnreadMessageCount(totalCount as number);
  };
  sb.addUserEventHandler(USER_HANDLER_ID, userEventHandler);
};

/**
 * Disconnects the channel event handler
 */
const disconnectUserEventHandler = () => {
  sb.removeUserEventHandler(USER_HANDLER_ID);
};

/**
 * Close application Sendbird connection.
 */
const disconnectUser = () => {
  disconnectChannelEventHandler();
  disconnectUserEventHandler();
  // sb.disconnect(() => {
  //   console.log('Disconnected from Sendbird.');
  // });
};

/**
 * Returns the number of unread messages across
 * all group channels that a user has participated in.
 */
const getUnreadMessageCount = async () => {
  try {
    const count = await sb.getTotalUnreadMessageCount();
    return count;
  } catch (e) {
    logger.error('getUnreadMessageCount error', e);
    return 0;
  }
};

const SendBirdClientSDK = {
  connectUser,
  disconnectUser,
  getChannel,
  getChannelMessages,
  sendMessage,
  sendFileMessage,
  connectChannelEventHandler,
  disconnectChannelEventHandler,
  connectUserEventHandler,
  disconnectUserEventHandler,
  getUnreadMessageCount,
  markChannelAsRead,
  markAllChannelsAsRead,
};

export default SendBirdClientSDK;
