import gql from 'graphql-tag';

const CREATE_USER = gql`
  mutation CreateUsers($input: [UserCreateInput!]!) {
    createUsers(input: $input) {
      info {
        nodesCreated
      }
      users {
        firstName
        lastName
        _id
      }
    }
  }
`;

export default CREATE_USER;
