import gql from 'graphql-tag';
import ListingFragments from '../fragments/listing.fragments';

const UPDATE_LISTING = gql`
  mutation UpdateListing($params: ListingParams!) {
    updateListing(params: $params) {
      ...ListingFields
      ...ListingAddress
      ...ListingPortfolioAddresses
      ...ListingSchedule
      ...ListingMedia
      ...ListingGuidance
      ...ListingVault
      ...ListingLicense
      ...ListingMetrics
      ...ListingMeta
      ...ListingUser
      ...ListingContacts
      ...ListingDealProfile
    }
  }

  ${ListingFragments.fields}
  ${ListingFragments.address}
  ${ListingFragments.portfolioAddresses}
  ${ListingFragments.schedule}
  ${ListingFragments.media}
  ${ListingFragments.guidance}
  ${ListingFragments.vault}
  ${ListingFragments.license}
  ${ListingFragments.metrics}
  ${ListingFragments.meta}
  ${ListingFragments.user}
  ${ListingFragments.contacts}
  ${ListingFragments.dealProfile}
`;

export default UPDATE_LISTING;
