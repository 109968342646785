import isDeepEqual from 'fast-deep-equal';
import { config } from '@biproxi/env';
import ListingStateEnum from '../enums/ListingStateEnum';
import FileAccessLevelsEnum from '../enums/FileAccessLevelsEnum';
import IFile from '../interfaces/IFile';
import IListingParams from '../interfaces/IListingParams';
import PropertyTypeFields from '../objects/PropertyTypeFields.object';
import ListingPropertyTypeEnum from '../enums/ListingPropertyTypeEnum';
import TListingInfoField from '../types/TListingInfoField';
import IListing, { IListingGraphQL } from '../interfaces/IListing';
import IListingInfoField from '../interfaces/IListingInfoField';
import TListingInfoFieldTypes from '../types/TListingInfoFieldTypes';
import ListingVaultFolderNamesEnum from '../enums/ListingVaultFolderNamesEnum';
import IListingVaultFolder, { IListingVaultFolderGraphQL } from '../interfaces/IListingVaultFolder';
import AddressUtil, { TSkipFormatAddressFields } from './AddressUtil';
import ListingInfoFieldNamesEnum from '../enums/ListingInfoFieldNamesEnum';
import listingInfoFieldsState from '../states/listingInfoFields.state';
import TimeUtil from './TimeUtil';
import IListingVault from '../interfaces/IListingVault';
import { TListingStatuses } from '../types/TListingStatus';
import ListingRequiredFieldsEnum from '../enums/ListingRequiredFieldsEnum';
import IListingContact from '../interfaces/IListingContact';
import DealProfileBuyerLocationEnum from '../enums/DealProfileEnums';
import { AmericanStatesExpandedEnum } from '../enums/AmericanStatesEnums';
import AmericanStatesDictionaryUtil from './AmericanStatesDictionaryUtil';
import StringUtil from './StringUtil';
import AuraListingFieldsEnum from '../enums/AuraListingFieldsEnum';
import BrokerCommisionEnum from '../enums/BrokerCommisionEnum';
import { INeo4jListing } from '../services/INeo4jService';

function field<T extends keyof TListingInfoFieldTypes, K extends TListingInfoFieldTypes[T]>(fieldName: T, value: K, unit?: string): IListingInfoField<K> {
  return {
    fieldName,
    value,
    unit,
  };
}

function getField<T extends keyof TListingInfoFieldTypes, K extends TListingInfoFieldTypes[T]>(infoFields: IListingInfoField<K>[], fieldName: ListingInfoFieldNamesEnum): IListingInfoField<K> | null {
  return infoFields.find((infoField) => infoField.fieldName === fieldName) ?? null;
}

function listingInfoFieldState(propertyType: ListingPropertyTypeEnum, lastInfoFields?: TListingInfoField[]): TListingInfoField[] {
  const infoFields = PropertyTypeFields[propertyType].map((fieldName) => {
    const currentInfoFieldValue = lastInfoFields ? getField(lastInfoFields, fieldName) : {};
    return {
      fieldName,
      value: (listingInfoFieldsState as any)[fieldName],
      ...(currentInfoFieldValue ?? {}),
    };
  });

  return infoFields;
}

function listingInfoFieldsAutocompleteState(lastInfoFields: TListingInfoField[], newInfoFields: TListingInfoField[]): TListingInfoField[] {
  const newInfo = lastInfoFields.map((field) => {
    const lastFieldName = field.fieldName;
    const newFieldValue = newInfoFields.find((field) => field.fieldName === lastFieldName)?.value ?? field.value;

    return {
      fieldName: field.fieldName,
      value: newFieldValue,
    };
  });

  return newInfo;
}

function folder(name: ListingVaultFolderNamesEnum, fileIds: string[], subFolders: IListingVaultFolderGraphQL[]): IListingVaultFolder {
  return {
    name,
    fileIds,
    subFolders,
  };
}

// I don't know why we do this, it seems to serve no purpose but the url of the pdp has the address in it
// unless it is private or portfolio
function slugifyAddressForPDPUrl(listing: IListing): string {
  const address = listing?.address;
  if (listing?.isPrivateListing) return 'private-listing';
  if (isValidPortfolioListing(listing)) return 'portfolio-listing';
  return StringUtil.slug(`${address?.address1 ?? 'street'}-${address?.city ?? 'city'}-${address?.state ?? 'state'}-${address?.zip ?? 'zip'}`);
}

function slug(listing: IListing | INeo4jListing): string {
  return `/listing/${listing._id}/${slugifyAddressForPDPUrl(listing as IListing)}`;
}

// function url(listing: IListing): string {
//   return `${config.WEB_UI_URL}/listing/${listing._id}/${slugifyAddressForPDPUrl(listing)}`;
// }

export function brandedListingUrl(listing: IListing): string {
  // TODO KEVIN  implement to sense branding hostname
  return `${config.WEB_UI_URL}/listing/${listing._id}/${slugifyAddressForPDPUrl(listing)}`;
}

function createListingPath(listingId?: string): string {
  return listingId ? `/app/create-listing?listingId=${listingId}` : '/app/create-listing';
}

function listingParams(listing: IListingGraphQL): IListingParams {
  function removeFiles(vaultFolder: any): IListingVaultFolder {
    return Object.keys(vaultFolder ?? {})
      .filter((key) => key !== 'files')
      ?.reduce((cur, key) => {
        if (key === 'subFolders') {
          cur[key] = vaultFolder[key].map((subFolder: any): IListing => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { files, ...noFiles } = subFolder;
            return {
              ...noFiles,
            };
          });
          return cur as IListingVaultFolder;
        }
        (cur as any)[key] = vaultFolder[key];
        return cur;
      }, {} as IListingVaultFolder);
  }

  const params: IListingParams = {
    _id: listing._id,
    name: listing.name,
    description: listing.description,
    assetClass: listing.assetClass,
    propertyType: listing.propertyType,
    score: listing.score,
    address: listing.address,
    media: {
      fileIds: listing.media.fileIds,
      video: {
        fileId: listing?.media?.video?.fileId ?? null,
      },
    },
    guidance: listing?.guidance ? {
      ...listing.guidance,
      brokerCommisionAmount: parseFloat((listing?.guidance?.brokerCommisionAmount as any)),
    } : {
      priced: false,
      askingPrice: null,
      depositAmount: null,
      brokerCommision: BrokerCommisionEnum.No,
      brokerCommisionAmount: null,
      dueDiligencePeriod: null,
      closingPeriod: null,
      callForOffersAt: null,
    },
    highlights: listing.highlights,
    info: listing.info,
    vault: {
      zipFileId: listing?.vault?.zipFileId || '',
      caFileId: listing?.vault?.caFileId || '',
      folders: listing?.vault?.folders?.length ? listing.vault.folders.map((vaultFolder) => removeFiles(vaultFolder)) : [],
    },
    license: listing?.license ?? {
      _id: '',
      owner: '',
      state: '',
      number: '',
    },
    state: listing.state,
    contacts: listing.contacts,
    dealProfile: listing?.dealProfile,
    listedByOwner: listing.listedByOwner,
    isPrivateListing: listing.isPrivateListing,
    isPortfolioListing: listing.isPortfolioListing,
    portfolioAddresses: listing.portfolioAddresses,
  };

  return params;
}

function vaultFiles(listing: IListingGraphQL): IFile[] {
  const finalFileCount = listing.vault.folders.reduce((cur: IFile[], next) => {
    const subFolderFiles = next.subFolders?.reduce((curSub, nextSub) => [...curSub, ...nextSub.files], [] as IFile[]);
    return cur.concat(next.files, subFolderFiles);
  }, []);
  return finalFileCount;
}

function vaultFileIds(listing: { vault: IListingVault }): string[] {
  return listing.vault.folders.reduce((cur: string[], next) => {
    const subFolderFileIds = next.subFolders?.reduce((curSub, nextSub) => [...curSub, ...nextSub.fileIds], [] as string[]);
    return cur.concat(next.fileIds, subFolderFileIds);
  }, []);
}

/**
 * Returns all folders in the vault
 */
function vaultFolders(listing: { vault: IListingVault }): IListingVaultFolder[] {
  return listing.vault.folders;
}

/**
 * Returns specific folder in the vault
 */
function vaultFolder(listing: { vault: IListingVault }, folderName: ListingVaultFolderNamesEnum): IListingVaultFolder | null {
  return listing.vault.folders.find((folder) => folder.name === folderName) ?? null;
}

function rootFolderFileCount(folder: IListingVaultFolder): number {
  return folder.fileIds.length + (folder?.subFolders ? folder.subFolders.reduce((cur, next) => cur + next.files.length, 0) : 0);
}

function hasUserSignedCA(listing: IListing, userId: string): boolean {
  return listing.metrics.signedCAUserIds.includes(userId);
}

function isScheduled(listing: IListing): boolean {
  const publishAt = (listing?.schedule?.publishAt ?? 0);
  return publishAt > 0 && publishAt > TimeUtil.now();
}

function isNotPublished(listing: IListing): boolean {
  return (listing?.state !== ListingStateEnum.Active
    && listing?.state !== ListingStateEnum.Closed
    && listing?.state !== ListingStateEnum.UnderContract
    && listing?.state !== ListingStateEnum.Private);
}

function name(listing: IListing | IListingGraphQL | IListingParams, skipField?: TSkipFormatAddressFields, showPrivateListingText?: boolean): string | null {
  if (isValidPortfolioListing(listing as IListing) && !listing.name) {
    return showPrivateListingText ? 'Private listing' : 'Portfolio listing';
  }

  return listing
    ? (listing.name || (showPrivateListingText
      ? 'Private listing'
      : AddressUtil.formatAddress(listing.address, skipField)) || null)
    : null;
}

function primaryImageUrl(listing: IListingGraphQL): string | null {
  return listing?.media?.files?.[0]?.url || null;
}

/** Determine valid stasuses we can update to depending on the
 * current status of our listing. These will populate our dropdown
 * on the ChangeListingStatus modal.
 */
function getListingStatuses(listing: IListingGraphQL): TListingStatuses<ListingStateEnum> {
  const potentialStatuses = [
    {
      name: 'Draft (Not accessible)',
      value: ListingStateEnum.Draft,
    },
    {
      name: ListingStateEnum.Active,
      value: ListingStateEnum.Active,
    },
    {
      name: ListingStateEnum.UnderContract,
      value: ListingStateEnum.UnderContract,
    },
    {
      name: ListingStateEnum.Closed,
      value: ListingStateEnum.Closed,
    },
    {
      name: 'Inactive (Not accessible)',
      value: ListingStateEnum.Inactive,
    },
    {
      name: ListingStateEnum.Scheduled,
      value: ListingStateEnum.Scheduled,
    },
    {
      name: ListingStateEnum.Private,
      value: ListingStateEnum.Private,
    },
  ];

  let listingStatuses: TListingStatuses<ListingStateEnum> = [];

  switch (listing.state) {
    case ListingStateEnum.Active:
      listingStatuses = potentialStatuses.filter(({ value }) => value !== ListingStateEnum.Scheduled && value !== ListingStateEnum.Draft);
      break;
    case ListingStateEnum.Draft:
      listingStatuses = potentialStatuses.filter(({ value }) => value !== ListingStateEnum.Draft
        && (value === ListingStateEnum.Active || value === ListingStateEnum.Scheduled));
      break;
    case ListingStateEnum.UnderContract:
      listingStatuses = potentialStatuses.filter(({ value }) => value !== ListingStateEnum.UnderContract
        && (value === ListingStateEnum.Closed || value === ListingStateEnum.Inactive || value === ListingStateEnum.Active));
      break;
    case ListingStateEnum.Closed:
      listingStatuses = potentialStatuses.filter(({ value }) => value !== ListingStateEnum.Closed
       && value === ListingStateEnum.Inactive);
      break;
    case ListingStateEnum.Inactive:
      listingStatuses = potentialStatuses.filter(({ value }) => value !== ListingStateEnum.Inactive
        && (value === ListingStateEnum.Active));
      break;

    case ListingStateEnum.Scheduled:
      listingStatuses = potentialStatuses.filter(({ value }) => value !== ListingStateEnum.Closed
         && (value !== ListingStateEnum.UnderContract));
      break;
    case ListingStateEnum.Expired:
      listingStatuses = potentialStatuses.filter(({ value }) => value === ListingStateEnum.Active
        || value === ListingStateEnum.Inactive);
      break;
      /** Include these cases until the enums below are fully deprecated */
    case ListingStateEnum.Archived:
      listingStatuses = potentialStatuses.filter(({ value }) => value !== ListingStateEnum.Inactive
          && (value === ListingStateEnum.Active || value === ListingStateEnum.Draft));
      break;
    case ListingStateEnum.Unlisted:
      listingStatuses = potentialStatuses.filter(({ value }) => value !== ListingStateEnum.Inactive
          && (value === ListingStateEnum.Active || value === ListingStateEnum.Draft));
      break;
    case ListingStateEnum.Private:
      listingStatuses = potentialStatuses.filter(({ value }) => value === ListingStateEnum.Private
          || value === ListingStateEnum.Closed || value === ListingStateEnum.Inactive || value === ListingStateEnum.UnderContract);
      break;
    default:
      listingStatuses = [];
  }

  return listingStatuses;
}

/** Determine the expiration date for our listing when we change listing status.
 * Appropriate when we change from any status to Active, but also if we
 * simply want to set a new expiration date on our already active listing.
 * Returns null if we are changing from Active to any other status.
 */

function setExpirationDate(newStatus: ListingStateEnum, date: number, scheduledExpiresAt: number): number | null {
  let expiresAt: number | null = date;
  if (newStatus !== ListingStateEnum.Active && newStatus !== ListingStateEnum.Scheduled) expiresAt = null;
  if (newStatus === ListingStateEnum.Scheduled) {
    expiresAt = scheduledExpiresAt;
  }
  return expiresAt;
}

/** Determine the publish date for our listing when we change listing status.
 * Appropriate when we change from Draft to Scheduled, but also if we
 * simply want to set a new publish date on our already scheduled listing.
 * Returns null if we are changing from Scheduled to any other status.
 */

function setPublishDate(newStatus: ListingStateEnum, date: number): number | null {
  let publishAt: number | null = date;
  if (newStatus !== ListingStateEnum.Scheduled) publishAt = null;
  return publishAt;
}

/**
 * Returns a boolean indicating if the supplied user
 * is the owner of the listing
 * @param listing - the listing
 * @param userId - the ID of the user whose ownership is in question
 * @returns - boolean indicating ownership or not
 */
function isOwner(listing: IListing, userId: string): boolean {
  return userId === listing?.meta?.createdBy ?? false;
}

function containsLevel2(listing: IListingGraphQL): boolean {
  return Boolean(vaultFiles(listing).find((file: IFile) => file?.accessLevel === FileAccessLevelsEnum.Level2));
}

function checkRequiredFields(listing: IListing | IListingGraphQL): any {
  let incompleteRequiredFields = false;
  const incompleteFields: string[] = [];
  if (!listing?.address?.address1) {
    incompleteFields.push(ListingRequiredFieldsEnum.Address);
  } if (listing?.isPortfolioListing && ((listing?.portfolioAddresses?.length ?? 0) < 2)) {
    incompleteFields.push(ListingRequiredFieldsEnum.PortfolioAddresses);
  } if (!listing?.media?.fileIds?.length) {
    incompleteFields.push(ListingRequiredFieldsEnum.Image);
  } if (!listing?.assetClass) {
    incompleteFields.push(ListingRequiredFieldsEnum.PropertyDetails);
  } if (!listing?.propertyType) {
    incompleteFields.push(ListingRequiredFieldsEnum.PropertyDetails);
  } if (!listing?.description) {
    incompleteFields.push(ListingRequiredFieldsEnum.Description);
  } if (!listing?.license?.owner && !listing?.listedByOwner) {
    incompleteFields.push(ListingRequiredFieldsEnum.License);
  }

  /** Check if any unnamed vault sub-folders exist */
  const unnamedSubFoldersExist = Boolean(listing?.vault?.folders?.find((folder) => folder.subFolders.find((subFolder) => subFolder.name.includes('Untitled'))));
  if (unnamedSubFoldersExist) {
    incompleteFields.push(ListingRequiredFieldsEnum.DueDiligence);
  }

  /** if any deal profiles fields are filled out, they all must be */
  if (dealProfileCriteriaExist(listing)) {
    if (!listing?.dealProfile?.buyerLocation || (listing.dealProfile.buyerLocation === DealProfileBuyerLocationEnum.RegionalInvestor
      && !listing?.dealProfile?.regionalPreference)
      || !(listing?.dealProfile?.investmentType?.length ? listing?.dealProfile?.investmentType?.length > 0 : null)) {
      incompleteFields.push(ListingRequiredFieldsEnum.DealProfile);
    }
  }

  if (incompleteFields.length) incompleteRequiredFields = true;
  return { incompleteFields, incompleteRequiredFields };
}

/** format list of user contacts: add new contacts, edit existing contacts, include existing contacts, and remove default contact */
function updatePersistentContactList(newContacts: IListingContact[], existingContacts: IListingContact[], userId: string): IListingContact[] {
  const newOrUpdatedContacts = newContacts.reduce((cur, next) => {
    /** remove default contact */
    if (next._id === userId) return cur;

    const existingContact = existingContacts.find((contact) => contact._id === next._id);

    /** if contact already exists but has changes, update contact. Otherwise add contact as is */
    if (existingContact) {
      const contactUpdated = Boolean(!isDeepEqual(next, existingContact));
      if (contactUpdated) cur.push(next);
      else cur.push(existingContact);
    } else {
      /** If new contact, add to list */
      cur.push(next);
      return cur;
    }
    return cur;
  }, [] as IListingContact[]);

  /** add all existing contacts in the persistent contact list that werent new or otherwise edited */
  const remainingContacts = existingContacts.filter((contact) => !newOrUpdatedContacts.find((newContact) => newContact._id === contact._id));

  const finalContactList = [...newOrUpdatedContacts, ...remainingContacts];

  return finalContactList;
}

function dealProfileCriteriaExist(listing: IListing | IListingGraphQL): boolean {
  return Boolean(listing?.dealProfile);
}

/** return an array of all 50 states with full name */
function returnAllStates() {
  let states: AmericanStatesExpandedEnum[] = [];

  Object.values(AmericanStatesDictionaryUtil.dictionary).forEach((stateArray) => {
    states = [...states, ...stateArray];
  });

  return states;
}

/**
 * Return the sqft of the listing if it exists.
 */
function getListingSquareFootage(listing: IListing | IListingGraphQL): string | null {
  return listing?.info?.find(({ fieldName }) => fieldName === ListingInfoFieldNamesEnum.BuildingSqFt)?.value as string ?? null;
}

/**
 * Used to determine how to display certain things such as the address to a user.
 */
function isValidPortfolioListing(listing: IListing | IListingGraphQL): boolean {
  return !!(listing?.isPortfolioListing && ((listing?.portfolioAddresses?.length ?? 0) > 1));
}

/**
 * Get the listing fields that correlate to listing Aura field
 */
function auraListingFields(): string[] {
  return Object.values(AuraListingFieldsEnum);
}

const ListingUtil = {
  field,
  getField,
  listingInfoFieldState,
  listingInfoFieldsAutocompleteState,
  folder,
  slug,
  // url,
  // loginUrl,
  createListingPath,
  // createListingPreviewUrl,
  // listingsPageUrl,
  listingParams,
  vaultFiles,
  vaultFileIds,
  vaultFolders,
  vaultFolder,
  rootFolderFileCount,
  hasUserSignedCA,
  isScheduled,
  isNotPublished,
  name,
  primaryImageUrl,
  getListingStatuses,
  setExpirationDate,
  setPublishDate,
  isOwner,
  containsLevel2,
  checkRequiredFields,
  updatePersistentContactList,
  dealProfileCriteriaExist,
  returnAllStates,
  getListingSquareFootage,
  slugifyAddressForPDPUrl,
  isValidPortfolioListing,
  auraListingFields,
};

export default ListingUtil;
