import Joi from 'joi';

export const authenticatedDataSchema = Joi.object().keys({
  userId: Joi.string().required(),
  email: Joi.string().required(),
  token: Joi.string().required(),
  organizationId: Joi.string().optional().allow(null), // this field is always allowed but not required
  isAdmin: Joi.boolean().optional().allow(null),
  ipAddress: Joi.string().optional().allow(null), // this field is always allowed but not required
});

export const authenticatedOptionalDataSchema = Joi.object().keys({
  userId: Joi.string().optional().allow(null),
  email: Joi.string().optional().allow(null),
  token: Joi.string().optional().allow(null),
  organizationId: Joi.string().optional().allow(null), // this field is always allowed but not required
  isAdmin: Joi.boolean().optional().allow(null),
  ipAddress: Joi.string().optional().allow(null), // this field is always allowed but not required
});

export const authorizedDataSchema = Joi.object().keys({
  userId: Joi.string().required(),
  email: Joi.string().required(),
  token: Joi.string().required(),
  organizationId: Joi.string().required(),
  isAdmin: Joi.boolean().optional().allow(null),
  ipAddress: Joi.string().optional().allow(null), // this field is always allowed but not required
});

export const authorizedOptionalDataSchema = Joi.object().keys({
  userId: Joi.string().optional().allow(null),
  email: Joi.string().optional().allow(null),
  token: Joi.string().optional().allow(null),
  organizationId: Joi.string().optional().allow(null),
  isAdmin: Joi.boolean().optional().allow(null),
  ipAddress: Joi.string().optional().allow(null), // this field is always allowed but not required
});
