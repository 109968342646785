/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {
  Page, Text, View, Document, StyleSheet, Image, Font,
} from '@react-pdf/renderer';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import { IOfferGraphQL } from '@biproxi/models/interfaces/IOffer';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import UserUtil from '@biproxi/models/utils/UserUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import OfferUtil from '@biproxi/models/utils/OfferUtil';
import IListingOverviewAnalytics from '@biproxi/models/interfaces/IListingOverviewAnalytics';
import Colors from '../../styles/Colors';

// can't get the fonts to work how I want
// const aktivGrotesk = 'https://use.typekit.net/ivz8awd.css';
// got this open type font by 'curling' the above url and copying the opentype urls given
// Font weights don't seem to work well.
const aktivGrotesk700 = 'https://use.typekit.net/af/a798a9/000000000000000077359d55/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3';
Font.register({
  family: 'aktiv-grotesk700',
  format: 'opentype',
  src: aktivGrotesk700,
});

const aktivGrotesk400 = 'https://use.typekit.net/af/ab3e12/000000000000000077359d4f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3';
Font.register({
  family: 'aktiv-grotesk400',
  format: 'opentype',
  src: aktivGrotesk400,
});

const aktivGrotesk500 = 'https://use.typekit.net/af/c11a71/000000000000000077359d5e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3';
Font.register({
  family: 'aktiv-grotesk500',
  format: 'opentype',
  src: aktivGrotesk500,
});

// Create styles
const styles = StyleSheet.create({
  // GENERAL
  page: {
    backgroundColor: Colors.White,
    padding: 24,
  },
  logoBox: {
    marginBottom: 24,
  },
  logo: {
    height: 'auto',
    width: 120,
  },

  // LISTING PREVIEW
  listingPreviewContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  listingImage: {
    height: 'auto',
    width: 193,
    maxHeight: 200,
    borderRadius: 4,
    marginRight: 12,
  },
  listingName: {
    fontFamily: 'aktiv-grotesk700',
    color: Colors.Grey900,
    fontWeight: 700,
    fontSize: 16,
    textOverflow: 'ellipsis',
    marginBottom: 5,
  },
  listingAddress: {
    fontFamily: 'aktiv-grotesk500',
    color: Colors.Grey900,
    fontWeight: 500,
    fontSize: 12,
    textOverflow: 'ellipsis',
    marginBottom: 5,
  },
  listingStatus: {
    fontFamily: 'aktiv-grotesk400',
    color: Colors.Grey900,
    fontWeight: 400,
    fontSize: 12,
    textOverflow: 'ellipsis',
  },
  listingNoImage: {
    backgroundColor: Colors.Grey200,
    width: 193,
    height: 127,
    borderRadius: 4,
    marginRight: 12,
  },

  // QUICK STATS
  statsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 24,
  },
  statBox: {
    backgroundColor: Colors.Grey100,
    borderRadius: 4,
    height: 40,
    display: 'flex',
    flexGrow: 1,
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  statTitle: {
    fontFamily: 'aktiv-grotesk700',
    color: Colors.Grey500,
    fontSize: 10,
    textOverflow: 'ellipsis',
  },
  statValue: {
    fontFamily: 'aktiv-grotesk400',
    fontWeight: 700,
    color: Colors.Grey500,
    fontSize: 14,
    textOverflow: 'ellipsis',
  },

  // USER PROFILE
  userProfileContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  userProfileImage: {
    borderRadius: 4,
    width: 48,
    height: 48,
    marginRight: 8,
  },
  userProfileName: {
    fontFamily: 'aktiv-grotesk500',
    fontWeight: 500,
    color: Colors.Brand700 || Colors.Blurple700,
    fontSize: 14,
    textOverflow: 'ellipsis',
  },
  userProfileContactInfo: {
    fontFamily: 'aktiv-grotesk400',
    fontWeight: 400,
    color: Colors.Grey900,
    fontSize: 12,
    textOverflow: 'ellipsis',
  },
  userInitialsContainer: {
    borderRadius: 4,
    backgroundColor: Colors.Grey900,
    width: 48,
    height: 48,
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userInitialsText: {
    fontFamily: 'aktiv-grotesk400',
    fontWeight: 400,
    color: Colors.White,
    fontSize: 16,
    textOverflow: 'ellipsis',
  },

  // LEADS
  leadTableHeader: {
    backgroundColor: Colors.Grey100,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
  },
  leadTableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  leadTableItem: {
    padding: 8,
  },
  leadTableHeaderText: {
    fontFamily: 'aktiv-grotesk400',
    fontWeight: 400,
    color: Colors.Grey900,
    fontSize: 10,
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
  },
  leadTableRowText: {
    fontFamily: 'aktiv-grotesk500',
    fontWeight: 500,
    color: Colors.Brand700 || Colors.Blurple700,
    fontSize: 12,
    textOverflow: 'ellipsis',
  },
  leadTableNotesText: {
    fontFamily: 'aktiv-grotesk400',
    fontWeight: 400,
    color: Colors.Grey600,
    fontSize: 12,
    textOverflow: 'ellipsis',
  },

  // OFFERS
  offerContainer: {
    borderRadius: 4,
    border: `1px solid ${Colors.Grey200}`,
    marginBottom: 8,
    padding: 16,
  },
  offerInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  offerInfoItem: {
    paddingHorizontal: 12,
  },
  offerInfoItemHeaderText: {
    fontFamily: 'aktiv-grotesk400',
    fontWeight: 400,
    color: Colors.Grey500,
    fontSize: 12,
    textOverflow: 'ellipsis',
  },
  offerInfoItemValueText: {
    fontFamily: 'aktiv-grotesk500',
    fontWeight: 500,
    color: Colors.Black,
    fontSize: 12,
    textOverflow: 'ellipsis',
  },
});

export type ListingReportProps = {
  listing: IListingGraphQL;
  offers: IOfferGraphQL[];
  leads: ILeadGraphQL[];
  investorMatches: ILeadGraphQL[];
  analytics: IListingOverviewAnalytics;
  config: any; // type this
};

const ListingReport: React.FC<ListingReportProps> = ({
  listing,
  offers,
  leads,
  investorMatches,
  analytics,
  config,
}) => {
  const ListingHeader: React.FC = () => (
    <View style={styles.listingPreviewContainer}>
      {listing?.media?.files?.[0]?.url ? (
        <Image
          style={styles.listingImage}
          src={listing?.media?.files?.[0]?.url}
        />
      ) : (
        <View style={styles.listingNoImage} />
      )}
      <View>
        <Text style={styles.listingName}>
          {ListingUtil.name(listing, { allButAddress1: true })}
        </Text>
        <Text style={styles.listingAddress}>
          {/* Lol we need to clean up this naming stuff */}
          {ListingUtil.isValidPortfolioListing(listing) ? 'Multiple addresses' : AddressUtil.formatAddress(listing?.address, { address1: !listing.name || listing?.isPrivateListing, address2: listing?.isPrivateListing }) || null}
        </Text>
        <Text style={styles.listingStatus}>
          Listing status:&nbsp;
          {' '}
          {listing.state}
        </Text>
      </View>
    </View>
  );

  const ListingStats: React.FC = () => (
    <View style={styles.statsContainer}>
      <View style={styles.statBox}>
        <Text style={styles.statTitle}>
          Listing views
        </Text>
        <Text style={styles.statValue}>
          {analytics?.totalListingViews || '--'}
        </Text>
      </View>
      <View style={{ width: 8 }} />
      <View style={styles.statBox}>
        <Text style={styles.statTitle}>
          Unique Visitors
        </Text>
        <Text style={styles.statValue}>
          {analytics?.uniqueListingViews || '--'}
        </Text>
      </View>
      <View style={{ width: 8 }} />
      <View style={styles.statBox}>
        <Text style={styles.statTitle}>
          Leads
        </Text>
        <Text style={styles.statValue}>
          {analytics?.totalListingLeads || '--'}
        </Text>
      </View>
      <View style={{ width: 8 }} />
      <View style={styles.statBox}>
        <Text style={styles.statTitle}>
          CA&apos;s signed
        </Text>
        <Text style={styles.statValue}>
          {analytics?.totalCAsSigned || '--'}
        </Text>
      </View>
    </View>
  );

  const UserProfile: React.FC<{ user: IUserGraphQL }> = ({ user }) => (
    <View style={styles.userProfileContainer}>
      {user?.profileImageFile?.url ? (
        <Image
          style={styles.userProfileImage}
          src={user?.profileImageFile?.url}
        />
      ) : (
        <View style={styles.userInitialsContainer}>
          <Text style={styles.userInitialsText}>
            {UserUtil.initials(user)}
          </Text>
        </View>
      )}
      <View>
        <Text style={styles.userProfileName}>
          {UserUtil.fullName(user)}
        </Text>
        <Text style={styles.userProfileContactInfo}>
          {user?.email}
        </Text>
        <Text style={styles.userProfileContactInfo}>
          {StringUtil.formatPhoneNumber(user?.phoneNumber)}
        </Text>
      </View>
    </View>
  );

  const ListingOffers: React.FC = () => (
    <View style={{ marginBottom: 24 }}>
      <Text style={styles.listingName}>
        Offers
      </Text>
      {offers?.map((offer) => (
        <View style={styles.offerContainer} key={offer?._id}>
          <UserProfile user={offer?.user} />
          <View style={styles.offerInfo}>
            <View style={[styles.offerInfoItem, { borderRight: `1px solid ${Colors.Grey200}`, paddingLeft: 0 }]}>
              <Text style={styles.offerInfoItemHeaderText}>
                {OfferUtil.getOfferStateText(offer)?.text}
              </Text>
              <Text style={styles.offerInfoItemValueText}>
                {`$${StringUtil.formatNumber(offer.purchasePrice)}`}
              </Text>
            </View>
            <View style={[styles.offerInfoItem, { borderRight: `1px solid ${Colors.Grey200}` }]}>
              <Text style={styles.offerInfoItemHeaderText}>
                Diligence period
              </Text>
              <Text style={styles.offerInfoItemValueText}>
                {`${offer.dueDiligencePeriod} days`}
              </Text>
            </View>
            <View style={[styles.offerInfoItem, { borderRight: `1px solid ${Colors.Grey200}` }]}>
              <Text style={styles.offerInfoItemHeaderText}>
                Closing period
              </Text>
              <Text style={styles.offerInfoItemValueText}>
                {`${offer.closingPeriod} days`}
              </Text>
            </View>
            <View style={[styles.offerInfoItem, { borderRight: `1px solid ${Colors.Grey200}` }]}>
              <Text style={styles.offerInfoItemHeaderText}>
                Deposit
              </Text>
              <Text style={styles.offerInfoItemValueText}>
                {`$${StringUtil.formatNumber(offer.depositAmount || 0)}`}
              </Text>
            </View>
            <View style={[styles.offerInfoItem, { borderRight: `1px solid ${Colors.Grey200}` }]}>
              <Text style={styles.offerInfoItemHeaderText}>
                Financing
              </Text>
              <Text style={styles.offerInfoItemValueText}>
                {offer.financingPeriod ? `${offer.financingPeriod} days` : 'None'}
              </Text>
            </View>
            <View style={styles.offerInfoItem}>
              <Text style={styles.offerInfoItemHeaderText}>
                Exclusivity
              </Text>
              <Text style={styles.offerInfoItemValueText}>
                {offer.exclusivityPeriod ? `${offer.exclusivityPeriod} days` : 'None'}
              </Text>
            </View>
          </View>
        </View>
      ))}
    </View>
  );

  const ListingLeads: React.FC = () => (
    <View>
      <Text style={styles.listingName}>
        Leads
      </Text>
      {leads?.map((lead) => (
        <View key={lead?._id}>
          <UserProfile user={lead?.user} />
          {/* Header */}
          <View style={styles.leadTableHeader}>
            <View style={[styles.leadTableItem, { width: 90 }]}>
              <Text style={styles.leadTableHeaderText}>
                VIEWS
              </Text>
            </View>
            <View style={[styles.leadTableItem, { width: 90 }]}>
              <Text style={styles.leadTableHeaderText}>
                CA
              </Text>
            </View>
            <View style={[styles.leadTableItem, { width: 90 }]}>
              <Text style={styles.leadTableHeaderText}>
                GUIDANCE
              </Text>
            </View>
            <View style={[styles.leadTableItem, { width: 90 }]}>
              <Text style={styles.leadTableHeaderText}>
                OFFER
              </Text>
            </View>
            <View style={[styles.leadTableItem, { width: 160 }]}>
              <Text style={styles.leadTableHeaderText}>
                STATUS
              </Text>
            </View>
          </View>
          {/* Row */}
          <View style={styles.leadTableRow}>
            <View style={[styles.leadTableItem, { width: 90 }]}>
              <Text style={styles.leadTableRowText}>
                {lead?.metrics?.pageViews}
              </Text>
            </View>
            <View style={[styles.leadTableItem, { width: 90 }]}>
              <Text style={styles.leadTableRowText}>
                {lead?.metrics?.signedCA ? 'Yes' : 'No'}
              </Text>
            </View>
            <View style={[styles.leadTableItem, { width: 90 }]}>
              <Text style={styles.leadTableRowText}>
                {`$${StringUtil.formatNumber(lead?.guidance || 0)}`}
              </Text>
            </View>
            <View style={[styles.leadTableItem, { width: 90 }]}>
              <Text style={styles.leadTableRowText}>
                {`$${StringUtil.formatNumber(lead?.offerAmount || 0)}`}
              </Text>
            </View>
            <View style={[styles.leadTableItem, { width: 160 }]}>
              <Text style={styles.leadTableRowText}>
                {lead.state}
              </Text>
            </View>
          </View>
          {/* Header */}
          <View style={styles.leadTableHeader}>
            <View style={[styles.leadTableItem, { width: 90 }]}>
              <Text style={styles.leadTableHeaderText}>
                SELLER NOTES
              </Text>
            </View>
          </View>
          {/* Row */}
          <View style={[styles.leadTableRow, { marginBottom: 24 }]}>
            <View style={[styles.leadTableItem]}>
              {lead?.sellerNotes?.length > 0 ? (
                <>
                  {lead?.sellerNotes?.map((note, index) => (
                    <View key={note._id} style={{ marginBottom: index === lead?.sellerNotes?.length - 1 ? 0 : 24 }}>
                      <Text style={styles.leadTableRowText}>
                        {StringUtil.capitalize(
                          TimeUtil.format(
                            note?.meta?.createdAt,
                            'LLLL d \'at\' h:mma',
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          ).toLowerCase(),
                        )}
                      </Text>
                      <Text style={styles.leadTableNotesText}>
                        {note?.text}
                      </Text>
                    </View>
                  ))}
                </>
              ) : (
                <Text style={styles.leadTableNotesText}>
                  None
                </Text>
              )}
            </View>
          </View>
        </View>
      ))}
    </View>
  );

  const ListingInvestorMatches: React.FC = () => (
    <View>
      <Text style={styles.listingName}>
        Investor matches
      </Text>
      {investorMatches?.map((investorMatch) => (
        <View key={investorMatch?._id}>
          <UserProfile user={investorMatch?.user} />
          {/* Header */}
          <View style={styles.leadTableHeader}>
            <View style={[styles.leadTableItem, { width: 90 }]}>
              <Text style={styles.leadTableHeaderText}>
                VIEWS
              </Text>
            </View>
          </View>
          {/* Row */}
          <View style={styles.leadTableRow}>
            <View style={[styles.leadTableItem, { width: 90 }]}>
              <Text style={styles.leadTableRowText}>
                {investorMatch?.metrics?.pageViews}
              </Text>
            </View>
          </View>
          {/* Header */}
          <View style={styles.leadTableHeader}>
            <View style={[styles.leadTableItem, { width: 90 }]}>
              <Text style={styles.leadTableHeaderText}>
                SELLER NOTES
              </Text>
            </View>
          </View>
          {/* Row */}
          <View style={[styles.leadTableRow, { marginBottom: 24 }]}>
            <View style={[styles.leadTableItem]}>
              {investorMatch?.sellerNotes?.length > 0 ? (
                <>
                  {investorMatch?.sellerNotes?.map((note, index) => (
                    <View key={note._id} style={{ marginBottom: index === investorMatch?.sellerNotes?.length - 1 ? 0 : 24 }}>
                      <Text style={styles.leadTableRowText}>
                        {StringUtil.capitalize(
                          TimeUtil.format(
                            note?.meta?.createdAt,
                            'LLLL d \'at\' h:mma',
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          ).toLowerCase(),
                        )}
                      </Text>
                      <Text style={styles.leadTableNotesText}>
                        {note?.text}
                      </Text>
                    </View>
                  ))}
                </>
              ) : (
                <Text style={styles.leadTableNotesText}>
                  None
                </Text>
              )}
            </View>
          </View>
        </View>
      ))}
    </View>
  );

  /** Render */
  return (
  // <PDFViewer style={{ width: '100vw', height: '100vh' }} showToolbar> // used to render in dom for dev
    <Document
      title="Biproxi-listing-report"
    >
      <Page
        size="LETTER"
        style={styles.page}
      >
        <View style={styles.logoBox}>
          <Image
            style={styles.logo}
            src="/biproxi-logo-higher-quality-black.png"
          />
        </View>
        <ListingHeader />
        <ListingStats />
        {/* Ask about no content screens */}
        {config?.showOffers && offers?.length > 0 && <ListingOffers />}
        {config?.showLeads && leads?.length > 0 && <ListingLeads />}
        {config?.showInvestorMatches && investorMatches?.length > 0 && <ListingInvestorMatches />}
      </Page>
    </Document>
  // </PDFViewer>
  );
};

export default ListingReport;
