import Cookie from 'js-cookie';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import { TOAuthAuthenticateUserResponse } from '@biproxi/models/services/IUserService';
import * as OAuthUtil from '@biproxi/models/utils/OAuthUtil';
import AuthenticationStrategyEnum from '@biproxi/models/enums/AuthenticationStrategyEnum';

/** ******************************************************************************
 *  JWT Management
 ****************************************************************************** */

const BIPROXI_AUTH_TOKEN = 'biproxi-token';
const HUBSPOT_CONVERSATIONS_TOKEN = 'biproxi-hubspot-token';

function setToken(token: string, hubspotToken: string): void {
  Cookie.set(BIPROXI_AUTH_TOKEN, token);
  Cookie.set(HUBSPOT_CONVERSATIONS_TOKEN, hubspotToken);
}

function getToken(cookies?: NextApiRequestCookies): string | null {
  if (cookies) {
    return cookies[BIPROXI_AUTH_TOKEN] ?? null;
  }

  return Cookie.get(BIPROXI_AUTH_TOKEN) ?? null;
}

function getHubSpotToken(cookies?: NextApiRequestCookies): string | null {
  if (cookies) {
    return cookies[BIPROXI_AUTH_TOKEN] ?? null;
  }

  return Cookie.get(BIPROXI_AUTH_TOKEN) ?? null;
}

function clearToken(): void {
  Cookie.remove(BIPROXI_AUTH_TOKEN);
  Cookie.remove(HUBSPOT_CONVERSATIONS_TOKEN);
}

function isAuthenticated(): boolean {
  // In the future we should also check
  // whether the token present is a valid and unexpired token
  return Boolean(getToken());
}

function logout() {
  clearToken();
  window.location.href = '/app/login';
}

/** ******************************************************************************
 *  OAuth
 ****************************************************************************** */

async function startOAuthFlow(strategy: AuthenticationStrategyEnum): Promise<TOAuthAuthenticateUserResponse> {
  const url = OAuthUtil.authenticationStrategyOAuthPath(strategy);
  return OAuthUtil.openOAuthWindow(url, OAuthUtil.FRAME_DATA_SOURCE);
}

function cancelOAuthFlow(): boolean {
  return OAuthUtil.closeOAuthWindow();
}

const Auth = {
  setToken,
  getToken,
  getHubSpotToken,
  isAuthenticated,
  logout,
  startOAuthFlow,
  cancelOAuthFlow,
};

export default Auth;
