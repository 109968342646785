import React from 'react';
import IFileParams from '@biproxi/models/interfaces/IFileParams';
import IFile from '@biproxi/models/interfaces/IFile';
import FileUtil from '@biproxi/models/utils/FileUtil';
import Text, { TextTypesEnum } from '../elements/Text';
import Flex from '../elements/Flex';
import Colors from '../styles/Colors';
import Icon, { Icons } from '../elements/Icon';
import Loader, { LoaderSizes } from '../elements/Loader';

type FileUploadBarStatusProps = {
    percent: Number;
    file: IFile | IFileParams;
}

const FileUploadBarStatus: React.FC<FileUploadBarStatusProps> = ({
  percent,
  file,
}) => {
  /* State */
  const isFile = FileUtil.isFile(file);

  return (
    <Flex align="center" justify="space-between" flex="1">
      <Flex>
        <Icon icon={Icons.FileCertificateLight} color={isFile ? Colors.Black : Colors.Grey500} size={18} margin="0 8px 0 0" />
        <Text type={TextTypesEnum.Regular16} color={isFile ? Colors.Black : Colors.Grey500}>
          {file?.name || 'Uploading...'}
        </Text>
      </Flex>
      {!isFile ? (
        <Flex>
          <Text type={TextTypesEnum.Regular14} color={Colors.Grey500} margin="0 8px 0 0">
            Uploading&nbsp;
            (
            {percent}
            %)
          </Text>
          <Loader size={LoaderSizes.ExtraSmall} color={Colors.Brand700 || Colors.Blurple700} />
        </Flex>
      ) : null}
    </Flex>
  );
};

export default FileUploadBarStatus;
