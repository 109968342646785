import React from 'react';
import styled from '@emotion/styled';
import IUnmaskedOwnerContactInfo from '@biproxi/models/interfaces/IUnmaskedOwnerContactInfo';
import StringUtil from '@biproxi/models/utils/StringUtil';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import Flex from '../../elements/Flex';
import Text, { TextTypesEnum } from '../../elements/Text';
import Icon, { Icons } from '../../elements/Icon';
import Colors from '../../styles/Colors';
import Divider from '../../elements/Divider';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';

const ContactInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export type PropertyOwnerContactInfoModalProps = {
  ownerName: string;
  contactInfo: IUnmaskedOwnerContactInfo;
}

const PropertyOwnerContactInfoModal: React.FC<PropertyOwnerContactInfoModalProps> = ({
  ownerName,
  contactInfo,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => {
    dispatch(AppActions.popModal());
  };

  return (
    <ModalContainer width="375px">
      <ModalHeader title="Contact details" close={popModal} />
      <ModalContent>
        <Flex direction="column">
          <Flex direction="column" margin="0 0 16px 0">
            <Text type={TextTypesEnum.Bold18}>Unmasked owner</Text>
            <Text>{ownerName}</Text>
          </Flex>
          <Flex margin="0 0 16px 0" align="flex-start">
            <Icon
              borderRadius="100px"
              background={Colors.Grey200}
              icon={Icons.PhoneRegular}
              color={Colors.Black}
              size="16px"
              width="32px"
              height="32px"
              margin="0 10px 0 0"
            />
            <ContactInfoWrapper>
              <Text>Phone number(s)</Text>
              {contactInfo?.phoneNumbers?.map((phoneNumber: string, index: number) => (
                <Text key={index} type={TextTypesEnum.Regular14}>{StringUtil.formatPhoneNumber(phoneNumber)}</Text>
              ))}
            </ContactInfoWrapper>
          </Flex>
          <Flex align="flex-start">
            <Icon
              borderRadius="100px"
              background={Colors.Grey200}
              icon={Icons.EnvelopeRegular}
              color={Colors.Black}
              size="16px"
              width="32px"
              height="32px"
              margin="0 10px 0 0"
            />
            <ContactInfoWrapper>
              <Text>Email(s)</Text>
              {contactInfo?.emails?.map((email: string, index: number) => (
                <Text key={index} type={TextTypesEnum.Regular14}>{email}</Text>
              ))}
            </ContactInfoWrapper>
          </Flex>
          <Divider margin="24px 0" />
          <Flex justify="flex-end">
            <Button
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Small}
              text="Close"
              onClick={(event) => {
                event.stopPropagation();
                popModal();
              }}
            />
          </Flex>
        </Flex>
      </ModalContent>
    </ModalContainer>
  );
};

export default PropertyOwnerContactInfoModal;
