import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { ISavedSearchGraphQL } from '@biproxi/models/interfaces/ISavedSearch';
import { useNavigate } from 'react-router-dom';
import Link from '../elements/Link';
import Text, { TextTypesEnum } from '../elements/Text';
import Colors from '../styles/Colors';
import SearchListingsFilters from './SearchListingsFilters';
import Icons from '../elements/Icons';
import Icon from '../elements/Icon';
import Flex from '../elements/Flex';
import {
  media, useDesktopMedia, useMobileMedia, useTabletMedia,
} from '../utils/MediaQuery';
import SquareIconButton from '../elements/SquareIconButton';
import { AppActions } from '../redux/app.redux';
import { useAppDispatch, useAppSelector } from '../redux/store';
import ListingCard from './ListingCard.default';
import ListingCardTypesEnum from '../models/enums/ListingCardTypesEnum';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import { ModalTypesEnum } from './modal/Modal';
import { ListingActions, ListingSelectors } from '../redux/listing.redux';
import { SaveSearchModalTypeEnum } from './modal/SaveSearchModal';
import Tag from '../elements/Tag';
import Divider from '../elements/Divider';
import Pagination from '../elements/Pagination';
import PaginationLimitsEnum from '../models/enums/PaginationLimitsEnum';
import { SRPLayoutEnum } from '../spa-pages/Search.page';
import * as UrlUtil from '../utils/UrlUtil';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
`;

type ListingsProps = {
  srpLayoutState: SRPLayoutEnum;
}

const Listings = styled.div<ListingsProps>`
  width: calc(100% - 64px);
  margin: 32px;
  gap: 32px;

  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 16px));

  ${media.mobile} {
    width: calc(100vw - 32px);
    grid-template-columns: 100%;
    margin: 16px 16px 0;
  }

  ${media.tablet} {
    width: calc(100% - 32px);
    margin: 16px 16px 0;
    grid-template-columns: ${({ srpLayoutState }) => {
    switch (srpLayoutState) {
      case SRPLayoutEnum.Half:
        return 'repeat(2, calc(50% - 16px))';
      case SRPLayoutEnum.Listings:
      default:
        return 'repeat(3, calc(33.33% - 20px))';
    }
  }};
  }

  ${media.desktopXL} {
    grid-template-columns: ${({ srpLayoutState }) => {
    switch (srpLayoutState) {
      case SRPLayoutEnum.Half:
        return 'repeat(3, calc(33.3% - 16px))';
      case SRPLayoutEnum.Listings:
      default:
        return 'repeat(4, calc(25% - 20px))';
    }
  }};
  }
`;

export type SearchListingsResultsProps = {
  listings: IListingGraphQL[] | null[];
  loading: boolean;
  querying: boolean;
  isSavedSearch?: boolean;
  totalListings?: number;
  paginationOffset?: number;
  setPaginationOffset?: React.Dispatch<React.SetStateAction<number>>;
  srpLayoutState: SRPLayoutEnum;
  setSRPLayoutState: React.Dispatch<React.SetStateAction<SRPLayoutEnum>>
};

const SearchListingsResults: React.FC<SearchListingsResultsProps> = ({
  listings,
  loading,
  querying,
  isSavedSearch,
  totalListings,
  paginationOffset,
  setPaginationOffset,
  srpLayoutState,
  setSRPLayoutState,
}) => {
  /** State */
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const queryParams = UrlUtil.parse(window?.location?.toString())?.query;

  /** Hooks */
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();
  const isDesktop = useDesktopMedia();
  const navigate = useNavigate();

  /** Refs */
  const pageRef = React.useRef<any>();

  /** Actions */
  const dispatch = useAppDispatch();
  const openMobileMap = () => dispatch(
    AppActions.toggleMobileSRPMapOpen(true),
  );

  const savedSearch: ISavedSearchGraphQL = useAppSelector(ListingSelectors.savedSearchQuery);
  const listingSearchQuery = useAppSelector(ListingSelectors.searchQuery);

  /** Helper Functions */
  const handlesrpLayoutStateChange = (newsrpLayoutState: SRPLayoutEnum) => {
    setSRPLayoutState(newsrpLayoutState);
  };

  const handleSaveSearchClick = (savedSearchType: SaveSearchModalTypeEnum) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.SaveSearch,
      props: {
        type: savedSearchType,
      },
    }),
  );

  const handleCancelSaveSearch = () => {
    dispatch(ListingActions.setListingSearch({ address: null }));
    dispatch(ListingActions.removeSavedSearch({}));
    dispatch(ListingActions.setListingSearch({ isSavedSearch: false }));
    navigate('/app/dashboard/search');
  };

  const determineNumberOfFilters = () => {
    let filters: number = 0;
    filters += savedSearch.query?.assetClasses?.length ?? 0;
    filters += savedSearch.query?.propertyTypes?.length ?? 0;
    const priceFilters = savedSearch.query?.price;
    if (priceFilters) {
      Object?.keys(priceFilters).forEach((key) => {
        if (priceFilters[key] !== null) {
          filters++;
        }
      });
    }
    return filters;
  };

  /** Effects */
  React.useEffect(() => {
    if (queryParams?.savedSearchData) {
      const savedSearchData: ISavedSearchGraphQL = JSON.parse(queryParams?.savedSearchData as any);
      dispatch(ListingActions.setListingSearch({ address: savedSearchData?.address }));
      dispatch(ListingActions.cacheSavedSearch({ ...savedSearchData }));
    }
  }, [queryParams]);

  React.useEffect(() => {
    if (pageRef?.current) {
      pageRef.current.scrollTo(0, 0);
    }
  }, [paginationOffset]);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [listingSearchQuery]);

  /** Render */
  if (loading) {
    listings = new Array(12).fill(null);
  }
  return (
    <Container ref={pageRef}>
      {(() => (
        <div>
          {isSavedSearch && (
            <Flex align="center" direction="column" margin={isMobile ? '16px 16px 0 16px' : '32px 32px 0px'}>
              <Flex width="100%" justify="space-between">
                <Text
                  type={TextTypesEnum.Bold24}
                >
                  {savedSearch.name}
                </Text>
                <Flex align="flex-end" justify="space-between">
                  <Text
                    type={TextTypesEnum.Medium14}
                    color={Colors.Brand700 || Colors.Blurple700}
                    onClick={() => handleCancelSaveSearch()}
                    margin="0 16px"
                  >
                    {isMobile ? 'Close' : 'Close saved search'}
                  </Text>
                  <Button
                    type={ButtonTypesEnum.Outline}
                    size={ButtonSizesEnum.Medium}
                    icon={Icons.PenRegular}
                    iconSize={16}
                    iconColor={Colors.Grey900}
                    text={isMobile ? null : 'Edit Search'}
                    onClick={() => handleSaveSearchClick(SaveSearchModalTypeEnum.Update)}
                  />
                  {isMobile && (
                  <SquareIconButton
                    icon={Icons.MapMarkedAltRegular}
                    iconSize={16}
                    size="40px"
                    onClick={openMobileMap}
                    margin="0px 0px 0px 8px"
                  />
                  )}
                </Flex>
              </Flex>
              <Flex direction={isMobile ? 'column' : 'row'} width="100%" align="flex-start">
                <Tag
                  text={savedSearch?.notifications ? 'Notifications on' : 'Notifications off'}
                  customTagColor={savedSearch?.notifications ? Colors.Green100 : Colors.Red100}
                  margin={isMobile ? '0 0 4px 0' : '0 4px 0 0'}
                >
                  <Icon
                    icon={savedSearch?.notifications ? Icons.CheckCircleSolid : Icons.TimesCircleSolid}
                    size="10"
                    color={savedSearch?.notifications ? Colors.Green800 : Colors.Red800}
                    margin="0 4px 0 0"
                  />
                </Tag>
                <Tag
                  text={`${determineNumberOfFilters() ?? '--'} filters`}
                  customTagColor={Colors.Grey100}
                  margin={isMobile ? '0 0 4px 0' : '0 4px 0 0'}
                />
              </Flex>
              <Divider margin="16px 0 0" />
            </Flex>
          )}
          <Flex align="flex-end" justify="space-between" margin={isMobile ? '16px 0 0 0' : '0'}>
            <Text
              data-cy="search-result-greeting"
              color={Colors.Black}
              type={isMobile ? TextTypesEnum.Bold18 : TextTypesEnum.Bold24}
              margin={!isDesktop ? '0 16px' : '28px 32px 0 32px'}
            >
              {(() => {
                if (loading) return 'Finding properties...';
                if (!listings?.length) return 'No properties listed';
                return `${totalListings} properties available`;
              })()}
            </Text>
            <Flex align="flex-end">
              {!loading && !isSavedSearch && !isMobile && (
              <Flex margin={isTablet ? '32px 16px 0px 0px' : '32px 32px 0px'}>
                <Button
                  data-cy="main-save-search-button"
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Small}
                  text={isMobile ? null : 'Save search'}
                  onClick={() => handleSaveSearchClick(SaveSearchModalTypeEnum.Create)}
                />
                <Button
                  data-cy="main-map-button"
                  type={ButtonTypesEnum.Outline}
                  size={ButtonSizesEnum.Small}
                  text={srpLayoutState === SRPLayoutEnum.Listings ? 'Show map' : 'Hide map'}
                  margin="0px 0px 0px 8px"
                  onClick={() => handlesrpLayoutStateChange(srpLayoutState === SRPLayoutEnum.Listings ? SRPLayoutEnum.Half : SRPLayoutEnum.Listings)}
                />
              </Flex>
              )}
              {isMobile && !isSavedSearch && (
              <>
                <SquareIconButton
                  icon={Icons.SaveRegular}
                  iconColor={Colors.White}
                  backgroundColor={Colors.Brand700 || Colors.Blurple700}
                  iconSize={16}
                  onClick={() => handleSaveSearchClick(SaveSearchModalTypeEnum.Create)}
                  size="40px"
                  margin="0px 8px 0px 0px"
                />
                <SquareIconButton
                  icon={Icons.MapMarkedAltRegular}
                  iconSize={16}
                  onClick={openMobileMap}
                  size="40px"
                  margin="0px 16px 0px 0px"
                />
              </>
              )}
            </Flex>
          </Flex>
          {!isSavedSearch && (
            <SearchListingsFilters
              listings={listings}
              loading={loading}
              querying={querying}
            />
          )}
          {(() => {
            if (!loading && !listings.length) {
              return (
                <Flex direction="column" align="center" width="100%" justify="center" height="600px">
                  <Icon
                    icon={Icons.MapMarkedAltRegular}
                    color={Colors.Grey400}
                    size={144}
                  />
                  <Text type={TextTypesEnum.Regular18} color={Colors.Grey700} margin="24px 0 0" width={isMobile ? '100%' : '400px'} align="center">
                    No properties listed for the area you searched. Please search for another location
                  </Text>
                </Flex>
              );
            }

            return (
              <>
                <Listings srpLayoutState={srpLayoutState}>
                  {listings.map((listing: IListingGraphQL | null, index) => (
                    <Link
                      key={listing?._id ?? index}
                      href={listing ? ListingUtil.slug(listing) : '/'}
                      target="_blank"
                      dataCy="search-listing-card-link"
                    >
                      <ListingCard
                        type={ListingCardTypesEnum.DefaultSearch}
                        listing={listing}
                      />
                    </Link>
                  ))}
                </Listings>
                <Pagination
                  setCurrentPage={setCurrentPage}
                  totalCount={totalListings}
                  siblingCount={1}
                  currentPage={currentPage}
                  maxVisibleData={(isMobile || isTablet) ? PaginationLimitsEnum.SRPListingsMobile : PaginationLimitsEnum.SRPListings}
                  paginationOffset={paginationOffset}
                  setPaginationOffset={setPaginationOffset}
                />
              </>
            );
          })()}
        </div>
      ))()}
    </Container>
  );
};

export default React.memo(SearchListingsResults);
