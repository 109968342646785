import React from 'react';
import styled from '@emotion/styled';
import { ADMIN_USERS } from '@biproxi/models/utils/PermissionUtil';
import { useMutation } from '@apollo/client';
import { IListingUploadGraphQL } from '@biproxi/models/interfaces/IListingUpload';
import IListingUploadParams from '@biproxi/models/interfaces/IListingUploadParams';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Dropdown from '../../elements/Dropdown';
import Flex from '../../elements/Flex';
import Button, { ButtonTypesEnum } from '../../elements/Button';
import UPDATE_LISTING_UPLOAD from '../../graphql/mutations/updateListingUpload.mutation';
import { IToastConfig, ToastTypesEnum } from '../Toast';

const Container = styled.div`
    max-width: 488px;
`;

export type AssignAdminModalProps = {
    uploadedListing: IListingUploadGraphQL
};

const AssignAdminModal: React.FC<AssignAdminModalProps> = ({
  uploadedListing,
}) => {
  /** State */
  const [selectedAdmin, setSelectedAdmin] = React.useState<string>(uploadedListing?.adminInfo?.email ?? '');
  const dropdownItems = ADMIN_USERS.map((admin: string) => (
    {
      name: admin,
      value: admin,
    }
  ));

  /** Actions */
  const dispatch = useAppDispatch();

  const popModal = () => {
    dispatch(AppActions.popModal());
  };

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /** GraphQL */
  interface UpdateListingUploadVars {
      params: Partial<IListingUploadParams>
  }

  interface UpdateListingUploadData {
      updateListingUpload: IListingUploadGraphQL
  }

  const [updateListingUpload] = useMutation<UpdateListingUploadData, UpdateListingUploadVars>(UPDATE_LISTING_UPLOAD, {
    onCompleted: () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'You have assined an admin to the listing',
      });
      popModal();
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error assigning an admin. Please try again.',
      });
    },
  });

  /** Functions */
  const handleAssignAdmin = () => {
    updateListingUpload({
      variables: {
        params: {
          _id: uploadedListing._id,
          listingId: uploadedListing.listingId,
          adminInfo: {
            // userId: selectedAdmin,
            email: selectedAdmin,
          },
        },

      },
    });
  };

  return (
    <ModalContainer>
      <ModalHeader title="Assign admin" close={popModal} />
      <ModalContent>
        <Container>
          <Flex direction="column">
            <Text
              type={TextTypesEnum.Regular12}
              color={Colors.Grey900}
            >
              Select admin
            </Text>
            <Dropdown
              items={dropdownItems}
              onChange={(value) => setSelectedAdmin(value)}
              value={selectedAdmin.length ? selectedAdmin : 'Select admin'}
            />
          </Flex>
        </Container>
      </ModalContent>
      <ModalFooter>
        <Button
          type={ButtonTypesEnum.Outline}
          text="Cancel"
          onClick={popModal}
        />
        <Button
          type={ButtonTypesEnum.Primary}
          text="Assign admin"
          disabled={!selectedAdmin.length}
          onClick={() => handleAssignAdmin()}
        />
      </ModalFooter>
    </ModalContainer>
  );
};

export default AssignAdminModal;
