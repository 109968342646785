import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import { IOfferGraphQL } from '@biproxi/models/interfaces/IOffer';
import OfferStateEnum from '@biproxi/models/enums/OfferStateEnum';
import { useMutation } from '@apollo/client';
import UserUtil from '@biproxi/models/utils/UserUtil';
import IFile from '@biproxi/models/interfaces/IFile';
import { useDropzone } from 'react-dropzone';
import IFileParams, { GraphQLFileUpload } from '@biproxi/models/interfaces/IFileParams';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { media } from '../../utils/MediaQuery';
import Icon, { Icons } from '../../elements/Icon';
import OfferCard, { OfferCardTypeEnum } from '../OfferCard';
import Divider from '../../elements/Divider';
import NoContent from '../NoContent';
import UPDATE_OFFER_STATE from '../../graphql/mutations/updateOfferState.mutation';
import Text, { TextAsEnum, TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import LinkText from '../../elements/LinkText';
import Input, { InputTypesEnum } from '../../elements/Input';
import LOIFile from '../LOIFile';
import CREATE_FILES from '../../graphql/mutations/createFiles.mutation';
import Flex from '../../elements/Flex';
import Label from '../../elements/Label';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { useAppDispatch } from '../../redux/store';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { AppActions } from '../../redux/app.redux';
import UPDATE_LEAD from '../../graphql/mutations/updateLead.mutation';
import LIST_LEADS from '../../graphql/queries/leads.query';
import { LeadActions } from '../../redux/lead.redux';

const Container = styled.div`
  width: 100%;
  height: 65vh;
  padding-right: 24px;
  overflow-y: auto;

  ${media.mobile} {
    height: calc(100vh - 135px);
  }
`;

const Dashed = styled.div`
  border: 1px dashed ${Colors.Grey300};
  padding: 12px 16px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

const HTMLText = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "aktiv-grotesk";
  line-height: 28px;

  > p {
    padding: 8px 0 0;
  }
`;

const CoverLetterContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${Colors.Grey300};
  margin: 0 0 16px;
`;

export type LeadModalOffersProps = {
  lead: ILeadGraphQL;
  buyer?: boolean;
};

const LeadModalOffers: React.FC<LeadModalOffersProps> = ({
  lead,
  buyer,
}) => {
  /** State */
  const [customOfferAmount, setCustomOfferAmount] = React.useState(lead?.offerAmount || 0);
  const [files, setFiles] = React.useState<IFile[]>(lead?.loiFiles || []);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [showFullDescription, setShowFullDescription] = React.useState<boolean>(false);

  /** Actions */
  const dispatch = useAppDispatch();
  const cacheLead = (lead: ILeadGraphQL) => dispatch(LeadActions.recacheLead({ leadId: lead?._id }));
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  // Only upload one file for now, could easily add support for more later.
  const onDrop: any = (browserFiles: GraphQLFileUpload[]) => {
    const fileParams: IFileParams[] = browserFiles.map((browserFile: GraphQLFileUpload) => ({
      name: browserFile.name,
      description: browserFile.name,
      mimetype: browserFile.type || 'application/octet-stream',
      encoding: 'utf8',
      upload: browserFile,
    }));

    uploadFiles({
      variables: {
        params: fileParams[0],
      },
    });
  };

  /** Hooks */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  /** GraphQL */
  interface Data {
    offer: IOfferGraphQL;
  }

  interface Vars {
    params: {
      offerId: string;
      state: OfferStateEnum;
    };
  }

  const [updateOfferState] = useMutation<Data, Vars>(UPDATE_OFFER_STATE);

  const [uploadFiles, { loading: fileLoading }] = useMutation(CREATE_FILES, {
    onCompleted: async (data) => {
      setFiles(data?.createFiles);
    },
  });

  const [updateLead, { loading }] = useMutation(UPDATE_LEAD, {
    refetchQueries: [{
      query: LIST_LEADS,
      variables: {
        params: {
          listingId: lead.listingId,
          isInvestorMatches: false,
        },
      },
    }],
    onCompleted: ({ updateLead }) => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Lead offer updated.',
      });
      cacheLead(updateLead);
    },
    onError: (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);

      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
    },
  });

  React.useEffect(() => {
    if (!buyer) {
      const updateState = async () => {
        if (lead?.offers?.[0]?.state === OfferStateEnum.New && !loaded) {
          setLoaded(true);
          updateOfferState({
            variables: {
              params: {
                offerId: lead?.offers?.[0]._id,
                state: OfferStateEnum.Active,
              },
            },
          });
        }
      };
      updateState();
    }
  }, [lead]);

  /** Render */
  return (
    <Container data-cy="leads-offer-container">
      {!buyer && (
        <>
          <Input
            label="Offer amount"
            placeholder="Set offer amount"
            value={customOfferAmount?.toString() ?? ''}
            onChange={(_event, unmasked) => setCustomOfferAmount(unmasked)}
            inputType={InputTypesEnum.Currency}
            margin="0 0 16px 0"
          />
          <Label text="LOI" />
          {(!fileLoading && files?.length <= 0) ? (
            <Dashed {...getRootProps()}>
              <input {...getInputProps()} />
              <Flex align="center">
                <Icon icon={Icons.FilePdfLight} color={Colors.Grey500} size={18} margin="0 8px 0 0" />
                <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>
                  Drag a PDF file here or
                  &nbsp;
                  <Text
                    type={TextTypesEnum.Medium14}
                    as={TextAsEnum.Span}
                    color={Colors.Brand700 || Colors.Blurple700}
                  >
                    click to upload
                  </Text>
                </Text>
              </Flex>
            </Dashed>
          ) : (
            <LOIFile file={files[0]} deleteFile={() => setFiles([])} />
          )}
          <Flex width="100%" justify="flex-end" margin="16px 0">
            <Button
              text="Add offer"
              isLoading={loading}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Small}
              onClick={() => {
                updateLead({
                  variables: {
                    params: {
                      _id: lead?._id,
                      offerAmount: customOfferAmount,
                      loiFileIds: files?.[0]?._id ? [files[0]._id] : [],
                    },
                  },
                });
              }}
            />
          </Flex>
        </>
      )}
      {lead?.offers?.length > 0 ? (
        <>
          {lead?.offers?.map((offer) => (
            <>
              {offer?.coverLetter && (
                <CoverLetterContainer>
                  <Text
                    type={TextTypesEnum.Bold18}
                    color={Colors.Grey900}
                  >
                    Cover Letter
                  </Text>
                  <Text
                    type={TextTypesEnum.Regular16}
                    color={Colors.Grey500}
                  >
                    By
                    {' '}
                    {UserUtil.fullName(lead?.user)}
                  </Text>
                  <HTMLText dangerouslySetInnerHTML={{ __html: showFullDescription || offer?.coverLetter?.length < 400 ? offer?.coverLetter : `${offer?.coverLetter?.substring(0, 400)}...` }} />
                  {offer?.coverLetter?.length > 400 && (
                  <LinkText
                    margin="16px 0 0"
                    type={TextTypesEnum.Medium12}
                    onClick={() => setShowFullDescription(!showFullDescription)}
                  >
                    {showFullDescription
                      ? 'Read less'
                      : 'Read more'}
                  </LinkText>
                  )}
                </CoverLetterContainer>
              )}
              <OfferCard offer={offer} type={buyer ? OfferCardTypeEnum.Rows : OfferCardTypeEnum.RowsWithActions} />
              <Divider margin="24px 0px" />
            </>
          ))}
        </>
      ) : (
        <NoContent
          height="176px"
          icon={Icons.EnvelopeOpenDollarLight}
          text="No current active offers."
        />
      )}
    </Container>
  );
};

export default LeadModalOffers;
