import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { brandedListingUrl } from '@biproxi/models/utils/ListingUtil';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Button, { ButtonSizesEnum } from '../../elements/Button';
import Icons from '../../elements/Icons';
import Colors from '../../styles/Colors';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import Label from '../../elements/Label';
import Text from '../../elements/Text';
import Icon from '../../elements/Icon';
import { ModalTypesEnum } from './Modal';
import useNylas from '../../hooks/useNylas.hook';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListingLinkContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${Colors.Grey300};
  border-radius: 4px;
  margin: 4px 0;
  height: 48px;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background: ${Colors.Grey200};
  }
`;

export type ShareListingModalProps = {
  listing: IListingGraphQL;
};

enum SocialMediaPlatformsEnum {
  LinkedIn = 'LinkedIn',
  Twitter = 'Twitter',
  Facebook = 'Facebook',
}

/**
 * Object containing the correlated icon names with a social platform
 * Using this method to avoid messy nested conditional rendering below
 */
const socialIconNames = {
  LinkedIn: 'Linkedin',
  Facebook: 'FacebookSquare',
  Twitter: 'TwitterSquare',
};

/**
 * Object containing functions that redirect a user to a respected social media platform to share a listing
 */
const SocialMediaShareURLs = {
  [SocialMediaPlatformsEnum.LinkedIn]: (postUrl: string) => `https://www.linkedin.com/sharing/share-offsite/?url=${postUrl}`,
  [SocialMediaPlatformsEnum.Facebook]: (postUrl: string) => `http://www.facebook.com/sharer.php?src=sp&u=${postUrl}`,
  [SocialMediaPlatformsEnum.Twitter]: (postUrl: string) => `https://twitter.com/intent/tweet?text=${postUrl}`,
};

const ShareListingModal: React.FC<ShareListingModalProps> = ({ listing }) => {
  /** Hooks */
  const { authenticated, handleDailyEmailReset } = useNylas();

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(AppActions.popModal());

  const pushSelectContactsModal = () => {
    popModal();
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.SelectContacts,
        props: {
          listing,
        },
      }),
    );
  };

  const pushAuthenticateWithNylasModal = () => {
    popModal();
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.AuthenticateWithNylas,
        props: {},
      }),
    );
  };

  /**
   * Popup window redirecting a user to a certain platform based on which button they click
   * @param socialPlatform, The platform that the user is being redirected to
   */
  const socialPopup = (socialPlatform: SocialMediaPlatformsEnum): void => {
    const windowWidth: number = 600;
    const windowHeight: number = 800;
    const leftOrientation = window.screenX + (window.outerWidth - windowWidth) / 2;
    const topOrientation = window.screenY + (window.outerHeight - windowHeight) / 2.5;
    const windowFeatures = `toolbar=0, scrollbars=0, location=0, width=${windowWidth}, height=${windowHeight}, top=${topOrientation}, left=${leftOrientation}`;
    window.open(
      SocialMediaShareURLs[socialPlatform](brandedListingUrl(listing)),
      socialPlatform,
      windowFeatures,
    );
  };

  /**
   * Click handler when a user clicks on a button
   * @param platform, Name of the platform with respect to whichever button a user clicks
   */
  const handleButtonClick = (platform: SocialMediaPlatformsEnum) => {
    switch (platform) {
      case SocialMediaPlatformsEnum.LinkedIn:
        socialPopup(SocialMediaPlatformsEnum.LinkedIn);
        break;
      case SocialMediaPlatformsEnum.Facebook:
        socialPopup(SocialMediaPlatformsEnum.Facebook);
        break;
      case SocialMediaPlatformsEnum.Twitter:
        socialPopup(SocialMediaPlatformsEnum.Twitter);
        break;
      default:
        break;
    }
  };

  /**
   * Toast that is displayed when a user copies the listing link to their clipboard
   * @param config, Config object containing toast type and message to be displayed
   */
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /**
   * Click handler when a user clicks on the button to copy the listing link directly
   */
  const handleCopyButtonClick = () => {
    try {
      navigator.clipboard.writeText(brandedListingUrl(listing));
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Link copied!',
      });
    } catch (err) {
      logger.error('handleCopyButtonClick error', err);
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'Error copying the link',
      });
    }
  };

  /** Effects */
  /** If it has been 24 hours since last email sent, reset user daily email limit.
   * Done here so that when share listing via email flow is started, we display the
   * reset limit and don't have to re-render there.
   */
  React.useEffect(() => {
    handleDailyEmailReset();
  });

  /** Render */
  return (
    <ModalContainer>
      <ModalHeader title="Share your listing" close={popModal} />
      <ModalContent>
        <ContentContainer>
          <Button
            margin="8px"
            size={ButtonSizesEnum.Large}
            icon={Icons.EnvelopeSolid}
            iconSize={25}
            onClick={() => (authenticated ? pushSelectContactsModal() : pushAuthenticateWithNylasModal())}
          />
          {Object.keys(SocialMediaPlatformsEnum).map(
            (key: SocialMediaPlatformsEnum, index) => (
              <Button
                key={index}
                margin="8px"
                size={ButtonSizesEnum.Large}
                icon={Icons[socialIconNames[SocialMediaPlatformsEnum[key]]]}
                iconSize={26}
                onClick={() => handleButtonClick(SocialMediaPlatformsEnum[key])}
              />
            ),
          )}
        </ContentContainer>
      </ModalContent>
      <ModalFooter>
        <FooterContainer>
          <Label margin="4px" text="Or copy and paste this link" />
          <ListingLinkContainer onClick={handleCopyButtonClick}>
            <Text margin="0 0 0 16px">{brandedListingUrl(listing)}</Text>
            <Icon icon={Icons.ClipboardRegular} color={Colors.Grey900} tip="Copy link" margin="0 16px 0 8px" />
          </ListingLinkContainer>
        </FooterContainer>
      </ModalFooter>
    </ModalContainer>
  );
};

export default ShareListingModal;
