import { useQuery } from '@apollo/client';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { IError } from '@biproxi/models/interfaces/common';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { ListingActions, ListingSelectors } from '../redux/listing.redux';
import GET_LISTING from '../graphql/queries/listing.query';
import { useAppDispatch, useAppSelector } from '../redux/store';

type UseListing = {
  listing: IListingGraphQL | null;
  listingId: string | null;
  loading: boolean;
  error: IError | null;
};

type UseListingHook = (listingId?: string) => UseListing;

const useListingHook: UseListingHook = (listingId) => {
  /* State */
  const selectedListingId = useAppSelector(ListingSelectors.selectedListingId);

  const listingCache = useAppSelector(ListingSelectors.listingCache);

  listingId = selectedListingId ?? listingId;

  const listing = listingCache[listingId];
  /* Actions */
  const dispatch = useAppDispatch();
  const cacheListing = (listing: IListingGraphQL) => dispatch(ListingActions.cacheListings({ listings: [listing] }));

  /* Hooks */
  interface Data {
    listing: IListingGraphQL;
  }

  interface Vars {
    listingId: string;
  }

  const { loading, error } = useQuery<Data, Vars>(GET_LISTING, {
    skip: !listingId, // not for now
    variables: {
      listingId,
    },
    onCompleted: (data) => {
      if (data.listing && !listing) {
        cacheListing(data.listing);
      }
    },
  });

  if (!listingId) {
    return {
      listing: null,
      listingId: null,
      loading: true,
      error: null,
    };
  }

  return {
    listing,
    listingId,
    loading: listing ? false : loading,
    error: error ? ApolloUtil.parseApolloClientError(error) : null,
  };
};

export default useListingHook;
