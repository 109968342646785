import gql from 'graphql-tag';
import OfferFragments from '../fragments/offer.fragments';

const LIST_OFFERS = gql`
  query Offers($params: ListOffersParams) {
    offers(params: $params) {
      ...OfferFields
      ...OfferLoiFiles
      ...OfferUser
      ...OfferListing
    }
  }

  ${OfferFragments.offerFields}
  ${OfferFragments.loiFiles}
  ${OfferFragments.user}
  ${OfferFragments.listing}
`;

export default LIST_OFFERS;
