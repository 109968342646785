import {
  createAction, createReducer, PayloadAction,
} from '@reduxjs/toolkit';
import shortid from 'shortid';
import {
  IModalConfig,
  ModalTypesEnum,
  urlSafeModalTypesEnum,
} from '../components/modal/Modal';
import * as UrlUtil from '../utils/UrlUtil';
import ISaveChanges from '../models/interfaces/ISaveChanges';
import IFileUpload from '../models/interfaces/IFileUpload';
import IFilePreview from '../models/interfaces/IFilePreview';
import IRequireAuthentication from '../models/interfaces/IRequireAuthentication';
import saveChangesState from '../models/states/saveChanges.state';
import fileUploadState from '../models/states/fileUpload.state';
import filePreviewState from '../models/states/filePreview.state';
import requireAuthenticationState from '../models/states/requireAuthentication.state';
import { IToastConfig } from '../components/Toast';
import IPageConfiguration from '../models/interfaces/IPageConfiguration';
import NextUtil from '../utils/NextUtil';
import { AppState } from './store';

export type AppReducerState = {
  modals: IModalConfig[];
  filePreview: IFilePreview;
  toasts: IToastConfig[];
  fileUpload: IFileUpload;
  saveChanges: ISaveChanges;
  requireAuthentication: IRequireAuthentication;
  intercomEnabled: boolean;
  mobileSideNavOpen: boolean;
  mobileSRPMapOpen: boolean;
};

export function appReducerState(config?: IPageConfiguration): AppReducerState {
  const { modalType = '' } = NextUtil.query(config);

  const isSafeModal = Object.values(urlSafeModalTypesEnum).includes(
    modalType as ModalTypesEnum,
  );

  const modals = modalType && isSafeModal ? [{
    type: modalType as ModalTypesEnum,
    props: {},
  }] : [];

  /**
   * The query in getPageConfiguration is completely broken
   * so this functionality was moved to useUser until we can get it fixed.
   */
  // if (config?.user?.isDeactivated) {
  //   Auth.logout();
  // }

  // if (config?.user && !UserUtil.phoneIsVerified(config.user)) {
  //   modals.push({
  //     type: ModalTypesEnum.CompleteRegister,
  //     props: {},
  //   });
  // }

  if (modalType && !isSafeModal) {
    UrlUtil.setQueryString({ modalType: null });
  }

  return {
    modals,
    toasts: [],
    fileUpload: fileUploadState(),
    saveChanges: saveChangesState(),
    filePreview: filePreviewState(),
    requireAuthentication: requireAuthenticationState(),
    intercomEnabled: false,
    mobileSideNavOpen: false,
    mobileSRPMapOpen: false,
  };
}

export enum AppActionTypesEnum {
  PushModal = 'PushModal',
  ReplaceModal = 'ReplaceModal',
  PopModal = 'PopModal',
  PopAllModals = 'PopAllModals',
  PushModalConfirmAction = 'PushModalConfirmAction',
  PushToast = 'PushToast',
  PopToast = 'PopToast',
  SetFileUpload = 'SetFileUpload',
  StartFileUpload = 'StartFileUpload',
  FinishFileUpload = 'FinishFileUpload',
  SetSaveChanges = 'SetSaveChanges',
  PreviewFiles = 'PreviewFiles',
  MoveFilePreview = 'MoveFilePreview',
  CloseFilePreview = 'CloseFilePreview',
  ToggleMobileSideNav = 'ToggleMobileSideNav',
  SetRequireAuthentication = 'SetRequireAuthentication',
  ToggleMobileSRPMapOpen = 'ToggleMobileSRPMapOpen',
  SetDataExplorerFilters = 'SetDataExplorerFilters',
  SetDataExplorerLocationFilter = 'SetDataExplorerLocationFilter',
  SetSelectedDataExplorerFilters = 'SetSelectedDataExplorerFilters',
  SetSelectedDataExplorerPropertyTypes = 'SetSelectedDataExplorerPropertyTypes',
  SetSelectedDataExplorerAssetClasses = 'SetSelectedDataExplorerAssetClasses',
  SetDataExplorerMapBounds = 'SetDataExplorerMapBounds',
  PurgeDataExplorerFilters = 'PurgeDataExplorerFilters',
}

/** ******************************************************************************
 *  Push Modal
 ****************************************************************************** */

// Payload
type PushModalPayloadType = IModalConfig;

// Action
const pushModal = createAction<PushModalPayloadType, AppActionTypesEnum.PushModal>(AppActionTypesEnum.PushModal);

// Reducer
function pushModalReducer(state: AppReducerState, action: PayloadAction<PushModalPayloadType>) {
  const modalConfig = action.payload;
  const modals = [...state.modals];
  UrlUtil.setQueryString({ modalType: modalConfig.type });
  modals.push(modalConfig);
  state.modals = modals;
  return state;
}

/** ******************************************************************************
 *  Replace Modal
 ****************************************************************************** */

// Payload
type ReplaceModalPayloadType = IModalConfig;

// Action
const replaceModal = createAction<ReplaceModalPayloadType, AppActionTypesEnum.ReplaceModal>(AppActionTypesEnum.ReplaceModal);

// Reducer
function replaceModalReducer(state: AppReducerState, action: PayloadAction<ReplaceModalPayloadType>) {
  const modalConfig = action.payload;
  const modals = [...state.modals];
  UrlUtil.setQueryString({ modalType: modalConfig.type });
  modals.pop();
  modals.push(modalConfig);
  state.modals = modals;
  return state;
}

/** ******************************************************************************
 *  Pop Modal
 ****************************************************************************** */

// Payload
type PopModalPayloadType = undefined;

// Action
const popModal = createAction<PopModalPayloadType, AppActionTypesEnum.PopModal>(AppActionTypesEnum.PopModal);

// Reducer
function popModalReducer(state: AppReducerState, _action: PayloadAction<PopModalPayloadType>) {
  UrlUtil.setQueryString({ modalType: null });
  const modals = [...state.modals];
  modals.pop();
  state.modals = modals;
  return state;
}

/** ******************************************************************************
 *  Pop All Modals At Once!
 ****************************************************************************** */

// Payload
type PopAllModalsPayloadType = undefined;

// Action
const popAllModals = createAction<PopAllModalsPayloadType, AppActionTypesEnum.PopAllModals>(AppActionTypesEnum.PopAllModals);

// Reducer
function popAllModalsReducer(state: AppReducerState, _action: PayloadAction<PopAllModalsPayloadType>) {
  UrlUtil.setQueryString({ modalType: null });
  state.modals = [];
  return state;
}

/** ******************************************************************************
*  Push Toast
****************************************************************************** */

// Payload
type PushToastPayloadType = IToastConfig;

// Action
const pushToast = createAction<PushToastPayloadType, AppActionTypesEnum.PushToast>(AppActionTypesEnum.PushToast);

// Reducer
function pushToastReducer(state: AppReducerState, action: PayloadAction<PushToastPayloadType>) {
  const toastConfig = action.payload;
  const toasts = [...state.toasts];
  toastConfig._id = shortid.generate();
  toasts.push(toastConfig);
  state.toasts = toasts;
  return state;
}

/** ******************************************************************************
 *  Pop Toast
****************************************************************************** */

// Payload
type PopToastPayloadType = {
  _id?: string;
};

// Action
const popToast = createAction<PopToastPayloadType, AppActionTypesEnum.PopToast>(AppActionTypesEnum.PopToast);

// Reducer
function popToastReducer(state: AppReducerState, action: PayloadAction<PopToastPayloadType>) {
  let toasts = [...state.toasts];
  if (action.payload._id) {
    toasts = toasts.filter((toast) => toast._id !== action.payload._id);
  } else {
    toasts.shift();
  }
  state.toasts = toasts;
  return state;
}

/** ******************************************************************************
 *  Set File Upload
 ****************************************************************************** */

// Payload
type SetFileUploadActionType = Partial<IFileUpload>;

// Action
const setFileUpload = createAction<SetFileUploadActionType, AppActionTypesEnum.SetFileUpload>(AppActionTypesEnum.SetFileUpload);

// Reducer
function setFileUploadReducer(state: AppReducerState, action: PayloadAction<SetFileUploadActionType>) {
  const fileUpload = action.payload;
  return {
    ...state,
    fileUpload: {
      ...state.fileUpload,
      ...fileUpload,
    },
  };
}

/** ******************************************************************************
 *  Start File Upload
 ****************************************************************************** */

// Payload
type StartFileUploadActionType = { blob: string; key: string; };

// Action
const startFileUpload = createAction<StartFileUploadActionType>(AppActionTypesEnum.StartFileUpload);

// Reducer
function startFileUploadReducer(state: AppReducerState, action: PayloadAction<StartFileUploadActionType>) {
  const { blob, key }: { blob: string; key: string } = action.payload;
  // This used to be tied to the image cropper
  // The modal type below should be ImageCropper
  const modalType = ModalTypesEnum.ConfirmChange;
  state = pushModalReducer(state, pushModal({ type: modalType, props: {} }));
  const fileUpload = { ...state.fileUpload };
  fileUpload.keys.push(key);
  fileUpload.blob = blob;
  return {
    ...state,
    fileUpload: {
      ...state.fileUpload,
      ...fileUpload,
    },
  };
}

/** ******************************************************************************
 *  Finish File Upload
 ****************************************************************************** */

// Payload
type FinishFileUploadActionType = { key: string; };

// Action
const finishFileUpload = createAction<FinishFileUploadActionType>(AppActionTypesEnum.FinishFileUpload);

// Reducer
function finishFileUploadReducer(state: AppReducerState, action: PayloadAction<FinishFileUploadActionType>) {
  const { key } = action.payload;
  const fileUpload = {
    keys: state.fileUpload.keys.filter((stateKey) => stateKey !== key),
  };

  return {
    ...state,
    fileUpload: {
      ...state.fileUpload,
      ...fileUpload,
    },
  };
}

/** ******************************************************************************
 *  Set Save Changes Upload
 ****************************************************************************** */

// Payload
type SetSaveChangesPayloadType = Partial<ISaveChanges>;

// Action
const setSaveChanges = createAction<SetSaveChangesPayloadType>(AppActionTypesEnum.SetSaveChanges);

// Reducer
function setSaveChangesReducer(state: AppReducerState, action: PayloadAction<SetSaveChangesPayloadType>) {
  const saveChanges: Partial<ISaveChanges> = action.payload;
  return {
    ...state,
    saveChanges: {
      ...state.saveChanges,
      ...saveChanges,
    },
  };
}

/** ******************************************************************************
*  Toggle Mobile Side Nav
****************************************************************************** */

// TODO(UI): modify the naming of this state; we will now have this action on all hovering over side nav, not just mobile!

// Payload
type ToggleMobileSideNavPayloadType = boolean;

// Action
const toggleMobileSideNav = createAction<ToggleMobileSideNavPayloadType, AppActionTypesEnum.ToggleMobileSideNav>(AppActionTypesEnum.ToggleMobileSideNav);

// Reducer
function toggleMobileSideNavReducer(state: AppReducerState, action: PayloadAction<ToggleMobileSideNavPayloadType>) {
  // pass in true to open, false to close
  const toggleMobileSideNav = action.payload;
  state.mobileSideNavOpen = toggleMobileSideNav;
  return state;
}

/** ******************************************************************************
*  Toggle Mobile SRP Map Open
****************************************************************************** */

// Payload
type ToggleMobileSRPMapOpenPayloadType = boolean;

// Action
const toggleMobileSRPMapOpen = createAction<ToggleMobileSRPMapOpenPayloadType, AppActionTypesEnum.ToggleMobileSRPMapOpen>(AppActionTypesEnum.ToggleMobileSRPMapOpen);

// Reducer
function toggleMobileSRPMapOpenReducer(state: AppReducerState, action: PayloadAction<ToggleMobileSRPMapOpenPayloadType>) {
  // pass in true to open, false to close
  const toggleMobileSRPMapOpen = action.payload;
  state.mobileSRPMapOpen = toggleMobileSRPMapOpen;
  return state;
}

/** ******************************************************************************
*  Preview Files
****************************************************************************** */

// Payload
type PreviewFilesPayloadType = IFilePreview;

// Action
const previewFiles = createAction<PreviewFilesPayloadType, AppActionTypesEnum.PreviewFiles>(AppActionTypesEnum.PreviewFiles);

// Reducer
function previewFilesReducer(state: AppReducerState, action: PayloadAction<PreviewFilesPayloadType>) {
  state.filePreview = action.payload;
  return state;
}

/** ******************************************************************************
*  Move File Preview
****************************************************************************** */

// Payload
type MoveFilePreviewPayloadType = {
  direction: 1 | -1;
};

// Action
const moveFilePreview = createAction<MoveFilePreviewPayloadType, AppActionTypesEnum.MoveFilePreview>(AppActionTypesEnum.MoveFilePreview);

// Reducer
function moveFilePreviewReducer(state: AppReducerState, action: PayloadAction<MoveFilePreviewPayloadType>) {
  const { filePreview: { fileId, files } } = state;
  const { payload: { direction } } = action;
  const currentIndex = files.map((file) => file._id).indexOf(fileId);
  let newIndex = currentIndex + direction;
  // Wrap around at beginner and end of files
  if (newIndex >= files.length) newIndex = 0;
  if (newIndex < 0) newIndex = files.length - 1;
  state.filePreview.fileId = files[newIndex]._id;
  return state;
}

/** ******************************************************************************
*  Close File Preview
****************************************************************************** */

// Payload
type CloseFilePreviewPayloadType = null;

// Action
const closeFilePreview = createAction<CloseFilePreviewPayloadType, AppActionTypesEnum.CloseFilePreview>(AppActionTypesEnum.CloseFilePreview);

// Reducer
function closeFilePreviewReducer(state: AppReducerState, _action: PayloadAction<CloseFilePreviewPayloadType>) {
  state.filePreview = filePreviewState();
  return state;
}

/** ******************************************************************************
*  Require Authentication
****************************************************************************** */

// Payload
type SetRequireAuthenticationPayloadType = IRequireAuthentication

// Action
const setRequireAuthentication = createAction<SetRequireAuthenticationPayloadType, AppActionTypesEnum.SetRequireAuthentication>(AppActionTypesEnum.SetRequireAuthentication);

// Reducer
function setRequireAuthenticationReducer(state: AppReducerState, action: PayloadAction<SetRequireAuthenticationPayloadType>) {
  state.requireAuthentication = action.payload;
  return state;
}

/** ******************************************************************************
 *  Exports
 ****************************************************************************** */

export const AppSelectors = {
  requireAuthentication: (state: AppState): AppReducerState['requireAuthentication'] => {
    const { app } = state;
    return app.requireAuthentication;
  },
  saveChanges: (state: AppState): AppReducerState['saveChanges'] => state?.app?.saveChanges,
};

export type AppActionPayloadTypes = {
  PushModalPayloadType: PushModalPayloadType,
  ReplaceModalPayloadType: ReplaceModalPayloadType,
  PopModalPayloadType: PopModalPayloadType,
  PopAllModalsPayloadType: PopAllModalsPayloadType,
  PushToastPayloadType: PushToastPayloadType,
  PopToastPayloadType: PopToastPayloadType,
  SetFileUploadActionType: SetFileUploadActionType,
  StartFileUploadActionType: StartFileUploadActionType,
  FinishFileUploadActionType: FinishFileUploadActionType,
  SetSaveChangesPayloadType: SetSaveChangesPayloadType,
  PreviewFilesActionType: PreviewFilesPayloadType,
  MoveFilePreviewActionType: MoveFilePreviewPayloadType,
  SetRequireAuthenticationPayloadType: SetRequireAuthenticationPayloadType,
};

export const AppActions = {
  pushModal,
  replaceModal,
  popModal,
  popAllModals,
  pushToast,
  popToast,
  setFileUpload,
  startFileUpload,
  finishFileUpload,
  setSaveChanges,
  toggleMobileSideNav,
  toggleMobileSRPMapOpen,
  previewFiles,
  moveFilePreview,
  closeFilePreview,
  setRequireAuthentication,
};

const reducer = createReducer(appReducerState(), (builder) => builder
  .addCase(pushModal, pushModalReducer)
  .addCase(replaceModal, replaceModalReducer)
  .addCase(popModal, popModalReducer)
  .addCase(popAllModals, popAllModalsReducer)
  .addCase(pushToast, pushToastReducer)
  .addCase(popToast, popToastReducer)
  .addCase(setFileUpload, setFileUploadReducer)
  .addCase(startFileUpload, startFileUploadReducer)
  .addCase(finishFileUpload, finishFileUploadReducer)
  .addCase(setSaveChanges, setSaveChangesReducer)
  .addCase(toggleMobileSideNav, toggleMobileSideNavReducer)
  .addCase(toggleMobileSRPMapOpen, toggleMobileSRPMapOpenReducer)
  .addCase(previewFiles, previewFilesReducer)
  .addCase(moveFilePreview, moveFilePreviewReducer)
  .addCase(closeFilePreview, closeFilePreviewReducer)
  .addCase(setRequireAuthentication, setRequireAuthenticationReducer));

export default reducer;
