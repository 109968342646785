import { logger } from '@biproxi/logger';
import { all } from 'redux-saga/effects';
import appSaga from './app.saga';
import userSaga from './user.saga';
import listingSaga from './listing.saga';
import leadSaga from './lead.saga';
import notificationSaga from './notification.saga';

export default function* rootSaga() {
  try {
    yield all([
      appSaga(),
      userSaga(),
      listingSaga(),
      leadSaga(),
      notificationSaga(),
    ]);
  } catch (e) {
    logger.error('rootSaga() error', e);
  }
}
