import React from 'react';
import IFile from '@biproxi/models/interfaces/IFile';
import NextUtil from '../utils/NextUtil';

// Yes this is a global. This hook will never be used more
// than once per page
let iframe = null;
if (NextUtil.hasWindow()) {
  iframe = document.createElement('iframe');
  iframe.style.position = 'absolute';
  iframe.style.top = '-10000px';
  iframe.style.left = '-10000px';
  document.body.append(iframe);
}

type UsePrintFile = (file: IFile) => void;

type UsePrintFileHook = (listingId?: string) => UsePrintFile;

const usePrintFile: UsePrintFileHook = () => {
  const print = React.useCallback((file: IFile): void => {
    iframe.src = '';
    const xhr = new XMLHttpRequest();
    xhr.open('GET', file.url, true);
    xhr.responseType = 'blob';

    xhr.onload = function x() {
      if (this.status === 200) {
        // Note: .response instead of .responseText
        const blob = new Blob([this.response], { type: file.mimetype });
        const url = URL.createObjectURL(blob);
        iframe.setAttribute('src', url);
        iframe.setAttribute('style', 'visibility:hidden;');

        iframe.onload = () => {
          setTimeout(() => {
            iframe.focus();
            iframe?.contentWindow?.print();
          }, 1);
        };
        document.body.append(iframe);
      }
    };

    xhr.send();
  }, []);
  return print;
};

export default usePrintFile;
