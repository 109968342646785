import React from 'react';
import styled from '@emotion/styled';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import { useMutation, useQuery } from '@apollo/client';
import { IChannelGraphQL } from '@biproxi/models/interfaces/IChannel';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import SendBirdClientSDK, { SendBirdMessageTypes } from '../utils/SendBirdClientSDK';
import Text, { TextTypesEnum } from '../elements/Text';
import Colors from '../styles/Colors';
import GET_USER from '../graphql/queries/user.query';
import Loader, { LoaderSizes } from '../elements/Loader';
import ProfileImage, { ProfileImageTypes } from './ProfileImage';
import { AppState, useAppDispatch, useAppSelector } from '../redux/store';
import { ChatActions } from '../redux/chat.redux';
import SEND_NEW_MESSAGE_NOTIFICATION_EMAIL from '../graphql/mutations/sendNewMessageNotificationEmail.mutation';
import ChatInput from './ChatInput';
import * as Motion from '../elements/Motion';
import CONVERT_INVESTOR_MATCH from '../graphql/mutations/convertInvestorMatchLead.mutation';
import { LeadActions } from '../redux/lead.redux';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const NoChannelContainer = styled.div`
  position: relative;
`;

const NoChannelText = styled.div`
  position: absolute;
  bottom: 16px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessagesContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
`;

const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputContainer = styled.form`
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
`;

type ChatStyleProps = {
  isOwnMessage: boolean;
  isFileMessage?: boolean;
  isInvestorMatch?: boolean;
  children?: React.ReactNode;
}

const ChatItem = styled(Motion.FadeIn)<ChatStyleProps & { onClick: () => void | null }>`
  background: ${Colors.Brand700 || Colors.Blurple700};
  background: ${({ isOwnMessage }) => (isOwnMessage ? `${Colors.Brand700 || Colors.Blurple700}` : `${Colors.Grey200}`)};
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  padding: 8px 16px;
  word-break: break-word;
  cursor: ${({ isFileMessage }) => (isFileMessage ? 'pointer' : 'text')};
`;

const ChatRow = styled.div<ChatStyleProps>`
  width: 100%;
  display: flex;
  justify-content: ${({ isOwnMessage }) => (isOwnMessage ? 'flex-end' : 'flex-start')};
  margin: 16px 0px;
`;

const MsgTime = styled.div<ChatStyleProps>`
  margin-top: 4px;
  display: flex;
  align-items: ${({ isOwnMessage }) => (isOwnMessage ? 'flex-end' : 'flex-start')};
`;

const ChatItemContainer = styled.div<ChatStyleProps>`
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: ${({ isOwnMessage }) => (isOwnMessage ? 'flex-end' : 'flex-start')};
`;

type ChatLayoutCustomProps = {
  channel?: IChannelGraphQL;
  leadId?: string;
  isInvestorMatch?: boolean;
};

const ChatLayoutCustom: React.FC<ChatLayoutCustomProps> = ({
  channel, leadId, isInvestorMatch,
}) => {
  /** State */
  const [message, setMessage] = React.useState('');
  const [messages, setMessages] = React.useState([]);
  const [rendered, setRendered] = React.useState(false);

  /** Actions */
  const dispatch = useAppDispatch();
  const { newMessage } = useAppSelector((state: AppState) => state.chat);
  const setNewMessage = (channel, message) => dispatch(
    ChatActions.setNewMessageReceived({ channel, message }),
  );
  const setInvestorMatchChanged = () => dispatch(LeadActions.setInvestorMatchChanged({}));
  const setInvestorTableNeedsRefetch = () => dispatch(LeadActions.setInvestorTableNeedsRefetch({ needsRefetch: true }));

  /** GraphQL */
  const { data, loading } = useQuery<{ user?: IUserGraphQL }>(GET_USER);
  const [sendNewMessageNotificationEmail] = useMutation(SEND_NEW_MESSAGE_NOTIFICATION_EMAIL);
  const [convertInvestorMatchToStandardLead] = useMutation(CONVERT_INVESTOR_MATCH, {
    variables: {
      params: {
        leadId,
      },
    },
    onCompleted: () => {
      setInvestorTableNeedsRefetch();
      setInvestorMatchChanged();
    },
  });

  /** Effects */
  // Mark messages as read on initial mount.
  React.useEffect(() => {
    if (channel?.hasUnreadMessages) {
      setRendered(true);
      SendBirdClientSDK.markChannelAsRead(channel?.channelUrl);
      setNewMessage({ ...channel, hasUnreadMessages: false }, null);
    }
  }, []);

  // Load in the messages from the channel
  React.useEffect(() => {
    (async () => {
      if (channel) {
        const res = await SendBirdClientSDK.getChannelMessages(channel?.channelUrl);
        setMessages(res);
      }
    })();
  }, [channel]);

  /**
   * Mark all messages in the channel
   * as read when the message list updates
   * and the chat window is open.
   */
  React.useEffect(() => {
    if (newMessage?.channelUrl === channel?.channelUrl) {
      setMessages((prevState) => [...prevState, newMessage]);
      // Don't mark when current user is the one sending the message.
      if (newMessage.sender.userId !== data.user._id) {
        if (rendered) {
          SendBirdClientSDK.markChannelAsRead(channel?.channelUrl);
        }
      }
    }
  }, [newMessage]);

  /** Convert an investor match to a standard lead once they have responded to the seller's message */
  React.useEffect(() => {
    if (isInvestorMatch) {
      const investorHasRespondedToSeller = messages?.some((message) => message.sender.userId !== data.user._id);
      if (investorHasRespondedToSeller) {
        convertInvestorMatchToStandardLead();
      }
    }
  }, [messages]);

  /* Render */
  const sendMessage = async (e) => {
    e?.preventDefault();

    if (!message) return;

    // Send the message with the Sendbird client SDK.
    const res = await SendBirdClientSDK.sendMessage(message, channel);

    // Update the ui to reflect that the new message has been sent.
    const updatedChannel = {
      channelUrl: res.channelUrl,
      hasUnreadMessages: false,
      lastMessageAt: res.createdAt,
      lastMessageText: res.message,
      listingId: channel.listingId,
    };

    const cleanedMessage = {
      channelUrl: res.channelUrl,
      messageId: res.messageId,
      sender: {
        userId: res.sender.userId,
      },
      createdAt: res.createdAt,
      message: res.message,
    };

    setNewMessage(updatedChannel, cleanedMessage);

    // Send an email notification to the message receiver when not on localhost.
    if (window?.location?.hostname !== 'localhost' && !channel?.user?.notificationSettings?.disableMessages) {
      sendNewMessageNotificationEmail({
        variables: {
          params: {
            listingId: channel?.listingId,
            message,
            messageReceiverUserId: channel.user?._id,
          },
        },
      });
    }

    setMessage('');
  };

  if (loading) {
    return (
      <LoaderContainer>
        <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      <MessagesContainer>
        <div>
          {channel && messages?.map((msg, index) => {
            const isOwnMessage = msg?.sender?.userId === data?.user?._id;
            const isFileMessage = msg?.messageType === SendBirdMessageTypes.File;
            return (
              <ChatRow isOwnMessage={isOwnMessage} key={`${msg.messageId}-${index}`}>
                {!isOwnMessage && <ProfileImage type={ProfileImageTypes.Small} user={channel?.user} />}
                <ChatItemContainer isOwnMessage={isOwnMessage}>
                  <ChatItem
                    isOwnMessage={isOwnMessage}
                    isFileMessage={isFileMessage}
                    onClick={isFileMessage ? () => {
                      window.open(msg.url);
                    } : null}
                  >
                    {isFileMessage ? (
                      <>
                        <Text type={TextTypesEnum.Regular14} color={isOwnMessage ? Colors.White : Colors.Black}>{`File Message: ${msg.name}`}</Text>
                        {msg.url && <Text type={TextTypesEnum.Regular14} color={isOwnMessage ? Colors.White : Colors.Black}>Click to download</Text>}
                      </>
                    ) : (
                      <Text type={TextTypesEnum.Regular14} color={isOwnMessage ? Colors.White : Colors.Black}>{msg?.message}</Text>
                    )}
                  </ChatItem>
                  <MsgTime isOwnMessage={isOwnMessage}>
                    <Text type={TextTypesEnum.Regular12} color={Colors.Grey500}>{TimeUtil.format(msg?.createdAt, 'ff', TimeUtil.currentBrowserTimezone())}</Text>
                  </MsgTime>
                </ChatItemContainer>
              </ChatRow>
            );
          })}
        </div>
      </MessagesContainer>
      <InputContainer onSubmit={sendMessage}>
        {!channel ? (
          <NoChannelContainer>
            <NoChannelText>
              {/* Channel not present, error, or blocked */}
              <Text
                type={TextTypesEnum.Regular16}
                color={Colors.Grey400}
              >
                Messaging this user is currently unavailable
              </Text>
            </NoChannelText>
          </NoChannelContainer>
        ) : (
          <>
            {(channel?.lastMessageAt === 0) && (messages?.length === 0) && (
              <NoChannelContainer>
                <NoChannelText>
                  <Text
                    type={TextTypesEnum.Regular16}
                    color={Colors.Grey400}
                  >
                    Conversation has not been started
                  </Text>
                </NoChannelText>
              </NoChannelContainer>
            )}
            <ChatInput
              value={message}
              onChange={(event) => setMessage(event.currentTarget.value)}
              onEnter={sendMessage}
              channel={channel}
              data-cy="custom-chat-layout-input"
            />
          </>
        )}
      </InputContainer>
    </Container>
  );
};

export default ChatLayoutCustom;
