import {
  ApolloClient, HttpLink, InMemoryCache,
} from '@apollo/client';
import axios from 'axios';
import { buildAxiosFetch } from 'axios-fetch';
import { config } from '@biproxi/env';

export const contentfulClient = new ApolloClient({
  link: new HttpLink({
    uri: config.NEXT_PUBLIC_CONTENTFUL_URI ?? 'https://graphql.contentful.com/content/v1/spaces/6a5ayeasz85q/environments/development',
    headers: {
      Authorization: `Bearer ${config.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN}`,
    },
    fetch: buildAxiosFetch(axios),
  }),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
