import gql from 'graphql-tag';

const ADD_LISTINGS_TO_ORGANIZATION = gql`
  mutation addListingsToOrganization($where: OrganizationWhere, $connect: OrganizationConnectInput) {
    updateOrganizations(where: $where, connect: $connect) {
      info {
        relationshipsCreated
      }
    }
  }
`;

export default ADD_LISTINGS_TO_ORGANIZATION;
