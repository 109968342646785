import axios from 'axios';
import { loggerConfig } from './logger.config';

const logtailAxios = axios.create({
  baseURL: 'https://in.logtail.com',
  headers: {
    Authorization: `Bearer ${loggerConfig.logtailToken}`,
    'Content-Type': 'application/json',
  },
});

export const logtail = async (o:any): Promise<void> => logtailAxios.post('', o);
