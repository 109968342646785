import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Colors, { ColorsType } from '../styles/Colors';
import Loader, { LoaderSizes } from './Loader';
import Text, { TextAsEnum, TextTypesEnum } from './Text';
import Icon, { IconsType } from './Icon';
import { AppState, useAppSelector } from '../redux/store';

export enum ButtonTypesEnum {
  Primary = 'Primary',
  PrimaryGreen = 'PrimaryGreen',
  Outline = 'Outline',
  Warning = 'Warning',
  Ghost = 'Ghost',
  BlurpleGhost = 'BlurpleGhost',
  Pale = 'Pale',
  HideTable = 'HideTable',
}

export enum ButtonSizesEnum {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export enum ButtonStatesEnum {
  Default = 'Default',
  Hovered = 'Hovered',
  Active = 'Active',
  Disabled = 'Disabled',
}

type StyledButtonProps = {
  buttonType: ButtonTypesEnum;
  buttonSize: ButtonSizesEnum;
  isLoading: boolean;
  disabled: boolean;
  isFullWidth: boolean;
  onClick?: any;
  margin?: string;
  padding?: string;
  customWidth?: string;
}

const buttonStateCSS = (props: StyledButtonProps, state: ButtonStatesEnum) => {
  const {
    buttonType,
    isLoading,
    disabled,
  } = props;

  if (isLoading) state = ButtonStatesEnum.Default;
  if (disabled) state = ButtonStatesEnum.Disabled;

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */
  const contentfulFetched = useAppSelector((state: AppState) => state.contentful.brandCollection.fetched);
  const company = useAppSelector((state: AppState) => state.contentful.brandCollection.companyName);

  let buttonStateStyles = {
    [ButtonTypesEnum.Primary]: {
      [ButtonStatesEnum.Default]: {
        background: Colors.Brand700 || Colors.Blurple700,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Hovered]: {
        background: Colors.Brand800 || Colors.Blurple800,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Active]: {
        background: Colors.Brand900 || Colors.Blurple900,
        border: '0',
        shadow: `0px 0px 0px 4px ${Colors.Brand50 || Colors.Blurple50}`,
      },
      [ButtonStatesEnum.Disabled]: {
        background: Colors.Grey100,
        border: '0',
        shadow: '0',
      },
    },
    [ButtonTypesEnum.PrimaryGreen]: {
      [ButtonStatesEnum.Default]: {
        background: Colors.Grey100,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Hovered]: {
        background: Colors.Green300,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Active]: {
        background: Colors.Green500,
        border: '0',
        shadow: `0px 0px 0px 4px ${Colors.Green50}`,
      },
      [ButtonStatesEnum.Disabled]: {
        background: Colors.Grey100,
        border: '0',
        shadow: '0',
      },
    },
    [ButtonTypesEnum.Outline]: {
      [ButtonStatesEnum.Default]: {
        background: Colors.White,
        border: `1px solid ${Colors.Grey300}`,
        shadow: '0',
      },
      [ButtonStatesEnum.Hovered]: {
        background: Colors.Grey200,
        border: `1px solid ${Colors.Grey200}`,
        shadow: '0',
      },
      [ButtonStatesEnum.Active]: {
        background: Colors.Grey300,
        border: `1px solid ${Colors.Grey700}`,
        shadow: `0px 0px 0px 4px ${Colors.Black03}`,
      },
      [ButtonStatesEnum.Disabled]: {
        background: Colors.Grey100,
        border: '0',
        shadow: '0',
      },
    },
    [ButtonTypesEnum.Ghost]: {
      [ButtonStatesEnum.Default]: {
        background: Colors.White,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Hovered]: {
        background: Colors.Grey200,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Active]: {
        background: Colors.Grey300,
        border: '0',
        shadow: `0px 0px 0px 4px ${Colors.Black10}`,
      },
      [ButtonStatesEnum.Disabled]: {
        background: Colors.White,
        border: '0',
        shadow: '0',
      },
    },
    [ButtonTypesEnum.BlurpleGhost]: {
      [ButtonStatesEnum.Default]: {
        background: Colors.White,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Hovered]: {
        background: Colors.Grey200,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Active]: {
        background: Colors.Grey300,
        border: '0',
        shadow: `0px 0px 0px 4px ${Colors.Black10}`,
      },
      [ButtonStatesEnum.Disabled]: {
        background: Colors.White,
        border: '0',
        shadow: '0',
      },
    },
    [ButtonTypesEnum.Pale]: {
      [ButtonStatesEnum.Default]: {
        background: Colors.Brand50 || Colors.Blurple50,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Hovered]: {
        background: Colors.Blurple10,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Active]: {
        background: Colors.Brand50 || Colors.Blurple50,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Disabled]: {
        background: Colors.Grey100,
        border: '0',
        shadow: '0',
      },
    },
    [ButtonTypesEnum.HideTable]: {
      [ButtonStatesEnum.Default]: {
        background: Colors.Green500,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Hovered]: {
        background: Colors.Green600,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Active]: {
        background: Colors.Green700,
        border: '0',
        shadow: `0px 0px 0px 4px ${Colors.Brand50 || Colors.Blurple50}`,
      },
      [ButtonStatesEnum.Disabled]: {
        background: Colors.Grey100,
        border: '0',
        shadow: '0',
      },
    },
    [ButtonTypesEnum.Warning]: {
      [ButtonStatesEnum.Default]: {
        background: Colors.Red500,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Hovered]: {
        background: Colors.Red700,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Active]: {
        background: Colors.Red800,
        border: '0',
        shadow: '0',
      },
      [ButtonStatesEnum.Disabled]: {
        background: Colors.Grey100,
        border: '0',
        shadow: '0',
      },
    },
  };

  /** If we fetched data, and company is not Biproxi do this */
  if (contentfulFetched && company !== 'Biproxi') {
    buttonStateStyles = {
      [ButtonTypesEnum.Primary]: {
        [ButtonStatesEnum.Default]: {
          background: Colors.Brand700 || Colors.Blurple700,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Hovered]: {
          background: Colors.Brand800 || Colors.Blurple800,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Active]: {
          background: Colors.Brand900 || Colors.Blurple900,
          border: '0',
          shadow: `0px 0px 0px 4px ${Colors.Brand50 || Colors.Blurple50}`,
        },
        [ButtonStatesEnum.Disabled]: {
          background: Colors.Grey100,
          border: '0',
          shadow: '0',
        },
      },
      [ButtonTypesEnum.PrimaryGreen]: {
        [ButtonStatesEnum.Default]: {
          background: Colors.Grey100,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Hovered]: {
          background: Colors.Green300,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Active]: {
          background: Colors.Green500,
          border: '0',
          shadow: `0px 0px 0px 4px ${Colors.Green50}`,
        },
        [ButtonStatesEnum.Disabled]: {
          background: Colors.Grey100,
          border: '0',
          shadow: '0',
        },
      },
      [ButtonTypesEnum.Outline]: {
        [ButtonStatesEnum.Default]: {
          background: Colors.White,
          border: `1px solid ${Colors.Grey300}`,
          shadow: '0',
        },
        [ButtonStatesEnum.Hovered]: {
          background: Colors.Grey200,
          border: `1px solid ${Colors.Grey200}`,
          shadow: '0',
        },
        [ButtonStatesEnum.Active]: {
          background: Colors.Grey300,
          border: `1px solid ${Colors.Grey700}`,
          shadow: `0px 0px 0px 4px ${Colors.Black03}`,
        },
        [ButtonStatesEnum.Disabled]: {
          background: Colors.Grey100,
          border: '0',
          shadow: '0',
        },
      },
      [ButtonTypesEnum.Ghost]: {
        [ButtonStatesEnum.Default]: {
          background: Colors.White,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Hovered]: {
          background: Colors.Grey200,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Active]: {
          background: Colors.Grey300,
          border: '0',
          shadow: `0px 0px 0px 4px ${Colors.Black10}`,
        },
        [ButtonStatesEnum.Disabled]: {
          background: Colors.White,
          border: '0',
          shadow: '0',
        },
      },
      [ButtonTypesEnum.BlurpleGhost]: {
        [ButtonStatesEnum.Default]: {
          background: Colors.White,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Hovered]: {
          background: Colors.Grey200,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Active]: {
          background: Colors.Grey300,
          border: '0',
          shadow: `0px 0px 0px 4px ${Colors.Black10}`,
        },
        [ButtonStatesEnum.Disabled]: {
          background: Colors.White,
          border: '0',
          shadow: '0',
        },
      },
      [ButtonTypesEnum.Pale]: {
        [ButtonStatesEnum.Default]: {
          background: Colors.Brand50 || Colors.Blurple50,
          border: '0',
          shadow: '0',
        },
        /**
         * !! FIX THIS, we don't have blurple (brand10) in some of the contentful stuff
         */
        [ButtonStatesEnum.Hovered]: {
          background: Colors.Brand50 || Colors.Blurple10,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Active]: {
          background: Colors.Brand50 || Colors.Blurple50,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Disabled]: {
          background: Colors.Grey100,
          border: '0',
          shadow: '0',
        },
      },
      [ButtonTypesEnum.HideTable]: {
        [ButtonStatesEnum.Default]: {
          background: Colors.Green500,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Hovered]: {
          background: Colors.Green600,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Active]: {
          background: Colors.Green700,
          border: '0',
          shadow: `0px 0px 0px 4px ${Colors.Brand50 || Colors.Blurple50}`,
        },
        [ButtonStatesEnum.Disabled]: {
          background: Colors.Grey100,
          border: '0',
          shadow: '0',
        },
      },
      [ButtonTypesEnum.Warning]: {
        [ButtonStatesEnum.Default]: {
          background: Colors.Red500,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Hovered]: {
          background: Colors.Red700,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Active]: {
          background: Colors.Red800,
          border: '0',
          shadow: '0',
        },
        [ButtonStatesEnum.Disabled]: {
          background: Colors.Grey100,
          border: '0',
          shadow: '0',
        },
      },
    };
  }

  const config = buttonStateStyles[buttonType][state];

  return css`
    background-color: ${config.background};
    border: ${config.border};
    box-shadow: ${config.shadow};
  `;
};

const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  height: 40px;
  data-cy: ${(props) => props['data-cy']};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none;
  border-radius: 4px;
  box-sizing: border-box;
  transition: all 0.1s;
  width: ${(props) => (props.customWidth || (props.isFullWidth ? '100%' : 'auto'))};
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  padding: ${(props) => (props.padding ? props.padding : '0px')};
  padding: ${(props) => {
    if (props.isFullWidth) return null;

    switch (props.buttonSize) {
      case ButtonSizesEnum.Large:
        return '0 24px';

      case ButtonSizesEnum.Medium:
        return '0 12px';

      case ButtonSizesEnum.Small:
        return '0 12px';

      default:
        return null;
    }
  }};
  height: ${(props) => {
    switch (props.buttonSize) {
      case ButtonSizesEnum.Large:
        return '48px';

      case ButtonSizesEnum.Medium:
        return '40px';

      case ButtonSizesEnum.Small:
        return '32px';

      default:
        return null;
    }
  }};
  ${(props) => buttonStateCSS(props, ButtonStatesEnum.Default)};

  &:hover {
    cursor: ${(props) => (!props.isLoading && !props.disabled ? 'pointer' : null)};
    ${(props) => buttonStateCSS(props, ButtonStatesEnum.Hovered)};
  }

   &:active {
    ${(props) => buttonStateCSS(props, ButtonStatesEnum.Active)};
  }
`;

type ButtonTextProps = {
  text: string;
  buttonType: ButtonTypesEnum;
  buttonSize: ButtonSizesEnum;
  disabled: boolean;
}

const ButtonText: React.FC<ButtonTextProps> = ({
  text,
  buttonType,
  buttonSize,
  disabled,
}) => {
  const type = (() => {
    switch (buttonSize) {
      case ButtonSizesEnum.Large:
        return TextTypesEnum.Medium14;

      case ButtonSizesEnum.Medium:
        return TextTypesEnum.Medium12;

      case ButtonSizesEnum.Small:
        return TextTypesEnum.Medium12;

      default:
        return null;
    }
  })();

  const color = (() => {
    if (disabled) return Colors.Grey400;
    switch (buttonType) {
      case ButtonTypesEnum.Primary:
      case ButtonTypesEnum.Warning:
        return Colors.White;

      case ButtonTypesEnum.Outline:
      case ButtonTypesEnum.Ghost:
        return Colors.Grey900;

      case ButtonTypesEnum.Pale:
        return Colors.Brand700 || Colors.Blurple700;
      case ButtonTypesEnum.BlurpleGhost:
        return Colors.Brand700 || Colors.Blurple700;

      case ButtonTypesEnum.HideTable:
        return Colors.White;

      default:
        return null;
    }
  })();

  return (
    <Text type={type} color={color} as={TextAsEnum.Span} noWrap>
      {text}
    </Text>
  );
};

export type ButtonProps = {
  text?: string;
  type?: ButtonTypesEnum
  size?: ButtonSizesEnum;
  onClick?: any;
  icon?: IconsType;
  iconColor?: ColorsType;
  iconSize?: number;
  rightIcon?: IconsType;
  margin?: string;
  padding?: string;
  isLoading?: boolean;
  loaderColor?: ColorsType;
  disabled?: boolean;
  isFullWidth?: boolean;
  id?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  'data-cy'?: string;
  customWidth?: string;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  text,
  type = ButtonTypesEnum.Primary,
  size = ButtonSizesEnum.Large,
  onClick = () => {},
  icon,
  iconColor = Colors.White,
  iconSize = 18,
  rightIcon,
  margin,
  padding,
  isLoading = false,
  loaderColor = Colors.White,
  disabled = false,
  isFullWidth = false,
  id,
  htmlType,
  'data-cy': dataCy,
  customWidth,
}, ref) => (
  <StyledButton
    ref={ref}
    buttonType={type}
    buttonSize={size}
    onClick={(event) => {
      if (!isLoading) {
        onClick(event);
      }
    }}
    isLoading={isLoading}
    disabled={disabled}
    isFullWidth={isFullWidth}
    margin={margin}
    padding={padding}
    id={id}
    type={htmlType}
    data-cy={dataCy}
    customWidth={customWidth}
  >
    {(() => (
      <>
        {(() => {
          if (isLoading) {
            return <Loader size={LoaderSizes.Small} color={loaderColor} />;
          }

          if (icon && !text) {
            return <Icon icon={icon} size={iconSize} color={iconColor} />;
          }

          return (
            <>
              {icon && (
                <Icon
                  icon={icon}
                  size={iconSize}
                  color={iconColor}
                  margin="0 8px 0 0"
                />
              )}
              <ButtonText
                text={text}
                buttonType={type}
                buttonSize={size}
                disabled={disabled}
              />
              {rightIcon && (
                <Icon
                  icon={rightIcon}
                  size={iconSize}
                  color={iconColor}
                  margin="0 0 0 8px"
                />
              )}
            </>
          );
        })()}
      </>
    ))()}
  </StyledButton>
));

export default Button;
