import gql from 'graphql-tag';

const GET_USER_ORGANIZATIONS_AND_ROLES = gql`
  query UserOrganizations($where: UserWhere, $sort: [UserOrganizationConnectionSort!], $organizationConnectionWhere2: UserOrganizationConnectionWhere) {
    users(where: $where) {
      _id
      firstName
      lastName
      email
      biproxiRoles
      biproxiPermissions
      organizationConnection(sort: $sort, where: $organizationConnectionWhere2) {
        edges {
          permissions
          roles
          node {
            _id
            name
            description
            listingsConnection {
              totalCount
              edges {
                node {
                  _id
                }
              }
            }
            usersConnection {
              totalCount
            }
          }
        }
      }
    }
  }
`;

export default GET_USER_ORGANIZATIONS_AND_ROLES;
