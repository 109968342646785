import TValueOf from '../models/types/TValueOf';

const BoxShadows = {
  Darker: '0px 4px 16px rgba(0, 0, 0, 0.1)',
  Hard: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  Standard: '0px 4px 16px rgba(0, 0, 0, 0.05)',
  Light: '0px 4px 32px rgba(0, 0, 0, 0.02)',
  UpDrop: '0px -4px 16px rgba(0, 0, 0, 0.05)',
};

export type BoxShadowsType = TValueOf<typeof BoxShadows>;

export default BoxShadows;
