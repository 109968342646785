/* eslint-disable implicit-arrow-linebreak */
import Joi from 'joi';
import ListingQueryTypesEnum from '../enums/ListingQueryTypesEnum';
import ListingAssetClassEnum from '../enums/ListingAssetClassEnum';
import ListingPropertyTypeEnum from '../enums/ListingPropertyTypeEnum';
import ListingStateEnum from '../enums/ListingStateEnum';
import BrokerCommisionEnum from '../enums/BrokerCommisionEnum';
import ListingVaultFolderNamesEnum from '../enums/ListingVaultFolderNamesEnum';
import JoiUtil from '../utils/JoiUtil';
import { authorizedOptionalDataSchema, authorizedDataSchema } from './authentication.joi';
import addressSchema, { requiredAddressSchema } from './address.joi';
// import license from './license.joi';
import ListingQuerySortByEnum from '../enums/ListingQuerySortByEnum';
import paginationSchema from './pagination.joi';
import {
  PreferredInvestmentTypeEnum,
  RegionalFocusEnum,
} from '../enums/InvestorProfileAnswersEnums';
import DealProfileBuyerLocationEnum from '../enums/DealProfileEnums';
import DeleteFromWebflowTypeEnum from '../enums/DeleteFromWebflowTypeEnum';

export const listing = {
  _id: () => Joi.string(),
  name: () => Joi.string(),
  description: () => Joi.string(),
  state: () => Joi.string().valid(...Object.values(ListingStateEnum)),
  assetClass: () => Joi.string(),
  propertyType: () => Joi.string(),
  score: () => Joi.number(),
  address: () => addressSchema,
  requiredAddress: () => requiredAddressSchema,
  schedule: () => listingSchedule,
  media: () => listingMedia,
  guidance: () => listingGuidance,
  highlights: () => Joi.array().items(Joi.string()),
  info: () => Joi.array().items(listingInfoField),
  vault: () => listingVault,
  license: () => listingLicense,
  contacts: () => Joi.array().items(listingContact),
  listedByOwner: () => Joi.boolean(),
  isPrivateListing: () => Joi.boolean(),
  isPortfolioListing: () => Joi.boolean(),
  portfolioAddresses: () => Joi.array().items(addressSchema),
  dealProfile: () => listingDealProfile,
};

const mapLatLng = Joi.object().keys({
  lat: Joi.number().required(),
  lng: Joi.number().required(),
});

const mapBounds = Joi.object().keys({
  ne: mapLatLng.optional().allow(null),
  nw: mapLatLng.optional().allow(null),
  se: mapLatLng.optional().allow(null),
  sw: mapLatLng.optional().allow(null),
});

const minMax = Joi.object().keys({
  min: Joi.alternatives().try(Joi.number(), Joi.string()).allow(null).allow(''),
  max: Joi.alternatives().try(Joi.number(), Joi.string()).allow(null).allow(''),
});

const listingSchedule = Joi.object().keys({
  expiresAt: Joi.date().optional().allow(null).allow(''),
  publishAt: Joi.date().optional().allow(null).allow(''),
  /** The two fields below allow successful data validation of listings scheduled for publish tasks */
  expirationTaskId: Joi.number().optional().allow(null).allow(''),
  publishTaskId: Joi.number().optional().allow(null).allow(''),
});

const video = Joi.object().keys({
  fileId: Joi.string().optional().allow(null).allow(''),
  // needed for backwards compatibility. Should delete this field everywhere in each database then delete this
  thumbnailFileId: Joi.string().optional().allow(null, ''),
});

const listingMedia = Joi.object().keys({
  fileIds: Joi.array().items(Joi.string()).required(),
  video,
});

const listingGuidance = Joi.object().keys({
  priced: Joi.boolean().required().allow(null),
  askingPrice: Joi.number().required().allow(null),
  depositAmount: Joi.number().required().allow(null),
  brokerCommision: Joi.string().valid(...Object.values(BrokerCommisionEnum))
    .optional().allow(null)
    .allow(''),
  brokerCommisionAmount: Joi.number().required().allow(null),
  dueDiligencePeriod: Joi.number().required().allow(null),
  closingPeriod: Joi.number().required().allow(null),
  callForOffersAt: Joi.number().required().allow(null),
});

const listingInfoField = Joi.object().keys({
  _id: Joi.string().optional(),
  fieldName: Joi.string().required(),
  value: Joi.any(),
  unit: Joi.string().optional()
    .allow(null)
    .allow(''),
});

const listingVaultSubFolder = Joi.object().keys({
  name: Joi.string().regex(/^((?!Untitled).)*$/).required().error(([error]) => JoiUtil.encodeError('Please name all of your folders', error)),
  fileIds: Joi.array().items(Joi.string()).optional(),
  subFolders: Joi.array().optional(),
  subFolderId: Joi.string().optional(),
});

const listingVaultFolder = Joi.object().keys({
  name: Joi.string().valid(...Object.values(ListingVaultFolderNamesEnum)).optional(),
  fileIds: Joi.array().items(Joi.string()).optional(),
  _id: Joi.string().optional().allow(null),
  subFolderId: Joi.string().optional().allow(null),
  subFolders: Joi.array().items(listingVaultSubFolder).optional().allow(null),
});

const listingVault = Joi.object().keys({
  zipFileId: Joi.string().optional().allow(null).allow(''),
  caFileId: Joi.string().optional().allow(null).allow(''),
  zipFileLevel2Id: Joi.string().optional().allow(null).allow(''),
  folders: Joi.array().items(listingVaultFolder).optional(),
});

export const listingContact = Joi.object().keys({
  _id: Joi.string().required(),
  name: Joi.string().required(),
  email: Joi.string().required(),
  phoneNumber: Joi.string().required(),
  company: Joi.string().optional().allow(null).allow(''),
  title: Joi.string().optional().allow(null).allow(''),
  license: Joi.string().optional().allow(null).allow(''),
  profileImageUrl: Joi.string().optional().allow(null).allow(''),
});

const listingLicense = Joi.object().keys({
  _id: Joi.string().optional().allow(null).allow(''),
  owner: Joi.string().required(),
  state: Joi.string().regex(/^[A-Z]{2}$/).required(),
  number: Joi.string().required(),
});

export const listingDealProfile = Joi.object().keys({
  buyerLocation: Joi.string().valid(...Object.values(DealProfileBuyerLocationEnum))
    .required().error(([error]) => JoiUtil.encodeError('Please select an investor location', error)),
  investmentType: Joi.array().items(Joi.string().valid(...Object.values(PreferredInvestmentTypeEnum))).min(1)
    .required()
    .error(([error]) => JoiUtil.encodeError('Please select an investment type', error)),
  regionalPreference: Joi.any()
    .when('buyerLocation', { is: DealProfileBuyerLocationEnum.RegionalInvestor, then: Joi.string().valid(...Object.values(RegionalFocusEnum)).required().error(([error]) => JoiUtil.encodeError('Please select a region', error)) })
    .when('buyerLocation', { is: !DealProfileBuyerLocationEnum.RegionalInvestor, then: Joi.any().optional().allow(null).allow('') }),
}).allow(null);

/** ******************************************************************************
*  Create Listing
******************************************************************************* */

export const createListingParamsSchema = Joi.object().keys({
  _id: listing._id().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  name: listing.name().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  description: listing.description().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  assetClass: listing.assetClass().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  propertyType: listing.propertyType().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  score: listing.score().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  state: listing.state().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  address: listing.address().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  schedule: listing.schedule().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  media: listing.media().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  guidance: listing.guidance().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  highlights: listing.highlights().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  info: listing.info().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  vault: listing.vault().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  license: listing.license().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  listedByOwner: Joi.boolean().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  contacts: listing.contacts().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  isPrivateListing: listing.isPrivateListing().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  isPortfolioListing: listing.isPortfolioListing().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  portfolioAddresses: listing.portfolioAddresses()
    .when('isPortfolioListing', { is: true, then: listing.portfolioAddresses().required().min(2).error(([error]) => JoiUtil.encodeError('Portfolio listings need at least two addresses', error)) })
    .when('isPortfolioListing', { is: false, then: listing.portfolioAddresses().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)) }),
  dealProfile: listing.dealProfile().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
}).prefs({
  abortEarly: false,
});

export const createListingRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: createListingParamsSchema,
});

/** ******************************************************************************
*  Update Listing
******************************************************************************* */

export const updateListingParamsSchema = Joi.object().keys({
  _id: Joi.any(), //  listing._id().error(([error]) => JoiUtil.encodeError('Required', error)),
  name: Joi.any(), //  listing.name().error(([error]) => JoiUtil.encodeError('Required', error)),
  description: listing.description().error(([error]) => JoiUtil.encodeError('Description Required', error)),
  assetClass: listing.assetClass().error(([error]) => JoiUtil.encodeError('Asset Class Required', error)),
  propertyType: listing.propertyType().error(([error]) => JoiUtil.encodeError('Property Type Required', error)),
  score: Joi.any(), //  listing.propertyType().error(([error]) => JoiUtil.encodeError('Required', error)),
  address: listing.requiredAddress().error(([error]) =>
    /**
     * Each field on the address validation schema
     * specifies its own error message
     * that is propogated back to the front end.
     */
    JoiUtil.encodeError(error.message, error)),
  schedule: Joi.any(), //  listing.schedule().error(([error]) => JoiUtil.encodeError('Required', error)),
  media: Joi.object().keys({
    fileIds: Joi.array().items(Joi.string()).min(1).required(),
    video,
  }).error(([error]) => JoiUtil.encodeError('At Least One Image Required', error)),
  guidance: Joi.any(), //  listing.guidance().error(([error]) => JoiUtil.encodeError('Required', error)),
  highlights: Joi.any(), //  listing.highlights().error(([error]) => JoiUtil.encodeError('Required', error)),
  info: Joi.any(), //  listing.info().error(([error]) => JoiUtil.encodeError('Required', error)),
  vault: listing.vault().optional(), //  listing.vault().error(([error]) => JoiUtil.encodeError('Required', error)),
  state: Joi.any(), // listing.state().error(([error]) =. Joiutil.encodeError('Required', error));
  contacts: Joi.any(),
  dealProfile: listing.dealProfile().optional(),
  license: Joi.any()
    .when('listedByOwner', { is: true, then: Joi.any().optional().allow(null).allow('') })
    .when('listedByOwner', { is: false, then: listing.license().error(([error]) => JoiUtil.encodeError('License Required', error)) }),
  listedByOwner: Joi.boolean().optional().allow(null),
  isPrivateListing: listing.isPrivateListing().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  isPortfolioListing: listing.isPortfolioListing().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  portfolioAddresses: listing.portfolioAddresses()
    .when('isPortfolioListing', { is: true, then: listing.portfolioAddresses().required().min(2).error(([error]) => JoiUtil.encodeError('Portfolio listings need at least two addresses', error)) })
    .when('isPortfolioListing', { is: false, then: listing.portfolioAddresses().optional().error(([error]) => JoiUtil.encodeError('Invalid', error)) }),
}).prefs({
  abortEarly: false,
});

export const updateListingRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: updateListingParamsSchema,
});

/** ******************************************************************************
 * Update Listing Contact
 * ****************************************************************************** */
export const updateListingContactParamsSchema = Joi.object().keys({
  userId: Joi.string().required().error(([error]) => JoiUtil.encodeError('User ID Required', error)),
  name: Joi.string().optional().allow(null),
  email: Joi.string().optional().allow(null),
  phoneNumber: Joi.string().optional().allow(null),
  company: Joi.string().optional().allow(null),
});

export const updateListingContactRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: updateListingContactParamsSchema,
});

/** ******************************************************************************
*  Update Draft
******************************************************************************* */

export const updateDraftParamsSchema = Joi.object().keys({
  _id: Joi.any(),
  name: Joi.any().optional(),
  description: Joi.any().optional(),
  assetClass: Joi.any().optional(),
  propertyType: Joi.any().optional(),
  score: Joi.any().optional(),
  address: Joi.any().optional(),
  schedule: Joi.any().optional(),
  media: Joi.any().optional(),
  guidance: Joi.any().optional(),
  highlights: Joi.any().optional(),
  info: Joi.any().optional(),
  vault: Joi.any().optional(),
  license: Joi.any().optional(),
  state: Joi.any().optional(),
  contacts: Joi.any().optional(),
  dealProfile: Joi.any().optional(),
  listedByOwner: Joi.any().optional(),
  isPrivateListing: listing.isPrivateListing().optional(),
  isPortfolioListing: listing.isPortfolioListing().optional(),
  portfolioAddresses: listing.portfolioAddresses().optional(),
}).prefs({
  abortEarly: false,
});

export const updateDraftRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: updateDraftParamsSchema,
});

/** ******************************************************************************
*  Publish Listing
******************************************************************************* */

export const publishListingSchema = Joi.object().keys({
  _id: listing._id().error(([error]) => JoiUtil.encodeError('ID Required', error)),
  name: listing.name().optional().allow('').allow(null)
    .error(([error]) => JoiUtil.encodeError('Name Required', error)),
  description: listing.description().error(([error]) => JoiUtil.encodeError('Description Required', error)),
  assetClass: listing.assetClass().error(([error]) => JoiUtil.encodeError('Asset Class Required', error)),
  score: listing.score().optional().allow(null),
  propertyType: listing.propertyType().error(([error]) => JoiUtil.encodeError('Property Type Required', error)),
  address: listing.requiredAddress().error(([error]) =>
    /**
     * Each field on the address validation schema
     * specifies its own error message
     * that is propogated back to the front end.
     */
    JoiUtil.encodeError(error.message, error)),
  schedule: listing.schedule().error(([error]) => JoiUtil.encodeError('Schedule Required', error)),
  media: Joi.object().keys({
    fileIds: Joi.array().items(Joi.string()).min(1).required(),
    video,
  }).error(([error]) => JoiUtil.encodeError('Media Required', error)),
  guidance: listing.guidance().error(([error]) => JoiUtil.encodeError('Guidance Required', error)),
  highlights: listing.highlights().error(([error]) => JoiUtil.encodeError('Highlights Required', error)),
  info: listing.info().error(([error]) => JoiUtil.encodeError('Info Required', error)),
  vault: Joi.object().keys({
    zipFileId: Joi.string().optional().allow(null).allow(''), // TODO -- maybe required?
    caFileId: Joi.string().optional().allow(null).allow(''),
    zipFileLevel2Id: Joi.string().optional().allow(null).allow(''),
    folders: Joi.array().optional().items(listingVaultFolder),
  }).error(([error]) => JoiUtil.encodeError('Vault Required', error)),
  license: Joi.any()
    .when('listedByOwner', { is: true, then: Joi.any().optional().allow(null).allow('') })
    .when('listedByOwner', { is: false, then: listing.license().error(([error]) => JoiUtil.encodeError('License Required', error)) }),
  contacts: listing.contacts().error(([error]) => JoiUtil.encodeError('Contacts Required', error)),
  dealProfile: listing.dealProfile(),
  listedByOwner: Joi.boolean().optional().allow(null),
  state: Joi.any(), // listing.state().error(([error]) =. Joiutil.encodeError('Required', error));
  isPrivateListing: listing.isPrivateListing().optional(),
  isPortfolioListing: listing.isPortfolioListing().optional(),
  portfolioAddresses: listing.portfolioAddresses()
    .when('isPortfolioListing', { is: true, then: listing.portfolioAddresses().required().min(2).error(([error]) => JoiUtil.encodeError('Portfolio listings need at least two addresses', error)) })
    .when('isPortfolioListing', { is: false, then: listing.portfolioAddresses().optional() }),
}).prefs({
  abortEarly: false,
});

export const publishListingParamsSchema = Joi.object().keys({
  listing: publishListingSchema,
  publishAt: Joi.number().allow(null),
  expiresAt: Joi.number().allow(null),
  isPublishTask: Joi.boolean().optional().allow(null),
}).prefs({
  abortEarly: false,
});

export const publishListingRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: publishListingParamsSchema,
});

/** ******************************************************************************
*  Update Investor Match
******************************************************************************* */

export const updateInvestorMatchesParamsSchema = Joi.object().keys({
  listingId: Joi.string().optional(),
  matchTotal: Joi.number().optional(),
}).prefs({
  abortEarly: false,
});

export const updateInvestorMatchesRequestSchema = Joi.object().keys({
  auth: authorizedOptionalDataSchema,
  params: updateInvestorMatchesParamsSchema,
});

/** ******************************************************************************
*  Update Investor Match
******************************************************************************* */

export const checkInvestorMatchesParamsSchema = Joi.object().keys({
  listingId: Joi.string().optional(),
}).prefs({
  abortEarly: false,
});

export const checkInvestorMatchesRequestSchema = Joi.object().keys({
  auth: authorizedOptionalDataSchema,
  params: checkInvestorMatchesParamsSchema,
});

/** ******************************************************************************
*  Delete Listing
******************************************************************************* */

export const deleteListingParamsSchema = Joi.object().keys({
  listingId: Joi.string().required(),
}).prefs({
  abortEarly: false,
});

export const deleteListingRequestSchema = Joi.object().keys({
  auth: authorizedOptionalDataSchema,
  params: deleteListingParamsSchema,
});

/** ******************************************************************************
*  Sign Listing CA
******************************************************************************* */

export const signListingCAParamsSchema = Joi.object().keys({
  listingId: Joi.string(),
  userFullName: Joi.string(),
}).prefs({
  abortEarly: false,
});

export const signListingCARequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: signListingCAParamsSchema,
});

/** ******************************************************************************
 * Request Private Listing Access
 * ****************************************************************************** */

export const requestPrivateListingAccessParams = Joi.object().keys({
  listingId: listing._id().required(),
});

export const requestPrivateListingAccessSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: requestPrivateListingAccessParams,
});

/** ******************************************************************************
*  Set Listing State
******************************************************************************* */

export const setListingStateParamsSchema = Joi.object().keys({
  listingId: Joi.string(),
  state: Joi.string().valid(...[
    ListingStateEnum.Draft,
    ListingStateEnum.Active,
    ListingStateEnum.Closed,
    ListingStateEnum.Inactive,
    ListingStateEnum.UnderContract,
    ListingStateEnum.Scheduled,
    ListingStateEnum.Expired,
    ListingStateEnum.Admin,
    ListingStateEnum.ReadyForReview,
    ListingStateEnum.Private,
  ]).required(),
  publishAt: Joi.number().allow(null),
  expiresAt: Joi.number().allow(null),
}).prefs({
  abortEarly: false,
});

export const setListingStateRequestSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: setListingStateParamsSchema,
});

/** ******************************************************************************
*  List Listings
******************************************************************************* */

export const miscellaneousListingQueryParamsSchema = Joi.object().keys({
  brokerCoOpAvailable: Joi.boolean(),
  netLease: Joi.boolean(),
  creditTenant: Joi.boolean(),
  offMarket: Joi.boolean(),
  forSaleByOwner: Joi.boolean(),
  isPortfolio: Joi.boolean(),
});

const listingQueryParamsSchema = Joi.object().keys({
  queryType: Joi.string().valid(...Object.values(ListingQueryTypesEnum)).optional(), // REMINDER: change this back to required once saved search is fully decoupled from mf listings
  assetClasses: Joi.array().items(Joi.string().valid(...Object.values(ListingAssetClassEnum))).optional().allow(null),
  propertyTypes: Joi.array().items(Joi.string().valid(...Object.values(ListingPropertyTypeEnum))).optional().allow(null),
  keywords: Joi.array().items(Joi.string().optional().allow('', null)).optional(),
  price: Joi.object().keys({
    includeUndefined: Joi.boolean().allow(null),
    min: Joi.number().allow(null),
    max: Joi.number().allow(null),
  }).optional().allow(null),
  buildingSize: minMax.optional().allow(null),
  capRate: minMax.optional().allow(null),
  occupancyRate: minMax.optional().allow(null),
  sortBy: Joi.string().valid(...Object.values(ListingQuerySortByEnum)).allow(null),
  listingIds: Joi.array().items(Joi.string()).allow(null).default(null),
  state: Joi.string().allow(null),
  address: addressSchema.optional().allow(null),
  miscellaneous: miscellaneousListingQueryParamsSchema.optional().allow(null),
  bounds: mapBounds.optional().allow(null),
  isPrivateListing: listing.isPrivateListing().optional().allow(null),
  isPortfolioListing: listing.isPortfolioListing().optional().allow(null),
  afterDate: Joi.number().optional().allow(null),
  brokerUserId: Joi.string().optional().allow(null),
});

export const listListingsParamsSchema = Joi.object().keys({
  pagination: paginationSchema.optional(),
  query: listingQueryParamsSchema,
}).prefs({
  abortEarly: false,
});

export const listListingsRequestSchema = Joi.object().keys({
  auth: authorizedOptionalDataSchema,
  params: listListingsParamsSchema,
});

/** ******************************************************************************
*  Add Listing Contact (Front-end Schema)
******************************************************************************* */

export const addOrEditListingContactFrontendSchema = Joi.object().keys({
  _id: Joi.string().required().error(([error]) => JoiUtil.encodeError('_id required', error)),
  name: Joi.string().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  email: Joi.string().email({ tlds: { allow: false } }).error(([error]) => JoiUtil.encodeError('Invalid email', error)),
  phoneNumber: Joi.string().regex(/\d+/).required().error(([error]) => JoiUtil.encodeError('Invalid phone number', error)),
  company: Joi.string().optional().allow('').allow(null),
  title: Joi.string().optional().allow('').allow(null),
  licenseNumber: Joi.string().optional().allow('').allow(null),
  licenseState: Joi.when('licenseNumber', {
    is: Joi.string(),
    then: Joi.string().required().error(([error]) => JoiUtil.encodeError('Required', error)),
    otherwise: Joi.any().allow(null),
  }),
}).prefs({
  abortEarly: false,
});

/** ******************************************************************************
 * Push Listing to Webflow
 * ****************************************************************************** */

export const pushListingToWebflowParams = Joi.object().keys({
  listingId: listing._id().required(),
  isAutomated: Joi.boolean().optional().allow(null),
  customCollectionId: Joi.string().optional().allow(null, ''),
});

export const pushListingToWebflowSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: pushListingToWebflowParams,
});

/** ******************************************************************************
 * Delete Listing from Webflow
 * ****************************************************************************** */

export const deleteListingFromWebflowParams = Joi.object().keys({
  listingId: listing._id().required(),
  type: Joi.string().valid(...Object.values(DeleteFromWebflowTypeEnum)),
  customCollectionId: Joi.string().optional().allow(null, ''),
});

export const deleteListingFromWebflowSchema = Joi.object().keys({
  auth: authorizedDataSchema,
  params: deleteListingFromWebflowParams,
});
