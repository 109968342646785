import { config } from '@biproxi/env';
import { TOAuthAuthenticateUserResponse } from '../services/IUserService';
import AuthenticationStrategyEnum from '../enums/AuthenticationStrategyEnum';
import * as IUserService from '../services/IUserService';

/** ******************************************************************************
*  Helpers
******************************************************************************* */

export const authenticationStrategyOAuthPath = (strategy: AuthenticationStrategyEnum): string => {
  switch (strategy) {
    // Google
    case AuthenticationStrategyEnum.GoogleRegister:
      return fullPath(GOOGLE_REGISTER_PATH);
    case AuthenticationStrategyEnum.GoogleLogin:
      return fullPath(GOOGLE_LOGIN_PATH);
    // LinkedIn
    case AuthenticationStrategyEnum.LinkedInRegister:
      return fullPath(LINKEDIN_REGISTER_PATH);
    case AuthenticationStrategyEnum.LinkedInLogin:
      return fullPath(LINKEDIN_LOGIN_PATH);

    default:
      return fullPath(GOOGLE_REGISTER_PATH);
  }
};

export const fullPath = (path: string): string => `${config.NEXT_PUBLIC_API_URL}/authentication${path}`;

export const FRAME_DATA_SOURCE = 'biproxi';

export const buildOAuthWindowResponse = (response: IUserService.TOAuthAuthenticateUserResponse): string => {
  const {
    user,
    token,
    hubspotToken,
    error,
  } = response;

  const paramsJSON = JSON.stringify({
    user,
    token,
    hubspotToken,
    error,
    source: FRAME_DATA_SOURCE,
  });

  // single api deployment can serve multiple domains
  // can un-wildcard if this is deployed for each domain
  return `<script>window.opener.postMessage(${paramsJSON}, '*'); </script>`;
};

/** ******************************************************************************
*  Google OAuth URLS
******************************************************************************* */

export const GOOGLE_LOGIN_PATH = '/login/google';
export const GOOGLE_LOGIN_CALLBACK_PATH = '/login/google/callback';
export const GOOGLE_REGISTER_PATH = '/register/google';
export const GOOGLE_REGISTER_CALLBACK_PATH = '/register/google/callback';

/** ******************************************************************************
*  LinkedIn OAuth URLS
******************************************************************************* */

export const LINKEDIN_LOGIN_PATH = '/login/linkedin';
export const LINKEDIN_LOGIN_CALLBACK_PATH = '/login/linkedin/callback';
export const LINKEDIN_REGISTER_PATH = '/register/linkedin';
export const LINKEDIN_REGISTER_CALLBACK_PATH = '/register/linkedin/callback';

/** ******************************************************************************
*  OAuth Window Management
******************************************************************************* */

let windowObjectReference: Window | null = null;
let previousUrl: string | null = null;

type OAuthMessageData = TOAuthAuthenticateUserResponse & { source: string };
type OAuthMessage = MessageEvent<OAuthMessageData>;

const receiveMessage = (event: OAuthMessage): OAuthMessageData | null => {
  if (event.origin !== config.NEXT_PUBLIC_API_URL) {
    return null;
  }

  if (event?.data?.source !== FRAME_DATA_SOURCE) {
    return null;
  }

  return event?.data ?? null;
};

let previousEventHandler: ((event: OAuthMessage) => void) | null = null;

export const openOAuthWindow = (url: string, name: string): Promise<OAuthMessageData> => new Promise((resolve) => {
  if (previousEventHandler) {
    window.removeEventListener('message', previousEventHandler);
    previousEventHandler = null;
  }

  const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

  if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousUrl !== url) {
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference?.focus();
  } else {
    windowObjectReference.focus();
  }

  previousEventHandler = (event: OAuthMessage): void => {
    const data = receiveMessage(event);

    if (data?.user || data?.error) {
      windowObjectReference?.close();

      resolve(data);
    }
  };

  window.addEventListener('message', previousEventHandler, false);

  previousUrl = url;
});

export const closeOAuthWindow = (): boolean => {
  windowObjectReference?.close();
  const closed = windowObjectReference?.closed ?? true;
  windowObjectReference = null;
  return closed;
};
