/* eslint-disable no-undef */
import React from 'react';
import styled from '@emotion/styled';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import GoogleMap from './GoogleMap';
import ListingMapMarker from './ListingMapMarker';
import { useAppDispatch } from '../redux/store';
import Loader, { LoaderSizes } from '../elements/Loader';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import { AppActions } from '../redux/app.redux';
import { useMobileMedia } from '../utils/MediaQuery';
import SquareIconButton from '../elements/SquareIconButton';
import Icons from '../elements/Icons';
import { SRPLayoutEnum } from '../spa-pages/Search.page';
import { MapOptions } from '../models/interfaces/IGoogleMaps';

type ContainerProps = {
  srpLayoutState: SRPLayoutEnum;
  'data-cy'?: string;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  height: 100%;
`;

const LoadingContainer = styled.div`
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.White};
  position: absolute;
  z-index: 205;
  top: 24px;
  left: calc((100% - 94px)/2);
`;

const MobileToggleContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export type SearchListingsMapProps = {
  listings: IListingGraphQL[] | [];
  loading: boolean;
  bounds: any;
  srpLayoutState?: SRPLayoutEnum;
  setSRPLayoutState?: React.Dispatch<React.SetStateAction<SRPLayoutEnum>>;
  mapOptions?: MapOptions;
};

const SearchListingsMap: React.FC<SearchListingsMapProps> = ({
  listings,
  loading,
  bounds,
  srpLayoutState,
  setSRPLayoutState,
  mapOptions,
}) => {
  /** Hooks */
  const isMobile = useMobileMedia();

  /** State */
  const [map, setMap] = React.useState<google.maps.Map>(null);
  const [mapLoaded, setMapLoaded] = React.useState(false);

  const markers = listings.map((listing: IListingGraphQL) => (
    <ListingMapMarker
      lat={AddressUtil.getLatitudeAsFloat(listing.address)}
      lng={AddressUtil.getLongitudeAsFloat(listing.address)}
      key={listing._id}
      listing={listing}
    />
  ));

  /** Actions */
  const dispatch = useAppDispatch();

  const closeMobileMap = () => dispatch(
    AppActions.toggleMobileSRPMapOpen(false),
  );

  const handleMapResize = () => {
    const googleBounds = new google.maps.LatLngBounds(bounds.sw, bounds.ne);
    map.fitBounds(googleBounds);
  };

  /** Effects */
  React.useEffect(() => {
    if (map && bounds) {
      handleMapResize();
    }
  }, [map, bounds, mapLoaded]);

  /** Map Event Handlers */
  const onLoad = (loadedMap) => {
    setMap(loadedMap.map);
    setTimeout(() => setMapLoaded(true), 500); // hack to trigger useEffect above. If we don't set timeout, effect will be called before the map state is completely set, thus the map wont be resized
  };

  /** Render */
  return (
    <Container data-cy="search-listings-map" srpLayoutState={srpLayoutState}>
      {(() => (
        <>
          {loading && (
          <LoadingContainer>
            <Loader size={LoaderSizes.ExtraSmall} color={Colors.Brand700 || Colors.Blurple700} />
            <Text type={TextTypesEnum.Medium12} color={Colors.Grey900} margin="0 0 0 4px">Loading...</Text>
          </LoadingContainer>
          )}
          <GoogleMap
            width="100%"
            height="100%"
            onLoad={(map) => onLoad(map)}
            options={mapOptions}
          >
            {markers}
          </GoogleMap>
        </>
      ))()}
      {(isMobile && !mapOptions?.disableMobileControls) && (
        <MobileToggleContainer>
          <SquareIconButton
            icon={Icons.ThListSolid}
            onClick={closeMobileMap}
          />
        </MobileToggleContainer>
      )}
      {(!isMobile && mapOptions?.srpFullScreenControls) && (
        <SquareIconButton
          icon={srpLayoutState === SRPLayoutEnum.Half ? Icons.ExpandWideRegular : Icons.CompressWide}
          iconColor={Colors.Brand700 || Colors.Blurple700}
          onClick={() => setSRPLayoutState(srpLayoutState === SRPLayoutEnum.Half ? SRPLayoutEnum.Map : SRPLayoutEnum.Half)}
          position="absolute"
          top="24px"
          left="24px"
        />
      )}
    </Container>
  );
};

export default React.memo(SearchListingsMap);
