enum AnalyticsEventTypesEnum {
  ViewedListing = 'Viewed Listing',
  SignedCA = 'Signed CA',
  SentMessage = 'Sent Message',
  ViewedDueDiligenceDocument = 'Viewed Due Diligence Document',
  DownloadedDueDiligenceDocument = 'Downloaded Due Diligence Document',
  DownloadedDueDiligenceVault = 'Downloaded Due Diligence Vault',
  MadeOffer = 'Made Offer',
  CreatedListing = 'Created Listing',
  ScheduledListing = 'Scheduled Listing',
  PublishedListing = 'Published Listing',
  ReceivedCA = 'Received CA',
  ReceivedOffer = 'Received Offer',
  ReceivedMessage = 'Received Message',
  ReceivedLead = 'Received Lead',
  BecameLead = 'Became Lead',
  CompletedInvestorProfile = 'Completed Investor Profile',
  RemovedListing = 'Removed Listing', // todo -- once the new listing status work is complete
  CompletedBrokerProfile = 'Completed Broker Profile', // todo -- once broker profile work is complete
  ViewedDataExplorer = 'Viewed Data Explorer',
  CreatedPrivateEvent = 'Created Private Event',
  SavedSearch = 'Saved Search',
  Became1031Investor = 'Became 1031 Investor',
  SubscribedToBrokerProMonthy = 'Subscribed to Broker Pro monthly plan',
  SubscribedToBrokerProAnnual = 'Subscribed to Broker Pro annual plan',
  ViewSubscriptionModal = 'Viewed subscription modal'
}

export default AnalyticsEventTypesEnum;
