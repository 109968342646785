import React from 'react';
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useMobileMedia } from '../utils/MediaQuery';

export type CropImageProps = {
  imageUrl: string;
  mimetype: string;
  aspect?: number;
  imageWidth?: string;
  onError?: () => void;
  // The blob is the new croppedImage, it will be set when the crop is executed. Do what you want with it.
  // This function is used by parent component to set the blob for the cropped image.
  setBlob: (blob: Blob) => void;
};

type CropImageHandle = {
  executeCrop: () => void,
}

/**
 * This component takes in and image then
 * allows the user to crop it with a certain aspect
 * and will then set the blob of the new cropped image
 *  in the parent component for you to do with as you wish.
 */
const CropImage: React.ForwardRefRenderFunction<CropImageHandle, CropImageProps> = ({
  imageUrl,
  mimetype,
  aspect = 16 / 9,
  imageWidth = '724px',
  onError,
  setBlob,
}, ref) => {
  /* State */
  const [crop, setCrop] = React.useState<Crop>();
  const [image, setImage] = React.useState<HTMLImageElement>();

  /**
   * Execute the crop from the parent component with
   * ref.currentTarget.executeCrop using the forwarded ref.
   */
  React.useImperativeHandle(ref, () => ({
    async executeCrop() {
      if (image && crop?.width && crop?.height) {
        const croppedImageBlob = await generateImageFromCrop(
          image,
          crop,
        );
        setBlob(croppedImageBlob as Blob);
      }
    },
  }));

  /** Effects */
  React.useEffect(() => {
    /**
     * Load the image into state as an Image.
     */
    const inputImage = new Image();
    inputImage.onload = () => {
      setImage(inputImage);
    };
    inputImage.crossOrigin = 'anonymous';
    inputImage.src = imageUrl;
  }, []);

  /**
   * React to aspect ratio changes and set
   * intial crop.
   */
  React.useEffect(() => {
    const height = image?.height;
    const width = image?.width;
    if (height && width) {
      const crop = centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 80,
          },
          aspect,
          width,
          height,
        ),
        width,
        height,
      );
      setCrop(crop);
    }
  }, [aspect, image]);

  /**
   * Generate the cropped image using the dimensions
   * from the crop using the canvas. The math in this ensures
   * that natural size and actual size of the image in the view are not important.
   */
  const generateImageFromCrop = (image: HTMLImageElement, crop: Crop) => {
    const cropWidth = image.naturalWidth * (crop.width / 100);
    const cropHeight = image.naturalHeight * (crop.height / 100);
    const startX = image.naturalWidth * (crop.x / 100);
    const startY = image.naturalHeight * (crop.y / 100);
    const canvas = document.createElement('canvas');
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      startX,
      startY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob: any) => {
        if (!blob) {
          onError();
          return;
        }
        resolve(blob);
      }, mimetype);
    });
  };

  const isMobile = useMobileMedia();

  /** Render */
  return (
    <ReactCrop
      crop={crop}
      aspect={aspect}
      minHeight={100}
      minWidth={100}
      // percentCrop is important here to ensure resizing the image in the view doesn't matter.
      onChange={(_, percentCrop) => setCrop(percentCrop)}
    >
      {image && (
        // if the image is taller than the width
        // reverse the autos so it never is too big for the screen
        // ..except on mobile because the image might warp
        <>
          {isMobile ? (
            <img
              src={imageUrl}
              width={imageWidth}

            />
          ) : (
            <img
              src={imageUrl}
              width={image?.width >= image?.height ? imageWidth : 'auto'}
              height={image?.width < image?.height ? '600px' : 'auto'}
            />
          )}
        </>
      )}
    </ReactCrop>
  );
};

export default React.forwardRef(CropImage);
