import React from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  useMutation, useQuery, OperationVariables, ApolloQueryResult,
} from '@apollo/client';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import * as SaveSearchJoi from '@biproxi/models/joi/savedSearch.joi';
import ISavedSearchParams from '@biproxi/models/interfaces/ISavedSearchParams';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import IAddress from '@biproxi/models/interfaces/IAddress';
import IListingQuery from '@biproxi/models/interfaces/IListingQuery';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import ListingAssetClassEnum from '@biproxi/models/enums/ListingAssetClassEnum';
import ListingPropertyTypeEnum from '@biproxi/models/enums/ListingPropertyTypeEnum';
import IListingsQuery from '@biproxi/models/interfaces/IListingsQuery';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import CREATE_SAVED_SEARCH from '../../graphql/mutations/createSavedSearch.mutation';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';
import { AppActions } from '../../redux/app.redux';
import { media, useMobileMedia, useTabletMedia } from '../../utils/MediaQuery';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import Input from '../../elements/Input';
import Divider from '../../elements/Divider';
import AutoCompleteAddress from '../AutoCompleteAddress';
import LIST_LISTINGS from '../../graphql/queries/listings.query';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Checkbox, { CheckboxTypesEnum } from '../../elements/Checkbox';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { ModalTypesEnum } from './Modal';
import { ConfirmChangeModalTypesEnum } from './ConfirmChangeModal';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import UPDATE_SAVED_SEARCH from '../../graphql/mutations/updateSavedSearch.mutation';
import AssetClassAndPropertyTypeFilter from '../AssetClassAndPropertyTypeFilter';
import PriceFilter from '../PriceFilter';
import KeywordFilter from '../KeywordFilter';
import ListingFilterInputSection from '../ListingFilterInputSection';
import { IListingFilterInputSection } from './ListingFiltersModal';
import PaginationLimitsEnum from '../../models/enums/PaginationLimitsEnum';
import SearchListingsMap from '../SearchListingsMap';
import getPropertyBounds from '../../utils/PropertyBoundsUtil';

const Container = styled.div`
    width: 611px;
    height: 100%;
    overflow: auto;

    ${media.mobile} {
      width: 100%;
    }
`;

const PriceContainer = styled.div`
  width: 100%;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 256px;
`;

const AddressContainer = styled.div`
  margin-bottom: 16px;
`;

const MiscellaneousContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export enum SaveSearchModalTypeEnum {
  Create = 'Create',
  Update = 'Update',
}

enum SaveSearchModalLocations {
  SRP = 'SRP',
  SavedSearchPage = 'SaveSearchPage'
}

export type SaveSearchModalProps = {
  refetchSavedSearches?: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<any>>, // only used when a user creates a saved search since we need a way for the new saved search to appear on the page once they create it from that page
  type: SaveSearchModalTypeEnum
};

const SaveSearchModal: React.FC<SaveSearchModalProps> = ({
  refetchSavedSearches,
  type = SaveSearchModalTypeEnum.Create,
}) => {
  /** State */
  const savedSearchFilterData: any = useAppSelector(ListingSelectors.savedSearchQuery);
  const location = useLocation();
  const currentPage = location.pathname === '/app/dashboard/search' ? SaveSearchModalLocations.SRP : SaveSearchModalLocations.SavedSearchPage; // currently, the save search modal only exists within two pages, otherwise a switch statement with a default value would probs be best for something like this

  const cachedAddress: IAddress = savedSearchFilterData?.address;
  const cachedQuery: IListingQuery = savedSearchFilterData?.query;

  const savedSearchId = type === SaveSearchModalTypeEnum.Create ? '' : savedSearchFilterData?._id;

  const [savedSearchFields, setSavedSearchFields] = React.useState<ISavedSearchParams>({
    _id: savedSearchId ?? '',
    name: type === SaveSearchModalTypeEnum.Update ? savedSearchFilterData?.name : '',
    notifications: type === SaveSearchModalTypeEnum.Update ? savedSearchFilterData?.notifications : true,
    address: cachedAddress ?? null,
    query: {
      queryType: ListingQueryTypesEnum.Active,
      assetClasses: cachedQuery?.assetClasses ?? [],
      propertyTypes: cachedQuery?.propertyTypes ?? [],
      address: cachedQuery?.address ?? null,
      price: {
        min: cachedQuery?.price?.min ?? null,
        max: cachedQuery?.price?.max ?? null,
        includeUndefined: cachedQuery?.price?.includeUndefined ?? true,
      },
      keywords: cachedQuery?.keywords ?? [],
      buildingSize: cachedQuery?.buildingSize ?? {
        min: null,
        max: null,
      },
      capRate: cachedQuery?.capRate ?? {
        min: null,
        max: null,
      },
      occupancyRate: cachedQuery?.occupancyRate ?? {
        min: null,
        max: null,
      },
      miscellaneous: cachedQuery?.miscellaneous ?? {
        brokerCoOpAvailable: false,
        netLease: false,
        creditTenant: false,
        offMarket: false,
        forSaleByOwner: false,
        isPortfolio: false,
      },
      sortBy: null,
      listingIds: [],
      bounds: cachedQuery?.bounds,
      state: cachedQuery?.state,
    },
  });

  const listingFilterSections: IListingFilterInputSection[] = [
    {
      title: 'Building Size',
      minValue: savedSearchFields.query.buildingSize?.min,
      maxValue: savedSearchFields.query.buildingSize?.max,
      setMinValue: (value: number) => setSavedSearchFields({
        ...savedSearchFields,
        query: {
          ...savedSearchFields?.query,
          buildingSize: {
            ...savedSearchFields?.query.buildingSize,
            min: value,
          },
        },
      }),
      setMaxValue: (value: number) => setSavedSearchFields({
        ...savedSearchFields,
        query: {
          ...savedSearchFields?.query,
          buildingSize: {
            ...savedSearchFields?.query.buildingSize,
            max: value,
          },
        },
      }),
    },
    {
      title: 'Cap Rate',
      minValue: savedSearchFields.query.capRate?.min,
      maxValue: savedSearchFields.query.capRate?.max,
      setMinValue: (value: number) => setSavedSearchFields({
        ...savedSearchFields,
        query: {
          ...savedSearchFields?.query,
          capRate: {
            ...savedSearchFields?.query.capRate,
            min: value,
          },
        },
      }),
      setMaxValue: (value: number) => setSavedSearchFields({
        ...savedSearchFields,
        query: {
          ...savedSearchFields?.query,
          capRate: {
            ...savedSearchFields?.query.capRate,
            max: value,
          },
        },
      }),
    },
    {
      title: 'Occupancy Rate',
      minValue: savedSearchFields.query.occupancyRate?.min,
      maxValue: savedSearchFields.query.occupancyRate?.max,
      setMinValue: (value: number) => setSavedSearchFields({
        ...savedSearchFields,
        query: {
          ...savedSearchFields?.query,
          occupancyRate: {
            ...savedSearchFields?.query.occupancyRate,
            min: value,
          },
        },
      }),
      setMaxValue: (value: number) => setSavedSearchFields({
        ...savedSearchFields,
        query: {
          ...savedSearchFields?.query,
          occupancyRate: {
            ...savedSearchFields?.query.occupancyRate,
            max: value,
          },
        },
      }),
    },
  ];

  const [selectedAssetClasses, setSelectedAssetClasses] = React.useState<ListingAssetClassEnum[]>(savedSearchFields?.query?.assetClasses ?? []);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = React.useState<ListingPropertyTypeEnum[]>(savedSearchFields?.query?.propertyTypes ?? []);
  const [selectedKeywords, setSelectedKeywords] = React.useState<string[]>(savedSearchFields?.query?.keywords ?? []);

  const [saveSearchDisabled, setSaveSearchDisabled] = React.useState<boolean>(true);

  /** Hooks */
  const formParams: UseFormParams = {
    fields: {
      name: type === SaveSearchModalTypeEnum.Create ? '' : savedSearchFilterData?.name,
    },
    errors: {
      name: '',
    },
    fieldOrder: [
      '/name',
    ],
    schema: SaveSearchJoi.createSavedSearchParamsSchema,
  };

  const {
    controllers: {
      name,
    },
    setFieldErrors,
  } = useForm(formParams);

  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();
  const navigate = useNavigate();

  /** GraphQL */
  type Data = {
    listings: {
      data: IListingGraphQL[],
    }
  }

  type Vars = {
    params: IListingsQuery;
  }

  const { data } = useQuery<Data, Vars>(LIST_LISTINGS, {
    variables: {
      params: {
        pagination: {
          offset: 0,
          limit: (isMobile || isTablet) ? PaginationLimitsEnum.SRPListingsMobile : PaginationLimitsEnum.SRPListings,
        },
        query: savedSearchFields.query,
      },
    },
  });

  const [createSavedSearch, { loading }] = useMutation(CREATE_SAVED_SEARCH, {
    onCompleted: async (data) => {
      const savedSearchId = data?.createSavedSearch?._id;
      dispatch(ListingActions.cacheSavedSearch({ _id: savedSearchId }));
      replaceWithConfirmModal(savedSearchId);
      if (refetchSavedSearches) {
        refetchSavedSearches();
      }
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        pushToast({
          message,
          type: ToastTypesEnum.Error,
          duration: 5000,
        });
      }
    },
  });

  const [updateSavedSearch] = useMutation(UPDATE_SAVED_SEARCH, {
    onCompleted: async (data) => {
      const savedSearchId = data?.updateSavedSearch?._id;
      replaceWithConfirmModal(savedSearchId);
      if (refetchSavedSearches) {
        refetchSavedSearches();
      }
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        pushToast({
          message,
          type: ToastTypesEnum.Error,
          duration: 5000,
        });
      }
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const popModal = () => {
    dispatch(AppActions.popModal());
  };

  const replaceWithConfirmModal = (savedSearchId?: number) => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Success,
        title: 'Search saved',
        text: "Go to 'Saved Searches' to view, edit, or delete your saved search.",
        clickableText: currentPage === SaveSearchModalLocations.SavedSearchPage ? {
          text: 'Go to saved search',
          onClick: () => {
            dispatch(ListingActions.setListingSearch({ address: savedSearchFields?.query?.address }));
            dispatch(ListingActions.cacheSavedSearch({ ...savedSearchFields }));
            dispatch(ListingActions.setListingSearch({ isSavedSearch: true }));
            const params = {
              savedSearchData: JSON.stringify({
                ...savedSearchFields,
                name: name.value(),
                _id: savedSearchId,
              }),
            };
            navigate({
              pathname: '/app/dashboard/search',
              search: createSearchParams(params).toString(),
            });
          },
        } : null,
        confirm: (event: React.FormEvent) => {
          event?.preventDefault();
          popModal();
        },
      },
    }),
  );

  /** Helper functions / Event handlers */
  const handleSaveSearch = async (event) => {
    if (event) event.preventDefault();
    const params: ISavedSearchParams = {
      ...savedSearchFields,
      name: name.value(),
      query: {
        ...savedSearchFields?.query,
        bounds: {
          ne: neSwPoints?.ne,
          sw: neSwPoints?.sw,
        }, // Have to redefine bounds here since a user can change the location at any point throughout the saved search flow. Probably best to redefine the bounds on save instead of on address change for performance
        keywords: selectedKeywords,
      },
    };
    setSavedSearchFields(params);

    if (currentPage === SaveSearchModalLocations.SRP) {
      dispatch(ListingActions.setListingSearch({ address: savedSearchFields?.address }));
      dispatch(ListingActions.cacheSavedSearch({ ...params }));
      dispatch(ListingActions.setListingSearch({ isSavedSearch: true }));
    }

    if (type === SaveSearchModalTypeEnum.Create) {
      await createSavedSearch({
        variables: {
          params,
        },
      });
    } else {
      await updateSavedSearch({
        variables: {
          params,
        },
      });
    }
  };

  const handleClearFilters = () => {
    setSavedSearchFields({
      name: '',
      notifications: true,
      query: {
        queryType: cachedQuery?.queryType ?? ListingQueryTypesEnum.Active,
        assetClasses: [],
        propertyTypes: [],
        price: {
          min: null,
          max: null,
          includeUndefined: false,
        },
        sortBy: null,
        bounds: cachedQuery?.bounds,
        state: cachedQuery?.state,
        address: null,
      },
      address: null,
    });
    setSelectedAssetClasses([]);
    setSelectedPropertyTypes([]);
  };

  // Checks if a user has filled out any of the required fields to actually make a saved search worth saving
  const checkSaveStatus = () => {
    if (savedSearchFields.query?.address
      || savedSearchFields?.query?.assetClasses?.length
      || savedSearchFields?.query?.propertyTypes?.length
      || savedSearchFields?.query?.price?.min
      || savedSearchFields?.query?.price?.min
      || savedSearchFields?.query?.buildingSize?.min
      || savedSearchFields?.query?.buildingSize?.max
      || savedSearchFields?.query?.capRate?.min
      || savedSearchFields?.query?.capRate?.max
      || savedSearchFields?.query?.occupancyRate?.min
      || savedSearchFields?.query?.occupancyRate?.max
      || savedSearchFields?.query?.miscellaneous?.brokerCoOpAvailable
      || savedSearchFields?.query?.miscellaneous?.netLease
      || savedSearchFields?.query?.miscellaneous?.creditTenant
      || savedSearchFields?.query?.miscellaneous?.offMarket
      || savedSearchFields?.query?.miscellaneous?.isPortfolio
      || savedSearchFields?.query?.miscellaneous?.forSaleByOwner) {
      return true;
    }
    return false;
  };

  const finalListings = data?.listings?.data ?? [];
  const isLoading = loading && finalListings.length === 0;

  const { neSwPoints } = getPropertyBounds(finalListings);

  React.useEffect(() => {
    const canSaveSearch = checkSaveStatus();
    setSaveSearchDisabled(!canSaveSearch);
  }, [savedSearchFields]);

  React.useEffect(() => {
    setSavedSearchFields({
      ...savedSearchFields,
      query: {
        ...savedSearchFields?.query,
        assetClasses: selectedAssetClasses,
        propertyTypes: selectedPropertyTypes,
      },
    });
  }, [selectedAssetClasses, selectedPropertyTypes]);

  // Clear saved search filters when you create a new saved search
  React.useEffect(() => {
    if (type === SaveSearchModalTypeEnum.Create) {
      handleClearFilters();
      dispatch(ListingActions.removeSavedSearch({}));
    }
  }, []);

  return (
    <ModalContainer
      width={isMobile ? '100vw' : null}
      height="calc(100% - 24px)"
      overflow="auto"
      data-cy="save-search-modal"
    >
      <ModalHeader dataCy="save-search-modal-header" closeCy="save-search-close-button" title={type === SaveSearchModalTypeEnum.Create ? 'Save Search' : 'Edit Search'} close={popModal} />
      <ModalContent>
        <Container>
          <Input
            data-cy="save-search-name-input"
            label="Name"
            value={name.value()}
            onChange={name.setValue}
            error={name.error()}
          />
          <Flex align="flex-start" margin="24px 0 0">
            <Checkbox
              onClick={() => setSavedSearchFields({ ...savedSearchFields, notifications: !savedSearchFields?.notifications })}
              type={CheckboxTypesEnum.Large}
              active={savedSearchFields?.notifications}
            />
            <Flex direction="column">
              <Text type={TextTypesEnum.Medium16}>Email me updates</Text>
              <Text>When new properties are published that match this saved search, Biproxi will email you to alert you about them.</Text>
            </Flex>
          </Flex>
          <Divider margin="24px 0" />
          <AddressContainer>
            <AutoCompleteAddress
              dataCy="save-search-address-input"
              label="Location"
              showUnit={false}
              value={savedSearchFields?.query?.address}
              onChange={(address: IAddress) => setSavedSearchFields({
                ...savedSearchFields,
                query: {
                  ...savedSearchFields.query,
                  address,
                },
              })}
            />
          </AddressContainer>
          {/* <SaveSearchModalMap
              listings={finalListings}
              savedSearchAddress={savedSearchFields?.address}
              loading={isLoading}
              querying={loading}
            /> */}
          <MapContainer>
            <SearchListingsMap
              listings={finalListings}
              loading={isLoading}
              bounds={neSwPoints}
              mapOptions={
                {
                  srpFullScreenControls: false,
                  fullscreenControl: false,
                  draggable: false,
                  zoomControl: false,
                  disableMobileControls: true,
                }
              }
            />
          </MapContainer>
          <Divider margin="24px 0" />
          <Text data-cy="save-search-asset-class-header" type={TextTypesEnum.Bold18} margin="0 0 16px 0">Asset Class & Subtype</Text>
          <AssetClassAndPropertyTypeFilter
            externalAssetClasses={selectedAssetClasses}
            externalPropertyTypes={selectedPropertyTypes}
            setExternalAssetClassState={setSelectedAssetClasses}
            setExternalPropertyTypeState={setSelectedPropertyTypes}
            column={isMobile}
          />
          <Divider margin="24px 0" />
          <Text
            type={TextTypesEnum.Bold18}
            color={Colors.Grey900}
            margin="16px 0 8px 0"
          >
            Keywords
            {/* (
              {determineFilterCount(SRPFilterCountEnum.KeywordsFilter)}
              ) */}
          </Text>
          <KeywordFilter
            data-cy="save-search-keyword-filter-input"
            values={selectedKeywords}
            setKeywordState={setSelectedKeywords}
          />
          <Divider margin="24px 0" />
          <Text type={TextTypesEnum.Bold18} margin="0 0 16px 0">Price</Text>
          <PriceContainer>
            <PriceFilter
              data-cy="save-search-price"
              minValue={savedSearchFields?.query?.price?.min?.toString() || ''}
              maxValue={savedSearchFields?.query?.price?.max?.toString() || ''}
              checkboxActive={savedSearchFields?.query?.price?.includeUndefined}
              onMinChange={(_event, unmasked) => {
                setSavedSearchFields({
                  ...savedSearchFields,
                  query: {
                    ...savedSearchFields?.query,
                    price: {
                      ...savedSearchFields?.query.price,
                      min: unmasked,
                    },
                  },
                });
              }}
              onMaxChange={(_event, unmasked) => {
                setSavedSearchFields({
                  ...savedSearchFields,
                  query: {
                    ...savedSearchFields?.query,
                    price: {
                      ...savedSearchFields?.query.price,
                      max: unmasked,
                    },
                  },
                });
              }}
              onCheckboxClick={() => setSavedSearchFields({
                ...savedSearchFields,
                query: {
                  ...savedSearchFields?.query,
                  price: {
                    ...savedSearchFields?.query.price,
                    includeUndefined: !savedSearchFields?.query?.price?.includeUndefined,
                  },
                },
              })}
            />
          </PriceContainer>
          <Divider margin="24px 0" />
          {listingFilterSections.map((section, index) => (
            <>
              <ListingFilterInputSection
                key={index}
                sectionTitle={section.title}
                minValue={section.minValue}
                maxValue={section.maxValue}
                setMinValue={section.setMinValue}
                setMaxValue={section.setMaxValue}
              />
              <Divider margin="16px 0" />
            </>
          ))}
          <MiscellaneousContainer>
            <Text
              type={TextTypesEnum.Bold18}
              color={Colors.Grey900}
              margin="0 0 8px 0"
            >
              Miscellaneous
            </Text>
            <Flex direction="column">
              <Flex direction={isMobile ? 'column' : 'row'}>
                <Checkbox
                  label="Broker Co-Op Available"
                  active={savedSearchFields?.query?.miscellaneous?.brokerCoOpAvailable}
                  onClick={() => setSavedSearchFields({
                    ...savedSearchFields,
                    query: {
                      ...savedSearchFields?.query,
                      miscellaneous: {
                        ...savedSearchFields?.query.miscellaneous,
                        brokerCoOpAvailable: !savedSearchFields?.query?.miscellaneous?.brokerCoOpAvailable,
                      },
                    },
                  })}
                  margin={isMobile ? '0 16px 0 0' : '0 32px 0 0'}
                />
                <Checkbox
                  label="Net Lease"
                  active={savedSearchFields?.query?.miscellaneous?.netLease}
                  onClick={() => setSavedSearchFields({
                    ...savedSearchFields,
                    query: {
                      ...savedSearchFields?.query,
                      miscellaneous: {
                        ...savedSearchFields?.query.miscellaneous,
                        netLease: !savedSearchFields?.query?.miscellaneous?.netLease,
                      },
                    },
                  })}
                  margin={isMobile ? '0 16px 0 0' : '0 32px 0 0'}
                />
                <Checkbox
                  label="Credit Tenant"
                  active={savedSearchFields?.query?.miscellaneous?.creditTenant}
                  onClick={() => setSavedSearchFields({
                    ...savedSearchFields,
                    query: {
                      ...savedSearchFields?.query,
                      miscellaneous: {
                        ...savedSearchFields?.query.miscellaneous,
                        creditTenant: !savedSearchFields?.query?.miscellaneous?.creditTenant,
                      },
                    },
                  })}
                  margin={isMobile ? '0 16px 0 0' : '0 32px 0 0'}
                />
              </Flex>
              <Flex margin={isMobile ? null : '16px 0 0'} direction={isMobile ? 'column' : 'row'}>
                <Checkbox
                  label="Off Market"
                  active={savedSearchFields?.query?.miscellaneous?.offMarket}
                  onClick={() => setSavedSearchFields({
                    ...savedSearchFields,
                    query: {
                      ...savedSearchFields?.query,
                      miscellaneous: {
                        ...savedSearchFields?.query.miscellaneous,
                        offMarket: !savedSearchFields?.query?.miscellaneous?.offMarket,
                      },
                    },
                  })}
                  margin={isMobile ? '0 16px 0 0' : '0 32px 0 0'}
                />
                <Checkbox
                  label="For Sale By Owner"
                  active={savedSearchFields?.query?.miscellaneous?.forSaleByOwner}
                  onClick={() => setSavedSearchFields({
                    ...savedSearchFields,
                    query: {
                      ...savedSearchFields?.query,
                      miscellaneous: {
                        ...savedSearchFields?.query.miscellaneous,
                        forSaleByOwner: !savedSearchFields?.query?.miscellaneous?.forSaleByOwner,
                      },
                    },
                  })}
                  margin={isMobile ? '0 16px 0 0' : '0 32px 0 0'}
                />
                <Checkbox
                  label="Portfolio"
                  active={savedSearchFields?.query?.miscellaneous?.isPortfolio}
                  onClick={() => setSavedSearchFields({
                    ...savedSearchFields,
                    query: {
                      ...savedSearchFields?.query,
                      miscellaneous: {
                        ...savedSearchFields?.query.miscellaneous,
                        isPortfolio: !savedSearchFields?.query?.miscellaneous?.isPortfolio,
                      },
                    },
                  })}
                  margin={isMobile ? '0 16px 0 0' : '0 32px 0 0'}
                />
              </Flex>
            </Flex>
          </MiscellaneousContainer>
          <Divider margin="16px 0" />
          <Flex justify="space-between">
            <Text
              type={TextTypesEnum.Medium12}
              color={Colors.Brand700 || Colors.Blurple700}
              onClick={() => handleClearFilters()}
            >
              Clear all
            </Text>
            <Button
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Small}
              text="Save"
              disabled={saveSearchDisabled}
              onClick={(event) => handleSaveSearch(event)}
            />
          </Flex>
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default SaveSearchModal;
