import { gql } from '@apollo/client';
import PrivateEventFragments from '../fragments/privateEvent.fragments';

const ACCEPT_PRIVATE_EVENT_INVITATION = gql`
  mutation AcceptPrivateEventInvitation($params: AcceptPrivateEventInvitationParams!) {
    acceptPrivateEventInvitation(params: $params) {
      ...PrivateEventFields
      ...PrivateEventPSAFiles
      ...PrivateEventParticipants
      ...PrivateEventBids
      ...PrivateEventSettings
      ...PrivateEventContingencies
      ...PrivateEventMeta
      ...PrivateEventListing 
    }
  }

  ${PrivateEventFragments.fields}
  ${PrivateEventFragments.psaFiles}
  ${PrivateEventFragments.participants}
  ${PrivateEventFragments.bids}
  ${PrivateEventFragments.settings}
  ${PrivateEventFragments.contingencies}
  ${PrivateEventFragments.meta}
  ${PrivateEventFragments.listing}
`;

export default ACCEPT_PRIVATE_EVENT_INVITATION;
