import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ModalContainer, ModalContent,
} from '../../styles/components/Modal.styles';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { media } from '../../utils/MediaQuery';
import Icon, { Icons } from '../../elements/Icon';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Flex from '../../elements/Flex';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import useNavigateToListingDetails from '../../hooks/useNavigateToListingDetails.hook';

const Container = styled.form`
  width: 336px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.mobile} {
    width: 100%;
  }
`;

const Circle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  background-color: ${Colors.Green200};
  margin-bottom: 24px;
  border-radius: 50%;
`;

export enum PublishMessageModalTypes {
  Success,
}

export type PublishMessageModalProps = {
  isScheduled: boolean;
  listing: IListingGraphQL;
};

const PublishMessageModal: React.FC<PublishMessageModalProps> = ({
  isScheduled,
  listing,
}) => {
  /* Hooks */
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToListingDetails = useNavigateToListingDetails();
  const ref = useOnclickOutside(() => {
    popModal();
  });

  /* Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(AppActions.popModal());

  /* State */
  const bigText = isScheduled ? 'Your listing has been scheduled!' : 'Your listing has been published!';
  const text = 'Navigate to your listing dashboard to control your listing and to view listing activity and analytics.';
  const isOnCreateListing = location.pathname.includes('/app/create-listing');

  /* Render */
  return (
    <ModalContainer data-cy="publish-message-modal">
      <ModalContent>
        <Flex align="center" justify="center">
          <Circle>
            <Icon icon={Icons.CheckSolid} size={24} color={Colors.Green700} />
          </Circle>
        </Flex>
        <Container ref={ref}>
          <Text
            type={TextTypesEnum.Bold24}
            color={Colors.Black}
            align="center"
          >
            {bigText}
          </Text>
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Grey700}
            align="center"
            margin="24px 0"
          >
            {text}
          </Text>
          <Button
            text="View listing dashboard"
            data-cy="view-listings-page-button"
            iconColor={Colors.Grey900}
            iconSize={14}
            type={ButtonTypesEnum.Outline}
            onClick={() => {
              popModal();
              navigateToListingDetails(listing?._id);
            }}
            size={ButtonSizesEnum.Large}
            htmlType="button"
            margin="0 0 8px"
            isFullWidth
          />
          <Button
            data-cy="my-listing-published-button"
            text={isOnCreateListing ? 'Go to my listings' : 'Close'}
            type={ButtonTypesEnum.Primary}
            onClick={() => {
              if (isOnCreateListing) {
                navigate('/app/dashboard/listings');
              }
              popModal();
            }}
            size={ButtonSizesEnum.Large}
            htmlType="button"
            isFullWidth
          />
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default PublishMessageModal;
