import { useRouter } from 'next/router';
import { createSearchParams, URLSearchParamsInit, useNavigate } from 'react-router-dom';

/**
 * Temp Solution, kinda weird but navigating from PDP w/ SSR -> Dashbaord SPA
 * requires both routing types to be updated.
 * Plan is to seperate the two apps though which will clean this up.
 */
const useHandleRouteChange = () => {
  const router = useRouter();
  const navigate = useNavigate();

  return (route: string, params: URLSearchParamsInit = null) => {
    const isNotInApp = !window?.location?.href?.includes('/app/');
    if (isNotInApp) {
      router.push({
        pathname: route,
        query: params as any,
      });
    }
    navigate({
      pathname: route,
      search: createSearchParams(params || {}).toString(),
    });
  };
};

export default useHandleRouteChange;
