import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import * as UserJoi from '@biproxi/models/joi/user.joi';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import ReCAPTCHA from 'react-google-recaptcha';
import StringUtil from '@biproxi/models/utils/StringUtil';
import JoiUtil from '@biproxi/models/utils/JoiUtil';
import { config } from '@biproxi/env';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import {
  AuthenticationModal,
  Content,
} from '../AuthenticationModalLayout';
import Text, { TextTypesEnum } from '../../elements/Text';
import LinkText from '../../elements/LinkText';
import Colors from '../../styles/Colors';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import VERIFY_PHONE_NUMBER_VERIFICATION_CODE from '../../graphql/mutations/verifyPhoneNumberVerificationCode.mutation';
import GET_USER from '../../graphql/queries/user.query';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from './Modal';
import { useAppDispatch } from '../../redux/store';
import Flex from '../../elements/Flex';
import Loader, { LoaderSizes } from '../../elements/Loader';
import CodeInput from '../../elements/CodeInput';
import useVerifyPhoneNumber from '../../hooks/useVerifyPhoneNumber.hook';
import BoxShadows from '../../styles/BoxShadows';
import UtmUtil from '../../utils/UTMUtil';
import Auth from '../../utils/Auth';
import { UserActions } from '../../redux/user.redux';

const NoCodeContainer = styled.div<{ visible: boolean }>`
  border: 1px solid ${Colors.Grey300};
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  box-shadow: ${BoxShadows.Light};
`;

export type VerifyPhoneNumberModalProps = {
  isStandalone?: boolean;
};

const VerifyPhoneNumberModal: React.FC<VerifyPhoneNumberModalProps> = ({
  isStandalone = false,
}) => {
  /** Hooks */
  const [showNoCodeOptions, setShowNoCodeOptions] = React.useState(false);
  const recaptchaRef = React.useRef(null);

  const formParams: UseFormParams = {
    fields: {
      phoneNumberVerificationCode: '',
    },
    fieldOrder: ['/phoneNumberVerificationCode'],
    schema: UserJoi.verifyPhoneNumberVerificationCodeParamsSchema,
  };

  const {
    controllers: {
      phoneNumberVerificationCode,
    },
    setFieldErrors,
  } = useForm(formParams);

  const {
    loading,
    startRecaptcha,
    sendPhoneNumberVerificationSMS,
  } = useVerifyPhoneNumber({
    recaptchaRef,
    setFieldErrors,
    setError: (message) => phoneNumberVerificationCode.setError(message),
  });

  /** GraphQL */
  const { data } = useQuery(GET_USER);
  const [verifyPhoneNumberVerificationCodeMutation, { loading: verifyLoading }] = useMutation(VERIFY_PHONE_NUMBER_VERIFICATION_CODE, {
    onCompleted: async (data) => {
      /**
       * User has created an account
       * and verified their phone number.
       * If the link contains the paid search utm source
       * it's from the TCOM marketing campaigns so we track it.
       */

      (window as any)?.dataLayer.push({
        event: 'TCOM_CONVERSION',
        userId: data?.user?._id,
      });

      // Cache User in redux
      cacheUser(data?.user);

      UtmUtil.clearUtmParameters();
      replaceWithPhoneVerified();
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        phoneNumberVerificationCode.setError(message);
      }
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const cacheUser = (user: IUserGraphQL) => dispatch(UserActions.cacheUser({ user }));
  const replaceModalWithCompleteRegister = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.CompleteRegister,
      props: {
        isStandalone,
      },
    }),
  );

  const replaceWithPhoneVerified = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.PhoneVerified,
      props: {
        isStandalone,
      },
    }),
  );

  const resendVerificationSMS = async () => {
    const token = await startRecaptcha();

    if (!token) return;

    await sendPhoneNumberVerificationSMS(token);
  };

  const handleSubmit = async (code?: string) => {
    const { errors } = JoiUtil.validate(
      UserJoi.verifyPhoneNumberVerificationCodeParamsSchema,
      { phoneNumberVerificationCode: code },
    );

    if (JoiUtil.hasErrors(errors)) {
      setFieldErrors(JoiUtil.parseErrors(errors));
      return;
    }

    await verifyPhoneNumberVerificationCodeMutation({
      variables: {
        params: {
          phoneNumberVerificationCode: code,
        },
      },
    });
  };

  /** Render */
  return (
    <AuthenticationModal fullscreen={isStandalone} showExit={false}>
      <Content>
        {verifyLoading ? (
          <Flex flex="1" align="center" justify="center" direction="column" height="100%">
            <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
            <Text type={TextTypesEnum.Medium18} margin="8px 0 0">Verifying code</Text>
          </Flex>
        ) : (
          <>
            <ReCAPTCHA
              ref={recaptchaRef as any}
              size="invisible"
              sitekey={config.NEXT_PUBLIC_FIREBASE_RECAPTCHA_SITE_KEY}
            />
            <Text type={TextTypesEnum.Bold24}>We just texted you</Text>
            <Text type={TextTypesEnum.Regular16} color={Colors.Grey700} margin="4px 0 0">
              Please enter the verification code we just sent to
              {' '}
              {StringUtil.formatPhoneNumber(data?.user?.phoneNumber) || 'your phone number'}
            </Text>
            {/* Could later clean up this component to work better with our form pattern */}
            <CodeInput
              data-cy="phone-code-input"
              ref={phoneNumberVerificationCode.setRef}
              error={phoneNumberVerificationCode.error()}
              margin="16px 0px"
              onChange={() => {
                phoneNumberVerificationCode.setError(null);
              }}
              onComplete={(code: string) => {
                handleSubmit(code);
              }}
            />
            <LinkText
              onClick={() => setShowNoCodeOptions(true)}
              type={TextTypesEnum.Medium14}
            >
              I didn&apos;t receive a code
            </LinkText>
            <NoCodeContainer visible={showNoCodeOptions}>
              <Text type={TextTypesEnum.Medium16} color={Colors.Grey900} margin="0 0 8px">
                We texted
                {' '}
                {StringUtil.formatPhoneNumber(data?.user?.phoneNumber) || 'you'}
              </Text>
              <Flex align="center" margin="0 0 8px">
                <LinkText
                  margin="0 8px 0 0"
                  type={TextTypesEnum.Medium14}
                  onClick={() => resendVerificationSMS()}
                >
                  Text me again
                </LinkText>
                {loading && <Loader size={LoaderSizes.ExtraSmall} color={Colors.Brand700 || Colors.Blurple700} />}
              </Flex>
              <LinkText
                type={TextTypesEnum.Medium14}
                onClick={() => replaceModalWithCompleteRegister()}
              >
                Edit phone number
              </LinkText>
              <LinkText
                type={TextTypesEnum.Medium14}
                onClick={() => Auth.logout()}
                margin="8px 0 0"
              >
                Return to login
              </LinkText>
            </NoCodeContainer>
          </>
        )}
      </Content>
    </AuthenticationModal>
  );
};

export default VerifyPhoneNumberModal;
