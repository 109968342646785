import gql from 'graphql-tag';

const UPDATE_USER = gql`
  mutation Mutation($where: UserWhere, $update: UserUpdateInput) {
    updateUsers(where: $where, update: $update) {
      users {
        _id
        metaLastUpdatedBy
        firstName
        lastName
        email
      }
    }
  }
`;

export default UPDATE_USER;
