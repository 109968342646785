import ListingInfoFieldNamesEnum from '../enums/ListingInfoFieldNamesEnum';

type TUnitBreakdownForMobileHome = {
  [ListingInfoFieldNamesEnum.Singlewide]: number | null;
  [ListingInfoFieldNamesEnum.Doublewide]: number | null;
  [ListingInfoFieldNamesEnum.Triplewide]: number | null;
  [ListingInfoFieldNamesEnum.RV]: number | null;
}

export const unitBreakdownForMobileHomeState = (current?: TUnitBreakdownForMobileHome): TUnitBreakdownForMobileHome => ({
  [ListingInfoFieldNamesEnum.Singlewide]: null,
  [ListingInfoFieldNamesEnum.Doublewide]: null,
  [ListingInfoFieldNamesEnum.Triplewide]: null,
  [ListingInfoFieldNamesEnum.RV]: null,
  ...current,
});

export default TUnitBreakdownForMobileHome;
