/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { logger } from '@biproxi/logger';
import { updateUserOrganizationRole } from '@biproxi/next-api-requests';
import BillingPlanIdEnum from '../enums/BillingPlanIdEnum';
import BiproxiPlatformRolesEnum, { TeamRolesEnum } from '../enums/Neo4jRolesEnums';
import BiproxiPlatformPermissionsEnum, { TeamPermissionsEnum } from '../enums/PermissionsEnum';
import StatusCodeEnum from '../enums/StatusCodeEnum';
import * as INeo4jServiceAPI from '../services/INeo4jService';

//  TODO: Need to fix the typings on all of these permission params in this file. Don't think one has been defined yet but needs to be.

export interface IUserOrganizationData {
  orgId: string;
  orgName: string;
  orgDescription?: string;
  permissions: TeamPermissionsEnum[];
  roles: TeamRolesEnum[];
}

function parseUserPermissions(userRoles: TeamRolesEnum[]) {
  const permissions: TeamPermissionsEnum[] = [];
  const allPermissions: TeamPermissionsEnum[] = Object.values(TeamPermissionsEnum).map((permission) => permission);
  const userPermissions = (() => {
    switch (true) {
      case userRoles?.includes(TeamRolesEnum.Owner):
        return allPermissions;
      case userRoles?.includes(TeamRolesEnum.Admin):
        return allPermissions?.filter((permission) => permission !== TeamPermissionsEnum.RemoveAdmin);
      case userRoles?.includes(TeamRolesEnum.Member):
        return [TeamPermissionsEnum.AddListings, TeamPermissionsEnum.RemoveListings, TeamPermissionsEnum.AddMembers];
      default:
        return permissions;
    }
  })();
  return userPermissions;
}

function userHasGlobalPermissions(userRoles: BiproxiPlatformRolesEnum[]): boolean {
  return userRoles?.includes(BiproxiPlatformRolesEnum.Developer) || userRoles?.includes(BiproxiPlatformRolesEnum?.SuperAdmin) || userRoles?.includes(BiproxiPlatformRolesEnum.BiproxiAdmin) || userRoles?.includes(BiproxiPlatformRolesEnum.Admin);
}

function getSubscriptionPermissions(subscriptionType: BillingPlanIdEnum): BiproxiPlatformPermissionsEnum[] {
  const permissions: BiproxiPlatformPermissionsEnum[] = [];
  switch (subscriptionType) {
    case BillingPlanIdEnum.BrokerProMonthly:
    case BillingPlanIdEnum.BrokerProYearly:
      permissions.push(BiproxiPlatformPermissionsEnum.CreatePrivateEvent, BiproxiPlatformPermissionsEnum.CreatePrivateListing, BiproxiPlatformPermissionsEnum.ViewInvestorMatches, BiproxiPlatformPermissionsEnum.DataExplorer);
      break;
    default:
      break;
  }
  return permissions;
}

function canUserCreatePrivateEvent(userPermissionsAndRoles: any): boolean {
  const permissions = userPermissionsAndRoles?.permissions ?? [];
  const roles = userPermissionsAndRoles?.roles ?? [];
  // need to revert back after testing as this is a temp fix
  let canUserCreatePrivateEventFlag = false;
  if (permissions?.includes(BiproxiPlatformPermissionsEnum.CreatePrivateEvent) || userHasGlobalPermissions(roles)) canUserCreatePrivateEventFlag = true;
  return canUserCreatePrivateEventFlag;
}

function canUserCreatePrivateListing(userPermissionsAndRoles: any): boolean {
  const permissions = userPermissionsAndRoles?.permissions ?? [];
  const roles = userPermissionsAndRoles?.roles ?? [];
  let canUserCreatePrivateEventFlag = false;
  if (permissions?.includes(BiproxiPlatformPermissionsEnum.CreatePrivateListing) || userHasGlobalPermissions(roles)) canUserCreatePrivateEventFlag = true;
  return canUserCreatePrivateEventFlag;
}

function canUserViewDataExplorerData(userPermissionsAndRoles: any): boolean {
  const permissions = userPermissionsAndRoles?.permissions ?? [];
  const roles = userPermissionsAndRoles?.roles ?? [];
  let canUserViewDataExplorerData = false;
  if (permissions?.includes(BiproxiPlatformPermissionsEnum.DataExplorer) || userHasGlobalPermissions(roles)) canUserViewDataExplorerData = true;
  return canUserViewDataExplorerData;
}

function canUserUploadOM(userPermissionsAndRoles: any): boolean {
  const permissions = userPermissionsAndRoles?.permissions ?? [];
  const roles = userPermissionsAndRoles?.roles ?? [];
  let canUserUploadOM = false;
  if (permissions?.includes(BiproxiPlatformPermissionsEnum.OMUpload) || userHasGlobalPermissions(roles)) canUserUploadOM = true;
  return canUserUploadOM;
}

function canUserViewInvestorMatches(userPermissionsAndRoles: any): boolean {
  const permissions = userPermissionsAndRoles?.permissions ?? [];
  const roles = userPermissionsAndRoles?.roles ?? [];
  return (permissions?.includes(BiproxiPlatformPermissionsEnum.ViewInvestorMatches || userHasGlobalPermissions(roles)));
}

function parseUserSpecificOrgRolesAndPermissions(userOrgs: IUserOrganizationData[], orgId: string): IUserOrganizationData {
  const targetOrg: IUserOrganizationData = userOrgs?.filter((org: IUserOrganizationData) => org?.orgId === orgId)?.[0];
  return targetOrg;
}

function isUserTeamAdmin(userRoles: TeamRolesEnum[]): boolean {
  return userRoles?.includes(TeamRolesEnum.Owner)
  || userRoles?.includes(TeamRolesEnum.Admin);
}

async function updateUserOrgRole(orgRoles: TeamRolesEnum[], userId: string, orgId: string): Promise<boolean> {
  let updateSuccessful: boolean = false;

  try {
    const params: INeo4jServiceAPI.IUpdateUserOrganizationRoleParams = {
      userId,
      orgId,
      orgRoles,
    };
    const res = await updateUserOrganizationRole(params);
    updateSuccessful = res.status === StatusCodeEnum.OK;
  } catch (e) {
    logger.warn(`${e}`);
  }
  return updateSuccessful;
}

/**
 * This function may change in the future but basically it returns true if the user has any sort of permission as we are currently counting that
 * to mean that they have a pro membership whereas the free membership has nothing. Also includes special cased internal biproxi roles - admin/super-admin
 */
const isSubscribed = (userPermissions: any) => (userPermissions?.userBiproxiRolesAndPermissions?.permissions?.length > 0);
// || (userPermissions?.userBiproxiRolesAndPermissions?.roles?.length > 0);

const PermissionsUtil = {
  parseUserPermissions,
  getSubscriptionPermissions,
  canUserCreatePrivateEvent,
  canUserCreatePrivateListing,
  canUserViewDataExplorerData,
  canUserUploadOM,
  canUserViewInvestorMatches,
  userHasGlobalPermissions,
  updateUserOrgRole,
  isUserTeamAdmin,
  parseUserSpecificOrgRolesAndPermissions,
  isSubscribed,
};

export default PermissionsUtil;
