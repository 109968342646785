import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import { useMutation } from '@apollo/client';
import * as ILeadService from '@biproxi/models/services/ILeadService';
import INote from '@biproxi/models/interfaces/INote';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import LeadNotesQueryTypeEnum from '@biproxi/models/enums/LeadNotesQueryTypeEnum';
import TextAreaWithButton from '../../elements/TextAreaWithButton';
import Icon from '../../elements/Icon';
import Icons from '../../elements/Icons';
import Colors from '../../styles/Colors';
import CREATE_LEAD_NOTE from '../../graphql/mutations/createLeadNote.mutation';
import Text, { TextTypesEnum } from '../../elements/Text';
import Flex from '../../elements/Flex';
import DELETE_LEAD_NOTE from '../../graphql/mutations/deleteLeadNote.mutation';
import * as Motion from '../../elements/Motion';
import { LeadActions } from '../../redux/lead.redux';
import { useAppDispatch } from '../../redux/store';
import Loader, { LoaderSizes } from '../../elements/Loader';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { AppActions } from '../../redux/app.redux';
import { media } from '../../utils/MediaQuery';

const Container = styled.div`
  width: 100%;
  height: 65vh;


  ${media.mobile} {
    height: calc(100vh - 135px);
  }
`;

const InnerContainer = styled.div`
  /* width: 100%; */
  height: 100vh;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const NotesContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  /* height: 100%; */
  height: 100vh;
`;

const Note = styled(Motion.FadeIn)`
  width: fill-available;
  background-color: ${Colors.Yellow100};
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 8px;
`;

const InputContainer = styled.form`
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
`;

const SendButton = styled.button`
  background: ${Colors.Yellow500};
  border-radius: 4px;
  height: 32px;
  width: 32px;
  outline: none;
  border: none;
  cursor: pointer;
`;

export type LeadModalSellerNotesProps = {
  lead: ILeadGraphQL;
};

const LeadModalSellerNotes: React.FC<LeadModalSellerNotesProps> = ({
  lead,
}) => {
  /** State */

  const [note, setNote] = React.useState('');
  const [leadNoteIds, setLeadNoteIds] = React.useState(lead?.sellerNotes?.map((note) => note._id));

  /** Hooks */

  React.useEffect(() => {
    setLeadNoteIds(lead?.sellerNotes?.map((note) => note._id));
  }, [lead]);

  /** Actions */

  const dispatch = useAppDispatch();

  const cacheLead = (lead: ILeadGraphQL) => dispatch(LeadActions.cacheLeads({ leads: [lead] }));

  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));

  const createNote = (event?: React.FormEvent): void => {
    event.preventDefault();

    if (!note || loading) return;

    createNoteMutation();
    setNote('');
  };

  const deleteNote = (note: INote) => {
    const newLeads = lead?.sellerNotes
      ?.filter((leadNote) => leadNote._id !== note._id)
      .map((note) => note._id);

    setLeadNoteIds(newLeads);
    deleteNoteMutation({
      variables: {
        params: {
          leadId: lead._id,
          noteId: note._id,
        },
      },
    });
  };

  /** GraphQL */

  type TCreateData = {
    createLeadNote: ILeadGraphQL;
  };

  type TCreatePayload = ILeadService.TCreateLeadNotePayload;

  const [createNoteMutation, { loading }] = useMutation<TCreateData, TCreatePayload>(CREATE_LEAD_NOTE, {
    variables: {
      params: {
        leadId: lead._id,
        text: note,
        type: LeadNotesQueryTypeEnum.Seller,
      },
    },
    onCompleted: ({ createLeadNote }) => {
      cacheLead(createLeadNote);
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'We were unable to create your note. Please try again or contact support.',
      });
    },
  });

  type TDeleteData = {
    deleteLeadNote: ILeadGraphQL;
  };

  type TDeletePayload = ILeadService.TDeleteLeadNotePayload;

  const [deleteNoteMutation] = useMutation<TDeleteData, TDeletePayload>(DELETE_LEAD_NOTE, {
    onCompleted: ({ deleteLeadNote }) => {
      cacheLead(deleteLeadNote);
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'We were unable to delete your note. Please try again or contact support.',
      });
    },
  });

  /* Render */
  const NoteButton = (
    <SendButton
      type="submit"
    >
      {loading ? (
        <Loader
          size={LoaderSizes.ExtraSmall}
          color={Colors.White}
        />
      ) : (
        <Icon
          icon={Icons.AngleRightRegular}
          color={Colors.White}
          size={14}
        />
      )}
    </SendButton>
  );

  return (
    <Container>
      <InnerContainer>
        <NotesContainer>
          <div>
            {lead?.sellerNotes
              ?.filter((note: INote) => leadNoteIds.includes(note._id))
              .map((note: INote) => {
                const createdAt = StringUtil.capitalize(
                  TimeUtil.format(
                    note.meta.createdAt,
                    'LLLL d, yyyy \'at\' h:mma',
                    Intl.DateTimeFormat().resolvedOptions().timeZone,
                  ).toLowerCase(),
                );

                return (
                  <Note key={note._id}>
                    <Flex justify="space-between" margin="0 0 8px">
                      <Text type={TextTypesEnum.Bold12} color={Colors.Grey700}>{createdAt}</Text>
                      <Icon
                        icon={Icons.TrashSolid}
                        color={Colors.Grey700}
                        size={12}
                        onClick={() => deleteNote(note)}
                        hoverColor={Colors.Red500}
                      />
                    </Flex>
                    <Text type={TextTypesEnum.Regular14} color={Colors.Black}>{note.text}</Text>
                  </Note>
                );
              })}
          </div>
        </NotesContainer>
        <InputContainer onSubmit={createNote}>
          <TextAreaWithButton
            value={note}
            onChange={(event) => setNote(event.currentTarget.value)}
            onEnter={createNote as any}
            SendButton={NoteButton}
            placeholder="Enter your note"
          />
        </InputContainer>
      </InnerContainer>
    </Container>
  );
};

export default LeadModalSellerNotes;
