import gql from 'graphql-tag';

const UPDATE_LISTING_STATE = gql`
  mutation updateListingState($where: ListingWhere, $update: ListingUpdateInput) {
    updateListings(where: $where, update: $update) {
      listings {
        _id
        state
        metaLastUpdatedBy
      }
    }
  }
`;

export default UPDATE_LISTING_STATE;
