import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import StringUtil from '@biproxi/models/utils/StringUtil';
import { useMutation } from '@apollo/client';
import * as OfferJoi from '@biproxi/models/joi/offer.joi';
import { useDropzone } from 'react-dropzone';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import IFileParams, { GraphQLFileUpload } from '@biproxi/models/interfaces/IFileParams';
import IFile from '@biproxi/models/interfaces/IFile';
import { IOfferGraphQL } from '@biproxi/models/interfaces/IOffer';
import IOfferParams from '@biproxi/models/interfaces/IOfferParams';
import OfferQueryTypesEnum from '@biproxi/models/enums/OfferQueryTypesEnum';
import LIST_OFFERS from '../../graphql/queries/offers.query';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Text, { TextTypesEnum, TextAsEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import { media } from '../../utils/MediaQuery';
import Divider from '../../elements/Divider';
import Input, { InputTypesEnum } from '../../elements/Input';
import Toggle from '../../elements/Toggle';
import CREATE_OFFER from '../../graphql/mutations/createOffer.mutation';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { ModalTypesEnum } from './Modal';
import { ConfirmChangeModalTypesEnum } from './ConfirmChangeModal';
import CREATE_FILES from '../../graphql/mutations/createFiles.mutation';
import Icon, { Icons } from '../../elements/Icon';
import Label from '../../elements/Label';
import OfferCard, { OfferCardTypeEnum } from '../OfferCard';
import LOIFile from '../LOIFile';
import GET_USER_WITH_OFFERS from '../../graphql/queries/userWithOffers.query';
import GET_LEAD from '../../graphql/queries/lead.query';
import RichTextEditor from '../RichTextEditor';
import InlineAlert, { InlineAlertTypesEnum } from '../InlineAlert';

const Container = styled.div`
  width: 632px;

  ${media.mobile} {
    width: 100%;
  }
`;

const GuidanceItem = styled.div`
  margin-right: 32px;

  &::last-of-type {
    margin-right: 0;
  }
`;

const Dashed = styled.div`
  border: 1px dashed ${Colors.Grey300};
  padding: 12px 16px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 32px;

  ${media.mobile} {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0px;
  }
`;

const StepCircle = styled.div<{ margin?: string, active: boolean }>`
  height: 8px;
  width: 8px;
  margin: ${({ margin }) => margin};
  background-color: ${({ active }) => (active ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey300)};
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: ${({ active }) => (active ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey400)};
  }
`;

const Form = styled.form``;

// Todo: look into how modal is set up and add this in there.
const Margin = styled.div`
  max-height: 100%;
`;

export type OfferModalProps = {
  listing?: IListingGraphQL;
  offer?: IOfferGraphQL;
};

const OfferModal: React.FC<OfferModalProps> = ({
  listing,
  offer,
}) => {
  /** State */
  const [financingContingency, setFinancingContingency] = React.useState(true);
  const [exclusivity, setExclusivity] = React.useState(true);
  const [files, setFiles] = React.useState<IFile[]>([]);
  const [stepNum, setStepNum] = React.useState<number>(1);

  /** Hooks */
  const formParams: UseFormParams = {
    fields: {
      purchasePrice: '',
      depositAmount: '',
      dueDiligencePeriod: '',
      closingPeriod: '',
      financingPeriod: null,
      exclusivityPeriod: null,
      otherTerms: '',
      coverLetter: '',
    },
    fieldOrder: [
      '/purchasePrice',
      '/depositAmount',
      '/dueDiligencePeriod',
      '/closingPeriod',
      '/financingPeriod',
      '/exclusivityPeriod',
      '/otherTerms',
      '/coverLetter',
    ],
    schema: OfferJoi.createOfferFormParamsSchema,
  };

  const {
    controllers: {
      purchasePrice,
      depositAmount,
      dueDiligencePeriod,
      closingPeriod,
      financingPeriod,
      exclusivityPeriod,
      otherTerms,
      coverLetter,
    },
    setFieldErrors,
    setValues,
    isValid,
  } = useForm(formParams);

  // Only upload one file for now, could easily add support for more later.
  const onDrop: any = (browserFiles: GraphQLFileUpload[]) => {
    const fileParams: IFileParams[] = browserFiles.map((browserFile: GraphQLFileUpload) => ({
      name: browserFile.name,
      description: browserFile.name,
      mimetype: browserFile.type || 'application/octet-stream',
      encoding: 'utf8',
      upload: browserFile,
    }));

    uploadFiles({
      variables: {
        params: fileParams[0],
      },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const replaceWithConfirmModal = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Success,
        title: 'Your offer has been submitted',
        text: 'View the status of your current offers in the dashboard.',
        confirm: (event: React.FormEvent) => {
          event?.preventDefault();
          popModal();
        },
      },
    }),
  );

  /** GraphQL */
  interface Data {
    offer: IOfferGraphQL;
  }

  interface Vars {
    params: IOfferParams;
  }

  const [createOffer, { loading }] = useMutation<Data, Vars>(CREATE_OFFER, {
    onCompleted: async () => {
      replaceWithConfirmModal();
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        pushToast({
          message,
          type: ToastTypesEnum.Error,
          duration: 5000,
        });
      }
    },
    // cleanup?
    refetchQueries: () => {
      const refetchList = [{
        query: LIST_OFFERS,
        variables: {
          params: {
            queryType: OfferQueryTypesEnum.Sent,
            isActive: true,
          },
        },
      },
      {
        query: LIST_OFFERS,
        variables: {
          params: {
            queryType: OfferQueryTypesEnum.Sent,
            isActive: false,
          },
        },
      },
      {
        query: GET_USER_WITH_OFFERS,
      },
      ];

      if (offer?.leadId) {
        refetchList.push({
          query: LIST_OFFERS,
          variables: {
            params: {
              queryType: OfferQueryTypesEnum.Sent,
              leadId: offer?.leadId,
              hasRevisedOffers: true,
            },
          },
        } as any);
      }

      if (offer?.listingId) {
        refetchList.push({
          query: GET_LEAD,
          variables: {
            params: {
              listingId: offer?.listingId,
            },
          },
        } as any);
      }

      return refetchList;
    },
  });

  const [uploadFiles, { loading: fileLoading }] = useMutation(CREATE_FILES, {
    onCompleted: async (data) => {
      setFiles(data?.createFiles);
    },
  });

  /** Effects */
  React.useEffect(() => {
    if (offer) {
      setValues({
        purchasePrice: offer.purchasePrice,
        depositAmount: offer.depositAmount,
        dueDiligencePeriod: offer.dueDiligencePeriod,
        closingPeriod: offer.closingPeriod,
        financingPeriod: offer.financingPeriod,
        exclusivityPeriod: offer.exclusivityPeriod,
        otherTerms: offer.otherTerms,
        coverLetter: offer.coverLetter,
      });
      setFinancingContingency(Boolean(offer.financingPeriod));
      setExclusivity(Boolean(offer.exclusivityPeriod));
      setFiles(offer?.loiFiles[0] ? [offer?.loiFiles[0]] : []);
    }
  }, []);

  /* Render */
  const advanceStep = (event?: React.FormEvent) => {
    event?.preventDefault();
    if (!isValid()) return;
    setStepNum(2);
  };

  const submitOffer = () => {
    const params: IOfferParams = {
      offerId: offer?._id,
      listingId: offer ? offer.listingId : listing?._id,
      purchasePrice: purchasePrice.value(),
      depositAmount: depositAmount.value(),
      dueDiligencePeriod: dueDiligencePeriod.value(),
      closingPeriod: closingPeriod.value(),
      financingPeriod: financingPeriod.value(),
      exclusivityPeriod: exclusivityPeriod.value(),
      otherTerms: otherTerms.value(),
      loiFileIds: files?.[0]?._id ? [files[0]._id] : [],
      coverLetter: coverLetter.value(),
    };

    createOffer({
      variables: {
        params,
      },
    });
  };

  const g = listing?.guidance;
  const showGuidance = listing && (g?.dueDiligencePeriod || g?.closingPeriod || g?.depositAmount);
  return (
    <Margin>
      <ModalContainer data-cy="offer-modal-container">
        <Form onSubmit={(event) => advanceStep(event)}>
          <ModalHeader title="Make an offer" close={popModal} />
          <ModalContent>
            <Container>
              {stepNum === 1 ? (
                <>
                  {offer && <OfferCard offer={offer} type={OfferCardTypeEnum.Rows} />}
                  {g?.askingPrice && (
                  <>
                    <Text type={TextTypesEnum.Regular16} color={Colors.Grey700}>
                      Asking price
                    </Text>
                    <Text type={TextTypesEnum.Bold24} color={Colors.Black} margin="0 0 16px">
                      {`$${StringUtil.formatNumber(g?.askingPrice?.toString())}`}
                    </Text>
                  </>
                  )}
                  {showGuidance && (
                  <>
                    <Text type={TextTypesEnum.Medium16} color={Colors.Grey900} margin="0px 0px 8px">
                      Seller guidance
                    </Text>
                    <Flex>
                      {g.dueDiligencePeriod && (
                      <GuidanceItem>
                        <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
                          Diligence period
                        </Text>
                        <Text type={TextTypesEnum.Bold16} color={Colors.Grey900}>
                          {`${g.dueDiligencePeriod} days`}
                        </Text>
                      </GuidanceItem>
                      )}
                      {g.closingPeriod && (
                      <GuidanceItem>
                        <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
                          Closing period
                        </Text>
                        <Text type={TextTypesEnum.Bold16} color={Colors.Grey900}>
                          {`${g.closingPeriod} days`}
                        </Text>
                      </GuidanceItem>
                      )}
                      {g.depositAmount && (
                      <GuidanceItem>
                        <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
                          Deposit (EMD)
                        </Text>
                        <Text type={TextTypesEnum.Bold16} color={Colors.Grey900}>
                          {`$${StringUtil.formatNumber(g.depositAmount)}`}
                        </Text>
                      </GuidanceItem>
                      )}
                    </Flex>
                  </>
                  )}
                  {(g?.askingPrice || showGuidance) && <Divider margin="24px 0px" color={Colors.Grey200} />}
                  <Text type={TextTypesEnum.Regular16} color={Colors.Grey700} margin={offer ? '24px 0px 24px' : '0px 0px 24px'}>
                    {offer ? 'Enter your revised offer below to send a new offer that supercedes your previous one.' : 'Please enter the basic terms of your offer.'}
                  </Text>
                  <InputContainer>
                    <Input
                      label="Purchase price"
                      placeholder="Enter a price"
                      value={purchasePrice.value().toString()}
                      onChange={(_event, unmasked) => purchasePrice.setRawValue(unmasked)}
                      ref={purchasePrice.setRef}
                      error={purchasePrice.error()}
                      inputType={InputTypesEnum.Currency}
                      margin="0px 0px 32px"
                      autoFocus
                      data-cy="offer-modal-purchase-price-input"
                    />
                    <Input
                      label="Deposit"
                      placeholder="Enter a price"
                      value={depositAmount.value().toString()}
                      onChange={(_event, unmasked) => depositAmount.setRawValue(unmasked)}
                      ref={depositAmount.setRef}
                      error={depositAmount.error()}
                      inputType={InputTypesEnum.Currency}
                      margin="0px 0px 32px"
                      data-cy="offer-modal-deposit-input"
                    />
                    <Input
                      label="Due diligence period"
                      placeholder="Enter a number"
                      value={dueDiligencePeriod.value().toString()}
                      onChange={(_event, unmasked) => dueDiligencePeriod.setRawValue(unmasked)}
                      ref={dueDiligencePeriod.setRef}
                      error={dueDiligencePeriod.error()}
                      inputType={InputTypesEnum.Number}
                      unit="days"
                      margin="0px 0px 32px"
                      data-cy="offer-modal-due-diligence-input"
                    />
                    <Input
                      label="Closing period"
                      placeholder="Enter a number"
                      value={closingPeriod.value().toString()}
                      onChange={(_event, unmasked) => closingPeriod.setRawValue(unmasked)}
                      ref={closingPeriod.setRef}
                      error={closingPeriod.error()}
                      inputType={InputTypesEnum.Number}
                      unit="days"
                      margin="0px 0px 32px"
                      data-cy="offer-modal-closing-period-input"
                    />
                    <Toggle
                      label="Finance contingency"
                      value={financingContingency}
                      onChange={(active) => {
                        setFinancingContingency(active);
                        if (!active) financingPeriod.setRawValue(null);
                      }}
                      margin="0px 0px 32px"
                      cyYes="offer-modal-financing-contingency-toggle-yes"
                      cyNo="offer-modal-financing-contingency-toggle-no"
                    />
                    <Input
                      label="Financing period"
                      placeholder="Enter a number"
                      value={financingPeriod.value()?.toString()}
                      onChange={(_event, unmasked) => (financingPeriod.setRawValue(unmasked))}
                      ref={financingPeriod.setRef}
                      error={financingPeriod.error()}
                      inputType={InputTypesEnum.Number}
                      disabled={!financingContingency}
                      unit="days"
                      margin="0px 0px 32px"
                      data-cy="offer-modal-financing-period-input"
                    />
                    <Toggle
                      label="Exclusivity"
                      value={exclusivity}
                      onChange={(active) => {
                        setExclusivity(active);
                        if (!active) exclusivityPeriod.setRawValue(null);
                      }}
                      margin="0px 0px 32px"
                      cyYes="offer-modal-exclusivity-toggle-yes"
                      cyNo="offer-modal-exclusivity-toggle-no"
                    />
                    <Input
                      label="Excusivity period"
                      placeholder="Enter a number"
                      value={exclusivityPeriod.value()?.toString()}
                      onChange={(_event, unmasked) => (exclusivityPeriod.setRawValue(unmasked))}
                      ref={exclusivityPeriod.setRef}
                      error={exclusivityPeriod.error()}
                      inputType={InputTypesEnum.Number}
                      disabled={!exclusivity}
                      unit="days"
                      margin="0px 0px 32px"
                      data-cy="offer-modal-exclusivity-period-input"
                    />
                    <Label
                      text="Upload LOI (optional)"
                    />
                  </InputContainer>
                  {(!fileLoading && files?.length <= 0) ? (
                    <Dashed {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Flex align="center">
                        <Icon icon={Icons.FilePdfLight} color={Colors.Grey500} size={18} margin="0 8px 0 0" />
                        <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>
                          Drag a PDF file here or
                          &nbsp;
                          <Text
                            type={TextTypesEnum.Medium14}
                            as={TextAsEnum.Span}
                            color={Colors.Brand700 || Colors.Blurple700}
                          >
                            click to upload
                          </Text>
                        </Text>
                      </Flex>
                    </Dashed>
                  ) : (
                    <LOIFile file={files[0]} deleteFile={() => setFiles([])} />
                  )}
                  <Text type={TextTypesEnum.Regular12} color={Colors.Grey700} margin="4px 0px 32px">
                    Optionally upload your own LOI that the broker and seller will be able to download and browse.
                  </Text>
                  <Input
                    label="Other terms (optional)"
                    placeholder="Enter any additional terms"
                    value={otherTerms.value().toString()}
                    onChange={otherTerms.setValue}
                    ref={otherTerms.setRef}
                    error={otherTerms.error()}
                    inputType={InputTypesEnum.TextArea}
                    height="160px"
                    margin="0px 0px 32px"
                  />
                </>
              ) : (
                <>
                  <Text data-cy="add-cover-letter-text" type={TextTypesEnum.Medium16} color={Colors.Grey900} margin="0px 0px 8px">
                    Add a cover letter (optional)
                  </Text>
                  <Text type={TextTypesEnum.Regular16} color={Colors.Grey700} margin="0px 0px 24px">
                    A cover letter is a message sent to the broker along with your offer. Think of it as a personal introduction to the broker to explain why you are the best candidate to buy this property.
                  </Text>
                  <RichTextEditor
                    error={coverLetter.error()}
                    value={coverLetter?.value()?.toString() ?? ''}
                    onChange={(val: string) => {
                      // value can have left over p tags when content
                      // is cleared so we delete them manually.
                      // Would be better to do this internally somehow.
                      if (val.match(/^<p>\s*<\/p>$/)) {
                        coverLetter.setRawValue('');
                      } else {
                        coverLetter.setRawValue(val);
                      }
                    }}
                    placeholder="Submit a cover letter about yourself to help stand out"
                  />
                  <InlineAlert
                    margin="24px 0 0"
                    type={InlineAlertTypesEnum.YellowWarning}
                    text="Offers with cover letters are more likely to be considered by a broker."
                  />
                </>
              )}
            </Container>
            <Divider margin="24px 0 0" color={Colors.Grey200} />
          </ModalContent>
          <ModalFooter>
            <Flex justify="space-between" width="100%" align="center">
              <Flex align="center">
                <Text type={TextTypesEnum.Regular16} color={Colors.Grey900}>
                  Step
                  {' '}
                  {stepNum}
                  {' '}
                  of 2
                </Text>
                <StepCircle
                  active={stepNum === 1}
                  margin="0 8px 0 16px"
                  data-tip="Step 1"
                  onClick={() => setStepNum(1)}
                />
                <StepCircle
                  active={stepNum === 2}
                  data-tip="Step 2"
                  onClick={(e) => (stepNum === 1 ? advanceStep(e) : null)}
                />
              </Flex>
              <Flex>
                <Button
                  text="Cancel"
                  type={ButtonTypesEnum.Ghost}
                  size={ButtonSizesEnum.Medium}
                  onClick={() => popModal()}
                  margin="0px 8px 0px 0px"
                  htmlType="button"
                />
                {stepNum === 1 ? (
                  <Button
                    text="Next"
                    type={ButtonTypesEnum.Primary}
                    size={ButtonSizesEnum.Medium}
                    htmlType="submit"
                    data-cy="offer-modal-next-button"
                  />
                ) : (
                  <Button
                    text="Submit offer"
                    isLoading={loading}
                    type={ButtonTypesEnum.Primary}
                    size={ButtonSizesEnum.Medium}
                    onClick={() => submitOffer()}
                    htmlType="button"
                    data-cy="offer-modal-submit-button"
                  />
                )}
              </Flex>
            </Flex>
          </ModalFooter>
        </Form>
      </ModalContainer>
    </Margin>
  );
};

export default OfferModal;
