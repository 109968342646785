/* eslint-disable no-redeclare */
/* eslint-disable no-restricted-syntax */
import {
  ApolloServer, BaseContext, ContextFunction,
} from '@apollo/server';
import type { WithRequired } from '@apollo/utils.withrequired';
import { NextApiHandler, NextApiRequest, NextApiResponse } from 'next';
import { parse } from 'url';
import type { IncomingHttpHeaders } from 'http';
import { logger } from '@biproxi/logger';
import StatusCodeEnum from '@biproxi/models/enums/StatusCodeEnum';

interface IOptions<Context extends BaseContext> {
  context?: ContextFunction<Parameters<NextApiHandler>, Context>;
}

interface IHTTPRequestBody {
  query?: string;
  variables?: Record<string, any>;
  operationName?: string;
  extensions?: Record<string, any>;
}

interface IHTTPGraphQLRequest {
  method: string;
  headers: Map<string, string>;
  search: string;
  body: unknown;
}

interface IHTTPGraphQLContext {
  req: NextApiRequest;
  res: NextApiResponse;
  graphqlQuery?: any;
}

export type GraphQLAPIHandler =
  (req: any, res: any) => Promise<GraphQLHandlerResponse>

type GraphQLHandlerResponse = {
  data: any;
  errors: any;
  status: StatusCodeEnum
}

const defaultContext: ContextFunction<[], any> = async () => ({});

function parseRequest(req: NextApiRequest) {
  const url: string = req?.url as string ?? '';
  const search = parse?.(url)?.search ?? '';
  const body: IHTTPRequestBody = req?.body ?? {};

  return { search, body };
}

function normalizeHeaders(headers: IncomingHttpHeaders): Map<string, string> {
  const newHeaders = new Map<string, string>();
  for (const [key, value] of Object.entries(headers)) {
    if (value !== undefined) {
      newHeaders.set(key, Array.isArray(value) ? value.join(', ') : value);
    }
  }

  return newHeaders;
}

function constructHTTPGraphQLRequest(context: IHTTPGraphQLContext): IHTTPGraphQLRequest {
  const { req } = context;
  const { search } = parseRequest(req);
  const { graphqlQuery } = context;
  const reqBodyResponse = graphqlQuery;
  const httpGraphQLRequest: IHTTPGraphQLRequest = {
    method: reqBodyResponse?.method ? reqBodyResponse.method.toUpperCase() : 'POST',
    headers: normalizeHeaders(req.headers),
    body: reqBodyResponse,
    search,
  };

  return httpGraphQLRequest;
}

function startNextGraphQLHandler(
  server: ApolloServer<BaseContext>,
  options?: IOptions<BaseContext>,
): GraphQLAPIHandler

function startNextGraphQLHandler<Context extends BaseContext>(
  server: ApolloServer<Context>,
  options: WithRequired<IOptions<Context>, 'context'>,
): GraphQLAPIHandler

function startNextGraphQLHandler<Context extends BaseContext>(
  server: ApolloServer<Context>,
  options?: IOptions<Context>,
): GraphQLAPIHandler {
  const contextFunction = options?.context || defaultContext;
  return async function x(req, res): Promise<GraphQLHandlerResponse> {
    const handlerResponse: GraphQLHandlerResponse = {
      data: null,
      errors: null,
      status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    };
    const context = await contextFunction(req, res);
    try {
      const httpGraphQLRequest: any = constructHTTPGraphQLRequest(context);
      const httpGraphQLResponse = await server
        .executeHTTPGraphQLRequest({
          httpGraphQLRequest,
          context: () => contextFunction(req, res),
        });
      for (const [key, value] of httpGraphQLResponse.headers) {
        res.setHeader(key, value);
      }

      res.statusCode = httpGraphQLResponse?.status || 200;
      if (httpGraphQLResponse.body.kind === 'complete') {
        // await server.stop();
        const bodyData = httpGraphQLResponse?.body?.string || '{}';
        const parsedBodyData = typeof bodyData === 'string' ? JSON?.parse(bodyData) : bodyData;
        if (parsedBodyData?.errors) {
          handlerResponse.errors = parsedBodyData?.errors;
          handlerResponse.status = StatusCodeEnum.INTERNAL_SERVER_ERROR;
          return handlerResponse;
        }
        handlerResponse.data = typeof bodyData === 'string' ? JSON?.parse(bodyData)?.data : bodyData;
        handlerResponse.status = httpGraphQLResponse?.status || StatusCodeEnum.OK;
        // res.send(httpGraphQLResponse.body.string);
      } else {
        for await (const chunk of httpGraphQLResponse.body.asyncIterator) {
          res.write(chunk);
        }
        // await server.stop();
        // res.end();
      }
    } catch (e) {
      logger.warn(`There was an error with the Next GraphQL Handler: ${e}`);
      res.status(400).json({
        body: (e as Error).message,
      });
    }
    return handlerResponse;
  };
}

export default startNextGraphQLHandler;
