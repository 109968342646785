enum LeadStatesEnum {
  NotContacted = 'Not contacted', // default
  AttemptingContact = 'Attempting contact',
  NotInterested = 'Not interested',
  PropertyTourScheduled = 'Property tour scheduled',
  ReviewingDiligence = 'Reviewing diligence',
  OfferExpected = 'Offer expected',
  OfferReceived = 'Offer received',
}

export default LeadStatesEnum;
