import { logger } from '@biproxi/logger';
import {
  all,
} from 'redux-saga/effects';

export default function* notificationSaga() {
  try {
    yield all([]);
  } catch (e) {
    logger.error('notificationSaga() error', e);
  }
}
