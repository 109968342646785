import gql from 'graphql-tag';

const DELETE_LISTING = gql`
  mutation DeleteListing($params: ListingWhere) {
    deleteListings(where: $params) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;

export default DELETE_LISTING;
