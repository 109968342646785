import { GetServerSidePropsContext } from 'next';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import { logger } from '@biproxi/logger';
import IPageConfiguration, { IPageContext } from '../models/interfaces/IPageConfiguration';
import { initializeApollo } from '../graphql/client';
import GET_USER from '../graphql/queries/user.query';
import Auth from './Auth';
import * as UrlUtil from './UrlUtil';
import UrlParams from '../models/interfaces/UrlParams';

const pageContextFromNextServerSidePropsContext = (context: GetServerSidePropsContext): IPageContext => ({
  query: context.query,
  resolvedUrl: context.resolvedUrl,
  hostName: context.req.headers?.host,
});

/**
 * This is very broken and needs fixing. The client.query just doesn't work at all.
 * No error or nothing, just returns undefined immediately without any network activity.
 * Not sure how to fix. Currently there are work arounds in place.
 *
 * Need to investigate all situation with client.query/mutate.
 */
const getPageConfiguration = async (context: GetServerSidePropsContext): Promise<IPageConfiguration> => {
  const client = initializeApollo(context);

  let getUserResponse = null;

  if (Auth.getToken(context.req.cookies)) {
    type TData = {
      user: IUserGraphQL | null;
    }

    try {
      getUserResponse = await client.query<TData>({
        query: GET_USER,
      });
    } catch (e) {
      logger.warn(`There was an issue getting user info in the getPageConfiguration() method in NextUtil: ${e}`);
    }
  }
  return {
    user: getUserResponse?.data?.user ?? null,
    listing: null,
    lead: null,
    privateEvent: null,
    initialApolloState: client.cache.extract(),
    initialReduxState: {},
    pageContext: pageContextFromNextServerSidePropsContext(context),
  };
};

const hasWindow = () => typeof window !== 'undefined';

const query = (config: IPageConfiguration | undefined): UrlParams => (hasWindow()
  ? UrlUtil.parse(window.location.toString()).query
  : config?.pageContext?.query ?? {});

const isSPAUrl = (url: string): boolean => {
  const { pathname } = UrlUtil.parse(url);
  const segments = pathname.split('/');
  return segments?.[1] === 'app';
};

const NextUtil = {
  pageContextFromNextServerSidePropsContext,
  getPageConfiguration,
  hasWindow,
  query,
  isSPAUrl,
};

export default NextUtil;
