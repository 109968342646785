import gql from 'graphql-tag';
import LeadFragments from '../fragments/lead.fragments';

const GET_LEAD = gql`
  query Lead($params: GetLeadParams!) {
    lead(params: $params) {
      ...LeadFields
      ...LeadLoiFiles
      ...LeadOffers
      ...LeadFileAccessLevel
      ...LeadPrivateListingAccess
      ...LeadNotes
      ...LeadSellerNotes
      ...LeadUser
      ...LeadListing
      ...LeadMetrics
      ...LeadMeta
      ...LeadAnalyticsEvents
      ...LeadChannel
    }
  }

  ${LeadFragments.fields}
  ${LeadFragments.loiFiles}
  ${LeadFragments.offers}
  ${LeadFragments.fileAccessLevel}
  ${LeadFragments.privateListingAccess}
  ${LeadFragments.notes}
  ${LeadFragments.sellerNotes}
  ${LeadFragments.user}
  ${LeadFragments.listing}
  ${LeadFragments.metrics}
  ${LeadFragments.meta}
  ${LeadFragments.analyticsEvents}
  ${LeadFragments.channel}
`;

export default GET_LEAD;
