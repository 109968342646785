import { logger } from '@biproxi/logger';
import axios from 'axios';

export const sendEmail = async (emailConfig: object) => {
  let result;
  try {
    const res = await axios.post('/api/email/send-email', emailConfig);
    result = res?.data;
  } catch (e) {
    logger.error(e as any);
  }
  return result;
};
