import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import IListingsQuery from '@biproxi/models/interfaces/IListingsQuery';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import PermissionsUtil from '@biproxi/models/utils/PermissionsUtil';
import Flex from '../elements/Flex';
import SearchListingsMap from '../components/SearchListingsMap';
import SearchListingsResults from '../components/SearchListingsResults';
import { ListingSelectors } from '../redux/listing.redux';
import { media, useMobileMedia, useTabletMedia } from '../utils/MediaQuery';
import { useAppSelector, AppState, useAppDispatch } from '../redux/store';
import PaginationLimitsEnum from '../models/enums/PaginationLimitsEnum';
import getPropertyBounds from '../utils/PropertyBoundsUtil';
import useUser from '../hooks/useUser.hook';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from '../components/modal/Modal';
import SubscriptionPromoDashboardBanner from '../components/SubscriptionPromoDashboardBanner';
import SRP_LIST_LISTINGS from '../graphql/queries/srpListingsPage.query';
import Loader, { LoaderSizes } from '../elements/Loader';
import * as UrlUtil from '../utils/UrlUtil';
import Colors from '../styles/Colors';
import useUserPermissions, { Neo4jReducerStatusEnum } from '../hooks/useUserPermissions.hook';

const Container = styled.div<{ height?: string }>`
  position: relative;
  height: ${({ height }) => height};
  width: calc(100vw - 60px);
  display: flex;
  overflow-x: hidden;

  ${media.mobile} {
    width: 100vw;
  }

  ${media.tablet} {
    width: 100vw;
  }
`;

const OuterContainer = styled.div`
  ${media.mobile} {
      width: 100vw;
    }

  ${media.tablet} {
    width: 100vw;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

type ListingsContainerProps = {
  srpLayoutState: SRPLayoutEnum;
}

const ListingsContainer = styled.div<ListingsContainerProps>`
  width: ${({ srpLayoutState }) => {
    switch (srpLayoutState) {
      case SRPLayoutEnum.Half:
        return 'calc(60vw - 60px)'; // not 50 / 50 split, we show more of listings than the map
      case SRPLayoutEnum.Map:
        return '0';
      case SRPLayoutEnum.Listings:
      default:
        return '100%';
    }
  }};
`;

type MobileProps = {
  active: boolean;
}

/**
 * Little hack that we will probably
 * want to change in the future. The bounds from the search
 * only work correctly if the map is rendered so we just hide it behind
 * the other screen.
 */
const MobileContainer = styled.div<MobileProps>`
  ${(props) => {
    if (props.active) {
      return `
        display: flex;
        flex: 1;
      `;
    }
    return `
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -100;
      `;
  }}
`;

export enum SRPLayoutEnum {
  Listings = 'Listings', // listings full screen
  Map = 'Map', // map full screen
  Half = 'Half', // map/listings half screen
}

type SearchPageProps = {};

const SearchPage: React.FC<SearchPageProps> = () => {
/** ******************************************************************************
*  Contentful STUFF
******************************************************************************* */

  /** Contentful State - Redux */
  const {
    contentful: {
      brandCollection: {
        fetched,
      },
    },
  } = useAppSelector((state: AppState) => state);

  /** ******************************************************************************
*  Contentful STUFF
******************************************************************************* */

  /** State */
  // const subscription = useAppSelector(UserSelectors.subscription);
  const [cachedListings, setCachedListings] = React.useState([]);
  const [paginationOffset, setPaginationOffset] = React.useState<number>(0);
  const [srpLayoutState, setSRPLayoutState] = React.useState<SRPLayoutEnum>(SRPLayoutEnum.Half);
  const [bannerVisible, setBannerVisible] = React.useState<boolean>(true); // fix so that people that are upgraded don't see the banner

  /** Redux */
  const { mobileSRPMapOpen } = useAppSelector((state: AppState) => state.app);
  const search = useAppSelector(ListingSelectors.search);
  const { query, isSavedSearch } = search;
  const savedSearchQuery = useAppSelector(ListingSelectors.savedSearchQuery);
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  /** GraphQL */
  type Data = {
    listings: {
      data: IListingGraphQL[];
      info: {
        totalCount: number;
      }
    }
  }

  type Vars = {
    params: IListingsQuery;
  }
  const { data, loading, refetch } = useQuery<Data, Vars>(SRP_LIST_LISTINGS, {
    variables: {
      params: {
        pagination: {
          offset: paginationOffset,
          limit: (isMobile || isTablet) ? PaginationLimitsEnum.SRPListingsMobile : PaginationLimitsEnum.SRPListings,
        },
        query: isSavedSearch ? savedSearchQuery?.query : query,
      },
    },
    onCompleted: (data) => {
      // kind of useless since we are only reading from the apollo cache
      // Should probably use a custom hook for this whole query and read from the redux cache on mount
      setCachedListings(data.listings.data);
    },
    context: {
      debounceKey: 'QUERY_LISTINGS',
    },
    notifyOnNetworkStatusChange: true,
  });

  /** Idk random variables */
  const finalListings = data?.listings.data ?? cachedListings;
  const isLoading = loading || finalListings.length === 0;
  const totalListings = data?.listings?.info?.totalCount ?? 0;

  // Quick solution so outliers on the map like places in the Philippines don't mess up the map bounds.
  // We want to focus the inital render on the US for the time being.
  // const { neSwPoints } = (() => {
  //   // This works but we have to be careful that this behavior doesn't change.
  //   // Ultimately this serves as a check to whether any filter if set.
  //   // if none are, these fields don't exist, but if any are, these fields do exist.
  //   if (!query?.keywords && !query?.address?.country) {
  //     return {
  //       neSwPoints:
  //       {
  //         ne: {
  //           lat: 46.8642414,
  //           lng: -77.07214069999999,
  //         },
  //         sw: {
  //           lat: 30.2477554,
  //           lng: -121.4954319,
  //         },
  //       },
  //     };
  //   }
  //   return getPropertyBounds(finalListings);
  // })();
  const { neSwPoints } = getPropertyBounds(finalListings);

  const { user, userId } = useUser();

  const userPermissions = useUserPermissions({ userId });

  const dispatch = useAppDispatch();
  const openReminderModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.LoginUpgradeAccountReminder,
      props: {},
    }),
  );

  const setBannerVisibleCallback = React.useCallback(() => setBannerVisible(!bannerVisible), [bannerVisible]);

  // lol
  const showBanner = !PermissionsUtil.isSubscribed(userPermissions) && bannerVisible && (!userPermissions?.loading && userPermissions.status !== Neo4jReducerStatusEnum.Idle);

  /** Effects */
  React.useEffect(() => {
    if (paginationOffset) {
      refetch();
    }
  }, [paginationOffset]);

  React.useEffect(() => {
    refetch();
  }, [query]);

  React.useEffect(() => {
    const { query } = UrlUtil.parse(window.location.toString());
    // have to use router query because if a user registers, the quick start guide modal is pushed and we dont want this modal to clash with it
    // once the quick start guide modal is popped, the subscription modal is pushed
    if (user && !user?.viewedSubscriptionEarlyAccessModal && !Object?.keys(query)?.includes('modalType')) {
      openReminderModal();
    }
  }, [user]);

  /* Render */
  return fetched ? (
    <OuterContainer>
      {showBanner && (
        <SubscriptionPromoDashboardBanner
          setBannerVisible={setBannerVisibleCallback}
        />
      )}
      <Container
        height={
          showBanner ? 'calc(100vh - 136px)' : 'calc(100vh - 56px)' // might want to remove in the future or restructure html/css for a better model lol. Basically, the MainNavigation component has a height of 56px, so if the banner isn't visible, we want the listings and map to take 100vh - 56px. The banner is 80 px, so if the banner is visible, we want (100vh - (56px + 80px))
        }
      >
        {isMobile ? (
          <>
            <MobileContainer active={!mobileSRPMapOpen}>
              <SearchListingsResults
                listings={finalListings}
                loading={loading}
                querying={loading}
                isSavedSearch={isSavedSearch}
                totalListings={totalListings}
                paginationOffset={paginationOffset}
                setPaginationOffset={setPaginationOffset}
                srpLayoutState={srpLayoutState}
                setSRPLayoutState={setSRPLayoutState}
              />
            </MobileContainer>
            <MobileContainer active={mobileSRPMapOpen}>
              <SearchListingsMap
                listings={finalListings}
                loading={isLoading}
                bounds={neSwPoints}
                srpLayoutState={srpLayoutState}
                setSRPLayoutState={setSRPLayoutState}
                mapOptions={
                {
                  fullscreenControl: false,
                  zoomControl: false,
                }
              }
              />
            </MobileContainer>
          </>
        ) : (
          <>
            <ListingsContainer srpLayoutState={srpLayoutState}>
              {!!fetch && (
              <SearchListingsResults
                listings={finalListings}
                loading={loading}
                querying={loading}
                isSavedSearch={isSavedSearch}
                totalListings={totalListings}
                paginationOffset={paginationOffset}
                setPaginationOffset={setPaginationOffset}
                srpLayoutState={srpLayoutState}
                setSRPLayoutState={setSRPLayoutState}
              />
              )}
            </ListingsContainer>
            {srpLayoutState !== SRPLayoutEnum.Listings && (
            <Flex width="100%" justify="center" align="center">
              {isLoading ? (
                <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
              ) : (
                <SearchListingsMap
                  listings={finalListings}
                  loading={isLoading}
                  bounds={neSwPoints}
                  srpLayoutState={srpLayoutState}
                  setSRPLayoutState={setSRPLayoutState}
                  mapOptions={
                    {
                      srpFullScreenControls: true,
                      fullscreenControl: false,
                      zoomControlOptions: {
                        // eslint-disable-next-line no-undef
                        position: google.maps.ControlPosition.TOP_RIGHT,
                      },
                    }
                  }
                />
              )}
            </Flex>
            )}
          </>
        )}
      </Container>
    </OuterContainer>
  ) : (
    <OuterContainer>
      <LoadingContainer>
        <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
      </LoadingContainer>
    </OuterContainer>
  );
};

export default SearchPage;
