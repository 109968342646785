import React from 'react';
import styled from '@emotion/styled';
import TextareaAutosize from 'react-textarea-autosize';
import * as Polished from 'polished';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from './Text';
import Flex from './Flex';
import Label from './Label';
import KeyCodesEnum from '../models/enums/KeyCodesEnum';

type StyleProps = {
  error?: string;
  focused?: boolean;
  hovered?: boolean;
  maxHeight?: string;
}

const Container = styled.div<StyleProps>`
  max-height: ${({ maxHeight }) => maxHeight || '144px'};
  border-radius: 4px;
  transition: all 0.2s;
  display: flex;
  box-sizing: border-box;
  overflow: auto;
  position: relative;

  border: 1px solid ${(props) => {
    if (props.error) return Colors.Red500;
    if (props.focused) return Colors.Brand700 || Colors.Blurple700;
    if (props.hovered) return Colors.Grey400;
    return Colors.Grey300;
  }};

  box-shadow: ${(props) => {
    if (props.error && props.focused) return `0px 0px 0px 4px ${Polished.rgba(Colors.Red500, 0.10)}`;
    if (props.focused) return `0px 0px 0px 4px ${Polished.rgba(Colors.Brand700 || Colors.Blurple700, 0.10)}`;
    return null;
  }};
`;

const Input = styled(TextareaAutosize)`
  width: 100%;
  font-family: "aktiv-grotesk";
  font-weight: 400;
  color: ${Colors.Grey900};
  font-size: 1.6rem;
  line-height: 2.4rem;
  border-radius: 4px;
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  padding: 12px 16px;

  ::placeholder {
    color: ${Colors.Grey400};
  }
`;

const SendButtonContainer = styled.div`
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
`;

type TextAreaWithButtonProps = {
  value: string;
  onChange: (e: any) => void
  onEnter: (e: React.KeyboardEvent<HTMLTextAreaElement>) => Promise<void>;
  error?: string;
  label?: string;
  SendButton: React.ReactNode,
  placeholder?: string,
  maxHeight?: string,
  'data-cy'?: string,
};

const TextAreaWithButton: React.FC<TextAreaWithButtonProps> = ({
  value,
  onChange,
  onEnter,
  error,
  label,
  SendButton,
  placeholder,
  maxHeight,
  'data-cy': dataCy,
}) => {
  /** State */
  const [hovered, setHovered] = React.useState(false);
  const [focused, setFocused] = React.useState(false);

  return (
    <Container
      focused={focused}
      hovered={hovered}
      error={error}
      maxHeight={maxHeight}
    >
      <Flex justify="space-between" align="center">
        {label && <Label text={label} />}
        {error && <Text type={TextTypesEnum.Medium12} color={Colors.Red500} margin="0 0 4px">{error}</Text>}
      </Flex>
      <Input
        data-cy={dataCy}
        autoFocus
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        placeholder={placeholder || 'Enter your text'}
        onChange={onChange}
        onKeyPress={(e) => {
          if (e.which === KeyCodesEnum.ENTER && !e.shiftKey) {
            e.preventDefault();
            onEnter(e);
          }
        }}
        value={value}
      />
      <SendButtonContainer>
        {SendButton}
      </SendButtonContainer>
    </Container>
  );
};

export default TextAreaWithButton;
