import React from 'react';
import styled from '@emotion/styled';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import StringUtil from '@biproxi/models/utils/StringUtil';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { useMutation } from '@apollo/client';
import PrivateEventUtil from '@biproxi/models/utils/PrivateEventUtil';
import PrivateEventStatesEnum from '@biproxi/models/enums/PrivateEventStatesEnum';
import { KB_PRIVATE_EVENT_USE_URL } from '@biproxi/models/externalLinks';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { media } from '../../utils/MediaQuery';
import Flex from '../../elements/Flex';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Colors from '../../styles/Colors';
import Text, { TextAsEnum, TextTypesEnum } from '../../elements/Text';
import ACCEPT_PRIVATE_EVENT_INVITATION from '../../graphql/mutations/acceptPrivateEventInvitation.mutation';
import useNavigateToPrivateEventParticipation from '../../hooks/useNavigateToPrivateEventParticipation.hook';
import Icons from '../../elements/Icons';
import Divider from '../../elements/Divider';
import useUser from '../../hooks/useUser.hook';
import LinkText from '../../elements/LinkText';
import { ModalTypesEnum } from './Modal';

const Container = styled.div`
  width: 432px;

  ${media.mobile} {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const GreyBox = styled.div`
  background: ${Colors.Grey100};
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
`;

export type AcceptPrivateEventInvitationModalProps = {
  privateEvent: IPrivateEventGraphQL
};

const AcceptPrivateEventInvitationModal: React.FC<AcceptPrivateEventInvitationModalProps> = ({
  privateEvent,
}) => {
  /** Hooks */
  const navigateToPrivateEventParticipation = useNavigateToPrivateEventParticipation();
  const { user } = useUser();

  // example login/register link a user will get in their email when invited to a private event
  // http://localhost:3000/app/login?to=/app/dashboard/events&inviteId=VqJluAeMi&modalType=Login

  /** Actions */
  const dispatch = useAppDispatch();
  const popAllModals = () => dispatch(
    AppActions.popAllModals(),
  );

  const viewPSA = () => dispatch(
    AppActions.previewFiles({
      fileId: privateEvent?.psaFileIds?.[0],
      files: privateEvent?.psaFiles,
      track: false,
    }),
  );
  const viewAdditionalTerms = () => {
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.DisplayTextBlock,
        props: {
          title: 'Additional terms',
          text: privateEvent?.contingencies?.additionalTerms,
        },
      }),
    );
  };

  /** GraphQL */
  const [acceptPrivateEventInvitation, { loading }] = useMutation(ACCEPT_PRIVATE_EVENT_INVITATION, {
    variables: {
      params: {
        privateEventId: privateEvent?._id,
        listingId: privateEvent?.listing?._id,
        listingUserId: privateEvent?.listing?.meta?.createdBy,
        listingOrganizationId: privateEvent?.listing?.organizationId,
      },
    },
    onCompleted: async () => {
      navigateToPrivateEventParticipation(privateEvent?._id);
      popAllModals();
    },
  });

  /** Render */
  const blocked = PrivateEventUtil.getParticipantFromEmail(user?.email, privateEvent)?.disabled;
  const canceled = privateEvent?.state === PrivateEventStatesEnum.Canceled;
  const finished = privateEvent?.state === PrivateEventStatesEnum.Finished;

  function Blocked() {
    return (
      <>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14} margin="0 0 8px">
          You have been disabled from participation in this event.
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Regular14}>
          Your invitation to this event has been blocked. If you believe that this is a mistake,
          please contact the event organizer or Biproxi support.
        </Text>
      </>
    );
  }

  function Finished() {
    return (
      <>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14} margin="0 0 8px">
          This event has already finished.
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Regular14}>
          You were too late to accept your event invitation and the event has already finished.
        </Text>
      </>
    );
  }

  function Canceled() {
    return (
      <>
        <Text color={Colors.Grey900} type={TextTypesEnum.Bold14} margin="0 0 8px">
          This event has been canceled.
        </Text>
        <Text color={Colors.Grey900} type={TextTypesEnum.Regular14}>
          The event organizer has canceled this event before you could accept your invite.
          For more information, please reach out to the event organizer.
        </Text>
      </>
    );
  }

  function EventInvite() {
    return (
      <>
        <GreyBox>
          <Text color={Colors.Grey900} type={TextTypesEnum.Bold14} margin="0 0 8px">
            You have been invited to participate in a Private Event for
            {' '}
            {ListingUtil.name(privateEvent?.listing, { allButAddress1: true })}
            .
          </Text>
          <Text color={Colors.Grey900} type={TextTypesEnum.Regular14}>
            For more information on how private events work,
            &nbsp;
            <Text
              type={TextTypesEnum.Regular14}
              as={TextAsEnum.Span}
              color={Colors.Brand700 || Colors.Blurple700}
              onClick={() => window.open(KB_PRIVATE_EVENT_USE_URL, '_blank')}
            >
              click here.
            </Text>
          </Text>
        </GreyBox>
        <Text color={Colors.Black} type={TextTypesEnum.Bold24} margin="16px 0px">
          {ListingUtil.name(privateEvent?.listing, { allButAddress1: true })}
        </Text>
        <Row>
          <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
            Starting bid
          </Text>
          <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
            {privateEvent?.settings?.startingBid ? `$${StringUtil.formatNumber(privateEvent?.settings?.startingBid)}` : '-'}
          </Text>
        </Row>
        <Row>
          <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
            Bid increments
          </Text>
          <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
            {privateEvent?.settings?.bidIncrement ? `$${StringUtil.formatNumber(privateEvent?.settings?.bidIncrement)}` : '-'}
          </Text>
        </Row>
        <Row>
          <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
            Start date
          </Text>
          <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
            {privateEvent?.startsAt ? TimeUtil.format(privateEvent?.startsAt, 'ff, ZZZZ', TimeUtil.currentBrowserTimezone()) : '-'}
          </Text>
        </Row>
        <Row>
          <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
            End date
          </Text>
          <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
            {privateEvent?.endsAt ? TimeUtil.format(privateEvent?.endsAt, 'ff, ZZZZ', TimeUtil.currentBrowserTimezone()) : '-'}
          </Text>
        </Row>
        <Row>
          <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
            Diligence period
          </Text>
          <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
            {privateEvent?.contingencies?.dueDiligencePeriod ? `${privateEvent.contingencies.dueDiligencePeriod} days` : '-'}
          </Text>
        </Row>
        <Row>
          <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
            Closing period
          </Text>
          <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
            {privateEvent?.contingencies?.closingPeriod ? `${privateEvent.contingencies.closingPeriod} days` : '-'}
          </Text>
        </Row>
        <Row>
          <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small}>
            Earnest money deposit
          </Text>
          <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
            {privateEvent?.contingencies?.depositAmount ? `$${StringUtil.formatNumber(privateEvent.contingencies.depositAmount)}` : '-'}
          </Text>
        </Row>
        <Row>
          <Text color={Colors.Grey500} type={TextTypesEnum.Regular14Small} margin="0 0 4px">
            Additional terms
          </Text>
          {privateEvent?.contingencies?.additionalTerms ? (
            <LinkText
              type={TextTypesEnum.Regular14Small}
              onClick={viewAdditionalTerms}
            >
              View
            </LinkText>
          ) : (
            <Text color={Colors.Grey900} type={TextTypesEnum.Bold14Small}>
              -
            </Text>
          )}
        </Row>
        {privateEvent?.psaFiles?.length > 0 && (
        <Button
          text="View PSA"
          icon={Icons.FilePdfLight}
          iconColor={Colors.Grey900}
          margin="16px 0 0"
          iconSize={14}
          onClick={() => {
            viewPSA();
          }}
          type={ButtonTypesEnum.Outline}
          size={ButtonSizesEnum.Large}
          isFullWidth
        />
        )}
        <Divider margin="24px 0px" />
        <Flex width="100%">
          <Button
            text="Decline"
            type={ButtonTypesEnum.Outline}
            size={ButtonSizesEnum.Medium}
            onClick={() => popAllModals()}
            margin="0px 8px 0px 0px"
            isFullWidth
          />
          <Button
            text="Accept"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            isLoading={loading}
            onClick={acceptPrivateEventInvitation}
            isFullWidth
          />
        </Flex>
      </>
    );
  }

  /** Render */
  return (
    <ModalContainer>
      <ModalHeader title="Pending Event Invitation" close={popAllModals} />
      <ModalContent>
        <Container>
          {(() => {
            if (blocked) return <Blocked />;
            if (finished) return <Finished />;
            if (canceled) return <Canceled />;
            return <EventInvite />;
          })()}
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default AcceptPrivateEventInvitationModal;
