enum OfferStateEnum {
  New = 'New', // Offer has not been seen yet.
  Active = 'Active', // Offer has been seen and it is the latest one.
  Old = 'Old', // Offer has been revised and this is a previous one.
  Rejected = 'Rejected', // Offer has been rejected by seller.
  Withdrawn = 'Withdrawn', // Offer has been withdrawn by buyer.
  Accepted = 'Accepted'
}

export default OfferStateEnum;
