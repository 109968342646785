import React from 'react';
import { usePopper } from 'react-popper';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import useOnclickOutside from 'react-cool-onclickoutside';
import * as PopperJS from '@popperjs/core';
import PopperPlacementTypes from '../models/enums/PopperPlacementTypes';
import ZIndexes from '../styles/ZIndexes';

const dropAndFadeIn = keyframes`
  from {
    opacity: 0;
    top: -20px;
    visibility: hidden;
  }

  to {
    opacity: 1;
    top: 0px;
    visibility: visible;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
`;

const MenuContainer = styled.div<{ visible: boolean, rendered: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  width: fit-content;
  z-index: ${ZIndexes.Anchor};
  animation: ${(props) => {
    if (!props.rendered) return null;
    if (props.visible) return css`${dropAndFadeIn} 0.3s forwards`;
    return css`${fadeOut} 0.3s forwards`;
  }};

`;

const InnerContainer = styled.div``;

function isFunction(fn: any): fn is any {
  return typeof fn === 'function';
}

export enum MenuEventTypes {
  MouseEnter = 'mouseenter',
  MouseLeave = 'mouseleave',
  MouseDown = 'mousedown',
}

export type AnchorProps = {
  anchorRef: any;
  children?: React.ReactNode | ((closeAnchor: Function) => React.FC) | any;
  placement?: PopperJS.Placement;
  offset?: [number, number];
  closeOnClickInside?: boolean;
  closeOnClickOutside?: boolean;
}

/**
 * Pass in menuItems to render a list of items.
 * Pass in children to render whatever you want.
 * Pass in both to render whatever you want above a list of items.
 * TODO: Add hover event listeners, animations, and more menu item types.
 */
const Anchor: React.FC<AnchorProps> = ({
  anchorRef,
  children,
  placement = PopperPlacementTypes.BottomEnd,
  offset = [0, 8],
  closeOnClickInside = true,
  closeOnClickOutside = true,
}) => {
  const [visible, setVisible] = React.useState(false);
  const [rendered, setRendered] = React.useState(false);
  const popperRef = React.useRef(null);

  const { styles, attributes } = usePopper(
    anchorRef.current,
    popperRef.current,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset, // [x, y]
          },
        },
      ],
    },
  );

  // Handle opening and closing menu.
  const handleVisibility = React.useCallback((isVisible?: boolean) => {
    if (!rendered) setRendered(true); // don't animate until rendered
    setVisible((prevVisible) => (isVisible || !prevVisible));
  }, []);

  // Add event listeners to anchor ref.
  React.useEffect(() => {
    anchorRef?.current?.addEventListener('mousedown', () => handleVisibility());
    return () => {
      anchorRef?.current?.removeEventListener('mousedown', () => handleVisibility());
    };
  }, [handleVisibility]);

  // Close menu on outside click.
  const onClickOutside = useOnclickOutside((event) => {
    if ((event.target instanceof Element) && !anchorRef?.current?.contains(event.target) && visible && closeOnClickOutside) {
      handleVisibility(false);
    }
  });

  /** Render */
  return (
    <MenuContainer
      ref={popperRef}
      style={styles.popper}
      {...attributes.popper}
      visible={visible}
      rendered={rendered}
    >
      <InnerContainer
        ref={onClickOutside}
        onClick={() => (closeOnClickInside ? handleVisibility(false) : null)}
      >
        {isFunction(children) ? children(() => { handleVisibility(false); }) : children}
      </InnerContainer>
    </MenuContainer>
  );
};

export default Anchor;
