import React from 'react';
import { useMutation } from '@apollo/client';
import * as IUserService from '@biproxi/models/services/IUserService';
import * as UserJoi from '@biproxi/models/joi/user.joi';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import AuthenticationStrategyEnum from '@biproxi/models/enums/AuthenticationStrategyEnum';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Input, { InputTypesEnum } from '../../elements/Input';
import REGISTER from '../../graphql/mutations/register.mutation';
import Auth from '../../utils/Auth';
import {
  AuthenticationModal,
  Content,
  OAuthScreen,
  AuthLoading,
} from '../AuthenticationModalLayout';
import Text, { TextTypesEnum } from '../../elements/Text';
import LinkText from '../../elements/LinkText';
import Flex, { Spacer } from '../../elements/Flex';
import Colors from '../../styles/Colors';
import Divider from '../../elements/Divider';
import { AppActions } from '../../redux/app.redux';
import { UserActions } from '../../redux/user.redux';
import { ModalTypesEnum } from './Modal';
import { AppState, useAppDispatch, useAppSelector } from '../../redux/store';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import { Icons } from '../../elements/Icon';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import UtmUtil from '../../utils/UTMUtil';

export type RegisterModalProps = {
  isStandalone?: boolean;
};

const RegisterModal: React.FC<RegisterModalProps> = ({
  isStandalone = false,
}) => {
  /** Hooks */
  const formParams: UseFormParams = {
    fields: {
      email: '',
      password: '',
    },
    fieldOrder: ['/email', '/password'],
    schema: UserJoi.registerUserParamsSchema,
  };

  const {
    controllers: {
      email,
      password,
    },
    isValid,
    setFieldErrors,
  } = useForm(formParams);

  /** State */
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [confirmPasswordError, setConfirmPasswordError] = React.useState('');
  const { oAuthLoading, oAuthError, authRedirectLoading } = useAppSelector((state: AppState) => state.user);

  /** Actions */
  const dispatch = useAppDispatch();

  const replaceModalWithLogin = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.Login,
      props: {
        isStandalone,
      },
    }),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const replaceModalWithCompleteRegister = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.CompleteRegister,
      props: {
        isStandalone,
      },
    }),
  );

  const startOAuthFlow = (strategy: AuthenticationStrategyEnum) => dispatch(
    UserActions.startOAuthFlow({
      strategy,
      isStandalone,
    }),
  );

  /** GraphQL */
  type TPayload = IUserService.TRegisterUserPayload;

  type TData = {
    register: IUserService.IAuthenticateUserResponse;
  };

  const [registerMutation, { loading }] = useMutation<TData, TPayload>(REGISTER, {
    variables: {
      params: {
        email: email.value().toString(),
        password: password.value().toString(),
      },
    },
    onCompleted: async ({ register: { token, hubspotToken } }) => {
      Auth.setToken(token, hubspotToken);
      replaceModalWithCompleteRegister();
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        pushToast({
          message,
          type: ToastTypesEnum.Error,
          duration: 5000,
        });
      }
    },
  });

  const register = (event?: React.FormEvent) => {
    if (event) event.preventDefault();
    if (!isValid()) return;

    if (confirmPassword !== password.value()) {
      setConfirmPasswordError('Passwords do not match');
      return;
    }
    registerMutation();
  };

  /** Effects */
  React.useEffect(() => {
    UtmUtil.saveUtmParameters();
  }, []);

  /** Render */
  return (
    <AuthenticationModal fullscreen={isStandalone} showExit={!isStandalone}>
      {(() => {
        if (oAuthLoading || oAuthError) {
          return <OAuthScreen />;
        }

        if (authRedirectLoading) {
          return <AuthLoading />;
        }

        return (
          <Content onSubmit={(event) => register(event)}>
            <Text type={TextTypesEnum.Bold24}>Sign up for a Free Account</Text>
            <Text type={TextTypesEnum.Bold18} color={Colors.Grey900}>No credit card required</Text>
            <Text type={TextTypesEnum.Regular16} color={Colors.Grey700} margin="4px 0 24px">
              Already have an account?
              <LinkText dataCy="sign-in-link" onClick={() => replaceModalWithLogin()} margin="0 0 0 4px">Sign in</LinkText>
            </Text>
            <Flex direction="row" justify="space-between" margin="24px 0" align="center">
              <Flex flex="1">
                <Button
                  icon={Icons.Google}
                  iconColor={Colors.Black}
                  iconSize={16}
                  onClick={() => {
                    startOAuthFlow(AuthenticationStrategyEnum.GoogleRegister);
                  }}
                  type={ButtonTypesEnum.Outline}
                  size={ButtonSizesEnum.Medium}
                  isFullWidth
                  htmlType="button"
                />
              </Flex>
              <Spacer />
              <Flex flex="1">
                <Button
                  icon={Icons.Linkedin}
                  iconColor={Colors.Black}
                  iconSize={16}
                  onClick={() => {
                    startOAuthFlow(AuthenticationStrategyEnum.LinkedInRegister);
                  }}
                  type={ButtonTypesEnum.Outline}
                  size={ButtonSizesEnum.Medium}
                  isFullWidth
                  htmlType="button"
                />
              </Flex>
            </Flex>
            <Divider margin="0 0 16px">Or continue with</Divider>
            <Input
              data-cy="register-email-input"
              autoFocus
              label="Email*"
              placeholder="Email address"
              value={email.value().toString()}
              onChange={(event) => {
                setConfirmPasswordError('');
                email.setValue(event);
              }}
              ref={email.setRef}
              error={email.error()}
              margin="0 0 24px"
            />
            <Input
              data-cy="register-password-input"
              label="Create password*"
              placeholder="Create your password"
              value={password.value().toString()}
              inputType={InputTypesEnum.Password}
              onChange={(event) => {
                setConfirmPasswordError('');
                password.setValue(event);
              }}
              ref={password.setRef}
              error={password.error()}
              margin="0 0 24px"
            />
            <Input
              data-cy="register-reenter-input"
              label="Re-enter your password*"
              placeholder="Re-enter your password"
              value={confirmPassword}
              inputType={InputTypesEnum.Password}
              onChange={(event) => {
                setConfirmPasswordError('');
                setConfirmPassword(event.target.value);
              }}
              error={confirmPasswordError}
              margin="0 0 24px"
            />
            <Button
              data-cy="sign-up-button"
              text="Sign up"
              isLoading={loading}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Large}
              isFullWidth
            />
          </Content>
        );
      })()}
    </AuthenticationModal>
  );
};

export default RegisterModal;
