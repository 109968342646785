import React from 'react';
import PermissionsUtil from '@biproxi/models/utils/PermissionsUtil';
import BiproxiPlatformPermissionsEnum, { TeamPermissionsEnum } from '@biproxi/models/enums/PermissionsEnum';
import BiproxiPlatformRolesEnum, { TeamRolesEnum } from '@biproxi/models/enums/Neo4jRolesEnums';
import { logger } from '@biproxi/logger';
import BillingPlanIdEnum from '@biproxi/models/enums/BillingPlanIdEnum';
import axios from 'axios';
import StatusCodeEnum from '@biproxi/models/enums/StatusCodeEnum';
import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
import { UserActions, UserSelectors } from '../redux/user.redux';
import { useAppDispatch, useAppSelector } from '../redux/store';

const BIPROXI_MASTER_NODE_ID = 'BVe74mhnx';

export enum Neo4jReducerStatusEnum {
  Fetching = 'FETCHING',
  Fetched = 'FETCHED',
  FetchError = 'FETCH_ERROR',
  Idle = 'IDLE',
}

interface INeo4jNode {
  _id: string;
  name: string;
  description: string;
  usersConnection: IUsersConnection; // for now because it is 2 am and i am lazy
  listingsConnection: IListingsConnection; // for now because it is 2 am and i am lazy
}

export interface INeo4jEdge {
  node: INeo4jNode;
  permission: BiproxiPlatformPermissionsEnum[];
  roles: TeamRolesEnum[];
}

interface INeo4jOrganizationConnection {
  edges: INeo4jEdge[];
}

type Neo4jFetchedDataType = {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  biproxiRoles: BiproxiPlatformRolesEnum[];
  biproxiPermissions: BiproxiPlatformPermissionsEnum[];
  organizationConnection: INeo4jOrganizationConnection;
}

interface IListingsConnection {
  totalCount?: number;
  edges: Partial<INeo4jEdge>[]
}

interface IUsersConnection {
  totalCount?: number;
}

interface INeo4jUser {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface IUserOrganizationData {
  orgId: string;
  orgName: string;
  orgDescription?: string;
  permissions: TeamPermissionsEnum[];
  roles: TeamRolesEnum[];
  userConnections?: IUsersConnection; // for now because it is 2 am and i am lazy
  listingConnections?: IListingsConnection; // for now because it is 2 am and i am lazy
}

export interface IUserBiproxiOrganizationData extends Omit<IUserOrganizationData, 'roles'|'permissions'> {
  permissions: BiproxiPlatformPermissionsEnum[];
  roles: BiproxiPlatformRolesEnum[];
}

export interface IPermissionsHookData {
  user: INeo4jUser;
  userBiproxiRolesAndPermissions: IUserBiproxiOrganizationData;
  userOrganizationsRolesAndPermissions: IUserOrganizationData[];
  userBillingPlan?: BillingPlanIdEnum;
}

type useUserPermissionsType = {
  user: INeo4jUser;
  userBiproxiRolesAndPermissions: IUserBiproxiOrganizationData;
  userOrganizationsRolesAndPermissions: IUserOrganizationData[];
  userBillingPlan: BillingPlanIdEnum;
  status: Neo4jReducerStatusEnum;
  errors: any;
  loading: boolean;
  refetch: any;
};

type useUserPermissionsHook = (useUserPermissionsHookProps: any) => useUserPermissionsType;

const useUserPermissions: useUserPermissionsHook = ({
  userId = '',
}) => {
  /** State */
  const [queryEnabled, setQueryEnabled] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);

  /** Actions */
  const dispatch = useAppDispatch();
  const cacheUserPermissions = (userPermissions: IPermissionsHookData) => dispatch(UserActions.cacheUserPermissions({ userPermissions }));
  const cachedUserPermissions = useAppSelector(UserSelectors.userPermissions);
  const currentOrganizationContext = useAppSelector(UserSelectors.currentOrganizationContext);
  const setCurrentOrganizationContext = (organization: IUserOrganizationData) => dispatch(UserActions.setCurrentOrganizationContext({ organization }));

  /** Cache */
  const initialState = {
    status: Neo4jReducerStatusEnum.Idle,
    userData: null,
    userBiproxiRolesAndPermissions: null,
    userOrganizationsRolesAndPermissions: null,
    userBillingPlan: null,
    errors: null,
  };

  /** Reducer */
  const [state, dispatchReducer] = React.useReducer((state, action) => {
    switch (action.type) {
      case Neo4jReducerStatusEnum.Fetching:
        return { ...initialState, status: Neo4jReducerStatusEnum.Fetching };
      case Neo4jReducerStatusEnum.Fetched:
        return {
          ...initialState,
          status: Neo4jReducerStatusEnum.Fetched,
          userData: action.payload?.user,
          userBiproxiRolesAndPermissions: action.payload?.userBiproxiRolesAndPermissions,
          userOrganizationsRolesAndPermissions: action.payload?.userOrganizationsRolesAndPermissions,
          userBillingPlan: action.payload?.userBillingPlan ?? BillingPlanIdEnum.FreemiumMonthly,
        };
      case Neo4jReducerStatusEnum.FetchError:
        return { ...initialState, status: Neo4jReducerStatusEnum.FetchError, error: action.payload };
      default:
        return state;
    }
  }, initialState);

  /** Fetch data */

  const fetchUserOrgsAndRoles = async (params: INeo4jServiceAPI.IGetUserOrganizationsAndRolesParams) => {
    const result = {
      data: null,
      userSubscriptionPlan: BillingPlanIdEnum.FreemiumMonthly,
      status: StatusCodeEnum.INTERNAL_SERVER_ERROR,
    };
    try {
      const res = await axios.get('/api/neo4j/getUserOrganizationsAndRoles', {
        params,
      });
      // axios.get('/api/ripple/getUserOrganizationsAndRoles');
      result.data = res?.data;
      result.status = res?.status;

      if (!res?.data || res?.status !== StatusCodeEnum.OK) {
        throw new Error('There was an issue querying for user organizations and roles');
      }

      result.userSubscriptionPlan = await fetchUserSubscriptionType(params?.userId);
    } catch (e) {
      logger.warn(`There was an issue with the getUserOrganizationsAndRoles query: ${e}`);
    }
    return result;
  };

  const fetchUserSubscriptionType = async (userId): Promise<BillingPlanIdEnum> => {
    try {
      const chargebeeRes = await axios.get(`/api/billing/subscription?id=${userId}`);
      return chargebeeRes?.data?.subscription_items?.[0]?.item_price_id;
    } catch (e) {
      logger.warn(`There was an issue fetching a user billing plan in the userUserPermissions hook: ${e}`);
      return BillingPlanIdEnum.FreemiumMonthly;
    }
  };

  /** Functions */
  const parseNeo4jUserData = (userData: Neo4jFetchedDataType) => {
    const {
      _id, firstName, lastName, email, biproxiRoles, biproxiPermissions,
    } = userData;
    const userBiproxiOrganization: IUserBiproxiOrganizationData = {
      orgId: BIPROXI_MASTER_NODE_ID,
      orgName: 'Biproxi',
      permissions: biproxiPermissions,
      roles: biproxiRoles,
    };
    let userOrganizations: IUserOrganizationData[] = null;
    if (userData?.organizationConnection) {
      const organizations: INeo4jOrganizationConnection = userData.organizationConnection;
      userOrganizations = organizations?.edges?.map((edge: INeo4jEdge) => ({
        orgId: edge?.node?._id,
        orgName: edge?.node?.name,
        orgDescription: edge?.node?.description,
        listingConnections: edge?.node?.listingsConnection,
        userConnections: edge?.node?.usersConnection,
        permissions: PermissionsUtil.parseUserPermissions(edge?.roles ?? []) ?? [],
        roles: edge?.roles ?? [],
      }));
      // get the 0th index below because the filter function returns an array, but the array will only ever contain a single item -- the biproxi org info
    }
    return {
      userData: {
        _id,
        firstName,
        lastName,
        email,
      },
      userBiproxiOrganization,
      userOrganizations,
    };
  };

  const parseUserSubscriptionData = (subscriptionType: BillingPlanIdEnum) => PermissionsUtil.getSubscriptionPermissions(subscriptionType);

  /** Effects */
  React.useEffect(() => {
    let revokeRequest = false;
    if (!userId?.length) return;
    const fetchNeo4jData = async () => {
      dispatchReducer({ type: Neo4jReducerStatusEnum.Fetching });
      if (cachedUserPermissions) {
        const data = cachedUserPermissions;
        if (queryEnabled) setQueryEnabled(false);
        dispatchReducer({ type: Neo4jReducerStatusEnum.Fetched, payload: data });
      } else {
        try {
          const data = await fetchUserOrgsAndRoles({ userId });
          logger.debug(`Data from useUserPermissions hook: ${JSON.stringify(data)}`);
          const neo4jUserData = data?.data?.data?.users?.[0];
          const { userData, userBiproxiOrganization, userOrganizations } = parseNeo4jUserData(neo4jUserData);

          // If no organization is currently set and the user belongs to an organization,
          // set it to the first one in the list as this, currently, is the primary.
          // probably want to change or add behavior around this. Talk to Mike if you have questions.
          if (!currentOrganizationContext && userOrganizations?.length > 0) {
            setCurrentOrganizationContext(userOrganizations[0]);
          }

          // inject the subscription permissions into the global biproxi user permissions
          const userSubscriptionType: BillingPlanIdEnum = data?.userSubscriptionPlan;
          const userSubscriptionPermissions: BiproxiPlatformPermissionsEnum[] = parseUserSubscriptionData(userSubscriptionType);
          userBiproxiOrganization.permissions = [...userBiproxiOrganization.permissions, ...userSubscriptionPermissions];
          const hookData: IPermissionsHookData = {
            user: userData,
            userBiproxiRolesAndPermissions: userBiproxiOrganization,
            userOrganizationsRolesAndPermissions: userOrganizations,
            userBillingPlan: userSubscriptionType,
          };
          cacheUserPermissions(hookData);
          if (revokeRequest) return;
          dispatchReducer({ type: Neo4jReducerStatusEnum.Fetched, payload: hookData });
        } catch (error) {
          if (revokeRequest) return;
          logger.warn(`There was an issue fetching user org and roles data: ${error}`);
          dispatchReducer({ type: Neo4jReducerStatusEnum.FetchError, payload: error?.message });
        }
      }
    };
    fetchNeo4jData();
    // eslint-disable-next-line consistent-return
    return function cleanup() {
      revokeRequest = true;
    };
  }, [userId, refresh]);

  return {
    ...state,
    refetch: () => {
      cacheUserPermissions(null);
      setRefresh(!refresh);
    },
    loading: state.status === Neo4jReducerStatusEnum.Fetching,
  };
};

export default useUserPermissions;
