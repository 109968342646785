/** These values are 80% of the daily limit for email providers. We use 80% so that the user
 * hopefully never hits their absolute limit and disables their email entirely via our app.
 */

/** provider daily recipient limits */
export enum NylasProviderDailyLimitsEnum {
  aol = 80,
  ews = 1600,
  gmail = 400,
  hotmail = 80,
  icloud = 1000,
  Other = 80,
  yahoo = 80,
}

/** provider limits for how many recipients a single email can have */
export enum NylasProviderPerEmailLimitsEnum {
    aol = 80,
    ews = 1600,
    gmail = 400,
    hotmail = 80,
    icloud = 500,
    Other = 80,
    yahoo = 80,
}
