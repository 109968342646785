import gql from 'graphql-tag';
import { INotificationGraphQL } from '@biproxi/models/interfaces/INotification';
import { DocumentNode } from 'graphql';
import ListingFragments from './listing.fragments';
import LeadFragments from './lead.fragments';
import UserFragments from './user.fragments';

// Not a perfect type, but it's good enough for now
type TNotificationFragments = {
  [K in keyof INotificationGraphQL]?: DocumentNode;
} & {
  fields: DocumentNode;
}

const NotificationFragments: TNotificationFragments = {
  fields: gql`
    fragment NotificationFields on Notification {
      _id
      type
      state
      leadId
      listingId
    }
  `,
  listing: gql`
    fragment NotificationListing on Notification {
      listing {
        ...ListingFields
        ...ListingAddress
        ...ListingPortfolioAddresses
        ...ListingMeta
      }
    }

    ${ListingFragments.fields}
    ${ListingFragments.address}
    ${ListingFragments.portfolioAddresses}
    ${ListingFragments.meta}
  `,
  user: gql`
    fragment NotificationUser on Notification {
      user {
        ...UserFields
      }
    }

    ${UserFragments.fields}
  `,
  lead: gql`
    fragment NotificationLead on Notification {
      lead {
        ...LeadFields
        ...LeadLoiFiles
        ...LeadOffers
        ...LeadFileAccessLevel
        ...LeadUser
      }
    }

    ${LeadFragments.fields}
  ${LeadFragments.loiFiles}
    ${LeadFragments.offers}
    ${LeadFragments.fileAccessLevel}
    ${LeadFragments.user}
  `,
  meta: gql`
    fragment NotificationMeta on Notification {
      meta {
        createdAt
      }
    }
  `,
};

export default NotificationFragments;
