import AuthenticationStrategyEnum from '@biproxi/models/enums/AuthenticationStrategyEnum';
import { IError } from '@biproxi/models/interfaces/common';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import {
  createAction, createReducer, PayloadAction,
} from '@reduxjs/toolkit';
import IInvestorProfile from '@biproxi/models/interfaces/IInvestorProfile';
import IUserNylasSettings from '@biproxi/models/interfaces/IUserNylasSettings';
import IUserNotificationSettings from '@biproxi/models/interfaces/IUserNotificationSettings';
import { AppState } from './store';
import IPageConfiguration from '../models/interfaces/IPageConfiguration';
import { IPermissionsHookData, IUserOrganizationData } from '../hooks/useUserPermissions.hook';

export type TUserReducerState = {
  oAuthLoading: boolean;
  oAuthError: IError | null;
  authRedirectLoading: boolean;
  user: IUserGraphQL | null;
  subscription: any;
  userErrors: Record<string, string> | null;
  userPermissions: IPermissionsHookData
  currentOrganizationContext: IUserOrganizationData | null;
};

export function userReducerState(config?: IPageConfiguration): TUserReducerState {
  return {
    oAuthLoading: false,
    oAuthError: null,
    authRedirectLoading: false,
    user: config?.user ?? null,
    subscription: config?.subscription ?? null,
    userErrors: null,
    userPermissions: null,
    currentOrganizationContext: null,
  };
}

export enum UserActionTypesEnum {
  CompleteAuthentication = 'CompleteAuthentication',
  StartOAuthFlow = 'StartOAuthFlow',
  FinishOAuthFlow = 'FinishOAuthFlow',
  CancelOAuthFlow = 'CancelOAuthFlow',
  SetAuthRedirectLoading = 'SetAuthRedirectLoading',
  CacheUser = 'CacheUser',
  PushUserFavoritedListings = 'PushUserFavoritedListings',
  PopUserFavoritedListings = 'PopUserFavoritedListings',
  SetUserField = 'SetUserField',
  SetUserInvestorProfileField = 'SetUserInvestorProfileField',
  SetUserErrors = 'SetUserErrors',
  SetUserNotificationSettings = 'SetUserNotificationSettings',
  SetUserNylasSettings = 'SetUserNylasSettings',
  SetUserSubscription = 'SetUserSubscription',
  SetCurrentOrganizationContext = 'SetCurrentOrganizationContext',
  CacheUserPermissions = 'CacheUserPermissions',
}

/** ******************************************************************************
 *  Complete Authentication
 ****************************************************************************** */

// Payload
type CompleteAuthenticationPayloadType = {
  token: string;
  hubspotToken: string;
};

// Action
const completeAuthentication = createAction<CompleteAuthenticationPayloadType, UserActionTypesEnum.CompleteAuthentication>(UserActionTypesEnum.CompleteAuthentication);

// Reducer
function completeAuthenticationReducer(state: TUserReducerState, _action: PayloadAction<CompleteAuthenticationPayloadType>) {
  return state;
}

/** ******************************************************************************
 *  Start OAuth Flow
 ****************************************************************************** */

// Payload
type StartOAuthFlowPayloadType = {
  strategy: AuthenticationStrategyEnum;
  isStandalone?: boolean;
};

// Action
const startOAuthFlow = createAction<StartOAuthFlowPayloadType, UserActionTypesEnum.StartOAuthFlow>(UserActionTypesEnum.StartOAuthFlow);

// Reducer
function startOAuthFlowReducer(state: TUserReducerState, _action: PayloadAction<StartOAuthFlowPayloadType>) {
  state.oAuthLoading = true;
  return state;
}

/** ******************************************************************************
 *  Finish OAuth Flow
 ****************************************************************************** */

// Payload
type FinishOAuthFlowPayloadType = null | {
  error?: IError;
};

// Action
const finishOAuthFlow = createAction<FinishOAuthFlowPayloadType, UserActionTypesEnum.FinishOAuthFlow>(UserActionTypesEnum.FinishOAuthFlow);

// Reducer
function finishOAuthFlowReducer(state: TUserReducerState, action: PayloadAction<FinishOAuthFlowPayloadType>) {
  state.oAuthLoading = false;
  state.oAuthError = action.payload?.error ?? null;
  return state;
}

/** ******************************************************************************
 *  Cancel OAuth Flow
 ****************************************************************************** */

// Payload
type CancelOAuthFlowPayloadType = null;

// Action
const cancelOAuthFlow = createAction<CancelOAuthFlowPayloadType, UserActionTypesEnum.CancelOAuthFlow>(UserActionTypesEnum.CancelOAuthFlow);

// Reducer
function cancelOAuthFlowReducer(state: TUserReducerState, _action: PayloadAction<CancelOAuthFlowPayloadType>) {
  state.oAuthLoading = false;
  state.oAuthError = null;
  state.authRedirectLoading = false;
  return state;
}

/** ******************************************************************************
 *  Set Auth Redirect Loading
 ****************************************************************************** */

// Payload
type SetAuthRedirectLoadingPayloadType = {
  loading: boolean;
};

// Action
const setAuthRedirectLoading = createAction<SetAuthRedirectLoadingPayloadType, UserActionTypesEnum.SetAuthRedirectLoading>(UserActionTypesEnum.SetAuthRedirectLoading);

// Reducer
function setAuthRedirectLoadingReducer(state: TUserReducerState, action: PayloadAction<SetAuthRedirectLoadingPayloadType>) {
  state.authRedirectLoading = action.payload.loading;
  return state;
}

/** ******************************************************************************
 *  Cache User
 ****************************************************************************** */

// Payload
type CacheUserPayloadType = {
  user: IUserGraphQL;
};

// Action
const cacheUser = createAction<CacheUserPayloadType, UserActionTypesEnum.CacheUser>(UserActionTypesEnum.CacheUser);

// Reducer
function cacheUserReducer(state: TUserReducerState, action: PayloadAction<CacheUserPayloadType>) {
  state.user = action.payload.user;
  return state;
}

/** ******************************************************************************
 *  Cache User
 ****************************************************************************** */

// Payload
type CacheUserPermissionsPayloadType = {
  userPermissions: IPermissionsHookData;
};

// Action
const cacheUserPermissions = createAction<CacheUserPermissionsPayloadType, UserActionTypesEnum.CacheUserPermissions>(UserActionTypesEnum.CacheUserPermissions);

// Reducer
function cacheUserPermissionsReducer(state: TUserReducerState, action: PayloadAction<CacheUserPermissionsPayloadType>) {
  state.userPermissions = action.payload.userPermissions;
  return state;
}

/** ******************************************************************************
 *  Cache Organizations
 ****************************************************************************** */

// Payload
type SetCurrentOrganizationContextPayloadType = {
  organization: IUserOrganizationData;
}

// Action
const setCurrentOrganizationContext = createAction<SetCurrentOrganizationContextPayloadType, UserActionTypesEnum.SetCurrentOrganizationContext>(UserActionTypesEnum.SetCurrentOrganizationContext);

// Reducer
function setCurrentOrganizationContextReducer(state: TUserReducerState, action: PayloadAction<SetCurrentOrganizationContextPayloadType>) {
  state.currentOrganizationContext = action.payload.organization;
  return state;
}

/** ******************************************************************************
 * Pop Favorite Listing
 ****************************************************************************** */

// Payload
type PopUserFavoritedListingPayloadType = {
  listingId: string;
};

// Action
const popUserFavoritedListings = createAction<PopUserFavoritedListingPayloadType, UserActionTypesEnum.PopUserFavoritedListings>(UserActionTypesEnum.PopUserFavoritedListings);

// Reducer
function popUserFavoritedListingsReducer(state: TUserReducerState, action: PayloadAction<PopUserFavoritedListingPayloadType>) {
  const { user } = state;
  const { listingId } = action.payload;
  user.favoritedListings = [...user.favoritedListings.filter((i) => i !== listingId)];
  return state;
}

/** ******************************************************************************
 *  Push Favorited Listing
 ****************************************************************************** */

// Payload
type PushUserFavoritedListingPayloadType = {
  listingId: string;
};

// Action
const pushUserFavoritedListings = createAction<PushUserFavoritedListingPayloadType, UserActionTypesEnum.PushUserFavoritedListings>(UserActionTypesEnum.PushUserFavoritedListings);

// Reducer
function pushUserFavoritedListingsReducer(state: TUserReducerState, action: PayloadAction<PushUserFavoritedListingPayloadType>) {
  const { user } = state;
  const { listingId } = action.payload;
  const listings = [...user.favoritedListings];
  listings.push(listingId);
  user.favoritedListings = listings;
  return state;
}

/** ******************************************************************************
 *  Set User Field
 ****************************************************************************** */

// Payload
type SetUserFieldPayloadType = Partial<IUserGraphQL>;

// Action
const setUserField = createAction<SetUserFieldPayloadType, UserActionTypesEnum.SetUserField>(UserActionTypesEnum.SetUserField);

// Reducer
function setUserFieldReducer(state: TUserReducerState, action: PayloadAction<SetUserFieldPayloadType>) {
  const { user } = state;

  const updatedUser = {
    ...user,
    ...action.payload,
  };

  state.user = updatedUser;

  return state;
}

/** ******************************************************************************
 *  Set User Investor Profile Field
 ****************************************************************************** */

// Payload
type SetUserInvestorProfileFieldPayloadType = Partial<IInvestorProfile>;

// Action
const setUserInvestorProfileField = createAction<SetUserInvestorProfileFieldPayloadType, UserActionTypesEnum.SetUserInvestorProfileField>(UserActionTypesEnum.SetUserInvestorProfileField);

// Reducer
function setUserInvestorProfileFieldReducer(state: TUserReducerState, action: PayloadAction<SetUserInvestorProfileFieldPayloadType>) {
  const { user: { investorProfile } } = state;

  const updatedInvestorProfile = {
    ...investorProfile,
    ...action.payload,
  };

  state.user.investorProfile = updatedInvestorProfile;

  return state;
}

/** ******************************************************************************
 *  Set User Errors for frontend validation when form hook can't be used
 ****************************************************************************** */

// Payload
type SetUserErrorsPayloadType = Record<string, string> | null;

// Action
const setUserErrors = createAction<SetUserErrorsPayloadType, UserActionTypesEnum.SetUserErrors>(UserActionTypesEnum.SetUserErrors);

// Reducer
function setUserErrorsReducer(state: TUserReducerState, action: PayloadAction<SetUserErrorsPayloadType>) {
  state.userErrors = { ...action.payload };
  return state;
}

/** ******************************************************************************
 *  Set User Notification Preferences
 ****************************************************************************** */

// Payload
type SetUserNotificationSettingsPayloadType = Partial<IUserNotificationSettings>

// Action
const setUserNotificationSettings = createAction<SetUserNotificationSettingsPayloadType, UserActionTypesEnum.SetUserNotificationSettings>(UserActionTypesEnum.SetUserNotificationSettings);

// Reducer
function setUserNotificationSettingsReducer(state: TUserReducerState, action: PayloadAction<SetUserNotificationSettingsPayloadType>) {
  const { user: { notificationSettings = {} } } = state;

  const updatedNotificationSettings = {
    ...notificationSettings,
    ...action.payload,
  };

  state.user.notificationSettings = updatedNotificationSettings;

  return state;
}

/** ******************************************************************************
 *  Set User Nylas Settings (rate limit, provider, etc.)
 ****************************************************************************** */

// Payload
type SetUserNylasSettingsPayloadType = Partial<IUserNylasSettings>;

// Action
const setUserNylasSettings = createAction<SetUserNylasSettingsPayloadType, UserActionTypesEnum.SetUserNylasSettings>(UserActionTypesEnum.SetUserNylasSettings);

// Reducer
function setUserNylasSettingsReducer(state: TUserReducerState, action: PayloadAction<SetUserNylasSettingsPayloadType>) {
  const { user } = state;

  const nylasSettings = user.nylasSettings ?? {};
  const newNylasSettings = {
    ...nylasSettings,
    ...action.payload,
  };

  user.nylasSettings = newNylasSettings;
  return state;
}

/** ******************************************************************************
 Set User Subscription
****************************************************************************** */

// Payload
type SetUserSubscriptionPayloadType = {
  currentSubscription: any,
};

// Action
const setUserSubscription = createAction<SetUserSubscriptionPayloadType, UserActionTypesEnum.SetUserSubscription>(UserActionTypesEnum.SetUserSubscription);

// Reducer
function setUserSubscriptionReducer(state: TUserReducerState, action: PayloadAction<SetUserSubscriptionPayloadType>) {
  state.subscription = action.payload.currentSubscription;
  return state;
}

/** ******************************************************************************
 *  Exports
 ****************************************************************************** */

export const UserSelectors = {
  user: (state: AppState): IUserGraphQL => state?.user?.user ?? null,
  userPermissions: (state: AppState): IPermissionsHookData[] => state?.user?.userPermissions ?? null,
  subscription: (state: AppState): any => state?.user?.subscription ?? {},
  authRedirectLoading: (state: AppState): boolean => state?.user?.authRedirectLoading,
  userErrors: (state: AppState): Record<string, string> | null => state?.user?.userErrors,
  currentOrganizationContext: (state: AppState): IUserOrganizationData | null => state?.user?.currentOrganizationContext,
};

export type UserActionPayloadTypes = {
  CompleteAuthenticationPayloadType: CompleteAuthenticationPayloadType,
  StartOAuthFlowPayloadType: StartOAuthFlowPayloadType,
  FinishOAuthFlowPayloadType: FinishOAuthFlowPayloadType,
  CancelOAuthFlowPayloadType: CancelOAuthFlowPayloadType,
  setAuthRedirectLoading: SetAuthRedirectLoadingPayloadType,
  CacheUserPayloadType: CacheUserPayloadType,
  SetCurrentOrganizationContextPayloadType: SetCurrentOrganizationContextPayloadType,
  PopUserFavoritedListingPayloadType: PopUserFavoritedListingPayloadType,
  PushUserFavoritedListingPayloadType: PushUserFavoritedListingPayloadType,
  SetUserFieldPayloadType: SetUserFieldPayloadType,
  SetUserInvestorProfileFieldPayloadType: SetUserInvestorProfileFieldPayloadType,
  SetUserNotificationSettingsPayloadType: SetUserNotificationSettingsPayloadType,
  SetUserNylasSettingsPayloadType: SetUserNylasSettingsPayloadType,
  SetUserErrorsPayloadType: SetUserErrorsPayloadType,
  SetUserSubscription: SetUserSubscriptionPayloadType,
  CacheUserPermissionsPayloadType: CacheUserPermissionsPayloadType,
};

export const UserActions = {
  completeAuthentication,
  startOAuthFlow,
  finishOAuthFlow,
  cancelOAuthFlow,
  setAuthRedirectLoading,
  cacheUser,
  setCurrentOrganizationContext,
  popUserFavoritedListings,
  pushUserFavoritedListings,
  setUserField,
  setUserInvestorProfileField,
  setUserNotificationSettings,
  setUserNylasSettings,
  setUserErrors,
  cacheUserPermissions,
  setUserSubscription,
};

const reducer = createReducer(userReducerState(), (builder) => builder
  .addCase(completeAuthentication, completeAuthenticationReducer)
  .addCase(startOAuthFlow, startOAuthFlowReducer)
  .addCase(finishOAuthFlow, finishOAuthFlowReducer)
  .addCase(cancelOAuthFlow, cancelOAuthFlowReducer)
  .addCase(setAuthRedirectLoading, setAuthRedirectLoadingReducer)
  .addCase(cacheUser, cacheUserReducer)
  .addCase(setCurrentOrganizationContext, setCurrentOrganizationContextReducer)
  .addCase(popUserFavoritedListings, popUserFavoritedListingsReducer)
  .addCase(pushUserFavoritedListings, pushUserFavoritedListingsReducer)
  .addCase(setUserField, setUserFieldReducer)
  .addCase(setUserInvestorProfileField, setUserInvestorProfileFieldReducer)
  .addCase(setUserErrors, setUserErrorsReducer)
  .addCase(setUserNotificationSettings, setUserNotificationSettingsReducer)
  .addCase(setUserNylasSettings, setUserNylasSettingsReducer)
  .addCase(setUserSubscription, setUserSubscriptionReducer)
  .addCase(cacheUserPermissions, cacheUserPermissionsReducer));

export default reducer;
