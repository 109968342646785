import React from 'react';
import Flex from '../elements/Flex';
import Text from '../elements/Text';
import Colors from '../styles/Colors';
import Input, { InputTypesEnum } from '../elements/Input';

export type MinMaxFilterProps = {
    minLabel?: string;
    maxLabel?: string;
    inputType: InputTypesEnum;
    minValue: number | string;
    maxValue: number | string;
    onMinChange: (_event: any, value: any) => void;
    onMaxChange: (_event: any, value: any) => void;
};

const MinMaxFilter: React.FC<MinMaxFilterProps> = ({
  minLabel,
  maxLabel,
  inputType = InputTypesEnum.Text,
  minValue,
  maxValue,
  onMinChange,
  onMaxChange,
}) => (
  <Flex align="center">
    <Input
      label={minLabel}
      placeholder="None"
      value={minValue?.toString() ?? ''}
      onChange={(event, unmasked) => onMinChange?.(event, inputType === InputTypesEnum.Percentage ? event.currentTarget.value : unmasked)}
      inputType={inputType}
    />
    <Text color={Colors.Grey500} margin="20px 8px 0">&ndash;</Text>
    <Input
      label={maxLabel}
      placeholder="None"
      value={maxValue?.toString() ?? ''}
      onChange={(event, unmasked) => onMaxChange?.(event, inputType === InputTypesEnum.Percentage ? event.currentTarget.value : unmasked)}
      inputType={inputType}
    />
  </Flex>
);

export default MinMaxFilter;
