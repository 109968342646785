import gql from 'graphql-tag';

const UPDATE_NYLAS_SETTINGS = gql`
  mutation UpdateUserNylasSettings ($params: UpdateUserNylasSettingsParams!) {
    updateUserNylasSettings (params: $params) {
      nylasAuthenticated
      nylasId
      nylasProvider
      dailyEmailLimit
      emailLastSentAt
    }
  }
`;

export default UPDATE_NYLAS_SETTINGS;
