import gql from 'graphql-tag';

const mutation = gql`
  mutation CompleteRegister($params: CompleteRegisterUserParams!) {
    completeRegister(params: $params) {
      user {
        _id
        firstName
        lastName
        email
        phoneNumber
        meta {
          createdAt
        }
      }
      token
    }
  }
`;

export default mutation;
