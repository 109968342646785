import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export type EllipsisProps = {
  children?: string | React.ReactNode;
}

const Ellipsis: React.FC<EllipsisProps> = ({
  children,
}) => (
  <Container>
    {children}
  </Container>
);

export default Ellipsis;
