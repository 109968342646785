import { useQuery } from '@apollo/client';
import { IError } from '@biproxi/models/interfaces/common';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { IPrivateEventGraphQL } from '@biproxi/models/interfaces/IPrivateEvent';
import { useAppSelector } from '../redux/store';
import { PrivateEventSelectors } from '../redux/privateEvent.redux';
import GET_PRIVATE_EVENT from '../graphql/queries/privateEvent.query';

type UsePrivateEvent = {
  privateEvent: IPrivateEventGraphQL | null;
  privateEventId: string | null;
  loading: boolean;
  error: IError | null;
};

type UsePrivateEventHook = (privateEventId?: string) => UsePrivateEvent;

/** TODO: Implement caching and subscriptions later */
const usePrivateEventHook: UsePrivateEventHook = (privateEventId) => {
  const selectedPrivateEventId = useAppSelector(PrivateEventSelectors.selectedPrivateEventId);
  privateEventId = selectedPrivateEventId ?? privateEventId;

  const { data, loading, error } = useQuery(GET_PRIVATE_EVENT, {
    skip: !privateEventId,
    variables: {
      params: {
        privateEventId,
      },
    },
  });

  if (!privateEventId) {
    return {
      privateEvent: null,
      privateEventId: null,
      loading: true,
      error: null,
    };
  }

  return {
    privateEvent: data?.privateEvent,
    privateEventId: data?.privateEvent?._id,
    loading,
    error: error ? ApolloUtil.parseApolloClientError(error) : null,
  };
};

export default usePrivateEventHook;
