import gql from 'graphql-tag';
import { IListingUploadGraphQL } from '@biproxi/models/interfaces/IListingUpload';
import { DocumentNode } from 'graphql';
import ListingFragments from './listing.fragments';
import UserFragments from './user.fragments';

type TListingUploadFragments = {
    [K in keyof IListingUploadGraphQL]?: DocumentNode;
} & {
    fields: DocumentNode;
}

const ListingUploadFragments: TListingUploadFragments = {
  fields: gql`
        fragment ListingUploadFields on ListingUpload {
            _id
            organizationId
            additionalInfo
            listingName
            listingId
            userId
            state
        }
    `,
  media: gql`
        fragment ListingUploadMedia on ListingUpload {
            media {
                fileIds
                zipFileId
                files {
                    _id
                    name
                    mimetype
                    url
                }
                zipFile {
                    _id
                    name
                    mimetype
                    url
                }
            }
        }
    `,
  meta: gql`
        fragment ListingUploadMeta on ListingUpload {
            meta {
                createdAt
                lastUpdatedAt
            }
        }
    `,

  user: gql`
        fragment ListingUploadUser on ListingUpload {
            user {
                ...UserFields
            }
        }

        ${UserFragments.fields}
    `,
  adminInfo: gql`
        fragment ListingUploadAdminInfo on ListingUpload {
            adminInfo {
                userId
                firstName
                lastName
                email
            }
        }
    `,
  listing: gql`
        fragment ListingUploadListing on ListingUpload {
            listing {
                ...ListingFields
                ...ListingAddress
                ...ListingPortfolioAddresses
                ...ListingMedia
            }
        }

        ${ListingFragments.fields}
        ${ListingFragments.address}
        ${ListingFragments.portfolioAddresses}
        ${ListingFragments.media}
    `,
};

export default ListingUploadFragments;
