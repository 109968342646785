import React from 'react';
import BrowserUtil, { BrowserEnum, SafariVersionsEnum } from './BrowserUtil';
import NextUtil from './NextUtil';

const mediaQuery = (query: string): string => `@media ${query}`;

const sizes = {
  mobile: 768, // ensures ipad mini and standard count as tablet
  desktop: 1200,
  desktopXL: 1700,
};

const queryStrings = {
  mobile: `screen and (max-width: ${(sizes.mobile) / 16}em)`,
  tablet: `screen and (min-width: ${(sizes.mobile + 1) / 16}em) and (max-width: ${(sizes.desktop - 1) / 16}em)`,
  desktop: `screen and (min-width: ${sizes.desktop / 16}em)`,
  desktopXL: `screen and (min-width: ${sizes.desktopXL / 16}em)`,
  print: 'print',
};

export const media = {
  mobile: mediaQuery(queryStrings.mobile),
  tablet: mediaQuery(queryStrings.tablet),
  desktop: mediaQuery(queryStrings.desktop),
  desktopXL: mediaQuery(queryStrings.desktopXL),
  print: mediaQuery(queryStrings.print),
};

export const useMedia = (query: string) => {
  const matchQuery = NextUtil.hasWindow() && window.matchMedia(query);
  const [isMatch, setIsMatch] = React.useState(matchQuery.matches);

  React.useEffect(() => {
    const matchQuery = window.matchMedia(query);

    const handler = () => setIsMatch(matchQuery.matches);

    const { browser, version } = BrowserUtil.detectBrowser();

    switch (browser) {
      case BrowserEnum.Safari:
        if (version === SafariVersionsEnum.SafariPre14) matchQuery.addListener(handler);
        break;
      default:
        matchQuery.addEventListener('change', handler);
        break;
    }

    return () => {
      switch (browser) {
        case BrowserEnum.Safari:
          if (version === SafariVersionsEnum.SafariPre14) matchQuery.removeListener(handler);
          break;
        default:
          matchQuery.removeEventListener('change', handler);
          break;
      }
    };
  }, [query, matchQuery]);

  return isMatch;
};

export const useMobileMedia = () => useMedia(queryStrings.mobile);
export const useTabletMedia = () => useMedia(queryStrings.tablet);
export const useDesktopMedia = () => useMedia(queryStrings.desktop);
export const useDesktopXLMedia = () => useMedia(queryStrings.desktopXL);
