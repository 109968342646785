import React from 'react';
import Pusher from 'pusher-js';
import { config } from '@biproxi/env';
import ChargebeeWebhookEventsEnum from '@biproxi/models/enums/ChargebeeWebhookEventsEnum';

type PusherTestProps = {};

/** this is a React.FC so that we have access to hooks to update the store and do gql mutations
 * in response to message queue events. If there is a better way, let me know!
 */

const PusherTest: React.FC<PusherTestProps> = () => {
  /** State */
  const pusher = new Pusher(config.NEXT_PUBLIC_PUSHER_KEY, {
    cluster: config.NEXT_PUBLIC_PUSHER_CLUSTER,
  });

  // const updateUserSubscription = (subscription: any) => dispatch(UserActions.setUserSubscription(subscription));

  /** Channels */
  const billingChannel = pusher.subscribe('billing-microservice');

  /** Billing Subscriptions */
  billingChannel.bind('chargebee-webhook-event', (data: any) => {
    switch (data?.event_type) {
      /** if user changes subscription, update store accordingly if useUser hook has not already */
      case ChargebeeWebhookEventsEnum.SubscriptionChanged:
        // if (data?.content?.customer?.id === user?._id) {
        //   updateUserSubscription({ currentSubscription: data?.content?.subscription })
        //   pushToast({
        //     type: ToastTypesEnum.Notification,
        //     message: 'Subscription updated BRUH!',
        //   });
        // }
        break;
      default:
        break;
    }
  });

  // React.useEffect(() => {
  //   return (
  //     () => {
  //       pusher.disconnect();
  //     }
  //   );
  // }, []);

  return (
    <div id="Pusher" />
  );
};

export default PusherTest;
