import { logger } from '@biproxi/logger';
import {
  all, takeLatest, call, put, select, take,
} from 'redux-saga/effects';
import {
  PayloadAction,
} from '@reduxjs/toolkit';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import * as IListingService from '@biproxi/models/services/IListingService';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import ListingQueryTypesEnum from '@biproxi/models/enums/ListingQueryTypesEnum';
import ListingUploadQueryTypesEnum from '@biproxi/models/enums/ListingUploadQueryTypesEnum';
// import * as INeo4jServiceAPI from '@biproxi/models/services/INeo4jService';
// import axios from 'axios';
// import StatusCodeEnum from '@biproxi/models/enums/StatusCodeEnum';
// import TimeUtil from '@biproxi/models/utils/TimeUtil';
import StatusCodeEnum from '@biproxi/models/enums/StatusCodeEnum';
import { MutateAuraListingHandler } from '@biproxi/neo4j-aura';
import {
  ListingActionTypesEnum, ListingActionPayloadTypes, ListingActions, ListingSelectors,
} from '../listing.redux';
import { initializeApollo } from '../../graphql/client';
import GET_LISTING from '../../graphql/queries/listing.query';
import LIST_LISTINGS from '../../graphql/queries/listings.query';
import CREATE_LISTING from '../../graphql/mutations/createListing.mutation';
import UPDATE_LISTING from '../../graphql/mutations/updateListing.mutation';
import UPDATE_DRAFT from '../../graphql/mutations/updateDraft.mutation';
import PUBLISH_LISTING from '../../graphql/mutations/publishListing.mutation';
import SET_LISTING_STATE from '../../graphql/mutations/setListingState.mutation';
import { AppState } from '../store';
import { AppActions } from '../app.redux';
import { ModalTypesEnum } from '../../components/modal/Modal';
import { ToastTypesEnum } from '../../components/Toast';
import * as UrlUtil from '../../utils/UrlUtil';
import DELETE_LISTING from '../../graphql/mutations/deleteListing.mutation';
import LIST_LISTING_UPLOADS from '../../graphql/queries/listingUploads.query';
import LIST_MY_LISTINGS from '../../graphql/queries/myListings.query';
import PaginationLimitsEnum from '../../models/enums/PaginationLimitsEnum';

export default function* listingSaga() {
  try {
    yield all([
      recacheListingWatch(),
      createListingWatch(),
      updateListingWatch(),
      updateDraftWatch(),
      publishListingWatch(),
      setListingStateWatch(),
      deleteListingWatch(),
      previewListingVaultWatch(),
      previewListingVideoWatch(),
      previewListingImagesWatch(),
    ]);
  } catch (e) {
    logger.error('listingSaga error', e);
  }
}
/** ******************************************************************************
 *  Recache Listing
 ****************************************************************************** */

function* recacheListingWatch() {
  yield takeLatest(
    ListingActionTypesEnum.RecacheListing,
    recacheListingSaga,
  );
}

function* recacheListingSaga(
  action: PayloadAction<ListingActionPayloadTypes['RecacheListingPayloadType']>,
) {
  /* Create GraphQL Client */
  const client = initializeApollo();

  try {
    interface Data {
      listing: IListingGraphQL;
    }

    interface Vars {
      listingId: string;
    }

    const { data } = yield call(async () => {
      const { payload: { listingId } } = action;
      return client.query<Data, Vars>({ query: GET_LISTING, variables: { listingId } });
    });

    yield put(ListingActions.cacheListings({ listings: [data.listing] }));
  } catch (e) {
    logger.error('recacheListingSaga error', e);
    const message = 'Failed to recache listing. Please try again or contact support.';
    yield put(AppActions.pushToast({ type: ToastTypesEnum.Error, message }));
  }
}

/** ******************************************************************************
 *  Create Listing
 ****************************************************************************** */

function* createListingWatch() {
  yield takeLatest(
    ListingActionTypesEnum.CreateListing,
    createListingSaga,
  );
}

function* createListingSaga(
  _action: PayloadAction<ListingActionPayloadTypes['CreateListingPayloadType']>,
) {
  /* Create GraphQL Client */
  const client = initializeApollo();

  try {
    type Data = {
      listing: IListingGraphQL;
    }

    type Vars = IListingService.TCreateListingPayload;

    const { data } = yield call(async () => client.mutate<Data, Vars>({
      mutation: CREATE_LISTING,
      variables: { params: {} },
      refetchQueries: [
        {
          query: LIST_LISTINGS,
          variables: {
            params: {
              query: {
                queryType: ListingQueryTypesEnum.Personal,
              },
            },
          },
        },
      ],
    }));
    yield put(ListingActions.createListingSuccess({ listing: data.createListing }));
  } catch (e) {
    yield put(ListingActions.createListingFailure(null));
  }
}

/** ******************************************************************************
 *  Update Listing
 ****************************************************************************** */

function* updateListingWatch() {
  yield takeLatest(
    ListingActionTypesEnum.UpdateListing,
    updateListingSaga,
  );
}

function* updateListingSaga(
  action: PayloadAction<ListingActionPayloadTypes['UpdateListingPayloadType']>,
) {
  const videoUploadInProgress: boolean = yield select((state: AppState) => ListingSelectors.videoUploadInProgress(state));

  if (videoUploadInProgress) {
    const message = 'Video upload in progress. Please cancel the upload or wait until it has completed.';
    yield put(AppActions.pushToast({ type: ToastTypesEnum.Warning, message }));
    yield put(ListingActions.updateListingFailure(null));
    return;
  }

  /* Create GraphQL Client */
  const client = initializeApollo();

  try {
    type Data = {
      listing: IListingGraphQL;
    }

    type Vars = IListingService.TUpdateListingPayload;

    const { data } = yield call(async () => {
      const params = ListingUtil.listingParams(action.payload.listing);
      return client.mutate<Data, Vars>({ mutation: UPDATE_LISTING, variables: { params } });
    });
    const updateListingAura = async (listing: IListingGraphQL) => {
      const auraListingMutator = new MutateAuraListingHandler();
      auraListingMutator.setAuraListingParams(listing as Required<IListingGraphQL>);
      const auraListingData = await auraListingMutator.updateListing();
      if (auraListingData?.status === StatusCodeEnum.OK) {
        logger.info(`Listing with id: ${listing?._id} was succesfully updated in Aura`);
      } else {
        logger.info(`There was an issue updating listing with id: ${listing?._id} in Aura`);
      }
    };

    updateListingAura(data?.updateListing);
    yield put(ListingActions.updateListingSuccess({ listing: data.updateListing }));

    const { pathname } = UrlUtil.parse(window.location.href);
    let message = 'Your listing has been updated.';
    // Change message to make sense for private event use.
    if (pathname.includes('/app/dashboard/events')) {
      message = 'Your listing has been created.';
    }

    if (action.payload.toast) {
      yield put(AppActions.pushToast({ type: ToastTypesEnum.Notification, message }));
    }
  } catch (e) {
    const { pathname } = UrlUtil.parse(window.location.href);
    logger.warn(`There was an issue when attempting to update a listing: ${e}`);
    let message = 'We were unable to update your listing. Please fill out all required fields and try again.';
    if (pathname.includes('/app/dashboard/events')) {
      message = 'We were unable to create your listing. Please fill out all required fields and try again.';
    }
    const { fields } = ApolloUtil.parseApolloClientError(e);
    yield put(AppActions.pushToast({ type: ToastTypesEnum.Error, message }));
    yield put(AppActions.popModal());
    yield put(ListingActions.updateListingFailure({ errors: fields }));
  }
}

/** ******************************************************************************
 *  Update Draft Listing
 ****************************************************************************** */

function* updateDraftWatch() {
  yield takeLatest(
    ListingActionTypesEnum.UpdateDraft,
    updateDraftSaga,
  );
}

function* updateDraftSaga(
  action: PayloadAction<ListingActionPayloadTypes['UpdateDraftPayloadType']>,
) {
  const videoUploadInProgress: boolean = yield select((state: AppState) => ListingSelectors.videoUploadInProgress(state));

  if (videoUploadInProgress) {
    const message = 'Video upload in progress. Please cancel the upload or wait until it has completed.';
    yield put(AppActions.pushToast({ type: ToastTypesEnum.Warning, message }));
    yield put(ListingActions.updateDraftFailure(null));
    return;
  }

  /* Create GraphQL Client */
  const client = initializeApollo();

  try {
    type Data = {
      listing: IListingGraphQL;
    }

    type Vars = IListingService.TUpdateDraftPayload;

    const { data } = yield call(async () => {
      const params = ListingUtil.listingParams(action.payload.listing);
      return client.mutate<Data, Vars>({ mutation: UPDATE_DRAFT, variables: { params } });
    });

    const updateDraftListingAura = async (listing: Partial<IListingGraphQL>) => {
      const auraListingMutator = new MutateAuraListingHandler();
      auraListingMutator.setAuraListingParams(listing as Required<IListingGraphQL>);
      const auraListingData = await auraListingMutator.listingMutationFlow(listing?._id);
      if (auraListingData?.status === StatusCodeEnum.OK) {
        logger.info(`Listing with id: ${listing?._id} was succesfully updated in Aura`);
      } else {
        logger.info(`There was an issue updating listing with id: ${listing?._id} in Aura`);
      }
    };

    updateDraftListingAura(data?.updateDraft);

    yield put(ListingActions.updateDraftSuccess({ listing: data.updateDraft }));

    if (action.payload.toast) {
      const message = 'Successfully updated listing.';
      yield put(AppActions.pushToast({ type: ToastTypesEnum.Notification, message }));
    }
  } catch (e) {
    logger.error('updateDraftSaga error', e);
    yield put(ListingActions.updateDraftFailure(null));
    if (action.payload.toast) {
      const message = 'Failed to update listing. Please try again or contact support.';
      yield put(AppActions.pushToast({ type: ToastTypesEnum.Error, message }));
    }
  }
}

/** ******************************************************************************
 *  Publish Listing
 ****************************************************************************** */

function* publishListingWatch() {
  yield takeLatest(
    ListingActionTypesEnum.PublishListing,
    publishListingSaga,
  );
}

function* publishListingSaga(
  action: PayloadAction<ListingActionPayloadTypes['PublishListingPayloadType']>,
) {
  const videoUploadInProgress: boolean = yield select((state: AppState) => ListingSelectors.videoUploadInProgress(state));

  if (videoUploadInProgress) {
    const message = 'Video upload in progress. Please cancel the upload or wait until it has completed.';
    yield put(ListingActions.publishListingFailure());
    yield put(AppActions.pushToast({ type: ToastTypesEnum.Warning, message }));
    return;
  }

  yield put(ListingActions.updateListing({ listing: action.payload.listing }));
  yield take(ListingActionTypesEnum.UpdateListingSuccess);

  /* Create GraphQL Client */
  const client = initializeApollo();

  try {
    type Data = {
      listing: IListingGraphQL;
    }

    type Vars = IListingService.TPublishListingPayload;

    const { data } = yield call(async () => {
      const params = {
        listing: ListingUtil.listingParams(action.payload.listing),
        publishAt: action.payload.publishAt,
        expiresAt: action.payload.expiresAt,
        isPublishTask: action.payload.isPublishTask,
      };
      return client.mutate<Data, Vars>({
        mutation: PUBLISH_LISTING,
        variables: { params },
        refetchQueries: [{
          query: LIST_LISTING_UPLOADS,
          variables: {
            params: {
              queryType: ListingUploadQueryTypesEnum.Personal,
            },
          },
        },
        {
          query: LIST_LISTINGS,
          variables: {
            params: {
              query: {
                queryType: ListingQueryTypesEnum.Personal,
              },
            },
          },
        },
        {
          query: LIST_MY_LISTINGS,
          variables: {
            params: {
              pagination: {
                offset: 0,
                limit: PaginationLimitsEnum.MyListings,
              },
              query: {
                queryType: ListingQueryTypesEnum.Personal,
              },
            },
          },
        }],
      });
    });

    const publishListingAura = async (listing: IListingGraphQL) => {
      const auraListingMutator = new MutateAuraListingHandler();
      auraListingMutator.setAuraListingParams(listing as Required<IListingGraphQL>);
      const auraListingData = await auraListingMutator.listingMutationFlow(listing?._id);
      if (auraListingData?.status === StatusCodeEnum.OK) {
        logger.info(`Listing with id: ${listing?._id} was succesfully updated in Aura`);
      } else {
        logger.info(`There was an issue updating listing with id: ${listing?._id} in Aura`);
      }
    };

    publishListingAura(data?.publishListing);

    yield put(ListingActions.publishListingSuccess({ listing: data.publishListing }));

    yield put(AppActions.replaceModal({
      type: ModalTypesEnum.PublishMessageModal,
      props: {
        isScheduled: action.payload.publishAt !== null,
        listing: data.publishListing,
      },
    }));
  } catch (e) {
    logger.error('publishListingsSaga error', e);
    const { fields } = ApolloUtil.parseApolloClientError(e);
    const message = 'We were unable to publish your listing. Please fill out all required fields and try again.';
    yield put(ListingActions.publishListingFailure({ errors: fields }));
    yield put(AppActions.popModal());
    yield put(AppActions.pushToast({ type: ToastTypesEnum.Error, message }));
  }
}

/** ******************************************************************************
 *  Set Listing State
 ****************************************************************************** */

function* setListingStateWatch() {
  yield takeLatest(
    ListingActionTypesEnum.SetListingState,
    setListingStateSaga,
  );
}

function* setListingStateSaga(
  action: PayloadAction<ListingActionPayloadTypes['SetListingStatePayloadType']>,
) {
  /* Create GraphQL Client */
  const client = initializeApollo();

  try {
    type Data = {
      listing: IListingGraphQL;
    }

    type Vars = IListingService.TSetListingStatePayload;

    const { data } = yield call(async () => {
      const params = action.payload.listingState;
      return client.mutate<Data, Vars>({ mutation: SET_LISTING_STATE, variables: { params } });
    });

    const updateAuraListingState = async (listing: IListingGraphQL) => {
      const auraListingMutator = new MutateAuraListingHandler();
      auraListingMutator.setAuraListingParams(listing as Required<IListingGraphQL>);
      const auraListingData = await auraListingMutator.updateListing();
      if (auraListingData?.status === StatusCodeEnum.OK) {
        logger.info(`Listing with id: ${listing?._id} was succesfully updated in Aura`);
      } else {
        logger.info(`There was an issue updating listing with id: ${listing?._id} in Aura`);
      }
    };
    updateAuraListingState(data?.setListingState);

    if (action.payload.toast) {
      const message = 'Listing status updated!';
      yield put(AppActions.pushToast({ type: ToastTypesEnum.Notification, message }));
    }
    yield put(ListingActions.setListingStateSuccess({ listing: data.setListingState }));
  } catch (e) {
    logger.error('setListingsStateSaga error', e);
    if (action.payload.toast) {
      const message = 'We were unable to update your listing status. Please try again or contact support.';
      yield put(AppActions.pushToast({ type: ToastTypesEnum.Error, message }));
    }
    yield put(ListingActions.setListingStateFailure(null));
  }
}

/** ******************************************************************************
 *  Delete Listing
 ****************************************************************************** */

function* deleteListingWatch() {
  yield takeLatest(
    ListingActionTypesEnum.DeleteListing,
    deleteListingSaga,
  );
}

function* deleteListingSaga(
  action: PayloadAction<ListingActionPayloadTypes['DeleteListingPayloadType']>,
) {
  /* Create GraphQL Client */
  const client = initializeApollo();

  try {
    type Data = {
      deleted: boolean;
    }

    type Vars = IListingService.TDeleteListingPayload;

    yield call(async () => {
      const params = action.payload;
      return client.mutate<Data, Vars>({
        mutation: DELETE_LISTING,
        variables: { params },
        refetchQueries: [
          {
            query: LIST_LISTINGS,
            variables: {
              params: {
                queryType: ListingQueryTypesEnum.Personal,
              },
            },
          },
        ],
      });
    });
    const deleteListingFromAura = async (listingId: string) => {
      const auraListingMutator = new MutateAuraListingHandler();
      const auraListingData = await auraListingMutator.deleteListing(listingId);
      if (auraListingData?.status === StatusCodeEnum.OK) {
        logger.info(`Listing with id: ${listingId} was succesfully updated in Aura`);
      } else {
        logger.info(`There was an issue updating listing with id: ${listingId} in Aura`);
      }
    };
    deleteListingFromAura(action.payload.listingId);
    const message = 'Listing deleted.';
    yield put(AppActions.pushToast({ type: ToastTypesEnum.Notification, message }));
    yield put(ListingActions.deleteListingSuccess());
  } catch (e) {
    logger.error('deleteListingsSaga error', e);
    const message = 'There was an error deleting your listing. Please try again or contact support.';
    yield put(AppActions.pushToast({ type: ToastTypesEnum.Error, message }));
    yield put(ListingActions.deleteListingFailure());
  }
}

/** ******************************************************************************
 *  Preview Listing Vault
 ****************************************************************************** */

function* previewListingVaultWatch() {
  yield takeLatest(
    ListingActionTypesEnum.PreviewListingVault,
    previewListingVaultSaga,
  );
}

function* previewListingVaultSaga(
  action: PayloadAction<ListingActionPayloadTypes['PreviewListingVaultPayloadType']>,
) {
  const { payload: { listingId, fileId } } = action;

  const listing: IListingGraphQL = yield select((state: AppState) => ListingSelectors.listing(state, listingId));

  const files = ListingUtil.vaultFiles(listing);

  yield put(AppActions.previewFiles({
    fileId,
    files,
    track: true,
  }));
}

/** ******************************************************************************
 *  Preview Listing Video
 ****************************************************************************** */

function* previewListingVideoWatch() {
  yield takeLatest(
    ListingActionTypesEnum.PreviewListingVideo,
    previewListingVideoSaga,
  );
}

function* previewListingVideoSaga(
  action: PayloadAction<ListingActionPayloadTypes['PreviewListingVideoPayloadType']>,
) {
  const { payload: { video } } = action;

  const { file, fileId } = video;

  yield put(AppActions.previewFiles({
    fileId,
    files: [file],
    track: false,
  }));
}

/** ******************************************************************************
 *  Preview Listing Images
 ****************************************************************************** */

function* previewListingImagesWatch() {
  yield takeLatest(
    ListingActionTypesEnum.PreviewListingImages,
    previewListingImagesSaga,
  );
}

function* previewListingImagesSaga(
  action: PayloadAction<ListingActionPayloadTypes['PreviewListingImagesPayloadType']>,
) {
  const { payload: { listingId, fileId } } = action;

  const listing: IListingGraphQL = yield select((state: AppState) => ListingSelectors.listing(state, listingId));

  const files = listing?.media?.files;

  yield put(AppActions.previewFiles({
    fileId,
    files,
    track: false,
  }));
}
