import React from 'react';
import styled from '@emotion/styled';
import { useMutation, useQuery } from '@apollo/client';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import StringUtil from '@biproxi/models/utils/StringUtil';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import ILeadPrivateListingAccess from '@biproxi/models/interfaces/ILeadPrivateListingAccess';
import UpdateLeadTypeEnum from '@biproxi/models/enums/UpdateLeadTypeEnum';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { useMobileMedia } from '../../utils/MediaQuery';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Button, { ButtonTypesEnum } from '../../elements/Button';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import UPDATE_LEAD from '../../graphql/mutations/updateLead.mutation';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import GET_SPECIFIC_USER from '../../graphql/queries/specificUser.query';
import useListingHook from '../../hooks/useListing.hook';
import Loader from '../../elements/Loader';
import Flex from '../../elements/Flex';
import GET_LEAD_BY_LISTING_AND_USER_ID from '../../graphql/queries/leadByListingAndUserId.query';
import Icon, { Icons } from '../../elements/Icon';
import * as UrlUtil from '../../utils/UrlUtil';

const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
`;

const UserProfileContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const BodyTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 24px 0;
`;

const ActionButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const UserProfileImageContainer = styled.div<{ profileUrl?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 109px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 16px;
  background-image: ${({ profileUrl }) => (profileUrl ? `url(${profileUrl})` : null)};
  background-color: ${({ profileUrl }) => (profileUrl ? 'transparent' : Colors.Grey900)};
  background-size: cover;
  background-position: center;
  background-origin: unset;
`;

export type PrivateListingPermissionRequestModalProps = {
  requestedUserId: string
  listingId?: string
}

const PrivateListingPermissionRequestModal: React.FC<PrivateListingPermissionRequestModalProps> = ({
  requestedUserId,
  listingId,
}) => {
  /** State */
  const [userId, setUserId] = React.useState<any>(requestedUserId ?? null);
  const [toastMessage, setToastMessage] = React.useState<string>('');
  /** Hooks */
  const { listing } = useListingHook();
  const { query } = UrlUtil.parse(window.location.toString());
  const isMobile = useMobileMedia();

  const {
    data: leadData, loading: isLeadLoading,
  } = useQuery(GET_LEAD_BY_LISTING_AND_USER_ID, {
    skip: !userId,
    variables: {
      params: {
        userId,
        listingId: listingId ?? listing?._id,
      },
    },
  });

  const lead = leadData?.getLeadByListingAndUserId;

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /** GraphQL */
  interface UserQueryData {
    specificUser: IUserGraphQL;
  }

  interface UserQueryVars {
    userId: string;
  }
  const { data: userData, refetch } = useQuery<UserQueryData, UserQueryVars>(GET_SPECIFIC_USER, {
    skip: !userId?.length,
    variables: {
      userId,
    },
  });

  interface UpdateLeadData {
    updateLead: ILeadGraphQL;
  }

  interface UpdateLeadVars {
    params: {
      _id: string;
      userId: string;
      privateListingAccess: ILeadPrivateListingAccess;
      updateType: UpdateLeadTypeEnum;
    }
  }

  const [updateLead, { loading: updateLeadLoading }] = useMutation<UpdateLeadData, UpdateLeadVars>(UPDATE_LEAD, {
    onCompleted: (data) => {
      if (data?.updateLead) {
        pushToast({
          type: ToastTypesEnum.Notification,
          message: toastMessage,
        });
        popModal();
      }
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error approving access. Please try again.',
      });
    },
  });

  /** Helper functions */
  const handleRequest = (privateListingAccessParams: ILeadPrivateListingAccess, updateLeadType: UpdateLeadTypeEnum) => {
    switch (updateLeadType) {
      case UpdateLeadTypeEnum.ApprovePrivateListingAccess:
        setToastMessage('You have approved access to your private listing');
        break;
      case UpdateLeadTypeEnum.DenyPrivateListingAccess:
        setToastMessage('You have denied access to your private listing');
        break;
      default:
        break;
    }
    updateLead({
      variables: {
        params: {
          _id: lead?._id,
          userId,
          privateListingAccess: privateListingAccessParams,
          updateType: updateLeadType,
        },
      },
    });
  };

  const { specificUser } = userData || {};

  const userFullName = `${specificUser?.firstName} ${specificUser?.lastName}`;

  React.useEffect(() => {
    if (query?.leadId) {
      setUserId(query.leadId);
    }
  }, [query]);

  React.useEffect(() => {
  }, [lead]);

  React.useEffect(() => {
    if (userId?.length) {
      refetch();
    }
  }, [userId]);

  if (isLeadLoading) {
    return (
      <ModalContainer
        width={isMobile ? '100vw' : '496px'}
        height={isMobile ? null : '420px'}
      >
        <ModalHeader
          title="Permission request"
          close={popModal}
        />
        <Flex
          justify="center"
          align="center"
          height="100%"
        >
          <Loader
            color={Colors.Brand400 || Colors.Blurple400}
          />
        </Flex>
      </ModalContainer>
    );
  }

  if (lead?.privateListingAccess?.accessApproved) {
    return (
      <ModalContainer
        width={isMobile ? '100vw' : '496px'}
      >
        <ModalHeader
          title="Permission request"
          close={popModal}
        />
        <ModalContent>
          <ContentWrapper>
            <Icon
              icon={Icons.CheckSolid}
              color={Colors.Green600}
              borderRadius="100px"
              background={Colors.Green100}
              width="48px"
              height="48px"
              size="24px"
            />
            <Text color={Colors.Grey900}>You have already approved access for this lead.</Text>
            <Button
              type={ButtonTypesEnum.Primary}
              text="Done"
              onClick={popModal}
              isFullWidth
            />
          </ContentWrapper>
        </ModalContent>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer
      width={isMobile ? '100vw' : '496px'}
      height={isMobile ? null : '420px'}
    >
      <ModalHeader
        title="Permission request"
        close={popModal}
      />
      <ModalContent>
        <ContentContainer>
          <UserProfileContainer>
            {specificUser?.profileImageFile?.url ? (
              <UserProfileImageContainer profileUrl={specificUser?.profileImageFile?.url} />
            ) : (
              <UserProfileImageContainer>
                <Text color={Colors.White} type={TextTypesEnum.Medium18}>
                  {specificUser?.firstName[0]}
                  {specificUser?.lastName[0]}
                </Text>
              </UserProfileImageContainer>
            )}
            <UserInfoContainer>
              <Text
                type={TextTypesEnum.Bold18}
              >
                {userFullName}
              </Text>
              {specificUser?.address?.city && specificUser?.address?.state && (
              <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>
                {specificUser?.address?.city}
                ,
                {' '}
                {specificUser?.address?.state}
              </Text>
              )}
              {specificUser?.email && (
              <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>{specificUser?.email}</Text>
              )}
              {(specificUser?.displayedPhoneNumber || specificUser?.phoneNumber) && (
              <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey500}>{specificUser?.displayedPhoneNumber ? StringUtil.formatPhoneNumber(specificUser?.displayedPhoneNumber) : StringUtil.formatPhoneNumber(specificUser?.phoneNumber)}</Text>
              )}
            </UserInfoContainer>
          </UserProfileContainer>
          <BodyTextContainer>
            <Text color={Colors.Grey900}>
              {userFullName}
              {' '}
              has requested permission to view your private listing
            </Text>
            <Text color={Colors.Grey900}>
              Do you want to approve this request?
            </Text>
          </BodyTextContainer>
          <ActionButtonsContainer>
            <Button
              type={ButtonTypesEnum.Outline}
              text="Deny"
              onClick={() => handleRequest(
                {
                  accessApproved: false,
                  accessDenied: true,
                  accessRequested: false, // reset state so investor can request again
                },
                UpdateLeadTypeEnum.DenyPrivateListingAccess,
              )}
            />
            <Button
              type={ButtonTypesEnum.Primary}
              text="Approve"
              isLoading={updateLeadLoading}
              onClick={() => handleRequest(
                {
                  accessApproved: true,
                  accessDenied: false,
                  accessRequested: true,
                },
                UpdateLeadTypeEnum.ApprovePrivateListingAccess,
              )}
            />
          </ActionButtonsContainer>
        </ContentContainer>
      </ModalContent>
    </ModalContainer>
  );
};

export default PrivateListingPermissionRequestModal;
