import React from 'react';
import styled from '@emotion/styled';
import { TextStyles, TextTypesEnum } from './Text';
import Colors from '../styles/Colors';

const LinkTextStyles = styled(TextStyles.withComponent('span'))`
  display: ${(props) => props.display};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export type LinkTextProps = {
  children: string | React.ReactNode;
  type?: TextTypesEnum;
  margin?: string;
  display?: 'inline-block' | 'block' | 'inline';
  onClick?: ((event?: MouseEvent) => void) & React.MouseEventHandler<HTMLElement>
  dataCy?: string;
}

const LinkText: React.FC<LinkTextProps> = ({
  children,
  type = TextTypesEnum.Bold16,
  margin = '0',
  display = 'inline-block',
  onClick = null,
  dataCy,
}) => (
  <LinkTextStyles
    type={type}
    color={Colors.Brand400 || Colors.Blurple700}
    onClick={onClick}
    margin={margin}
    display={display}
    data-cy={dataCy}
  >
    {children}
  </LinkTextStyles>
);

export default LinkText;
