import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from './Text';
import Flex from './Flex';
import BoxShadows from '../styles/BoxShadows';

type ContainerProps = {
  margin?: string;
  dataCy?: string;
}

const Container = styled.div<ContainerProps>`
  margin: ${(props) => props.margin};

  #RadioTextId {
      cursor: pointer;
    }
`;

type RadioCircleProps = {
  active: boolean;
  value: string;
}

const RadioCircle = styled.div<RadioCircleProps>`
  position: relative;
  box-sizing: border-box;
  border: ${(props) => (props.active ? `8px solid ${Colors.Brand700 || Colors.Blurple700}` : `2px solid ${Colors.Grey400}`)};
  border-radius: 50px;
  transition: all 0.1s;
  height: 24px;
  width: 24px;
  background-color: ${Colors.White};
  cursor: pointer;

  &:hover {
    box-shadow: ${BoxShadows.Darker};
  }
`;

type RadioProps = {
  active: boolean;
  dataCy?: string;
  onClick?: () => void;
  label?: string;
  margin?: string;
  labelTextType?: TextTypesEnum;
  expandedText?: string;
};

const Radio: React.FC<RadioProps> = ({
  active,
  dataCy,
  onClick,
  label,
  margin,
  labelTextType = TextTypesEnum.Medium16,
  expandedText,
}) => (
  <Container data-cy={dataCy} margin={margin} onClick={() => onClick?.()}>
    <Flex align="center">
      <RadioCircle
        data-cy={`${dataCy}-${label.toLowerCase().replace(/\s/g, '-')}`}
        value={label}
        active={active}
      />
      <Flex direction="column">
        <Text
          id="RadioTextId"
          type={labelTextType}
          color={Colors.Black}
          margin="0px 0px 0px 8px"
        >
          {label}
        </Text>
        {expandedText && (
        <Text
          width="288px"
          margin="0px 0px 0px 8px"
          type={TextTypesEnum.Regular16}
          color={Colors.Grey700}
        >
          {expandedText}
        </Text>
        )}
      </Flex>
    </Flex>
  </Container>
);

export default Radio;
