import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from './Text';
import Icon, { IconsType } from './Icon';

const Container = styled.div<{ margin: string; bgColor: string }>`
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 20px;
  min-width: fit-content;
  max-width: fit-content;
  padding: 2px 4px;
  background-color: ${(props) => props.bgColor};
  margin: ${(props) => props.margin};
  white-space: nowrap;
`;

export enum ColoredTagTypesEnum {
  Yellow = 'Yellow',
  Green = 'Green',
  Red = 'Red',
  Blurple = 'Blurple',
  Grey = 'Grey',
}

type ColoredTagProps = {
  margin?: string;
  text: string;
  type?: ColoredTagTypesEnum;
  icon?: IconsType;
};

const ColoredTag: React.FC<ColoredTagProps> = ({
  margin,
  text,
  type,
  icon,
}) => {
  const getColors = (type: ColoredTagTypesEnum) => {
    switch (type) {
      case ColoredTagTypesEnum.Green:
        return {
          bgColor: Colors.Green100,
          textColor: Colors.Green800,
        };
      case ColoredTagTypesEnum.Grey:
        return {
          bgColor: Colors.Grey100,
          textColor: Colors.Grey800,
        };
      case ColoredTagTypesEnum.Yellow:
        return {
          bgColor: Colors.Yellow100,
          textColor: Colors.Yellow800,
        };
      case ColoredTagTypesEnum.Blurple:
        return {
          bgColor: Colors.Brand100 || Colors.Blurple100,
          textColor: Colors.Brand800 || Colors.Blurple800,
        };
      case ColoredTagTypesEnum.Red:
        return {
          bgColor: Colors.Red100,
          textColor: Colors.Red800,
        };
      default:
        return {
          bgColor: Colors.Grey100,
          textColor: Colors.Grey800,
        };
    }
  };

  const { bgColor, textColor } = getColors(type);

  return (
    <Container
      margin={margin}
      bgColor={bgColor}
    >
      {icon && (
        <Icon
          icon={icon}
          color={textColor}
          size={10}
          margin="0 4px 0 0"
        />
      )}
      <Text type={TextTypesEnum.Medium14} color={textColor}>{text}</Text>
    </Container>
  );
};

export default ColoredTag;
