import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import ListingStateFilterEnum from '@biproxi/models/enums/ListingStateFilterEnum';
import Colors, { ColorsType } from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import { useAppDispatch } from '../redux/store';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from './modal/Modal';
import Icon from '../elements/Icon';
import Icons from '../elements/Icons';

const Container = styled.div<{status: ListingStateEnum | ListingStateFilterEnum, bgColor: ColorsType, margin?: string, changeable?: boolean, dropdownConfig?: boolean}>`
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: ${(props) => (props.dropdownConfig ? 'left' : 'center')};
  border-radius: 4px;
  position: relative;
  height: 20px;
  min-width: ${(props) => (props.dropdownConfig ? null : 'fit-content')};
  max-width: ${(props) => (props.dropdownConfig ? null : 'fit-content')};
  padding: 2px 4px;
  background-color: ${(props) => props.bgColor};
  margin: ${(props) => props.margin};
  font-size: 1.2rem;

  width: ${(props) => (props.dropdownConfig ? '100%' : null)};

  &:hover {
    cursor: ${(props) => props.changeable && 'pointer'};
    background-color: ${(props) => {
    if (props.changeable) {
      switch (props.bgColor) {
        case Colors.Grey100:
          return Colors.Grey200;
        case Colors.Green100:
          return Colors.Green200;
        case Colors.Red100:
          return Colors.Red200;
        case Colors.Yellow100:
          return Colors.Yellow200;
        case Colors.Brand100 || Colors.Blurple100:
          return Colors.Brand200 || Colors.Blurple200;
        default:
          return null;
      }
    } else {
      return null;
    }
  }}
  }
`;

export type ListingStatusProps = {
  listing?: IListingGraphQL | null;
  margin?: string;
  changeable?: boolean;
  status?: ListingStateEnum | ListingStateFilterEnum | null;
  dropdownConfig?: boolean;
};

const ListingStatus: React.FC<ListingStatusProps> = ({
  listing,
  margin,
  changeable = false,
  status,
  dropdownConfig = false,
}) => {
  /** Hooks */
  const dispatch = useAppDispatch();

  /** Actions */
  const pushChangeListingStatusModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ChangeListingStatus,
      props: {
        listing,
      },
    }),
  );

  /* Render */
  const click = changeable ? () => pushChangeListingStatusModal() : null;
  let textColor;
  let bgColor;
  let listingStatus;

  /** Apply "(Not Accessible)" text to Inactive and Draft statuses
   * for presentational purposes. We do this only for the changeStatus
   * dropdown.
   */
  if (status) {
    listingStatus = status === ListingStateEnum.Draft ? 'Draft (Not Accessible)'
      : status === ListingStateEnum.Inactive ? 'Inactive (Not Accessible)'
        : status;
  } else {
    listingStatus = listing.state;
  }

  /** Set background and text color depending on status. */
  switch (status || listing.state) {
    case ListingStateEnum.Active:
      bgColor = Colors.Green100;
      textColor = Colors.Green800;
      break;
    case ListingStateEnum.Draft:
      bgColor = Colors.Grey100;
      textColor = Colors.Grey800;
      break;
    case ListingStateEnum.ReadyForReview:
    case ListingStateEnum.UnderContract:
      bgColor = Colors.Yellow100;
      textColor = Colors.Yellow800;
      break;
    case ListingStateEnum.Closed:
    case ListingStateEnum.Private:
      bgColor = Colors.Brand100 || Colors.Blurple100;
      textColor = Colors.Brand800 || Colors.Blurple800;
      break;
    case ListingStateEnum.Inactive:
      bgColor = Colors.Red100;
      textColor = Colors.Red800;
      break;
    case ListingStateEnum.CallForOffers:
      bgColor = Colors.Green100;
      textColor = Colors.Green800;
      break;
    case ListingStateEnum.Scheduled:
      bgColor = Colors.Grey100;
      textColor = Colors.Grey800;
      break;
    case ListingStateEnum.Expired:
      bgColor = Colors.Red100;
      textColor = Colors.Red800;
      break;
    default:
      bgColor = Colors.Grey100;
      textColor = Colors.Grey800;
  }

  return (
    <Container
      status={status || listing.state}
      bgColor={bgColor}
      margin={margin}
      changeable={changeable}
      dropdownConfig={dropdownConfig}
      onClick={(event) => {
        if (!dropdownConfig) {
          event.stopPropagation();
        }
        if (click) click();
      }}
    >
      <Text data-cy="listing-status-thumbnail" type={TextTypesEnum.Medium14} color={textColor} align={dropdownConfig ? 'left' : null}>{listingStatus}</Text>
      {changeable && (
        <Icon
          icon={Icons.AngleDownRegular}
          color={textColor}
          size={12}
          margin="0 0 0 4px"
        />
      )}
    </Container>
  );
};

export default React.memo(ListingStatus);
