import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from './Text';
import BoxShadows from '../styles/BoxShadows';

const Container = styled.div<{ margin?: string; }>`
  margin: ${({ margin }) => margin};
`;

const Box = styled.div`
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Grey100};
  transition: all 0.1s;
  border-radius: 4px;

`;

type OptionProps = {
  active: boolean;
  left?: boolean;
  onClick?: () => void;
};

const Option = styled.div<OptionProps>`
  height: 40px;
  width: calc((100% / 2) - 6px);
  background-color: ${(props) => (props.active ? Colors.White : null)};
  box-shadow: ${(props) => (props.active ? BoxShadows.Hard : null)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Black};
  margin: ${(props) => (props.left ? '0 4px 0 0px' : null)};
  border-radius: 4px;
  transition: all 0.1s;
  box-sizing: border-box;


  &:hover {
    cursor: pointer;
    background-color: ${(props) => (props.active ? Colors.White : Colors.Grey200)};
  }
`;

type ToggleProps = {
  value: boolean;
  cyYes?: string;
  cyNo?: string;
  onChange?: (active: boolean) => void;
  label?: string | React.ReactChild;
  margin?: string;
  customYesText?: string;
  customNoText?: string;
};

const Toggle: React.FC<ToggleProps> = ({
  value,
  cyYes,
  cyNo,
  onChange,
  label,
  margin,
  customYesText,
  customNoText,
}) => (
  <Container margin={margin}>
    {label && <Text type={TextTypesEnum.Medium12} margin="0 0 4px">{label}</Text>}
    <Box>
      <Option left data-cy={cyYes} active={value} onClick={() => onChange(true)}>
        <Text type={TextTypesEnum.Medium16} color={value ? Colors.Black : Colors.Grey400}>{customYesText || 'Yes'}</Text>
      </Option>
      <Option data-cy={cyNo} active={!value} onClick={() => onChange(false)}>
        <Text type={TextTypesEnum.Medium16} color={!value ? Colors.Black : Colors.Grey400}>{customNoText || 'No'}</Text>
      </Option>
    </Box>
  </Container>
);
export default Toggle;
