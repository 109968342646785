import {
  createAction, createReducer, PayloadAction,
} from '@reduxjs/toolkit';
import { IChannelGraphQL } from '@biproxi/models/interfaces/IChannel';
import IPageConfiguration from '../models/interfaces/IPageConfiguration';

export type TChatReducerState = {
  // TODO: add typing
  newMessage: any;
  newChannel: any;
  unreadMessageCount: number;
  activeChatChannels: IChannelGraphQL[];
  minimizedChatChannels: IChannelGraphQL[];

  /**
   * Deprecated: delete later.
   */
  currentlyOpenChannelUrl: string;
  channelsOpenedUrls: string[];
};

export function chatReducerState(_config?: IPageConfiguration): TChatReducerState {
  return {
    newMessage: null,
    newChannel: null,
    unreadMessageCount: 0,
    activeChatChannels: [],
    minimizedChatChannels: [],

    /**
     * Deprecated: delete later.
     */
    currentlyOpenChannelUrl: '',
    channelsOpenedUrls: [],
  };
}

export enum UserActionTypesEnum {
  SetNewMessageReceived = 'SetNewMessageReceived',
  SetUnreadMessageCount = 'SetUnreadMessageCount',
  SetCurrentlyOpenChannelUrl = 'SetCurrentlyOpenChannelUrl',
  PushChannelsOpenedUrls = 'PushChannelsOpenedUrls',
  PopChannelsOpenedUrls = 'PopChannelsOpenedUrls',
  PushActiveChatChannels = 'PushActiveChatChannels',
  PopActiveChatChannels = 'PopActiveChatChannels',
  PushMinimizedChatChannels = 'PushMinimizedChatChannels',
  PopMinimizedChatChannels = 'PopMinimizedChatChannels,'
}

/** ******************************************************************************
 *  Set New Message
 ****************************************************************************** */

// Payload
type SetNewMessageReceivedPayloadType = {
  message: any;
  channel: any;
};

// Action
const setNewMessageReceived = createAction<SetNewMessageReceivedPayloadType, UserActionTypesEnum.SetNewMessageReceived>(UserActionTypesEnum.SetNewMessageReceived);

// Reducer
function setNewMessageReceivedReducer(state: TChatReducerState, action: PayloadAction<SetNewMessageReceivedPayloadType>) {
  const { message, channel } = action.payload;
  state.newChannel = channel;
  state.newMessage = message;
  return state;
}

/** ******************************************************************************
 *  Set Unread Message Count
 ****************************************************************************** */

// Payload
type SetUnreadMessageCountPayloadType = {
  unreadMessageCount: number;
};

// Action
const setUnreadMessageCount = createAction<SetUnreadMessageCountPayloadType, UserActionTypesEnum.SetUnreadMessageCount>(UserActionTypesEnum.SetUnreadMessageCount);

// Reducer
function setUnreadMessageCountReducer(state: TChatReducerState, action: PayloadAction<SetUnreadMessageCountPayloadType>) {
  const { unreadMessageCount } = action.payload;
  state.unreadMessageCount = unreadMessageCount;
  return state;
}

/** ******************************************************************************
 *  Set Currently Open Channel Url
 ****************************************************************************** */

// Payload
type SetCurrentlyOpenChannelUrlPayloadType = {
  channelUrl: string;
};

// Action
const setCurrentlyOpenChannelUrl = createAction<SetCurrentlyOpenChannelUrlPayloadType, UserActionTypesEnum.SetCurrentlyOpenChannelUrl>(UserActionTypesEnum.SetCurrentlyOpenChannelUrl);

// Reducer
function setCurrentlyOpenChannelUrlReducer(state: TChatReducerState, action: PayloadAction<SetCurrentlyOpenChannelUrlPayloadType>) {
  const { channelUrl } = action.payload;
  state.currentlyOpenChannelUrl = channelUrl;
  return state;
}

/** ******************************************************************************
 *  Push Currently Opened Channel Urls
 ****************************************************************************** */

// Payload
type PushChannelsOpenedUrlsPayloadType = {
  channelUrl: string;
};

// Action
const pushChannelsOpenedUrls = createAction<PushChannelsOpenedUrlsPayloadType, UserActionTypesEnum.PushChannelsOpenedUrls>(UserActionTypesEnum.PushChannelsOpenedUrls);

// Reducer
function pushChannelsOpenedUrlsReducer(state: TChatReducerState, action: PayloadAction<PushChannelsOpenedUrlsPayloadType>) {
  const { channelUrl } = action.payload;
  const { channelsOpenedUrls } = state;
  const channelUrls = [...channelsOpenedUrls];
  channelUrls.push(channelUrl);
  state.channelsOpenedUrls = channelUrls;
  return state;
}

/** ******************************************************************************
 *  Pop Currently Open Channel Url
 ****************************************************************************** */

// Payload
type PopChannelsOpenedUrlsPayloadType = {
  channelUrl: string;
};

// Action
const popChannelsOpenedUrls = createAction<PopChannelsOpenedUrlsPayloadType, UserActionTypesEnum.PopChannelsOpenedUrls>(UserActionTypesEnum.PopChannelsOpenedUrls);

// Reducer
function popChannelsOpenedUrlsReducer(state: TChatReducerState, action: PayloadAction<PopChannelsOpenedUrlsPayloadType>) {
  const { channelUrl } = action.payload;
  const { channelsOpenedUrls } = state;
  const channelUrls = [...channelsOpenedUrls.filter((i) => i !== channelUrl)];
  state.channelsOpenedUrls = channelUrls;
  return state;
}

/** ******************************************************************************
 *  push Active Channel
 ****************************************************************************** */

// Payload
type PushActiveChatChannelsPayloadType = {
  channel: IChannelGraphQL;
};

// Action
const pushActiveChatChannels = createAction<PushActiveChatChannelsPayloadType, UserActionTypesEnum.PushActiveChatChannels>(UserActionTypesEnum.PushActiveChatChannels);

// Reducer
function pushActiveChatChannelsReducer(state: TChatReducerState, action: PayloadAction<PushActiveChatChannelsPayloadType>) {
  const { channel } = action.payload;
  const { activeChatChannels } = state;
  const activeChannels = [...activeChatChannels];

  const alreadyActive = Boolean(activeChannels?.find((ch) => ch?.channelUrl === channel?.channelUrl));

  if (!alreadyActive) {
    // Pop minimized channel if there is one.
    state = popMinimizedChatChannelsReducer(state, {
      type: UserActionTypesEnum.PopMinimizedChatChannels,
      payload: { channelUrl: channel.channelUrl },
    });

    // Push oldest item to minimized section
    if (activeChannels?.length >= 3) {
      state = pushMinimizedChatChannelsReducer(state, {
        type: UserActionTypesEnum.PushMinimizedChatChannels,
        payload: { channel: activeChannels[0] },
      });
      activeChannels?.shift();
    }
    // push to end of active list
    activeChannels?.push(channel);

    state.activeChatChannels = activeChannels;
  }

  return state;
}

/** ******************************************************************************
 *  Pop Active Channel
 ****************************************************************************** */

// Payload
type PopActiveChatChannelsPayloadType = {
  channelUrl: string;
};

// Action
const popActiveChatChannels = createAction<PopActiveChatChannelsPayloadType, UserActionTypesEnum.PopActiveChatChannels>(UserActionTypesEnum.PopActiveChatChannels);

// Reducer
function popActiveChatChannelsReducer(state: TChatReducerState, action: PayloadAction<PopActiveChatChannelsPayloadType>) {
  const { channelUrl } = action.payload;
  const { activeChatChannels } = state;
  const channels = [...activeChatChannels?.filter((i) => i.channelUrl !== channelUrl)];
  state.activeChatChannels = channels;
  return state;
}

/** ******************************************************************************
 *  Push Minimized Channel
 ****************************************************************************** */

// Payload
type PushMinimizedChatChannelsPayloadType = {
  channel: IChannelGraphQL;
};

// Action
const pushMinimizedChatChannels = createAction<PushMinimizedChatChannelsPayloadType, UserActionTypesEnum.PushMinimizedChatChannels>(UserActionTypesEnum.PushMinimizedChatChannels);

// Reducer
function pushMinimizedChatChannelsReducer(state: TChatReducerState, action: PayloadAction<PushMinimizedChatChannelsPayloadType>) {
  const { channel } = action.payload;
  const { minimizedChatChannels } = state;
  const channels = [...minimizedChatChannels];

  // Pop active channel if there is one.
  popActiveChatChannelsReducer(state, {
    type: UserActionTypesEnum.PopActiveChatChannels,
    payload: { channelUrl: channel.channelUrl },
  });

  channels?.push(channel);
  state.minimizedChatChannels = channels;
  return state;
}

/** ******************************************************************************
 *  Pop Minimized Channel
 ****************************************************************************** */

// Payload
type PopMinimizedChatChannelsPayloadType = {
  channelUrl: string;
};

// Action
const popMinimizedChatChannels = createAction<PopMinimizedChatChannelsPayloadType, UserActionTypesEnum.PopMinimizedChatChannels>(UserActionTypesEnum.PopMinimizedChatChannels);

// Reducer
function popMinimizedChatChannelsReducer(state: TChatReducerState, action: PayloadAction<PopMinimizedChatChannelsPayloadType>) {
  const { channelUrl } = action.payload;
  const { minimizedChatChannels } = state;
  // TODO: figure out way to pop these off with rerendering the modals
  const channels = [...minimizedChatChannels?.filter((i) => i.channelUrl !== channelUrl)];
  state.minimizedChatChannels = channels;
  return state;
}

/** ******************************************************************************
 *  Exports
 ****************************************************************************** */

export const ChatSelectors = {};

export type ChatActionPayloadTypes = {
  SetNewMessageReceivedPayloadType: SetNewMessageReceivedPayloadType,
  SetUnreadMessageCountPayloadType: SetUnreadMessageCountPayloadType,
  SetCurrentlyOpenChannelUrlPayloadType: SetCurrentlyOpenChannelUrlPayloadType,
  PushChannelsOpenedUrlsPayloadType: PushChannelsOpenedUrlsPayloadType,
  PopChannelsOpenedUrlsPayloadType: PopChannelsOpenedUrlsPayloadType,
  PushActiveChatChannelsPayloadType: PushActiveChatChannelsPayloadType,
  PopActiveChatChannelsPayloadType: PopActiveChatChannelsPayloadType,
  PushMinimizedChatChannelsPayloadType: PushMinimizedChatChannelsPayloadType,
  PopMinimizedChatChannelsPayloadType: PopMinimizedChatChannelsPayloadType,
};

export const ChatActions = {
  setNewMessageReceived,
  setUnreadMessageCount,
  setCurrentlyOpenChannelUrl,
  pushChannelsOpenedUrls,
  popChannelsOpenedUrls,
  pushActiveChatChannels,
  popActiveChatChannels,
  pushMinimizedChatChannels,
  popMinimizedChatChannels,
};

const reducer = createReducer(chatReducerState(), (builder) => builder
  .addCase(setNewMessageReceived, setNewMessageReceivedReducer)
  .addCase(setUnreadMessageCount, setUnreadMessageCountReducer)
  .addCase(setCurrentlyOpenChannelUrl, setCurrentlyOpenChannelUrlReducer)
  .addCase(pushChannelsOpenedUrls, pushChannelsOpenedUrlsReducer)
  .addCase(popChannelsOpenedUrls, popChannelsOpenedUrlsReducer)
  .addCase(pushActiveChatChannels, pushActiveChatChannelsReducer)
  .addCase(popActiveChatChannels, popActiveChatChannelsReducer)
  .addCase(pushMinimizedChatChannels, pushMinimizedChatChannelsReducer)
  .addCase(popMinimizedChatChannels, popMinimizedChatChannelsReducer));

export default reducer;
