import Joi from 'joi';
import JoiUtil from '../utils/JoiUtil';
import { authenticatedDataSchema, authorizedDataSchema } from './authentication.joi';
import license from './license.joi';
import {
  AssetsOwnedEnum,
  TargetPriceRangeEnum,
  PreferredInvestmentTypeEnum,
  InvestorTypeEnum,
  NationalOrGlobalFocusEnum,
} from '../enums/InvestorProfileAnswersEnums';
import ListingAssetClassEnum from '../enums/ListingAssetClassEnum';
import paginationSchema from './pagination.joi';
import { listingDealProfile, listingContact } from './listing.joi';
import { AmericanStatesExpandedEnum } from '../enums/AmericanStatesEnums';

export const user = {
  _id: () => Joi.string().required(),
  email: () => Joi.string().email({ tlds: { allow: false } }),
  password: () => Joi.string().required(),
  firstName: () => Joi.string().required(),
  lastName: () => Joi.string().required(),
  phoneNumber: () => Joi.string().regex(/\d+/).required(),
  phoneNumberVerificationCode: () => Joi.string().regex(/^[0-9]{6}$/).required(),
  recaptchaToken: () => Joi.string().required(),
  resetPasswordCode: () => Joi.string().required(),
  passwordLastSet: () => Joi.number().optional(),
  role: () => Joi.string().required(),
  organizationName: () => Joi.string().optional().allow(null, ''),
  profileImageFileId: () => Joi.string().optional().allow(null, ''),
  bio: () => Joi.string().optional().allow(null, ''),
  ipAddress: () => Joi.string().optional().allow(null, ''),
  license: () => Joi.array().items(Joi.object().keys(license)).optional(),
  hideQuickStartGuideSchema: () => Joi.boolean().optional().allow(null, ''),
  viewedSubscriptionEarlyAccessModal: () => Joi.boolean().optional().allow(null, ''),
  investorProfile: () => investorProfile,
  oAuthProviders: () => oAuthProviders,
  notificationSettings: () => notificationSettings,
  nylasSettings: () => nylasSettings,
  contacts: () => Joi.array().items(listingContact),
};

/** ******************************************************************************
*  Register
******************************************************************************* */

export const oAuthProviders = Joi.object().keys({
  google: Joi.boolean(),
  linkedin: Joi.boolean(),
});

export const registerUserParamsSchema = Joi.object().keys({
  email: user.email().required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  password: user.password().required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  firstName: user.firstName().optional().default(''),
  lastName: user.lastName().optional().default(''),
  phoneNumber: user.phoneNumber().optional().default(''),
}).prefs({
  abortEarly: false,
});

export const registerUserRequestSchema = Joi.object().keys({
  params: registerUserParamsSchema,
  strategy: Joi.string().required(),
  ipAddress: Joi.string().optional().allow(null, ''),
  geolocAddress: Joi.object().keys({
    country: Joi.string().required().allow(null),
    city: Joi.string().required().allow(null),
    state: Joi.string().required().allow(null),
    zip: Joi.string().required().allow(null),
    googlePlaceId: Joi.string().optional().allow(null),
    timeZoneId: Joi.string().optional().allow(null),
  }),
});

/** ******************************************************************************
*  Complete Register
******************************************************************************* */

export const completeUserRegistrationParamsSchema = Joi.object().keys({
  firstName: user.firstName().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  lastName: user.lastName().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  phoneNumber: user.phoneNumber().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  role: user.role().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  utmParams: Joi.object().keys({
    utm_id: Joi.string().allow('').allow(null),
    utm_source: Joi.string().allow('').allow(null),
    utm_medium: Joi.string().allow('').allow(null),
    utm_campaign: Joi.string().allow('').allow(null),
    utm_term: Joi.string().allow('').allow(null),
    utm_content: Joi.string().allow('').allow(null),
  }).allow(null),
}).prefs({
  abortEarly: false,
});

export const completeUserRegistrationRequestSchema = Joi.object().keys({
  params: completeUserRegistrationParamsSchema,
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Login
******************************************************************************* */

export const loginUserParamsSchema = Joi.object().keys({
  email: user.email().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  password: user.password().error(([error]) => JoiUtil.encodeError('Invalid', error)),
});

export const loginUserRequestSchema = Joi.object().keys({
  params: loginUserParamsSchema,
  strategy: Joi.string().required(),
  ipAddress: Joi.string().optional().allow(null, ''),
});

/** ******************************************************************************
*  OAuth Login
******************************************************************************* */

export const oAuthLoginUserParamsSchema = Joi.object().keys({
  email: user.email().error(([error]) => JoiUtil.encodeError('Invalid', error)),
});

export const oAuthLoginUserRequestSchema = Joi.object().keys({
  params: oAuthLoginUserParamsSchema,
  strategy: Joi.string().required(),
});

/** ******************************************************************************
*  Send phone number verification code
******************************************************************************* */

export const sendPhoneNumberVerificationCodeParamsSchema = Joi.object().keys({
  recaptchaToken: user.recaptchaToken().error(([error]) => JoiUtil.encodeError('Recaptcha token is invalid', error)),
});

export const sendPhoneNumberVerificationCodeRequestSchema = Joi.object().keys({
  params: sendPhoneNumberVerificationCodeParamsSchema,
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Verify phone number verification code
******************************************************************************* */

export const verifyPhoneNumberVerificationCodeParamsSchema = Joi.object().keys({
  phoneNumberVerificationCode: user.phoneNumberVerificationCode().error(([error]) => JoiUtil.encodeError('Invalid', error)),
});

export const verifyPhoneNumberVerificationCodeRequestSchema = Joi.object().keys({
  params: verifyPhoneNumberVerificationCodeParamsSchema,
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Send Password Reset Email
******************************************************************************* */

export const sendPasswordResetParamsSchema = Joi.object().keys({
  email: user.email().error(([error]) => JoiUtil.encodeError('Invalid', error)),
});

export const sendPasswordResetRequestSchema = Joi.object().keys({
  params: sendPasswordResetParamsSchema,
});

/** ******************************************************************************
*  Reset Password
******************************************************************************* */

export const resetPasswordParamsSchema = Joi.object().keys({
  password: user.password().required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
});

export const resetPasswordRequestSchema = Joi.object().keys({
  params: Joi.object().keys({
    resetPasswordCode: user.resetPasswordCode().required().error(([error]) => JoiUtil.encodeError('Reset code is invalid', error)),
    password: user.password().required().error(([error]) => JoiUtil.encodeError('Password is invalid', error)),
  }),
});

/** ******************************************************************************
*  Authenticated Password Change
******************************************************************************* */

export const authenticatedPasswordChangeParamsSchema = Joi.object().keys({
  password: user.password().required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  newPassword: user.password().required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  newPasswordConfirm: user.password().required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
}).prefs({
  abortEarly: false,
});

export const authenticatedPasswordChangeRequestSchema = Joi.object().keys({
  params: Joi.object().keys({
    password: user.password().required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
    newPassword: user.password().required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  }),
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Update Basic User Information
******************************************************************************* */

export const updateBasicUserInformationParamsSchema = Joi.object().keys({
  firstName: user.firstName().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  lastName: user.lastName().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  organizationName: user.organizationName().error(([error]) => JoiUtil.encodeError('Invalid org name', error)),
  role: user.role().error(([error]) => JoiUtil.encodeError('Invalid role', error)),
  profileImageFileId: user.profileImageFileId().error(([error]) => JoiUtil.encodeError('Invalid Profile Image', error)),
  bio: user.bio().error(([error]) => JoiUtil.encodeError('Invalid bio', error)),
  address: Joi.any(),
  viewedSubscriptionEarlyAccessModal: user.viewedSubscriptionEarlyAccessModal().error(([error]) => JoiUtil.encodeError('Invalid subscription modal view param', error)),
}).prefs({
  abortEarly: false,
});

export const updateBasicUserInformationRequestSchema = Joi.object().keys({
  params: updateBasicUserInformationParamsSchema,
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Add License
******************************************************************************* */

export const addUserLicenseParamsSchema = Joi.object().keys({
  owner: license.owner().error(([error]) => JoiUtil.encodeError('Required', error)),
  state: license.state().error(([error]) => JoiUtil.encodeError('Required', error)),
  number: license.number().error(([error]) => JoiUtil.encodeError('Required', error)),
}).prefs({
  abortEarly: false,
});

export const addUserLicenseRequestSchema = Joi.object().keys({
  params: addUserLicenseParamsSchema,
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Delete License
******************************************************************************* */

export const deleteUserLicenseRequestSchema = Joi.object().keys({
  params: Joi.object().keys({
    licenseId: Joi.string().required(),
  }),
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Update User Email
******************************************************************************* */

export const updateUserEmailParamsSchema = Joi.object().keys({
  email: user.email().error(([error]) => JoiUtil.encodeError('Invalid', error)),
});

export const updateUserEmailRequestSchema = Joi.object().keys({
  params: updateUserEmailParamsSchema,
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Update User Phone Number
******************************************************************************* */

export const updateUserPhoneNumberParamsSchema = Joi.object().keys({
  phoneNumber: user.phoneNumber().error(([error]) => JoiUtil.encodeError('Invalid', error)),
});

export const updateUserPhoneNumberRequestSchema = Joi.object().keys({
  params: Joi.object().keys({
    phoneNumber: user.phoneNumber().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  }),
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Update User Secondary Phone Numbers
******************************************************************************* */

export const updateUserSecondaryPhoneNumbersParamsSchema = Joi.object().keys({
  phoneNumbers: Joi.array().items(Joi.string()).required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
});

export const updateUserSecondaryPhoneNumbersRequestSchema = Joi.object().keys({
  params: updateUserSecondaryPhoneNumbersParamsSchema,
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Update User Display Phone Number
******************************************************************************* */

export const updateUserDisplayPhoneNumberParamsSchema = Joi.object().keys({
  phoneNumber: Joi.string().required().error(([error]) => JoiUtil.encodeError('Invalid', error)),
});

export const updateUserDisplayPhoneNumberRequestSchema = Joi.object().keys({
  params: updateUserDisplayPhoneNumberParamsSchema,
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Favorite Listing
******************************************************************************* */

export const favoriteListingRequestSchema = Joi.object().keys({
  params: Joi.object().keys({
    listingId: Joi.string().required(),
    favorite: Joi.boolean().required(),
  }),
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Get Listings' Favorited Users
******************************************************************************* */

export const getFavoritedUsersRequestSchema = Joi.object().keys({
  params: Joi.object().keys({
    listingId: Joi.string().required(),
  }),
  auth: authorizedDataSchema,
});

/** ******************************************************************************
*  Get users who match a listing's deal profiles criteria
******************************************************************************* */

export const getDealProfileMatchesParamsSchema = Joi.object().keys({
  matchCriteria: Joi.object().keys({
    dealProfile: listingDealProfile,
    listingState: Joi.string().valid(...Object.values(AmericanStatesExpandedEnum)).optional().allow(null),
    listingAssetClass: Joi.string().valid(...Object.values(ListingAssetClassEnum)).optional().allow(null),
  }),
});

export const getDealProfileMatchesRequestSchema = Joi.object().keys({
  params: getDealProfileMatchesParamsSchema,
  auth: authorizedDataSchema,
});

/** ******************************************************************************
*  Send New Message Notification Email
******************************************************************************* */

export const sendNewMessageNotificationEmailRequestSchema = Joi.object().keys({
  params: Joi.object().keys({
    listingId: Joi.string().required(),
    messageReceiverUserId: Joi.string().required(),
    message: Joi.string().required(),
  }),
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Update InvestorProfile
******************************************************************************* */

export const investorProfile = Joi.object().keys({
  exchangeInvestor: Joi.boolean().optional().allow(null),
  investorType: Joi.string().valid(...Object.values(InvestorTypeEnum))
    .optional()
    .allow(null)
    .allow(''),
  assetsOwned: Joi.string().valid(...Object.values(AssetsOwnedEnum))
    .optional()
    .allow(null)
    .allow(''),
  targetPriceRanges: Joi.array().items(Joi.string().valid(...Object.values(TargetPriceRangeEnum)))
    .optional()
    .allow(null)
    .allow(''),
  preferredInvestmentTypes: Joi.array().items(Joi.string().valid(...Object.values(PreferredInvestmentTypeEnum)))
    .optional()
    .allow(null)
    .allow(''),
  targetAssetClasses: Joi.array().items(Joi.string().valid(...Object.values(ListingAssetClassEnum)))
    .optional()
    .allow(null)
    .allow(''),
  geographicalFocus: Joi.array().items(Joi.string().valid(...Object.values(AmericanStatesExpandedEnum))).optional().allow(null)
    .optional()
    .allow(null)
    .allow(''),
  nationalOrGlobal: Joi.string().valid(...Object.values(NationalOrGlobalFocusEnum)).allow('', null),
  __typename: Joi.string().optional().allow(null, ''),
});

// Could clean up this redundant schema but will need to change graphQL schema and other things.
export const updateInvestorProfileParamsSchema = Joi.object().keys({
  exchangeInvestor: Joi.boolean().optional().allow(null),
  investorType: Joi.string().valid(...Object.values(InvestorTypeEnum))
    .optional()
    .allow(null)
    .allow(''),
  assetsOwned: Joi.string().valid(...Object.values(AssetsOwnedEnum))
    .optional()
    .allow(null)
    .allow(''),
  targetPriceRanges: Joi.array().items(Joi.string().valid(...Object.values(TargetPriceRangeEnum)))
    .optional()
    .allow(null)
    .allow(''),
  preferredInvestmentTypes: Joi.array().items(Joi.string().valid(...Object.values(PreferredInvestmentTypeEnum)))
    .optional()
    .allow(null)
    .allow(''),
  targetAssetClasses: Joi.array().items(Joi.string().valid(...Object.values(ListingAssetClassEnum)))
    .optional()
    .allow(null)
    .allow(''),
  geographicalFocus: Joi.array().items(Joi.string().valid(...Object.values(AmericanStatesExpandedEnum))).optional().allow(null)
    .optional()
    .allow(null)
    .allow(''),
  nationalOrGlobal: Joi.string().valid(...Object.values(NationalOrGlobalFocusEnum)).allow('', null),
  __typename: Joi.string().optional().allow(null, ''),
});

export const updateInvestorProfileRequestSchema = Joi.object().keys({
  params: updateInvestorProfileParamsSchema,
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Update User Profile
******************************************************************************* */

export const updateUserProfileParamsSchema = Joi.object().keys({
  firstName: user.firstName().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  lastName: user.lastName().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  organizationName: user.organizationName().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  role: user.role().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  profileImageFileId: user.profileImageFileId().error(([error]) => JoiUtil.encodeError('Invalid Profile Image', error)),
  bio: user.bio().error(([error]) => JoiUtil.encodeError('Invalid', error)),
  address: Joi.any(),
  investorProfile: user.investorProfile().optional().allow(null),
}).prefs({
  abortEarly: false,
});

export const updateUserProfileRequestSchema = Joi.object().keys({
  params: updateUserProfileParamsSchema,
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  List Users
******************************************************************************* */

export const listUsersParamsSchema = Joi.object().keys({
  pagination: paginationSchema.optional(),
  query: Joi.object().keys({
    search: Joi.string().optional().allow('', null),
  }),
}).prefs({
  abortEarly: false,
});

export const listUsersRequestSchema = Joi.object().keys({
  auth: authenticatedDataSchema,
  params: listUsersParamsSchema,
});

/** ******************************************************************************
*  Hide Quick Start Guide
******************************************************************************* */
export const hideQuickStartGuideSchema = Joi.object().keys({
  params: Joi.object().keys({
    hideQuickStartGuide: Joi.boolean().required(),
  }),
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Update User OAuth Providers
******************************************************************************* */
export const updateUserOAuthProvidersSchema = Joi.object().keys({
  params: Joi.object().keys({
    oAuthProviders: user.oAuthProviders().required(),
  }),
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Update User Notifications Settings
******************************************************************************* */

export const notificationSettings = Joi.object().keys({
  disableMessages: Joi.boolean().allow(null),
  disableOfferUpdates: Joi.boolean().allow(null),
  disableFavoritedListingsUpdates: Joi.boolean().allow(null),
  disableListingUpdates: Joi.boolean().allow(null),
  disableNewOffers: Joi.boolean().allow(null),
  disableRevisedOffer: Joi.boolean().allow(null),
  disableNewLeads: Joi.boolean().allow(null),
  disableInvestmentOpportunities: Joi.boolean().allow(null),
  disableAccountReminders: Joi.boolean().allow(null),
  disablePrivateListingsUpdates: Joi.boolean().allow(null),
});

export const updateUserNotificationsSchema = Joi.object().keys({
  params: Joi.object().keys({
    notificationSettings: user.notificationSettings().required(),
  }),
  auth: authenticatedDataSchema,
});

/** ******************************************************************************
*  Update Nylas Settings
******************************************************************************* */

const nylasSettings = Joi.object().keys({
  nylasToken: Joi.string().optional().allow(null),
  nylasId: Joi.string().optional().allow(null),
  nylasAuthenticated: Joi.boolean().optional().allow(null),
  nylasProvider: Joi.string().allow(null),
  dailyEmailLimit: Joi.number().optional().allow(null),
  emailLastSentAt: Joi.number().optional().allow(null),
});

export const updateUserNylasSettingsParamsSchema = Joi.object().keys({
  nylasToken: Joi.string().optional().allow(null),
  nylasId: Joi.string().optional().allow(null),
  nylasAuthenticated: Joi.boolean().optional().allow(null),
  nylasProvider: Joi.string().allow(null),
  dailyEmailLimit: Joi.number().optional().allow(null),
  emailLastSentAt: Joi.number().optional().allow(null),
}).prefs({
  abortEarly: false,
});

export const updateUserNylasSettingsRequestSchema = Joi.object().keys({
  auth: authenticatedDataSchema,
  params: updateUserNylasSettingsParamsSchema,
});

/** ******************************************************************************
*  Update User Contacts
******************************************************************************* */

export const updateUserContactsParamsSchema = Joi.object().keys({
  contacts: Joi.array().items(listingContact),
});

export const updateUserContactsRequestSchema = Joi.object().keys({
  auth: authenticatedDataSchema,
  params: updateUserContactsParamsSchema,
});

/** ******************************************************************************
*  Check if broker user has published listing
******************************************************************************* */

export const checkIfBrokerHasPublishedListingParamsSchema = Joi.object().keys({
  userId: user._id().required(),
});

export const checkIfBrokerHasPublishedListingRequestSchema = Joi.object().keys({
  auth: authenticatedDataSchema,
  params: checkIfBrokerHasPublishedListingParamsSchema,
});

/** ******************************************************************************
*  Toggle User Account Activation
******************************************************************************* */

export const toggleUserAccountActivationParamsSchema = Joi.object().keys({
  userId: user._id().required(),
  deactivate: Joi.boolean().required(),
});

export const toggleUserAccountActivationRequestSchema = Joi.object().keys({
  auth: authenticatedDataSchema,
  params: toggleUserAccountActivationParamsSchema,
});
