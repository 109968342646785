import gql from 'graphql-tag';
import LeadFragments from '../fragments/lead.fragments';

const CREATE_LEAD_NOTE = gql`
  mutation CreateLeadNote($params: CreateLeadNoteParams!) {
    createLeadNote(params: $params) {
      ...LeadFields
      ...LeadLoiFiles
      ...LeadNotes
      ...LeadSellerNotes
      ...LeadUser
      ...LeadListing
      ...LeadMetrics
      ...LeadMeta
      ...LeadAnalyticsEvents
      ...LeadChannel
    }
  }

  ${LeadFragments.fields}
  ${LeadFragments.loiFiles}
  ${LeadFragments.notes}
  ${LeadFragments.sellerNotes}
  ${LeadFragments.user}
  ${LeadFragments.listing}
  ${LeadFragments.metrics}
  ${LeadFragments.meta}
  ${LeadFragments.analyticsEvents}
  ${LeadFragments.channel}
`;

export default CREATE_LEAD_NOTE;
