import React from 'react';
import styled from '@emotion/styled';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { media } from '../../utils/MediaQuery';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';

const Container = styled.div`
  width: 632px;
  max-height: 80vw;
  overflow-y: auto;

  ${media.mobile} {
    width: 100%;
    max-height: unset;
  }
`;

export type DisplayTextBlockModalProps = {
  title: string;
  text: string;
};

const DisplayTextBlockModal: React.FC<DisplayTextBlockModalProps> = ({
  title,
  text,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(AppActions.popModal());

  /* Render */
  return (
    <ModalContainer>
      <ModalHeader title={title} close={popModal} />
      <ModalContent>
        <Container>
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Grey900}
          >
            {text}
          </Text>
        </Container>
      </ModalContent>
      <ModalFooter />
    </ModalContainer>
  );
};

export default DisplayTextBlockModal;
