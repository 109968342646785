enum BiproxiPlatformRolesEnum {
  BiproxiAdmin = 'BIPROXI_ADMIN', // highest level role. has access to ALL permissions
  SuperAdmin = 'SUPER_ADMIN', // has access to super admin privileges but not ALL permissions
  Admin = 'ADMIN',
  Developer = 'DEVELOPER',
}

export enum Neo4jListingRolesEnum {
  Owner = 'OWNER'
}

export enum TeamRolesEnum {
  Owner = 'OWNER',
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Pending = 'PENDING',
}

export default BiproxiPlatformRolesEnum;
