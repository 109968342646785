import ListingPropertyTypeEnum from '../enums/ListingPropertyTypeEnum';
import ListingInfoFieldNamesEnum from '../enums/ListingInfoFieldNamesEnum';

type PropertyTypeInfoFieldNames = Record<ListingPropertyTypeEnum, ListingInfoFieldNamesEnum[]>

const PropertyTypeFields: PropertyTypeInfoFieldNames = {
  // Absolute Net
  [ListingPropertyTypeEnum.AbsoluteNet]: [
    ListingInfoFieldNamesEnum.TenantName,
    ListingInfoFieldNamesEnum.Guarantor,
    ListingInfoFieldNamesEnum.Credit,
    ListingInfoFieldNamesEnum.CreditRatingAgency,
    ListingInfoFieldNamesEnum.CreditRating,
    ListingInfoFieldNamesEnum.AnnualRent,
    ListingInfoFieldNamesEnum.Ownership,
    ListingInfoFieldNamesEnum.LeaseExpiration,
    ListingInfoFieldNamesEnum.NumberofLeaseRenewals,
    ListingInfoFieldNamesEnum.YearsPerRenewalOption,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Assisted Living
  [ListingPropertyTypeEnum.AssistedLiving]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.NumberofRooms,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.AgeRestriction,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Bio Tech
  [ListingPropertyTypeEnum.BioTech]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.CeilingHeight,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.LabToOfficeSqFtRatio,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Bowling Alley
  [ListingPropertyTypeEnum.BowlingAlley]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.Operating,
    ListingInfoFieldNamesEnum.RedevelopmentOpportunity,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.FeeSimple,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Brownfield
  [ListingPropertyTypeEnum.Brownfield]: [
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.LocationType,
    ListingInfoFieldNamesEnum.DevelopableLand,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.SIC,
    ListingInfoFieldNamesEnum.NAICS,
    ListingInfoFieldNamesEnum.ConservationEasement,
    ListingInfoFieldNamesEnum.EnvironmentalSurvey,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Built To Suit
  [ListingPropertyTypeEnum.BuilttoSuit]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingClass,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Casino
  [ListingPropertyTypeEnum.Casino]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.ADR,
    ListingInfoFieldNamesEnum.NumberofRooms,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.EconomicScale,
    ListingInfoFieldNamesEnum.RevPAR,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Cell Towers
  [ListingPropertyTypeEnum.CellTowers]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.AnchorTenant,
    ListingInfoFieldNamesEnum.AnchorTenantCredit,
    ListingInfoFieldNamesEnum.NumberofTenants,
    ListingInfoFieldNamesEnum.AdditionalStructuralCapacity,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.AnchoredTenantLeaseExpiration,
    ListingInfoFieldNamesEnum.FeeSimple,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Childcare
  [ListingPropertyTypeEnum.Childcare]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.OperatingForChildcare,
    ListingInfoFieldNamesEnum.Tenancy,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Church
  [ListingPropertyTypeEnum.Church]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Operating,
    ListingInfoFieldNamesEnum.RedevelopmentOpportunity,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.FeeSimple,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Data Center
  [ListingPropertyTypeEnum.DataCenter]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.CeilingHeight,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.RedevelopmentOpportunity,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Dental
  [ListingPropertyTypeEnum.Dental]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Development
  [ListingPropertyTypeEnum.Development]: [
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.LocationType,
    ListingInfoFieldNamesEnum.DevelopableLand,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.SIC,
    ListingInfoFieldNamesEnum.NAICS,
    ListingInfoFieldNamesEnum.ConservationEasement,
    ListingInfoFieldNamesEnum.EnvironmentalSurvey,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Dialysis
  [ListingPropertyTypeEnum.Dialysis]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Distribution Warehouse
  [ListingPropertyTypeEnum.DistributionWarehouse]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.ClearHeight,
    ListingInfoFieldNamesEnum.GradeLevelDoors,
    ListingInfoFieldNamesEnum.LoadingDocks,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Double Net
  [ListingPropertyTypeEnum.DoubleNet]: [
    ListingInfoFieldNamesEnum.TenantName,
    ListingInfoFieldNamesEnum.Guarantor,
    ListingInfoFieldNamesEnum.Credit,
    ListingInfoFieldNamesEnum.CreditRatingAgency,
    ListingInfoFieldNamesEnum.CreditRating,
    ListingInfoFieldNamesEnum.AnnualRent,
    ListingInfoFieldNamesEnum.Ownership,
    ListingInfoFieldNamesEnum.LeaseExpiration,
    ListingInfoFieldNamesEnum.NumberofLeaseRenewals,
    ListingInfoFieldNamesEnum.YearsPerRenewalOption,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Extended Stay
  [ListingPropertyTypeEnum.ExtendedStay]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.ADR,
    ListingInfoFieldNamesEnum.NumberofRooms,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.EconomicScale,
    ListingInfoFieldNamesEnum.RevPAR,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Federal
  [ListingPropertyTypeEnum.Federal]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.CreditRatingAgency,
    ListingInfoFieldNamesEnum.CreditRating,
    ListingInfoFieldNamesEnum.LeaseCommencementDate,
    ListingInfoFieldNamesEnum.RemainingTotalTerm,
    ListingInfoFieldNamesEnum.AnnualRent,
    ListingInfoFieldNamesEnum.AnnualRentIncrease,
    ListingInfoFieldNamesEnum.KickOutClause,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Flex
  [ListingPropertyTypeEnum.Flex]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.ClearHeight,
    ListingInfoFieldNamesEnum.GradeLevelDoors,
    ListingInfoFieldNamesEnum.LoadingDocks,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Full Service
  [ListingPropertyTypeEnum.FullService]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.EconomicScale,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.ADR,
    ListingInfoFieldNamesEnum.NumberofRooms,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.RevPAR,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Garden
  [ListingPropertyTypeEnum.Garden]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.AgeRestriction,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Gas Station
  [ListingPropertyTypeEnum.GasStation]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.NumberofUnitsSpaces,
    ListingInfoFieldNamesEnum.Anchored,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // General Purpose
  [ListingPropertyTypeEnum.GeneralPurpose]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.ClearHeight,
    ListingInfoFieldNamesEnum.GradeLevelDoors,
    ListingInfoFieldNamesEnum.LoadingDocks,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Government Contractor
  [ListingPropertyTypeEnum.GovernmentContractor]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.LeaseCommencementDate,
    ListingInfoFieldNamesEnum.CreditRatingAgency,
    ListingInfoFieldNamesEnum.CreditRating,
    ListingInfoFieldNamesEnum.RemainingTotalTerm,
    ListingInfoFieldNamesEnum.AnnualRent,
    ListingInfoFieldNamesEnum.AnnualRentIncrease,
    ListingInfoFieldNamesEnum.KickOutClause,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Greenfield
  [ListingPropertyTypeEnum.Greenfield]: [
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.LocationType,
    ListingInfoFieldNamesEnum.DevelopableLand,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.SIC,
    ListingInfoFieldNamesEnum.NAICS,
    ListingInfoFieldNamesEnum.ConservationEasement,
    ListingInfoFieldNamesEnum.EnvironmentalSurvey,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Gross
  [ListingPropertyTypeEnum.Gross]: [
    ListingInfoFieldNamesEnum.TenantName,
    ListingInfoFieldNamesEnum.Guarantor,
    ListingInfoFieldNamesEnum.Credit,
    ListingInfoFieldNamesEnum.CreditRatingAgency,
    ListingInfoFieldNamesEnum.CreditRating,
    ListingInfoFieldNamesEnum.AnnualRent,
    ListingInfoFieldNamesEnum.Ownership,
    ListingInfoFieldNamesEnum.LeaseExpiration,
    ListingInfoFieldNamesEnum.NumberofLeaseRenewals,
    ListingInfoFieldNamesEnum.YearsPerRenewalOption,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // GSA
  [ListingPropertyTypeEnum.GSA]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.CreditRatingAgency,
    ListingInfoFieldNamesEnum.CreditRating,
    ListingInfoFieldNamesEnum.LeaseCommencementDate,
    ListingInfoFieldNamesEnum.RemainingTotalTerm,
    ListingInfoFieldNamesEnum.AnnualRent,
    ListingInfoFieldNamesEnum.AnnualRentIncrease,
    ListingInfoFieldNamesEnum.KickOutClause,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // HeavyManufacturing
  [ListingPropertyTypeEnum.HeavyManufacturing]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.GradeLevelDoors,
    ListingInfoFieldNamesEnum.LoadingDocks,
    ListingInfoFieldNamesEnum.ClearHeight,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // High Rise
  [ListingPropertyTypeEnum.HighRise]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.AgeRestriction,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Hospital
  [ListingPropertyTypeEnum.Hospital]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Independent Living
  [ListingPropertyTypeEnum.IndependentLiving]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.AgeRestriction,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Infill
  [ListingPropertyTypeEnum.Infill]: [
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.LocationType,
    ListingInfoFieldNamesEnum.DevelopableLand,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.SIC,
    ListingInfoFieldNamesEnum.NAICS,
    ListingInfoFieldNamesEnum.ConservationEasement,
    ListingInfoFieldNamesEnum.EnvironmentalSurvey,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Light Assembly
  [ListingPropertyTypeEnum.LightAssembly]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.GradeLevelDoors,
    ListingInfoFieldNamesEnum.LoadingDocks,
    ListingInfoFieldNamesEnum.ClearHeight,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Limited Service
  [ListingPropertyTypeEnum.LimitedService]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.EconomicScale,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.ADR,
    ListingInfoFieldNamesEnum.RevPAR,
    ListingInfoFieldNamesEnum.NumberofRooms,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Mall
  [ListingPropertyTypeEnum.Mall]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.NumberofUnitsSpaces,
    ListingInfoFieldNamesEnum.Anchored,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Marina
  [ListingPropertyTypeEnum.Marina]: [
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.NumberofWetSlips,
    ListingInfoFieldNamesEnum.MaximumBoatSize,
    ListingInfoFieldNamesEnum.NumberofDryRacks,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.RedevelopmentOpportunity,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // MedicalCampus
  [ListingPropertyTypeEnum.MedicalCampus]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.OnCampus,
    ListingInfoFieldNamesEnum.NumberofTenants,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.Credit,
    ListingInfoFieldNamesEnum.WeightedAverageLeaseTerm,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Medical Office
  [ListingPropertyTypeEnum.MedicalOffice]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.OnCampus,
    ListingInfoFieldNamesEnum.NumberofTenants,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.Credit,
    ListingInfoFieldNamesEnum.WeightedAverageLeaseTerm,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Mid Rise
  [ListingPropertyTypeEnum.MidRise]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.AgeRestriction,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Mixed Use Multi-family
  [ListingPropertyTypeEnum.MixedUseMultiFamily]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.AgeRestriction,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Mixed Use Office
  [ListingPropertyTypeEnum.MixedUseOffice]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.Anchored,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Mixed Use Retail
  [ListingPropertyTypeEnum.MixedUseRetail]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.Anchored,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Mobile Home
  [ListingPropertyTypeEnum.MobileHome]: [
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.NumberofPads,
    ListingInfoFieldNamesEnum.CommunityType,
    ListingInfoFieldNamesEnum.AverageRentPerPad,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.WaterPaidbyPark,
    ListingInfoFieldNamesEnum.ElectricBilledDirect,
    ListingInfoFieldNamesEnum.UnitBreakdownForMobileHome,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Modified Gross
  [ListingPropertyTypeEnum.ModifiedGross]: [
    ListingInfoFieldNamesEnum.TenantName,
    ListingInfoFieldNamesEnum.Guarantor,
    ListingInfoFieldNamesEnum.Credit,
    ListingInfoFieldNamesEnum.CreditRatingAgency,
    ListingInfoFieldNamesEnum.CreditRating,
    ListingInfoFieldNamesEnum.AnnualRent,
    ListingInfoFieldNamesEnum.Ownership,
    ListingInfoFieldNamesEnum.LeaseExpiration,
    ListingInfoFieldNamesEnum.NumberofLeaseRenewals,
    ListingInfoFieldNamesEnum.YearsPerRenewalOption,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Multi Tenant
  [ListingPropertyTypeEnum.MultiTenant]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingClass,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Neighborhood Center
  [ListingPropertyTypeEnum.NeighborhoodCenter]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.NumberofUnitsSpaces,
    ListingInfoFieldNamesEnum.Anchored,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Non Performing
  [ListingPropertyTypeEnum.NonPerforming]: [
    ListingInfoFieldNamesEnum.DefaultStatus,
    ListingInfoFieldNamesEnum.AssetClass,
    ListingInfoFieldNamesEnum.LeinPosition,
    ListingInfoFieldNamesEnum.UPB,
    ListingInfoFieldNamesEnum.MaturityDate,
    ListingInfoFieldNamesEnum.AmortizationRate,
    ListingInfoFieldNamesEnum.InterestRate,
    ListingInfoFieldNamesEnum.DefaultInterest,
    ListingInfoFieldNamesEnum.AccruedInterestAmount,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Out Parcel
  [ListingPropertyTypeEnum.OutParcel]: [
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.LocationType,
    ListingInfoFieldNamesEnum.DevelopableLand,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.SIC,
    ListingInfoFieldNamesEnum.NAICS,
    ListingInfoFieldNamesEnum.ConservationEasement,
    ListingInfoFieldNamesEnum.EnvironmentalSurvey,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Parking Lot
  [ListingPropertyTypeEnum.ParkingLot]: [
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.Type,
    ListingInfoFieldNamesEnum.NumberofSpaces,
    ListingInfoFieldNamesEnum.NumberofLevels,
    ListingInfoFieldNamesEnum.TotalSqFt,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.FeeSimple,
    ListingInfoFieldNamesEnum.LocationType,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Power Center
  [ListingPropertyTypeEnum.PowerCenter]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.NumberofUnitsSpaces,
    ListingInfoFieldNamesEnum.Anchored,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Redevelopment
  [ListingPropertyTypeEnum.Redevelopment]: [
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.LocationType,
    ListingInfoFieldNamesEnum.DevelopableLand,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.ConservationEasement,
    ListingInfoFieldNamesEnum.SIC,
    ListingInfoFieldNamesEnum.NAICS,
    ListingInfoFieldNamesEnum.EnvironmentalSurvey,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Resort
  [ListingPropertyTypeEnum.Resort]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.EconomicScale,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.ADR,
    ListingInfoFieldNamesEnum.NumberofRooms,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.RevPAR,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Select Service
  [ListingPropertyTypeEnum.SelectService]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.EconomicScale,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.ADR,
    ListingInfoFieldNamesEnum.NumberofRooms,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.RevPAR,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Self Storage
  [ListingPropertyTypeEnum.SelfStorage]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.UnitPriceFor10X10,
    ListingInfoFieldNamesEnum.BoatRVStorage,
    ListingInfoFieldNamesEnum.UnitBreakdownForSelfStorage,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Senior Housing
  [ListingPropertyTypeEnum.SeniorHousing]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.AgeRestriction,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Single Net
  [ListingPropertyTypeEnum.SingleNet]: [
    ListingInfoFieldNamesEnum.TenantName,
    ListingInfoFieldNamesEnum.Guarantor,
    ListingInfoFieldNamesEnum.Credit,
    ListingInfoFieldNamesEnum.CreditRatingAgency,
    ListingInfoFieldNamesEnum.CreditRating,
    ListingInfoFieldNamesEnum.AnnualRent,
    ListingInfoFieldNamesEnum.Ownership,
    ListingInfoFieldNamesEnum.LeaseExpiration,
    ListingInfoFieldNamesEnum.NumberofLeaseRenewals,
    ListingInfoFieldNamesEnum.YearsPerRenewalOption,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Single Tenant Office
  [ListingPropertyTypeEnum.SingleTenantOffice]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingClass,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Single Tenant Retail
  [ListingPropertyTypeEnum.SingleTenantRetail]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.NetLease,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Sports and Leisure
  [ListingPropertyTypeEnum.SportsandLeisure]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.Operating,
    ListingInfoFieldNamesEnum.RedevelopmentOpportunity,
    ListingInfoFieldNamesEnum.FeeSimple,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // State
  [ListingPropertyTypeEnum.State]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.CreditRatingAgency,
    ListingInfoFieldNamesEnum.CreditRating,
    ListingInfoFieldNamesEnum.LeaseCommencementDate,
    ListingInfoFieldNamesEnum.RemainingTotalTerm,
    ListingInfoFieldNamesEnum.AnnualRent,
    ListingInfoFieldNamesEnum.AnnualRentIncrease,
    ListingInfoFieldNamesEnum.KickOutClause,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Strip Center
  [ListingPropertyTypeEnum.StripCenter]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.NumberofUnitsSpaces,
    ListingInfoFieldNamesEnum.Anchored,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Student Housing
  [ListingPropertyTypeEnum.StudentHousing]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.AgeRestriction,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Sub Performing
  [ListingPropertyTypeEnum.SubPerforming]: [
    ListingInfoFieldNamesEnum.DefaultStatus,
    ListingInfoFieldNamesEnum.AssetClass,
    ListingInfoFieldNamesEnum.LeinPosition,
    ListingInfoFieldNamesEnum.UPB,
    ListingInfoFieldNamesEnum.MaturityDate,
    ListingInfoFieldNamesEnum.AmortizationRate,
    ListingInfoFieldNamesEnum.InterestRate,
    ListingInfoFieldNamesEnum.DefaultInterest,
    ListingInfoFieldNamesEnum.AccruedInterestAmount,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Suburban
  [ListingPropertyTypeEnum.Suburban]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingClass,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Theatre
  [ListingPropertyTypeEnum.Theatre]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.RedevelopmentOpportunity,
    ListingInfoFieldNamesEnum.FeeSimple,
    ListingInfoFieldNamesEnum.Operating,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // TripleNet
  [ListingPropertyTypeEnum.TripleNet]: [
    ListingInfoFieldNamesEnum.TenantName,
    ListingInfoFieldNamesEnum.Guarantor,
    ListingInfoFieldNamesEnum.Credit,
    ListingInfoFieldNamesEnum.CreditRatingAgency,
    ListingInfoFieldNamesEnum.CreditRating,
    ListingInfoFieldNamesEnum.AnnualRent,
    ListingInfoFieldNamesEnum.Ownership,
    ListingInfoFieldNamesEnum.LeaseExpiration,
    ListingInfoFieldNamesEnum.NumberofLeaseRenewals,
    ListingInfoFieldNamesEnum.YearsPerRenewalOption,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // TruckTerminal
  [ListingPropertyTypeEnum.TruckTerminal]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.GradeLevelDoors,
    ListingInfoFieldNamesEnum.LoadingDocks,
    ListingInfoFieldNamesEnum.ClearHeight,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Urban
  [ListingPropertyTypeEnum.Urban]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingClass,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // UrgentCare
  [ListingPropertyTypeEnum.UrgentCare]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // US Government
  [ListingPropertyTypeEnum.USGovernment]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.CreditRatingAgency,
    ListingInfoFieldNamesEnum.CreditRating,
    ListingInfoFieldNamesEnum.LeaseCommencementDate,
    ListingInfoFieldNamesEnum.RemainingTotalTerm,
    ListingInfoFieldNamesEnum.AnnualRent,
    ListingInfoFieldNamesEnum.AnnualRentIncrease,
    ListingInfoFieldNamesEnum.KickOutClause,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Veterinarian
  [ListingPropertyTypeEnum.Veterinarian]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.BuildingSqFt,
    ListingInfoFieldNamesEnum.LotSize,
    ListingInfoFieldNamesEnum.NumberofSpaces,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.Zoning,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  // Walk Up
  [ListingPropertyTypeEnum.WalkUp]: [
    ListingInfoFieldNamesEnum.YearBuilt,
    ListingInfoFieldNamesEnum.YearRenovated,
    ListingInfoFieldNamesEnum.NumberOfFloors,
    ListingInfoFieldNamesEnum.NumberofUnits,
    ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily,
    ListingInfoFieldNamesEnum.ParkingSpaces,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.AgeRestriction,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  [ListingPropertyTypeEnum.Residential]: [
    ListingInfoFieldNamesEnum.NumberOfHomes,
    ListingInfoFieldNamesEnum.Contiguous,
    ListingInfoFieldNamesEnum.SameSubdivision,
    ListingInfoFieldNamesEnum.YearBuiltRange,
    ListingInfoFieldNamesEnum.AverageNumberOfBedAndBath,
    ListingInfoFieldNamesEnum.AverageSqft,
    ListingInfoFieldNamesEnum.RentControl,
    ListingInfoFieldNamesEnum.NumberOfExistingLeases,
    ListingInfoFieldNamesEnum.Occupancy,
    ListingInfoFieldNamesEnum.AssumableFinancing,
    ListingInfoFieldNamesEnum.CapRate,
  ],
  [ListingPropertyTypeEnum.Other]: [],
};

export default PropertyTypeFields;
