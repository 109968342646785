/* eslint-disable no-undef */
import { logger } from '@biproxi/logger';
/* eslint-disable camelcase */
import React from 'react';
import styled from '@emotion/styled';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import GoogleTimezoneAPI from '@biproxi/utils/GoogleTimezoneAPI';
import IAddress from '@biproxi/models/interfaces/IAddress';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import ILocation from '@biproxi/models/interfaces/ILocation';
import Dropdown, { DropdownTypesEnum } from '../elements/Dropdown';
import Input, { InputSizeEnum, InputThemeEnum, InputTypesEnum } from '../elements/Input';
import Icons from '../elements/Icons';
import Colors from '../styles/Colors';
import Icon from '../elements/Icon';
import Text, { TextTypesEnum } from '../elements/Text';
import Ellipsis from '../elements/Ellipsis';
import { TDropdownItemProps } from './DropdownList';

const DropdownItem = styled.div<{isHovered: boolean}>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  transition: all 0.1s;
  color: ${Colors.Grey100};
  background-color: ${({ isHovered }) => (isHovered ? `${Colors.Brand50 || Colors.Blurple50}` : null)};
  &:hover {
    background-color: ${Colors.Brand50 || Colors.Blurple50};
    color: ${Colors.Black};
    cursor: pointer;
  }
`;

const Circle = styled.div`
  position: relative;
  height: 12px;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.2rem 1rem 1.2rem 1.2rem;
  border-radius: 50%;
`;

const ItemComponent = React.forwardRef<HTMLDivElement, TDropdownItemProps>(({
  item,
  onChange,
  isHovered,
}, ref) => (
  <DropdownItem
    data-cy="auto-complete-item"
    ref={ref}
    isHovered={isHovered}
    onClick={() => onChange(item)}
  >
    <Circle>
      <Icon icon={Icons.MapMarkerAltSolid} color={Colors.Brand700 || Colors.Blurple700} size={14} />
    </Circle>
    <Ellipsis>
      <Text type={TextTypesEnum.Regular16} color={Colors.Grey900}>
        {item.name}
      </Text>
    </Ellipsis>
  </DropdownItem>
));

type AutoCompleteAddressProps = {
  label?: string;
  dataCy?: string;
  placeholder?: string;
  value?: IAddress;
  onChange?: (address: IAddress) => void;
  error?: string;
  width?: string;
  showUnit?: boolean,
  inputSize?: InputSizeEnum;
  inputTheme?: InputThemeEnum;
  autoFocus?: boolean;
  fullAddressesOnly?: boolean;
  disabled?: boolean;
}

const AutoCompleteAddress: React.FC<AutoCompleteAddressProps> = ({
  label,
  dataCy,
  placeholder = 'Enter the address',
  value,
  onChange,
  error,
  width,
  showUnit = true,
  inputSize = InputSizeEnum.Regular,
  inputTheme = InputThemeEnum.Light,
  autoFocus = false,
  fullAddressesOnly = false,
  disabled = false,
}) => {
  const {
    value: inputValueText,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      // could limit to only full addresses in North America
      types: fullAddressesOnly ? ['address'] : undefined,
    },
    debounce: 250,
  });

  const handleSelect = async (place: google.maps.places.AutocompletePrediction | null): Promise<IAddress> => {
    if (place === null) {
      setValue('', false);
      clearSuggestions();
      onChange(null);
      return null;
    }

    setValue(place.description, false);
    clearSuggestions();

    try {
      const [result] = await getGeocode({
        placeId: place.place_id,
      });
      const {
        geometry: { location },
      } = result;

      const lat = location.lat();
      const lng = location.lng();

      const { timeZoneId } = await GoogleTimezoneAPI.getTimezoneInfo(lat, lng);

      const locationObj: ILocation = {
        type: 'Point',
        coordinates: [lng.toString(), lat.toString()], // GeoJson is [lng, lat] order
      };

      const addressObject = {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        location: locationObj,
        googlePlaceId: result.place_id,
        timeZoneId,
      };

      // assign the address components to our model
      // only confirmed to support US addresses
      // based off of the types google gives us in address_component array.
      result.address_components.forEach((ac: google.maps.GeocoderAddressComponent) => {
        // Street Number
        if (ac.types.includes('street_number')) {
          addressObject.address1 = ac.short_name;
        }

        // Street Name
        if (ac.types.includes('route')) {
          addressObject.address1 = `${addressObject.address1} ${ac.short_name}`;
        }

        // Secondary address line (i.e Unit B)
        if (ac.types.includes('subpremise')) {
          addressObject.address2 = ac.short_name;
        }

        // City name
        if ((ac.types.includes('locality') || ac.types.includes('sublocality')) && ac.types.includes('political')) {
          addressObject.city = ac.short_name;
        }

        // State
        if (
          ac.types.includes('administrative_area_level_1')
          && ac.types.includes('political')
        ) {
          addressObject.state = ac.short_name;
        }

        // Country
        if (ac.types.includes('country') && ac.types.includes('political')) {
          if (ac.short_name.toUpperCase() === 'US') {
            // Edge case where google is returning the country code
            // as both USA and US, we are standardizing on USA
            addressObject.country = 'USA';
          } else {
            addressObject.country = ac.short_name;
          }
        }
        if (ac.types.includes('postal_code')) {
          addressObject.zip = ac.short_name;
        }
      });
      return addressObject;
    } catch (e) {
      logger.error('AutoCompleteAddress handleSelect() error', e);
      return null;
    }
  };

  React.useEffect(() => {
    setValue(AddressUtil.formatAddress(value, { address2: true }) || '', false);
  }, [value]);

  return (
    <>
      <Dropdown
        label={label}
        cyInput={dataCy}
        error={error}
        type={DropdownTypesEnum.Search}
        inputSize={inputSize}
        inputTheme={inputTheme}
        value={inputValueText}
        onChange={async (place: google.maps.places.AutocompletePrediction) => {
          onChange(await handleSelect(place));
        }}
        clear={() => handleSelect(null)}
        onSearch={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        items={Object.values(data).map((value) => ({
          name: value.description,
          value,
        }))}
        width={width}
        ItemComponent={ItemComponent}
        autoFocus={autoFocus}
      />
      {showUnit && (
        <Input
          placeholder="Building, unit, floor, etc"
          value={value ? value?.address2 : ''}
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...value, address2: e.currentTarget.value });
          }}
          inputType={InputTypesEnum.Text}
          inputTheme={inputTheme}
          margin="8px 0px 0"
          disabled={disabled}
        />
      )}
    </>
  );
};

export default AutoCompleteAddress;
