import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import Colors from '../../styles/Colors';
import { AppState } from '../../redux/store';
import { FadeIn } from '../../elements/Motion';
import { media } from '../../utils/MediaQuery';
import ZIndexes from '../../styles/ZIndexes';
import RegisterModal, { RegisterModalProps } from './RegisterModal';
import CompleteRegisterModal, { CompleteRegisterModalProps } from './CompleteRegisterModal';
import VerifyPhoneNumberModal, { VerifyPhoneNumberModalProps } from './VerifyPhoneNumberModal';
import LoginModal, { LoginModalProps } from './LoginModal';
import ForgotPasswordEmailModal, { ForgotPasswordEmailModalProps } from './ForgotPasswordEmailModal';
import ResetPasswordModal, { ResetPasswordModalProps } from './ResetPasswordModal';
import PhoneVerifiedModal, { PhoneVerifiedModalProps } from './PhoneVerifiedModal';
import ChangeProfilePictureModal, { ChangeProfilePictureModalProps } from './ChangeProfilePictureModal';
import ChangePhoneNumberModal, { ChangePhoneNumberModalProps } from './ChangePhoneNumberModal';
import ChangePhoneNumberCodeModal, { ChangePhoneNumberCodeModalProps } from './ChangePhoneNumberCodeModal';
import ChangePasswordModal, { ChangePasswordModalProps } from './ChangePasswordModal';
import ChangeEmailAddressModal, { ChangeEmailAddressModalProps } from './ChangeEmailAddressModal';
import ChangeListingStatusModal, { ChangeListingStatusModalProps } from './ChangeListingStatusModal';
import AddLicenseModal, { AddLicenseModalProps } from './AddLicenseModal';
import EditHighlightModal, { EditHighlightModalProps } from './EditHighlightModal';
import UnlockDocumentsModal, { UnlockDocumentsModalProps } from './UnlockDocumentsModal';
import ConfirmChangeModal, { ConfirmChangeModalProps } from './ConfirmChangeModal';
import ShareListingModal, { ShareListingModalProps } from './ShareListingModal';
import SelectContactsModal, { SelectContactsModalProps } from './SelectContactsModal';
import AuthenticateWithNylasModal, { AuthenticateWithNylasModalProps } from './AuthenticateWithNylasModal';
import EmailPreviewModal, { EmailPreviewModalProps } from './EmailPreviewModal';
import PublishMessageModal, { PublishMessageModalProps } from './PublishMessageModal';
import SaveChangesModal, { SaveChangesModalProps } from './SaveChangesModal';
import ContactBrokerModal, { ContactBrokerModalConfig } from './ContactBrokerModal';
import OfferModal, { OfferModalProps } from './OfferModal';
import ChatModal, { ChatModalProps } from './ChatModal';
import LeadModal, { LeadModalProps } from './LeadModal';
import CreateListingActionsModal, { CreateListingActionsModalProps } from './CreateListingActionsModal';
import ManualAddressCoordinatesModal, { ManualAddressCoordinatesModalProps } from './ManualAddressCoordinates';
import useLockBodyScroll from '../../hooks/useLockBodyScroll.hook';
import RejectOfferModal, { RejectOfferModalProps } from './RejectOfferModal';
import BuyerLeadModal, { BuyerLeadModalProps } from './BuyerLeadModal';
import ProfilePreviewModal, { ProfilePreviewModalProps } from './ProfilePreviewModal';
import AcceptPrivateEventInvitationModal, { AcceptPrivateEventInvitationModalProps } from './AcceptPrivateEventInvitationModal';
import ModifyBiddingRoomModal, { ModifyBiddingRoomModalProps } from './ModifyBiddingRoomModal';
import SaveSearchModal, { SaveSearchModalProps } from './SaveSearchModal';
import PropertyOwnerContactInfoModal, { PropertyOwnerContactInfoModalProps } from './PropertyOwnerContactInfoModal';
import AddOrEditListingContactModal, { AddOrEditListingContactModalProps } from './AddOrEditListingContactModal';
import ListingFiltersModal, { ListingFiltersModalProps } from './ListingFiltersModal';
import MessageAllBiddersModal, { MessageAllBiddersModalProps } from './MessageAllBiddersModal';
import UploadListingModal, { UploadListingModalProps } from './UploadListingModal';
import AssignAdminModal, { AssignAdminModalProps } from './AssignAdminModal';
import UploadDocumentsAdminModal, { UploadDocumentsModalProps } from './UploadDocumentsAdminModal';
import PrivateListingPermissionRequestModal, { PrivateListingPermissionRequestModalProps } from './PrivateListingPermissionRequestModal';
import SecondaryPhoneNumberActionsModal, { SecondaryPhoneNumberActionsModalProps } from './SecondaryPhoneNumberActionsModal';
import GeneralErrorModal, { GeneralErrorModalProps } from './GeneralErrorModal';
import RequestPrivateListingAccessModal, { RequestPrivateListingAccessModalConfig } from './RequestPrivateListingAccessModal';
import WelcomeToBiproxiModal, { WelcomeToBiproxiModalProps } from './WelcomeToBiproxiModal';
import DeleteFromWebflowModal, { DeleteFromWebflowModalProps } from './DeleteFromWebflowModal';
import PushToWebflowModal, { PushToWebflowModalProps } from './PushToWebflowModal';
import BrokerListingsModal, { BrokerListingsModalProps } from './BrokerListingsModal';
import QuickStartGuideModal, { QuickStartGuideModalProps } from './QuickStartGuideModal';
import CropListingImageModal, { CropListingImageModalProps } from './CropListingImageModal';
import CreateTeamModal, { CreateTeamModalProps } from './CreateTeam.modal';
import InviteUserToTeamModal, { InviteUserToTeamModalProps } from './InviteUserToTeamModal';
import CreateListingReportModal, { CreateListingReportModalProps } from './CreateListingReportModal';
import DisplayTextBlockModal, { DisplayTextBlockModalProps } from './DisplayTextBlockModal';
import BillingPaywallModal, { BillingPaywallModalProps } from './BillingPaywallModal';
import LoginUpgradeAccountReminderModal, { LoginUpgradeAccountReminderModalProps } from './LoginUpgradeAccountReminderModal';
import AddListingsToTeamModal, { AddListingsToTeamModalProps } from './AddListingsToTeamModal';
import CompareDataExplorerPropertiesModal, { CompareDataExplorerPropertiesModalProps } from './CompareDataExplorerPropertiesModal';
import SubscriptionConfirmedModal, { SubscriptionConfirmedModalProps } from './SubscriptionConfirmedModal';

export enum ModalTypesEnum {
  Register = 'Register',
  CompleteRegister = 'CompleteRegister',
  VerifyPhoneNumber = 'VerifyPhoneNumber',
  Login = 'Login',
  PhoneVerified = 'PhoneVerified',
  ForgotPasswordEmail = 'ForgotPasswordEmail',
  ResetPassword = 'ResetPassword',
  ChangeProfilePicture = 'ChangeProfilePicture',
  ChangePhoneNumber = 'ChangePhoneNumber',
  ChangePhoneNumberCode = 'ChangePhoneNumberCode',
  ChangePassword = 'ChangePassword',
  ChangeEmailAddress = 'ChangeEmailAddress',
  ChangeListingStatus = 'ChangeListingStatus',
  AddLicense = 'AddLicense',
  DeleteLicense = 'DeleteLicense',
  DeleteHighlight = 'DeleteHighlight',
  EditHighlight = 'EditHighlight',
  UnlockDocuments = 'UnlockDocuments',
  ConfirmChange = 'ConfirmChange',
  ShareListingModal = 'ShareListingModal',
  SelectContacts = 'SelectContacts',
  AuthenticateWithNylas = 'AuthenticateWithNylas',
  EmailPreview = 'EmailPreview',
  PublishMessageModal = 'PublishMessageModal',
  SaveChanges = 'SaveChanges',
  ManualAddressCoordinates = 'ManualAddressCoordinates',
  ContactBroker = 'ContactBroker',
  Lead = 'Lead',
  Chat = 'Chat',
  Offer = 'Offer',
  RejectOffer = 'RejectOffer',
  BuyerLead = 'BuyerLead',
  CreateListingActions = 'CreateListingActions',
  ProfilePreview = 'ProfilePreview',
  AcceptPrivateEventInvitation = 'AcceptPrivateEventInvitation',
  ModifyBiddingRoom = 'ModifyBiddingRoom',
  SaveSearch = 'SaveSearch',
  PropertyOwnerContactInfo = 'PropertyOwnerContactInfo',
  AddOrEditListingContact = 'AddOrEditListingContact',
  ListingFilters = 'ListingFilters',
  MessageAllBidders = 'MessageAllBidders',
  UploadListing = 'UploadListing',
  UploadDocumentsAdmin = 'UploadDocumentsAdmin',
  AssignAdmin = 'AssignAdmin',
  PrivateListingPermissionRequest = 'PrivateListingPermissionRequest',
  SecondaryPhoneNumberActions = 'SecondaryPhoneNumberActions',
  GeneralErrorModal = 'GeneralErrorModal',
  RequestPrivateListingAccessModal = 'RequestPrivateListingAccessModal',
  WelcomeToBiproxiModal = 'WelcomeToBiproxiModal',
  DeleteFromWebflowModal = 'DeleteFromWebflowModal',
  PushToWebflowModal = 'PushToWebflowModal',
  BrokerListings = 'BrokerListings',
  QuickStartGuideModal = 'QuickStartGuideModal',
  CropListingImageModal = 'CropListingImageModal',
  CreateTeam = 'CreateTeam',
  InviteUserToTeam = 'InviteUserToTeam',
  CreateListingReport = 'CreateListingReport',
  DisplayTextBlock = 'DisplayTextBlock',
  BillingPaywall = 'BillingPaywall',
  LoginUpgradeAccountReminder = 'LoginUpgradeAccountReminder',
  AddListingsToTeam = 'AddListingsToTeam',
  CompareDataExplorerProperties = 'CompareDataExplorerProperties',
  SubscriptionConfirmed = 'SubscriptionConfirmed',
}

interface IModalProps {
  [ModalTypesEnum.Register]: RegisterModalProps;
  [ModalTypesEnum.CompleteRegister]: CompleteRegisterModalProps;
  [ModalTypesEnum.VerifyPhoneNumber]: VerifyPhoneNumberModalProps;
  [ModalTypesEnum.ForgotPasswordEmail]: ForgotPasswordEmailModalProps;
  [ModalTypesEnum.PhoneVerified]: PhoneVerifiedModalProps;
  [ModalTypesEnum.ResetPassword]: ResetPasswordModalProps;
  [ModalTypesEnum.Login]: LoginModalProps;
  [ModalTypesEnum.ChangeProfilePicture]: ChangeProfilePictureModalProps;
  [ModalTypesEnum.ChangePhoneNumber]: ChangePhoneNumberModalProps;
  [ModalTypesEnum.ChangePhoneNumberCode]: ChangePhoneNumberCodeModalProps;
  [ModalTypesEnum.ChangePassword]: ChangePasswordModalProps;
  [ModalTypesEnum.ChangeEmailAddress]: ChangeEmailAddressModalProps;
  [ModalTypesEnum.ChangeListingStatus]: ChangeListingStatusModalProps;
  [ModalTypesEnum.AddLicense]: AddLicenseModalProps;
  [ModalTypesEnum.EditHighlight]: EditHighlightModalProps;
  [ModalTypesEnum.UnlockDocuments]: UnlockDocumentsModalProps;
  [ModalTypesEnum.ConfirmChange]: ConfirmChangeModalProps;
  [ModalTypesEnum.ShareListingModal]: ShareListingModalProps;
  [ModalTypesEnum.SelectContacts]: SelectContactsModalProps;
  [ModalTypesEnum.AuthenticateWithNylas]: AuthenticateWithNylasModalProps;
  [ModalTypesEnum.EmailPreview]: EmailPreviewModalProps;
  [ModalTypesEnum.PublishMessageModal]: PublishMessageModalProps;
  [ModalTypesEnum.SaveChanges]: SaveChangesModalProps;
  [ModalTypesEnum.ManualAddressCoordinates]: ManualAddressCoordinatesModalProps;
  [ModalTypesEnum.ContactBroker]: ContactBrokerModalConfig;
  [ModalTypesEnum.Lead]: LeadModalProps;
  [ModalTypesEnum.Chat]: ChatModalProps;
  [ModalTypesEnum.Offer]: OfferModalProps;
  [ModalTypesEnum.RejectOffer]: RejectOfferModalProps;
  [ModalTypesEnum.BuyerLead]: BuyerLeadModalProps;
  [ModalTypesEnum.CreateListingActions]: CreateListingActionsModalProps;
  [ModalTypesEnum.ProfilePreview]: ProfilePreviewModalProps;
  [ModalTypesEnum.AcceptPrivateEventInvitation]: AcceptPrivateEventInvitationModalProps;
  [ModalTypesEnum.ModifyBiddingRoom]: ModifyBiddingRoomModalProps;
  [ModalTypesEnum.SaveSearch]: SaveSearchModalProps;
  [ModalTypesEnum.PropertyOwnerContactInfo]: PropertyOwnerContactInfoModalProps;
  [ModalTypesEnum.AddOrEditListingContact]: AddOrEditListingContactModalProps;
  [ModalTypesEnum.ListingFilters]: ListingFiltersModalProps;
  [ModalTypesEnum.MessageAllBidders]: MessageAllBiddersModalProps;
  [ModalTypesEnum.UploadListing]: UploadListingModalProps;
  [ModalTypesEnum.AssignAdmin]: AssignAdminModalProps;
  [ModalTypesEnum.UploadDocumentsAdmin]: UploadDocumentsModalProps;
  [ModalTypesEnum.PrivateListingPermissionRequest]: PrivateListingPermissionRequestModalProps;
  [ModalTypesEnum.SecondaryPhoneNumberActions]: SecondaryPhoneNumberActionsModalProps;
  [ModalTypesEnum.GeneralErrorModal]: GeneralErrorModalProps;
  [ModalTypesEnum.RequestPrivateListingAccessModal]: RequestPrivateListingAccessModalConfig;
  [ModalTypesEnum.WelcomeToBiproxiModal]: WelcomeToBiproxiModalProps;
  [ModalTypesEnum.DeleteFromWebflowModal]: DeleteFromWebflowModalProps;
  [ModalTypesEnum.PushToWebflowModal]: PushToWebflowModalProps;
  [ModalTypesEnum.BrokerListings]: BrokerListingsModalProps;
  [ModalTypesEnum.QuickStartGuideModal]: QuickStartGuideModalProps;
  [ModalTypesEnum.CropListingImageModal]: CropListingImageModalProps;
  [ModalTypesEnum.CreateTeam]: CreateTeamModalProps;
  [ModalTypesEnum.InviteUserToTeam]: InviteUserToTeamModalProps;
  [ModalTypesEnum.CreateListingReport]: CreateListingReportModalProps;
  [ModalTypesEnum.DisplayTextBlock]: DisplayTextBlockModalProps;
  [ModalTypesEnum.BillingPaywall]: BillingPaywallModalProps;
  [ModalTypesEnum.LoginUpgradeAccountReminder]: LoginUpgradeAccountReminderModalProps;
  [ModalTypesEnum.AddListingsToTeam]: AddListingsToTeamModalProps;
  [ModalTypesEnum.CompareDataExplorerProperties]: CompareDataExplorerPropertiesModalProps;
  [ModalTypesEnum.SubscriptionConfirmed]: SubscriptionConfirmedModalProps;
}

export type TModalProps = IModalProps[keyof IModalProps];

export interface IModalConfig {
  type: ModalTypesEnum;
  props: TModalProps;
}

// No safe modals for now
export const urlSafeModalTypesEnum = [
  // ModalTypesEnum.Register,
  // ModalTypesEnum.Login,
  // ModalTypesEnum.ResetPassword,
  ModalTypesEnum.PrivateListingPermissionRequest,
  ModalTypesEnum.SubscriptionConfirmed,
  // ModalTypesEnum.WelcomeToBiproxiModal,
  // ModalTypesEnum.QuickStartGuideModal,
];

export enum ModalPositions {
  Top = 'Top',
  Center = 'Center',
}

const positions = {
  [ModalTypesEnum.Register]: ModalPositions.Center,
  [ModalTypesEnum.CompleteRegister]: ModalPositions.Center,
  [ModalTypesEnum.Login]: ModalPositions.Center,
  [ModalTypesEnum.VerifyPhoneNumber]: ModalPositions.Center,
  [ModalTypesEnum.ForgotPasswordEmail]: ModalPositions.Center,
  [ModalTypesEnum.ResetPassword]: ModalPositions.Center,
  [ModalTypesEnum.PhoneVerified]: ModalPositions.Center,
  [ModalTypesEnum.ChangeProfilePicture]: ModalPositions.Top,
  [ModalTypesEnum.ChangePhoneNumber]: ModalPositions.Top,
  [ModalTypesEnum.ChangePhoneNumberCode]: ModalPositions.Top,
  [ModalTypesEnum.ChangePassword]: ModalPositions.Center,
  [ModalTypesEnum.ChangeEmailAddress]: ModalPositions.Top,
  [ModalTypesEnum.ChangeListingStatus]: ModalPositions.Center,
  [ModalTypesEnum.AddLicense]: ModalPositions.Top,
  [ModalTypesEnum.DeleteLicense]: ModalPositions.Top,
  [ModalTypesEnum.DeleteHighlight]: ModalPositions.Top,
  [ModalTypesEnum.EditHighlight]: ModalPositions.Top,
  [ModalTypesEnum.UnlockDocuments]: ModalPositions.Top,
  [ModalTypesEnum.ConfirmChange]: ModalPositions.Top,
  [ModalTypesEnum.PublishMessageModal]: ModalPositions.Top,
  [ModalTypesEnum.SaveChanges]: ModalPositions.Top,
  [ModalTypesEnum.ShareListingModal]: ModalPositions.Center,
  [ModalTypesEnum.SelectContacts]: ModalPositions.Center,
  [ModalTypesEnum.AuthenticateWithNylas]: ModalPositions.Center,
  [ModalTypesEnum.EmailPreview]: ModalPositions.Center,
  [ModalTypesEnum.ManualAddressCoordinates]: ModalPositions.Center,
  [ModalTypesEnum.ContactBroker]: ModalPositions.Center,
  [ModalTypesEnum.Chat]: ModalPositions.Center,
  [ModalTypesEnum.Offer]: ModalPositions.Top,
  [ModalTypesEnum.RejectOffer]: ModalPositions.Center,
  [ModalTypesEnum.BuyerLead]: ModalPositions.Center,
  [ModalTypesEnum.CreateListingActions]: ModalPositions.Top,
  [ModalTypesEnum.AcceptPrivateEventInvitation]: ModalPositions.Center,
  [ModalTypesEnum.ModifyBiddingRoom]: ModalPositions.Center,
  [ModalTypesEnum.ProfilePreview]: ModalPositions.Top,
  [ModalTypesEnum.SaveSearch]: ModalPositions.Center,
  [ModalTypesEnum.PropertyOwnerContactInfo]: ModalPositions.Center,
  [ModalTypesEnum.AddOrEditListingContact]: ModalPositions.Top,
  [ModalTypesEnum.ListingFilters]: ModalPositions.Center,
  [ModalTypesEnum.MessageAllBidders]: ModalPositions.Center,
  [ModalTypesEnum.UploadListing]: ModalPositions.Center,
  [ModalTypesEnum.AssignAdmin]: ModalPositions.Center,
  [ModalTypesEnum.UploadDocumentsAdmin]: ModalPositions.Center,
  [ModalTypesEnum.PrivateListingPermissionRequest]: ModalPositions.Center,
  [ModalTypesEnum.SecondaryPhoneNumberActions]: ModalPositions.Center,
  [ModalTypesEnum.GeneralErrorModal]: ModalPositions.Center,
  [ModalTypesEnum.RequestPrivateListingAccessModal]: ModalPositions.Center,
  [ModalTypesEnum.WelcomeToBiproxiModal]: ModalPositions.Center,
  [ModalTypesEnum.DeleteFromWebflowModal]: ModalPositions.Center,
  [ModalTypesEnum.PushToWebflowModal]: ModalPositions.Center,
  [ModalTypesEnum.BrokerListings]: ModalPositions.Center,
  [ModalTypesEnum.QuickStartGuideModal]: ModalPositions.Center,
  [ModalTypesEnum.CropListingImageModal]: ModalPositions.Center,
  [ModalTypesEnum.CreateTeam]: ModalPositions.Center,
  [ModalTypesEnum.InviteUserToTeam]: ModalPositions.Center,
  [ModalTypesEnum.CreateListingReport]: ModalPositions.Center,
  [ModalTypesEnum.DisplayTextBlock]: ModalPositions.Center,
  [ModalTypesEnum.BillingPaywall]: ModalPositions.Center,
  [ModalTypesEnum.LoginUpgradeAccountReminder]: ModalPositions.Center,
  [ModalTypesEnum.AddListingsToTeam]: ModalPositions.Center,
  [ModalTypesEnum.CompareDataExplorerProperties]: ModalPositions.Center,
  [ModalTypesEnum.SubscriptionConfirmed]: ModalPositions.Center,
};

/** ******************************************************************************
 *  Modal
 ****************************************************************************** */

 type ContainerProps = {}

const Container = styled(FadeIn)<ContainerProps>`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: ${Colors.Black50};
  z-index: ${ZIndexes.Modal};
  overflow-y: scroll; 
`;

type ModalProps = {};

const Modal: React.FC<ModalProps> = () => {
  /* State */
  const appState = useSelector((state: AppState) => state.app);
  const { modals } = appState;
  const open = modals.length > 0;

  useLockBodyScroll(open);

  /** Render */
  if (!open) return null;

  const isTop = positions[[...modals].pop().type as ModalTypesEnum] === ModalPositions.Top;

  return (
    <Container duration={0.10}>
      {modals.map((modal, index) => (
        <RenderModal key={index} modal={modal} active={index === modals.length - 1} isTop={isTop} />
      ))}
    </Container>
  );
};

export default Modal;

/** ******************************************************************************
 *  Render Modal
 ****************************************************************************** */

type RenderModalContainerProps = {
  active: boolean;
  isTop: boolean;
};

const RenderModalContainer = styled.div<RenderModalContainerProps>`
  position: ${(props) => (props.active ? null : 'absolute')};
  top: ${(props) => (props.active ? null : '-10000000px')}; 
  left: ${(props) => (props.active ? null : '-10000000px')};
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.isTop ? 'flex-start' : 'center')};
  padding: ${(props) => (props.isTop ? '100px 0' : '0')};
  height: 100%;
  width: 100%;

  ${media.mobile} {
    padding: 0px;
    align-items: center;
    height: 100%;
  }

  ${media.tablet} {
    padding: 0px;
    align-items: center;
    height: 100%;
  }
`;

type RenderModalProps = {
  modal: IModalConfig;
  active: boolean;
  isTop: boolean;
};

const RenderModal: React.FC<RenderModalProps> = ({ modal, active, isTop }) => (
  <RenderModalContainer active={active} isTop={isTop}>
    {(() => {
      const props = modal.props as any;

      switch (modal.type) {
        case ModalTypesEnum.Register:
          return <RegisterModal {...props} />;
        case ModalTypesEnum.CompleteRegister:
          return <CompleteRegisterModal {...props} />;
        case ModalTypesEnum.Login:
          return <LoginModal {...props} />;
        case ModalTypesEnum.VerifyPhoneNumber:
          return <VerifyPhoneNumberModal {...props} />;
        case ModalTypesEnum.ForgotPasswordEmail:
          return <ForgotPasswordEmailModal {...props} />;
        case ModalTypesEnum.ResetPassword:
          return <ResetPasswordModal {...props} />;
        case ModalTypesEnum.PhoneVerified:
          return <PhoneVerifiedModal {...props} />;
        case ModalTypesEnum.ChangeProfilePicture:
          return <ChangeProfilePictureModal {...props} />;
        case ModalTypesEnum.ChangePhoneNumber:
          return <ChangePhoneNumberModal {...props} />;
        case ModalTypesEnum.ChangePhoneNumberCode:
          return <ChangePhoneNumberCodeModal {...props} />;
        case ModalTypesEnum.ChangePassword:
          return <ChangePasswordModal {...props} />;
        case ModalTypesEnum.ChangeEmailAddress:
          return <ChangeEmailAddressModal {...props} />;
        case ModalTypesEnum.ChangeListingStatus:
          return <ChangeListingStatusModal {...props} />;
        case ModalTypesEnum.AddLicense:
          return <AddLicenseModal {...props} />;
        case ModalTypesEnum.EditHighlight:
          return <EditHighlightModal {...props} />;
        case ModalTypesEnum.UnlockDocuments:
          return <UnlockDocumentsModal {...props} />;
        case ModalTypesEnum.ConfirmChange:
          return <ConfirmChangeModal {...props} />;
        case ModalTypesEnum.ShareListingModal:
          return <ShareListingModal {...props} />;
        case ModalTypesEnum.SelectContacts:
          return <SelectContactsModal {...props} />;
        case ModalTypesEnum.AuthenticateWithNylas:
          return <AuthenticateWithNylasModal {...props} />;
        case ModalTypesEnum.EmailPreview:
          return <EmailPreviewModal {...props} />;
        case ModalTypesEnum.PublishMessageModal:
          return <PublishMessageModal {...props} />;
        case ModalTypesEnum.SaveChanges:
          return <SaveChangesModal {...props} />;
        case ModalTypesEnum.ManualAddressCoordinates:
          return <ManualAddressCoordinatesModal {...props} />;
        case ModalTypesEnum.ContactBroker:
          return <ContactBrokerModal {...props} />;
        case ModalTypesEnum.Chat:
          return <ChatModal {...props} />;
        case ModalTypesEnum.Lead:
          return <LeadModal {...props} />;
        case ModalTypesEnum.Offer:
          return <OfferModal {...props} />;
        case ModalTypesEnum.RejectOffer:
          return <RejectOfferModal {...props} />;
        case ModalTypesEnum.BuyerLead:
          return <BuyerLeadModal {...props} />;
        case ModalTypesEnum.CreateListingActions:
          return <CreateListingActionsModal {...props} />;
        case ModalTypesEnum.ProfilePreview:
          return <ProfilePreviewModal {...props} />;
        case ModalTypesEnum.AcceptPrivateEventInvitation:
          return <AcceptPrivateEventInvitationModal {...props} />;
        case ModalTypesEnum.ModifyBiddingRoom:
          return <ModifyBiddingRoomModal {...props} />;
        case ModalTypesEnum.SaveSearch:
          return <SaveSearchModal {...props} />;
        case ModalTypesEnum.PropertyOwnerContactInfo:
          return <PropertyOwnerContactInfoModal {...props} />;
        case ModalTypesEnum.AddOrEditListingContact:
          return <AddOrEditListingContactModal {...props} />;
        case ModalTypesEnum.ListingFilters:
          return <ListingFiltersModal {...props} />;
        case ModalTypesEnum.MessageAllBidders:
          return <MessageAllBiddersModal {...props} />;
        case ModalTypesEnum.UploadListing:
          return <UploadListingModal {...props} />;
        case ModalTypesEnum.AssignAdmin:
          return <AssignAdminModal {...props} />;
        case ModalTypesEnum.UploadDocumentsAdmin:
          return <UploadDocumentsAdminModal {...props} />;
        case ModalTypesEnum.PrivateListingPermissionRequest:
          return <PrivateListingPermissionRequestModal {...props} />;
        case ModalTypesEnum.SecondaryPhoneNumberActions:
          return <SecondaryPhoneNumberActionsModal {...props} />;
        case ModalTypesEnum.GeneralErrorModal:
          return <GeneralErrorModal {...props} />;
        case ModalTypesEnum.RequestPrivateListingAccessModal:
          return <RequestPrivateListingAccessModal {...props} />;
        case ModalTypesEnum.WelcomeToBiproxiModal:
          return <WelcomeToBiproxiModal {...props} />;
        case ModalTypesEnum.DeleteFromWebflowModal:
          return <DeleteFromWebflowModal {...props} />;
        case ModalTypesEnum.PushToWebflowModal:
          return <PushToWebflowModal {...props} />;
        case ModalTypesEnum.BrokerListings:
          return <BrokerListingsModal {...props} />;
        case ModalTypesEnum.QuickStartGuideModal:
          return <QuickStartGuideModal {...props} />;
        case ModalTypesEnum.CropListingImageModal:
          return <CropListingImageModal {...props} />;
        case ModalTypesEnum.CreateTeam:
          return <CreateTeamModal {...props} />;
        case ModalTypesEnum.InviteUserToTeam:
          return <InviteUserToTeamModal {...props} />;
        case ModalTypesEnum.CreateListingReport:
          return <CreateListingReportModal {...props} />;
        case ModalTypesEnum.DisplayTextBlock:
          return <DisplayTextBlockModal {...props} />;
        case ModalTypesEnum.BillingPaywall:
          return <BillingPaywallModal {...props} />;
        case ModalTypesEnum.LoginUpgradeAccountReminder:
          return <LoginUpgradeAccountReminderModal {...props} />;
        case ModalTypesEnum.AddListingsToTeam:
          return <AddListingsToTeamModal {...props} />;
        case ModalTypesEnum.CompareDataExplorerProperties:
          return <CompareDataExplorerPropertiesModal {...props} />;
        case ModalTypesEnum.SubscriptionConfirmed:
          return <SubscriptionConfirmedModal {...props} />;
        default:
          return <div />;
      }
    })()}
  </RenderModalContainer>
);
