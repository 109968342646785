import { logger } from '@biproxi/logger';
import { AsyncParser } from 'json2csv';
import csvPlus from 'csvtojson';
import * as csv from 'csv';

function fromJson(fields: string[], data: object): Promise<string> {
  return new Promise((resolve, reject) => {
    const json = JSON.stringify(data);
    const opts = { fields };
    const transformOpts = {};
    const asyncParser = new AsyncParser(opts, transformOpts);

    let csv = '';
    asyncParser.processor
      .on('data', (chunk) => {
        csv += chunk.toString();
      })
      .on('end', () => resolve(csv))
      .on('error', (err) => reject(err));

    asyncParser.input.push(json);
    asyncParser.input.push(null);
  });
}

async function toJson(csvFilePath: string): Promise<any[]> {
  return csvPlus().fromFile(csvFilePath);
}

/** Parse a marketing contact list CSV uploaded by a broker. Returns an object with each column name
 * as key and an array of column values as the value so that we can have the broker select which column
 * values to use (dummy proofing emails) for the marketing emails.
 */

async function parseContactList(csvFile: any, setContactsData: (contactsData: any) => void): Promise<any> {
  let contacts;
  const reader = new FileReader() as any;
  reader.onabort = () => logger.info('file reading was aborted');
  reader.onerror = () => logger.info('file reading failed');
  reader.onload = () => {
    csv.parse(reader.result, {
      columns: true,
      relaxColumnCount: true,
      skip_records_with_empty_values: true,
      groupColumnsByName: true,
    }, (err, data) => {
      if (err) {
        logger.info('Unable to parse CSV file');
        return;
      }
      /** Parse the data with each column name as key and an array of row
       * fields as the value. We do this so that we can populate the
       * dropdown that lets the user choose which column the emails reside
       * on for easy email blasting later.
       */
      const finalData = data.reduce((cur, next) => {
        Object.keys(next).forEach((key) => {
          if (key) {
            if (!cur[key]) cur[key] = [];
            if (next[key]) cur[key].push(next[key]);
          }
        });
        return cur;
      }, {} as any);

      setContactsData(finalData);
    });
  };
  try {
    reader.readAsBinaryString(csvFile);
    return contacts;
  } catch (err) {
    logger.info('readAsBinaryString error', err);
    return contacts;
  }
}

const CSVUtil = {
  fromJson,
  toJson,
  parseContactList,
};

export default CSVUtil;
