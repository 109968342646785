import React from 'react';
import styled from '@emotion/styled';
import Loader, { LoaderSizes } from '../elements/Loader';
import Colors from '../styles/Colors';

export const LoadMoreWhenInView = styled.div`
  height: 0px;
  width: 0px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 120px;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

// https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver
export const useInViewOptions = {
  root: null,
  rootMargin: '20px',
  threshold: 1.0,
};

const InfiniteLoader: React.FC = () => (
  <Container>
    <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
  </Container>
);

export default InfiniteLoader;
