import { gql } from '@apollo/client';
import PrivateEventFragments from '../fragments/privateEvent.fragments';

const GET_PRIVATE_EVENT = gql`
  query PrivateEvent($params: GetPrivateEventParams!) {
    privateEvent(params: $params) {
      ...PrivateEventFields
      ...PrivateEventPSAFiles
      ...PrivateEventParticipants
      ...PrivateEventBids
      ...PrivateEventSettings
      ...PrivateEventContingencies
      ...PrivateEventMeta
      ...PrivateEventListing 
    }
  }

  ${PrivateEventFragments.fields}
  ${PrivateEventFragments.psaFiles}
  ${PrivateEventFragments.participants}
  ${PrivateEventFragments.bids}
  ${PrivateEventFragments.settings}
  ${PrivateEventFragments.contingencies}
  ${PrivateEventFragments.meta}
  ${PrivateEventFragments.listing}
`;

export default GET_PRIVATE_EVENT;
