enum ListingLoaderTypesEnum {
  CreateListing = 'CreateListing',
  UpdateListing = 'UpdateListing',
  UpdateDraft = 'UpdateDraft',
  PublishListing = 'PublishListing',
  SetListingState = 'SetListingState',
  DeleteListing = 'DeleteListing',
}

export default ListingLoaderTypesEnum;
