import React from 'react';
import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import IFileParams, { GraphQLFileUpload } from '@biproxi/models/interfaces/IFileParams';
import IFile from '@biproxi/models/interfaces/IFile';
import Image from 'next/image';
import { useAppDispatch } from '../../redux/store';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import CREATE_FILES from '../../graphql/mutations/createFiles.mutation';
import Icon, { Icons } from '../../elements/Icon';
import Loader, { LoaderSizes } from '../../elements/Loader';
import { media } from '../../utils/MediaQuery';
import { AppActions } from '../../redux/app.redux';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import Flex from '../../elements/Flex';

//! IMPROVEMENT: Roll this and CreateListingPhotoUpload into one component as they are very similar

const DropzoneContainer = styled.div<{ disabled?: boolean }>`
  height: 144px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: 2px dashed ${Colors.Grey300};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.mobile} {
    width: 100%;
    height: 190px;
  }
`;

const ImageWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;

type CreateListingContactPhotoUploadProps = {
  setProfileImageUrl: (url: string) => void;
  existingImageUrl?: string;
  disabled?: boolean;
};

const CreateListingContactPhotoUpload: React.FC<CreateListingContactPhotoUploadProps> = ({ setProfileImageUrl, existingImageUrl, disabled }) => {
  /** State */
  const [uploadedImage, setUploadedImage] = React.useState<IFile>(null);

  /* Actions */
  const dispatch = useAppDispatch();

  const pushToast = (config: IToastConfig) => dispatch(AppActions.pushToast(config));

  /** GraphQL */
  const [uploadFiles, { loading }] = useMutation(CREATE_FILES, {
    onCompleted: async (data) => {
      const files = data.createFiles;
      setUploadedImage(files[0]);
      setProfileImageUrl(files[0].url);
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error uploading your photo. Please try again or contact support.',
      });
    },
  });

  /* Hooks */
  const onDrop: any = (browserFiles: GraphQLFileUpload[]) => {
    if (loading) return;
    const fileParams: IFileParams[] = browserFiles.map((browserFile: GraphQLFileUpload) => ({
      name: browserFile.name,
      description: browserFile.name,
      mimetype: browserFile.type,
      encoding: 'utf8',
      upload: browserFile,
    }));
    uploadFiles({
      variables: {
        params: fileParams,
      },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  });

  /** Disable dropzone by not passing it required props if 'disabled' prop is true */
  const dropzoneFinalProps = disabled ? null : getRootProps();

  /* Render */
  return (
    <>
      <Flex>
        <Text type={TextTypesEnum.Medium12}>
          Picture &nbsp;
        </Text>
        <Text margin="0px 0px 4px 0px" type={TextTypesEnum.Regular12}>
          (Optional)
        </Text>
      </Flex>
      <DropzoneContainer disabled={disabled} {...dropzoneFinalProps}>
        <input {...getInputProps()} />
        {loading ? (
          <>
            <Loader
              size={LoaderSizes.Medium}
              color={Colors.Brand700 || Colors.Blurple700}
            />
            <Text
              type={TextTypesEnum.Regular14}
              color={Colors.Black}
              margin="8px 0 0"
            >
              Uploading...
            </Text>
          </>
        ) : (
          <>
            {existingImageUrl || uploadedImage ? (
              <ImageWrapper id="ImageWrapper">
                <Image
                  src={existingImageUrl || uploadedImage?.url}
                  fill
                  alt="contact-photo"
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </ImageWrapper>
            ) : (
              <>
                <Icon
                  icon={Icons.ImageLight}
                  color={Colors.Grey700}
                  size={36}
                  margin="0 0 8px 0"
                />
                <Text
                  type={TextTypesEnum.Medium12}
                  color={Colors.Grey700}
                >
                  Drag photo(s) here or
                </Text>
                <Text
                  type={TextTypesEnum.Medium12}
                  color={Colors.Brand700 || Colors.Blurple700}
                >
                  click to upload
                </Text>
              </>
            )}
          </>
        )}
      </DropzoneContainer>
    </>
  );
};

export default CreateListingContactPhotoUpload;
