import { logger } from '@biproxi/logger';
import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import { AppActions } from '../redux/app.redux';
import { useAppDispatch, useAppSelector } from '../redux/store';
import Auth from '../utils/Auth';
import { UserActions, UserSelectors } from '../redux/user.redux';
import * as UrlUtil from '../utils/UrlUtil';

const useCompleteAuthentication = (
  isStandalone: boolean,
  navigationRoute: string = '/app/dashboard/search',
) => {
  /** Hooks */
  const navigate = useNavigate();

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );
  const setAuthRedirectLoading = (loading: boolean) => dispatch(
    UserActions.setAuthRedirectLoading({ loading }),
  );
  const cacheUser = (user: IUserGraphQL) => dispatch(UserActions.cacheUser({ user }));
  const cachedUser = useAppSelector(UserSelectors.user);

  // I would like to move all of the functionality from here into this current file/hook
  const completeAuthentication = () => dispatch(
    UserActions.completeAuthentication(),
  );

  /** Effects */
  React.useEffect(() => {
    // Ensure user is cached before navigating into the dashboard
    // so the useUserHook can just read from the redux cache instead of fetching again.
    if (cachedUser?._id) {
      setAuthRedirectLoading(true);
      if (isStandalone) {
        const { query } = UrlUtil.parse(window.location.toString());
        const { to, ...rest } = query;
        if (to) {
          navigate({
            pathname: to as string,
            search: createSearchParams(rest).toString(),
          });
        } else {
          navigate(navigationRoute);
        }
        popModal();
      } else {
        popModal();
      }
    }
  }, [cachedUser]);

  return (authToken: string, hubspotToken: string, user: IUserGraphQL) => {
    if (authToken && user) {
      setAuthRedirectLoading(true);

      // delete this when functionality has been moved over to here
      // the saga is the old way but I didn't realize how much of it there was
      completeAuthentication();

      Auth.setToken(authToken, hubspotToken);
      cacheUser(user);
    } else {
      // should throw some kind of error or something
      logger.error('Error Authenticating');
    }
  };
};

export default useCompleteAuthentication;
