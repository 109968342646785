import React from 'react';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import FileAccessLevelsEnum from '@biproxi/models/enums/FileAccessLevelsEnum';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import Dropdown, { TDropdownItems } from '../../elements/Dropdown';
import InlineLeadTableAlert, { InlineLeadTableAlertProps } from '../InlineLeadTableAlert';
import SET_LEAD_FILE_ACCESS_LEVEL from '../../graphql/mutations/setLeadFileAccessLevel.mutation';
import { useAppDispatch } from '../../redux/store';
import { LeadActions } from '../../redux/lead.redux';
import REJECT_LEAD_FILE_ACCESS_LEVEL from '../../graphql/mutations/rejectLeadFileAccessLevel.mutation';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { AppActions } from '../../redux/app.redux';

const Container = styled.div`
  position: relative;
  width: 100%;
`;

function enumToDropdownItems<ValueType>(enumToConvert: ValueType): TDropdownItems<ValueType> {
  return Object.values(enumToConvert).map((value: ValueType) => ({
    name: value.toString(),
    value,
  }));
}

type LeadDilligenceAccessProps = {
  lead: ILeadGraphQL,
};

const LeadDilligenceAccess: React.FC<LeadDilligenceAccessProps> = ({
  lead,
}) => {
  /* Actions */
  const dispatch = useAppDispatch();

  const cacheLead = (lead: ILeadGraphQL) => dispatch(LeadActions.recacheLead({ leadId: lead?._id }));

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /* State */
  const [accessLevel, setAccessLevel] = React.useState(lead.fileAccessLevel?.accessLevel);

  /* GraphQL */
  const [setLeadFileAccessLevel] = useMutation(
    SET_LEAD_FILE_ACCESS_LEVEL,
    {
      onCompleted: async ({ setLeadFileAccessLevel }) => {
        pushToast({
          type: ToastTypesEnum.Notification,
          message: 'Level 2 access approved.',
        });
        cacheLead(setLeadFileAccessLevel);
      },
    },
  );

  const [rejectLeadFileAccessLevelChange] = useMutation(REJECT_LEAD_FILE_ACCESS_LEVEL, {
    onCompleted: ({ rejectLeadFileAccessLevelChange }) => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Level 2 access rejected.',
      });
      cacheLead(rejectLeadFileAccessLevelChange);
    },
  });

  const inlineAlertConfig: InlineLeadTableAlertProps = {
    alertHeading: 'Level 2 Diligence Access',
    alertText: `Give ${lead?.user?.firstName} access to the Level 2 Diligence Vault`,
    onAccept: (masterFunction) => {
      masterFunction?.();
      setLeadFileAccessLevel({
        variables: {
          params: {
            leadId: lead._id,
            accessLevel: FileAccessLevelsEnum.Level2,
          },
        },
      });
    },
    onDecline: () => rejectLeadFileAccessLevelChange({
      variables: {
        params: {
          leadId: lead._id,
        },
      },
    }),
    isTableAlert: false,
    lead,
  };

  /* Effects */
  React.useEffect(() => {
    setAccessLevel(lead.fileAccessLevel?.accessLevel);
  }, [lead]);

  /* Render */
  return (
    <Container>
      <InlineLeadTableAlert
        config={inlineAlertConfig}
      />
      <Dropdown
        placeholder={accessLevel}
        value={accessLevel}
        onChange={(value: FileAccessLevelsEnum) => {
          setLeadFileAccessLevel({
            variables: {
              params: {
                leadId: lead._id,
                accessLevel: value,
              },
            },
          });
          setAccessLevel(value);
        }}
        items={enumToDropdownItems<typeof FileAccessLevelsEnum>(FileAccessLevelsEnum)}
      />
    </Container>
  );
};

export default LeadDilligenceAccess;
