import gql from 'graphql-tag';

const REJECT_OFFER = gql`
  mutation Rejectoffer($params: RejectOfferParams!) {
    rejectOffer(params: $params) {
      _id
    }
  }
`;

export default REJECT_OFFER;
