enum AuthenticationStrategyEnum {
  BasicRegister = 'BasicRegister',
  BasicLogin = 'BasicLogin',
  LinkedInRegister = 'LinkedInRegister',
  LinkedInLogin = 'LinkedInLogin',
  GoogleRegister = 'GoogleRegister',
  GoogleLogin = 'GoogleLogin',
}

export default AuthenticationStrategyEnum;
