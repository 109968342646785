import styled from '@emotion/styled';
import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { IListingVaultFolderGraphQL } from '@biproxi/models/interfaces/IListingVaultFolder';
import FileAccessLevelsEnum from '@biproxi/models/enums/FileAccessLevelsEnum';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import IFile from '@biproxi/models/interfaces/IFile';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Icon, { Icons } from '../../elements/Icon';
import { useAppDispatch } from '../../redux/store';
import { ListingActions } from '../../redux/listing.redux';
import Flex from '../../elements/Flex';
import ColoredTag, { ColoredTagTypesEnum } from '../../elements/ColoredTag';

const Container = styled.div``;

type FileProps = {
  isActive: boolean;
}

const Title = styled.div<FileProps>`
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 4px;
  padding-left: 8px;
  background-color: ${Colors.White};
  transition: all 0.1s;
  border-radius: 4px;

  &:hover {
    cursor: ${(props) => (props.isActive ? 'pointer' : null)};
    background-color: ${(props) => (props.isActive ? Colors.Grey200 : null)};
  }

  &:active {
    background-color: ${(props) => (props.isActive ? Colors.Grey300 : null)};
  }
`;

const Locked = styled.div`
  background-color: ${Colors.Grey100};
  position: relative;
  height: 24px;
  border-radius: 8px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  margin: 0 0 0 8px;
`;

const Files = styled.div<{ isEmpty?: boolean }>`
  margin-left: 8px;
  border-left: ${({ isEmpty }) => (isEmpty ? null : `1px solid ${Colors.Grey400}`)};
  padding-top: 4px;
  padding-left: 8px;
`;

const File = styled.div<{locked?: boolean}>`
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 4px;
  background-color: ${Colors.White};
  transition: all 0.1s;
  border-radius: 4px;

  &:hover{
    cursor: ${(props) => (props.locked ? null : 'pointer')};
    background-color: ${(props) => (props.locked ? null : Colors.Grey200)};
  }
`;

type PDPVaultFolderProps = {
  id?: string;
  listing: IListingGraphQL;
  folder: IListingVaultFolderGraphQL;
  isActive: boolean;
  hasLevel2Access: boolean;
  filesViewedIds?: string[];
  filesDownloadedIds?: string[];
};

const PDPVaultFolder: React.FC<PDPVaultFolderProps> = ({
  id,
  listing,
  folder,
  isActive,
  hasLevel2Access,
  filesViewedIds,
  filesDownloadedIds,
}) => {
  /* State */
  const color = isActive ? Colors.Grey900 : Colors.Grey400;
  const fileCount = ListingUtil.rootFolderFileCount(folder);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSubFolderOpen, setIsSubFolderOpen] = React.useState({});

  /** Actions */
  const dispatch = useAppDispatch();

  const previewListingVault = (fileId: string) => dispatch(
    ListingActions.previewListingVault({
      listingId: listing._id,
      fileId,
    }),
  );

  const openOrCloseSubFolder = (foldername: string) => {
    setIsSubFolderOpen({
      ...isSubFolderOpen,
      [foldername]: !isSubFolderOpen[foldername],
    });
  };

  /** Effects */
  React.useEffect(() => {
    const subFolderNames = folder.subFolders?.map((subFolder) => subFolder.name);
    const subFolderOpen = subFolderNames?.reduce((cur, subFolderName) => {
      cur[subFolderName] = false;
      return cur;
    }, {});
    setIsSubFolderOpen(subFolderOpen);
  }, [folder]);

  /* Render */
  if (!fileCount) {
    return null;
  }

  function LockedFile({ file }: { file: IFile }) {
    return (
      <File locked>
        <Icon
          icon={Icons.FilePdfLight}
          size={16}
          color={Colors.Grey400}
          margin="0 8px 0 0"
        />
        <Text type={TextTypesEnum.Regular16} color={Colors.Grey400}>
          {file.name}
        </Text>
        <Locked>
          <Icon icon={Icons.LockAltSolid} color={Colors.Grey800} margin="0 8px 0 0" size={10} />
          <Text type={TextTypesEnum.Medium14} color={Colors.Grey800}>Level 2</Text>
        </Locked>
      </File>
    );
  }

  function RegularFile({ file }: { file: IFile }) {
    return (
      <File onClick={() => previewListingVault(file._id)}>
        <Icon
          icon={Icons.FileCertificateLight}
          size={16}
          color={Colors.Black}
          margin="0 8px 0 0"
        />
        <Text type={TextTypesEnum.Regular16} color={color}>
          {file.name}
        </Text>
        {filesViewedIds?.includes(file._id) && (
        <ColoredTag
          type={ColoredTagTypesEnum.Green}
          text="Viewed"
          margin="0 0 0 8px"
        />
        )}
        {filesDownloadedIds?.includes(file._id) && (
        <ColoredTag
          type={ColoredTagTypesEnum.Blurple}
          text="Downloaded"
          margin="0 0 0 8px"
        />
        )}
      </File>
    );
  }

  return (
    <Container id={id}>
      <Title onClick={isActive ? () => setIsOpen(!isOpen) : null} isActive={isActive}>
        <Icon
          icon={isOpen ? Icons.FolderOpenSolid : Icons.FolderSolid}
          size={16}
          width="16px"
          color={color}
          margin="0 8px 0 0"
        />
        <Text type={TextTypesEnum.Regular16} color={color}>
          {`${folder.name} (${fileCount} file${fileCount === 1 ? '' : 's'})`}
        </Text>
      </Title>
      {isOpen && (
        <>
          <Flex margin="0px 0px 0px 12px" direction="column">
            <>
              {folder.subFolders.map((subFolder, index) => (
                <React.Fragment key={index}>
                  <Title onClick={isActive ? () => openOrCloseSubFolder(subFolder.name) : null} isActive={isActive}>
                    <Icon
                      icon={isSubFolderOpen[subFolder.name] ? Icons.FolderOpenSolid : Icons.FolderSolid}
                      size={16}
                      width="16px"
                      color={color}
                      margin="0 8px 0 0"
                    />
                    <Text type={TextTypesEnum.Regular16} color={color}>
                      {`${subFolder.name} (${subFolder.fileIds.length} file${subFolder.fileIds.length === 1 ? '' : 's'})`}
                    </Text>
                  </Title>
                  {isSubFolderOpen[subFolder.name] && (
                    <Files isEmpty={Boolean(!(subFolder.fileIds.length > 0))}>
                      {subFolder.files.map((file) => (file.accessLevel === FileAccessLevelsEnum.Level2 && !hasLevel2Access ? (
                        <LockedFile key={file._id} file={file} />
                      ) : (
                        <RegularFile key={file._id} file={file} />
                      )))}
                    </Files>
                  )}
                </React.Fragment>
              ))}
            </>
          </Flex>
          <Files isEmpty={Boolean(!(folder.files.length > 0))}>
            {folder.files.map((file) => (file.accessLevel === FileAccessLevelsEnum.Level2 && !hasLevel2Access ? (
              <LockedFile key={file._id} file={file} />
            ) : (
              <RegularFile key={file._id} file={file} />
            )))}
          </Files>
        </>
      )}
    </Container>
  );
};

export default PDPVaultFolder;
