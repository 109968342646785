import gql from 'graphql-tag';

const READ_NOTIFICATIONS = gql`
  mutation ReadNotifications($params: ReadNotificationsParams!) {
    readNotifications(params: $params) {
      _id
      state
    }
  }
`;

export default READ_NOTIFICATIONS;
