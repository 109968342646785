import React from 'react';
import styled from '@emotion/styled';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import { IPrivateEventBid } from '@biproxi/models/interfaces/IPrivateEvent';
import StringUtil from '@biproxi/models/utils/StringUtil';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Flex from '../../elements/Flex';

const Container = styled.div`
  width: 100%;
`;

const Circle = styled.div`
  position: relative;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: ${Colors.Grey200};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const SmallCircle = styled.div`
  position: relative;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: ${Colors.Grey900};
`;

const Line = styled.div`
  margin: 8px 0 8px 16px;
  width: 1px;
  background-color: ${Colors.Grey300};
  height: 16px;
`;

type BidOverviewLeadModalStatItemProps = {
  bid: IPrivateEventBid;
  isLast: boolean;
};

const BidOverviewLeadModalStatItem: React.FC<BidOverviewLeadModalStatItemProps> = ({
  bid,
  isLast,
}) => (
  <Container>
    <Flex align="center">
      <Circle>
        <SmallCircle />
      </Circle>
      <Flex direction="column">
        <Text type={TextTypesEnum.Medium14Small} color={Colors.Black}>
          $
          {StringUtil.formatNumber(bid.totalBidAmount)}
          &nbsp;
          (Total bid)
        </Text>
        <Text type={TextTypesEnum.Regular12} color={Colors.Grey700}>
          $
          {StringUtil.formatNumber(bid.bidIncrement)}
          &nbsp;
          (Bid increment)
        </Text>
        <Text type={TextTypesEnum.Regular12} color={Colors.Grey700}>
          {TimeUtil.format(bid.meta.createdAt, 'ff, ZZZZ', TimeUtil.currentBrowserTimezone())}
        </Text>
      </Flex>
    </Flex>
    {!isLast && (
      <Line />
    )}
  </Container>
);
export default BidOverviewLeadModalStatItem;
