import gql from 'graphql-tag';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import { DocumentNode } from 'graphql';
import UserFragments from './user.fragments';

// Not a perfect type, but it's good enough for now
type TListingFragments = {
  [K in keyof IListingGraphQL]?: DocumentNode;
} & {
  fields: DocumentNode;
}

const ListingFragments: TListingFragments = {
  fields: gql`
    fragment ListingFields on Listing {
      _id
      organizationId
      name
      description
      assetClass
      propertyType
      score
      state
      highlights
      info
      listedByOwner
      isPrivateListing
      isPortfolioListing
    }
  `,
  address: gql`
    fragment ListingAddress on Listing {
      address {
        address1
        address2
        city
        state
        zip
        country
        location {
          type
          coordinates
        }
        googlePlaceId
        timeZoneId
        cherreId
      }
    }
  `,
  schedule: gql`
    fragment ListingSchedule on Listing {
      schedule {
        expiresAt
        publishAt
      }
    }
  `,
  media: gql`
    fragment ListingMedia on Listing {
      media {
        fileIds
        files {
          _id
          name
          mimetype
          url
        }
        video {
          fileId
          file {
            _id
            name
            mimetype
            url
          }
       }
      }
    }
  `,
  guidance: gql`
    fragment ListingGuidance on Listing {
      guidance {
        priced
        askingPrice
        depositAmount
        brokerCommision
        brokerCommisionAmount
        dueDiligencePeriod
        closingPeriod
        callForOffersAt
      }
    }
  `,
  vault: gql`
    fragment ListingVault on Listing {
      vault {
        zipFileId
        zipFileLevel2Id
        caFileId
        zipFile {
          _id
          name
          mimetype
          url
        }
        caFile {
          _id
          name
          mimetype
          url
        }
        zipFileLevel2 {
          _id
          name
          mimetype
          url
        }
        folders {
          name
          fileIds
          files {
            _id
            name
            mimetype
            url
            accessLevel
          }
          subFolders {
            name
            fileIds
            subFolderId
            files {
              _id
              name
              mimetype
              url
              accessLevel
            }
          }
        }
      }
    }
  `,
  license: gql`
    fragment ListingLicense on Listing {
      license {
        _id
        owner
        state
        number
      }
    }
  `,
  metrics: gql`
    fragment ListingMetrics on Listing {
      metrics {
        signedCAUserIds
      }
    }
  `,
  meta: gql`
    fragment ListingMeta on Listing {
      meta {
        createdAt
        createdBy
      }
    }
  `,
  user: gql`
    fragment ListingUser on Listing {
      user {
        ...UserFields
        ...UserProfileImageFile
      }
    }

    ${UserFragments.fields}
    ${UserFragments.profileImageFile}
  `,
  contacts: gql`
    fragment ListingContacts on Listing {
      contacts {
        _id
        name
        title
        company
        email
        phoneNumber
        license
        profileImageUrl
      }
    }
  `,
  portfolioAddresses: gql`
    fragment ListingPortfolioAddresses on Listing {
      portfolioAddresses {
        address1
        address2
        city
        state
        zip
        country
        location {
          type
          coordinates
        }
        googlePlaceId
        timeZoneId
        cherreId
      }
    }
  `,
  dealProfile: gql`
    fragment ListingDealProfile on Listing {
      dealProfile {
        investmentType
        buyerLocation
        regionalPreference
      }
    }
  `,
};

export default ListingFragments;
