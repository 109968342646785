import { IResponse, IError } from '../interfaces/common';
import StatusCodeEnum from '../enums/StatusCodeEnum';

function isErrorResponse(response: IResponse): response is Required<IResponse> {
  return (response as Required<IResponse>).error !== undefined;
}

function isError(error: any): error is Required<IError> {
  return (error as Required<IError>).message !== undefined;
}

function isFieldError(error: IError): error is Required<IError> {
  return (error as Required<IError>).fields !== undefined;
}

const isSuccessfulResponse = (response: IResponse): boolean => response.status === StatusCodeEnum.OK;

const APIUtil = {
  isErrorResponse,
  isError,
  isFieldError,
  isSuccessfulResponse,
};

export default APIUtil;
