import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { usePDF } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { IOfferGraphQL } from '@biproxi/models/interfaces/IOffer';
import IOfferQuery from '@biproxi/models/interfaces/IOfferQuery';
import OfferQueryTypesEnum from '@biproxi/models/enums/OfferQueryTypesEnum';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import { IListLeadsParams } from '@biproxi/models/services/ILeadService';
import IListingOverviewAnalytics from '@biproxi/models/interfaces/IListingOverviewAnalytics';
import BiproxiPlatformPermissionsEnum from '@biproxi/models/enums/PermissionsEnum';
import PaywallFeaturesEnum from '@biproxi/models/enums/PaywallFeaturesEnum';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Colors from '../../styles/Colors';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import Text, { TextTypesEnum } from '../../elements/Text';
import ListingReport from '../pdf-document/ListingReport';
import LIST_OFFERS from '../../graphql/queries/offers.query';
import LIST_LEADS from '../../graphql/queries/leads.query';
import useListingHook from '../../hooks/useListing.hook';
import LISTING_OVERVIEW_ANALYTICS from '../../graphql/queries/listingOverviewAnalytics.query';
import Flex, { CustomSpacer } from '../../elements/Flex';
import { media } from '../../utils/MediaQuery';
import Checkbox from '../../elements/Checkbox';
import useUser from '../../hooks/useUser.hook';
import { ModalTypesEnum } from './Modal';
import useUserPermissions from '../../hooks/useUserPermissions.hook';

const ContentContainer = styled.div`
  width: 300px;

  ${media.mobile} {
    width: 100%;
  }
`;

export type CreateListingReportModalProps = {};

const CreateListingReportModal: React.FC<CreateListingReportModalProps> = () => {
  /** State */
  const [leads, setLeads] = React.useState(null);
  const [investorMatches, setInvestorMatches] = React.useState(null);

  /** Hooks */
  const { listing } = useListingHook();
  const { userId } = useUser();
  const [ready, setReady] = React.useState(false);
  const [config, setConfig] = React.useState({
    showOffers: true,
    showLeads: true,
    showInvestorMatches: false,
  });

  const { userBiproxiRolesAndPermissions } = useUserPermissions({ userId });

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );
  const pushBillingPaywallModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.BillingPaywall,
      props: {
        paywallFeature: PaywallFeaturesEnum.InvestorMatching,
      },
    }),
  );

  interface OfferData {
    offers: IOfferGraphQL[];
  }

  interface OfferVars {
    params: IOfferQuery;
  }

  const { data: offerData } = useQuery<OfferData, OfferVars>(LIST_OFFERS, {
    variables: {
      params: {
        queryType: OfferQueryTypesEnum.Received,
        listingId: listing?._id,
        isActive: true,
      },
    },
    onError: (error) => {
      logger.error('LIST_OFFERS', error);
    },
  });

    /** GraphQL */
    interface Data {
      leads: ILeadGraphQL[];
    }
    interface Vars {
      params: IListLeadsParams;
    }

    useQuery<Data, Vars>(LIST_LEADS, {
      variables: {
        params: {
          listingId: listing?._id,
        },
      },
      onError: (error) => {
        logger.error('LIST_LEADS error', error);
      },
      onCompleted: (data) => {
        const l = data?.leads?.filter((lead) => !lead.isInvestorMatch);
        const im = data?.leads?.filter((im) => im.isInvestorMatch);

        setLeads(l || []);
        setInvestorMatches(im || []);
      },
    });

    interface ListingAnalyticsData {
      listingOverviewAnalytics: IListingOverviewAnalytics;
    }
    interface ListingAnalyticsVars {
      params: {
        listingId: string[]
        listingUserId: string
        dateRange: string
      }
    }

    const { data: listingOverviewData } = useQuery<ListingAnalyticsData, ListingAnalyticsVars>(LISTING_OVERVIEW_ANALYTICS, {
      variables: {
        params: {
          listingId: [listing._id],
          listingUserId: listing?.meta?.createdBy,
          dateRange: 'from 360 days ago to now', // feels weird
        },
      },
      onError: (error) => {
        logger.error('LISTING_OVERVIEW_ANALYTICS error', error);
      },
    });

    const [pdfInstance, updatePdfInstance] = usePDF({
      document: <ListingReport
        listing={listing}
        offers={offerData?.offers}
        leads={leads}
        investorMatches={investorMatches}
        analytics={listingOverviewData?.listingOverviewAnalytics}
        config={config}
      />,
    });

    /** Effects */
    // update pdf after data has all loaded in
    React.useEffect(() => {
      if (listingOverviewData && offerData && leads && investorMatches) {
        updatePdfInstance();
        setReady(true);
      }
    }, [listingOverviewData, offerData, leads, investorMatches]);

    // update pdf on config change
    React.useEffect(() => {
      updatePdfInstance();
    }, [config]);

    const userSubscribedToBrokerPro = (userBiproxiRolesAndPermissions?.permissions?.includes(BiproxiPlatformPermissionsEnum.ViewInvestorMatches));

    /** Render */
    return (
      <ModalContainer>
        <ModalHeader title="Create report" close={popModal} />
        <ModalContent>
          <ContentContainer>
            <Text type={TextTypesEnum.Medium12} color={Colors.Grey900} margin="0 0 8px 0">
              What do you want to include in your report?
            </Text>
            <Checkbox
              onClick={() => {
                setConfig({ ...config, showOffers: !config?.showOffers });
              }}
              active={config.showOffers}
              label="Include offers"
              margin="16px 0 0"
              labelTextType={TextTypesEnum.Medium12}
            />
            <Checkbox
              onClick={() => {
                setConfig({ ...config, showLeads: !config?.showLeads });
              }}
              active={config.showLeads}
              label="Include leads table"
              margin="16px 0 0"
              labelTextType={TextTypesEnum.Medium12}
            />
            <Checkbox
              onClick={() => {
                if (!userSubscribedToBrokerPro) {
                  pushBillingPaywallModal();
                } else {
                  setConfig({ ...config, showInvestorMatches: !config?.showInvestorMatches });
                }
              }}
              active={config.showInvestorMatches}
              label="Include investor matches table"
              margin="16px 0 0"
              labelTextType={TextTypesEnum.Medium12}
            />
          </ContentContainer>
        </ModalContent>
        <ModalFooter>
          <Flex justify="flex-end" width="100%">
            <Button
              text="Cancel"
              onClick={popModal}
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Large}
            />
            <CustomSpacer width="8px" />
            <Button
              text="Generate Report"
              onClick={() => {
                if (pdfInstance?.url) {
                  window.open(pdfInstance?.url);
                } else {
                  pushToast({
                    type: ToastTypesEnum.Notification,
                    message: 'There was an issue generating the report. Please try again later or contact support',
                  });
                }
              }}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Large}
              disabled={!!pdfInstance.error}
              isLoading={!ready || pdfInstance.loading}
            />
          </Flex>
        </ModalFooter>
      </ModalContainer>
    );
};

export default CreateListingReportModal;
