import React from 'react';
import styled from '@emotion/styled';
import { usePDF } from '@react-pdf/renderer';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Colors from '../../styles/Colors';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import Text, { TextTypesEnum } from '../../elements/Text';
import CompareDataExplorerPropertiesReport from '../pdf-document/CompareDataExplorerPropertiesReport';
import Flex, { CustomSpacer } from '../../elements/Flex';
import { media } from '../../utils/MediaQuery';

const ContentContainer = styled.div`
  width: 300px;
  max-width: 300px;

  ${media.mobile} {
    width: 100%;
    max-width: unset;
  }
`;

export type CompareDataExplorerPropertiesModalProps = {
  selectedProperty: any;
  properties: any;
};

const CompareDataExplorerPropertiesModal: React.FC<CompareDataExplorerPropertiesModalProps> = ({
  selectedProperty,
  properties,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const [pdfInstance] = usePDF({
    document: <CompareDataExplorerPropertiesReport
      selectedProperty={selectedProperty}
      properties={properties}
    />,
  });

  /** Render */
  return (
    <ModalContainer>
      <ModalHeader title="Create report" close={popModal} />
      <ModalContent>
        <ContentContainer>
          <Text type={TextTypesEnum.Medium14} color={Colors.Grey900}>
            Please wait while we generate your report. Your report will consist of your main selected property and all selected compared properties.
          </Text>
        </ContentContainer>
      </ModalContent>
      <ModalFooter>
        <Flex justify="flex-end" width="100%">
          <Button
            text="Cancel"
            onClick={popModal}
            type={ButtonTypesEnum.Ghost}
            size={ButtonSizesEnum.Large}
          />
          <CustomSpacer width="8px" />
          <Button
            text="View PDF report"
            onClick={() => {
              if (pdfInstance?.url) {
                window.open(pdfInstance?.url);
              } else {
                pushToast({
                  type: ToastTypesEnum.Notification,
                  message: 'There was an issue generating the report. Please try again later or contact support',
                });
              }
            }}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Large}
            disabled={!!pdfInstance.error}
            isLoading={pdfInstance.loading}
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default CompareDataExplorerPropertiesModal;
