import React from 'react';
import styled from '@emotion/styled';
import ListingStateEnum from '@biproxi/models/enums/ListingStateEnum';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import { media } from '../../utils/MediaQuery';
import { ListingActions } from '../../redux/listing.redux';
import ListingStatus from '../ListingStatus';
import ImageWithAspectRatio from '../../elements/ImageWithAspectRatio';
import Dropdown from '../../elements/Dropdown';
import { TDropdownItemProps } from '../DropdownList';
import { ModalTypesEnum } from './Modal';

const Container = styled.div`
  width: 100%;

  ${media.mobile} {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 72px;
  margin: 0px 8px 0px 0px;
  border-radius: 8px;
`;

const ListingNameWrapper = styled.div`
  max-width: 200px;
  &, & > *, > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.1s;
  }
`;

const DropdownItem = styled.div<{ isHovered: boolean }>`
  display: flex;
  align-items: center;
  height: 28px;
  width: 100%;
  overflow: hidden;
  background-color: ${({ isHovered }) => (isHovered ? `${Colors.Grey100}` : null)};

  &:hover {
    background-color: ${Colors.Grey100};
    color: ${Colors.Black};
    cursor: pointer;
  }
`;

const ItemComponent = React.forwardRef<HTMLDivElement, TDropdownItemProps>(({
  item,
  onChange,
  isHovered,
}, ref) => (
  <DropdownItem ref={ref} onClick={() => onChange(item)} isHovered={isHovered}>
    <ListingStatus status={item.value} dropdownConfig margin="0px 8px 0px 8px" />
  </DropdownItem>
));

export type ChangeListingStatusModalProps = {
  listing: IListingGraphQL;
};

const ChangeListingStatusModal: React.FC<ChangeListingStatusModalProps> = ({
  listing,
}) => {
  /** TODO: Clean this up, remove the date stuff, it should no longer be needed.. */

  /** State */
  const [newStatus, setNewStatus] = React.useState<ListingStateEnum>(null);
  const [date] = React.useState<number>(null);
  const [scheduledExpiresAt] = React.useState<number>(null);
  const listingStatuses = ListingUtil.getListingStatuses(listing);
  const expiresAt = ListingUtil.setExpirationDate(newStatus, date, scheduledExpiresAt);
  const publishAt = ListingUtil.setPublishDate(newStatus, date);

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushCreateListingActionsModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.CreateListingActions,
      props: {
        listing,
      },
    }),
  );

  const setListingState = (state: ListingStateEnum) => dispatch(
    ListingActions.setListingState({
      listingState: {
        listingId: listing._id,
        state,
        expiresAt,
        publishAt,
      },
      toast: true,
    }),
  );

  const updateListingStatus = () => {
    setListingState(newStatus);
    popModal();
  };

  /** Effects */
  /** Require a date if we are switching from any status to Active or
   * Scheduled or if we simply want to update expiration date / publish date
   */

  const [, setRequireDate] = React.useState(false);

  React.useEffect(() => {
    if (newStatus === ListingStateEnum.Active || newStatus === ListingStateEnum.Scheduled) {
      setRequireDate(true);
    } else {
      setRequireDate(false);
    }
  }, [newStatus]);

  /* Render */
  return (
    <ModalContainer width="360px">
      <ModalHeader title="Change listing status" close={popModal} />
      <ModalContent>
        <Container>
          <Flex>
            <ImageContainer>
              <ImageWithAspectRatio
                src={listing?.media?.files[0]?.url}
                aspectRatio={3 / 2}
                skWidth={72}
              />
            </ImageContainer>
            <Flex direction="column" width="200px">
              <ListingStatus
                listing={listing}
              />
              <ListingNameWrapper>
                <Text
                  type={TextTypesEnum.Bold18}
                  color={Colors.Black}
                >
                  {ListingUtil.name(listing, { allButAddress1: true })}
                </Text>
              </ListingNameWrapper>
            </Flex>
          </Flex>
          <Dropdown
            label="Change status to"
            placeholder={listingStatuses[0].name}
            value={newStatus}
            onChange={(val) => setNewStatus(val)}
            items={listingStatuses}
            margin="24px 0px"
            ItemComponent={ItemComponent}
          />
        </Container>
      </ModalContent>
      <ModalFooter>
        <Flex justify="flex-end" width="100%">
          <Button
            text="Cancel"
            type={ButtonTypesEnum.Ghost}
            size={ButtonSizesEnum.Medium}
            onClick={() => popModal()}
            margin="0px 8px 0px 0px"
          />
          <Button
            text="Change status"
            onClick={() => {
              if (newStatus === ListingStateEnum.Active || newStatus === ListingStateEnum.Scheduled) {
                popModal();
                pushCreateListingActionsModal();
              } else {
                updateListingStatus();
              }
            }}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default ChangeListingStatusModal;
