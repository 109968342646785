import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import * as ListingJoi from '@biproxi/models/joi/listing.joi';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import UserUtil from '@biproxi/models/utils/UserUtil';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import * as IListingService from '@biproxi/models/services/IListingService';
import AnalyticsEventTypesEnum from '@biproxi/models/enums/AnalyticsEventTypesEnum';
import EventTrackerUtil from '@biproxi/models/utils/EventTrackerUtil';
import { CONFIDENTIALITY_URL } from '@biproxi/models/externalLinks';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Input, { InputTypesEnum } from '../../elements/Input';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { media } from '../../utils/MediaQuery';
import Icon, { Icons } from '../../elements/Icon';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Divider from '../../elements/Divider';
import LinkText from '../../elements/LinkText';
import SignaturePad from '../SignaturePad';
import useUser from '../../hooks/useUser.hook';
import SIGN_LISTING_CA from '../../graphql/mutations/signListingCA.mutation';
import { ListingActions } from '../../redux/listing.redux';
import { ModalTypesEnum } from './Modal';
import { ConfirmChangeModalTypesEnum } from './ConfirmChangeModal';
import Link from '../../elements/Link';
import GET_LEAD from '../../graphql/queries/lead.query';
import useIsPrivateListing from '../../hooks/useIsPrivateListing.hook';
import REQUEST_PRIVATE_LISTING_ACCESS from '../../graphql/mutations/requestPrivateListingAccess.mutation';
import { IToastConfig, ToastTypesEnum } from '../Toast';

const Container = styled.div`
  width: 384px;

  ${media.mobile} {
    width: 100%;
  }
`;

const Form = styled.form``;

const Folder = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 4px;
  padding-left: 8px;
  background-color: ${Colors.White};
  transition: all 0.1s;
  border-radius: 4px;
`;

export type UnlockDocumentsModalProps = {
  listing: IListingGraphQL;
};

const UnlockDocumentsModal: React.FC<UnlockDocumentsModalProps> = ({
  listing,
}) => {
  /** Hooks */
  const { user } = useUser();
  const formParams: UseFormParams = {
    fields: {
      userFullName: '',
    },
    fieldOrder: ['/userFullName'],
    schema: ListingJoi.signListingCAParamsSchema,
  };

  const {
    controllers: {
      userFullName,
    },
    setFieldErrors,
  } = useForm(formParams);

  /** State */
  const isValid = userFullName.value().toLowerCase() === UserUtil.fullName(user).toLowerCase();
  const customCAFile = listing.vault?.caFile;
  const { isPrivateListing, isPrivateListingAccess } = useIsPrivateListing({
    params: {
      user,
      listing,
    },
  });

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const cacheListing = (listing: IListingGraphQL) => dispatch(
    ListingActions.cacheListings({ listings: [listing] }),
  );

  const replaceWithSuccess = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Success,
        title: 'The due diligence documents are now unlocked',
        text: 'Click on any of the files or folders to browse or download your preferred documents.',
        confirm: (event: React.FormEvent) => {
          event?.preventDefault();
          popModal();
        },
      },
    }),
  );

  const replaceWithPrivateListingSuccess = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Success,
        title: 'Your request has been sent!',
        text: 'You have successfully signed the CA and your request to view the private listing has been sent to the broker. You may view the listing and documents once the broker has granted you permission.',
        confirm: (event: React.FormEvent) => {
          event?.preventDefault();
          popModal();
        },
      },
    }),
  );

  const previewCustomCA = () => dispatch(
    AppActions.previewFiles({
      fileId: customCAFile._id,
      files: [customCAFile],
      track: false,
    }),
  );

  /** GraphQL */

  interface RequestPrivateListingPermissionData {
    listing: IListingGraphQL;
  }

  interface RequestPrivateListingPermissionVars {
    params: {
      listingId: String;
    }
  }

  const [requestPrivateListingAccess] = useMutation<RequestPrivateListingPermissionData, RequestPrivateListingPermissionVars>(REQUEST_PRIVATE_LISTING_ACCESS, {
    onCompleted: () => {
      signListingCA();
    },
    onError: () => {
      pushToast({
        type: ToastTypesEnum.Error,
        message: 'There was an error with the permission request. Please try again.',
      });
    },
  });

  type Data = {
    signListingCA: IListingGraphQL;
  }
  type Params = IListingService.TSignListingCAPayload;

  const [signListingCA, { loading }] = useMutation<Data, Params>(SIGN_LISTING_CA, {
    variables: {
      params: {
        listingId: listing._id,
        userFullName: userFullName.value(),
      },
    },
    onCompleted: async ({ signListingCA }) => {
      cacheListing(signListingCA);
      /** Track signed CA event */
      EventTrackerUtil.trackWindowEvent(
        AnalyticsEventTypesEnum.SignedCA,
        window,
        {
          userId: user?._id ?? null,
          organizationId: user?.organization?._id ?? null,
          listingId: listing._id,
          listingUserId: listing.meta.createdBy,
          listingOrganizationId: listing.organizationId,
        },
      );
      EventTrackerUtil.trackWindowEvent(
        AnalyticsEventTypesEnum.ReceivedCA,
        window,
        {
          userId: user?._id ?? null,
          organizationId: user?.organization?._id ?? null,
          listingId: listing._id,
          listingUserId: listing.meta.createdBy,
          listingOrganizationId: listing.organizationId,
        },
      );
      if (isPrivateListing && !isPrivateListingAccess) {
        replaceWithPrivateListingSuccess();
      } else {
        replaceWithSuccess();
      }
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        userFullName.setError(message);
      }
    },
    refetchQueries: [{
      query: GET_LEAD,
      variables: {
        params: {
          userId: user?._id,
          listingId: listing._id,
        },
      },
    }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    if (isPrivateListing && !isPrivateListingAccess) {
      requestPrivateListingAccess({
        variables: {
          params: {
            listingId: listing._id,
          },
        },
      });
    } else {
      signListingCA();
    }
  };

  /* Render */
  return (
    <ModalContainer>
      <Form onSubmit={(event) => handleSubmit(event)}>
        <ModalHeader title="Unlock documents" close={popModal} />
        <ModalContent>
          <Container>
            {listing.vault.folders
              .filter((folder) => folder?.files?.length > 0)
              .map((folder, index) => (
                <Folder key={index}>
                  <Icon
                    icon={Icons.FolderSolid}
                    size={16}
                    width="16px"
                    color={Colors.Grey900}
                    margin="0 8px 0 0"
                  />
                  <Text type={TextTypesEnum.Regular16} color={Colors.Grey900}>
                    {folder.name}
                    &nbsp;
                    (
                    {folder.files.length}
                    &nbsp;
                    files
                    )
                  </Text>
                </Folder>
              ))}
            <Divider color={Colors.Grey200} margin="24px 0" />
            {customCAFile ? (
              <Text type={TextTypesEnum.Regular16} margin="0 0 24px">
                To view due diligence docs, you must first sign this
                {' '}
                <Text
                  type={TextTypesEnum.Regular16}
                  color={Colors.Brand500 || Colors.Blurple500}
                  onClick={() => previewCustomCA()}
                >
                  confidentiality agreement.
                </Text>
              </Text>
            ) : (
              <Text type={TextTypesEnum.Regular16} margin="0 0 24px">
                To view due diligence docs, you must first sign this
                {' '}
                <Link
                  href={CONFIDENTIALITY_URL}
                  target="_blank"
                  isExternal
                >
                  <LinkText type={TextTypesEnum.Regular16} display="block">confidentiality agreement.</LinkText>
                </Link>
              </Text>
            )}
            <Input
              autoFocus
              label="Enter your full name to sign"
              placeholder={UserUtil.fullName(user)}
              value={userFullName.value()}
              onChange={userFullName.setValue}
              inputType={InputTypesEnum.Text}
              margin="0 0 16px"
              data-cy="unlock-docs-modal-enter-name-input"
            />
            <SignaturePad text={userFullName.value()} />
            {customCAFile ? (
              <Text type={TextTypesEnum.Regular12} margin="0 0 24px">
                By signing your name you agree to comply with this
                {' '}
                <Text
                  type={TextTypesEnum.Regular12}
                  color={Colors.Brand500 || Colors.Blurple500}
                  onClick={() => previewCustomCA()}
                >
                  confidentiality agreement.
                </Text>
              </Text>
            ) : (
              <Text type={TextTypesEnum.Regular12} margin="0 0 24px">
                By signing your name you agree to comply with this
                {' '}
                <Link
                  href={CONFIDENTIALITY_URL}
                  target="_blank"
                  isExternal
                >
                  <LinkText type={TextTypesEnum.Regular12} display="block">confidentiality agreement.</LinkText>
                </Link>
              </Text>
            )}
          </Container>
        </ModalContent>
        <ModalFooter>
          <Flex justify="flex-end" width="100%">
            <Button
              text="Cancel"
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Medium}
              onClick={() => popModal()}
              margin="0px 8px 0px 0px"
              htmlType="button"
            />
            <Button
              text={isPrivateListing && !isPrivateListingAccess ? 'Sign & request permission' : 'Sign & view files'}
              isLoading={loading}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Medium}
              htmlType="submit"
              disabled={!isValid}
              data-cy="unlock-docs-modal-submit-button"
            />
          </Flex>
        </ModalFooter>
      </Form>
    </ModalContainer>
  );
};

export default UnlockDocumentsModal;
