import gql from 'graphql-tag';
import OfferFragments from '../fragments/offer.fragments';

const GET_USER_WITH_OFFERS = gql`
  query User {
    user {
      _id
      offers {
        ...OfferFields
        ...OfferLoiFiles
        ... OfferUser
      }
    }
  }

  ${OfferFragments.offerFields}
  ${OfferFragments.loiFiles}
  ${OfferFragments.user}
`;

export default GET_USER_WITH_OFFERS;
