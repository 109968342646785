import React from 'react';
import styled from '@emotion/styled';
import * as UserJoi from '@biproxi/models/joi/user.joi';
import { useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import GET_USER from '../../graphql/queries/user.query';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { UserActions } from '../../redux/user.redux';
import Input, { InputTypesEnum } from '../../elements/Input';
import Flex from '../../elements/Flex';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import UPDATE_USER_EMAIL from '../../graphql/mutations/updateUserEmail.mutation';
import { media } from '../../utils/MediaQuery';
import Auth from '../../utils/Auth';

const Container = styled.div`
  width: 432px;

  ${media.mobile} {
    width: 100%;
  }
`;

const Form = styled.form``;

export type ChangeEmailAddressModalProps = {};

const ChangeEmailAddressModal: React.FC<ChangeEmailAddressModalProps> = () => {
  /** Hooks */
  const formParams: UseFormParams = {
    fields: {
      email: '',
    },
    fieldOrder: ['/email'],
    schema: UserJoi.updateUserEmailParamsSchema,
  };

  const {
    controllers: {
      email,
    },
    setFieldErrors,
    isValid,
  } = useForm(formParams);

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const updateUserRedux = (updatedValue: string) => dispatch(
    UserActions.setUserField({ email: updatedValue }),
  );

  const handleSubmit = async (event?: React.FormEvent) => {
    if (event) event.preventDefault();
    if (!isValid()) return;

    await updateUserEmailMutation({
      variables: {
        params: {
          email: email.value().toString(),
        },
      },
    });
  };

  /** GraphQL */
  const [updateUserEmailMutation, { loading }] = useMutation(UPDATE_USER_EMAIL, {
    refetchQueries: [{ query: GET_USER }],
    onCompleted: async (data) => {
      if (data?.updateUserEmail?.token) {
        updateUserRedux(email.value().toString());
        pushToast({
          type: ToastTypesEnum.Notification,
          message: 'Email address successfully changed',
        });
        popModal();
        const hubspotToken = Auth.getHubSpotToken();
        Auth.setToken(data?.updateUserEmail?.token, hubspotToken);
      } else {
        pushToast({
          type: ToastTypesEnum.Error,
          message: 'There was an error changing your email address. Please try again.',
        });
        popModal();
      }
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        email.setError(message);
      }
    },
  });

  /* Render */
  return (
    <ModalContainer>
      <Form onSubmit={(event) => handleSubmit(event)}>
        <ModalHeader title="Change email address" close={popModal} />
        <ModalContent>
          <Container>
            <Input
              autoFocus
              label="Enter your new email address"
              placeholder="Enter your email address"
              value={email.value()}
              onChange={email.setValue}
              ref={email.setRef}
              error={email.error()}
              inputType={InputTypesEnum.Text}
            />
          </Container>
        </ModalContent>
        <ModalFooter>
          <Flex justify="flex-end" width="100%">
            <Button
              text="Cancel"
              type={ButtonTypesEnum.Ghost}
              size={ButtonSizesEnum.Medium}
              onClick={() => popModal()}
              margin="0px 8px 0px 0px"
              htmlType="button"
            />
            <Button
              text="Change email"
              isLoading={loading}
              type={ButtonTypesEnum.Primary}
              size={ButtonSizesEnum.Medium}
              htmlType="submit"
            />
          </Flex>
        </ModalFooter>
      </Form>
    </ModalContainer>
  );
};

export default ChangeEmailAddressModal;
