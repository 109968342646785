import React from 'react';

export default function makeEventHandler(executeOnEvent: Function) {
  return function x(eventHandler: Function) {
    return function y(event: React.ChangeEvent<HTMLInputElement>, useEventTarget: boolean) {
      executeOnEvent();
      if (useEventTarget) {
        eventHandler(event?.target?.value as string);
      } else {
        eventHandler(event?.currentTarget?.value as string);
      }
    };
  };
}
