import axios from 'axios';
import { config } from '@biproxi/env';

export const cancelSubscriptionMutation = async (subscriptionId: string) => {
  const res = await axios.post('/api/billing/cancel-subscription', { subscriptionId }, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const result = await res.data;
  return result;
};

export const createChargebeeCustomerMutation = async (userData: object) => {
  const res = await axios.post('/api/billing/create-customer', userData, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const customer = await res.data;
  return customer;
};

export const deletePaymentSourceMutation = async (paymentSourceId: string) => {
  const res = await axios.post('/api/billing/delete-payment-source', { paymentSourceId }, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const result = await res.data;
  return result;
};

export const deleteCustomer = async (customerId: string) => {
  const res = await axios.post('/api/billing/delete-customer', { customerId }, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const result = await res.data;
  return result;
};

export const renewSubscriptionMutation = async (subscriptionId: string) => {
  const res = await axios.post('/api/billing/renew-subscription', { subscriptionId }, {
    headers: {
      Authorization: config.CHARGEBEE_KEY,
    },
  });
  const result = await res.data;
  return result;
};
