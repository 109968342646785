import React from 'react';
import Flex from '../elements/Flex';
import Text, { TextTypesEnum } from '../elements/Text';
import Colors from '../styles/Colors';
import Input, { InputTypesEnum } from '../elements/Input';
import Checkbox from '../elements/Checkbox';

export type PriceFilterProps = {
    minValue?: number | string;
    maxValue?: number | string;
    checkboxActive?: boolean;
    onMinChange?: (_event: any, value: any) => void;
    onMaxChange?: (_event: any, value: any) => void;
    onCheckboxClick?: () => void;
    'data-cy'?: string;
};

const PriceFilter: React.FC<PriceFilterProps> = ({
  minValue,
  maxValue,
  checkboxActive = true,
  onMinChange,
  onMaxChange,
  onCheckboxClick,
  'data-cy': dataCy,
}) => {
  const [checkboxActiveState, setCheckboxActiveState] = React.useState(checkboxActive);
  return (
    <>
      <Flex align="center">
        <Input
          data-cy={dataCy && (`${dataCy}-min`)}
          label="Min price"
          placeholder="None"
          value={minValue?.toString() ?? ''}
          onChange={(_event, unmasked) => onMinChange?.(_event, unmasked)}
          inputType={InputTypesEnum.Currency}
        />
        <Text color={Colors.Grey500} margin="20px 8px 0">&ndash;</Text>
        <Input
          data-cy={dataCy && (`${dataCy}-max`)}
          label="Max price"
          placeholder="None"
          value={maxValue?.toString() ?? ''}
          onChange={(_event, unmasked) => onMaxChange?.(_event, unmasked)}
          inputType={InputTypesEnum.Currency}
        />
      </Flex>
      <Checkbox
        onClick={() => {
          setCheckboxActiveState(!checkboxActiveState);
          onCheckboxClick?.();
        }}
        active={checkboxActiveState}
        label="Show unpriced"
        margin="16px 0 0"
        labelTextType={TextTypesEnum.Medium12}
      />
    </>
  );
};

export default PriceFilter;
