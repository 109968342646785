import Joi from 'joi';
import { authenticatedDataSchema } from './authentication.joi';
import JoiUtil from '../utils/JoiUtil';
import OfferQueryTypesEnum from '../enums/OfferQueryTypesEnum';
import OfferStateEnum from '../enums/OfferStateEnum';
import paginationSchema from './pagination.joi';

/** ******************************************************************************
*  Create Offer
******************************************************************************* */

export const createOfferFormParamsSchema = Joi.object().keys({
  purchasePrice: Joi.number().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  depositAmount: Joi.number().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  dueDiligencePeriod: Joi.number().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  closingPeriod: Joi.number().required().error(([error]) => JoiUtil.encodeError('Required', error)),
  financingPeriod: Joi.number().optional().allow('', null),
  exclusivityPeriod: Joi.number().optional().allow('', null),
  otherTerms: Joi.string().optional().allow('', null),
  coverLetter: Joi.string().optional().allow('', null),
  offerUserOrganizationId: Joi.string().optional().allow('', null),
}).prefs({
  abortEarly: false,
});

export const createOfferParamsSchema = Joi.object().keys({
  offerId: Joi.string().optional().allow(null, ''),
  listingId: Joi.string().required(),
  purchasePrice: Joi.number().required(),
  depositAmount: Joi.number().required(),
  dueDiligencePeriod: Joi.number().required(),
  closingPeriod: Joi.number().required(),
  financingPeriod: Joi.number().optional().allow('', null),
  exclusivityPeriod: Joi.number().optional().allow('', null),
  otherTerms: Joi.string().optional().allow('', null),
  loiFileIds: Joi.array().items(Joi.string()).optional(),
  coverLetter: Joi.string().optional().allow('', null),
  offerUserOrganizationId: Joi.string().optional().allow('', null),
}).prefs({
  abortEarly: false,
});

export const createOfferRequestSchema = Joi.object().keys({
  auth: authenticatedDataSchema,
  params: createOfferParamsSchema,
});

/** ******************************************************************************
*  List Offers
******************************************************************************* */

export const listOffersParamsSchema = Joi.object().keys({
  queryType: Joi.string().valid(...Object.values(OfferQueryTypesEnum)).required(),
  listingId: Joi.string().optional().allow(null),
  leadId: Joi.string().optional().allow(null),
  hasRevisedOffers: Joi.boolean().optional().allow(null),
  isActive: Joi.boolean().optional().allow(null),
  pagination: paginationSchema.optional(),
}).prefs({
  abortEarly: false,
});

export const listOffersRequestSchema = Joi.object().keys({
  auth: authenticatedDataSchema,
  params: listOffersParamsSchema,
});

/** ******************************************************************************
*  Reject Offer
******************************************************************************* */

export const rejectOfferParamsSchema = Joi.object().keys({
  offerId: Joi.string().required(),
  message: Joi.string().optional().allow(null, ''),
}).prefs({
  abortEarly: false,
});

export const rejectOfferRequestSchema = Joi.object().keys({
  auth: authenticatedDataSchema,
  params: rejectOfferParamsSchema,
});

/** ******************************************************************************
*  Update Offer State
******************************************************************************* */

export const updateOfferStateParamsSchema = Joi.object().keys({
  offerId: Joi.string().required(),
  state: Joi.string().valid(...Object.values(OfferStateEnum)).required(),
}).prefs({
  abortEarly: false,
});

export const updateOfferStateRequestSchema = Joi.object().keys({
  auth: authenticatedDataSchema,
  params: updateOfferStateParamsSchema,
});
