import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import { media } from '../../utils/MediaQuery';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Input, { InputTypesEnum } from '../../elements/Input';
import PUSH_LISTING_TO_WEBFLOW from '../../graphql/mutations/pushListingToWebflow.mutation';
import Divider from '../../elements/Divider';

const Container = styled.div`
  width: 432px;

  ${media.mobile} {
    width: 100%;
  }
`;

enum ButtonPushedTypeEnum {
  Featured = 'Featured',
  Custom = 'Custom',
}

export type PushToWebflowModalProps = {
  listingId: string;
};

const PushToWebflowModal: React.FC<PushToWebflowModalProps> = ({
  listingId,
}) => {
  /** State */
  const [whichLoading, setWhichLoading] = React.useState(null);
  const [customCollectionId, setCustomCollectionId] = React.useState('');

  const dispatch = useAppDispatch();

  const popModal = () => dispatch(AppActions.popModal());
  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  /** GraphQl */
  const [pushListingToWebflow] = useMutation(PUSH_LISTING_TO_WEBFLOW, {
    onCompleted: () => {
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Success',
      });
      popModal();
      setWhichLoading(null);
    },
    onError: (error) => {
      const { message } = ApolloUtil.parseApolloClientError(error);
      pushToast({
        type: ToastTypesEnum.Error,
        message,
      });
      popModal();
      setWhichLoading(null);
    },
  });

  /* Render */
  return (
    <ModalContainer>
      <ModalHeader title="Push listing to Webflow" close={popModal} />
      <ModalContent>
        <Container>
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Grey900}
            margin="8px 0 0"
          >
            Are you sure you want to push this listing to the Biproxi Webflow site? This will replace the oldest pushed listing in that collection if there are already 6 listings.
          </Text>
          <Button
            text="Push to featured listings section"
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Large}
            margin="16px 0 0"
            isLoading={whichLoading === ButtonPushedTypeEnum.Featured}
            onClick={() => {
              if (!whichLoading) {
                setWhichLoading(ButtonPushedTypeEnum.Featured);
                pushListingToWebflow({
                  variables: {
                    params: {
                      listingId,
                    },
                  },
                });
              }
            }}
          />
          <Divider margin="16px 0" />
          <Input
            placeholder="Enter a collectionId from Webflow"
            value={customCollectionId}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCustomCollectionId(e.target.value);
            }}
            inputType={InputTypesEnum.Text}
          />
          <Button
            text="Push to custom collection"
            disabled={!customCollectionId}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Large}
            margin="16px 0 0"
            isLoading={whichLoading === ButtonPushedTypeEnum.Custom}
            onClick={() => {
              if (!whichLoading) {
                setWhichLoading(ButtonPushedTypeEnum.Custom);
                pushListingToWebflow({
                  variables: {
                    params: {
                      listingId,
                      customCollectionId,
                    },
                  },
                });
              }
            }}
          />
        </Container>
      </ModalContent>
    </ModalContainer>
  );
};

export default PushToWebflowModal;
