enum AuraListingFieldsEnum {
  _id = '_id',
  Name = 'name',
  AssetClass = 'assetClass',
  PropertyType = 'propertyType',
  Description = 'description',
  IsPortfolioListing = 'isPortfolioListing',
  IsPrivateListing = 'isPrivateListing',
  State = 'state',
  Address1 = 'address1',
  Address2 = 'address2',
  CherreId = 'cherreId',
  City = 'city',
  Country = 'country',
  GooglePlaceId = 'googlePlaceId',
  LocationCoordinates = 'locationCoordinates',
  LocationType = 'locationType',
  AddressState = 'addressState',
  TimeZoneId = 'timeZoneId',
  Zip = 'zip',
  MediaFileIds = 'mediaFileIds',
  MediaVideoFileId = 'mediaVideoFileId',
  MediaVideoThumbnailFileId = 'mediaVideoThumbnailFileId',
  MetaCreatedAt = 'metaCreatedAt',
  MetaCreatedBy = 'metaCreatedBy',
  MetaLastPublishedAt = 'metaLastPublishedAt',
  MetaLastUpdatedAt = 'metaLastUpdatedAt',
  MetaLastUpdatedBy = 'metaLastUpdatedBy',
  ListingPrimaryListingUrl = 'listingPrimaryListingUrl',
}

/**
 * These are the mongo fields that are needed within Aura
 */
export enum AuraMongoListingFieldsEnum {
  _id = '_id',
  Name = 'name',
  AssetClass = 'assetClass',
  PropertyType = 'propertyType',
  Description = 'description',
  IsPortfolioListing = 'isPortfolioListing',
  IsPrivateListing = 'isPrivateListing',
  State = 'state',
  Address = 'address',
  Media = 'media',
}

export default AuraListingFieldsEnum;
