import React from 'react';
import styled from '@emotion/styled';
import Image from 'next/image';
import Skeleton from 'react-loading-skeleton';
import { BIPROXI_WEBSITE_URL } from '@biproxi/models/externalLinks';
import ColorJson from '../styles/Colors.json';
import Colors from '../styles/Colors';
import { media } from '../utils/MediaQuery';
import Flex from '../elements/Flex';
import Loader, { LoaderSizes } from '../elements/Loader';
import Text, { TextTypesEnum } from '../elements/Text';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../elements/Button';
import { AppState, useAppDispatch, useAppSelector } from '../redux/store';
import { AppActions } from '../redux/app.redux';
import { UserActions } from '../redux/user.redux';
import Icon, { Icons } from '../elements/Icon';
import ExitButton from '../elements/ExitButton';

const AuthenticationModalContainer = styled.div<{ fullscreen: boolean }>`
  position: relative;
  height: ${({ fullscreen }) => (fullscreen ? '100vh' : '680px')};
  width: ${({ fullscreen }) => (fullscreen ? '100vw' : '1152px')};
  border-radius: ${({ fullscreen }) => (fullscreen ? '0' : '16px')};
  display: flex;
  background-color: ${Colors.White};

  ${media.mobile} {
    // min-height solution for now on Android mobile to
    // prevent bottom button popping over input
    min-height: 600px;
    position: relative;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    border-radius: 0px;
  }
`;

const ContentContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${media.mobile} {
    width: 100%;
  }
`;

type AuthenticationModalProps = {
  children: React.ReactNode;
  fullscreen?: boolean;
  showExit?: boolean;
  close?: Function;
};

export const AuthenticationModal: React.FC<AuthenticationModalProps> = ({
  children,
  fullscreen = false,
  showExit = true,
  close,
}) => {
  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  return (
    <AuthenticationModalContainer fullscreen={fullscreen}>
      <MarketingPanel
        fullscreen={fullscreen}
      />
      <ContentContainer>{children}</ContentContainer>
      {showExit && (
        <ExitButton
          color={Colors.Grey300}
          onClick={() => (close ? close() : popModal())}
        />
      )}
    </AuthenticationModalContainer>
  );
};

/** ******************************************************************************
 *  Marketing Panel
 ****************************************************************************** */

// TODO: The CSS needs to be refactored because splitting the page using 50% does not scale images correctly and this should be a two column layout //

const MarketingPanelContainer = styled.div<{ fullscreen: boolean, backgroundColor?: string }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 50%;
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ fullscreen }) => (fullscreen ? '0' : '16px 0 0 16px')};
  overflow: hidden;

  ${media.mobile} {
    display: none;
  }
`;

const Logo = styled.img`
  width: 156px;
  height: auto;
  position: absolute;
  top: 32px;
  left: 32px;
  cursor: pointer;
`;

const SkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const SkeletonLogoContainer = styled.div`
  width: 150px;
  height: 100px;
  position: absolute;
  top: 32px;
  left: 32px;
  cursor: pointer;
`;

type MarketingPanelProps = {
  fullscreen: boolean;
}

export const MarketingPanel: React.FC<MarketingPanelProps> = ({ fullscreen }) => {
  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  const [backgroundImageLoad, setBackgroundImageLoad] = React.useState(false);
  const [cornerLogoLoad, setCornerLogoLoad] = React.useState(false);

  const {
    contentful: {
      brandCollection: {
        colors,
        companyMediaAssets,
        cornerLogoUrl,
      },
    },
  } = useAppSelector((state: AppState) => state);

  const companyBackground = companyMediaAssets.marketingLoginPhoto.url || companyMediaAssets.marketingLoginPhoto;
  const cornerLogo = companyMediaAssets.cornerLoginLogo.url || companyMediaAssets.cornerLoginLogo;
  const cornerUrl = cornerLogoUrl || BIPROXI_WEBSITE_URL;
  /**
   * Since contentful pretty much overwrites the Json file
   * 'SpecialBlack' doesn't exist in some brand JSONs
  */
  const marketingPanelColor = colors.Brand900 || ColorJson.SpecialBlack;

  React.useEffect(() => {
    setBackgroundImageLoad(true);
    setCornerLogoLoad(true);

    if (companyBackground) setBackgroundImageLoad(false);
    if (cornerLogo) setCornerLogoLoad(false);
  }, []);

  /** ******************************************************************************
     *  Contentful STUFF
     ******************************************************************************* */
  return (
    <MarketingPanelContainer fullscreen={fullscreen} backgroundColor={marketingPanelColor}>
      {!backgroundImageLoad
        ? (
          <Image
            alt="biproxi-logo"
            src={companyBackground}
            fill
            style={{
              objectFit: 'cover',
            }}
          />
        ) : (
          <SkeletonContainer style={{ width: '100%', height: '100%' }}>
            <Skeleton
              width="100%"
              height="100%"
              style={{ backgroundColor: Colors.Grey300, borderRadius: '0' }}
            />
          </SkeletonContainer>
        )}
      {!cornerLogoLoad ? (
        <Logo
          onClick={() => window.open(cornerUrl, '_self')}
          src={cornerLogo}
        />
      ) : (
        <SkeletonLogoContainer onClick={() => window.open(cornerUrl, '_self')}>
          <Skeleton
            width="100%"
            height="100%"
            style={{ backgroundColor: Colors.Grey300 }}
          />
        </SkeletonLogoContainer>
      )}
    </MarketingPanelContainer>
  );
};

/** ******************************************************************************
 *  Content
 ****************************************************************************** */

const ContentStyled = styled.form<ContentProps>`
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '400px')};
  box-sizing: border-box;

  ${media.mobile} {
    width: calc(100% - 32px);
    /* padding: 24px 16px 16px; */
  }
`;

type ContentProps = {
  fullWidth?: boolean;
} & React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, {}>

export const Content: React.FC<ContentProps> = ({
  onSubmit,
  children,
  fullWidth,
}) => (
  <ContentStyled onSubmit={onSubmit} fullWidth={fullWidth}>
    {children}
  </ContentStyled>
);
export const BottomRight = styled.div<{ isStandalone: boolean }>`
  position: absolute;
  bottom: 25px;
  right: ${({ isStandalone }) => (isStandalone ? '100px' : '25px')};

  ${media.mobile} {
    position: fixed;
    bottom: 40px;
    right: 25px;
  }
`;

/** ******************************************************************************
 *  Skip Step Button
 ****************************************************************************** */

export const SkipStepButton: React.FC<{ onClick?: () => void; isStandalone: boolean; }> = ({
  onClick,
  isStandalone,
}) => (
  <BottomRight isStandalone={isStandalone}>
    <Button
      onClick={onClick}
      text="Skip this step"
      type={ButtonTypesEnum.Outline}
      size={ButtonSizesEnum.Medium}
      htmlType="button"
      rightIcon={Icons.AngleRightRegular}
      iconColor={Colors.Grey900}
      iconSize={16}
    />
  </BottomRight>
);

/** ******************************************************************************
 *  Loading
 ****************************************************************************** */

export const AuthLoading: React.FC = () => {
  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  const [loadingName, setLoadingName] = React.useState(false);

  /** Contentful Variables - Redux */
  const {
    contentful: {
      brandCollection: {
        companyName,
      },
    },
  } = useAppSelector((state: AppState) => state);

  React.useEffect(() => {
    setLoadingName(true);

    if (companyName) {
      setLoadingName(false);
    }
  }, []);

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  return (
    <Flex flex="1" align="center" justify="center" direction="column">
      <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
      <Text data-cy="sign-in-loader-text" type={TextTypesEnum.Medium18} margin="8px 0 0">
        Signing in to
        {' '}
        {!loadingName ? companyName : (
          <SkeletonContainer style={{ width: '100%', height: '100%' }}>
            <Skeleton
              width="100%"
              height="100%"
              style={{ backgroundColor: Colors.Grey500 }}
            />
          </SkeletonContainer>
        )}
      </Text>
    </Flex>
  );
};

/** ******************************************************************************
 *  OAuth
 ****************************************************************************** */

export const OAuthScreen: React.FC = () => {
  /** State */
  const { oAuthLoading, oAuthError } = useAppSelector((state: AppState) => state.user);

  /** Actions */
  const dispatch = useAppDispatch();

  const cancelOAuthFlow = () => dispatch(
    UserActions.cancelOAuthFlow(),
  );

  /** Render */
  if (oAuthLoading) {
    return (
      <ContentStyled>
        <Flex flex="1" align="center" justify="center" direction="column">
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
          <Text data-cy="sign-in-loader-text" type={TextTypesEnum.Medium18} margin="8px 0 24px">Signing in with another app</Text>
          <Button
            text="Cancel"
            onClick={() => {
              cancelOAuthFlow();
            }}
            type={ButtonTypesEnum.Outline}
            size={ButtonSizesEnum.Large}
            isFullWidth
          />
        </Flex>
      </ContentStyled>
    );
  }

  if (oAuthError) {
    return (
      <ContentStyled>
        <Flex flex="1" align="center" justify="center" direction="column">
          <Icon icon={Icons.TimesCircleSolid} size={48} color={Colors.Red500} />
          <Text type={TextTypesEnum.Bold24} color={Colors.Black} margin="12px 0 0">Authorization Failed</Text>
          <Text type={TextTypesEnum.Regular16} color={Colors.Grey700} margin="4px 0 24px" align="center">
            {oAuthError?.message}
            <br />
            {' '}
            Please try again.
          </Text>
          <Button
            text="Try again"
            onClick={() => {
              cancelOAuthFlow();
            }}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Large}
            isFullWidth
          />
        </Flex>
      </ContentStyled>
    );
  }

  return null;
};
