export enum InvestorTypeEnum {
  OwnerOperator = 'Owner Operator',
  PrivateInvestor = 'Private Investor',
  FamilyOffice = 'Family Office',
  InstitutionalInvestor = 'Institutional Investor',
  REIT = 'REIT',
  ExchangeBuyer = 'Exchange Buyer',
}

export enum AssetsOwnedEnum {
  None = 'None',
  OneToThree = '1-3',
  FourToSix = ' 4-6',
  SevenToNine = '7-9',
  MoreThanTen = '10+'
}

export enum TargetPriceRangeEnum {
  LessThan1Million = '$0-$1M',
  OneMillionToFiveMillion = '$1M-$5M',
  FiveMillionToTenMillion = '$5M-$10M',
  TenMillionToFifteenMillion = '$10M-$15M',
  FifteenMillionToTwentyFiveMillion = '$15M-$20M',
  MoreThanTwentyFiveMillion = '$20M+'
}

export enum PreferredInvestmentTypeEnum {
  Core = 'Core',
  CorePlus = 'Core-Plus',
  ValueAdd = 'Value-Add',
  Opportunistic = 'Opportunistic',
  Distressed = 'Distressed',
  DevelopmentRedevelopment = 'Development/Re-development',
}

export const PreferredInvestmentTypeDescriptionObject = {
  [PreferredInvestmentTypeEnum.Core]: 'Stable income with very low risk',
  [PreferredInvestmentTypeEnum.CorePlus]: 'Stable income with low to moderate risk',
  [PreferredInvestmentTypeEnum.ValueAdd]: 'Moderate to high risk depending on value added to property and management',
  [PreferredInvestmentTypeEnum.Opportunistic]: 'High risk depending on value added to property and management',
  [PreferredInvestmentTypeEnum.Distressed]: 'Undervalued due to one or more factors including: mismanagement, poor physical condition, environmental concerns, poor pricing, or poor market conditions',
  [PreferredInvestmentTypeEnum.DevelopmentRedevelopment]: 'Core',
};

// Asset Class question just uses the Asset Class Enum.

export enum NationalOrGlobalFocusEnum {
  Global = 'Global',
  National = 'National',
}

export enum RegionalFocusEnum {
  Northeast = 'Northeast',
  Southeast = 'Southeast',
  'Mid-Atlantic' = 'Mid-Atlantic',
  Northwest = 'Northwest',
  Southwest = 'Southwest',
  Midwest = 'Midwest',
  West = 'West',
  Hawaii = 'Hawaii',
  Alaska = 'Alaska',
}
