import React from 'react';
import styled from '@emotion/styled';
import { useQuery, useMutation } from '@apollo/client';
import * as UserJoi from '@biproxi/models/joi/user.joi';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import JoiUtil from '@biproxi/models/utils/JoiUtil';
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '../../styles/components/Modal.styles';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { UserActions } from '../../redux/user.redux';
import Text, { TextTypesEnum } from '../../elements/Text';
import Flex from '../../elements/Flex';
import Colors from '../../styles/Colors';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import useForm, { UseFormParams } from '../../hooks/useForm.hook';
import { IToastConfig, ToastTypesEnum } from '../Toast';
import GET_USER from '../../graphql/queries/user.query';
import CodeInput from '../../elements/CodeInput';
import VERIFY_PHONE_NUMBER_VERIFICATION_CODE from '../../graphql/mutations/verifyPhoneNumberVerificationCode.mutation';
import LinkText from '../../elements/LinkText';
import { ModalTypesEnum } from './Modal';
import { media } from '../../utils/MediaQuery';
import Loader, { LoaderSizes } from '../../elements/Loader';

const Container = styled.div`
  width: 432px;

  ${media.mobile} {
    width: 100%;
  }
`;

const LoaderContainer = styled.div`
  width: 432px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;

  ${media.mobile} {
    width: 100%;
  }
`;

export type ChangePhoneNumberCodeModalProps = {
  phoneNumber: string;
};

const ChangePhoneNumberCodeModal: React.FC<ChangePhoneNumberCodeModalProps> = ({
  phoneNumber,
}) => {
  /** Hooks */
  const formParams: UseFormParams = {
    fields: {
      phoneNumberVerificationCode: '',
    },
    fieldOrder: ['/phoneNumberVerificationCode'],
    schema: UserJoi.verifyPhoneNumberVerificationCodeParamsSchema,
  };

  const {
    controllers: {
      phoneNumberVerificationCode,
    },
    setFieldErrors,
  } = useForm(formParams);

  /** Actions */
  const dispatch = useAppDispatch();
  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const replaceModalWithChangePhoneNumber = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ChangePhoneNumber,
      props: {},
    }),
  );

  const pushToast = (config: IToastConfig) => dispatch(
    AppActions.pushToast(config),
  );

  const handleSubmit = async (code?: string) => {
    const { errors } = JoiUtil.validate(
      UserJoi.verifyPhoneNumberVerificationCodeParamsSchema,
      { phoneNumberVerificationCode: code },
    );

    if (JoiUtil.hasErrors(errors)) {
      setFieldErrors(JoiUtil.parseErrors(errors));
      return;
    }

    await verifyPhoneNumberVerificationCodeMutation({
      variables: {
        params: {
          phoneNumberVerificationCode: code,
        },
      },
    });
  };

  const updateUserRedux = (updatedValue: string) => dispatch(
    UserActions.setUserField({ phoneNumber: updatedValue }),
  );

  /** GraphQL */
  const { data } = useQuery(GET_USER);
  const [verifyPhoneNumberVerificationCodeMutation, { loading }] = useMutation(VERIFY_PHONE_NUMBER_VERIFICATION_CODE, {
    refetchQueries: [{ query: GET_USER }],
    onCompleted: async () => {
      updateUserRedux(phoneNumber);
      pushToast({
        type: ToastTypesEnum.Notification,
        message: 'Phone number successfully changed',
      });
      popModal();
    },
    onError: async (error) => {
      const { message, fields } = ApolloUtil.parseApolloClientError(error);

      if (fields) {
        setFieldErrors(fields);
      } else {
        phoneNumberVerificationCode.setError(message);
      }
    },
  });

  /* Render */
  return (
    <ModalContainer>
      <ModalHeader title="Change phone number" close={popModal} />
      <ModalContent>
        {loading ? (
          <LoaderContainer>
            <Flex flex="1" align="center" justify="center" direction="column" height="100%">
              <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
              <Text type={TextTypesEnum.Medium18} margin="8px 0 0">Verifying code</Text>
            </Flex>
          </LoaderContainer>
        ) : (
          <Container>
            <Text type={TextTypesEnum.Bold18}>We just texted you</Text>
            <Text type={TextTypesEnum.Regular16} color={Colors.Grey700} margin="4px 0 0">
              Please enter the verification code we just sent to
              {' '}
              {StringUtil.formatPhoneNumber(data?.user?.phoneNumberWaitingToVerify) || 'your phone number'}
            </Text>
            <CodeInput
              ref={phoneNumberVerificationCode.setRef}
              error={phoneNumberVerificationCode.error()}
              onChange={() => {
                phoneNumberVerificationCode.setError(null);
              }}
              onComplete={(code: string) => {
                handleSubmit(code);
              }}
            />
            <LinkText
              onClick={() => replaceModalWithChangePhoneNumber()}
              type={TextTypesEnum.Medium14}
              margin="24px 0px 0px 0px"
            >
              I didn&apos;t receive a code
            </LinkText>
          </Container>
        )}
      </ModalContent>
      <ModalFooter>
        <Flex justify="flex-end" width="100%">
          <Button
            text="Cancel"
            type={ButtonTypesEnum.Ghost}
            size={ButtonSizesEnum.Medium}
            onClick={() => popModal()}
            margin="0px 8px 0px 0px"
            htmlType="button"
            disabled={loading}
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default ChangePhoneNumberCodeModal;
