import React from 'react';
import styled from '@emotion/styled';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import StringUtil from '@biproxi/models/utils/StringUtil';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import Icon, { Icons } from '../../elements/Icon';

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export type LeadStatsProps = {
  lead: ILeadGraphQL;
};

const LeadStats: React.FC<LeadStatsProps> = ({
  lead,
}) => {
  /* State */
  const {
    metrics: {
      signedCA,
      lastPageViewedAt,
      pageViews,
      offersMade,
      documentDownloads,
      documentViews,
    },
  } = lead;

  const lastViewed = lastPageViewedAt > 0 ? StringUtil.capitalize(
    TimeUtil.date(
      lastPageViewedAt,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    ).toRelativeCalendar(),
  ) : 'n/a';

  const rows = [
    {
      name: 'Listing views',
      value: pageViews,
    },
    {
      name: 'Last visit',
      value: lastViewed,
    },
    {
      name: 'Offers made',
      value: offersMade,
    },
    {
      name: 'CA signed',
      render: () => (
        signedCA
          ? (
            <Icon
              icon={Icons.CheckCircleSolid}
              color={signedCA ? Colors.Brand700 || Colors.Blurple700 : Colors.Grey300}
              size={16}
            />
          )
          : <Text type={TextTypesEnum.Regular16} color={Colors.Black}>0</Text>
      ),
    },
    {
      name: 'Docs viewed',
      value: documentViews,
    },
    {
      name: 'Docs downloaded',
      value: documentDownloads,
    },
  ];

  /* Render */
  return (
    <Container data-cy="leads-stats-container">
      {rows.map((row, index) => (
        <Row key={index}>
          <Text type={TextTypesEnum.Regular16} color={Colors.Grey500}>{row.name}</Text>
          {row?.render?.() ?? <Text type={TextTypesEnum.Regular16} color={Colors.Black}>{row.value}</Text>}
        </Row>
      ))}
      {}
    </Container>
  );
};

export default LeadStats;
