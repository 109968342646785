/* eslint-disable react/no-unused-prop-types */
import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import * as IListingService from '@biproxi/models/services/IListingService';
import ApolloUtil from '@biproxi/models/utils/ApolloUtil';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import EventTrackerUtil from '@biproxi/models/utils/EventTrackerUtil';
import AnalyticsEventTypesEnum from '@biproxi/models/enums/AnalyticsEventTypesEnum';
import { useDebounceCallback } from '@react-hook/debounce';
import UserUtil from '@biproxi/models/utils/UserUtil';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import { CONFIDENTIALITY_URL } from '@biproxi/models/externalLinks';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Flex from '../../elements/Flex';
import Input, { InputTypesEnum } from '../../elements/Input';
import INITIATE_CHAT from '../../graphql/mutations/initiateChat.mutation';
import { AppActions } from '../../redux/app.redux';
import { ListingSelectors } from '../../redux/listing.redux';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  ModalContainer, ModalContent, ModalFooter, ModalHeader,
} from '../../styles/components/Modal.styles';
import { ConfirmChangeModalTypesEnum } from './ConfirmChangeModal';
import { ModalTypesEnum } from './Modal';
import { media } from '../../utils/MediaQuery';
import SignatureInput from '../SignatureInput';
import useUser from '../../hooks/useUser.hook';
import Link from '../../elements/Link';
import LinkText from '../../elements/LinkText';
import REQUEST_PRIVATE_LISTING_ACCESS from '../../graphql/mutations/requestPrivateListingAccess.mutation';
import SIGN_LISTING_CA from '../../graphql/mutations/signListingCA.mutation';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';

const Container = styled.div`
  width: 432px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.mobile} {
    width: 100%;
  }
`;

const SignatureContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export type RequestPrivateListingAccessModalProps = {
    listing: IListingGraphQL;
    lead?: ILeadGraphQL;
  };

export type RequestPrivateListingAccessModalConfig = {
    onComplete?: (result?: any) => void,
    onError?: (error: Error) => void,
    onSubmit?: (submitParams?: any) => void;
    onCancel?: () => void,
    params?: RequestPrivateListingAccessModalProps;
  };

const RequestPrivateListingAccessModal: React.FC<RequestPrivateListingAccessModalConfig> = (
  config: RequestPrivateListingAccessModalConfig = { params: {} as RequestPrivateListingAccessModalProps },
) => {
  /** Hooks */
  const { user } = useUser();

  /** State */
  const [message, setMessage] = React.useState('');
  const [userFullName, setUserFullName] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const isSignatureValid = userFullName === UserUtil.fullName(user);
  const setSignatureDebounce = useDebounceCallback(setUserFullName, 400);

  /** Actions */
  const dispatch = useAppDispatch();
  const listingId = config?.params?.listing?._id ?? useAppSelector(ListingSelectors.selectedListingId);
  const listing = config?.params?.listing;
  const lead = config?.params?.lead;
  const customCAFile = listing.vault?.caFile;

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const replaceWithConfirmModal = () => dispatch(
    AppActions.replaceModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Success,
        title: 'Your request has been sent!',
        text: 'You will receive confirmation when permissions have been granted by broker.',
        confirm: (event: React.FormEvent) => {
          event?.preventDefault();
          popModal();
        },
      },
    }),
  );

  const previewCustomCA = () => dispatch(
    AppActions.previewFiles({
      fileId: customCAFile._id,
      files: [customCAFile],
      track: false,
    }),
  );

  /** GraphQL */

  /** Request private listing access */
  interface RequestPrivateListingPermissionData {
    listing: IListingGraphQL;
  }

  interface RequestPrivateListingPermissionVars {
    params: {
      listingId: String;
    }
  }

  const [requestPrivateListingAccess, { loading: privateListingAccessRequestLoading }] = useMutation<RequestPrivateListingPermissionData, RequestPrivateListingPermissionVars>(REQUEST_PRIVATE_LISTING_ACCESS, {
    onError: (error) => {
      logger.error('REQUEST_PRIVATE_LISTING_ACCESS error', error);
      const { message } = ApolloUtil.parseApolloClientError(error);
      setError(message);
    },
  });

  /** Send message to broker */
  const [initiateChat, { loading: chatLoading }] = useMutation(INITIATE_CHAT, {
    onError: (error) => {
      logger.error('INITIATE_CHAT error', error);
      const { message } = ApolloUtil.parseApolloClientError(error);
      setError(message);
    },
  });

  /** Sign CA */
  type Data = {
    signListingCA: IListingGraphQL;
  }
  type Params = IListingService.TSignListingCAPayload;

  const [signListingCA, { loading: caLoading }] = useMutation<Data, Params>(SIGN_LISTING_CA, {
    variables: {
      params: {
        listingId: listing._id,
        userFullName,
      },
    },
    onCompleted: async () => {
      /** Track signed CA event */
      EventTrackerUtil.trackWindowEvent(
        AnalyticsEventTypesEnum.SignedCA,
        window,
        {
          userId: user?._id ?? null,
          organizationId: user?.organization?._id ?? null,
          listingId: listing._id,
          listingUserId: listing.meta.createdBy,
          listingOrganizationId: listing.organizationId,
        },
      );
      EventTrackerUtil.trackWindowEvent(
        AnalyticsEventTypesEnum.ReceivedCA,
        window,
        {
          userId: user?._id ?? null,
          organizationId: user?.organization?._id ?? null,
          listingId: listing._id,
          listingUserId: listing.meta.createdBy,
          listingOrganizationId: listing.organizationId,
        },
      );
    },
    onError: async (error) => {
      logger.error('SIGN_LISTING_CA error', error);
      const { message } = ApolloUtil.parseApolloClientError(error);
      setError(message);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    config?.onSubmit?.();

    await requestPrivateListingAccess({
      variables: {
        params: {
          listingId,
        },
      },
    });

    if (!lead?.fileAccessLevel?.hasSignedCA && userFullName?.length > 0) {
      await signListingCA();
    }

    if (!message) {
      if (!error) {
        replaceWithConfirmModal();
        config?.onComplete?.();
      }
    }

    await initiateChat({
      variables: {
        params: {
          message,
          listingId,
        },
      },
    });

    if (!error) {
      replaceWithConfirmModal();
      config?.onComplete?.();
    }
  };

  /** Effects */
  React.useEffect(() => {
    if (privateListingAccessRequestLoading || chatLoading || caLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [privateListingAccessRequestLoading, chatLoading, caLoading]);

  /* Render */
  return (
    <ModalContainer>
      <ModalHeader title="Request permission" close={popModal} />
      <ModalContent>
        <Container>
          <Input
            autoFocus
            placeholder="Enter your message to the broker"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            error={error}
            inputType={InputTypesEnum.TextArea}
            height="120px"
          />
          {!lead?.fileAccessLevel?.hasSignedCA && (
          <SignatureContainer>
            <Flex>
              <Text type={TextTypesEnum.Regular16} margin="0 0 16px" display="inline">
                To request access to this private listing, you must first sign this
                {' '}
                {customCAFile ? (
                  <Text
                    type={TextTypesEnum.Regular16}
                    color={Colors.Brand500 || Colors.Blurple500}
                    display="inline"
                    onClick={() => previewCustomCA()}
                  >
                    confidentiality agreement.
                  </Text>
                ) : (
                  <Link
                    href={CONFIDENTIALITY_URL}
                    target="_blank"
                    isExternal
                  >
                    <LinkText type={TextTypesEnum.Regular16} display="inline">confidentiality agreement.</LinkText>
                  </Link>
                )}
              </Text>
            </Flex>
            <SignatureInput
              user={user}
              setParentSignatureState={setSignatureDebounce}
            />
          </SignatureContainer>
          )}
        </Container>
      </ModalContent>
      <ModalFooter>
        <Flex justify="flex-end" width="100%">
          <Button
            text="Cancel"
            type={ButtonTypesEnum.Ghost}
            size={ButtonSizesEnum.Medium}
            onClick={() => popModal()}
            margin="0px 8px 0px 0px"
            htmlType="button"
          />
          <Button
            text="Send request"
            isLoading={loading}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Medium}
            onClick={(event) => handleSubmit(event)}
            disabled={!isSignatureValid && !lead?.fileAccessLevel?.hasSignedCA}
          />
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};

export default RequestPrivateListingAccessModal;
